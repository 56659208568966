/**=========================================================
 * Module: wg-units.js
 * Data-Access-Layer for Dashboard Data (Places, Units, etc etc)
 =========================================================*/

namespace wg {

  export interface IWGApiEntity {
    // Has been loaded and processed
    ready: boolean;
    // Needs update from the server. Refrain from processing, it will be updated soon
    changed: boolean;
    // Needs processing of local data.
    changed_locally: boolean;
    // Is currently being updated from the server
    loading: boolean;
    // Is currently being processed
    processing: boolean;

    // Delete local data
    reset(): void;

    // Reduce and Normalize data
    parse?(data: any): any;

    // parse?<T>(data: T): T;

    // Process all collected entries and related entities
    // Sets ready and updates all_ready status
    process_data?(): void;

    // Update all entries from server
    update?(): void;

    // Merge data onto structures
    merge_entry?(entry: object, process_afterwards?: boolean): any;

    // Update from server a single ID, merge and process data
    update_singular?(id: number): void;

    // Delete from server a single Id
    delete?(id: number, callback?: (result: IReturnResult["result"]) => void): void;

    // activate
    activate?(id: number, active: boolean, callback?: (result: IReturnResult["result"]) => void): void;

    // Add a local-only entry
    add_local?(entry: ISensor): void;

    // Delete a local-only entry
    delete_local?(id: number | string): void;

    // Method. Timer used for repeating update
    timer?: NodeJS.Timeout;
    // Number of retries already used for update
    retries?: number;


    // [key: string]: any;
  }

  export interface IWGApiPlaces extends IWGApiEntity {
    places: IPlace[];
    places_id: { [id: number]: IPlace };

  }

  export interface IWGApiUnits extends IWGApiEntity {
    units: IUnit[];
    units_id: { [id: number]: IUnit };

  }

  export interface IWGApiDevices extends IWGApiEntity {
    there_are_unplaced: boolean;
    has_sensors: boolean;

    devices: IDevice[];
    devices_id: { [id: number]: IDevice };
    devices_uuid: { [uuid: string]: IDevice };
    update_schedule: (timestamp?: number) => void;
    update_singular: (id: number, uuid?: string) => void;
    update_singular_schedule: (id: number, timestamp?: number) => void;
    new_parameter_received_timer: NodeJS.Timeout;
    processLastKnownMessage: (device_uuid: string, stream: string, msg_timestamp: number, payload: IDataResult, realtime?: boolean) => boolean;
  }

  export interface IWGApiSensors extends IWGApiEntity {
    // All sensors: regular and manual, Key'd by their internal_name
    sensors: ISensor[];
    // HashMap, by Internal_name
    sensors_name: { [internal_name: string]: ISensor };
    // Manual Entries' sensors
    manual_sensors: ISensor[];
  }

  export interface IWGApiProcesses extends IWGApiEntity {
    processes: IProcess[];
    processes_id: { [id: number]: IProcess };
  }

  export interface IWGApiBatches extends IWGApiEntity {
    batches: IBatch[];
    batches_id: { [id: number]: IBatch };
  }

  export interface IWGApiAlarms extends IWGApiEntity {
    alarms: IAlarm[];
    alarms_id: { [id: number]: IAlarm };
  }

  export class WGApiData {
    static $inject = ['$rootScope', '$translate', '$http', 'AuthService', 'DataUtils'];

    constructor(
        private $rootScope: IRootScope,
        private $translate: ng.translate.ITranslateService,
        private $http: ng.IHttpService,
        private AuthService: IAuthService,
        private DataUtils: DataUtils,
    ) {
      this.init();
    }

    // Initialize some overall variables/consts
    private UNPLACED_UNITS_PLACE_ID = -(this.AuthService.view_as_owner?.id || this.AuthService.user?.id);
    public ALL_UNITS_PLACE_ID = -2; // 1 is pedro.costa, 5 is fabio.goncalves. 2-4 are not used
    private current_data_user_id: number = null;
    private update_changed_data_timer: NodeJS.Timeout;
    private process_data_timer: NodeJS.Timeout;

    public last_data_received = 1;
    public last_data_request = 1;

    public AllReady = false;


    public WGPlaces: IWGApiPlaces = {
      places: [],
      places_id: {},

      ready: false,
      changed: true,
      changed_locally: false,
      loading: false,
      processing: false,
      retries: 0,

      reset: () => { // Places
        this.WGPlaces.places = emptyOrCreateArray(this.WGPlaces.places);
        this.WGPlaces.places_id = emptyOrCreateDict(this.WGPlaces.places_id);
        this.WGPlaces.ready = false;
        this.WGPlaces.changed = true;
        this.WGPlaces.loading = false;
        this.WGPlaces.processing = false;
      },

      // Places have changed. Redo it's dependencies and dependents
      process_data: () => { // Places
        if (this.WGPlaces.loading) {
          return;
        }
        this.WGPlaces.processing = true;
        this.WGPlaces.changed_locally = false;
        this.WGPlaces.ready = false;

        // if (WG_debug) console.time("WGPlaces-process_data");

        // Reconnect units and places
        this.link_units_and_places(); // This call can change visible units. Refresh filters

        // Re-run other functions dependent on these connections


        // if (WG_debug) console.timeEnd("WGPlaces-process_data");
        this.WGPlaces.processing = false;
        this.WGPlaces.ready = true

        this.update_ready_status();
        this.DataUtils.global_apply_schedule("data-changed");
        this.$rootScope.$broadcast('places_updated');
      },

      // Processes passed object as Place
      parse: (entry: IPlace): IPlace => {
        if (!entry.id) {
          console.error("Wrong Place object:", entry);
          return null;
        }
        let _place = _.pick(entry, ['id', 'name', 'name_sref', 'description', 'dynamic_fields', 'owner.id', 'owner.username', 'units']) as IPlace;

        // Add required fields not arriving from API
        this.DataUtils.normalize_places_info(_place);

        this.AuthService.anonymize_entity("Place", _place);

        return _place;
      },

      // Parses an object as Place and merges onto existing this.WGPlaces
      merge_entry: (entry: IPlace, process_afterwards = false): IPlace => {
        let _place = this.WGPlaces.parse(entry);

        if (!_place || !_place.id || _place.type !== 'place') {
          console.warn("Merging wrong Place!", _place);
          return null;
        }

        if (this.WGPlaces.places_id[_place.id]) {
          // Already exists. Just update one reference's data
          // if (WG_debug) {
          //   // Print differences that arrived from server
          //   let diffs = _.reduce(_place, (result, value, key) => {
          //     if (['units', 'counts'].includes(key))
          //       return result;
          //     return _.isEqual(value, this.WGPlaces.places_id[_place.id][key]) ? result : result.concat(key);
          //   }, []);
          //
          //   if (diffs.length)
          //     console.info("Place Changed: ", {diffs: diffs, from: _.clone(this.WGPlaces.places_id[_place.id]), to: _.clone(_place)});
          // }

          _.assign(this.WGPlaces.places_id[_place.id], _place);
        } else {
          this.WGPlaces.places.push(_place);
          this.WGPlaces.places_id[_place.id] = _place;
        }
        if (process_afterwards) {
          this.WGPlaces.process_data();
        } else {
          this.WGPlaces.changed_locally = true;
        }
        return this.WGPlaces.places_id[_place.id];
      },

      update: () => { // Places
        if (this.WGPlaces.loading) {
          return;
        }

        // if (WG_debug) console.time("WGPlaces-update");

        this.WGPlaces.ready = false;
        this.WGPlaces.changed = false;
        this.WGPlaces.loading = true;
        this.AllReady = false;

        // Used to detect removals
        let _place_ids = [];

        let get_and_merge = (page = 1, force_last = false) => {
          this.last_data_request = Date.now();
          if (WG_debug) console.info('WGApiData, getting Places, page:', page);
          // When no response arrives in 1 min,
          clearTimeout(this.WGPlaces.timer);
          this.WGPlaces.timer = setTimeout(() => {
            console.error("Getting Places timedout!!! Retrying")
            this.WGPlaces.loading = false;
            this.WGPlaces.update();
          }, 65000);


          this.$http.get('api/dashboard/places/?page_size=1000&page=' + page, {timeout: 45000}).then(
              (response) => { // onSuccess
                // if (WG_debug) console.timeLog("WGPlaces-update");

                clearTimeout(this.WGPlaces.timer);


                // Merge with the existing places list, don't replace it.
                // @ts-ignore
                _.forEach(response.data.results, (_entry: IPlace) => {
                  if (!_entry.id) return;

                  _place_ids.push(_entry.id);

                  this.WGPlaces.merge_entry(_entry);
                });

                // If response.data arrived with "next", request next page
                if (!force_last && response.data['next']) {
                  let _page = response.data['next']?.split('page=')[1]?.split('&')[0];
                  if (_page != page + 1) {
                    if (WG_debug) console.warn("Getting Places page error!", response.data['next'], page);
                    force_last = true;
                  }
                  get_and_merge(page + 1, force_last);
                  return;
                }

                this.WGPlaces.retries = 0;

                // Remove deleted entries
                _.forEach(this.WGPlaces.places_id, (_place, _id) => {
                  if (!_place) {
                    return;
                  }
                  if (_place_ids.includes(_place.id)) {
                    return;
                  }
                  if (_place.id < 0) {
                    // Don't remove virtual-places
                    return;
                  }

                  console.info("Place Changed. Deleted: ", {del: _.clone(_place)});
                  _.remove(this.WGPlaces.places, {id: _place.id});
                  delete this.WGPlaces.places_id[_place.id];
                });

                console.log("Updated Places: ", _.size(this.WGPlaces.places));

                this.WGPlaces.loading = false;

                // if (WG_debug) console.timeEnd("WGPlaces-update");
                this.WGPlaces.process_data();
                return;
              },
              (reason) => { // onError
                this.WGPlaces.ready = true;
                this.WGPlaces.changed = true;
                this.WGPlaces.loading = false;
                console.error('Failed getting places. Retrying', this.WGPlaces.retries, reason);

                clearTimeout(this.WGPlaces.timer);
                if (this.WGPlaces.retries++ <= 3) {
                  setTimeout(() => {
                    this.WGPlaces.update();
                  }, 2000);
                }

                // if (WG_debug) console.timeEnd("WGPlaces-update");
                return;
              }
          );
        }
        get_and_merge(1);

        return;
      },

      update_singular: (id): void => { // Places
        if (!id || id < 0) {
          console.warn("Updating wrong Place!", id);
          return;
        }
        this.$http.get('api/dashboard/places/' + id + '/', {timeout: 45000}).then(
            (response) => { // onSuccess
              this.WGPlaces.merge_entry(response.data as IPlace, true);
              console.log("Updated Place", id);
              return;
            },
            (reason) => { // onError
              console.error("Error updating Place: ", reason);
              return;
            });
        return;
      },

      delete: (id: number, callback) => { // Places
        let _place = this.WGPlaces.places_id[id];
        if (!_place) {
          console.warn("Place doesn't exist to be deleted:", id);
          callback?.('error');
          return;
        }

        this.WGPlaces.changed_locally = true;
        if (!_.isEmpty(_place.units))
          this.WGUnits.changed_locally = true;

        this.process_data_soon(3000);
        this.$http.delete('api/dashboard/places/' + _place.id + '/').then(
            (response) => {
              if (response.status >= 200 && response.status < 300) {
                console.log("WGPlace Delete SUCCESS");
                this.WGPlaces.delete_local(id);
                callback?.('success');
              } else {
                console.warn("WGPlace Delete Failed!", response);
                callback?.('error');
              }
            }, (response) => {
              console.error("WGPlace Delete Failed", response);
              callback?.('error');
            }
        ).finally(() => {
          this.process_data_soon();
        });
      },

      delete_local: (id: number) => { // Places
        let _entry = this.WGPlaces.places_id[id];

        if (!_entry) {
          if (WG_debug) console.warn("No Place detected with id:", id);
          return
        }

        if (WG_debug) console.info("Deleting Place locally", _entry);

        // Delete from the main data-structure
        delete this.WGPlaces.places_id[id];
        _.remove(this.WGPlaces.places, {id: id});

        this.WGPlaces.changed_locally = true;
        this.process_data_soon(200, true);
      },
    };

    public WGUnits: IWGApiUnits = {
      units: [],
      units_id: {},

      ready: false,
      changed: true,
      changed_locally: false,
      loading: false,
      processing: false,
      retries: 0,

      reset: () => { // Units
        this.WGUnits.units = emptyOrCreateArray(this.WGUnits.units);
        this.WGUnits.units_id = emptyOrCreateDict(this.WGUnits.units_id);
        this.WGUnits.ready = false;
        this.WGUnits.changed = true;
        this.WGUnits.loading = false;
        this.WGUnits.processing = false;
      },

      // Units have changed. Redo it's dependencies and dependents
      process_data: () => { // Units
        if (this.WGUnits.loading) {
          return;
        }
        this.WGUnits.processing = true;
        this.WGUnits.changed_locally = false;
        this.WGUnits.ready = false;

        // if (WG_debug) console.time("WGUnits-process_data");
        // Reconnect them to other places
        this.link_devices_and_units();
        this.link_units_and_places();
        this.link_processes_and_units();

        // Re-run other functions dependent on these connections

        this.link_notifications_and_units_devices();

        this.get_units_last_values(this.WGUnits.units);

        this.update_units_status(this.WGPlaces.places);

        // if (WG_debug) console.timeEnd("WGUnits-process_data");

        this.WGUnits.processing = false;
        this.WGUnits.ready = true;

        this.update_ready_status();
        this.DataUtils.global_apply_schedule("data-changed");
        this.$rootScope.$broadcast('units_updated');
      },

      // Processes passed object as Unit
      parse: (entry: IUnit): IUnit => {
        if (!entry.id) {
          console.error("Wrong Unit object:", entry);
          return null;
        }
        let _unit = _.pick(entry, ['id', 'name', 'name_sref', 'description', 'unit_type', 'config_fields', 'dynamic_fields',
          'owner.id', 'owner.username', 'place.id', 'process.id', 'protocol', 'protocols', 'devices']) as IUnit;

        // Add required fields not arriving from API
        this.DataUtils.normalize_units_info(_unit);

        this.AuthService.anonymize_entity("Unit", _unit);

        return _unit;
      },

      // Parses an object as Unit and merges onto existing this.WGUnits
      merge_entry: (entry: IUnit, process_afterwards = false): IUnit => {
        let _unit = this.WGUnits.parse(entry);

        if (!_unit || !_unit.id || _unit.type !== 'unit') {
          console.warn("Merging wrong Unit!", _unit);
          return;
        }

        if (this.WGUnits.units_id[_unit.id]) {
          // Already exists. Just update one reference's data
          // if (WG_debug) {
          //   // Print differences that arrived from server
          //   let diffs = _.reduce(_unit, (result, value, key) => {
          //     if (['place'].includes(key)) {
          //       if (!value && this.WGUnits.units_id[_unit.id][key]?.id < 0) // Unplaced units arrive without Place
          //         return result;
          //       return (value?.id == this.WGUnits.units_id[_unit.id][key]?.id) ? result : result.concat(key);
          //     }
          //     if (['process'].includes(key)) {
          //       return (value?.id == this.WGUnits.units_id[_unit.id][key]?.id) ? result : result.concat(key);
          //     }
          //     if (['alarms'].includes(key)) {
          //       return _.isEqual(value?.user_notifications, this.WGUnits.units_id[_unit.id][key]?.user_notifications) ? result : result.concat(key);
          //     }
          //     if (['devices'].includes(key))
          //       return (value?.[0]?.id == this.WGUnits.units_id[_unit.id][key]?.[0]?.id) ? result : result.concat(key);
          //     return _.isEqual(value, this.WGUnits.units_id[_unit.id][key]) ? result : result.concat(key);
          //   }, []);
          //
          //   if (diffs.length)
          //     console.info("Unit Changed: ", {diffs: diffs, from: _.clone(this.WGUnits.units_id[_unit.id]), to: _.clone(_unit)});
          // }

          _.assign(this.WGUnits.units_id[_unit.id], _unit);
        } else {
          this.WGUnits.units.push(_unit);
          this.WGUnits.units_id[_unit.id] = _unit;
        }
        if (process_afterwards) {
          this.WGUnits.process_data();
        } else {
          this.WGUnits.changed_locally = true;
        }

        return this.WGUnits.units_id[_unit.id];
      },

      update: () => { // Units
        // Ensure only one request is underway, with a timeout to clear flag and retry
        if (this.WGUnits.loading) {
          return;
        }

        // if (WG_debug) console.time("WGUnits-update");

        this.WGUnits.ready = false;
        this.WGUnits.changed = false;
        this.WGUnits.loading = true;
        this.AllReady = false;

        // Used to detect removals
        let _unit_ids = [];

        let get_and_merge = (page = 1, force_last = false) => {
          if (WG_debug) console.info('WGApiData, getting Units, page:', page);
          // When no response arrives in 1 min,
          clearTimeout(this.WGUnits.timer);
          this.WGUnits.timer = setTimeout(() => {
            console.error("Getting Units timedout!!! Retrying")
            this.WGUnits.loading = false;
            this.WGUnits.update();
          }, 65000);

          this.$http.get('api/dashboard/units/?active=true&page_size=1000&page=' + page, {timeout: 45000}).then(
              (response) => { // onSuccess
                // if (WG_debug) console.timeLog("WGUnits-update");

                clearTimeout(this.WGUnits.timer);

                // Merge with the existing units list, don't replace it.
                // @ts-ignore
                _.forEach(response.data.results, (_entry: IUnit) => {
                  if (!_entry.id) return;

                  _unit_ids.push(_entry.id);

                  this.WGUnits.merge_entry(_entry);
                });

                // If response.data arrived with "next", request next page
                if (!force_last && response.data['next']) {
                  let _page = response.data['next']?.split('page=')[1]?.split('&')[0];
                  if (_page != page + 1) {
                    if (WG_debug) console.warn("Getting Units page error!", response.data['next'], page);
                    force_last = true;
                  }
                  get_and_merge(page + 1, force_last);
                  return;
                }

                this.WGUnits.retries = 0;

                // This removes deleted entries
                _.forEach(this.WGUnits.units_id, (_unit, _id) => {
                  if (!_unit) {
                    return;
                  }
                  if (_unit_ids.includes(_unit.id)) {
                    return;
                  }
                  if (_unit.id < 0) {
                    // Don't remove virtual-units
                    return;
                  }

                  console.info("Unit Changed. Deleted: ", {del: _.clone(_unit)});
                  _.remove(this.WGUnits.units, {id: _unit.id});
                  delete this.WGUnits.units_id[_unit.id];
                });

                console.log("Updated Units: ", _.size(this.WGUnits.units));

                this.WGUnits.loading = false;

                // if (WG_debug) console.timeEnd("WGUnits-update");
                this.WGUnits.process_data();
                return;
              },
              (reason) => { // onError
                this.WGUnits.ready = true;
                this.WGUnits.changed = true;
                this.WGUnits.loading = false;
                console.error('Failed getting units. Retrying', this.WGUnits.retries, reason);

                clearTimeout(this.WGUnits.timer);
                if (this.WGUnits.retries++ <= 3) {
                  setTimeout(() => {
                    this.WGUnits.update();
                  }, 2000);
                }

                // if (WG_debug) console.timeEnd("WGUnits-update");
                return;
              }
          );
        }
        get_and_merge(1);

        return;
      },

      update_singular: (id) => { // Units
        if (!id || id < 0) {
          console.warn("Updating wrong Unit!", id);
          return;
        }
        this.$http.get<IUnit>('api/dashboard/units/' + id + '/', {timeout: 60000}).then(
            (response) => { // onSuccess
              this.WGUnits.merge_entry(response.data, true);
              console.log("Updated Unit", id);
              return;
            },
            (reason) => { // onError
              console.error("Error updating Unit: ", reason);
              // Promise.reject(reason);
              return;
            });
        return;
      },

      delete: (id: number, callback) => { // Units

        let _unit = this.WGUnits.units_id[id];
        if (!_unit) {
          console.warn("Unit doesn't exist to be deleted:", id);
          callback?.('error');
          return;
        }

        this.WGUnits.changed_locally = true;
        if (_unit.place?.id > 0) {
          this.WGPlaces.changed_locally = true;
        }
        if (_unit.process) {
          this.WGProcesses.changed_locally = true;
        }
        if (!_.isEmpty(_unit.devices)) {
          this.WGDevices.changed_locally = true;
        }
        this.process_data_soon(3000);

        this.$http.delete('api/dashboard/units/' + _unit.id + '/').then(
            (response) => {
              if (response.status >= 200 && response.status < 300) {
                console.log("WGUnit Delete SUCCESS");
                this.WGUnits.delete_local(id);
                callback?.('success');
              } else {
                console.warn("WGUnit Delete Failed!", response);
                callback?.('error');
              }
            }, (response) => {
              console.error("WGUnit Delete Failed", response);
              callback?.('error');
            }
        ).finally(() => {
          this.process_data_soon();
        });
      },

      delete_local: (id: number) => { // Units
        let _entry = this.WGUnits.units_id[id];

        if (!_entry) {
          if (WG_debug) console.warn("No Unit detected with id:", id);
          return
        }

        if (WG_debug) console.info("Deleting Unit locally", _entry);

        // Remove .devices.unit if exists
        _.forEach(_entry.devices, (_device) => {
          if (_device?.unit?.id == _entry.id) {
            _device.unit = null;
            this.WGDevices.changed_locally = true;
          }
        });

        // Delete from the main data-structure
        delete this.WGUnits.units_id[id];
        _.remove(this.WGUnits.units, {id: id});

        this.WGUnits.changed_locally = true;
        this.process_data_soon(200, true);
      },
    }

    public WGDevices: IWGApiDevices = {
      devices: this.$rootScope.userDevices || [],
      devices_id: {},
      devices_uuid: this.$rootScope.userDevicesUUID || {},

      ready: false,
      changed: true,
      changed_locally: false,
      loading: false,
      processing: false,
      retries: 0,
      there_are_unplaced: false,
      has_sensors: false,

      reset: () => { // Devices
        this.WGDevices.devices = emptyOrCreateArray(this.WGDevices.devices);
        this.WGDevices.devices_id = emptyOrCreateDict(this.WGDevices.devices_id);
        this.WGDevices.devices_uuid = emptyOrCreateDict(this.WGDevices.devices_uuid);
        this.WGDevices.ready = false;
        this.WGDevices.changed = true;
        this.WGDevices.loading = false;
        this.WGDevices.processing = false;

        this.$rootScope.lastKnownMessages = emptyOrCreateDict(this.$rootScope.lastKnownMessages); // Legacy
        this.$rootScope.lastSensorValues = emptyOrCreateDict(this.$rootScope.lastSensorValues); // Legacy
      },

      // Devices have changed. Redo its dependencies and dependents
      process_data: () => { // Devices
        if (this.WGDevices.loading) {
          return;
        }
        this.WGDevices.processing = true;
        this.WGDevices.changed_locally = false;
        this.WGDevices.ready = false;

        console.log("Devices process_data");


        // Merge it with other data
        this.link_devices_and_sensors();

        this.link_devices_and_units(); // This might create Units
        this.link_units_and_places();

        // Re-run other functions dependent on these connections

        this.link_notifications_and_units_devices();

        this.link_alarms_and_devices();
        this.get_units_last_values(this.WGUnits.units);

        this.update_units_status(this.WGPlaces.places);

        // if (WG_debug) console.timeEnd("WGDevices-process_data");

        this.WGDevices.processing = false;
        this.WGDevices.ready = true;

        this.update_ready_status();

        this.DataUtils.global_apply_schedule("data-changed");
        this.$rootScope.$broadcast('devices_updated');
      },

      // Processes passed object as Device
      parse: (entry: IDevice): IDevice => {
        if (!entry.id || !entry.uuid) {
          console.error("Wrong Device object:", entry);
          return null;
        }
        let _device = _.pick(entry, ['id', 'name', 'name_sref', 'iid', 'uuid', 'internal_name', 'sn', 'model',
          'description', 'unit_type', 'path',
          'configs', 'last_known_message', 'management_active', 'owner.id', 'owner.username', 'groups',
          'unit.id']) as IDevice;


        _device.configs = parseData(_device.configs, {}) as IDeviceConfigs;

        this.DataUtils.parse_device_info(_device);


        this.AuthService.anonymize_entity("Device", _device);

        return _device;
      },

      // Parses an object as Unit and merges onto existing this.WGUnits
      merge_entry: (entry: IDevice, process_afterwards = false): IDevice => {
        let _device = this.WGDevices.parse(entry);

        if (!_device || !_device.id || _device.type !== 'device') {
          console.warn("Merging wrong Device!", _device);
          return;
        }

        if (this.WGDevices.devices_id[_device.id]) {
          // Already exists. Just update one reference's data
          // if (WG_debug) {
          //   // Print differences that arrived from server
          //   let diffs = _.reduce(_device, (result, value, key) => {
          //     if (['ignored_key'].includes(key))
          //       return result;
          //     return _.isEqual(value, this.WGDevices.devices_id[_device.id][key]) ? result : result.concat(key);
          //   }, []);
          //
          //   if (diffs.length)
          //     console.info("Device Changed: ", {diffs: diffs, from: _.clone(this.WGDevices.devices_id[_device.id]), to: _.clone(_device)});
          // }

          _.assign(this.WGDevices.devices_id[_device.id], _device);

        } else {
          this.WGDevices.devices.push(_device);
          this.WGDevices.devices_id[_device.id] = _device;
          this.WGDevices.devices_uuid[_device.uuid] = _device;
        }
        this.DataUtils.normalize_device_info(this.WGDevices.devices_id[_device.id]);
        if (process_afterwards) {
          this.WGDevices.process_data();
        } else {
          this.WGDevices.changed_locally = true;
        }
        return this.WGDevices.devices_id[_device.id];
      },

      update: () => { // Devices
        if (this.WGDevices.loading) {
          return;
        }

        // if (WG_debug) console.time("WGDevices-update");

        this.WGDevices.ready = false;
        this.WGDevices.changed = false;
        this.WGDevices.loading = true;
        this.AllReady = false;

        let _device_ids = [];

        let get_and_merge = (page = 1, force_last = false) => {
          if (WG_debug) console.info('WGApiData, getting Devices, page:', page);

          // When no response arrives in 1 min,
          clearTimeout(this.WGDevices.timer);
          this.WGDevices.timer = setTimeout(() => {
            console.error("Getting devices timedout!!! Retrying")
            this.WGDevices.loading = false;
            this.WGDevices.update();
          }, 65000);

          this.$http.get('api/dashboard/devices/simple/?page_size=1000&page=' + page, {timeout: 55000}).then(
              (response) => { // onSuccess
                // if (WG_debug) console.timeLog("WGDevices-update");

                clearTimeout(this.WGDevices.timer);

                this.$rootScope.lastKnownMessages = emptyOrCreateDict(this.$rootScope.lastKnownMessages);
                this.$rootScope.lastSensorValues = emptyOrCreateDict(this.$rootScope.lastSensorValues);

                this.last_data_received = Date.now();

                // Used to detect removals

                // Merge with the existing devices list, don't replace it.
                _.forEach(response.data as IDevice[], (_entry) => {
                  if (!_entry.id) return;

                  _device_ids.push(_entry.id);

                  this.WGDevices.merge_entry(_entry);
                });

                // If response.data arrived with "next", request next page
                if (!force_last && response.data['next']) {
                  let _page = response.data['next']?.split('page=')[1]?.split('&')[0];
                  if (_page != page + 1) {
                    if (WG_debug) console.warn("Getting Devices page error!", response.data['next'], page);
                    force_last = true;
                  }
                  get_and_merge(page + 1, force_last);
                  return;
                }

                this.WGDevices.retries = 0;

                // Remove deleted entries
                _.forEach(this.WGDevices.devices_id, (_device, _id) => {
                  if (!_device) {
                    return;
                  }
                  if (_device_ids.includes(_device.id)) {
                    return;
                  }

                  console.info("Device Changed. Deleted: ", {del: _.clone(_device)});
                  _.remove(this.WGDevices.devices, {id: _device.id});
                  delete this.WGDevices.devices_id[_device.id];
                  delete this.WGDevices.devices_uuid[_device.uuid];
                });

                console.log("Updated Devices: ", _.size(this.WGDevices.devices_id));

                this.$rootScope.userDevices = this.WGDevices.devices; // Legacy
                this.$rootScope.userDevicesUUID = this.WGDevices.devices_uuid; // Legacy

                this.WGDevices.loading = false;

                // if (WG_debug) console.timeEnd("WGDevices-update");
                this.WGDevices.process_data();
                return;
              },
              (reason) => { // onError
                this.WGDevices.ready = true;
                this.WGDevices.changed = true;
                this.WGDevices.loading = false;
                console.error('Failed getting devices. Retrying', this.WGDevices.retries, reason.statusText, reason);

                clearTimeout(this.WGDevices.timer);
                if (this.WGDevices.retries++ <= 3) {
                  setTimeout(() => {
                    this.WGDevices.update();
                  }, 2000);
                }

                // if (WG_debug) console.timeEnd("WGDevices-update");
                return;
              });
        }
        get_and_merge(1);
        return;
      },

      update_singular: (id = 0, uuid = null) => { // Devices
        if (!uuid && id > 0) {
          uuid = this.WGDevices.devices_id?.[id]?.uuid || null;
        }

        if (!uuid) {
          console.warn("Updating wrong Device!", id);
          return;
        }
        this.$http.get('api/dashboard/devices/simple/?page_size=1000&uuid=' + uuid, {timeout: 45000}).then(
            (response) => { // onSuccess
              if (response?.data?.[0]) {
                this.WGDevices.merge_entry(response.data[0] as IDevice, true);
                console.log("Updated Device", id);
              } else {
                console.warn("Failed to updated Device. Deleted? Updating all", response.data);
                setTimeout(() => {
                  this.WGDevices.update();
                }, 500);
              }
              return;
            }
            , (reason) => { // onError
              console.error("Error updating Device: ", reason);
              return;
            });
        return;
      },

      // Schedule all device+LKM update to the given timestamp, if non schedule exists before that
      update_schedule: (timestamp: number = null) => {
        // if (!device_uuid || !stream || !msg_timestamp || !payload) {
        //   return;
        // }
      },

      // Schedule a given device+LKM update to the given timestamp, if no schedule exists before that
      update_singular_schedule: (id: number, timestamp: number = null) => {
      },

      // TODO: Implement. What is a "Device Delete"? Unpair?
      // TODO: Call the DEL API here. PATCH, {active: false} ?
      delete: (id: number) => {  // Devices
        console.warn("Device Delete not acceptable!", id);
        this.update_changed_data();
      },

      /**
       * Processes a data message, storing the result in root.lkm and root.LSV (per parameter)
       * Also updates devices status if needed.
       * @param device_uuid
       * @param stream
       * @param msg_timestamp
       * @param payload
       * @param realtime If this LKM arrived in a realtime string, or from storage
       */
      processLastKnownMessage: (device_uuid, stream, msg_timestamp, payload, realtime = false) => {
        if (!device_uuid || !stream || !msg_timestamp || !payload) {
          return;
        }

        if (!this.WGDevices.ready && !this.WGDevices.processing) {
          return;
        }
        if (!this.WGSensors.ready && !this.WGSensors.processing) {
          return;
        }
        // if (WG_debug) console.debug("Processing LKM", device_uuid, stream, msg_timestamp);

        let new_parameter_received = false;
        let _current_time = new Date().getTime();
        // Ignore very old data - >3y
        // if (msg_timestamp < _current_time - 3 * 366 * 24 * 60 * 60 * 1000) {
        //   return;
        // }

        let device = this.WGDevices.devices_uuid[device_uuid];

        if (!device) {
          device = _.find(this.WGDevices.devices, {uuid: device_uuid});
          if (!device) {
            // We can receive MQTT_data from devices that we do not have permission to see. Just ignore
            if (WG_debug) console.warn("We received an LKM of a device not on our list", device_uuid, stream);
            return;
          } else {
            if (WG_debug) console.error("Device not found on UUID dict, but on array! What?!?", device.model, device.sn, device.name);
          }
        }

        if (!this.$rootScope.lastKnownMessages[device_uuid]) {
          if (device.lkm) {
            this.$rootScope.lastKnownMessages[device_uuid] = device.lkm;
          } else {
            device.lkm = this.$rootScope.lastKnownMessages[device_uuid] = {};
          }
        }
        // Define device's LKM if it doesn't exist or is different.
        if (!device.lkm || device.lkm !== this.$rootScope.lastKnownMessages[device_uuid]) {
          delete device.lkm;
          device.lkm = this.$rootScope.lastKnownMessages[device_uuid] || {};
        }

        // When not admin, save NULL when a useless stream arrives.
        // if (!this.AuthService.canAccess('admin') && REMOVED_STREAMS.includes(stream.toUpperCase())) {
        if (REMOVED_STREAMS.includes(stream.toUpperCase())) {
          // delete this.$rootScope.lastKnownMessages[device_uuid][stream];
          this.$rootScope.lastKnownMessages[device_uuid][stream] = null;
          return;
        }

        if (_.includes(SIMULATOR_STREAMS, stream) && msg_timestamp < _current_time - 3 * 24 * 60 * 60 * 1000) {
          // if (WG_debug) console.info("FERMENT_SIMULATOR older than 3 days. Ignored", payload);
          return;
        }
        this.AuthService.anonymize_lkm(stream, payload);

        // First time the sensor receives this stream
        if (!angular.isDefined(this.$rootScope.lastKnownMessages[device_uuid][stream])) {
          this.$rootScope.lastKnownMessages[device_uuid][stream] = {timestamp: 0};
        }
        if (msg_timestamp > this.$rootScope.lastKnownMessages[device_uuid][stream].timestamp) {
          if (stream.toLowerCase() === 'sleep' && payload['infos'] && angular.isDefined(payload['infos']['sleep'])) {
            // for sensors with acquisition_time the sleep value sent is less than the actual interval between reads
            // so on the dashboard we should consider the first calculated sleep value.
            // TODO: this changes received LKM. Confirm if OK!!
            payload.value = Math.round(payload['infos']['sleep']);
          }

          // if (stream.toLowerCase() === 'configs' && payload.value) {
          //   if (WG_debug) console.log("Received LKM configs:", payload.value);
          // }

          this.$rootScope.lastKnownMessages[device_uuid][stream].timestamp = msg_timestamp;
          this.$rootScope.lastKnownMessages[device_uuid][stream].topic = stream;
          this.$rootScope.lastKnownMessages[device_uuid][stream].device = device_uuid;
          this.$rootScope.lastKnownMessages[device_uuid][stream].payload = payload;

          if (stream.toUpperCase() === 'AP/WPS_ENROLLEE_SEEN' && this.WGDevices.ready) {
            let device: Partial<IDevice> = parseData(payload['device']);

            if (WG_debug) console.log("New sensor was paired. Get it", device);

            if (device && (device.id > 0 || device.uuid)) {
              this.WGDevices.update_singular(device.id, device.uuid);
            } else {
              this.WGDevices.changed = true;
              this.update_changed_data_soon(3000);
            }
          }
        }

        // Do not process useless topics, not even for Admins
        // if (REMOVED_STREAMS.includes(stream.toUpperCase())) {
        //   return;
        // }

        // Now, process every parameter received to it's own rootScope structure (Allows easier access to show on HTML)


        if (!this.$rootScope.lastSensorValues[device_uuid]) {
          // this.$rootScope.lastSensorValues[device_uuid] = _target_dev.last_values = _target_dev.last_values || {};
          if (device.last_values) {
            this.$rootScope.lastSensorValues[device_uuid] = device.last_values;
          } else {
            console.log("First device data", device.model, device.sn, device.name);
            device.last_values = this.$rootScope.lastSensorValues[device_uuid] = emptyOrCreateDict(device.last_values);
            device.last_card_values = emptyOrCreateDict(device.last_card_values);
            device.cards_count = 0;
          }
        } else if (device.last_values !== this.$rootScope.lastSensorValues[device_uuid]) {
          if (WG_debug && _.size(device.last_values) > 0) console.warn("LSV exists but is different. What happened?", _.clone(device.last_values), _.clone(this.$rootScope.lastSensorValues[device_uuid]));
          device.last_values = this.$rootScope.lastSensorValues[device_uuid]; // Recovering previously calculated LSV
        }

        let _is_manual = false;

        // Process sensors related with received stream
        _.forEach(this.WGSensors.sensors_name, (_sensor, _internal_name) => {
          if (!_sensor || stream !== _sensor.stream) {
            return;
          }

          try {
            // Fail fast. Ignore if MSG is older than what we already have.
            if (device.last_values[_internal_name]?.timestamp >= msg_timestamp) {
              // if (WG_debug) console.debug("LSV already updated");
              return;
            }

            // Confirm this LKM has data regarding this specific parameter
            let _sub_query = _sensor.configs?.sub_query;
            let _this_val = this.DataUtils.get_value_from_lkm(payload, _sensor, _sub_query, device);
            if (_.isNil(_this_val)) {
              return;
            }
            // PreProcess to fix some WG specific bugs
            let _temp = this.DataUtils.preprocess_data(_sensor, [_this_val], device);
            if (_.isNil(_temp?.[0])) {
              return;
            } else {
              _this_val = _temp[0];
            }

            // All good. Let's parse and update

            if (_sensor.configs?.manual) {
              _is_manual = true;
            }

            // First time the sensor receives this parameter
            if (_.isNil(this.$rootScope.lastSensorValues[device_uuid][_internal_name])) {
              device.last_values[_internal_name] = this.DataUtils.get_sensor_default_values(_internal_name);
              new_parameter_received = true;
            }
            let _root_LSV = this.$rootScope.lastSensorValues[device_uuid][_internal_name]

            // Fill with the received data
            _root_LSV.timestamp = msg_timestamp;

            if (_root_LSV.val_orig !== _this_val)
              _root_LSV.val_orig = _this_val; // Stores the original value


            // Converts and get val_txt and val_numeric
            let _val_txt = _this_val;
            let _val_numeric = NaN;

            if (_internal_name.startsWith("FERMENT_SIMULATOR") && device.model.startsWith("bp")
                && !this.AuthService.canAccess('admin')) {
              if (WG_debug) console.log("Not showing BPs FERMENT_SIMULATOR", _internal_name, _this_val);
              return;
            }

            if (_internal_name === "WAKEUP_REASON") {
              _val_numeric = _this_val as number;
              if (_.isNil(_this_val)) {
                _val_txt = "-";
              } else {
                _val_txt = this.DataUtils.convert_wakeup_reason(_val_numeric, device);
              }
            } else if (_internal_name.endsWith('_rgb')) {
              if (_.isString(_this_val) && _.size(_this_val) == 7 && _this_val.startsWith('#')) {
                _root_LSV.color = _this_val;
              }
            } else {
              if (!_.isNil(_this_val) && !isNaN(+_this_val)) {
                // Converts a string to a number, if possible
                _val_numeric = +_this_val;
                _root_LSV.color = this.DataUtils.get_value_color(device, _sensor, _val_numeric);
                if (_root_LSV.color) {
                  if (WG_debug) console.log("Got color:", _root_LSV.color);
                  _root_LSV.style = {'background-color': _root_LSV.color};
                }

                if (!_.isNil(_sensor.conversion?.id)) {
                  _val_numeric = wg.convert(_val_numeric, _sensor.conversion?.id, this.$rootScope.lastKnownMessages[device_uuid]);
                  // console.log("Converted to:", _this_LSV.val_numeric, _this_LSV);
                }
                let _decimals = 3; // Should never be required, decimals should always be defined
                if (!_.isNil(_sensor.configs.decimals)) {
                  _decimals = _sensor.configs.decimals;
                }
                if (_.isNil(_val_numeric)) {
                  if (WG_debug) console.log("Error converting value. val_numeric is Null", _val_numeric, _this_val, _sensor.conversion, device.sn)
                } else {
                  _val_txt = _val_numeric.toFixed(_decimals);
                }
              }
              if (_internal_name.startsWith("FERMENT_SIMULATOR") && _internal_name.endsWith('_endTime')) {
                if (new Date(_val_numeric) < new Date()) {
                  _val_txt = this.$translate.instant("app.fermentation_prediction.fermentation_end.PAST");
                } else {
                  _val_txt = this.$translate.instant("app.fermentation_prediction.fermentation_end.PREDICTING");
                  // _val_txt = Highcharts.dateFormat('%m/%d %Hh', _val_numeric);
                }
              }
            }

            _root_LSV.val = _val_txt;
            _root_LSV.val_numeric = _val_numeric;

            // Must update the LKM value so that old lkm has values converted and not the original.
            // Because if not, it will plot unconverted values
            // if (_sub_query && !Number.isFinite(payload.value)) {

            // if (Number.isFinite(_root_LSV.val_numeric)) {
            //   if (Number.isFinite(payload.value)) {
            //     payload.value = _root_LSV.val_numeric;
            //   } else {
            //     if (_sub_query?.[0]) {
            //       try {
            //         payload.value[_sub_query[0]] = _root_LSV.val_numeric;
            //       } catch (e) {
            //         console.warn('Error. ', e, _sub_query[0], _root_LSV.val_numeric, payload.value);
            //       }
            //     }
            //   }
            // }

            if (CARDVIEW_SENSORS_LIST.includes(_internal_name)
                && _internal_name != 'PRESS_VOLUME_TOTALIZER'
                && !IGNORED_STREAMS.includes(stream.toUpperCase())
                && !COMMANDS_STREAMS.includes(stream.toUpperCase())
                && !AUXILIARY_STREAMS.includes(stream.toUpperCase())
                && !stream.toUpperCase().endsWith('ALARM')
                && !_is_manual) {
              if (!device.last_read || device.last_read < msg_timestamp) {
                device.last_read = msg_timestamp;
              }
            }

          } catch (e) {
            console.error('Error processing LKM', e, _sensor, this.$rootScope.lastKnownMessages[device_uuid][stream]);
          }
        })

        if (SMARTBOX_MODELS.includes(device.model)
            && SMARTBOX_READ_STREAMS.includes(stream.toUpperCase())
            && !_is_manual) {
          if (!device.last_read || device.last_read < msg_timestamp) {
            device.last_read = msg_timestamp;
          }
        }

        // Get last_comm information, regarding any contact from the sensor to correctly detect "OFF" state
        // Ignore downstreams and manual sensors
        // if (!IGNORED_STREAMS.includes(stream.toUpperCase())
        //     && !stream.toUpperCase().endsWith('ALARM') // Should be sent at the same time as measurements, but sometimes it's delayed
        //     && !_is_manual) {
        if (COMMANDS_STREAMS.includes(stream.toUpperCase())) {
          if (!device.last_command || device.last_command < msg_timestamp) {
            device.last_command = msg_timestamp;
          }
        } else if (IGNORED_STREAMS.includes(stream.toUpperCase())
            || stream.toUpperCase().endsWith('ALARM')) {
          //if (WG_debug) console.log('Not updated, IGNORED_STREAMS');
        } else if (_is_manual) {
          // if (device.last_comm < msg_timestamp) {
          //   device.last_comm = msg_timestamp;
          // }
          //if (WG_debug) console.log('Not updated, IGNORED_STREAMS');
        } else {
          if (!device.last_comm || device.last_comm < msg_timestamp) {
            device.last_comm = msg_timestamp;
            device.received_time = _current_time;
            if (realtime) {
              device.time_drift = device.received_time - device.last_comm;
              if (Math.abs(device.time_drift) > 30 * 1000) {
                this.$rootScope.mqtt_status_extra_2 = "Detected clock drift from device: " + device.sn + " = " + Math.round(device.time_drift / 1000) + "s";
              }
              if (this.AuthService.canAccess('dev') && Math.abs(device.time_drift) > 1 * 60 * 1000) {
                console.warn('Smartbox with a high time-drift! Delayed: ', Math.round(device.time_drift / 1000) + 's', 'id:', device.comm_status.last_gw_id, device);
              }
            }
          }
        }

        // if (!_is_manual) {
        if (stream.toUpperCase() == 'PONG') {
          this.DataUtils.update_device_status(device);
        } else {
          this.DataUtils.update_device_status_soon(device, 600, true);
        }
        // }

        if (new_parameter_received && this.WGDevices.ready && this.WGSensors.ready) {
          // if (new_parameter_received && this.WGSensors.ready) {
          clearTimeout(this.WGDevices.new_parameter_received_timer);
          this.WGDevices.new_parameter_received_timer = setTimeout(() => {
            this.$rootScope.$broadcast('new_parameter_received', device.id, stream);
          }, 500);
        }

        return true;
        // if (WG_debug) console.info("Finished registering LKMs of " + stream, _target_dev);
      },
      new_parameter_received_timer: null,
    }

    public WGSensors: IWGApiSensors = {
      sensors: [],
      sensors_name: this.$rootScope.watgridSensorNames || {},
      manual_sensors: this.$rootScope.userSensors || [],

      ready: false,
      changed: true,
      changed_locally: false,
      loading: false,
      processing: false,
      retries: 0,

      reset: () => { // Sensors
        this.WGSensors.sensors_name = emptyOrCreateDict(this.WGSensors.sensors_name);
        this.WGSensors.sensors = emptyOrCreateArray(this.WGSensors.sensors);
        this.WGSensors.manual_sensors = emptyOrCreateArray(this.WGSensors.manual_sensors);
        this.WGSensors.ready = false;
        this.WGSensors.changed = true;
        this.WGSensors.loading = false;
        this.WGSensors.processing = false;
      },

      // Sensors have changed. Reprocess
      process_data: () => { // Sensors
        if (this.WGSensors.loading) {
          return;
        }
        this.WGSensors.processing = true;
        this.WGSensors.changed_locally = false;
        this.WGSensors.ready = false;

        // if (WG_debug) console.time("WGSensors-process_data");

        _.forEach(this.WGSensors.sensors_name, (sensor, internal_name) => {

          // Get this sensor's Master_Sensor
          let _master_sensor = this.WGSensors.sensors_name[sensor.configs.masterSensor] || undefined;

          // Get missing configs from master_sensor
          sensor.name_sref = sensor.name_sref || sensor.configs.name_sref || _master_sensor?.configs.name_sref;
          // if (!sensor.name_sref
          //     && _master_sensor?.configs.name_sref
          //     && sensor.name === _master_sensor.name) {// Get name_sref from parent only if the name matches
          //   sensor.name_sref = _master_sensor?.configs.name_sref;
          // }
          if (!_.isEmpty(sensor.name_sref)) {
            sensor.name = this.$translate.instant(sensor.name_sref)
          }
          sensor.configs.name_sref = sensor.name_sref;

          if (sensor.configs.manual === true) {
            // if(sensor.configs.query && WG_debug) {
            //   console.warn("Manual query already configured!", sensor);
            // }
            _.defaults(sensor.configs, {
              query: [{"payload": "timestamp"}, "payload"], // Manual sensors are allowed to delete points. We need the whole "payload" instead of just "value"
              sub_query: '',
            })
          }

          _.defaults(sensor.configs
              , _master_sensor?.configs
              , {
                accessLevel: "admin",
                query: {"payload": ["timestamp", "value"]},
                exportQuery: ["datetime", {"payload": ["timestamp", "value"]}],
                sub_query: '',
                decimals: 3,
                graph: false,
                graph_type: 'line',
                graph_options: {},
              });

          _.defaults(sensor.configs.graph_options
              , _master_sensor?.configs.graph_options);

          if (WG_debug) {
            if (sensor.configs.sub_query?.[0]
                && !_.isEqual(sensor.configs.sub_query?.[0], sensor.configs.query?.['payload']?.[1])
                && !_.isEqual(sensor.configs.sub_query?.[0], sensor.configs.query?.['payload']?.[1]?.value)
                && !_.isEqual(sensor.configs.sub_query?.[0], sensor.configs.query?.['payload']?.[1]?.value?.[0])) {
              console.warn("Sensor with different sub_query: ", sensor.configs.sub_query, sensor.configs.query, sensor);
            }
          }
          sensor.unit = sensor.unit || _master_sensor?.unit || null;
          sensor.unit_sref = sensor.unit_sref || sensor.configs.unit_sref || null;
          if (sensor.unit_sref) {
            sensor.unit = this.$translate.instant(sensor.unit_sref)
          }
          sensor.configs.unit_sref = sensor.unit_sref;

          sensor.unit_orig = sensor.unit; // Deprecate
          // sensor.unit_orig_sref = sensor.unit_sref; // Deprecate
          sensor.configs.decimals_orig = sensor.configs.decimals; // Deprecate

          sensor.admin = (sensor.configs.accessLevel === 'admin');
          if (sensor.admin
              && this.AuthService.view_as_owner?.uuid
              && sensor.configs.accessException?.indexOf(this.AuthService.view_as_owner.uuid) >= 0) {
            sensor.admin = false; // User has Exception'al access to this sensor
          }

          if (!sensor.admin
              && this.AuthService.view_as_owner?.uuid
              && sensor.configs.accessDeny?.indexOf(this.AuthService.view_as_owner.uuid) >= 0) {
            sensor.admin = true; // User is denied access to this sensor
            sensor.configs.accessLevel = "admin";
          }

          if (sensor.configs.manual) {
            sensor.configs.icon = 'icon-wg-manual-entry';
            // if (sensor.configs.icon && sensor.configs.icon != 'icon-wg-manual-entry') {
            //   sensor.configs.over_icon = 'icon-wg-manual-entry';
            // }
          }


          // Check if user chose a conversion for this SI_type
          if (sensor.configs?.si_type
              && sensor.configs.conversions
              && this.AuthService.user.configs?.conversions?.[sensor.configs.si_type]) {

            let _desired_conversion_id = this.AuthService.user.configs.conversions[sensor.configs.si_type];

            // If the desired conversion is in the list of valid conversions for this sensor, use it
            if (sensor.configs.conversions[_desired_conversion_id]) {
              sensor.conversion = sensor.configs.conversions[_desired_conversion_id];
              sensor.conversion.id = _desired_conversion_id;

              // Deprecate. Use conversion.unit directly when showing converted
              if (sensor.conversion.unit_sref) {
                sensor.unit = this.$translate.instant(sensor.conversion.unit_sref);
              } else {
                sensor.unit = sensor.conversion.unit;
              }
              sensor.configs.decimals = sensor.conversion.decimals; // Deprecate
              // sensor.configs.query = conversions[conversion?.id].query; // We do not change the query for now.

              if (sensor.conversion.graph_options) {
                _.assign(sensor.configs.graph_options, sensor.conversion.graph_options);
              }

              { // Deprecate
                if (sensor.conversion.minRange) {
                  sensor.configs.graph_options.minRange = sensor.conversion.minRange;
                  console.warn("Sensor with deprecated conversion: ", sensor.internal_name, sensor.conversion);
                }
                if (sensor.conversion.floor) {
                  sensor.configs.graph_options.floor = sensor.conversion.floor;
                  console.warn("Sensor with deprecated conversion: ", sensor.internal_name, sensor.conversion);
                }
                if (sensor.conversion.min) {
                  sensor.configs.graph_options.min = sensor.conversion.min;
                  console.warn("Sensor with deprecated conversion: ", sensor.internal_name, sensor.conversion);
                }
                if (sensor.conversion.max) {
                  sensor.configs.graph_options.max = sensor.conversion.max;
                  console.warn("Sensor with deprecated conversion: ", sensor.internal_name, sensor.conversion);
                }
                if (sensor.conversion.softMin) {
                  sensor.configs.graph_options.softMin = sensor.conversion.softMin;
                  console.warn("Sensor with deprecated conversion: ", sensor.internal_name, sensor.conversion);
                }
                if (sensor.conversion.softMax) {
                  sensor.configs.graph_options.softMax = sensor.conversion.softMax;
                  console.warn("Sensor with deprecated conversion: ", sensor.internal_name, sensor.conversion);
                }
              }
            }
          }
        });

        // if (WG_debug) console.timeEnd("WGSensors-process_data");

        this.WGSensors.processing = false;
        this.WGSensors.ready = true;

        // We need to reprocess devices' data
        this.WGDevices.process_data();

        this.update_ready_status();
        this.DataUtils.global_apply_schedule("data-changed");
        this.$rootScope.$broadcast('sensors_updated');
      },

      // Processes passed object as Sensor
      parse: (entry: ISensor): ISensor => {
        if (!entry.stream || !entry.internal_name) {
          console.error("Wrong Sensor object:", entry);
          return null;
        }
        if (REMOVED_STREAMS.includes(entry.stream.toUpperCase())) {
          //if (WG_debug) console.warn("Ignored sensor:", entry.stream, entry);
          return null;
        }
        let _sensor = _.pick(entry, ['id', 'stream', 'internal_name',
          'name', 'description', 'unit', 'configs']) as ISensor;

        this.DataUtils.normalize_sensor_info(_sensor);
        return _sensor;
      },

      // Parses an object as Unit and merges onto existing this.WGUnits
      merge_entry: (entry: ISensor, process_afterwards = false): ISensor => {
        let _sensor = this.WGSensors.parse(entry);

        if (!_sensor || !_sensor.stream || !_sensor.internal_name) {
          // console.warn("Merging wrong Sensor!", _sensor);
          return;
        }

        if (this.WGSensors.sensors_name[_sensor.internal_name]) {
          // Already exists. Just update one reference's data
          // if (WG_debug) {
          //   // Print differences that arrived from server
          //   let diffs = _.reduce(_sensor, (result, value, key) => {
          //     if (['ignored_key'].includes(key))
          //       return result;
          //     return _.isEqual(value, this.WGSensors.sensors_name[_sensor.internal_name][key]) ? result : result.concat(key);
          //   }, []);
          //
          //   if (diffs.length)
          //     console.info("Sensor Changed: ", {diffs: diffs, from: _.clone(this.WGSensors.sensors_name[_sensor.internal_name]), to: _.clone(_sensor)});
          // }

          _.assign(this.WGSensors.sensors_name[_sensor.internal_name], _sensor);
        } else {
          this.WGSensors.sensors.push(_sensor);
          this.WGSensors.sensors_name[_sensor.internal_name] = _sensor;
          if (_sensor.configs.manual && (!_sensor.configs.accessLevel || this.DataUtils.canUserAccessSensor(_sensor.internal_name))) {
            this.WGSensors.manual_sensors.push(_sensor);
          }
        }
        if (process_afterwards) {
          this.WGSensors.process_data();
        } else {
          this.WGSensors.changed_locally = true;
        }
        return this.WGSensors.sensors_name[_sensor.internal_name];
      },

      // Get all sensors, and their configurations
      update: () => { // Sensors
        if (this.WGSensors.loading) {
          return;
        }

        // if (WG_debug) console.time("WGSensors-update");

        console.log("WGApiData, getting Sensors");

        this.WGSensors.ready = false;
        this.WGSensors.changed = false;
        this.WGSensors.loading = true;
        this.AllReady = false;

        // When no response arrives in 1 min,
        clearTimeout(this.WGSensors.timer);
        this.WGSensors.timer = setTimeout(() => {
          console.error("Getting sensors timedout!!! Retrying")
          this.WGSensors.loading = false;
          this.WGSensors.update();
        }, 65000);

        this.$http.get('api/dashboard/sensors/all/?page_size=1000', {timeout: 40000}).then(
            (response) => { // onSuccess
              // if (WG_debug) console.timeLog("WGSensors-update");
              clearTimeout(this.WGSensors.timer);
              this.WGSensors.retries = 0;

              // Used to detect removals
              let _sensor_names = [];

              _.forEach(response.data as ISensor[], (_entry: ISensor) => {
                if (!_entry.internal_name) return;

                _sensor_names.push(_entry.internal_name);

                this.WGSensors.merge_entry(_entry);
              });

              // Remove deleted entries
              _.forEach(this.WGSensors.sensors_name, (_sensor, _internal_name) => {
                if (!_sensor) {
                  return;
                }
                if (_sensor_names.includes(_sensor.internal_name)) {
                  return;
                }

                console.info("Sensor Changed. Deleted: ", {del: _.clone(_sensor)});
                _.remove(this.WGSensors.sensors, {internal_name: _sensor.internal_name});
                delete this.WGSensors.sensors_name[_sensor.internal_name];
                _.remove(this.WGSensors.manual_sensors, {internal_name: _sensor.internal_name});
              });

              console.log("Updated Sensors: ", _.size(this.WGSensors.sensors_name));

              this.$rootScope.watgridSensorNames = this.WGSensors.sensors_name; // legacy
              this.$rootScope.userSensors = this.WGSensors.manual_sensors; // legacy

              this.WGSensors.loading = false;

              // if (WG_debug) console.timeEnd("WGSensors-update");
              this.WGSensors.process_data();
              return;
            },

            (reason) => {// onError
              this.WGSensors.ready = true;
              this.WGSensors.changed = true;
              this.WGSensors.loading = false;
              console.error('Failed getting sensors. Retrying', this.WGSensors.retries, reason);

              clearTimeout(this.WGSensors.timer);
              if (this.WGSensors.retries++ <= 3) {
                setTimeout(() => {
                  this.WGSensors.update();
                }, 2000);
              }

              // if (WG_debug) console.timeEnd("WGSensors-update");
              return;
            });
        return;
      },

      update_singular: (id) => { // Sensors

        if (!id || id < 0) {
          console.warn("Updating wrong Sensor!", id);
          return;
        }
        this.$http.get('api/dashboard/sensors/' + id + '/', {timeout: 45000}).then(
            (response) => { // onSuccess
              this.WGSensors.merge_entry(response.data as ISensor, true);
              console.log("Updated Sensor", id);
              return;
            },
            (reason) => { // onError
              console.error("Error updating Sensor: ", reason);
              return;
            });
        return;
      },

      add_local: (entry: ISensor) => {
        if (this.WGSensors.sensors_name[entry.internal_name]) {
          if (WG_debug) console.warn("Sensor already present:", this.WGSensors.sensors_name[entry.internal_name]);
          return
        }
        this.WGSensors.merge_entry(entry, true);
      },

      delete_local: (internal_name: string) => { // Sensors
        let _entry = this.WGSensors.sensors_name[internal_name];
        if (!_entry && typeof internal_name == 'number') {
          _entry = _.find(this.WGSensors.sensors, {id: internal_name}); // Avoid searching by id!
        }

        if (!_entry || internal_name != _entry.internal_name) {
          if (WG_debug) console.warn("No Sensor detected with internal_name:", internal_name);
          return
        }

        if (WG_debug) console.info("Deleting Sensor ", _entry);

        // Delete from the main data-structure
        delete this.WGSensors.sensors_name[internal_name];
        _.remove(this.WGSensors.sensors, {internal_name: internal_name});
        _.remove(this.WGSensors.manual_sensors, {internal_name: internal_name});

        this.WGSensors.changed_locally = true;
        this.process_data_soon(200, true);
      },
    }

    public WGProcesses: IWGApiProcesses = {
      processes: [],
      processes_id: {},

      ready: false,
      changed: true,
      changed_locally: false,
      loading: false,
      processing: false,
      retries: 0,

      reset: () => { // Processes
        this.WGProcesses.processes = emptyOrCreateArray(this.WGProcesses.processes);
        this.WGProcesses.processes_id = emptyOrCreateDict(this.WGProcesses.processes_id);
        this.WGProcesses.ready = false;
        this.WGProcesses.changed = true;
        this.WGProcesses.loading = false;
        this.WGProcesses.processing = false;
      },

      // Processes have changed. Redo it's dependencies and dependents
      process_data: () => {
        if (this.WGProcesses.loading) {
          return;
        }
        this.WGProcesses.processing = true;
        this.WGProcesses.changed_locally = false;
        this.WGProcesses.ready = false;

        // if (WG_debug) console.time("WGProcesses-process_data");

        this.link_processes_and_units();
        this.link_processes_and_batches();

        // Re-run other functions dependent on these connections
        this.update_units_status(this.WGPlaces.places);

        // if (WG_debug) console.timeEnd("WGProcesses-process_data");

        this.WGProcesses.processing = false;
        this.WGProcesses.ready = true;

        this.update_ready_status();
        this.DataUtils.global_apply_schedule("data-changed");
        this.$rootScope.$broadcast('processes_updated');
      },

      // Processes passed object as Process
      parse: (entry: IProcess): IProcess => {
        if (!entry.id) {
          console.error("Wrong Process object:", entry);
          return null;
        }
        let _process = _.pick(entry, ['id', 'name', 'name_sref', 'active', 'process_type',
          'content_type', 'description', 'dynamic_fields',
          'started_at', 'ended_at', 'last_activity',
          'batch.id', 'owner.id', 'units']) as IProcess;

        _process.type = 'process';

        this.AuthService.anonymize_entity("Process", _process);

        return _process;
      },

      // Parses an object as Unit and merges onto existing this.WGUnits
      merge_entry: (entry: IProcess, process_afterwards = false): IProcess => {
        let _process = this.WGProcesses.parse(entry);

        if (!_process || !_process.id || _process.type !== 'process') {
          console.warn("Merging wrong Process!", _process);
          return;
        }
        if (!_process.active) {
          // Fix for processes that are active but not marked as such
          // When process started in the past, and not yet finished
          let _current_time = new Date().getTime();
          if (_process.started_at && Date.parse(_process.started_at) < _current_time) {
            if (!_process.ended_at || Date.parse(_process.ended_at) > _current_time) {
              if (WG_debug) console.warn("Process inactive but still not ended!", {process: _process});
              _process.active = true;
            }
          }
        }

        if (_process.active === false) {
          // Ensure inactive entries are removed from the process lists
          if (this.WGProcesses.processes_id[_process.id]) {
            console.info("Removing inactive process.: ", _process);
            this.WGProcesses.delete_local(_process.id);
          }
          return null;
        }

        if (this.WGProcesses.processes_id[_process.id]) {
          // Already exists. Just update one reference's data
          // if (WG_debug) {
          //   // Print differences that arrived from server
          //   let diffs = _.reduce(_process, (result, value, key) => {
          //     if (['units'].includes(key))
          //       return result;
          //     return _.isEqual(value, this.WGProcesses.processes_id[_process.id][key]) ? result : result.concat(key);
          //   }, []);
          //
          //   if (diffs.length)
          //     console.info("Process Changed: ", {diffs: diffs, from: _.clone(this.WGProcesses.processes_id[_process.id]), to: _.clone(_process)});
          // }

          _.assign(this.WGProcesses.processes_id[_process.id], _process);
        } else {
          this.WGProcesses.processes.push(_process);
          this.WGProcesses.processes_id[_process.id] = _process;
        }
        if (process_afterwards) {
          this.WGProcesses.process_data();
        } else {
          this.WGProcesses.changed_locally = true;
        }
        return this.WGProcesses.processes_id[_process.id];
      },

      // Arrive with batches[], should nest with units[]
      update: () => {
        if (this.WGProcesses.loading) {
          return;
        }

        // if (WG_debug) console.time("WGProcesses-update");

        this.WGProcesses.ready = false;
        this.WGProcesses.changed = false;
        this.WGProcesses.loading = true;
        this.AllReady = false;

        // Used to detect removals
        let _process_ids = [];

        let get_and_merge = (page = 1, force_last = false) => {
          if (WG_debug) console.info('WGApiData, getting Processes, page:', page);
          // When no response arrives in 1 min,
          clearTimeout(this.WGProcesses.timer);
          this.WGProcesses.timer = setTimeout(() => {
            console.error("Getting processes timedout!!! Retrying")
            this.WGProcesses.loading = false;
            this.WGProcesses.update();
          }, 65000);

          this.$http.get('api/dashboard/processes/?page_size=1000&page=' + page, {timeout: 45000}).then(
              (response) => { // onSuccess
                // if (WG_debug) console.timeLog("WGProcesses-update");

                clearTimeout(this.WGProcesses.timer);


                // Merge with the existing processes list, don't replace it.
                // @ts-ignore
                _.forEach(response.data.results, (_entry: IProcess) => {
                  if (!_entry.id) return;

                  _process_ids.push(_entry.id);

                  this.WGProcesses.merge_entry(_entry);
                });

                // If response.data arrived with "next", request next page
                if (!force_last && response.data['next']) {
                  let _page = response.data['next']?.split('page=')[1]?.split('&')[0];
                  if (_page != page + 1) {
                    if (WG_debug) console.warn("Getting Processes page error!", response.data['next'], page);
                    force_last = true;
                  }
                  get_and_merge(page + 1, force_last);
                  return;
                }

                this.WGProcesses.retries = 0;

                // Remove deleted entries
                _.forEach(this.WGProcesses.processes_id, (_process, _id) => {
                  if (!_process) {
                    return;
                  }
                  if (_process_ids.includes(_process.id)) {
                    return;
                  }

                  console.info("Process Changed. Deleted: ", {del: _.clone(_process)});
                  _.remove(this.WGProcesses.processes, {id: _process.id});
                  delete this.WGProcesses.processes_id[_process.id];
                });

                console.log("Updated Processes: ", _.size(this.WGProcesses.processes_id));

                this.WGProcesses.loading = false;

                // if (WG_debug) console.timeEnd("WGProcesses-update");
                this.WGProcesses.process_data();
                return;
              },
              (reason) => {// onError
                this.WGProcesses.ready = true;
                this.WGProcesses.changed = true;
                this.WGProcesses.loading = false;
                console.error('Failed getting processes. Retrying', this.WGProcesses.retries, reason);

                clearTimeout(this.WGProcesses.timer);
                if (this.WGProcesses.retries++ <= 3) {
                  setTimeout(() => {
                    this.WGProcesses.update();
                  }, 2000);
                }

                // if (WG_debug) console.timeEnd("WGProcesses-update");
                return;
              }
          );
        }
        get_and_merge(1);
        return;
      },

      update_singular: (id) => {
        if (!id || id < 0) {
          console.warn("Updating wrong Process!", id);
          return;
        }
        this.$http.get('api/dashboard/processes/' + id + '/', {timeout: 45000}).then(
            (response) => { // onSuccess
              this.WGProcesses.merge_entry(response.data as IProcess, true);
              console.log("Updated Process", id);
              return;
            },
            (reason) => { // onError
              console.error("Error updating Process: ", reason);
              return;
            });
        return;
      },

      delete: (id: number, callback) => { // Processes

        let _process = this.WGProcesses.processes_id[id];
        if (!_process) {
          console.warn("Process doesn't exist to be deleted:", id);
          callback?.('error');
          return;
        }

        this.WGProcesses.changed_locally = true;
        if (_process.unit?.id > 0) {
          this.WGUnits.changed_locally = true;
        }
        if (_process.batch?.id > 0) {
          this.WGBatches.changed_locally = true;
        }
        if (!_.isEmpty(_process.units)) {
          this.WGUnits.changed_locally = true;
        }
        this.process_data_soon(3000);

        this.$http.delete('api/dashboard/processes/' + _process.id + '/').then(
            (response) => {
              if (response.status >= 200 && response.status < 300) {
                console.log("WGProcess Delete SUCCESS");
                this.WGProcesses.delete_local(id);
                callback?.('success');
              } else {
                console.warn("WGProcess Delete Failed!", response);
                callback?.('error');
              }
            }, (response) => {
              console.error("WGProcess Delete Failed", response);
              callback?.('error');
            }
        ).finally(() => {
          this.process_data_soon();
        });
      },

      delete_local: (id: number) => { // Processes
        let _entry = this.WGProcesses.processes_id[id];

        if (!_entry) {
          if (WG_debug) console.warn("No Process detected with id:", id);
          return
        }

        if (WG_debug) console.info("Deleting Process locally", _entry);

        // Delete from the main data-structure
        delete this.WGProcesses.processes_id[id];
        _.remove(this.WGProcesses.processes, {id: id});

        this.WGProcesses.changed_locally = true;
        this.process_data_soon(200, true);
      },
    }

    public WGBatches: IWGApiBatches = {
      batches: [],
      batches_id: {},
      ready: false,
      changed: true,
      changed_locally: false,
      loading: false,
      processing: false,
      retries: 0,

      reset: () => { // Batches
        this.WGBatches.batches = emptyOrCreateArray(this.WGBatches.batches);
        this.WGBatches.batches_id = emptyOrCreateDict(this.WGBatches.batches_id);

        this.WGBatches.ready = false;
        this.WGBatches.changed = true;
        this.WGBatches.loading = false;
        this.WGBatches.processing = false;
      },

      // Batches have changed. Redo it's dependencies and dependents
      process_data: () => {
        if (this.WGBatches.loading) {
          return;
        }
        this.WGBatches.processing = true;
        this.WGBatches.changed_locally = false;
        this.WGBatches.ready = false;

        // if (WG_debug) console.time("WGBatches-process_data");

        _.forEach(this.WGBatches.batches, (batch) => {
          batch.type = 'batch';
        });
        // console.log("WGBatches.batches modified!", this.WGBatches.batches)

        this.link_processes_and_batches();

        // Re-run other functions dependent on these connections

        // if (WG_debug) console.timeEnd("WGBatches-process_data");

        this.WGBatches.processing = false;
        this.WGBatches.ready = true;

        this.update_ready_status();
        this.DataUtils.global_apply_schedule("data-changed");
        this.$rootScope.$broadcast('batches_updated');
      },

      // Processes passed object as Batch
      parse: (entry: IBatch): IBatch => {
        if (!entry.id) {
          console.error("Wrong Batch object:", entry);
          return null;
        }
        // let _batch = _.pick(entry, ['id', 'name', 'name_sref', 'active', 'process_type',
        //   'content_type', 'description', 'dynamic_fields',
        //   'started_at', 'ended_at', 'last_activity',
        //   'batch.id', 'owner.id', 'units']) as IBatch;

        let _batch = _.clone(entry);
        _batch.type = 'batch';

        this.AuthService.anonymize_entity("Batch", _batch);

        return _batch;
      },

      // Parses an object as Unit and merges onto existing this.WGUnits
      merge_entry: (entry: IBatch, process_afterwards = false): IBatch => {
        let _batch = this.WGBatches.parse(entry);

        if (!_batch || !_batch.id || _batch.type !== 'batch') {
          console.warn("Merging wrong Batch!", _batch);
          return;
        }

        if (this.WGBatches.batches_id[_batch.id]) {
          // Already exists. Just update one reference's data
          // if (WG_debug) {
          //   // Print differences that arrived from server
          //   let diffs = _.reduce(_batch, (result, value, key) => {
          //     if (['units'].includes(key))
          //       return result;
          //     return _.isEqual(value, this.WGBatches.batches_id[_batch.id][key]) ? result : result.concat(key);
          //   }, []);
          //
          //   if (diffs.length)
          //     console.info("Batch Changed: ", {diffs: diffs, from: _.clone(this.WGBatches.batches_id[_batch.id]), to: _.clone(_batch)});
          // }

          _.assign(this.WGBatches.batches_id[_batch.id], _batch);
        } else {
          this.WGBatches.batches.push(_batch);
          this.WGBatches.batches_id[_batch.id] = _batch;
        }
        if (process_afterwards) {
          this.WGBatches.process_data();
        } else {
          this.WGBatches.changed_locally = true;
        }
        return this.WGBatches.batches_id[_batch.id];
      },

      // Arrive with nothing, should nest with processes[]
      update: () => {
        if (this.WGBatches.loading) {
          return;
        }

        // if (WG_debug) console.time("WGBatches-update");

        this.WGBatches.ready = false;
        this.WGBatches.changed = false;
        this.WGBatches.loading = true;
        this.AllReady = false;

        // Used to detect removals
        let _batch_ids = [];

        let get_and_merge = (page = 1, force_last = false) => {
          if (WG_debug) console.info('WGApiData, getting Batches, page:', page);

          // When no response arrives in 1 min,
          clearTimeout(this.WGBatches.timer);
          this.WGBatches.timer = setTimeout(() => {
            console.error("Getting batches timedout!!! Retrying")
            this.WGBatches.loading = false;
            this.WGBatches.update();
          }, 65000);


          this.$http.get('api/dashboard/batches/?page_size=1000&page=' + page, {timeout: 45000}).then(
              (response) => { // onSuccess
                // if (WG_debug) console.timeLog("WGBatches-update");

                clearTimeout(this.WGBatches.timer);


                // Merge with the existing batches list, don't replace it.
                // @ts-ignore
                _.forEach(response.data.results, (_entry: IBatch) => {
                  if (!_entry.id) return;

                  _batch_ids.push(_entry.id);

                  this.WGBatches.merge_entry(_entry);
                });

                // If response.data arrived with "next", request next page
                if (!force_last && response.data['next']) {
                  let _page = response.data['next']?.split('page=')[1]?.split('&')[0];
                  if (_page != page + 1) {
                    if (WG_debug) console.warn("Getting Batches page error!", response.data['next'], page);
                    force_last = true;
                  }
                  get_and_merge(page + 1, force_last);
                  return;
                }
                this.WGBatches.retries = 0;
                // Remove deleted entries
                _.forEach(this.WGBatches.batches_id, (_batch, _id) => {
                  if (!_batch) {
                    return;
                  }
                  if (_batch_ids.includes(_batch.id)) {
                    return;
                  }

                  console.info("Batch Changed. Deleted: ", {del: _.clone(_batch)});
                  _.remove(this.WGBatches.batches, {id: _batch.id});
                  delete this.WGBatches.batches_id[_batch.id];
                });

                console.log("Updated Batches: ", _.size(this.WGBatches.batches_id));

                this.WGBatches.loading = false;

                // if (WG_debug) console.timeEnd("WGBatches-update");
                this.WGBatches.process_data();
                return;
              },
              (reason) => { // onError
                this.WGBatches.ready = true;
                this.WGBatches.changed = true;
                this.WGBatches.loading = false;
                console.error('Failed getting batches. Retrying', this.WGBatches.retries, reason);

                clearTimeout(this.WGBatches.timer);
                if (this.WGBatches.retries++ <= 3) {
                  setTimeout(() => {
                    this.WGBatches.update();
                  }, 2000);
                }

                // if (WG_debug) console.timeEnd("WGBatches-update");
                return;
              }
          );
        }
        get_and_merge(1);

        return;
      },

      update_singular: (id) => {
        if (!id || id < 0) {
          console.warn("Updating wrong Batch!", id);
          return;
        }
        this.$http.get('api/dashboard/batches/' + id + '/', {timeout: 45000}).then(
            (response) => { // onSuccess
              this.WGBatches.merge_entry(response.data as IBatch, true);
              console.log("Updated Batch", id);
              return;
            },
            (reason) => { // onError
              console.error("Error updating Batch: ", reason);
              return;
            });
        return;
      },

      // TODO: Implement.
      delete: (id: number, callback) => { // Units

        let _batch = this.WGBatches.batches_id[id];
        if (!_batch) {
          console.warn("Batch doesn't exist to be deleted:", id);
          callback?.('error');
          return;
        }

        this.WGBatches.changed_locally = true;
        if (!_.isEmpty(_batch.processes)) {
          this.WGProcesses.changed_locally = true;
        }
        this.process_data_soon(3000);

        this.$http.delete('api/dashboard/batches/' + _batch.id + '/').then(
            (response) => {
              if (response.status >= 200 && response.status < 300) {
                console.log("WGBatch Delete SUCCESS");
                this.WGBatches.delete_local(id);
                callback?.('success');
              } else {
                console.warn("WGBatch Delete Failed!", response);
                callback?.('error');
              }
            }, (response) => {
              console.error("WGBatch Delete Failed", response);
              callback?.('error');
            }
        ).finally(() => {
          this.process_data_soon();
        });
      },

      delete_local: (id: number) => { // Batchs
        let _entry = this.WGBatches.batches_id[id];

        if (!_entry) {
          if (WG_debug) console.warn("No Batch detected with id:", id);
          return
        }

        if (WG_debug) console.info("Deleting Batch locally", _entry);

        // Remove .devices.unit if exists
        _.forEach(_entry.processes, (_process) => {
          if (_process?.batch?.id == _entry.id) {
            _process.batch = undefined;
            this.WGProcesses.changed_locally = true;
          }
        });

        // Delete from the main data-structure
        delete this.WGBatches.batches_id[id];
        _.remove(this.WGBatches.batches, {id: id});

        this.WGBatches.changed_locally = true;
        this.process_data_soon(200, true);
      },

    }

    public WGAlarms: IWGApiAlarms = {
      alarms: [],
      alarms_id: {},
      ready: false,
      changed: true,
      changed_locally: false,
      loading: false,
      processing: false,
      retries: 0,

      reset: () => { // Alarms
        this.WGAlarms.alarms = emptyOrCreateArray(this.WGAlarms.alarms);
        this.WGAlarms.alarms_id = emptyOrCreateDict(this.WGAlarms.alarms_id);

        this.WGAlarms.ready = false;
        this.WGAlarms.changed = true;
        this.WGAlarms.loading = false;
        this.WGAlarms.processing = false;
      },

      // Alarms have changed. Redo it's dependencies and dependents
      process_data: () => {
        if (this.WGAlarms.loading) {
          return;
        }
        this.WGAlarms.processing = true;
        this.WGAlarms.changed_locally = false;
        this.WGAlarms.ready = false;

        // if (WG_debug) console.time("WGAlarms-process_data");

        _.forEach(this.WGAlarms.alarms, (alarm) => {
          alarm.type = 'alarm';
        });
        // console.log("WGAlarms.alarms modified!", this.WGAlarms.alarms)

        this.link_alarms_and_devices();

        // Re-run other functions dependent on these connections

        // if (WG_debug) console.timeEnd("WGAlarms-process_data");

        this.WGAlarms.processing = false;
        this.WGAlarms.ready = true;

        this.update_ready_status();
        this.DataUtils.global_apply_schedule("data-changed");
        this.$rootScope.$broadcast('alarms_updated');
      },

      // Processes passed object as Alarm
      parse: (entry: IAlarm): IAlarm => {
        if (!entry.id) {
          console.error("Wrong Alarm object:", entry);
          return null;
        }
        // let _alarm = _.pick(entry, ['id', 'name', 'name_sref', 'active', 'process_type',
        //   'content_type', 'description', 'dynamic_fields',
        //   'started_at', 'ended_at', 'last_activity',
        //   'alarm.id', 'owner.id', 'units']) as IAlarm;

        let _alarm = _.clone(entry);
        _alarm.type = 'alarm';

        this.AuthService.anonymize_entity("Alarm", _alarm);

        return _alarm;
      },

      // Parses an object as Unit and merges onto existing this.WGUnits
      merge_entry: (entry: IAlarm, process_afterwards = false): IAlarm => {
        let _alarm = this.WGAlarms.parse(entry);

        if (!_alarm || !_alarm.id || _alarm.type !== 'alarm') {
          console.warn("Merging wrong Alarm!", _alarm);
          return;
        }

        if (this.WGAlarms.alarms_id[_alarm.id]) {
          // Already exists. Just update one reference's data
          // if (WG_debug) {
          //   // Print differences that arrived from server
          //   let diffs = _.reduce(_alarm, (result, value, key) => {
          //     if (['units'].includes(key))
          //       return result;
          //     return _.isEqual(value, this.WGAlarms.alarms_id[_alarm.id][key]) ? result : result.concat(key);
          //   }, []);
          //
          //   if (diffs.length)
          //     console.info("Alarm Changed: ", {diffs: diffs, from: _.clone(this.WGAlarms.alarms_id[_alarm.id]), to: _.clone(_alarm)});
          // }

          _.assign(this.WGAlarms.alarms_id[_alarm.id], _alarm);
        } else {
          this.WGAlarms.alarms.push(_alarm);
          this.WGAlarms.alarms_id[_alarm.id] = _alarm;
        }
        if (process_afterwards) {
          this.WGAlarms.process_data();
        } else {
          this.WGAlarms.changed_locally = true;
        }
        return this.WGAlarms.alarms_id[_alarm.id];
      },

      // Arrive with nothing, should nest with devices[]
      update: () => {
        if (this.WGAlarms.loading) {
          return;
        }

        // if (WG_debug) console.time("WGAlarms-update");

        this.WGAlarms.ready = false;
        this.WGAlarms.changed = false;
        this.WGAlarms.loading = true;
        this.AllReady = false;

        // Used to detect removals
        let _alarm_ids = [];

        let get_and_merge = (page = 1, force_last = false) => {
          if (WG_debug) console.info('WGApiData, getting Alarms, page:', page);

          // When no response arrives in 1 min,
          clearTimeout(this.WGAlarms.timer);
          this.WGAlarms.timer = setTimeout(() => {
            console.error("Getting alarms timedout!!! Retrying")
            this.WGAlarms.loading = false;
            this.WGAlarms.update();
          }, 65000);


          this.$http.get('api/dashboard/alarm/?page_size=100&page=' + page, {timeout: 45000}).then(
              (response) => { // onSuccess
                // if (WG_debug) console.timeLog("WGAlarms-update");

                clearTimeout(this.WGAlarms.timer);


                // Merge with the existing alarms list, don't replace it.
                // @ts-ignore
                _.forEach(response.data.results, (_entry: IAlarm) => {
                  if (!_entry.id) return;

                  _alarm_ids.push(_entry.id);

                  this.WGAlarms.merge_entry(_entry);
                });

                // If response.data arrived with "next", request next page
                if (!force_last && response.data['next']) {
                  let _page = response.data['next']?.split('page=')[1]?.split('&')[0];
                  if (_page != page + 1) {
                    if (WG_debug) console.warn("Getting Alarms page error!", response.data['next'], page);
                    force_last = true;
                  }
                  get_and_merge(page + 1, force_last);
                  return;
                }
                this.WGAlarms.retries = 0;
                // Remove deleted entries
                _.forEach(this.WGAlarms.alarms_id, (_alarm, _id) => {
                  if (!_alarm) {
                    return;
                  }
                  if (_alarm_ids.includes(_alarm.id)) {
                    return;
                  }

                  console.info("Alarm Changed. Deleted: ", {del: _.clone(_alarm)});
                  _.remove(this.WGAlarms.alarms, {id: _alarm.id});
                  delete this.WGAlarms.alarms_id[_alarm.id];
                });

                console.log("Updated Alarms: ", _.size(this.WGAlarms.alarms_id));

                this.WGAlarms.loading = false;

                // if (WG_debug) console.timeEnd("WGAlarms-update");
                this.WGAlarms.process_data();
                return;
              },
              (reason) => { // onError
                this.WGAlarms.ready = true;
                this.WGAlarms.changed = true;
                this.WGAlarms.loading = false;
                console.error('Failed getting alarms. Retrying', this.WGAlarms.retries, reason);

                clearTimeout(this.WGAlarms.timer);
                if (this.WGAlarms.retries++ <= 3) {
                  setTimeout(() => {
                    this.WGAlarms.update();
                  }, 2000);
                }

                // if (WG_debug) console.timeEnd("WGAlarms-update");
                return;
              }
          );
        }
        get_and_merge(1);

        return;
      },

      update_singular: (id) => {
        if (!id || id < 0) {
          console.warn("Updating wrong Alarm!", id);
          return;
        }
        this.$http.get('api/dashboard/alarm/' + id + '/', {timeout: 45000}).then(
            (response) => { // onSuccess
              this.WGAlarms.merge_entry(response.data as IAlarm, true);
              console.log("Updated Alarm", id);
              return;
            },
            (reason) => { // onError
              console.error("Error updating Alarm: ", reason);
              return;
            });
        return;
      },

      // delete: (id: number) => {
      //
      //
      //     this.$http.delete('api/dashboard/alarm/' + id + '/', {timeout: 45000}).then(
      //         function (response) {
      //           console.log('Removing Alarm', response.data);
      //
      //           const url = this.$rootScope.base_url + 'data/' + this.$scope.device.path + '/' + item.sensor.internal_name + '/REMOVE_ALARM' + '?api_key=' + $rootScope.apiKey;
      //           const payload = {
      //             iid: $scope.device.iid,
      //             value: data,
      //             timestamp: new Date().getTime(),
      //             timeout: 40000,
      //           };
      //
      //           console.log('removeAlarm_data', url, payload);
      //           $http.post(url, payload).then(
      //               function (response) {
      //                 // console.log(response.data);
      //               },
      //               function (response) {
      //                 console.error(response);
      //               }
      //           );
      //           getAlarms();
      //           parentRet.loading = false;
      //           parentRet.result = 'success';
      //           parentRet.message = null;
      //           $scope.data = null;
      //           $scope.has_alarm = false;
      //           $scope.sensor_select_disabled = false;
      //           // ngDialog.close(0);
      //         },
      //         function (response) {
      //           console.error('then onRejected', response);
      //           parentRet.result = 'error';
      //           parentRet.loading = false;
      //           parentRet.message = response?.data?.message || 'Error ' + response?.status;
      //         }
      //     );
      //   this.update_changed_data();
      // },

      delete: (id: number, callback) => { // Alarms

        let _alarm = this.WGAlarms.alarms_id[id];
        if (!_alarm) {
          console.warn("Alarm doesn't exist to be deleted:", id);
          callback?.('error');
          return;
        }

        this.WGAlarms.changed_locally = true;
        if (_alarm.device?.id > 0) {
          this.WGDevices.changed_locally = true;
        }
        this.process_data_soon(3000);

        this.$http.delete('api/dashboard/alarm/' + _alarm.id + '/').then(
            (response) => {
              if (response.status >= 200 && response.status < 300) {
                console.log("WGAlarm Delete SUCCESS");
                this.WGAlarms.delete_local(_alarm.id);

                if (_alarm.device?.path && _alarm.device?.iid && _alarm.sensor?.internal_name) {
                  const url = 'data/' + _alarm.device.path + '/' + _alarm.sensor.internal_name + '/REMOVE_ALARM' + '?api_key=' + this.$rootScope.apiKey;
                  const payload = {
                    iid: _alarm.device.iid,
                    value: {
                      'username': _alarm.owner.username,
                      'alarm_id': _alarm.id,
                      'sensor_internal_name': _alarm.sensor.internal_name,
                    },
                    timestamp: new Date().getTime(),
                    timeout: 45000,
                  };

                  console.log('removeAlarm_data', url, payload);
                  this.$http.post(url, payload).then(
                      function (response) {
                        console.log("WGAlarm-Storage Delete SUCCESS");
                      },
                      function (response) {
                        console.error(response);
                      }
                  );
                }
                callback?.('success');
              } else {
                console.warn("WGAlarm Delete Failed!", response);
                callback?.('error');
              }
            }, (response) => {
              console.error("WGAlarm Delete Failed", response);
              callback?.('error');
            }
        ).finally(() => {
          this.process_data_soon(500, true);
        });
      },

      delete_local: (id: number) => { // Alarms
        let _entry = this.WGAlarms.alarms_id[id];

        if (!_entry) {
          if (WG_debug) console.warn("No Alarm detected with id:", id);
          return
        }

        if (WG_debug) console.info("Deleting Alarm locally", _entry);

        // Delete from the main data-structure
        delete this.WGAlarms.alarms_id[id];
        _.remove(this.WGAlarms.alarms, {id: id});

        this.WGAlarms.changed_locally = true;
        this.process_data_soon(200, true);
      },

      activate: (id: number, active = true, callback) => { // Alarms

        let _alarm = this.WGAlarms.alarms_id[id];
        if (!_alarm) {
          console.warn("Alarm doesn't exist to be activated:", id);
          callback?.('error');
          return;
        }

        if (_alarm.device?.id > 0) {
          this.WGDevices.changed_locally = true;
        }
        this.process_data_soon(3000);

        this.$http.patch<IAlarm>('api/dashboard/alarm/' + _alarm.id + '/', {active: active}).then(
            (response) => {
              if (response.status >= 200 && response.status < 300) {
                console.log("WGAlarm Activate SUCCESS");
                this.WGAlarms.alarms_id[_alarm.id].active = active;
                this.process_data_soon(3000, true);

                if (_alarm.device?.path && _alarm.device?.iid && _alarm.sensor?.internal_name) {
                  // Saves in Storage .../SET_ALARM stream
                  const key = '?api_key=' + this.$rootScope.apiKey;
                  const alarm_stream = response.data.sensor.internal_name + '/SET_ALARM';
                  const url = this.$rootScope.base_url + 'data/' + _alarm.device.path + '/' + alarm_stream + key;
                  const payload = {
                    iid: _alarm.device.iid,
                    value: response.data,
                    timestamp: new Date().getTime(),
                    timeout: 40000,
                  };

                  if (WG_debug) console.log('setAlarm_data', url, payload);
                  // Saves in storage .../SET_ALARM stream
                  this.$http.post(url, payload).then(
                      function (response) {
                        if (WG_debug) console.log('setAlarm_data saved', response);
                        this.WGAlarms.update_singular(_alarm.id);
                      }, function (response) {
                        console.error(response);

                        this.WGAlarms.changed = true;
                        this.update_changed_data();
                      });
                }
                callback?.('success');
              } else {
                console.warn("WGAlarm Activate Failed!", response);
                callback?.('error');
              }
            }, (response) => {
              console.error("WGAlarm Activate Failed", response);
              callback?.('error');
            }
        ).finally(() => {
          this.process_data_soon(500, true);
        });
      },

    }


    public reset_all_data() {
      this.WGPlaces.reset();
      this.WGUnits.reset();
      this.WGDevices.reset();
      this.WGSensors.reset();
      this.WGProcesses.reset();
      this.WGBatches.reset();

      this.update_ready_status();
    }


    public update_ready_status() {
      if (!this.WGPlaces.ready) {
        this.AllReady = false;
        return;
      }
      if (!this.WGUnits.ready) {
        this.AllReady = false;
        return;
      }
      if (!this.WGDevices.ready) {
        this.AllReady = false;
        return;
      }
      if (!this.WGSensors.ready) {
        this.AllReady = false;
        return;
      }
      if (!this.WGProcesses.ready) {
        this.AllReady = false;
        return;
      }

      // if (!WGBatches.ready) {
      //   this.AllReady = false;
      //   return;
      // }

      this.AllReady = true;
      return;
    }

    public init() {
      if (WG_debug) console.log('WGApiData starting');

      this.$rootScope.WGPlaces = this.WGPlaces;

      this.$rootScope.WGUnits = this.WGUnits;

      this.$rootScope.WGDevices = this.WGDevices;
      this.$rootScope.userDevices = this.WGDevices.devices; // Legacy
      this.$rootScope.userDevicesUUID = this.WGDevices.devices_uuid; // Legacy
      // Saved per parameter Stream
      this.$rootScope.lastKnownMessages = this.$rootScope.lastKnownMessages || {};
      // Saved per device and per parameter Internal_Name
      this.$rootScope.lastSensorValues = this.$rootScope.lastSensorValues || {};

      this.$rootScope.WGSensors = this.WGSensors;
      this.$rootScope.watgridSensorNames = this.WGSensors.sensors_name; // legacy
      this.$rootScope.userSensors = this.WGSensors.manual_sensors; // legacy

      this.$rootScope.WGProcesses = this.WGProcesses;

      this.$rootScope.WGBatches = this.WGBatches;

      this.$rootScope.WGAlarms = this.WGAlarms;

      this.$rootScope.WGApiData = this;

      this.$rootScope.$watch('last_notifications.list', (_new_value, _old_value) => {
        if (_new_value === _old_value) { // Initializing watcher
          return;
        }
        console.log("Notifications changed!", _new_value)
        this.process_notifications_updates();
      }, true);


      if (!this.AuthService.isLogged || !(this.AuthService.view_as_owner?.id || this.AuthService.user?.id)) {
        console.warn("WGApiData. User not Logged In");
        return;
      }

      // Detect user changes
      if (!this.current_data_user_id || this.current_data_user_id !== (this.AuthService.view_as_owner?.id || this.AuthService.user?.id)) {
        console.log("WGApiData User changed. Resetting data", {
          from: this.current_data_user_id,
          to: (this.AuthService.view_as_owner?.id || this.AuthService.user?.id)
        })
        this.current_data_user_id = (this.AuthService.view_as_owner?.id || this.AuthService.user?.id);
        this.reset_all_data();
      } else {
        console.log("WGApiData Initializing with user: ", this.current_data_user_id)
        // TODO: Get from localstorage if available for quick-display. Update after few seconds.
      }

      // If a long time as passed, get all data
      if (_.isNil(this.last_data_received)
          || (this.last_data_received < (new Date().getTime()) - 1.1 * 60 * 60 * 1000)) {
        console.log("WGApiData Data too old. Resetting data", this.last_data_received);
        this.update_changed_data(true);
      } else {
        this.update_changed_data();
      }
    }

// --------------  Structure traversal functions

    /**
     * Returns all places associated with the passed items
     * @param _items - anything with ['type'] field - places|units|processes|devices|batches
     * @returns {[]}
     */
    public extractPlaces(_items: number | string | (number | string | IPlace | IUnit | IDevice | IProcess | IBatch)[]): IPlace[] {
      if (_.isEmpty(_items) || _.isEmpty(this.WGPlaces.places)) { // Nothing passed.
        return [];
      }

      // single item passed
      if (!Array.isArray(_items)) {
        _items = [_items];
      }

      let _out: IPlace[] = [];
      _.forEach(_items, (_item) => {

        if (typeof _item == 'number' || typeof _item == 'string') {
          if (this.WGPlaces.places_id[_item]) {
            _out.push(this.WGPlaces.places_id[_item]);
          }
          return;
        }

        // place already passed
        if (_item.type === 'place') {
          if (this.WGPlaces.places_id[_item.id]) {
            _out.push(this.WGPlaces.places_id[_item.id]);
          }
          return;
        }

        // Units passed. Go up
        if (_item.type === 'unit') {
          let unit = _item as IUnit;
          if (unit.place) {
            if (this.WGPlaces.places_id[unit.place.id]) {
              _out.push(this.WGPlaces.places_id[unit.place.id]);
            }
          }
          return;
        }

        // Devices passed. Go up
        if (_item.type === 'device') {
          let device = _item as IDevice;
          if (device.unit) {
            let unit = this.WGUnits.units_id[device.unit.id];
            if (unit?.place) {
              if (this.WGPlaces.places_id[unit.place.id]) {
                _out.push(this.WGPlaces.places_id[unit.place.id]);
              }
            }
          }
          return;
        }

        // Processes passed. Go up
        if (_item.type === 'process') {
          let process = _item as IProcess;
          if (process.unit) {
            let unit = this.WGUnits.units_id[process.unit.id];
            if (unit?.place) {
              if (this.WGPlaces.places_id[unit.place.id]) {
                _out.push(this.WGPlaces.places_id[unit.place.id]);
              }
            }
          }
          // A process cannot be in multiple Units for now
          _.forEach(process.units, (_unit) => {
            if (_unit) {
              let unit = this.WGUnits.units_id[_unit.id];
              if (unit?.place) {
                if (this.WGPlaces.places_id[unit.place.id]) {
                  _out.push(this.WGPlaces.places_id[unit.place.id]);
                }
              }
            }
          });
          return;
        }

        console.warn("Couldn't extract a useful Place", _.clone(_item));
      });
      return _out;
    }

    /**
     * Returns all units associated with the passed items
     * @param _items - an id or object with ['type'] field - places|units|processes|devices|batches
     * @returns {[]}
     */
    public extractUnits(_items: number | string | (number | string | IPlace | IUnit | Partial<IDevice> | IProcess | IBatch)[]): IUnit[] {
      if (_.isEmpty(_items) || _.isEmpty(this.WGUnits.units)) { // Nothing passed.
        return [];
      }

      // single item passed
      if (!Array.isArray(_items)) {
        _items = [_items];
      }

      let _out: IUnit[] = [];
      _.forEach(_items, (_item) => {

        if (typeof _item == 'number' || typeof _item == 'string') {
          if (this.WGUnits.units_id[_item]) {
            _out.push(this.WGUnits.units_id[_item]);
          }
          return;
        }

        // units already passed
        if (_item.type === 'unit') {
          if (this.WGUnits.units_id[_item.id]) {
            _out.push(this.WGUnits.units_id[_item.id]);
          }
          return;
        }

        // places passed
        if (_item.type === 'place') {
          let place = _item as IPlace;
          _.forEach(place.units, (_unit) => {
            if (this.WGUnits.units_id[_unit.id]) {
              _out.push(this.WGUnits.units_id[_unit.id]);
            }
          });
          return;
        }

        // Devices passed. Go up
        if (_item.type === 'device') {
          let device = _item as IDevice;
          if (device.unit) {
            if (this.WGUnits.units_id[device.unit.id]) {
              _out.push(this.WGUnits.units_id[device.unit.id]);
            }
          }
          return;
        }

        // Processes passed. Go up
        if (_item.type === 'process') {
          let process = _item as IProcess;
          if (process.unit) {
            let unit = this.WGUnits.units_id[process.unit.id];
            if (unit) {
              _out.push(unit);
            }
          }
          _.forEach(process.units, (_unit) => {
            let unit = this.WGUnits.units_id[_unit.id];
            if (unit) {
              _out.push(unit);
            }
          });
          return;
        }

        console.warn("Couldn't extract a useful Unit", _.clone(_item));
      });
      return _out;
    }

    /**
     * Returns all devices associated with the passed items
     * @param _items - anything with ['type'] field - places|units|processes|devices|batches
     * @returns {[]}
     */
    public extractDevices(_items: number | string | (number | string | IPlace | IUnit | IDevice | IProcess | IBatch)[]): IDevice[] {
      if (_.isEmpty(_items) || _.isEmpty(this.WGDevices.devices)) { // Nothing passed.
        return [];
      }
      // single item passed
      if (!Array.isArray(_items)) {
        _items = [_items];
      }

      let _out: IDevice[] = [];
      _.forEach(_items, (_item) => {

        if (typeof _item == 'number' || typeof _item == 'string') {
          if (this.WGDevices.devices_id[_item]) {
            _out.push(this.WGDevices.devices_id[_item]);
            return;
          }
          if (this.WGDevices.devices_uuid[_item]) {
            _out.push(this.WGDevices.devices_uuid[_item]);
            return;
          }
          let ret = _.find(this.WGDevices.devices, {sn: _item}) as IDevice;
          if (ret) {
            _out.push(ret);
            return;
          }
          return;
        }

        // device passed
        if (_item.type === 'device' || _item['sn'] || _item['iid'] || _item['model']) {
          if (this.WGDevices.devices_id[_item.id]) {
            _out.push(this.WGDevices.devices_id[_item.id]);
          }
          return;
        }

        // place passed
        if (_item.type === 'place') {
          let place = _item as IPlace;
          _.forEach(place.units, (_unit) => {
            let unit = this.WGUnits.units_id[_unit.id]
            if (unit) {
              _.forEach(unit.devices, (_device) => {
                if (this.WGDevices.devices_id[_device.id]) {
                  _out.push(this.WGDevices.devices_id[_device.id]);
                }
              });
            }
          });
          return;
        }

        // unit passed
        if (_item.type === 'unit') {
          let unit = _item as IUnit;
          _.forEach(unit.devices, (_device) => {
            if (this.WGDevices.devices_id[_device.id]) {
              _out.push(this.WGDevices.devices_id[_device.id]);
            }
          });
          return;
        }

        // Process passed. Go sideways
        if (_item.type === 'process') {
          let process = _item as IProcess;
          if (process.unit) {
            let unit = this.WGUnits.units_id[process.unit.id];
            if (unit) {
              _.forEach(unit.devices, (_device) => {
                if (this.WGDevices.devices_id[_device.id]) {
                  _out.push(this.WGDevices.devices_id[_device.id]);
                }
              });
            }
          }
          _.forEach(process.units, (_unit) => {
            let unit = this.WGUnits.units_id[_unit.id]
            if (unit) {
              _.forEach(unit.devices, (_device) => {
                if (this.WGDevices.devices_id[_device.id]) {
                  _out.push(this.WGDevices.devices_id[_device.id]);
                }
              });
            }
          });
          return;
        }

        console.warn("Couldn't extract a useful Device", _.clone(_item));
      });
      return _out;
    }

    /**
     * Returns all processes associated with the passed items
     * @param _items - anything with ['type'] field - places|units|processes|devices|batches
     * @returns {[]}
     */
    public extractProcesses(_items: number | string | (number | string | IPlace | IUnit | IDevice | IProcess | IBatch)[]): IProcess[] {
      if (_.isEmpty(_items) || _.isEmpty(this.WGProcesses.processes)) { // Nothing passed.
        return [];
      }

      // single item passed
      if (!Array.isArray(_items)) {
        _items = [_items];
      }

      let _out: IProcess[] = [];
      _.forEach(_items, (_item) => {

        if (typeof _item == 'number' || typeof _item == 'string') {
          if (this.WGProcesses.processes_id[_item]) {
            _out.push(this.WGProcesses.processes_id[_item]);
          }
          return;
        }

        // process already passed
        if (_item.type === 'process') {
          if (this.WGProcesses.processes_id[_item.id]) {
            _out.push(this.WGProcesses.processes_id[_item.id]);
          }
          return;
        }

        // places passed
        if (_item.type === 'place') {
          let place = _item as IPlace;
          _.forEach(place.units, (_unit) => {
            let unit = this.WGUnits.units_id[_unit.id];
            if (unit?.process) {
              if (this.WGProcesses.processes_id[unit.process.id]) {
                _out.push(this.WGProcesses.processes_id[unit.process.id]);
              }
            }
          });
          return;
        }

        // units passed
        if (_item.type === 'unit') {
          let unit = _item as IUnit;
          if (unit.process) {
            if (this.WGProcesses.processes_id[unit.process.id]) {
              _out.push(this.WGProcesses.processes_id[unit.process.id]);
            }
          }
          return;
        }

        // Devices passed. Go sideways
        if (_item.type === 'device') {
          let device = _item as IDevice;
          if (device.unit) {
            let unit = this.WGUnits.units_id[device.unit.id];
            if (unit?.process) {
              if (this.WGProcesses.processes_id[unit.process.id]) {
                _out.push(this.WGProcesses.processes_id[unit.process.id]);
              }
            }
          }
          return;
        }

        // Batches passed. Go Up
        if (_item.type === 'batch') {
          let batch = _item as IBatch;
          _.forEach(batch.processes, (_process) => {
            if (this.WGProcesses.processes_id[_process.id]) {
              _out.push(this.WGProcesses.processes_id[_process.id]);
            }
          });
          return;
        }

        console.warn("Couldn't extract a useful Process", _.clone(_item));
      });
      return _out;
    }

    /**
     * Returns all sensors associated with the passed items
     * @param items - anything with ['type'] field - places|units|processes|devices|batches
     * @returns {[]}
     */
    public extractSensors(items = []) {
      // TODO: Implement this
    }


    // --------  Build our data-structure, reconnecting data-sets

    // Reconnect units & places
    public link_units_and_places() {
      if (!this.WGPlaces.ready && !this.WGPlaces.processing) {
        return;
      }
      if (!this.WGUnits.ready && !this.WGUnits.processing) {
        return;
      }


      // Clear reverse connections
      _.forEach(this.WGPlaces.places, (place) => {
        // if (WG_debug) {
        //   _.forEach(place.units, (_unit) => {
        //     if (_unit?.id && !this.WGUnits.units_id[_unit.id]) {
        //       console.warn("Place with invalid Unit!", {place_id: place.id, unit_id: _unit.id});
        //     }
        //   });
        // }
        place.units = emptyOrCreateArray(place.units);
      });

      _.forEach(this.WGUnits.units, (unit) => {
        if (!unit) return;

        let _place: IPlace = null;
        if (unit?.place?.id) {
          _place = this.WGPlaces.places_id[unit.place.id];
        }
        if (!_place) {
          let _unplace_id = -unit.owner?.id || this.UNPLACED_UNITS_PLACE_ID;
          _place = this.WGPlaces.places_id[_unplace_id];
          // Create virtual Place if not exists
          if (!_place) {
            _place = this.WGPlaces.merge_entry({
              id: _unplace_id,
              name: this.$translate.instant("app.overview.UNPLACED_UNITS_NAME"),
              name_sref: "app.overview.UNPLACED_UNITS_NAME",
              owner: {id: -_unplace_id, username: unit.owner?.username || null},
            }) as IPlace;
            if (WG_debug) console.debug("Created virtual Place!", {place_id: _place.id});
          }
        }

        if (_place) {
          unit.place = _place;
          _place.units.push(unit);
        } else {
          if (WG_debug) console.warn("Failed to create unPlaced Unit!", _.cloneDeep({unit: unit}));
          unit.place = undefined;
        }
      });

      // If there's more than 1 place, ignoring all_units_place, create an All Units Place:
      let _all_units_place = this.WGPlaces.places_id[this.ALL_UNITS_PLACE_ID];
      if (_.size(this.WGPlaces.places) - (_all_units_place ? 1 : 0) > 1) {
        if (!_all_units_place) {
          _all_units_place = this.WGPlaces.merge_entry({
            id: this.ALL_UNITS_PLACE_ID,
            name: this.$translate.instant("app.overview.places.ALL_UNITS"),
            name_sref: "app.overview.places.ALL_UNITS",
            owner: {id: 0, username: null}, // Ordered by owner.id, this always shows first
          });
          if (WG_debug) console.debug("Created All Units Place!");
        }
        _.forEach(this.WGUnits.units, (unit) => {
          _all_units_place?.units.push(unit);
        });
      }

      // console.log("Reconnecting Units & Places")
    }

    /***
     * Reconnect units & processes
     * This may delete processes. If it does, it should call WGProcesses.process_data()
     **/
    public link_processes_and_units() {
      if (!this.WGUnits.ready && !this.WGUnits.processing) {
        return;
      }
      if (!this.WGProcesses.ready && !this.WGProcesses.processing) {
        return;
      }

      // Clear reverse connections
      _.forEach(this.WGProcesses.processes, (process) => {
        // if (WG_debug) {
        //   _.forEach(process.units, (_unit) => {
        //     if (_unit?.id > 0 && !this.WGUnits.units_id[_unit.id]) {
        //       if (process.active) {
        //         console.warn("Active Process with invalid Unit! Deleted?", {
        //           process_id: process.id,
        //           unit_id: _unit.id
        //         });
        //       } else {
        //         console.warn("Inactive Process with invalid Unit!", {
        //           process_id: process.id,
        //           unit_id: _unit.id
        //         });
        //       }
        //     }
        //   });
        // }
        process.units = emptyOrCreateArray(process.units);
        process.unit = undefined;
      });

      _.forEach(this.WGUnits.units, (unit) => {
        if (unit?.process?.id) {

          let process = this.WGProcesses.processes_id[unit.process.id];
          if (process) {
            // if active or started_at < current time and ended_at > current time. Convert them to Date objects
            if (process.active) {
              unit.process = process;
              process.unit = unit;
              process.units.push(unit);
            } else {
              // Detect and don't link finished processes
              unit.process = undefined;
            }
          } else {
            // if (WG_debug) console.warn("Unit with inexistent Process!", {unit_id: unit.id});
            unit.process = undefined;
          }

        }
      });

      // Remove processes associated with deleted Unit
      _.remove(this.WGProcesses.processes, (process: IProcess) => {
        if (!process) {
          return false;
        }
        if (_.isEmpty(process.units)) {
          delete this.WGProcesses.processes_id[process.id];
          if (WG_debug) console.warn("Removing Process associated with deleted Units!", _.cloneDeep({process: process, units: this.WGUnits.units}));
          return true;
        }
        if (_.isNil(process.unit)) {
          delete this.WGProcesses.processes_id[process.id];
          if (WG_debug) console.warn("Removing Process associated with deleted Unit!", _.cloneDeep({process: process, units: this.WGUnits.units}));
          return true;
        }
        return false;
      });

      // console.log("Reconnected Units & Processes");
    }

    // Reconnect units & devices.
    public link_devices_and_units() {// Nesting devices->units->devices
      if (!this.WGUnits.ready && !this.WGUnits.processing) {
        return;
      }
      if (!this.WGDevices.ready && !this.WGDevices.processing) {
        return;
      }

      // Clear reverse connections and LKM values
      _.forEach(this.WGUnits.units, (unit) => {
        // if (WG_debug) {
        //   _.forEach(unit.devices, (_device) => {
        //     if (!this.WGDevices.devices_id[_device.id]) {
        //       console.warn("Unit with invalid Device!", {unit_id: unit.id, device_id: _device.id});
        //       if (WG_debug) unit.name = 'INV - ' + unit.name;
        //     }
        //   });
        // }
        unit.devices = emptyOrCreateArray(unit.devices) as IDevice[];
      });
      let _there_are_unplaced = false;
      let _has_sensors = false;
      _.forEach(this.WGDevices.devices, (device) => {
        if (!device) return;
        if (SMARTBOX_MODELS.includes(device.model)) {
          // Ignore SmartBoxes
          return;
        }
        _has_sensors = true;
        let _unit = null as IUnit;
        if (device.unit?.id) {
          _unit = this.WGUnits.units_id[device.unit.id];
        }
        if (!_unit) {
          _unit = this.WGUnits.units_id[-device.id];
          if (!_unit) {
            _unit = this.WGUnits.merge_entry({
              id: -device.id, // unique negative ID.
              unit_type: device.unit_type,
              owner: {id: device.owner?.id || 0, username: device.owner?.username || null},
            }) as IUnit;
          }
          // if (WG_debug) console.debug("Created virtual Unit!", {unit_id: _unit.id});
        }

        if (!_unit || _unit.id < 0) {
          _there_are_unplaced = true;
        }
        if (_unit) {
          this.DataUtils.syncUnitDeviceName(_unit, device);
          device.unit = _unit;
          _unit.devices.push(device);
        } else {
          if (WG_debug) console.warn("Failed to create virtual Unit!", {device_id: device.id});
          device.unit = undefined;
        }
      });

      _.forEach(this.WGUnits.units_id, (unit, id) => { // Remove
        if (!unit) {
          return;
        }
        if (unit.id < 0 && _.isEmpty(unit.devices)) {
          _.remove(this.WGUnits.units, {id: unit.id});
          delete this.WGUnits.units_id[unit.id];
        }
      });
      this.WGDevices.there_are_unplaced = _there_are_unplaced;
      this.WGDevices.has_sensors = _has_sensors;
      // console.log("Reconnected Devices & Units");
    }

    public link_devices_and_sensors() {
      if (!this.WGDevices.ready && !this.WGDevices.processing) {
        return;
      }
      if (!this.WGSensors.ready) {
        return;
      }

      _.forEach(this.WGDevices.devices, (_device: IDevice) => {

            // Process every last_known_message as new data
            _.forEach(_device.last_known_message, (payload, key) => {
              if (!payload.timestamp) return;

              var tmp = this.$rootScope.getResources(key);
              var _stream = tmp['stream'] || key;
              if (!_stream) return;

              if (!_device.streams.includes(_stream))
                _device.streams.push(_stream);

              // This depends on Sensors to be valid
              this.WGDevices.processLastKnownMessage(_device.uuid, _stream, payload.timestamp, _.cloneDeep(payload), false);
            });
            // delete _device.last_known_message;

            // We need to immediately update the status, as other things depend on this
            this.DataUtils.update_device_status(_device);
            this.DataUtils.update_device_card_parameters(_device);
          }
      );

      this.DataUtils.update_smartbox_status();

      // console.log("Reconnected Devices & Units");
    }

    /***
     * Reconnect alarms & devices
     * This may delete devices. If it does, it should call WGDevices.process_data()
     **/
    public link_alarms_and_devices() {
      if (!this.WGAlarms.ready && !this.WGAlarms.processing) {
        return;
      }
      if (!this.WGDevices.ready && !this.WGDevices.processing) {
        return;
      }

      // Clear reverse connections
      _.forEach(this.WGDevices.devices, (device) => {
        // if (WG_debug) {
        //   _.forEach(device.alarms, (_alarm) => {
        //     if (_alarm?.id > 0 && !this.WGAlarms.alarms_id[_alarm.id]) {
        //       console.warn("Device with invalid Alarm!", {
        //         device_id: device.id,
        //         alarm_id: _alarm.id
        //       });
        //     }
        //   });
        // }
        device.alarms = emptyOrCreateArray(device.alarms);
      });

      _.forEach(this.WGAlarms.alarms, (alarm) => {
        if (!alarm || !alarm.device) return;

        if (alarm.active === false) return;

        let _device = null;
        if (alarm.device.id > 0) {
          _device = this.WGDevices.devices_id[alarm.device.id];
        }
        if (!_device && alarm.device.uuid) {
          _device = this.WGDevices.devices_uuid[alarm.device.uuid];
        }
        // Ignore alarms from accounts we are not viewing
        if (!_device && alarm.device.owner?.username != this.AuthService.view_as_owner?.username) {
          //if (WG_debug) console.info("Ignoring alarm from other account", alarm);
          return;
        }
        if (!_device && alarm.device.iid) {
          _device = _.find(this.WGDevices.devices, {iid: alarm.device.iid});
          if (WG_debug && _device) console.warn("Alarm configured with Device iid!", {alarm_id: alarm.id});
        }

        if (_device) {
          alarm.device = _device;
          _device.alarms.push(alarm);
          // if (WG_debug) console.warn("Alarm linked to Device!", {alarm: alarm, device: _device});
        } else {
          if (WG_debug) console.warn("Alarm with inexistent Device!", {alarm_id: alarm.id});
          alarm.device = undefined;
        }
      });

      // console.log("Reconnected Alarms & Devices");
    }

    // Reconnect batches & processes
    public link_processes_and_batches() {// Nesting processes->batches->processes
      if (!this.WGProcesses.ready && !this.WGProcesses.processing) {
        return;
      }
      if (!this.WGBatches.ready && !this.WGBatches.processing) {
        return;
      }


      // Clear reverse connections
      _.forEach(this.WGBatches.batches, (batch) => {
        // if (WG_debug) {
        //   _.forEach(batch.processes, (_process) => {
        //     if (_process.active && !this.WGProcesses.processes_id[_process.id]) {
        //       console.warn("Batch with invalid Process!", {batch_id: batch.id, process_id: _process.id});
        //     }
        //   });
        // }
        batch.processes = emptyOrCreateArray(batch.processes);
      });

      _.forEach(this.WGProcesses.processes, (process) => {
        if (process?.batch?.id) {

          let _batch = this.WGBatches.batches_id[process.batch.id];
          if (_batch) {
            process.batch = _batch;
            _batch.processes.push(process);
          } else {
            // if (WG_debug) console.warn("Process with invalid Batch!", {
            //   process_id: process.id,
            //   batch_id: _batch.id
            // });
            process.batch = undefined;
          }
        }
      });

      // console.log("Reconnected Processes & Batches");
    }


    // Reconnect notifications & devices. Depends and changes Units
    public link_notifications_and_units_devices() {
      if (!this.$rootScope.last_notifications) {
        return;
      }
      if (!this.WGUnits.ready && !this.WGUnits.processing) {
        return;
      }
      if (!this.WGDevices.ready && !this.WGDevices.processing) {
        return;
      }


      _.forEach(this.WGUnits.units, (unit) => {
        if (!unit.alarms) {
          unit.alarms = {};
        }
        unit.alarms.user_notifications = emptyOrCreateArray(unit.alarms.user_notifications);
        unit.alarms.ai_notifications = emptyOrCreateArray(unit.alarms.ai_notifications);

        // No notifications. Just clear unit's and exit
        if (_.isEmpty(this.$rootScope.last_notifications.list)) {
          return;
        }
        if (_.isEmpty(unit.devices)) {
          return;
        }

        _.forEach(unit.devices, (device) => {
          if (!device || !device.id) {
            return;
          }
          _.forEach(this.$rootScope.last_notifications.list, (notification) => {
            if (notification.read || !notification.notification) {
              return;
            }
            let _notification_from_this_device = false;
            if (notification.notification['published_device']?.id === device.id) {
              _notification_from_this_device = true;
            } else if (notification.notification['published_alarm']?.device?.id === device.id) {
              _notification_from_this_device = true;
            }
            if (!_notification_from_this_device) {
              return;
            }

            let _notification = _.cloneDeep(notification);
            let _notification_configs = _notification.notification.config_fields;

            // Insert in the correct list according to notification.notification.type
            if (_notification_configs && _notification.notification['type'] === 'ai' || _notification['type'] === 'ai') { // Type is at notification.notification.type, but let's check
              _notification.actions = emptyOrCreateArray(_notification.actions);


              if (_notification_configs['title_sref']) {
                _notification['title_sref'] = _notification_configs['title_sref'];
              }
              if (_notification_configs['message_sref']) {
                _notification['message_sref'] = _notification_configs['message_sref'];
              }
              if (_notification_configs['actions_type']) {
                switch (_notification_configs['actions_type'].toUpperCase()) {
                  case "FERMENTATION_STARTED":
                  case "FERMENTATION_STARTED_TITLE":
                    if (!unit.process || !unit.process.active) {
                      // Else, already in a process. Nothing to suggest
                      _notification.unit_id = unit.id;
                      _notification.actions.push({
                        title_sref: "app.notifications.actions.START_FERMENTATION_PROCESS",
                        on_click: (notif_data) => {
                          // if (ngDialog && ngDialog.close) ngDialog.close();
                          this.$rootScope.doEditProcess('create', null, notif_data.unit_id);
                        }
                      });
                    }
                    break;

                  case "FERMENTATION_STAGNATED":
                  case "FERMENTATION_STAGNATED_TITLE":
                    // Nothing to suggest
                    break;

                  case "FERMENTATION_END":
                  case "FERMENTATION_END_TITLE":
                    if (device && device.status_density_reading === true) {
                      _notification.device_id = device.id;
                      _notification.actions.push({
                        title_sref: "app.notifications.actions.STOP_DENSITY_READ",
                        on_click: (notif_data) => {
                          // if (ngDialog && ngDialog.close) ngDialog.close();
                          this.$rootScope.doConfigReadDensity(notif_data.device_id, false);
                        }
                      });
                    }
                    if (unit.process && unit.process.active && unit.process.id) {
                      _notification.process_id = unit.process.id;
                      _notification.actions.push({
                        title_sref: "app.notifications.actions.STOP_FERMENTATION_PROCESS",
                        on_click: (notif_data) => {
                          // if (ngDialog && ngDialog.close) ngDialog.close();
                          this.$rootScope.doStopProcesses(notif_data.process_id);
                        }
                      });
                    }
                    break;
                }
              }

              // @ts-ignore
              unit.alarms.ai_notifications.push(_notification);
            } else {
              unit.alarms.user_notifications.push(_notification);
            }
          });
        });
      });
    }


// --------  Individual processing functions when data arrives

    // Reconnects notifications and devices. Depends and changes Units
    public process_notifications_updates() {
      if (!this.$rootScope.last_notifications
          || !this.$rootScope.last_notifications.list) {
        return;
      }

      this.link_notifications_and_units_devices();

      // Re-run other functions dependent on these connections
      this.update_units_status(this.WGPlaces.places);

      this.$rootScope.$broadcast('notifications_updated');
    }


// --------------------- Get updated data from server ---------------------//

    // Prioritizes units according to their status: Alarms, Favorites, Running processes, etc
    // Updates cards' notifications
    // Uses Units' devices, alarms, processes,
    public update_units_status(items: IUnit[] | any = this.WGUnits.units) {
      // All other datasets are optional
      if (!this.WGUnits.ready && !this.WGUnits.processing) {
        return;
      }

      // console.log('update_units_status', items);
      _.forEach(this.extractUnits(items), (unit) => {

        // console.log('Calculating unit priority', unit);
        unit.priority = 0;

        if (!unit.alarms) {// unPlaced devices' units can get here without this
          unit.alarms = {};
        }
        unit.alarms.status_notifications = emptyOrCreateArray(unit.alarms.status_notifications);

        // Default units Importance, to define order.
        // Highest == most important, shown first:
        let _priorities = {
          'DeviceON': 20, // Device On
          'UserAlarms': 25, // User Alarms
          'AIAlarms': 15, // AI Alarms
          // 'Favorite': 13, // Is user Favorite
          'ProcessRunning': 11, // Process Running
          'WPOnWithoutProcess': 9, // Vat Collecting without a Process running
          'ProcessWithoutDevice': 7, // Process Running but device is not-ON
          'DensityReadingDisabled': 5, // Density reading is disabled
          'DeviceHasDataOFF': 3, // Device is OFF
          'DensityDescending': 2, // Has density measurement, minus density
          'DeviceNoData': 0, // Device doesn't yet have data
          // Else, no device nor process
        }

        if (!_.isEmpty(unit.alarms.user_notifications)) {
          unit.priority |= (1 << _priorities['UserAlarms']);
        }

        if (!_.isEmpty(unit.alarms.ai_notifications)) {
          unit.priority |= (1 << _priorities['AIAlarms']);
        }

        if (unit.unit_type === "vat"
            && unit.devices?.[0] && (unit.devices[0].status === "ON" || unit.devices[0].status === "FAULT")
            && unit.devices[0].status_density_reading === true
            && !unit.process?.active) {
          unit.priority |= (1 << _priorities['WPOnWithoutProcess']);

          if (!this.AuthService.user.configs.ai_notifications?.['DENS_AFTER_FERMENTATION']
              || this.AuthService.user.configs.ai_notifications['DENS_AFTER_FERMENTATION'].active) {
            let _current_density = 1.0;
            if (unit.devices[0].last_values?.['QL_TREAT_LDENSA_massDensity']?.val_numeric) {
              _current_density = unit.devices[0].last_values['QL_TREAT_LDENSA_massDensity'].val_numeric;
            }
            if (_current_density > 1.05) {
              unit.alarms.status_notifications.push({
                id: 0,
                title_sref: "app.notifications.DENS_AFTER_FERMENTATION_TITLE",
                message_sref: "app.notifications.DENS_WITHOUT_FERMENTATION_MESSAGE",
                timestamp: new Date().toLocaleString(),
                allow_mark_read: false,
                unit: unit,
                unit_id: unit.id,
                device: unit.devices[0],
                device_id: unit.devices[0].id,
                actions: [
                  {
                    title_sref: "app.notifications.actions.START_FERMENTATION_PROCESS",
                    on_click: (notif_data) => {
                      // if (ngDialog && ngDialog.close) ngDialog.close();
                      this.$rootScope.doEditProcess('create', null, notif_data.unit_id);
                    }
                  },
                ]
              });
            } else {

              unit.alarms.status_notifications.push({
                id: 0,
                title_sref: "app.notifications.DENS_AFTER_FERMENTATION_TITLE",
                message_sref: "app.notifications.DENS_AFTER_FERMENTATION_MESSAGE",
                timestamp: new Date().toLocaleString(),
                allow_mark_read: false,
                unit_id: unit.id,
                device_id: unit.devices[0].id,
                actions: [
                  {
                    title_sref: "app.notifications.actions.STOP_DENSITY_READ",
                    on_click: (notif_data) => {
                      // if (ngDialog && ngDialog.close) ngDialog.close();
                      this.$rootScope.doConfigReadDensity(notif_data.device_id, false);
                    }
                  },
                ]
              });
            }

          }
        }
        // if (unit.config_fields?.is_user_favorite?.[this.AuthService.user.uuid]) {
        //   unit.priority |= (1 << _priorities['Favorite']);
        // }

        if (unit.process?.active
            && unit.devices?.[0]?.status !== "ON"
            && unit.devices?.[0]?.status !== "FAULT") {
          unit.priority |= (1 << _priorities['ProcessWODevice']);
          if (!this.AuthService.user.configs.ai_notifications?.['DEVICE_MISSING']
              || this.AuthService.user.configs.ai_notifications['DEVICE_MISSING'].active) {
            unit.alarms.status_notifications.push({
              id: 0,
              title_sref: "app.notifications.FERMENTATION_WITHOUT_DENS_TITLE",
              message_sref: "app.notifications.FERMENTATION_WITHOUT_DENS_MESSAGE",
              timestamp: new Date().toLocaleString(),
              allow_mark_read: false,
              process_id: unit.process.id,
              actions: [
                {
                  title_sref: "app.notifications.actions.STOP_FERMENTATION_PROCESS",
                  on_click: (notif_data) => {
                    // if (ngDialog && ngDialog.close) ngDialog.close();
                    this.$rootScope.doStopProcesses(notif_data.process_id);
                  }
                },
              ]
            });
          }
        }

        if (unit.process) {
          unit.priority |= (1 << _priorities['ProcessRunning']);
        }
        if (unit.devices?.[0]) {
          if (unit.devices[0].status === "ON" || unit.devices[0].status === "FAULT") {
            unit.priority |= (1 << _priorities['DeviceON']);
          }

          if (_.isEmpty(unit.devices[0].last_values)) {
            unit.priority |= (1 << _priorities['DeviceNoData']);
          } else {
            if (unit.devices[0].status === "OFF") {
              unit.priority |= (1 << _priorities.DeviceHasDataOFF);
            }
            if (_.isFinite(unit.devices[0].last_values['QL_TREAT_LDENSA_massDensity']?.val_orig)) {
              // @ts-ignore
              unit.priority += _priorities.DensityDescending - unit.devices[0].last_values['QL_TREAT_LDENSA_massDensity'].val_orig;
            }
          }

          if (unit.devices[0].status_density_reading === false) {
            unit.priority |= (1 << _priorities['DensityReadingDisabled']);
          }
        }

        unit.priority = Math.round(unit.priority);
      });
    }

    // @deprecated
    // Link unit's and device's last sensor-values for quick-access
    // TODO: Use when adding Support for multiple devices per unit
    public get_units_last_values(items: IUnit[] = this.WGUnits.units) {
      if (this.WGDevices.loading || this.WGUnits.loading) {
        return;
      }

      _.forEach(items, (unit) => {
        if (!unit) {// I don't know why, but sometimes unit is undefined here
          return;
        }
        // TODO: Support multiple devices
        if (unit.devices?.[0]?.last_values) {
          unit.last_values = unit.devices[0].last_values;
        } else {
          unit.last_values = emptyOrCreateDict(unit.last_values);
        }
      });
    }


    /**
     * Update places/units/devices/processes/batches from the DB if they have had changes applied, in parallel
     */
    public update_changed_data(force = false) {
      if (!this.current_data_user_id || !this.AuthService.isLogged) {
        return;
      }
      clearTimeout(this.update_changed_data_timer);
      // if (WG_debug) console.time("update_changed_data");

      if (this.WGSensors.changed || force) {
        this.WGSensors.update();
      }
      if (this.WGDevices.changed || force) {
        this.WGDevices.update();
      }
      if (this.WGPlaces.changed || force) {
        this.WGPlaces.update();
      }
      if (this.WGUnits.changed || force) {
        this.WGUnits.update();
      }
      if (this.WGProcesses.changed || force) {
        setTimeout(this.WGProcesses.update, 500);
      }
      if (this.WGBatches.changed || force) {
        setTimeout(this.WGBatches.update, 500);
      }
      if (this.WGAlarms.changed || force) {
        setTimeout(this.WGAlarms.update, 500);
      }

      // if (WG_debug) console.timeEnd("update_changed_data");
    }

    /**
     * Singleton-like function. Batches multiple calls to a single one after a short time.
     * Run is postponed if timeout is defined. Else it keeps the last defined scheduled.
     * @param _timeout - ms to wait for
     */
    public update_changed_data_soon(_timeout = null) {
      if (!_timeout && this.update_changed_data_timer) {
        // Unspecified timeout and Device is already scheduled. Ignore.
        return;
      }
      if (!_timeout) {
        _timeout = 500;
      }
      if (this.update_changed_data_timer) {
        console.log('update_changed_data postponing', _timeout);
        clearTimeout(this.update_changed_data_timer);
      }
      // if (WG_debug) console.log('update_changed_data scheduled', _timeout);
      this.update_changed_data_timer = setTimeout(() => {
        this.update_changed_data_timer = undefined;
        this.update_changed_data();
      }, _timeout);
    };

    /**
     * Process places/units/devices/processes/batches local data if they have changes pending
     */
    public process_data() {
      clearTimeout(this.process_data_timer);
      this.process_data_timer = undefined;

      // if (WG_debug) console.time("processed_changed_data");

      if (this.WGSensors.changed_locally) {
        this.WGSensors.process_data();
      }
      if (this.WGDevices.changed_locally) {
        this.WGDevices.process_data();
      }
      if (this.WGPlaces.changed_locally) {
        this.WGPlaces.process_data();
      }
      if (this.WGUnits.changed_locally) {
        this.WGUnits.process_data();
      }
      if (this.WGProcesses.changed_locally) {
        this.WGProcesses.process_data();
      }
      if (this.WGBatches.changed_locally) {
        this.WGBatches.process_data();
      }
      if (this.WGAlarms.changed_locally) {
        this.WGAlarms.process_data();
      }
      // if (WG_debug) console.timeEnd("processed_changed_data");
    }

    /**
     * Singleton-like function. Batches multiple calls to a single one after a short time.
     * Used to show intermediate results while performing multiple-actions, like moving many units.
     * @param timeout - ms to wait for
     * @param replace - if true, replace a pending timer. Otherwise ignores if already scheduled.
     */
    public process_data_soon(timeout = 500, replace = false) {
      if (!replace && this.process_data_timer) {
        if (WG_debug) console.log("Already scheduled. Ignoring.");
        return;
      }
      if (WG_debug) console.log("process_data_soon", timeout);
      clearTimeout(this.process_data_timer);
      this.process_data_timer = setTimeout(() => {
        this.process_data();
      }, timeout);
    }

  }

  App.service('WGApiData', WGApiData);
}

