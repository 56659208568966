namespace wg {
  interface IScope extends ng.IScope {
    changeSelection(item: IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm | IAlarmTemplate | ILicense): void;

    changeAllSelection(value: boolean): void;

    list_items: (IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm | IAlarmTemplate | ILicense)[];
    selection: (IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm | IAlarmTemplate | ILicense)[];
    checkboxes: {
      checked: boolean,
      items: { [id: number]: boolean }
    };
    entity_type: 'devices' | 'smartboxes' | 'processes' | 'batches' | 'places' | 'units' | 'alarms' | 'alarm_templates' | 'licenses';
  }

  App.controller('ManageDataTableController', ['$rootScope', '$scope', '$state', '$http', '$filter', '$timeout', 'ngTableParams', 'ngDialog', 'AuthService', 'WGApiData',
    function ($rootScope: IRootScope, $scope: IScope, $state: _IState, $http: ng.IHttpService, $filter: ng.IFilterService, $timeout: ng.ITimeoutService, ngTableParams: NgTable.ITableParamsConstructor<IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm | IAlarmTemplate | ILicense>, ngDialog: ng.dialog.IDialogService, AuthService: IAuthService, WGApiData: WGApiData) {

      //                                      ---     VARIABLES
      // entity_type = $state.current.url.slice(1);
      // @ts-ignore
      let entity_type: IScope['entity_type'] = $state.current?.url?.match("\/?([^\?\/&]+).*")[1];
      if (_.isEmpty(entity_type)) {
        console.error("Error, trying to access ", $state.current.url);
      }
      let entity_type_singular;
      // entity_type_singular = toSingular[entity_type] || 'unit';

      let table_sorting: { [column: string]: 'asc' | 'desc' } = {name: 'asc'}

      if (entity_type.startsWith('device')) {
        entity_type_singular = 'device';

      } else if (entity_type.startsWith('smartbox')) {
        entity_type_singular = 'smartbox';

      } else if (entity_type.startsWith('process')) {
        entity_type_singular = 'process';
        table_sorting = {
          active: 'desc',   // Active first
          created_at: 'asc',
        }

      } else if (entity_type.startsWith('batch')) {
        entity_type_singular = 'batch';

      } else if (entity_type.startsWith('place')) {
        entity_type_singular = 'place';

      } else if (entity_type.startsWith('alarm')) {
        entity_type_singular = 'alarm';

      } else if (entity_type.startsWith('alarm_template')) {
        entity_type_singular = 'alarm_template';
        table_sorting = {id: 'asc'};
      } else if (entity_type.startsWith('license')) {
        entity_type_singular = 'license';

      } else {
        entity_type_singular = 'unit';
      }

      $scope.loading = false;
      //                  --- SELECTED
      let animateSelected: ng.IPromise<any>;
      $scope.see = {
        item: null,
        is: function () {
          return !_.isNil($scope.see.item);
        },
        select: function (item) {
          if ($scope.delete_success === true)
            $scope.delete_success = null
          let _url_params = {};
          _url_params[entity_type_singular] = item.id;
          AuthService.update_url(_url_params, false, false, false);

          $scope.see.item = null;

          if (angular.isDefined(animateSelected)) {
            $timeout.cancel(animateSelected);
          }
          animateSelected = $timeout(function () {
            $scope.see.item = item;
          }, 1);
          $('html, body').animate({scrollTop: $(document).height()}, 800, 'swing');
        },
        clear: function () {
          $scope.see.item = null;
          let _url_params = {};
          _url_params[entity_type_singular] = null;
          AuthService.update_url(_url_params, false, false, false);
        }
      };

      $scope.counts = {
        ON: 0,
        OFF: 0,
        TOTAL: 0,
      };

      // --- Multi selection and actions
      // Selected entries
      $scope.selection = [];
      $scope.checkboxes = {
        checked: false, // all selected
        items: {},  // checked items
      };

      $scope.changeSelection = function (item) {
        // remove or add an item to selection array, based on item.id
        let obj = _.find($scope.selection, {id: item.id});
        if (!$scope.checkboxes.items[item.id]) {
          // De-selected
          if (obj)
            $scope.selection = _.without($scope.selection, item);
        } else {
          if (!obj) { // @ts-ignore
            $scope.selection.push(item);
          }
        }

        if (!$scope.list_items) {
          return;
        }
        var checked = 0, unchecked = 0;
        var total = $scope.list_items.length;
        angular.forEach($scope.list_items, function (item) {
          if ($scope.checkboxes.items[item.id]) {
            checked++;
          } else {
            unchecked++;
          }
        });

        // Select_all logic
        if (total > 0 && checked === total) {
          $scope.checkboxes.checked = true;
        } else {
          $scope.checkboxes.checked = false;
        }
      };

      $scope.changeAllSelection = function (value) {
        if (WG_debug) console.log("changeAllSelection", value);
        $scope.checkboxes.checked = value;
        //v// Select or de-select all items
        if (value) {
          _.forEach($scope.list_items, function (item: IPlace | IUnit | IDevice | IProcess | IBatch) {
            if (!_.isNil(item?.id)) {
              $scope.checkboxes.items[item.id] = value;
            }
          });
          $scope.selection = $scope.list_items;
        } else {
          $scope.checkboxes.items = {};
          $scope.selection = [];
        }
      };

      //  ---  Multi-actions
      $scope.batch = {
        feedback: null,
        states: {success: 'success', error: 'error', loading: 'loading'},
        action: null, // Selected action
        actions: [
          {id: 'delete', name_sref: "app.common.DELETE"},
          {id: 'stop', name_sref: "app.common.STOP"},
          {id: 'activate', name_sref: "app.common.ACTIVATE"},
          {id: 'deactivate', name_sref: "app.common.DEACTIVATE"},
        ],
        items: [], // Holds Items state during processing
        do: function () {
          console.log("Multi action ", $scope.batch.action);

          $scope.batch.items = emptyOrCreateArray($scope.batch.items);
          //    Make array for multi feedback
          _.forEach($scope.selection, function (entry, key) {
            $scope.batch.items[key] = null;
          });
          $scope.batch.feedback = $scope.batch.states.loading;

          //  ACTIONs

          if ($scope.batch.action?.id == 'stop') {
            $scope.batch.stop()
          } else if ($scope.batch.action?.id == 'delete') {
            $scope.batch.delete()
          } else if ($scope.batch.action?.id == 'activate') {
            $scope.batch.activate()
          } else if ($scope.batch.action?.id == 'deactivate') {
            $scope.batch.activate(false)
          } else {
            console.error("Batch action not recognized", $scope.batch.action);
            $scope.batch.feedback = $scope.batch.states.error;
          }
        },
        clear: function () {
          $scope.batch.action = null;
          $scope.batch.feedback = null;
          $scope.batch.items = []
        },
        delete: function () {
          console.log("Batch Delete");
          $scope.batch.feedback = $scope.batch.states.loading;

          console.log("DeleteConfirm ", entity_type, ", elements: ", $scope.selection);
          if (deleteConfirmIsOpen) {
            return
          }
          deleteConfirmIsOpen = true;
          $scope.see.clear();
          ngDialog.openConfirm({
            template: 'app/views/modals/confirm-danger.html',
            className: 'ngdialog-theme-default',
            data: {
              what: entity_type,
              which: $scope.selection[0]['name'] ? _.map($scope.selection, 'name').join(', ') : '',
            }
          })
              .then((data) => {
                console.log('Delete Confirmed. data:', data);
                _.forEach($scope.selection, function (entry, batchKey) {
                      deleteThing(entry, batchKey);
                    }
                );
              })
              .catch((reason) => {
                console.log('DELETE Modal promise rejected. Reason: ', reason);
              })
              .finally(() => {
                deleteConfirmIsOpen = false;
                $scope.loading = false;
              })

        },
        activate: function (active = true) {
          console.log("Batch activate: ", active);
          $scope.batch.feedback = $scope.batch.states.loading;
          //  *Batch activate
          _.forEach($scope.selection, function (entry: IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm | IAlarmTemplate | ILicense, batchKey) {
                if (entity_type === 'alarms') {
                  WGApiData.WGAlarms.activate(entry.id, active, (response) => {
                    $scope.reload();
                    updatedBatchFeedback(batchKey, response);
                  });
                }
                if ($scope.see.item !== null && $scope.see.item.id === entry.id)
                  $scope.see.clear();
              }
          );
        },
        stop: function () {
          console.log("Batch Units Stop");
          if (entity_type_singular != 'process' || editElemModalIsOpen) {
            console.log("batch stop modal already open");
            return;
          }
          let stopIts = [];
          //  *create pass array for dialog if action===stop
          _.forEach($scope.selection, function (process: IProcess, key) {
            if (process.active)
              stopIts.push({
                id: process.id,
                name: process.name,
                started_at: process.started_at,
                active: process.active,
                batch_key: key,
              });
          });
          editElemModalIsOpen = true;
          //  *Batch stop with dialog
          let manyStop = ngDialog.open({
            template: 'app/views/modals/stop_many.html',
            controller: 'SeeRunStopController',
            className: 'ngdialog-theme-default',
            data: {
              isMany: true,
              procs: stopIts
            }
          });
          manyStop.closePromise.then(function (data) {
            console.log("Process Stop dialog closed.",
                "data:", data);
            $scope.reload(true);
          });
        }
      };
      $scope.$watch('batch.items', function (o, n) {
        console.log("Batch.items changed:", $scope.batch.items, "state:", $scope.batch.feedback);
        if (!_.isEmpty($scope.batch.items)) {
          var k = _.indexOf($scope.batch.items, false);
          if (k > -1) {
            $scope.batch.feedback = $scope.batch.states.error;
          } else {
            $scope.batch.feedback = $scope.batch.states.success;
          }
        }
      }, true);

      // Configure available batch actions according to entity type
      switch (entity_type) {
        case 'processes':
          $scope.batch.actions = _.filter($scope.batch.actions, (action) => _.includes(['stop', 'delete'], action.id));
          break;
        case 'alarms':
          $scope.batch.actions = _.filter($scope.batch.actions, (action) => _.includes(['delete', 'activate', 'deactivate'], action.id));
          break;
        case 'places':
        case 'units':
        case 'batches':
        case 'alarm_templates':
        default:
          $scope.batch.actions = _.filter($scope.batch.actions, (action) => _.includes(['delete'], action.id));
          break;

      }

      //                  --- DATA-TABLE
      $scope.list_items = [];

      $scope.search = {
        all: '',
        do_search: function () {
          console.log("Search:", $scope.search.all);
          $scope.tableParams.reload();
        },
      };
      $scope.showingFrom = 0;
      $scope.showingTo = 0;

      if (entity_type.includes('alarm') && (!WGApiData.WGDevices.ready || !WGApiData.WGSensors.ready || _.isEmpty(AuthService.viewing_notification_targets))) {
        // If not enough data to build view, reprocess when ready
        let unhook1 = $rootScope.$watchGroup(['WGSensors.ready', 'WGDevices.ready', 'AuthService.viewing_notification_targets'], () => {
          preprocess_data($scope.list_items);
          if (!WGApiData.WGSensors.ready || !WGApiData.WGDevices.ready || _.isNil(AuthService.viewing_notification_targets)) {
            if (WG_debug) console.log("Not all data ready. Waiting", WGApiData.WGSensors.ready, WGApiData.WGDevices.ready, !_.isEmpty(AuthService.viewing_notification_targets));
            return;
          }
          unhook1?.();
          if (WG_debug) console.log("All data ready. Reloading", entity_type);
          // $scope.tableParams.reload();
          return;
        });

      } else {
          if (WG_debug) console.log("Data was ready.", entity_type);
      }

      $scope.tableParams = new ngTableParams({
        page: 1,            // show first page
        count: 25,          // initial count per page
        sorting: table_sorting,
        filter: {}
      }, {
        total: 0, // length of data
        paginationMaxBlocks: 7, // 7 = first, 3 centered, last
        paginationMinBlocks: 3,
        getData: (params) => {
          $scope.loading = true;
          console.log("Get-TD " + entity_type);
          let search = $scope.search.all;
          let filter = '';
          let obj = params.filter();
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (angular.isDefined(obj[key]) && obj[key] !== '')
                filter += key + '=' + obj[key] + '&';
            }
          }
          let query = '';
          if (params.orderBy() + '' !== '') {
            query += '&ordering=' + params.orderBy();
          }
          if (angular.isDefined(search) && search !== '') {
            query += '&search=' + search;
          } else {
            query += '&page=' + params.page();
          }
          if (filter !== '') {
            filter = filter.substring(0, filter.length - 1);
            query += '&' + filter;
          }
          let request_type: string = entity_type;
          if (entity_type == 'alarms') {
            request_type = 'alarm'
          }
          if (entity_type == 'alarm_templates') {
            request_type = 'alarm_template'
          }
          // console.log('Get-TD api/dashboard/'+cena+'/?page=' + params.page() + '&page_size=' + params.count() + query, "AND search:", search);
          return $http.get<IDataResponse<IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm | IAlarmTemplate | ILicense>>('api/dashboard/' + request_type + '/?page_size=' + params.count() + query, {})
              .then(function (response) {
                console.log("Got-TD " + entity_type + ". response:", response);

                $scope.list_items = preprocess_data(response.data.results);


                $scope.changeAllSelection(false);
                //angular.forEach(data, function(user) {
                //  var obj = _.find($scope.users,  {id: user.id});
                //  if (angular.isDefined(obj)) {
                //    angular.extend(obj, user);
                //    $scope.checkboxes.items[user.id] = false;
                //  } else {
                //    $scope.users.push(user);
                //  }
                //});

                _.forEach($scope.list_items, function (elem) {
                  if (_.toInteger($state?.params?.[entity_type_singular]) == elem.id) {
                    $scope.see.select(elem);
                    return false;
                  }
                });

                // update table params
                $scope.updateTotals(response.data.count);

                return $scope.list_items
              }).catch((error) => {
                console.error(error);
                return $scope.list_items
              }).finally(() => {
                $scope.loading = false;
              });
          // $scope.getGroups();
        }
      });

      //                              ---     ACTIONS
      var editElemModalIsOpen = false;
      var deleteConfirmIsOpen = false;

      $scope.create_process_element = function () {
        if (editElemModalIsOpen) {
          console.log("attempt to open second edit modal");
          return;
        }
        editElemModalIsOpen = true;

        var element = $scope.see.item;

        //console.log("Create modal for process with",entity_type);
        var dialogTemplate = 'app/views/modals/edit-dialog-process.html';
        var dialogData = {
          process: {},
          result: 'selectedUnitEditProcess',
          mode: 'openConfirm',
          operation: 'create',
          closeByEscape: false,
          closeByDocument: false,
          hasSmtg: false,
          hasWhat: '',
        };

        // object
        if ($scope.see.is()) {
          dialogData.hasSmtg = true;
          dialogData.hasWhat = entity_type_singular;
          dialogData[entity_type_singular] = $scope.see.item;
        }
        //
        // if(entity_type==='batches'){
        //   dialogData.hasWhat= 'batch';
        //   dialogData.batch= element;
        // }else if(entity_type==='units'){
        //   dialogData.hasWhat= 'unit';
        //   dialogData.unit= element;
        // }
        var dialog = ngDialog.open({
          template: dialogTemplate,
          data: dialogData
        });
        dialog.closePromise.then(function (obj) {
          editElemModalIsOpen = false;

          console.log("close_create_process_element_Dialog. obj:", obj);
          $scope.tableParams.reload();
        });
      };

      // Open modal to CREATE/EDIT: Batch // Place // Unit // Alarm
      $scope.edit = function (elem, whatThis, withThis) {
        console.log("OpenEdit", {entity_type: entity_type, whatThis: whatThis, withThis: withThis});

        if (editElemModalIsOpen) {
          console.log("Attempt to open second edit modal");
          return;
        }
        editElemModalIsOpen = true;
        let dialog: { closePromise?: ng.IPromise<any> } = null;

        //  accao
        let accao = angular.isDefined(elem) ? 'edit' : 'create';

        if (entity_type == 'alarms') {
          dialog = $rootScope.modalSetAlarm(elem?.device || elem);
        } else {

          let options = {
            template: 'app/views/modals/edit-dialog-' + entity_type + '.html',
            width: 540,
            data: {
              elemType: entity_type,
              action: accao,
              elem: elem,
              hasSmtg: false,
              hasWhat: '',
              place: null,
            }
          };

          // WITH OBJECT? only for units
          if ($scope.see.is() && $scope.pages === 'units') {
            options.data.hasSmtg = true;
            options.data.hasWhat = entity_type_singular;
            options.data[entity_type_singular] = $scope.see.item;
          }

          // -------------------------------Create Unit in place----------------------------------//
          if (whatThis === 'unit' && withThis === 'place') {
            options = {
              template: 'app/views/modals/edit-dialog-units.html',
              width: 540,
              data: {
                elemType: entity_type,
                action: 'create',
                elem: $scope.see.item,
                hasSmtg: true,
                hasWhat: 'place',
                place: $scope.see.item,
              }
            };
          }

          //  GO!
          dialog = ngDialog.open(options);

        }

        if (!dialog) {
          editElemModalIsOpen = false;
          if (WG_debug) console.warn("ngDialog didn't open?");
          return;
        }

        dialog?.closePromise?.then(function (obj) {
          // console.log("closeElemEditDialog. obj:", obj, "list_items", $scope.list_items);
          // if ((typeof obj.value === "object") && (obj.value !== null)) {
          //   $scope.see.item = obj.value.item_data;
          //   let d = _.findIndex($scope.list_items as (IDevice | IPlace | IUnit | IProcess | IBatch | IAlarm | IAlarmTemplate | ILicense)[], {id: obj.value.item_data.id});
          //   // let d = _.findIndex($scope.list_items, function (x: IPlace | IUnit | IDevice | IProcess | IBatch) {
          //   //   return x.id === obj.value.item_data.id;
          //   // });
          //   if (d < 0) {
          //     $scope.list_items.push(obj.value.item_data);
          //     $scope.list_items.sort(order_names);
          //   } else {
          //     $scope.list_items[d] = obj.value.item_data;
          //   }
          // }
          // $scope.reload();
        }, function (reason) {
        }).finally(function () {
          editElemModalIsOpen = false;
          $scope.reload();
        });
      };


      $scope.delete = function (elemento, batchKey) {
        $scope.loading = true;
        // if (batchKey >= 0) {
        //   deleteThing(elemento, batchKey);
        //   return
        // }

        console.log("DeleteConfirm ", entity_type, ", element: ", elemento);
        if (!deleteConfirmIsOpen) {
          deleteConfirmIsOpen = true;
        } else {
          $scope.loading = false;
          return
        }
        ngDialog.openConfirm({
          template: 'app/views/modals/confirm-danger.html',
          className: 'ngdialog-theme-default',
          data: {
            what: entity_type,
            which: elemento.name
          }
        }).then(function (data) {
          console.log('Delete Confirmed. data:', data);
          deleteThing(elemento, batchKey);
          deleteConfirmIsOpen = false;
        }, function (reason) {
          deleteConfirmIsOpen = false;
          $scope.loading = false;
          console.log('DELETE Modal promise rejected. Reason: ', reason);
        })
      };

      //                              ---     FUNCTIONS

      $scope.delete_success = null;
      $scope.del_suc_null = function () {
        $scope.delete_success = null
      };

      function updatedBatchFeedback(batchKey, result: '' | 'success' | 'error') {
        if (batchKey >= 0) {
          $scope.batch.items[batchKey] = (result === 'success');
        } else {
          $scope.delete_success = (result === 'success');
          $scope.see.clear();
        }
        $scope.loading = false;
        if (result === 'error') {
          console.error(result);
        }
      }

      function deleteThing(element, batchKey) {
        if (!element || element.id < 0) {
          return;
        }
        console.log('Delete Confirmed');
        if (entity_type === 'places') {
          WGApiData.WGPlaces.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else if (entity_type === 'units') {
          WGApiData.WGUnits.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else if (entity_type === 'devices') {
          WGApiData.WGDevices.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else if (entity_type === 'processes') {
          WGApiData.WGProcesses.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else if (entity_type === 'batches') {
          WGApiData.WGBatches.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else if (entity_type === 'alarms') {
          WGApiData.WGAlarms.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else if (entity_type === 'alarm_templates') {
          WGApiData.WGAlarms.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          }, true);
        } else {
          console.log("DELETE: entity_type not recognized:", entity_type);
        }


        // $http.delete('api/dashboard/' + entity_type + '/' + element.id + '/')
        //     .then(function (response) {
        //       WGApiData.WGUnits.changed = true;
        //       WGApiData.WGProcesses.changed = true;
        //       WGApiData.WGDevices.changed = true;
        //
        //       //  -Array para definir os items seleccionados
        //       var obj = _.find($scope.selection, {id: element.id});
        //       if (angular.isDefined(obj)) {
        //         $scope.selection = _.without($scope.selection, obj) as IDevice[] | IPlace[] | IUnit[] | IProcess[] | IBatch[];
        //       }
        //
        //       //  -Array para popular a dataTable
        //       obj = _.find($scope.list_items, {id: element.id});
        //       if (angular.isDefined(obj)) {
        //         $scope.list_items.splice(_.indexOf($scope.list_items, obj), 1);
        //         //$scope.users = _.without($scope.users, obj);
        //         $scope.checkboxes.items[element.id] = false;
        //         $scope.updateTotals($scope.tableParams.total() - 1);
        //         if ($scope.list_items.length === 0 || $scope.selection.length === 0) {
        //           $scope.reload();
        //         }
        //       }
        //
        //       //  -Batch User Feedback
        //       if (batchKey >= 0) {
        //         $scope.batch.items[batchKey] = true;
        //       } else {
        //         $scope.delete_success = true;
        //         $scope.see.clear();
        //       }
        //       $scope.loading = false;
        //     }, function (response) {
        //       //  -Batch User Feedback
        //       if (batchKey >= 0) {
        //         $scope.batch.items[batchKey] = false;
        //       } else {
        //         $scope.delete_success = false;
        //       }
        //       $scope.loading = false;
        //       console.error(response);
        //     });
      }

      //                              --- FUNCTIONS
      $scope.updateTotals = function (total) {
        $scope.tableParams.total(total);
        $scope.showingFrom = ($scope.tableParams.page() - 1) * $scope.tableParams.count() + 1;
        if ($scope.showingFrom > $scope.list_items.length)
          $scope.showingFrom = $scope.list_items.length;
        $scope.showingTo = $scope.showingFrom - 1 + $scope.tableParams.count();
        if ($scope.showingTo > $scope.list_items.length)
          $scope.showingTo = $scope.list_items.length;
        if ($scope.showingTo > $scope.tableParams.total())
          $scope.showingTo = $scope.tableParams.total();
      };

      $scope.reload = function () {
        // $state.reload();
        $rootScope.$broadcast('adminReload');
      };


      /**
       * Preprocess data, such as linking devices to devices-object, sensors, etc
       * @param data. list_devices if null
       */
      function preprocess_data(data) {
        if (!data) {
          data = $scope.list_items;
        }
        if (WG_debug) console.log("Preprocess_data", entity_type);

        _.forEach(data, (elem) => {
          if (_.isNil(elem)) { // sanity check
            return;
          }

          AuthService.anonymize_entity(entity_type_singular, elem);

          if (entity_type.includes('alarm')) {
            if (elem.actions) {
              elem.actions = parseData(elem.actions);
            }

            if (WGApiData.WGDevices.ready) {
              // We may be seeing a device from another account
              let _device = _.first(WGApiData.extractDevices(elem.device));
              if (_device) {
                elem.device = _device;
              }
            }

            if (WGApiData.WGSensors.ready) {
              if (elem.parameter?.id) {
                elem.parameter = WGApiData.WGSensors?.sensors_id?.[elem.parameter.id];
              } else if (elem.parameter && _.isNumber(elem.parameter)) {
                elem.parameter = WGApiData.WGSensors?.sensors_id?.[elem.parameter];
              } else if (elem.parameter && _.isString(elem.parameter)) {
                elem.parameter = WGApiData.WGSensors?.sensors_name?.[elem.parameter];
              }
              _.forEach(elem.rules, function (rule) {
                if (rule.parameter?.id) {
                  rule.parameter = WGApiData.WGSensors?.sensors_id?.[rule.parameter.id];
                } else if (rule.parameter && _.isNumber(rule.parameter)) {
                  rule.parameter = WGApiData.WGSensors?.sensors_id?.[rule.parameter];
                } else if (rule.parameter && _.isString(rule.parameter)) {
                  rule.parameter = WGApiData.WGSensors?.sensors_name?.[rule.parameter];
                }
                rule.value_conv = convert(rule.value, rule.parameter?.conversion?.id, null, false);
                if (!_.isNil(rule.parameter?.configs?.decimals)) {
                  rule.value_conv = Math.round(rule.value_conv * Math.pow(10, rule.parameter.configs.decimals + 1)) / Math.pow(10, rule.parameter.configs.decimals + 1);
                }
              });
              _.forEach(elem.rule_templates, function (rule) {
                if (rule.parameter?.id) {
                  rule.parameter = WGApiData.WGSensors?.sensors_id?.[rule.parameter.id];
                } else if (rule.parameter && _.isNumber(rule.parameter)) {
                  rule.parameter = WGApiData.WGSensors?.sensors_id?.[rule.parameter];
                } else if (rule.parameter && _.isString(rule.parameter)) {
                  rule.parameter = WGApiData.WGSensors?.sensors_name?.[rule.parameter];
                }
                rule.value_conv = convert(rule.value, rule.parameter?.conversion?.id, null, false);
                if (!_.isNil(rule.parameter?.configs?.decimals)) {
                  rule.value_conv = Math.round(rule.value_conv * Math.pow(10, rule.parameter.configs.decimals + 1)) / Math.pow(10, rule.parameter.configs.decimals + 1);
                }
              });
            }

            if (!_.isNil(elem.status)) {
              elem.active = elem.status >= 1;
            }

            if (!_.isEmpty(elem.notification_targets) && !_.isEmpty(AuthService.viewing_notification_targets)) {
              elem.notification_targets = WGApiData.WGAlarms.extractNotificationTargets(elem.notification_targets);
            }
          }

          // if(entity_type==='batches' || entity_type==='processes'){
          //   if(elem.started_at!==null) {
          //     elem.started_at = new Date(elem.started_at).getTime();
          //   }
          //   if(elem.ended_at!==null) {
          //     elem.ended_at = new Date(elem.ended_at).getTime();
          //   }
          // }

          // filter inactive(finished) processes from the returned batches
          // if (entity_type === 'batches') {
          //   if (!_.isEmpty(elem.processes)) {
          //     elem.processes = _.filter(elem.processes, {'active': true});
          //   }
          // }
        });
        return data;
      }

      $scope.$on('adminReload', function (event, args) {
        console.log('adminReload', 'admin-elements');
        $scope.tableParams.reload();
      });

      // watch if table is loading,
      // if so only show loading animations
      // if it takes more than 1000 ms
      var promiseTimeoutLoading: ng.IPromise<any>;
      $scope.$watch('tableParams.settings().$loading', function (value) {
        // console.log("$watch('tableParams.settings().$loading'", $scope);
        $timeout.cancel(promiseTimeoutLoading);
        if (value) {
          promiseTimeoutLoading = $timeout(function () {
            $scope.loading = true;
          }, 1000);
        } else {
          $scope.loading = false;
        }
      });

      //  -DEV
      $scope.spitData = function () {
        console.log("spitData. $scope", $scope, "selected=", $scope.see.is());
      };


      //ALARM TEMPLATE
      $scope.openModalCreateOrEdit = (alarmOrAlarmTemplate, isAlarmTemplate, isEditMode: boolean) => {
        editElemModalIsOpen = true;

        let template = 'app/views/modals/create-or-edit-single-alarm.html';

        var options = {
          template: template,
          class: "wg-dialog",
          data: {
            alarm: alarmOrAlarmTemplate,
            isAlarmTemplate: isAlarmTemplate,
            parentCollection: $scope.list_items,
            isEditMode: isEditMode
          }
        };
        ngDialog.openConfirm(options)
            .then(
                function (data) { // onSuccess
                  console.log('Set Alarm, Ok', data);

                }, function (reason) { // onError
                  console.log('Set Alarm closed', reason);

                })
            .finally(() => {
              $scope.tableParams.reload();
            });
      }
    }]
  )
}