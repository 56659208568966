namespace wg {

  interface TableData {
    name: string;
    internal_name: string;
    uuid: string;
    description: string;
    unit_type: string;
    unit_name: string;
    readonly device: Partial<IDevice>;
    listSens: ISensorReading[];
    sensors_avg?: {
      [internal_name: string]: { avg: string };
    };
    sensors: {
      // status_timestamp: null;
      // status: null;
      info: ISensorReading;
      [internal_name: string]: ISensorReading;
    };
  }

  interface IScope extends ng.IScope {
    updateDeviceWatch(): void;

    changeUnit(itemx: INestable["item"], initial?: boolean): void,

    graphCtrl: GraphController;

    searchQuery: string;
    nest_items: INestable[];

    nest_items_byGroup: INestable[];
    nest_items_byPlace: INestable[];
    nest_items_byUnit: INestable[];
    nest_items_byDevice: INestable[];
    nest_items_byParameter: INestable[];
    nest_items_current: INestable[];

    nest_items_flat: INestable[];

    unitsByLocal: {
      place: string,
      units: TableData[];

    }
    countDevicesBySensor: {
      [internal_name: string]: {
        total: number,
        online: number,
        offline: number,
      };
    }

    selectedPlace: string;
    placeListSens: ISensorReading[];

    selectedUnit: ISelectedUnit;

    device_entries_UUID: {
      [UUID: string]: IDeviceEntry,
    };

    selectedUnitForceRead: IReturnResult,
  }

  interface ISelectedUnit {
    alarmSens: ISensor[],
    device: IDeviceEntry,
    root_device: IDevice,
    canForceRead: boolean,
    text: string,
    description: string,
    unit_type: string,
    unit_type_icon: string,

    bat: any,
    wakes: any,
    manualSens: any,
    listSens: ISensorReading[],
    listPredSens: ISensorReading[],
    listMeshVinesPredSens: ISensorReading[],

    // [internal_name: string]: any;

  }

  interface IDeviceEntry {
    id?: IDevice['id'],
    name?: IDevice['name'],
    internal_name?: IDevice['internal_name'],
    uuid?: IDevice['uuid'],
    iid?: IDevice['iid'],
    sn?: IDevice['sn'],
    unit_type?: IDevice['unit_type'],
    model?: IDevice['model'],
    path?: IDevice['path'],
    description?: IDevice['description'],
    configs?: IDevice['configs'],
    version?: IDevice['version'],
    hw_version?: IDevice['hw_version'],
    status?: IDevice['status'],
    last_read?: IDevice['last_read'],

    comm_status?: IDevice['comm_status'],

    group?: string,
    readonly root_device: IDevice,
    sensors?: string[],
    unit?: {
      id?: IUnit['id'],
      name?: IUnit['name'],
    },
    isChecked?: boolean,
  }

  App.controller("DevicesUnitsController", ['$rootScope', '$scope', '$filter', '$http', '$timeout', '$state', '$stateParams', 'ngDialog', 'AuthService', 'Data', 'WGApiData', 'DataUtils', 'APIUtils',
    function ($rootScope: IRootScope, $scope: IScope, $filter: ng.IFilterService, $http: ng.IHttpService, $timeout: ng.ITimeoutService, $state: _IState, $stateParams, ngDialog: ng.dialog.IDialogService, AuthService: IAuthService, Data, WGApiData: WGApiData, DataUtils: DataUtils, APIUtils: APIUtils) {

      if (WG_debug) console.log('Entrou no Devices-Units-Controller', $stateParams);

      $scope.graphCtrl = undefined; // Gets injected by the graph tab
      $scope.selectedUnitManualEntry = {};
      $scope.selectedUnitCrontab = {};
      $scope.selectedUnitAlarm = {};

      // $scope.gotoBottom = function () {
      //
      //   var _target_div = $('#params_buttons');
      //   if (_target_div && _target_div.offset())
      //     $('html, body').animate({scrollTop: _target_div.offset().top}, '800', 'swing');
      //
      //   // console.log("clicou");
      //   // // set the location.hash to the id of
      //   // // the element you wish to scroll to.
      //   // $location.hash('bottom');
      //   //
      //   // // call $anchorScroll()
      //   // $anchorScroll();
      // };

      $scope.reload = function (force) {
        var notify = false;
        if (force === undefined) {
          force = true;
        }
        if (force) {
          notify = true;
        }
        if (WG_debug) console.log('reload', force);
        var params = {device_uuid: null, place: null};
        if (last_unit_itemx?.selected) {
          params.device_uuid = last_unit_itemx.uuid;
        }
        $state.go('app.devices.units', params, {reload: 'app.devices.units', notify: notify});
      };

      $scope.doubleClickUnit = function (itemx) {
        // console.log('doubleClickUnit', itemx);
        $scope.reload(false);
      };
      //
      $scope.realTime = true;
      $scope.clickedRealTime = false;
      $scope.show_realTime_check = false;

      $scope.searchForm = {};
      $scope.searchQuery = '';

      // Objects holding status of child-modals
      $scope.configControlBoard = {};
      $scope.selectedUnitEditDevice = {};

      //                --  NESTED

      // TODO: Nest by: account -> Place -> Devices
      $scope.nest_options = {
        initState: (AuthService.view_as_owner?.id < 0 || (AuthService.view_as_owner?.username || AuthService.user?.username) == "admin.script") ? "collapsed" : "expanded", // "collapsed" or "expanded"
      }

      $scope.changeNestBy = function (order) {
        if (order == $scope.orderBy) {
          return;
        }
        $scope.clearSelectedSensors();
        if (order === 'group') {
          $scope.orderBy = 'group';
          // $scope.nest_items = $scope.nest_items_byGroup;
          // console.log($scope.nest_items);
        } else if (order === 'place') {
          $scope.orderBy = 'place';
          // $scope.nest_items = $scope.nest_items_byPlace;
          // console.log($scope.nest_items);
        } else {
          $scope.orderBy = 'device';
          // $scope.nest_items = $scope.nest_items_byDevice;
          // console.log($scope.nest_items);
        }
        $scope.performSearch();
      };

      $scope.nest_items = []; // Current list being shown in html, filtered.

      $scope.nest_items_byGroup = $scope.nest_items;
      $scope.nest_items_byPlace = $scope.nest_items;
      $scope.nest_items_byUnit = $scope.nest_items;
      $scope.nest_items_byDevice = $scope.nest_items;
      $scope.nest_items_byParameter = $scope.nest_items;

      $scope.nest_items_current = $scope.nest_items_byGroup; // Unfiltered. Should be one of the previous main lists

      $scope.nest_items_flat = [] as INestable[];


      function recursive_nestable_filter_entry(entry: INestable, searchQ: string): INestable {
        if (_.isArray(entry)) {
          if (WG_debug) console.error("Bug searching through Nestable Array");
          return {} as INestable;
        }
        let searchQ_lower = searchQ.toLowerCase();
        if (entry.item.text?.toLowerCase().includes(searchQ_lower)
            || entry.item.id?.toString().includes(searchQ_lower)
            || entry.item.uuid?.toLowerCase().includes(searchQ_lower)) {
          return entry;
        }
        if (entry.item.device) {
          let _device_info = '' + entry.item.device.name + '|' + entry.item.device.sn + '|' + entry.item.device.uuid + '|' + entry.item.device.id + '|' + entry.item.device.iid + '|'
              + entry.item.device.status + '|' + entry.item.device.model_name + '|' + entry.item.device.version + '|' + entry.item.device.hw_version;
          if (_device_info.toLowerCase().includes(searchQ_lower)) {
            return entry;
          }
        }
        if (entry.item.isGroup && !_.isEmpty(entry.children)) {
          // Group. Check children, and create new group with only similar children
          let filtered_children = recursive_nestable_filter(entry.children, searchQ);
          if (!_.isEmpty(filtered_children)) {
            let group_entry = _.cloneDeep(entry);
            group_entry.children = filtered_children;
            group_entry.item.children = filtered_children;
            return group_entry
          }
        }
        return {} as INestable;
      }

      function recursive_nestable_filter(entries: INestable[], searchQ: string): INestable[] {
        let new_array: INestable[] = [];
        entries.forEach((entry) => {
          let matched_entry = recursive_nestable_filter_entry(entry, searchQ);
          if (!_.isEmpty(matched_entry)) {
            new_array.push(matched_entry);
          }
        });
        return new_array;
      }

      $scope.performSearch = function () {

        if (_.isEmpty($scope.searchQuery)) {
          $scope.nest_items = $scope.nest_items_current;
          return;
        }
        // Flatten items to ease search and display
        // $scope.nest_items_flat = [];
        // flatten_nestable($scope.nest_items_current, $scope.nest_items_flat);

        $scope.nest_items = recursive_nestable_filter($scope.nest_items_current, $scope.searchQuery);
        if (WG_debug) console.log("Recursively built search-results:", $scope.nest_items);
      }

      $scope.place_items = {};
      $scope.unit_items = {};

      $scope.selUnitType = 'temp';
      $scope.numberUnits = 0;
      $scope.selectUnitType = function (unitType) {
        $scope.selUnitType = unitType;
      };

      $scope.selectedPlace = null;
      $scope.unitsByLocal = null;


      // #656565
      // #4d4d4f
      $scope.selectedUnit = null;
      $scope.hasSelectedUnit = false;
      $scope.hasSelectedPlace = false;
      $scope.hasSelectedUnitGraph = false;
      $scope.selectedPlaceAvg = {
        enabled: false,
        intervalAvailableOptions: [
          {id: 1, value: 1},
          {id: 2, value: 2},
          {id: 3, value: 4},
          {id: 4, value: 8},
          {id: 5, value: 12},
          {id: 6, value: 24},
        ],
        selectedInterval: {id: 3, value: 4},
        click: function () {
          // console.log("selectedPlaceAvg.click");
          selectGroup(last_itemx);
        },
        change: function (item, index) {
          this.selectedInterval = item;
          // console.log("selectedPlaceAvg.change", item, index);
          selectGroup(last_itemx);
        },
      };

      var last_itemx;
      var last_unit_itemx;
      var last_place_itemx;
      var valid_sensors_list = ['TEMP', 'IN_TEMP', 'QL_TEMP', 'QL_TREAT', 'LLV_TREAT', 'QL_TREAT_LDENSA', 'BAT_TREAT', 'VPROG', 'AI_LDENSA', 'DISTANCE', 'PRESS_TEMPERATURE'];
      $scope.changeUnit = function (itemx, initial = false) {
        console.log("Selecting", itemx);
        if (!itemx)
          return;
        //console.log(itemx);
        last_itemx = itemx;
        if (itemx.isGroup) {
          selectGroup(itemx, initial);
        } else {
          selectDevice(itemx, initial);
        }
      };

      function selectDevice(_device, initial = false) {
        // console.log("selectProcess3. last_unit_itemx",last_unit_itemx, "itemx",itemx);
        if (angular.isDefined($scope.updateDeviceWatch) && angular.isFunction($scope.updateDeviceWatch)) {
          // console.log('cancel unWatchDeviceLKM');
          $scope.updateDeviceWatch();
        }
        if (!angular.isDefined(_device.uuid)) {
          return;
        }
        if (!angular.isDefined($rootScope.lastKnownMessages[_device.uuid])) {
          // return;
        }
        if (last_unit_itemx?.selected && _device?.uuid === last_unit_itemx?.uuid) {
          // Same sensor selected. Ignore
          return;
        }

        $scope.selectedUnit = {} as IScope['selectedUnit'];
        $scope.hasSelectedUnit = true;
        $scope.hasSelectedPlace = false;
        $scope.hasSelectedUnitGraph = false;
        if (angular.isDefined(last_place_itemx)) {
          last_place_itemx.selected = false;
          last_place_itemx.style = {};
        }
        if (angular.isDefined(last_unit_itemx)) {
          last_unit_itemx.selected = false;
          last_unit_itemx.style = {};
        }
        //-
        if ($scope.graphCtrl && angular.isDefined(last_unit_itemx) && angular.isDefined(last_unit_itemx.uuid) && last_unit_itemx.uuid !== _device.uuid) {
          $scope.graphCtrl.init();
        }
        //-
        if ($scope.selectedUnitCrontab) {
          $scope.selectedUnitCrontab.result = '';
        }
        if ($scope.selectedUnitAlarm) {
          $scope.selectedUnitAlarm.result = '';
        }
        if ($scope.selectedUnitManualEntry) {
          $scope.selectedUnitManualEntry.result = '';
        }
        if (angular.isDefined($scope.selectedUnitResetLevel)) {
          $scope.selectedUnitResetLevel.result = '';
        }
        if (angular.isDefined($scope.selectedUnitSet0Level)) {
          $scope.selectedUnitSet0Level.result = '';
        }
        if (angular.isDefined($scope.selectedUnitForceRead)) {
          $scope.selectedUnitForceRead.result = '';
        }
        last_unit_itemx = _device;
        _device.selected = true;
        _device.style = {
          'color': '#8b4243'
        };
        if (angular.isDefined($rootScope.lastKnownMessages[_device.uuid])) {
          // Monitor incoming MQTT data, adding new points of graphed sensors
          $scope.updateDeviceWatch = $rootScope.$watch('lastKnownMessages["' + _device.uuid + '"]',
              function (_new_value, _old_value) {
                if (_.isNil(_new_value) || _.isEqual(_new_value, _old_value)) {
                  return;
                }
                // console.log('unit ws col', newValue);
                updateDevice(_device);
              }, true);
        }
        updateDevice(_device);
        // updateDevice(itemx);

        $scope.selectedGraphSensors = {};
        // Add to URL

        // $scope.reload(false);
        if (last_unit_itemx?.selected) {
          AuthService.update_url({
            device: last_unit_itemx.uuid,
            place: null,
            unit: null,
            device_uuid: null
          }, false, false, initial);
        }
      }

      $scope.canUserAccessSensor = function (internal_name) {
        DataUtils.canUserAccessSensor(internal_name);
      }

      $scope.addAlarmSens = function (parameter_name) {
        let _sensor = WGApiData.WGSensors.sensors_name[parameter_name];
        if (_sensor
            && DataUtils.canUserAccessSensor(parameter_name)
            && !_.find($scope.selectedUnit.alarmSens, {id: _sensor.id})) {
          // console.log('addAlarmSens', sens, 'adding');
          $scope.selectedUnit.alarmSens.push(_sensor);
        }
      };

      function updateDevice(itemx) {
        if (WG_debug) console.log('Devices-units updateDevice');
        var root_device = WGApiData.WGDevices.devices_uuid[itemx.uuid || itemx.device.uuid];
        var _device_lsv = root_device.last_values;
        var _device_lkm = root_device.lkm;
        if (!_device_lkm) {
          // return;
        }
        $scope.selectedUnit.alarmSens = [];

        // console.log("itemx", itemx);

        let device_entry = $scope.device_entries_UUID[itemx.uuid || itemx.device.uuid];
        $scope.selectedUnit.device = device_entry;
        $scope.selectedUnit.root_device = root_device;
        $scope.selectedUnit.canForceRead = $scope.canForceRead();

        $scope.selectedUnit.text = itemx.text;
        $scope.selectedUnit.description = itemx.description;
        $scope.selectedUnit.unit_type = device_entry.unit_type;

        $scope.selectedUnit.unit_type_icon = root_device.tech_icon;

        // if ($scope.selectedUnit.unit_type === 'barrel') {
        //   $scope.selectedUnit.unit_type_icon = 'icon icon-wg-barrel-rl-wireless';
        // } else if ($scope.selectedUnit.unit_type === 'vat') {
        //   $scope.selectedUnit.unit_type_icon = 'icon icon-wg-vat-rl-wireless';
        // } else if ($scope.selectedUnit.unit_type === 'press') {
        //   $scope.selectedUnit.unit_type_icon = 'icon icon-wg-press-wireless';
        // } else if ($scope.selectedUnit.unit_type === 'cellar') {
        //   $scope.selectedUnit.unit_type_icon = 'icon icon-wg-cellar-line';
        // } else if ($scope.selectedUnit.unit_type === 'bottle') {
        //   $scope.selectedUnit.unit_type_icon = 'icon icon-wg-bottle';
        // }

        $scope.selectedUnit.bat = {val: null};
        if (_device_lkm &&
            _device_lkm['BAT_TREAT'] &&
            _device_lkm['BAT_TREAT'].payload &&
            angular.isDefined(_device_lkm['BAT_TREAT'].payload.value)) {
          $scope.selectedUnit.bat.val = _device_lkm['BAT_TREAT'].payload.value.toFixed(0);
          $scope.selectedUnit.bat.sensor = 'BAT_TREAT';
        }


        //  WAKEUP_REASON
        $scope.selectedUnit.wakes = {};
        if (angular.isDefined(_device_lkm) && angular.isDefined(_device_lkm['WAKEUP_REASON'])) {
          //  Todo: one of the following two seem like shouldn't be necessary
          $scope.selectedUnit.wakes.val = _device_lkm['WAKEUP_REASON'].payload.value;
          $scope.selectedUnit.wakes.sref = 'app.devices.wakes';
          $scope.selectedUnit.wakes.params = {device: device_entry.uuid};
          $scope.selectedUnit.wakes.sensor = 'WAKEUP_REASON';
          $scope.addAlarmSens('WAKEUP_REASON');
        } else {
          $scope.selectedUnit.wakes.val = null;
        }


        if (angular.isDefined($scope.selectedUnit.device?.configs?.unit_view)) {
          var configs = $scope.selectedUnit.device.configs.unit_view;
          // console.log('unit_view configs', configs);
          if (angular.isDefined(configs.disable_realtime)) {
            if (configs.disable_realtime) {
              if (!$scope.clickedRealTime) {
                // console.log('disable_realtime', configs.disable_realtime);
                $scope.realTime = false;
                $scope.show_realTime_check = true;
              }
            } else {
              $scope.realTime = true;
              $scope.show_realTime_check = false;
            }
          } else {
            $scope.realTime = true;
            $scope.show_realTime_check = false;
          }
          if (angular.isDefined(configs.hide_sensors)) {
            // console.log('unit_view configs hide_sensors', configs.hide_sensors);
            configs.hide_sensors.forEach(function (_sens) {
              if ($scope.selectedUnit.hasOwnProperty(_sens)) {
                // console.log('unit_view configs hide_sensor', sens);
                if (!AuthService.user_view_as_admin) {
                  $scope.selectedUnit[_sens].val = null;
                }
              }
            });
          }
        } else {
          $scope.realTime = true;
          $scope.show_realTime_check = false;
        }

        // Array of values for the Manual Sensors
        $scope.selectedUnit.manualSens = [];
        _.forEach(WGApiData.WGSensors.manual_sensors, function (sensor) {
          if (_device_lsv?.[sensor.internal_name]) {
            $scope.selectedUnit.manualSens.push(_device_lsv[sensor.internal_name]);
            $scope.selectedUnit.alarmSens.push(sensor);
            // $scope.addAlarmSens(sensor);
          // } else if (_device_lkm?.[sensor.stream]) {
          //   let sens = {
          //     sensor: sensor, // Includes stream, unit, name, etc
          //     timestamp: _device_lkm[sensor.stream].timestamp,
          //     val: _device_lkm[sensor.stream].payload.value,
          //   };
          //   $scope.selectedUnit.manualSens.push(sens);
          //   $scope.selectedUnit.alarmSens.push(sensor);
          //   // $scope.addAlarmSens(sensor);
          }
        });

        // List of $rootScope.lastSensorValues related to this device
        $scope.selectedUnit.listSens = DataUtils.get_last_sensors_values(ALL_SENSORS_NAMES, itemx.uuid);

        _.forEach($scope.selectedUnit.listSens, function (_last_value) {
          if (!_last_value) return;
          $scope.addAlarmSens(_last_value.sensor.internal_name);
        });

        if ($scope.selectedUnit.root_device.capabilities.fermentation_prediction) {
          // PREDICTION_SENSORS_LIST can be incremented later on manual-actions.
          $scope.selectedUnit.listPredSens = DataUtils.get_last_sensors_values(PREDICTION_SENSORS_LIST, itemx.uuid);
          $scope.selectedUnit.listMeshVinesPredSens = DataUtils.get_last_sensors_values(MESHVINES_PREDICTION_SENSORS_LIST, itemx.uuid);
        }
      }

      $scope.canForceRead = function () {
        if ($scope.selectedUnit.root_device.capabilities) {
          return $scope.selectedUnit.root_device.capabilities.force_read;
        }
        return DataUtils.can_device_force_read($scope.selectedUnit.root_device);
      };

      $scope.$on('$destroy', () => {
        if ($scope.updateDeviceWatch) {
          $scope.updateDeviceWatch();
        }
      })

      function selectGroup(selected_entry: INestable["item"], initial = false) {
        if (!selected_entry || !selected_entry.isGroup || _.isEmpty(selected_entry.children)) {
          return;
        }
        if (selected_entry.uuid) {
          return;
        }
        if (angular.isDefined($scope.updateDeviceWatch) && angular.isFunction($scope.updateDeviceWatch)) {
          // console.log('cancel unWatchDeviceLKM');
          $scope.updateDeviceWatch();
        }

        $scope.hasSelectedUnit = false;
        $scope.hasSelectedPlace = true;

        $scope.countDevicesBySensor = {};

        function countStatus(itemData: TableData, sensor_internal_name: string) {
          if (!$scope.countDevicesBySensor[sensor_internal_name]) {
            $scope.countDevicesBySensor[sensor_internal_name] = {
              total: 0,
              online: 0,
              offline: 0
            };
          }
          $scope.countDevicesBySensor[sensor_internal_name].total++;
          if (itemData.device.status == 'ON' && itemData.sensors[sensor_internal_name].status == "OK") {
            $scope.countDevicesBySensor[sensor_internal_name].online++;
          } else if (itemData.sensors[sensor_internal_name].status !== "DISABLED") {
            $scope.countDevicesBySensor[sensor_internal_name].offline++;
          }
        }

        // console.log("itemx", itemx);
        $scope.selectedPlace = selected_entry.text; // Selected place
        $scope.unitsByLocal = {
          place: selected_entry.text,
          units: [],
        }; // Units by selected place

        if (angular.isDefined(last_unit_itemx)) {
          last_unit_itemx.selected = false;
          last_unit_itemx.style = {};
        }
        if (angular.isDefined(last_place_itemx)) {
          last_place_itemx.selected = false;
          last_place_itemx.style = {};
        }

        last_place_itemx = selected_entry;
        selected_entry.selected = true;
        selected_entry.style = {'color': '#8b4243'};

        let _children = flatten_nestable(selected_entry.children, []);

        _.forEach(_children, (entry) => {
          let _uuid = entry.item.uuid || entry.item.device.uuid
          let _rootDevice: IDevice = WGApiData.WGDevices.devices_uuid[_uuid];
          let device_entry = $scope.device_entries_UUID[_uuid];

          let tableEntry: TableData = {
            name: entry.item.text,
            internal_name: _rootDevice.internal_name,
            uuid: _uuid,
            description: _rootDevice.description,
            unit_type: _rootDevice.unit?.unit_type,
            unit_name: _rootDevice.unit?.name || '',
            device: _rootDevice,
            listSens: [],
            sensors: {
              // status: null,
              // status_timestamp: null,
              info: {
                sensor: undefined,
                internal_name: 'info',
                timestamp: _rootDevice.last_read,
                val: "",
                val_numeric: 0,
                status: _rootDevice.status == 'ON' ? 'OK' : _rootDevice.status == 'DEACTIVATED' ? 'DISABLED' : 'FAIL',
              },
            }
          };

          countStatus(tableEntry, 'info');

          // Get available parameters for this "group"
          tableEntry.listSens = DataUtils.get_last_sensors_values($rootScope.tableview_sensors_list, _uuid);
          _.forEach(tableEntry.listSens, function (_last_value) {
            if (!_last_value) return;
            // unmarshal them to sensors' dict
            tableEntry.sensors[_last_value.sensor.internal_name] = _last_value;
            countStatus(tableEntry, _last_value.sensor.internal_name);
          });

          $scope.unitsByLocal.units.push(tableEntry);

          // console.log("unit", itemData);
          function get_mean(device_uuid, sensor_name) {
            var _sensor = WGApiData.WGSensors.sensors_name[sensor_name] as Partial<ISensor>;
            if (!_sensor) {
              _sensor = {
                configs: {
                  query: "value",
                  decimals: 3,
                }
              };
            }
            var _device = $scope.device_entries_UUID[device_uuid];
            // var defaultParams = {
            //   b: '1y',
            //   q: {"payload": ["timestamp", "value"]},
            //   f: 'list',
            //   l: 100000,
            //   s: 100000
            // };
            var _params = {
              b: tableEntry.sensors[sensor_name].timestamp - $scope.selectedPlaceAvg.selectedInterval.value * 60 * 60 * 1000,
              e: tableEntry.sensors[sensor_name].timestamp,
              q: _sensor.configs.query,
              f: 'list',
              // l: 8,
            };
            Data.get(_device, _sensor, _params).then(
                function (data) {
                  // data = data.data;
                  // console.log('gotData', _device.name, _sensor.stream, _sensor.internal_name);
                  var sum = _.reduce(data, function (memo, e) {
                    return memo + e[1];
                  }, 0);
                  var avg = sum / data.length;
                  // console.log('sum', sum, 'len', data.length, 'avg', avg);
                  if (!tableEntry.sensors_avg)
                    tableEntry.sensors_avg = {};
                  if (!isNaN(avg)) {
                    tableEntry.sensors_avg[sensor_name] = {avg: avg.toFixed(_sensor.configs.decimals)};
                  } else {
                    // itemData.sensors[sensor_name].avg = itemData.sensors[sensor_name].val
                  }
                  // TODO: Convert avg
                  // var sortedData = _.sortBy(data, function (e) {
                  //   return e[0];
                  // });
                });
          }

          if ($scope.selectedPlaceAvg.enabled) {
            angular.forEach(tableEntry.listSens, function (_lsv, _internal_name) {
              get_mean(device_entry.uuid, _lsv.sensor.internal_name);
            });
          }
        });

        /*** Select other type if this place doesn't have any ***/
        // var types = ['temp', 'llv', 'turb', 'dens'];
        // if ($scope.countDevicesBySensor[$scope.selUnitType] === 0) {
        //   for (var i in types) {
        //     if ($scope.countDevicesBySensor[types[i]] > 0) {
        //       $scope.selectUnitType(types[i]);
        //       break;
        //     }
        //   }
        // }
        // console.log("unitsByLocal", '\n', angular.toJson($scope.unitsByLocal), '\n', angular.toJson($scope.countDevicesBySensor));
      }

      // Local shallow-ref
      $scope.device_entries_UUID = {};
      $scope.device = {};
      $scope.selectedDevices = [];
      $scope.availableDevices = [];

      $scope.deviceChecked = function (device, sensor) {
        $rootScope.selectedDevice = $scope.device_entries_UUID[device.uuid];
        if ($scope.device_entries_UUID[device.uuid].isChecked)
          return;
        $scope.device_entries_UUID[device.uuid].isChecked = true;

        $scope.selectedDevices.push(device);
      };


      let all_unWatch = $rootScope.$watch('WGApiData.AllReady', function () {
        if (!WGApiData.AllReady) {
          return;
        }
        // all_unWatch();

        for (let _device of WGApiData.WGDevices.devices) {

          let _rootDevice: IDevice = WGApiData.WGDevices.devices_uuid[_device.uuid];
          if (SMARTBOX_MODELS.includes(_device.model)) {
            // Ignore Smartboxes
            continue;
          }
          if (!_device.lkm) {
            // No LKMs
            continue;
          }
          let _device_entry = $scope.device_entries_UUID[_device.uuid];
          if (!_device_entry) {
            // Create local "device" entry if not exists
            _device_entry = _.assign({
                  isChecked: false,
                  root_device: _device,
                  group: _device.groups?.[0]?.name || '',
                  sensors: [],
                },
                _.cloneDeep(_.pick(_device,
                    ['id', 'name', 'internal_name', 'uuid', 'iid', 'sn', 'unit_type', 'model', 'path', 'description', 'configs', 'hw_version', 'version', 'status', 'last_read', 'comm_status', 'unit.id', 'unit.name'])))
            $scope.device_entries_UUID[_device.uuid] = _device_entry;
          }
          // Fill list of available parameters
          for (let sensor in _device.lkm) {
            if (_.includes(valid_sensors_list, sensor)
                && !_.includes(_device_entry.sensors, sensor)) {
              _device_entry.sensors.push(sensor);
            }
          }

          if (_.indexOf($scope.availableDevices, _device_entry) < 0) {
            $scope.availableDevices.push(_device_entry);
            let _entry_name = (_device.unit?.name && !_device.name.includes(_device.unit.name) && !_device.unit.name.includes(_device.name) ?
                _device.unit.name + " - " + _device.name : _device.name);

            let new_item_entry: INestable = {
              item: {
                text: _entry_name,
                uuid: _device.uuid,
                device: _device, // same as rootDevice
                // root_device: _rootDevice, // deprecate
                isGroup: false,
              },
              children: [],
            };
            new_item_entry.item.children = new_item_entry.children;
            $scope.unit_items[_device.uuid] = new_item_entry.item;

            // TODO: Make a "Group By" radio/select-box/tabs/buttons

            // Create Nest lists

            // Group by "device.group|device.groups[0]"
            if (!_.isEmpty(_device_entry.group)) {
              let found_group = false;
              for (var i in $scope.nest_items_current) {
                // Check if a group (branch of the tree) already exists with the same name
                if (_device_entry.group === $scope.nest_items_current[i].item.text && $scope.nest_items_current[i].item.isGroup) {
                  $scope.nest_items_current[i].children.push(new_item_entry);
                  found_group = true;
                  break;
                }
              }
              if (!found_group) { // doesn't exist yet. create one and add to it
                let new_group_entry: INestable = {
                  item: {
                    text: _device_entry.group,
                    isGroup: true,
                  },
                  children: [new_item_entry]
                };
                new_group_entry.item.children = new_group_entry.children;

                // console.log(_item.item)
                $scope.place_items[_device_entry.group] = new_group_entry.item;
                $scope.nest_items_current.push(new_group_entry);
              }
            } else {
              $scope.nest_items_current.push(new_item_entry);
            }
          }
        }

        // $scope.nest_items_current = _.sortBy($scope.nest_items_current, function (e) {
        //   if (e.item.isGroup) {
        //     return e.item.text;
        //   }
        //   return 'zzz' + e.item.text;
        // });
        $scope.nest_items_current.sort(function (a, b) {
          if (a.item.isGroup && !b.item.isGroup) {
            return -1
          }
          if (!a.item.isGroup && b.item.isGroup) {
            return 1
          }
          if (a.item.text > b.item.text)
            return 1;
          if (a.item.text < b.item.text)
            return -1;
          return 0;
        });

        $scope.nest_items = $scope.nest_items_current;

        // $scope.placeListSens = add_sensors_list($rootScope.tableview_sensors_list);
        $scope.placeListSens = DataUtils.get_last_sensors_values($rootScope.tableview_sensors_list, null, true);


        if (!_.isEmpty($state?.params)) {
          if (WG_debug) console.log('Pre-selecting some things:', $state.params);

          let _wanted_device = $state.params.device || $state.params.unit || $state.params.device_uuid || $state.params.device_id || $state.params.device_sn || $state.params['device1'];
          let device_uuid = _wanted_device && WGApiData.extractDevices(_wanted_device)?.[0]?.uuid;
          if (device_uuid && $scope.unit_items[device_uuid]) {
            if (WG_debug) console.log("Selected Device based on $state.params", $state.params);
            $scope.changeUnit($scope.unit_items[device_uuid], true);

            // Params compatibility between TechnicalView and Overview
            if (!_.isEmpty($state.params.sensors)) {
              let _params_sensors = _.isArray($state.params.sensors) ? $state.params.sensors : [$state.params.sensors];
              let _params = {sensors: null, unit: null};
              _.forEach(_params_sensors, function (sensor_internal_name) {
                let first_free = 99999;
                for (let i = 1; i < 19; i++) {
                  if (!$state.params['device' + i]) {
                    // First free parameter found
                    first_free = i;
                  } else if ($state.params['device' + i] == device_uuid && $state.params['param' + i] == sensor_internal_name) {
                    // Already filled
                    i = 999999;
                    return;
                  }
                }
                if (first_free < 19) {
                  $state.params['device' + i] = device_uuid;
                  $state.params['param' + i] = sensor_internal_name;
                }
              });
              AuthService.update_url(_params, false, false, true);
            }

            // If we have graphics that should be shown, schedule to shown them when data finishes loading
            if ($state.params['device1']) {
              let unhook1 = $scope.$watchCollection('graphCtrl', function () {
                if (!$scope.graphCtrl) {
                  return;
                }

                let unhook2 = $rootScope.$watchGroup(['WGSensors.ready', 'WGDevices.ready'], function () {
                  if (!WGApiData.WGSensors.ready || !WGApiData.WGDevices.ready || !$scope.graphCtrl) {
                    return;
                  }
                  for (let i = 1; i < 19; i++) {
                    if ($state.params['device' + i] != device_uuid) {
                      continue;
                    }
                    if ($state.params['device' + i] && $state.params['param' + i]) {
                      $scope.addToGraph($state.params['device' + i], $state.params['param' + i], false, false);
                    } else {
                      break;
                    }
                  }
                  unhook1();
                  unhook2();
                });
              });
              // Show graphs from URL
            }

          } else if ($state.params?.place) {
            $scope.changeUnit($scope.place_items[$state.params.place], true);
          } else if ($rootScope.selectedDevice) {
            $scope.selectedDevice = $rootScope.selectedDevice;
            $scope.device.selected = $scope.selectedDevice;
            $scope.deviceChecked($scope.selectedDevice);
          }
        }
      });


      $scope.last_selected_sensor = null;
      $scope.selectedGraphSensors = {};

      $scope.addToGraph = function (device_uuid, sensor_internal_name, forced = false, add_to_url = true) {
        if ($scope.graphCtrl) {
          let ret = $scope.graphCtrl.addToGraph(device_uuid, sensor_internal_name, forced, add_to_url);
          if (ret) {
            $scope.hasSelectedUnitGraph = true;
            $scope.last_selected_sensor = sensor_internal_name;
          }
          return ret;
        }
        return false;
      };

      $scope.forceRead = function () {
        $scope.selectedUnitForceRead.loading = true;
        APIUtils.force_device_read($scope.selectedUnit.device.root_device, false, false, false,
            function (response) {
              $scope.selectedUnitForceRead.loading = false;
              $scope.selectedUnitForceRead.result = 'success';
            }, function (response) {
              $scope.selectedUnitForceRead.loading = false;
              $scope.selectedUnitForceRead.result = 'error';
            });
      };

      $rootScope.modalFermentationPrediction = function (_device) {
        if (!_device) {
          console.error('No device passed');
          return;
        }
        console.info('main modalFermentationPrediction');

        let options = {
          template: 'app/views/modals/fermentation-prediction.html',
          className: 'medium-Modal ngdialog-theme-default',
          data: {
            device: _device,
            result: 'showFermentationPrediction',
            mode: 'openConfirm',
            start_at: null,
            end_at: null,
          }
        };
        if (AuthService.user_view_as_admin) {
          if (_.isFinite($scope.graphCtrl.xAxis_main.min)) {
            options.data.start_at = $scope.graphCtrl.xAxis_main.min;
          } else if ($state?.params?.xAxisMin && _.isFinite(parseInt($state.params.xAxisMin))) {
            options.data.start_at = parseInt($state.params.xAxisMin);
          }

          if (_.isFinite($scope.graphCtrl.xAxis_main.max)) {
            options.data.end_at = $scope.graphCtrl.xAxis_main.max;
          } else if ($state?.params?.xAxisMax && _.isFinite(parseInt($state.params.xAxisMax))) {
            options.data.end_at = parseInt($state.params.xAxisMax);
          }
        }
        ngDialog.openConfirm(options).then(
            function (data) {
              if (WG_debug) console.log('modalFermentationPrediction processing', data);
              $scope.graphCtrl.getFermentSimulatorData(_device, data, '', false);
            }, function (reason) {
              if (WG_debug) console.log('modalFermentationPrediction Fail. Reason: ', reason);
            });
      };

      $scope.manualEntry = function () {
        if (WG_debug) console.info('Opening manual entry modal');
        ngDialog.openConfirm({
          template: 'app/views/modals/manual-entry.html',
          className: 'ngdialog-theme-default',
          controller: 'ManualEntryModalInstance',
          // @ts-ignore
          scope: $scope,
          data: {
            device: $scope.selectedUnit.device,
            result: 'selectedUnitManualEntry',
            mode: 'openConfirm'
          }
        }).then(
            function (data) {
              // console.log('manual-entry', data);
              if (!angular.isDefined($rootScope.lastKnownMessages[data.device.uuid])) {
                $rootScope.lastKnownMessages[data.device.uuid] = {};
              }
              if (!angular.isDefined($rootScope.lastKnownMessages[data.device.uuid][data.sensor.stream])) {
                $rootScope.lastKnownMessages[data.device.uuid][data.sensor.stream] = {};
              }

              $rootScope.lastKnownMessages[data.device.uuid][data.sensor.stream].payload = data.payload;
              updateDevice(last_itemx);
              $scope.hasSelectedUnitGraph = true;
              $scope.graphCtrl.getSensor(data.device, data.sensor, {}, true);
            }, function (reason) {
              // console.log('Modal promise rejected. Reason: ', reason);
            });
      };

      $scope.selectedUnitSet0Level = {
        loading: false,
        result: '',
        set0: function (device) {
          APIUtils.device_set_threshold(device, false, $scope.selectedUnitSet0Level);
        }
      };

      $scope.selectedUnitResetLevel = {
        loading: false,
        result: '',
        reset: function (device) {
          APIUtils.device_set_threshold(device, true, $scope.selectedUnitResetLevel);
        }
      };

      $scope.selectedUnitForceRead = {
        loading: false,
        result: ''
      };

      $scope.clearSelectedSensors = function () {
        if ($scope.graphCtrl?.init) {
          $scope.graphCtrl.init();
        }
        // $scope.last_selected_sensor = null;
        $scope.selectedGraphSensors = {};
        $scope.hasSelectedUnitGraph = false;

        // Delete from URL
        let _params = {
          'xAxisMin': null,
          'xAxisMax': null,
        };
        for (let i = 1; i < 19; i++) {
          // First free parameter found
          _params['device' + i] = null;
          _params['param' + i] = null;
        }
        AuthService.update_url(_params);
      };

      $scope.clickRealTimeCheck = function () {
        $scope.realTime = !$scope.realTime;
        $scope.clickedRealTime = true;
        // console.log('clickRealTimeCheck', $scope.realTime);
      };

      // function notifyMe() {
      //   if (!Notification) {
      //     alert('Desktop notifications not available in your browser. Try Chromium.');
      //     return;
      //   }
      //   if (Notification.permission !== "granted")
      //     Notification.requestPermission();
      //   else {
      //     var notification = new Notification('Notification title', {
      //       icon: 'http://cdn.sstatic.net/stackexchange/img/logos/so/so-icon.png',
      //       body: "Hey there! You've been notified!",
      //     });
      //     notification.onclick = function () {
      //       window.open("http://stackoverflow.com/a/13328397/1269037");
      //     };
      //   }
      // }
      //
      // notifyMe();

      //\\ DEV //\\
      $scope.spitData = function () {
        // console.log("net SD : ", $scope);
        // console.log("net SD : ", $scope,"grouped_running_processes",grouped_running_processes,
        //   "grouped_units",grouped_units,
        //   "grouped_devices",grouped_devices
        // );
        console.log("oldOverview SD : ", $scope,
            "selectedDevices:", $scope.selectedDevices.length > 0,
            "selectedGraphSensors:", _.keys($scope.selectedGraphSensors).length > 0,
            "selectedPlace:", $scope.selectedPlace !== null,
            "selectedUnit:", $scope.selectedUnit !== null,
            "last_selected_sensor:", $scope.last_selected_sensor !== null
        );
      };
    }
  ])
  ;
}