/**=========================================================
 * Module: access-register.js
 * Demo for register account api
 =========================================================*/
namespace wg {
  App.controller('RegisterFormController', ['$rootScope', '$scope', '$http', '$state', 'AuthService', '$timeout',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $state: _IState, AuthService: IAuthService, $timeout: ng.ITimeoutService) {
      if (AuthService.isLogged) {
        $state.go(AuthService.homeState);
        return;
      }

      // bind here all data from the form
      $scope.account = {
        username: '',
        email: '',
        password1: '',
        password2: '',
        activation_token: '',
        company: '',
        country: '',
        distributor: '',
        agreed: true,
      };

      $scope.retStatus = {
        loading: false,
        result: null,
        // place the message if something goes wrong
        message: '',
      } as IReturnResult;

      $scope.validateInput = function (name, type) {
        // console.log('Validating...');
        var input = $scope.accountFormValidate[name];
        // console.log(input.$dirty, input.$error);
        return input.$dirty && input.$error[type];
      };

      $scope.countries_original = [
        {name: 'Argentina', code: 'AR', domain: '.ar'},
        {name: 'Australia', code: 'AU', domain: '.au'},
        {name: 'Chile', code: 'CL', domain: '.cl'},
        // {name: 'China', code: 'CN', domain: '.cn'},
        {name: 'France', code: 'FR', domain: '.fr'},
        {name: 'Germany', code: 'DE', domain: '.de'},
        {name: 'Italy', code: 'IT', domain: '.it'},
        {name: 'New Zealand', code: 'NZ', domain: '.nz'},
        {name: 'Portugal', code: 'PT', domain: '.pt'},
        {name: 'South Africa', code: 'ZA', domain: '.za'},
        {name: 'Spain', code: 'ES', domain: '.es'},
        {name: 'United Kingdom', code: 'GB', domain: '.uk'},
        {name: 'United States', code: 'US', domain: '.us'},
        {name: 'Other', code: 'OT', domain: null}
      ];

      $scope.countries = _.clone($scope.countries_original);


      $scope.changedEmail = function () {
        if (WG_debug) console.log('Email changed', $scope.account.email);
        // if e-mail ends with a domain equal to some from the country list, move that country to the top of the select list, and choose it if no country is selected

        // $scope.countries = _.clone($scope.countries_original);
        let email = $scope.account.email;
        if (email) {
          _.forEach($scope.countries, function (country) {
            if (country.domain && email.endsWith(country.domain)) {
              country.suggested = true;
              if (!$scope.account.country) {
                $scope.changedCountry(country);
              }
            } else {
              country.suggested = false;
            }
          });
          if (!$scope.account.company) {
            $scope.account.company = AuthService.get_company_name(null, email);
          }
        }
        // if (!found_probable_country) {
        //   $scope.countries = _.clone($scope.countries_original);
        // }
      }

      $scope.changedCountry = function (_selected_country) {
        if (WG_debug) console.log('Country changed', _selected_country, $scope.account);
        if (_selected_country) {
          $scope.selected_country = _selected_country;
          $scope.account.country = _selected_country.code;
        }
      }
      // $scope.t = function () {
      //   console.log('Register T');
      //   var data = {detail: "Verification e-mail sent."};
      //   var status = 201;
      //   $state.go(AuthService.loginState, {
      //     detail: data.detail,
      //     status: status
      //   }, {
      //     // location: false,
      //     // location: true,
      //     // inherit: false,
      //     // notify: false,
      //     // reload: true
      //   });
      // };

      var dataError = null;
      $scope.register = function () {
        if ($scope.retStatus.loading)
          return;

        $scope.retStatus.loading = true;
        $scope.retStatus.result = '';
        $scope.retStatus.message = '';

        let timeoutRequest = $timeout(function () {
          $scope.retStatus.loading = false;
          $scope.retStatus.message = 'app.error.TIMEOUT';
          $scope.retStatus.result = "error";
        }, 10000);

        console.log('Registering account...');
        AuthService.register($scope.account.username,
            $scope.account.email,
            $scope.account.password1, $scope.account.password2,
            $scope.account.activation_token,
            $scope.account.company,
            $scope.account.country,
            $scope.account.distributor,
        ).then(
            (data) => {
              $scope.retStatus.message = data.detail;
              $rootScope.parsley_reset('signupInput', dataError);
              if (AuthService.response.status === 201) {
                $scope.retStatus.result = "success";
                setTimeout(function () {
                  $state.go(AuthService.loginState, {
                    detail: data.detail,
                    status: AuthService.response.status
                  }, {location: false, inherit: false});
                }, 2000);
              } else {
                $scope.retStatus.result = "error";
              }
            }, (data) => {
              $rootScope.parsley_reset('signupInput', dataError);
              dataError = data;
              var used_errors = $rootScope.parsley_set('signupInput', dataError);
              if (data?.non_field_errors) {
                $scope.retStatus.message = data.non_field_errors[0];
              } else {
                $scope.retStatus.message = 'pages.register.SERVERREQUEST';
              }
              $scope.retStatus.result = "error";
            })
            .finally(() => {
              $scope.retStatus.loading = false;
              $timeout.cancel(timeoutRequest);
            });
      };

      var x = /[a-fA-F0-9]/;
      var s = '-';
      var m = [x, x, x, x, x, x, x, x, s, x, x, x, x, s, x, x, x, x, s, x, x, x, x, s, x, x, x, x, x, x, x, x, x, x, x, x];
      $scope.text_mask_config = {
        mask: m,
        // mask: function (rawValue) {
        //   console.log(rawValue);
        //   return m;
        // },
      }
    }]);
}