namespace wg {

  export class CreateOrEditTask {

    public TASK_TYPES = wg.TASK_TYPES;
    public stage: IWinemakingStage;
    public task: ITask;
    public createMode: boolean = true;
    public rulesErrors = new Map<IAlarmRule, []>(); //maps an array of errors for each rule
    public taskErrors: {} = {};
    public showErrors: boolean = false;
    public repeats: number;
    public addStopActionDelay: number | Date = 0;
    public addStopAction: boolean;
    public taskForm: any;
    public responseError: string = null;
    public loading: boolean = false;

    public isEnologistHelpCollapsed = true;
    public test= "test";

    static $inject = ['$scope', '$rootScope', 'ngDialog', '$http'];


    constructor(private $scope: any, private $rootScope: any, private ngDialog: any, private $http: ng.IHttpService) {
    }


    $onInit() {

      //CREATE MODE
      if (!this.$scope.task) {
        this.createMode = true;
        // Set default values
        this.task = {
          rules: [],
          required_to_end_stage: true,
          requires_enologist_validation: false,
        } as ITask;
      }
      //EDIT MODE
      else {
        this.createMode = false;
        this.task = (this.$scope.task);

        if (this.task.quantity) {
          this.task.quantity_txt = this.task.quantity.toString();
          // @ts-ignore
          this.task.quantity = parseFloat(this.task.quantity);
          // When taskForm becomes available, set the view value to the quantity_txt
          this.$scope.$watch('taskForm.quantity', (newValue, oldValue) => {
            if (WG_debug) console.log('taskForm became available quantity', newValue, oldValue);
            if (newValue && this.task.quantity_txt) {
              newValue.$setViewValue(this.task.quantity_txt);
              newValue.$render();
            }
          });
        }
      }

      this.stage = this.$scope.stage;
    }

    public addRule(ctrl: CreateOrEditTask) {
      if (!ctrl.task.rules) {
        ctrl.task.rules = [];
      }
      ctrl.task.rules.push({
        type_id: undefined,
        delay: 0,
      })
    }

    public removeRule(rule: IAlarmRule) {
      let index = this.task.rules.indexOf(rule);
      this.task.rules.splice(index, 1);
    }

    public getNumberOfErrors(rule: IAlarmRule) {
      const errors = this.rulesErrors.get(rule);
      if (!errors) return -1;
      return Object.keys(errors).length;
    }

    public hasErrors(rulesErrors: Map<IAlarmRule, []>, taskErrors: {}): boolean {
      console.log('hasErrors', rulesErrors, taskErrors);
      // rulesErrors in rules
      const values = Array.from(rulesErrors.values());
      for (let val of values) {
        if (!_.isEmpty(val)) {
          return true;
        }
      }

      //errors in task
      const taskKeys = Object.keys(taskErrors);
      for (let key of taskKeys) {
        if (!_.isEmpty(taskErrors[key])) {
          return true;
        }
      }

      return false;
    }

    public checkForTaskErrors(task: wg.ITask, taskErrors: {}) {


      if (task.type_id == undefined) {
        taskErrors["type"] = "Please select a type";
      } else {
        taskErrors["type"] = [];
      }

      if (_.isEmpty(task.name)) {
        taskErrors["name"] = "Please enter a name";
      } else {
        taskErrors["name"] = [];
      }

      if (task.description?.length > 2048) {
        taskErrors["template_notes"] = "Recipe note is too long";
      }


    }

    public save() {

      //TODO: change this name for something more specific. i.e. explicitly tell that this checks for errors in the rules array
      // TODO: This is not blocking the form submission, it should
      // this.$scope.$emit('checkErrors');
      this.$scope.$emit('showErrors');
      this.showErrors = true;

      // Get and send the number-string as saved in the quantity-form
      if (!_.isNil(this.$scope.taskForm?.quantity?.$viewValue)) {
        this.task.quantity_txt = this.$scope.taskForm.quantity.$viewValue;
      }
      _.forEach(this.$scope.taskForm, (field, key) => {
        if (typeof field === 'object' && field.$setTouched) {
          field.$setTouched();
        }
      });
      this.checkForTaskErrors(this.task, this.taskErrors);

      if (this.$scope.taskForm.$valid && !this.hasErrors(this.rulesErrors, this.taskErrors)) {
        this.loading = true;

        if (this.createMode) {
          this.createNewTask(this.task);
        } else {
          this.editTask(this.task);
        }

      }
    }


    cancel() {
      this.ngDialog.closeAll();
    }


    public async deleteRules(rules: IAlarmRule[]): Promise<any> {

      const promises = rules.filter(rule => rule.markedForDeletion).map(rule => {
        if (rule.id) {
          return this.$http.delete('api/dashboard/winemaking/task_rule_template/' + rule.id + '/');
        }
      })

      return Promise.all(promises);
    }

    public createNewTask(task: ITask) {
      if (WG_debug) console.log('Create task', task);
      const taskData: ITask = {
        type_id: task.type_id,
        name: task.name,
        description: task.description,
        template_notes: task.template_notes,
        stage_template: this.stage.id,
        required_to_end_stage: task.required_to_end_stage,
        requires_enologist_validation: task.requires_enologist_validation,
        quantity: !_.isNil(task.quantity_txt) ? task.quantity_txt : task.quantity,
        quantity_unit: task.quantity_unit,
        added_quantity: task.added_quantity,
      };
      // remove null rules or rules without a type_id
      _.remove(task.rules, rule => _.isNil(rule?.type_id));

      this.$http.post<ITask>('api/dashboard/winemaking/task_template/', taskData)
          .then((response) => {
            task.id = response.data.id;

            this.createOrEditRule(task.rules).then((result) => {
              const addedTask = {
                ...task,
                rules: result.map(rule => rule?.data)
              }
              //TODO!! UNIFORMIZE NORMALIZATION
              addedTask.rules.forEach(obj => {
                Object.keys(obj).forEach(key => {
                  if (key == 'previous_task_template') {
                    obj['previousTask'] = obj[key];
                    delete obj[key];
                  }
                  if (key == ("parameter") && _.isNumber(obj[key])) {
                    let _sensor = _.find(this.$rootScope.WGSensors?.sensors, {id: obj[key]});
                    if (_sensor) {
                      obj[key] = _sensor;
                    }
                  }
                })
              })

              this.$scope.confirm({addedTasks: [addedTask]});
            })
                .catch((error: any) => {
                  const errorCode = error.status;
                  console.log(error);
                })
                .finally(() => {
                  this.loading = false;
                });
          })
    }

    // private addRules(taskTemplateId: number): Promise<any> {
    //   const rules = this.task.rules.map(rule => {
    //     // If rule.delay is a Date object, convert it to a number
    //     if (rule.delay && rule.delay instanceof Date) {
    //       rule.delay = rule.delay.getHours() * 60 * 60 + rule.delay.getMinutes() * 60;
    //     }
    //
    //     return {
    //       type_id: rule.type_id,
    //       delay: rule.delay,
    //       previous_task_template: rule.previousTask,
    //       task_rule_template: taskTemplateId
    //     }
    //   });
    //
    //   const rulePromises = rules.map(rule => {
    //     const data = {
    //       ...rule,
    //       task_template: taskTemplateId
    //     };
    //     return this.$http.post('api/dashboard/winemaking/task_rule_template/', data);
    //   });
    //
    //   return Promise.all(rulePromises);
    // }

    public async editTask(task: ITask) {
      if (WG_debug) console.log('Editing task', task);
      //TODO! atomify this

      const taskData : ITask = {
        type_id: task.type_id,
        name: task.name,
        description: task.description,
        template_notes: task.template_notes,
        required_to_end_stage: task.required_to_end_stage,
        requires_enologist_validation: task.requires_enologist_validation,
        quantity: !_.isNil(task.quantity_txt) ? task.quantity_txt : task.quantity,
        quantity_unit: task.quantity_unit,
        added_quantity: task.added_quantity,
      };
      this.loading = true;
      const taskResult = await this.$http.patch('api/dashboard/winemaking/task_template/' + task.id + '/', taskData);
      if (taskResult.status < 200 || taskResult.status >= 300) {
        this.responseError = "Error updating task";
        this.loading = false;
        return;
      }

      const rulesToDelete = task.rules.filter(rule => rule.markedForDeletion);
      const rulesToEdit = task.rules.filter(rule => !rule.markedForDeletion);
      await this.deleteRules(rulesToDelete);
      const rulesEditingResult = await this.createOrEditRule(rulesToEdit);

      //remove the rules that were deleted from the task
      task.rules = _.remove(task.rules, rule => !rule.markedForDeletion);

      //the result of the request
      const editedTask = taskResult.data as ITask;
      editedTask.rules = rulesEditingResult.map(rule => rule?.data);

      this.loading = false;
      this.$scope.confirm({addedTasks: [task]});
    }


    public getTaskType(id) {
      return _.find(TASK_TYPES, {id: id});
    }

    /**
     * goes through the rules and creates if the id field is null and tries to update if there's an id
     * @param rules - the rules to be created or edited
     */
    public async createOrEditRule(rules: IAlarmRule[]): Promise<angular.IHttpPromiseCallbackArg<IAlarmRule>[]> {
      const rulePromises = rules.map(async rule => {
        if (_.isNil(rule.type_id)) {
          console.warn('Invalid rule type:', rule);
          return null;
        }
        // send parameter as Sensor_id, not internal_name nor Sensor
        let _parameter_id = null;
        if (_.isNumber(rule.parameter?.['id'])) {
          _parameter_id = rule.parameter['id'];
        } else if (_.isString(rule.parameter)) {
          _parameter_id = this.$rootScope.WGSensors.sensors_name[rule.parameter]?.id;
        } else if (!_.isNil(rule.parameter)) {
          console.error('Invalid parameter, not null?', rule.parameter);
        }

        let data = {
          name: rule.name,
          type_id: rule.type_id,

          parameter: _parameter_id,
          logical: rule.logical,
          expression: rule.expression,
          value: (!_.isNil(rule.value_conv) ? rule.value_conv : rule.value),
          // unit: rule.unit,

          previous_task_template: rule.previousTask,
          delay: rule.delay,

          task_template: "" + this.task.id
        };

        if (rule.id) { // Editing
          return this.$http.patch('api/dashboard/winemaking/task_rule_template/' + rule.id + '/', data);
        } else { // Creating
          // data['task_template'] = this.task.id;
          return this.$http.post('api/dashboard/winemaking/task_rule_template/', data);
        }
      });

      return Promise.all(rulePromises);

    }

  }


  App.controller('CreateOrEditTask', CreateOrEditTask as any);
}
