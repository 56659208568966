namespace wg {

  type sequenceNode = {
    tasks: ITask[]
    next: sequenceNode
  };

  type RowElement = {
    fraction: number, //
    widthMultiplier?: number,
    empty: boolean,
    task: ITask
  }


  class WgTaskSequence {

    public static $inject = ["$scope", "ngDialog", "$rootScope", "$http", "$state"];

    public TASK_RULE_TYPES = wg.TASK_RULE_TYPES;
    public task: ITask; //starting task
    public taskSequence: RowElement[][];
    public protocol: IWinemakingProtocol;
    public stage: IWinemakingStage;
    public readonly: boolean;

    public constructor(private $scope: ng.IScope,
                       private ngDialog: ng.dialog.IDialogService,
                       private $rootScope: any,
                       private $http: ng.IHttpService,
                       private $state: _IState) {
      $rootScope.$on('reloadTasks', (event, task) => {
        // this.buildTaskSequence(this.task, this.taskSequence);
      });

      $scope.$watch(() => this.protocol, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.taskSequence = this.renderSequence();
        }
      });

    }

    $onInit() {
      this.taskSequence = this.renderSequence();
      // console.log("wg-task-sequence", this.taskSequence);
    }


    public editTask(task: ITask, ctrl: WgTaskSequence) {
      // open modal
      this.ngDialog.openConfirm({
        template: 'app/views/modals/create-or-edit-task.html',
        data: {task: task, stage: ctrl.stage},
        controller: ['$scope', 'ngDialog', function ($scope, ngDialog) {
          $scope.task = _.cloneDeep(task);
          $scope.stage = ctrl.stage;
        }],
      }).then((result: { addedTasks: ITask[] }) => {
        const tasks = result.addedTasks;  //added or edited tasks
        tasks.forEach(modifiedTask => {
          const index = ctrl.stage.tasks.findIndex(t => t.id == modifiedTask.id);
          if (index !== -1) {
            //modify in place
            Object.keys(modifiedTask).forEach(key => {
              ctrl.stage.tasks[index][key] = modifiedTask[key];
            });
          } else {
            ctrl.stage.tasks.push(modifiedTask);
          }

          // For now, refresh state
          this.$state.reload();
        })

      }).catch(() => {

      });
    }


    public deleteTask(task: ITask, ctrl: WgTaskSequence) {
      this.$http.delete("api/dashboard/winemaking/task_template/" + task.id + "/")
          .then((result) => {
            if (WG_debug) console.log("Task deleted", result);
            _.remove(this.stage.tasks, (t) => t.id == task.id);
          })
          .catch((result) => {

          })
          .finally(() => {
            // For now, refresh state
            this.$state.reload();
          });
    }

    public getDependencyRule(task: ITask) {
      return task?.rules?.find(rule => rule.previousTask);
    }

    public getTaskById(id: number) {
      return this.stage.tasks?.find(t => t.id == id);
    }


    /**
     * renders the task sequence from a tree to a 2d array, so it's easier for angular to render
     */
    public renderSequence(): RowElement[][] {


      let sequence: RowElement[][] = [];

      let row = [
        {fraction: 1, empty: false, task: this.task}
      ];
      sequence.push(row);

      let largestFraction = 1;


      //first row
      while (!row.every(el => el.empty)) {

        let newRow: RowElement[] = [] as RowElement[];
        for (let el of row) {
          if (el.empty) {
            newRow.push({fraction: el.fraction, empty: true, task: null});
          } else {
            const children = this.stage.tasks.filter(t => t.rules?.find(r => r.previousTask == el.task.id));
            for (let child of children) {
              newRow.push({fraction: el.fraction * children.length, empty: false, task: child});
              largestFraction = Math.max(largestFraction, el.fraction * children.length);
            }
            if (children.length == 0) {
              newRow.push({fraction: el.fraction, empty: true, task: null});
            }
          }
        }
        sequence.push(newRow);
        row = newRow;
      }

      //widthMultiplier = 1/fraction * largestFraction
      sequence = sequence.map(row => {
        return row.map(el => {
          return {fraction: el.fraction, widthMultiplier: (1 / el.fraction) * largestFraction, empty: el.empty, task: el.task};
        })
      });

      return sequence;
    }

  }

  App.component('wgTaskSequence', {
        controller: WgTaskSequence as any,
        controllerAs: 'ctrl',
        bindings: {
          task: '=',
          allTasks: '<',
          protocol: '<',
          stage: '=',
          readonly: '='
        },
        templateUrl: 'app/views/directives/wg-task-sequence.html'
      }
  )
}