/*!
 *
 * Angle - Bootstrap Admin App + AngularJS
 *
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: http://support.wrapbootstrap.com/knowledge_base/topics/usage-licenses
 *
 */
namespace wg {
  if (typeof $ === 'undefined') {
    throw new Error('This application\'s JavaScript requires jQuery');
  }

// APP START
// ----------------------------------- 
  if (angular.version.minor > 5) {
    // https://stackoverflow.com/questions/50448326/uncaught-typeerror-angular-lowercase-is-not-a-function
    // https://github.com/angular/angular.js/commit/1daa4f2231a89ee88345689f001805ffffa9e7de
    angular.uppercase = function (text) {
      return text.toUpperCase();
    };
    angular.lowercase = function (text) {
      return text.toLowerCase();
    };
  }

  export var App = angular.module('dashboard', ['ngRoute', 'ngAnimate', 'ngStorage', 'ngCookies', 'pascalprecht.translate', 'ui.bootstrap', 'ui.router', 'oc.lazyLoad', 'ngSanitize', 'ngResource', 'ui.utils', 'textAngular', 'angularMoment', 'tmh.dynamicLocale', 'angular-jwt', 'restangular'
    , 'cfp.hotkeys'
    , 'duScroll'
    , 'angular-bind-html-compile'
    , 'bm.uiTour'
  ])

  App.run(['uiTourService', function (uiTourService) {
    uiTourService.createDetachedTour('dashTour');
  }])

  App.run(["$rootScope", "$state", '$location', '$window', '$templateCache', '$filter', 'AuthService', '$translate', 'amMoment', '$http', 'tmhDynamicLocale', 'DataUtils',
    function ($rootScope: IRootScope, $state: _IState, $location: ng.ILocationService, $window, $templateCache, $filter: ng.IFilterService, AuthService: IAuthService, $translate: ng.translate.ITranslateService, amMoment, $http: ng.IHttpService, tmhDynamicLocale, DataUtils: DataUtils) {
      if (WG_debug) console.log('App init');

      $rootScope.reload = function () {
        if (WG_debug) console.log('reload', $state.current.name);
        // Reload current controller only.
        $state.reload();
        // $state.transitionTo($state.current.name,
        //     $state.params,
        //     {
        //       reload: true,
        //       inherit: false,
        //       notify: true
        //     },
        // );
      };
      $rootScope.goBack = function () {
        window.history.back();
      };

      $rootScope.goHome = function () {
        $state.go(AuthService.homeState);
      };

      // var setTopNavBarOffsetTimeout;
      // $rootScope.setTopNavBarOffset = function () {
      //   $timeout.cancel(setTopNavBarOffsetTimeout);
      //   setTopNavBarOffsetTimeout = $timeout(function () {
      //     // document.getElementById('main_section').style.marginTop = document.getElementById('header').offsetHeight + 'px';
      //   }, 10);
      // }
      //
      // $(window).resize(function () {
      //   $rootScope.setTopNavBarOffset()
      // });

      if (WG_debug) console.log('App init Defining vars');
      $rootScope.WG_debug = WG_debug;
      $rootScope.WG_version = WG_version;
      $rootScope.WG_site_name = WG_site_name;
      $rootScope.WG_is_beta = WG_debug || wg.isBeta();
      $rootScope.WG_is_mar2protect = (WG_site_name == 'mar2protect');

      $rootScope.topic_re = new RegExp('(?:^|\s)(([0-9a-zA-Z]+)/([0-9a-zA-Z]+)/([0-9a-zA-Z]+))/([0-9a-zA-Z]+)/?(.*)?(?:\s|$)');
      // Set reference to access them from any scope
      $rootScope.$state = $state;
      $rootScope.$storage = $window.localStorage;

      $rootScope.system_status = $rootScope.system_status || {};

      $rootScope.ERRORS_to_string = ERRORS_to_string;

      $rootScope.TASK_TYPES = TASK_TYPES;
      $rootScope.TASK_STATUS_TYPES = TASK_STATUS_TYPES;
      $rootScope.TASK_RULE_TYPES = TASK_RULE_TYPES;
      $rootScope.WINE_TYPES = WINE_TYPES;


      // Scope Globals
      // -----------------------------------
      $rootScope.app = {
        name: 'Winegrid Dashboard',
        description: 'Web App',
        year: ((new Date()).getFullYear()),
        layout: {
          isFixed: true,
          isCollapsed: false, // If sidebar is collapsed/shown in desktop
          isSubNavHidden: false, // If top-nav is hidden/shown in mobile
          isBoxed: false,
          isRTL: false,
          horizontal: false,
          isFloat: false,
          asideHover: false, // sub-sidebar shown on mouse hover
        },
        useFullLayout: false,
        hiddenFooter: false,
        viewAnimation: 'ng-fadeInUp',
      };
      if ($rootScope.WG_is_mar2protect) {
        $rootScope.app.name = 'Mar2Protect Dashboard';
      }
      $rootScope.pageTitle = $rootScope.app.name;
      if (WG_debug) console.log('Initialized layout', $rootScope.app.layout);

      if (WG_debug || $rootScope.WG_is_beta) {
        screenLog.init({
              autoScroll: true,
              css: "top: auto;\n" +
                  "right: 225px;\n" +
                  "bottom: 0px;\n" +
                  "width: 60%;\n" +
                  "max-width: 70vh;\n" +
                  "max-height: 17vh;\n" +
                  "padding: 0px;"
            },
            'error',);
      }


      // Internationalization
      // ----------------------

      $rootScope.language = {
        // Handles language dropdown
        listIsOpen: false,
        // list of available languages
        available: {
          'en-GB': {localeId: 'en-GB', name: 'English', iconid: "gb"},
          'pt-PT': {localeId: 'pt-PT', name: 'Português', iconid: "pt"},
          'fr-FR': {localeId: 'fr-FR', name: 'Français', iconid: "fr"},
          'es-ES': {localeId: 'es-ES', name: 'Español', iconid: "es"},
          'de-DE': {localeId: 'de-DE', name: 'Deutsch', iconid: "de"},
          'it-IT': {localeId: 'it-IT', name: 'Italiano', iconid: "it"},
          'ka-GE': {localeId: 'ka-GE', name: 'Georgian', iconid: "ge"}
        },
        // display always the current ui language
        init: function () {
          if (WG_debug) console.log('App language init');
          let proposedLanguage = $translate.proposedLanguage() || $translate.use();
          let preferredLanguage = $translate.preferredLanguage(); // we know we have set a preferred one in app.config
          let localeId = (proposedLanguage || preferredLanguage);
          if (!this.available[localeId]) {
            localeId = preferredLanguage;
          }
          this.set(localeId, null);
        },
        set: function (localeId, ev) {
          if (WG_debug) console.log('App language set');
          this.listIsOpen = false;
          this.selected = this.available[localeId];
          $translate.use(localeId).then(
              function (data) {
                // $rootScope.$emit('languageChange', localeId);
                $rootScope.language.configLanguage(localeId, ev);
              });
        },
        configLanguage: function (localeId: string, event) {
          $http.defaults.headers.common['Accept-Language'] = localeId;

          if (localeId === 'pt-PT') {
            $rootScope.amMomentTimezone = 'Europe/Lisbon';
            amMoment.changeLocale('pt');
            tmhDynamicLocale.set(localeId.toLowerCase());
            $window.Parsley?.setLocale('pt-pt');
          } else if (localeId === 'en-US') {
            $rootScope.amMomentTimezone = 'America/New_York';
            amMoment.changeLocale('en');
            tmhDynamicLocale.set(localeId.toLowerCase());
            $window.Parsley?.setLocale('en');
          } else if (localeId === 'en-GB') {
            $rootScope.amMomentTimezone = 'Europe/London';
            amMoment.changeLocale('en');
            tmhDynamicLocale.set(localeId.toLowerCase());
            $window.Parsley?.setLocale('en');
          } else if (localeId === 'fr-FR') {
            $rootScope.amMomentTimezone = 'Europe/Paris';
            amMoment.changeLocale('fr');
            tmhDynamicLocale.set(localeId.toLowerCase());
            $window.Parsley?.setLocale('fr');
          } else if (localeId === 'es-ES') {
            $rootScope.amMomentTimezone = 'Europe/Madrid';
            amMoment.changeLocale('es');
            tmhDynamicLocale.set(localeId.toLowerCase());
            $window.Parsley?.setLocale('es');
          } else if (localeId === 'it-IT') {
            $rootScope.amMomentTimezone = 'Europe/Rome';
            amMoment.changeLocale('it');
            tmhDynamicLocale.set(localeId.toLowerCase());
            $window.Parsley?.setLocale('it');
          } else if (localeId === 'de-DE') {
            $rootScope.amMomentTimezone = 'Europe/Berlin';
            amMoment.changeLocale('de');
            tmhDynamicLocale.set(localeId.toLowerCase());
            $window.Parsley?.setLocale('de');
          } else if (localeId === 'ka-GE') {
            $rootScope.amMomentTimezone = 'Asia/Tbilisi';
            amMoment.changeLocale('ka');
            tmhDynamicLocale.set(localeId.toLowerCase());
            $window.Parsley?.setLocale('ka');
          }
          if (angular.isDefined(window.Highcharts)) {
            var options = Highcharts.getOptions();
            if (angular.isObject(options.lang)) {
              options.lang.loading = $translate.instant('highchart.lang.loading');
              options.lang.months = $translate.instant('highchart.lang.months').split(', ');
              options.lang.shortMonths = $translate.instant('highchart.lang.shortMonths').split(', ');
              options.lang.weekdays = $translate.instant('highchart.lang.weekdays').split(', ');
              // @ts-ignore
              options.lang.exportButtonTitle = $translate.instant('highchart.lang.exportButtonTitle');
              // @ts-ignore
              options.lang.printButtonTitle = $translate.instant('highchart.lang.printButtonTitle');
              options.lang.rangeSelectorFrom = $translate.instant('highchart.lang.rangeSelectorFrom');
              options.lang.rangeSelectorTo = $translate.instant('highchart.lang.rangeSelectorTo');
              options.lang.rangeSelectorZoom = $translate.instant('highchart.lang.rangeSelectorZoom');
              options.lang.resetZoom = $translate.instant('highchart.lang.resetZoom');
              options.lang.resetZoomTitle = $translate.instant('highchart.lang.resetZoomTitle');
              options.lang.decimalPoint = $translate.instant('highchart.lang.decimalPoint');
              options.lang.thousandsSep = $translate.instant('highchart.lang.thousandsSep');
              options.lang.numericSymbols = $translate.instant('highchart.lang.numericSymbols').split(', ');
              options.lang.printChart = $translate.instant('highchart.lang.printChart');
              options.lang.downloadPNG = $translate.instant('highchart.lang.downloadPNG');
              options.lang.downloadJPEG = $translate.instant('highchart.lang.downloadJPEG');
              options.lang.downloadPDF = $translate.instant('highchart.lang.downloadPDF');
              options.lang.downloadSVG = $translate.instant('highchart.lang.downloadSVG');
              options.lang.contextButtonTitle = $translate.instant('highchart.lang.contextButtonTitle');
              Highcharts.setOptions(options);
            }
          }

          if (event != null) {
            // TODO: decide if we should refresh the browser or not.
            $rootScope.reload();
            // $state.go($state.current.name, $state.params, {reload: $state.current.name});
            // $state.go($state.current.name, $state.params, {reload: true});
          }
        },
      }

      //var localeId = $translate.proposedLanguage() || $translate.use() || $translate.preferredLanguage();
      //$rootScope.language.configLanguage(localeId, null);
      // $rootScope.$on('languageChange', function (event, localeId) {
      //   if (WG_debug) console.warn('On languageChange', localeId);
      //   $rootScope.language.configLanguage(localeId, null);
      // });
      $rootScope.language.init();


      console.log('App init, AuthService init');
      $rootScope.AuthService = AuthService;
      AuthService.init();


      if (!AuthService.isLogged) {
        console.log('App init, go to Login', AuthService.loginState);
        // $state.go(AuthService.loginState, {user: null});
        $state.go(AuthService.loginState, {
          user: null,
          // location: true,
          // inherit: false,
          // notify: true,
          // reload: true
        });
      }

      $rootScope.DataUtils = DataUtils;

      /**************************************************
       **************** STATES STUFF ********************
       **************************************************/

      $rootScope.previousState = {};

      $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        // if (WG_debug) console.log('main $stateChangeStart', fromState, toState, toParams);

        // Check if target state has been deprecated
        if (toState.redirectTo) {
          console.log('redirectTo:', toState.redirectTo);
          event.preventDefault();
          $state.go(toState.redirectTo, toParams, {location: 'replace'})
          return;
        }

        /**
         * $stateChangeStart is a synchronous check to the accessLevels property
         * if it's not set, it will setup a pendingStateChange and will let
         * the grandfather resolve do his job.
         *
         * In short:
         * If accessLevels is still undefined, it let the user change the state.
         * Grandfather.resolve will either let the user in or reject the promise later!
         */
        if (AuthService.userRole === null) {
          AuthService.doneLoading = false;
          AuthService.pendingStateChange = {
            toState: toState,
            toParams: toParams
          };
          return;
        }

        // if the state has undefined accessLevel, anyone can access it.
        // NOTE: if `wrappedService.userRole === undefined` means the service still doesn't know the user role,
        // we need to rely on grandfather resolve, so we let the stateChange success, for now.
        // console.log('main canAccess', AuthService.canAccess(toState.accessLevel));
        if (AuthService.canAccess(toState.accessLevel)) {
          if (WG_debug) console.log('We can access the new state.');
          angular.noop(); // requested state can be transitioned to.
        } else if (!AuthService.isLogged) {
          // ignore if already going to AuthService.loginState
          if (toState.name === AuthService.loginState) {
            angular.noop();
          } else {
            console.log('Go to Login', AuthService.loginState);
            event.preventDefault();
            $state.go(AuthService.loginState, {user: null});
          }
        } else if (AuthService.view_as_owner.id && toParams.user
            && toParams.user != AuthService.view_as_owner.id) {
          console.log('Changing selected user to: ', toParams.user);
          angular.noop();
        } else if (toState.accessLevel == "distributor" && AuthService.view_as_owner.role == 'user') {
          if (toState.name === AuthService.homeState) {
            angular.noop();
          } else {
            console.log('Changed from Distributor. Going home: ', toParams.user);
            event.preventDefault();
            $state.go(AuthService.homeState);
          }
        } else {
          event.preventDefault();
          $rootScope.$emit('$statePermissionError');
          console.error('Error, trying to access a protected page:', {
            fromState: fromState,
            fromParams: fromParams,
            toState: toState,
            toParams: toParams,
            homeState: AuthService.homeState,
            view_as_owner: AuthService.view_as_owner
          });

          // $state.go(AuthService.errorState, {error: 'unauthorized'}, {location: 'replace'});
          $state.go(AuthService.errorState, {error: 'unauthorized'}, {location: false, inherit: false});
        }
      });

// $rootScope.$on('$locationChangeSuccess', function (event, _new_url, _old_url) {
//   // $rootScope.actualLocation = $location.absUrl();
//   if (WG_debug) console.warn('Location changed:', $location.absUrl(), {_new_url: _new_url, _old_url: _old_url});
// });

// Hook success
      $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        if (WG_debug) console.debug('App init $stateChangeSuccess', toState);

        //https://jasonwatmore.com/post/2015/11/07/angularjs-google-analytics-with-the-ui-router
        if (angular.isDefined($window.ga)) {
          $window.ga('set', 'page', $window.location.hash);
          $window.ga('send', 'pageview');
          // $window.ga('send', 'pageview', $location.path());
        }

        // Stores last-state on localStorage to get back to it later
        // if (fromState?.name) {
        //   if (fromState.name.startsWith?.("page.")
        //       || ['app.error'].includes(fromState.name)) {
        //     if (WG_debug) console.log('Undesired state. Don\'t save to avoid coming back.', fromState.name);
        //   } else {
        //     $rootScope.previousState = {name: fromState.name, params: fromParams};
        //     // if(WG_debug) console.log('previousState $stateChangeSuccess', $rootScope.previousState);
        //   }
        // }

        // Stores last-state on localStorage to get back to it later
        if (toState?.name) {
          if (toState.name.startsWith("page") || toState.name == 'app.error') {
            if (WG_debug) console.debug('Useless new state. Don\'t save.', toState.name);
          } else {
            $rootScope.previousState = {
              name: toState.name,
              params: toParams,
              url: toState.url,
              href: $state.href(toState.name)
            };
            let _new_user_data = {last_state: $rootScope.previousState};

            if (WG_debug) console.debug('App init $stateChangeSuccess save on UD.last_state', $rootScope.previousState);
            AuthService.setUserData(_new_user_data);
          }
        }

        // if (WG_debug) console.info('$stateChangeSuccess 2', event, fromParams, toParams);
        // display new view from top
        $window.scrollTo(0, 0);

        // Save the route title
        // $rootScope.pageTitle = $rootScope.app.name + ' - ' + ($state.current['title'] || $translate.instant($state.current.name) || $rootScope.app.description);
        $rootScope.pageTitle = $rootScope.app.name + ' - ' + ($state.current['title'] || $rootScope.app.description);
        document.title = $rootScope.pageTitle;
      });


// Hook not found
      $rootScope.$on('$stateNotFound', function (event, unfoundState, fromState, fromParams) {
        console.log('$stateNotFound');
        console.log(unfoundState.to); // "lazy.state"
        console.log(unfoundState.toParams); // {a:1, b:2}
        console.log(unfoundState.options); // {inherit:false} default options
      });
// Hook error
      $rootScope.stateChangeErrorCount = 0;
      /**
       * Gets triggered when a resolve isn't fulfilled
       * NOTE: when the user doesn't have required permissions for a state, this event
       *       it's not triggered.
       *
       * In order to redirect to the desired state, the $http status code gets parsed.
       * If it's an HTTP code (ex: 403), could be prefixed with a string (ex: resolvename403),
       * to handle same status codes for different resolve(s).
       * This is defined inside $state.redirectMap.
       */
      $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
        console.warn('$stateChangeError', _.cloneDeep({toState: toState, toParams: toParams, fromState: fromState, fromParams: fromParams, error: error}));
        $rootScope.stateChangeErrorCount++;
        if ($rootScope.stateChangeErrorCount > 4) return;
        //return $state.go(AuthService.errorState, { error: error }, { location: false, inherit: false });

        // If it's the first time and error contains "You either misspelled the module name or forgot to load", retry the same transition without allowing cache
        if ($rootScope.stateChangeErrorCount == 1 && error.message?.includes('You either misspelled the module name or forgot to load')) {
          console.warn('Retry transition without cache');
          return $state.go(toState, toParams, {location: false, inherit: false, notify: false, reload: true});
        }
        /**
         * This is a very clever way to implement failure redirection.
         * You can use the value of redirectMap, based on the value of the rejection
         * So you can setup DIFFERENT redirections based on different promise errors.
         */
        var redirectObj;
        // in case the promise given to resolve function is an $http request
        // the error is a object containing the error and additional informations
        error = (typeof error === 'object') ? error.status + '' : error;
        // in case of a random 4xx/5xx status code from server, user gets loggedout
        // otherwise it *might* forever loop (look call diagram)
        if (/^[45]\d{2}$/.test(error)) {
          AuthService.logout();
        }
        /**
         * Generic redirect handling.
         * If a state transition has been prevented and it's not one of the 2 above errors, means it's a
         * custom error in your application.
         *
         * redirectMap should be defined in the $state(s) that can generate transition errors.
         */
        if (angular.isDefined(toState.redirectMap) && angular.isDefined(toState.redirectMap[error])) {
          if (typeof toState.redirectMap[error] === 'string') {
            return $state.go(toState.redirectMap[error], {error: error}, {location: false, inherit: false});
          } else if (typeof toState.redirectMap[error] === 'object') {
            redirectObj = toState.redirectMap[error];
            return $state.go(redirectObj.state, {error: redirectObj.prefix + error}, {
              location: false,
              inherit: false
            });
          }
        }
        return $state.go(AuthService.errorState, {error: error}, {location: false, inherit: false});
      });


      // If website version is different than cached, refresh user-configs
      if (AuthService.checkVersion(WG_version)) {
        // disable template cache
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
          if (typeof (toState) !== 'undefined') {
            $templateCache.remove(toState.templateUrl);
          }
        });
      }

      $rootScope.scroll_to = function (div_id) {
        return scroll_to(div_id)
      };

      $rootScope.spitData = spitData;

      $rootScope.toPrettyJSON = function (object, spacing) {
        return $filter('json')(object, spacing);
      };

      // $rootScope.toPrettyXML = function (xml) {
      //   return formatXml(xml);
      // };

      // $rootScope.value2percentage = function (value, min, max) {
      //   return (value - min) / (max - min) * 100;
      // };

      /**
       * Returns a darker or lighter shade of a color
       * @param color
       * @param percent - between -1 and 1. -1 makes it black, 1 makes it double the brightness
       */
      // $rootScope.shadeColor = function (color, percent: number) {
      //   var R = parseInt(color.substring(1, 3), 16);
      //   var G = parseInt(color.substring(3, 5), 16);
      //   var B = parseInt(color.substring(5, 7), 16);
      //
      //   R = R * (100 + percent) / 100;
      //   G = G * (100 + percent) / 100;
      //   B = B * (100 + percent) / 100;
      //
      //   R = (R < 255) ? R : 255;
      //   G = (G < 255) ? G : 255;
      //   B = (B < 255) ? B : 255;
      //
      //   var RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
      //   var GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
      //   var BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));
      //
      //   return "#" + RR + GG + BB;
      // };

      $rootScope.getResources = function (topic) {
        var match = $rootScope.topic_re.exec(topic);
        if (match != null) {
          return {
            base: match[1],
            tenant: match[2],
            product: match[3],
            group: match[4],
            device: match[5],
            stream: match[6]
          };
        }
        return {};
      };

      $rootScope.parsley_reset = function (id, dataError) {
        for (let key in dataError) {
          $('#' + id + '-' + key)?.parsley()?.reset();
        }
      };

      $rootScope.parsley_set = function (id, dataError) {
        let specificField, i, ret = false;
        for (let key in dataError) {
          specificField = $('#' + id + '-' + key).parsley();
          if (_.isNil(specificField)) continue;
          i = 0;
          _.forEach(dataError[key], function (error) {
            window['ParsleyUI'].addError(specificField, 'error-' + i, error);
            ret = true;
            i++;
          });
        }
        return ret;
      };

      console.log('WG Dashboard Ready');
    }]);
}

let App = wg.App;

// @ts-ignore
let CronParser = window?.['CronParser'] || global?.window?.['CronParser'] || global?.['CronParser'] || CronParser;

