namespace wg {

  class CreateOrEditAlarmForDevice {
    public loading: boolean = true;
    public upstreamError: string;


    //you can either pass in a device or a list of alarms
    public device: any;
    public alarms: IAlarm[] = [];
    public selectedAlarm: IAlarm;
    public alarmForm: any;
    public myParameters: ISensor[] = [];
    public result: "success" | "error" | "" = "" //for sweetalert
    static $inject = ['$scope', 'ngDialog', 'WGApiData', 'AuthService', '$http', '$rootScope', 'DataUtils', '$timeout', '$translate'];

    public constructor(
        private $scope,
        private ngDialog: ng.dialog.IDialogService,
        private WGApiData: WGApiData,
        private AuthService: IAuthService,
        private $http: ng.IHttpService,
        private $rootScope: any,
        private DataUtils: DataUtils,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService
    ) {
      this.device = this.$scope.ngDialogData.device;

    }

    // Get notification targets of selected user, and parameters of selected device to share with all sub-components
    public $onInit() {
      if (this.device) {
        this.myParameters = emptyOrCreateArray(this.myParameters);
        this.myParameters = this.WGApiData.extractAlarmParameters([this.device], this.myParameters)
      }

      this.getAlarms(this);

    }

    private getAlarms(ctrl: CreateOrEditAlarmForDevice) {

      //if the sensors are not ready, wait for them to be ready
      if (!this.WGApiData.WGSensors.ready || !this.WGApiData.WGDevices.ready) {
        let unhook1 = this.$rootScope.$watchGroup(['WGSensors.ready', 'WGDevices.ready'], () => {
          if (!this.WGApiData.WGSensors.ready || !this.WGApiData.WGDevices.ready) {
            return;
          }
          unhook1();
          if (this.device) {
            this.myParameters = emptyOrCreateArray(this.myParameters);
            this.myParameters = this.WGApiData.extractAlarmParameters([this.device], this.myParameters)
          }
          this.getAlarms(ctrl);
        });
        return;
      }

      this.loading = true;
      this.$http.get<IDataResponse<IAlarm>>(`api/dashboard/alarm/?device_id=${ctrl.device.id}`)
          .then((response) => {
            ctrl.alarms = response.data.results

            ctrl.alarms.forEach((alarm: IAlarm) => {
              if (alarm.device != ctrl.device.id && alarm.device?.id != ctrl.device.id) {
                console.error("Alarm device does not match the device id", alarm.device, ctrl.device.id);
              }

              alarm.actions = parseData(alarm.actions, {});
              alarm.active = alarm.status >= 1;
              alarm.rules = alarm.rules || [];
              alarm.rules.forEach((rule) => {
                rule.type_id = wg.TASK_RULE_TYPES.parameter_reached.id;

                if (rule.parameter?.['id']) {
                  rule.parameter = this.WGApiData.WGSensors.sensors_id[rule.parameter['id']];
                } else if (rule.parameter && _.isNumber(rule.parameter)) {
                  rule.parameter = this.WGApiData.WGSensors.sensors_id[rule.parameter];
                } else if (rule.parameter && _.isString(rule.parameter)) {
                  rule.parameter = this.WGApiData.WGSensors.sensors_name[rule.parameter];
                } else {
                  console.error("Rule parameter is not valid", rule.parameter);
                }
                rule.value_conv = convert(rule.value, rule.parameter?.conversion?.id, null, false);
                if (!_.isNil(rule.parameter?.configs?.decimals)) {
                  rule.value_conv = Math.round(rule.value_conv * Math.pow(10, rule.parameter.configs.decimals + 1)) / Math.pow(10, rule.parameter.configs.decimals + 1);
                }
              })
              //used `as any` because it does not make sense to add this to the interface since it is only used here
              if (!(alarm as any).rulesErrors) {
                (alarm as any).rulesErrors = new Map();
              }

              // Anonymize the alarm entity
              this.AuthService.anonymize_entity("Alarm", alarm);
            })
            console.log("Got Alarms", ctrl.alarms);
          })
          .catch((error) => {
            console.error("Failed to get alarms", error);
          })
          .finally(() => {
            this.loading = false;
          });
    }

    // parent method of all the alarm CRUD
    // splits the alarms into two arrays, one for updating and one for creating
    // there are nuances to each of these operations, so they are handled in their own methods, check comments
    public saveAlarms(ctrl: CreateOrEditAlarmForDevice, alarmToSave: IAlarm) {

      // error and loading handling
      this.$rootScope.$emit('showErrors');
      if (ctrl.alarmForm.$invalid || this.WGApiData.WGAlarms.checkAlarmForErrors(alarmToSave)) {
        //mark the form elements as dirty so that the errors can be shown
        ctrl.alarmForm.$setDirty();
        Object.keys(ctrl.alarmForm).forEach((key) => {
          if (ctrl.alarmForm[key] && ctrl.alarmForm[key].$setTouched) {
            ctrl.alarmForm[key].$setTouched();
          }
        })
        return;
      }
      let _alarmToSave = _.cloneDeep(alarmToSave);

      // @ts-ignore
      _alarmToSave.notification_targets = _.map(_alarmToSave.notification_targets, 'id');

      ctrl.loading = true; // Canceled in getAlarms()
      this.WGApiData.WGAlarms.saveAlarm(_alarmToSave, (error) => {

            this.getAlarms(ctrl);
            if (error) {
              // ctrl.upstreamError = error
              ctrl.loading = false;
              ctrl.result = "error";

            }
            this.selectAlarm(null);
          },
          false)
    }


    public deleteAlarm(alarm: IAlarm) {
      const ctrl = this;
      if (alarm.id) {
        this.WGApiData.WGAlarms.delete(alarm.id, () => {
          this.alarms = _.filter(this.alarms, (a) => a.id !== alarm.id);
          this.selectedAlarm = null;
          this.loading = false;
          this.getAlarms(this);
        })

      } else {
        this.alarms = _.filter(this.alarms, (a) => a !== alarm);
        this.selectedAlarm = null;
      }
    };


    public toggleAlarmStatus(alarm: IAlarm) {

      if (!alarm.id) {
        alarm.status = 0
        alarm.active = false;
        return;
      }

      //keep angular from automatically updating the model
      alarm.active = alarm.status > 0;

      this.$http.patch<IAlarm>(`api/dashboard/alarm/${alarm.id}/`, {status: alarm.status > 0 ? 0 : 1})
          .then((alarmResponse) => {
            alarm.status = alarmResponse.data.status;
            alarm.active = alarm.status > 0;
            console.log("Alarm status toggled", alarmResponse.data);
          })
          .catch((error) => {
            console.error("Failed to toggle alarm status", error);
          })
    }

    public selectAlarm(alarm: IAlarm) {

      //mark the form elements as pristine so that the errors can be hidden
      this.alarmForm?.$setPristine();
      this.alarmForm && Object.keys(this.alarmForm).forEach((key) => {
        if (this.alarmForm[key] && this.alarmForm[key].$setUntouched) {
          this.alarmForm[key].$setUntouched();
        }
      })

      // this.alarms.forEach((a: IAlarm) => {
      //   a.selected = false;
      // })

      this.selectedAlarm = null;
      this.$timeout(() => { // This forces the angularJS component to reset, reconstruct and rebind
        this.selectedAlarm = _.cloneDeep(alarm);

        if (this.selectedAlarm != null) {
          this.selectedAlarm.selected = true;
          this.selectedAlarm.rearm_interval_option = this.selectedAlarm.rearm_interval_option || wg.availableRearmOptions.find((option) => option.secondsDuration === this.selectedAlarm.rearm_interval);
        }
      });
    }

    public addAlarm(ctrl: CreateOrEditAlarmForDevice) {
      const newAlarm = {
        id: null,
        device: ctrl.device,
        name: this.$translate.instant("app.modal.alarm.NEW_ALARM"),
        status: 1,
        rules: [],
        actions: {email: false, sms: false},
        rearm_interval_option: wg.availableRearmOptions[0],
        notification_targets: [],
        sensor: undefined,
        rulesErrors: new Map(),
      } as unknown as IAlarm;
      ctrl.alarms.push(newAlarm);
      ctrl.selectAlarm(newAlarm);
    }

  }

  App.controller('CreateOrEditAlarmForDevice', CreateOrEditAlarmForDevice);
}


