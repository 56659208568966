namespace wg {
  // This is the controller for the modal that allows the user to create or edit a stage
  // If $scope.ngDialogData.stage is defined, the user is editing an existing protocol
  // Otherwise, the user is creating a new protocol
  export class CreateOrEditStage {

    public stage: IWinemakingStage;
    public createMode: boolean = true;

    public loading: boolean = true;
    public protocol: IWinemakingProtocol;


    static $inject = ['ngDialog', '$scope', '$http'];

    constructor(private ngDialog: any, private $scope: any, private $http: ng.IHttpService) {

      console.log('CreateOrEditStage', this.$scope.ngDialogData);

      if (this.$scope.ngDialogData?.stage) {
        //editing
        this.stage = _.cloneDeep(this.$scope.ngDialogData.stage);
        this.createMode = false;
      } else {
        //creating so the default 'previous_stage_template' is the last stage of the protocol
        this.stage = {
          name: this.$scope.ngDialogData.name,
          previous_stage_template: this.$scope.ngDialogData.last_stage?.id,
        }
      }
      this.protocol = this.$scope.ngDialogData.protocol;


    }

    public $onInit() {
      this.loading = false;
    }

    public save() {

      const a =
          {
            "name": "testStageTemplate"
            , "description": ""
            , "protocol_template": 1
            , "previous_stage_template": null
            , "owner": {"id": 156, "username": "joao.rodrigues", "company": ""}
            , "configs": ""
          }
      if (this.createMode) {
        let data: IWinemakingStage = {
          name: this.stage.name,
          description: this.stage.description,
          previous_stage_template: this.stage.previous_stage_template,
        }
        if (this.stage?.id) {
          data.id = this.stage.id;
        }
        if (this.stage?.description) {
          data.description = this.stage.description;
        }
        if (this.protocol?.id) {
          data.protocol_template = this.protocol.id;
        }
        this.$http.post(apiUrls.winemaking + 'stage_template/', data)
            .then((response: any) => {
              console.log('Created stage', response.data);
              this.$scope.confirm(response.data);
            })
            .catch((response: any) => {
              const errorCode = response.status
              // this.closeWithError("There was an error communicating with the server.\n Error code = " + errorCode);
            })
            .finally(() => {
              this.loading = false;
            });
      } else {
        this.$http.patch(apiUrls.winemaking + 'stage_template/' + this.stage.id + '/', this.stage)
            .then((response: any) => {
              console.log('Updated stage', response.data);
              this.$scope.confirm(response.data);
            })
            .catch((response: any) => {
              const errorCode = response.status
              // this.closeWithError("There was an error communicating with the server.\n Error code = " + errorCode);
            });
      }

    }

    public cancel() {
      this.ngDialog.close()
    }
  }

  App.controller('CreateOrEditStage', CreateOrEditStage);
}