/**=========================================================
 * Module: edit-units.js
 * Modal controller for Units Create/Edit
 =========================================================*/
namespace wg {
  App.controller('fermentationPredictionController', ['$rootScope', '$scope', '$http', '$timeout', 'ngDialog',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $timeout: ng.ITimeoutService, ngDialog: ng.dialog.IDialogService) {

      let temp_changes_chart: Highcharts.Chart;

      if (!$scope.ngDialogData) {
        $scope.ngDialogData = {};
      }


      let parentRet: IReturnResult = {
        loading: false,
        result: '',
        message: '',
      };
      $scope.ret = parentRet;

      let ret_key = $scope.ngDialogData?.result || 'fermentationPredictionRet';
      if ($scope.$parent?.[ret_key]) {
        $scope.$parent[ret_key] = parentRet;
      } else if ($scope.$parent?.$parent?.[ret_key]) {
        $scope.$parent.$parent[ret_key] = parentRet;
      }


      $scope.nowDate = new Date();
      $scope.nowMillis = $scope.nowDate.getTime();

      $scope.newDataForm = {};

      $scope.ngDialogData.device = $scope.ngDialogData.device?.root_device || $scope.ngDialogData.device || null;

      interface IFormData {
        start_at: number;
        start_at_date: Date;
        end_at: number;
        end_at_date: Date;
        manual_temp_at: number;
        manual_temp_at_date: Date;
      }

      $scope.last_temp = ($scope.ngDialogData.device?.root_device || $scope.ngDialogData.device)?.last_values?.TEMP?.val_numeric || null;
      if ($scope.last_temp > 35 || $scope.last_temp < 10) {
        $scope.last_temp = 20;
      }

      $scope.formData = {
        start_at_mode: 'auto',
        start_at: $scope.ngDialogData.start_at || null,
        start_at_date: null,

        auto_detect_start: $scope.ngDialogData.auto_detect_start || true,

        end_at_mode: 'auto',
        end_at: $scope.ngDialogData.end_at || null,
        end_at_date: null,

        manual_temp_mode: 'keep',
        manual_temp: $scope.ngDialogData.manual_temp || $scope.last_temp || 20.0,
        manual_temp_at: $scope.ngDialogData.manual_temp_at || $scope.ngDialogData.end_at || $scope.nowMillis,
        manual_temp_at_date: null,

        // N,Xa,numax,Kad,Bmax,Ks,mixingcoeff

        // Ethanol reported.
      } as IFormData;

      // Pre-fill so datetimepicker's are pre-set

      $scope.formData.start_at_mode = $scope.formData.start_at ? 'manual' : 'auto';
      $scope.formData.start_at_date = $scope.formData.start_at ? new Date($scope.formData.start_at) : null;

      $scope.formData.end_at_mode = $scope.formData.end_at ? 'manual' : 'auto';
      $scope.formData.end_at_date = $scope.formData.end_at ? new Date($scope.formData.end_at) : null;

      $scope.formData.manual_temp_at_date = $scope.formData.manual_temp_at ? new Date($scope.formData.manual_temp_at) : null;

      $scope.initial_manual_temp_at = $scope.formData.manual_temp_at;

      $scope.isHelpCollapsed = {
        sensors: true,
        more: true
      };

      //            --  UI
      let isPristine = true;

      $scope.parametersAreValid = true;
      $scope.checkParametersAreValid = function () {
        if ($scope.formData.start_at && $scope.formData.start_at > $scope.nowMillis) {
          return false;
        }
        if ($scope.formData.start_at && $scope.formData.end_at && $scope.formData.start_at > $scope.formData.end_at) {
          $scope.parametersAreValid = false;
          return false;
        }

        // check that if they are defined, they are valid
        if ($scope.formData.manual_temp_mode == 'single') {
          if (!_.isFinite($scope.formData.manual_temp) || !$scope.formData.manual_temp_at) {
            $scope.parametersAreValid = false;
            return false;
          }
        } else if ($scope.formData.manual_temp_mode == 'multi') {
          if (_.size(temp_changes_chart?.series[0]?.data) < 3) {
            $scope.parametersAreValid = false;
            return false;
          }
          // Anything is valid?
        }
        $scope.parametersAreValid = true;
        return true;
      };


      //                                  --- DATETIMEPICKER
      $scope.dateTimeNow = function () {
        return new Date();
      };
      $scope.dateOptions = {
        startingDay: 1,
        showWeeks: false
      };

      //  --- Temp selection chart
      $scope.showGraph = function () {
        let one_day_millis = 24 * 60 * 60 * 1000;
        let min_time = Math.min($scope.formData.end_at || $scope.nowMillis, $scope.nowMillis);
        let max_time = min_time + 20 * one_day_millis;
        if ($scope.formData.manual_temp_mode == "multi" && temp_changes_chart) {
          if (temp_changes_chart.xAxis?.[0]?.min != min_time) {
            temp_changes_chart.series[0].data[0].x = min_time;
            temp_changes_chart.series[0].data[temp_changes_chart.series[0].data.length - 1].x = max_time + one_day_millis;
            temp_changes_chart.xAxis[0].setExtremes(min_time, max_time);
          }
          temp_changes_chart.redraw?.();
          return;
        } else {
          temp_changes_chart?.destroy?.();
        }


        let data_start_time = $scope.formData.manual_temp_at;
        if ($scope.formData.manual_temp_at == $scope.initial_manual_temp_at) {
          $scope.formData.manual_temp_at = min_time;
          $scope.initial_manual_temp_at = $scope.formData.manual_temp_at;
          data_start_time = min_time + 2 * one_day_millis;
        }
        // let pointCount = 3; // +2, first and last "fixed" points
        $scope.temp_changes = [{
          x: min_time, y: $scope.last_temp
          , custom: {noTooltip: true}, marker: {enabled: false, states: {hover: {enabled: false}}}
        },
          {x: data_start_time, y: $scope.formData.manual_temp},
          {x: data_start_time + 2 * one_day_millis, y: $scope.formData.manual_temp},
          {x: data_start_time + 4 * one_day_millis, y: $scope.formData.manual_temp},
          {
            x: max_time + one_day_millis, y: $scope.formData.manual_temp
            , custom: {noTooltip: true}, marker: {enabled: false, states: {hover: {enabled: false}}}
          },
        ] as Array<Highcharts.PointOptionsObject>;

        let dblclick_time = 0;
        let dblclick_coords = {x: 0, y: 0};
        let dbl_click_to_addPoint = function (_series: Highcharts.Series, timestamp: number, _coords: {
          x: number,
          y: number
        }, point_to_unselect = null) {
          if (!_series || !timestamp || !_coords || !_coords.x) {
            if (WG_debug) console.log("Invalid click", this);
            return;
          }
          if ($scope.temp_changes.length > 11) {
            console.error("Too many temperature changes");
            return;
          }

          if (timestamp - dblclick_time < 300
              && _coords.x == dblclick_coords.x
              && _coords.y == dblclick_coords.y) {
            if (WG_debug) console.warn("DBL Click");

            // if (!point_to_unselect)
            //   point_to_unselect = _.find(_series.points, {state: 'hover'});
            // if (point_to_unselect) {
            //   // We were hover'ing a point. Un-hover it
            //   let _removed_coords = {x: point_to_unselect.x, y: point_to_unselect.y};
            //   _series.removePoint(point_to_unselect.index, false); // Remove and re-add hovered point, so previous one is not the prefered target of a drag-drop
            //   _series.addPoint(_removed_coords, true, false, true);
            // }

            _series.addPoint(_coords, false, false, false);
            updateData(_series);

            let _point = _.find(_series.points, {x: _coords.x, y: _coords.y});
            if (_point) {
              if (_series.chart?.tooltip?.refresh)
                _series.chart.tooltip.refresh(_point); // Show tooltip on newly created point
            }
            return;
          } else {
            if (WG_debug) console.warn("DBL Click not yet");
          }
          dblclick_time = timestamp;
          dblclick_coords = _coords;
        };

        // Orders, snaps to grid, spaces, and update extraneous last point
        function updateData(series: Highcharts.Series) {

          // ## Info: series.userOptions.data === $scope.temp_changes
          // let _data = (series.userOptions as Highcharts.SeriesLineOptions).data as Array<Highcharts.PointOptionsObject>;

          sortByInPlace($scope.temp_changes, [0, 'x']);
          // Ensure extraneous line is updated after sorting
          $scope.temp_changes[$scope.temp_changes.length - 1].y = $scope.temp_changes[$scope.temp_changes.length - 2].y;

          // Snap to each 30min and space them
          let previous_time = 0;
          for (let _p of $scope.temp_changes as Highcharts.PointOptionsObject[]) {
            _p.x = Math.round(_p.x / (30 * 60 * 1000)) * (30 * 60 * 1000);
            if (previous_time > 0) {
              if (_p.x < previous_time + 30 * 60 * 1000) {
                _p.x = previous_time + 30 * 60 * 1000;
              }
              if (_p.x > max_time) {
                _p.x = max_time - 1;
              }
            }
            previous_time = _p.x;
          }
          series.setData($scope.temp_changes, true, true, false);
          // if (WG_debug) console.log("Data changed! ", _series.data, $scope.temp_changes);
          // return false; // So draggable-points.js doesn't try to update the original point
        }

        temp_changes_chart = Highcharts.chart({
          chart: {
            renderTo: 'HighchartPredictedTempDiv',
            displayErrors: !!WG_debug,
            events: {
              click: function (e) {
                if (WG_debug) console.warn("Chart click", this, e);
                // this == Highcharts.Chart
                // e.currentTarget == this
                // e.xAxis[0].value and e.yAxis[0].value are the clicked coordinates

                let _series = this.series?.[0];
                let _x = e['xAxis']?.[0]?.value;
                let _y = e['yAxis']?.[0]?.value;

                if (!_series || !e.timeStamp || _.isNil(_x) || _.isNil(_y)) {
                  return;
                }
                dbl_click_to_addPoint(_series, e.timeStamp, {x: _x, y: _y});
                return false; // prevent other actions of being performed
              },
              // contextmenu: function (e) {
              //   if (WG_debug) console.warn("Chart contextmenu", this, e);
              // },
              // dblclick: function (e) {
              //   if (WG_debug) console.warn("Chart dblclick", this, e);
              // },
              // mousedown: function (e) {
              //   if (WG_debug) console.warn("Chart mousedown", this, e);
              // },
            },
            animation: false,
            panning: {enabled: false},
          },
          credits: {enabled: false},
          boost: {enabled: false},
          accessibility: {enabled: false},
          legend: {enabled: false},

          title: {
            text: 'Set planned temperature changes'
          },

          plotOptions: {
            series: {
              events: {
                click: function (e) {
                  if (WG_debug) console.warn("Series click", this, e);
                  // this == HighchartsSeriesObject
                  // e.point.series === this
                  // e.chartX are the clicked coordinates in graph-area
                  // this.xAxis.toValue(e.chartX, false); to get data-coordinate

                  let _series = this;
                  let _x = e['chartX'] || e['offsetX'] || null;
                  let _y = e['chartY'] || e['offsetY'] || null;

                  if (!_series || !e.timeStamp || !this.xAxis || _.isNil(_x) || _.isNil(_y)) {
                    if (WG_debug) console.log("Invalid click", this);
                    return;
                  }
                  _x = this.xAxis.toValue(_x, false);
                  _y = this.yAxis.toValue(_y, false);

                  dbl_click_to_addPoint(this, e.timeStamp, {x: _x, y: _y}, e.point);
                  // return false;
                },
                // @ts-ignore
                contextmenu: function (e) {
                  if (WG_debug) console.warn("Series contextmenu", this, e);
                },
              },
              point: {
                cursor: 'ns-resize',
                events: {
                  drag: function (e) {
                    // if (WG_debug) console.warn("Point drag", e, _target.series.data[_target.index].y);
                    // this - Highcharts.Point, the previous point config!
                    // e.target - Highcharts.Point, the new point config
                    // e.x and e.y - are the new coordinates. Updating series.data after return;
                    // Returning false stops the drag and drops.

                    let _new_coords = e;
                    let _target = e.target;
                    // let _target: HighchartsPointObject = this;

                    if (!_target || _target.index === 0 || _target.index >= $scope.temp_changes.length - 1) {
                      if (WG_debug) console.error("Invalid target", _target);
                      return false;
                    }
                  },
                  drop: function (e) {
                    // this - Highcharts.Point, the previous point config!
                    // e.target - Highcharts.Point, with the new config
                    // e.x and e.y - the new coordinates, already on the e.target (saved by drag())
                    // Returning false stops the drag and drop.


                    let _point = e.newPoint?.point || e.target;
                    let _series = _point.series;
                    let _new_coords = {x: _point.x, y: _point.y};
                    // if (WG_debug) console.warn("Drop", _new_coords, _point);

                    if (!_point || _point.index === 0 || _point.index >= $scope.temp_changes.length - 1) {
                      if (WG_debug) console.error("Invalid target", _point);
                      return false;
                    }

                    // ## Info: _series.userOptions.data === $scope.temp_changes

                    // #### Ensure it's sorted.

                    // ## Method 1: Remove and re-add dragged point. This also changes $scope.temp_changes
                    // {
                    //   _point.remove(false, false);
                    //   _series.addPoint(_new_coords, true, false, true);
                    //   // Ensure extraneous line is updated after sorting
                    //   $scope.temp_changes[$scope.temp_changes.length - 1].y = $scope.temp_changes[$scope.temp_changes.length - 2].y;
                    //   if (WG_debug) console.log("Data changed! ", _series.data, $scope.temp_changes);
                    //   return false; // So draggable-points.js doesn't try to update the original point
                    // }

                    updateData(_series);
                    // ## Method 2: Get whole dataset and order everything
                    // {
                    //   // ## Info: _series.userOptions.data === $scope.temp_changes
                    //   // let _data = (_series.userOptions as Highcharts.SeriesLineOptions).data.slice() as Array<Highcharts.PointOptionsObject>;
                    //
                    //   sortByInPlace($scope.temp_changes, [0, 'x']);
                    //   // Ensure extraneous line is updated after sorting
                    //   $scope.temp_changes[$scope.temp_changes.length - 1].y = $scope.temp_changes[$scope.temp_changes.length - 2].y;
                    //
                    //   // Snap to each 30min and space them
                    //   let previous_time = 0;
                    //   for (let _p of $scope.temp_changes as Highcharts.PointOptionsObject[]) {
                    //     _p.x = Math.round(_p.x / (30 * 60 * 1000)) * (30 * 60 * 1000);
                    //     if (previous_time > 0) {
                    //       if (_p.x < previous_time + 30 * 60 * 1000) {
                    //         _p.x = previous_time + 30 * 60 * 1000;
                    //       }
                    //       if (_p.x > max_time) {
                    //         _p.x = max_time - 1;
                    //       }
                    //     }
                    //     previous_time = _p.x;
                    //   }
                    //   _series.setData($scope.temp_changes, true, true, false);
                    //   // if (WG_debug) console.log("Data changed! ", _series.data, $scope.temp_changes);
                    //   // return false; // So draggable-points.js doesn't try to update the original point
                    // }

                    // ## Method 3: Old mess
                    // {
                    //   // Sort them
                    //   if ((_point.x < _point.series.data[_point.index - 1].x)
                    //       || (_point.x > _point.series.data[_point.index + 1].x)) {
                    //     if (WG_debug) console.warn("Point dropped out of order. Sorting", e);
                    //     // let _point = _.clone(_target.series.data[_target_index]);
                    //     // let _series = _target.series;
                    //     // _target.remove();
                    //     // _target.series.addPoint([_target.x, _target.y], true);
                    //
                    //     // _target.series.data = _.sortBy(_target.series.data, 'x'); // Avoid. Creates a new array
                    //     _point.remove(false, true);
                    //     _series.addPoint(_new_coords, false, false, true);
                    //
                    //     // Ensure extraneous line is updated after sorting
                    //     _series.data[pointCount + 1].update({y: _point.series.data[pointCount].y}, true, true);
                    //   }
                    //   _series.data = _.sortBy(_series.data, 'x'); // Avoid. Creates a new array
                    //
                    //   // Ensure extraneous line is updated after sorting
                    //   _series.data[pointCount + 1].y = _series.data[pointCount].y;
                    // }


                    // Space the data at least 30min
                    // let previous_time = 0;
                    // for (let i = 1; i < pointCount + 1; i++) {
                    //   let _point = _series.data[i];
                    //   previous_time = _series.data[i - 1].x;
                    //
                    //   if (_point.x < previous_time + 2 * 60 * 60 * 1000) {
                    //     _point.x = previous_time + 2 * 60 * 60 * 1000;
                    //   }
                    //   if (_point.x > max_time)
                    //     _point.x = max_time - 1;
                    // }


                    if (WG_debug) console.log("Data changed! ", $scope.temp_changes);
                    return false;
                  },

                  // click: function (e) {
                  //   if (WG_debug) console.warn("Series Point click", this, e);
                  // },

                  // @ts-ignore
                  contextmenu: function (e) {
                    // this == e.point - Highcharts.Point, the clicked point
                    if (WG_debug) console.warn("Series Point contextmenu", e);
                    if ($scope.temp_changes.length <= 2 || !this.series.data) {
                      return;
                    }
                    let _point = e.point;
                    let _series = _point?.series;

                    let _target_index = _point?.index || 0;
                    if (!_point || !_series || !_target_index || _target_index >= $scope.temp_changes.length - 1) {
                      if (WG_debug) console.warn("Invalid target", e);
                      return false;
                    }
                    // if (WG_debug) console.error("Deleting point", this, e);
                    if (_point.remove) {
                      _point.remove();
                    } else if (_series.removePoint) {
                      _series.removePoint(_target_index);
                    } else {
                      if (WG_debug) console.warn("Invalid target", e);
                      return false;
                    }
                    updateData(_series);
                    e.preventDefault();
                    return false;
                  },
                }
              },

              allowPointSelect: false,
              step: 'left',
              showInNavigator: false,
              dragDrop: {
                draggableX: true,
                dragMinX: min_time,
                dragMaxX: max_time - 1,
                dragPrecisionX: 30 * 60 * 1000,

                draggableY: true,
                dragMinY: 10,
                dragMaxY: 34,
                dragPrecisionY: 0.1,

                dragSensitivity: 3
              },
              // stickyTracking: false,
              findNearestPointBy: 'xy',
              dataGrouping: {
                enabled: false,
              },
              marker: {enabled: true},
            },
            line: {
              // cursor: 'ns-resize',
              // events: {
              // click: function (e: any) {
              //   if (WG_debug) console.error("Line click", this, e);
              //   // this == HighchartsSeriesObject
              //   // e.point.series === this
              //   // e.chartX are the clicked coordinates in graph-area
              //   // this.xAxis.toValue(e.chartX, false); to get data-coordinate
              //
              //
              //   let _x = e.chartX || e.offsetX || null;
              //   let _y = e.chartY || e.offsetY || null;
              //   if (!this || !this.xAxis || !e.timeStamp || !_x) {
              //     if (WG_debug) console.log("Invalid click", this);
              //     return;
              //   }
              //   // if (WG_debug) console.error("line click event", this, e);
              //
              //   _x = this.xAxis.toValue(_x, false);
              //   _y = this.yAxis.toValue(_y, false);
              //
              //   dbl_click_to_addPoint(this, e.timeStamp, {x: _x, y: _y}, e.point);
              //   return false; // prevent nearest point from being selected
              // },
              // contextmenu: function (e) {
              //   if (WG_debug) console.error("Line contextmenu", this, e);
              //   return false;
              // },
              // },
            },
          },


          navigator: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          rangeSelector: {
            enabled: false
          },

          tooltip: {
            // pointFormat: "Set Temp to: <b>{point.y:.1f}</b><br/>",
            formatter: function () {
              // If tooltip is disabled
              if (this.point.options?.custom?.noTooltip) {
                return false;
              }

              return 'At <b>' + Highcharts.dateFormat('%Y-%m-%d %H:%M', this.point.x) +
                  '</b>, <br/>temperature will be: <b>' + Highcharts.numberFormat(this.point.y, 1) + '</b><br/>';
            }
          },

          xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
              day: '%y/%m/%d %H:%M'
            },
            tickInterval: 2 * 24 * 60 * 60 * 1000,
            min: min_time,
            max: max_time,

          },
          yAxis: {
            title: {
              text: 'Temp'
            },
            min: 10,
            max: 34,
            tickInterval: 5,
          },

          series: [{
            data: $scope.temp_changes,
            type: 'line',
          }],

        });
      }

      //                              --- ACTIONS : PROCESS // DELETE // CLOSE

      $scope.submit = function () {
        if (!$scope.checkParametersAreValid()) {
          return;
        }
        let _params = {
          start_at: null,
          auto_detect_start: true,
          end_at: null,
          manual_temperature: [],
        };

        _params.start_at = $scope.formData.start_at || null;

        _params.auto_detect_start = true; //_params.start_at ? false : true;

        _params.end_at = $scope.formData.end_at || null;

        if ($scope.formData.manual_temp_mode == 'single') {
          $scope.formData.manual_temp_at = $scope.formData.manual_temp_at || $scope.nowMillis;

          _params.manual_temperature = [[$scope.formData.manual_temp_at, $scope.formData.manual_temp]];
        } else if ($scope.formData.manual_temp_mode == 'multi') {
          // Don't send first and last temps, they are only for graphic'ing reasons.
          for (let i = 1; i < _.size(temp_changes_chart?.series[0]?.data) - 1; i++) {
            let _point = temp_changes_chart.series[0].data[i];
            _params.manual_temperature.push([_point.x, Math.round(_point.y * 10.0) / 10.0]);
          }
          if (WG_debug) console.log("Submitting: ", _params);
        }

        $scope.confirm(_params);
      }

      $scope.cancel = function (value) {
        parentRet.result = 'error';
        parentRet.loading = false;

        $scope.closeThisDialog();
      };

      $scope.close = function (value) {
        parentRet.loading = false;

        let return_result = {
          wasPristine: isPristine,
          isAdd: false,
          item_data: $scope.unitData
        };
        if (!isPristine) {
          return_result.isAdd = true;
          return_result.item_data.devices = [$scope.selected.device];
        }


        console.log("Close this dialog", return_result);

        if (parentRet.result === 'success' && $scope.confirm)
          $scope.confirm(return_result);
        else if ($scope.closeThisDialog)
          $scope.closeThisDialog(return_result);
        else
          $scope.close(0);
      };


      //-------------------------------------------- INIT------------------------------------------//

      function init() {

        $scope.changedInputs = {};

        // $scope.coms={process:null,device:null};
        //      								 ---     ngDialog?
        $scope.inputreadonly = true;
        $scope.disabledinput = false;
        $scope.origin = "manage";


      }

      $scope.spitData = function () {
        console.log("Spit. $scope:", $scope);
      };

      init();

    }])
  ;
}