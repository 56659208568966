/**
 * Created by silva on 15-09-2016.
 */
namespace wg {

  interface IScope extends ng.IScope {
    list_items: ILicense[];
    selection: IMultiSelect<ILicense>;
  }

  App.controller("TableLicensesController",
      ['$rootScope', '$scope', '$filter', '$http', '$timeout', '$state', '$stateParams', '$translate', 'ngDialog', 'ngTableParams', '$document', '$interval', 'AuthService', 'Data', '$window', 'moment',
        function ($rootScope: IRootScope, $scope: IScope, $filter: ng.IFilterService, $http: ng.IHttpService, $timeout: ng.ITimeoutService, $state: _IState, $stateParams, $translate: ng.translate.ITranslateService, ngDialog: angular.dialog.IDialogService, ngTableParams: NgTable.ITableParamsConstructor<ILicense>, $document, $interval, AuthService: IAuthService, Data, $window, moment: any) {


          //
          //                                      ---     VARIABLES

          // @ts-ignore
          let entity_type = $state.current.url.slice?.(1);
          $scope.loading = false;

          $scope.nowDate = new Date();
          $scope.nowTime = $scope.nowDate.getTime();

          //                  --- SELECTED
          $scope.search = {
            all: '',
            do_search: function () {
              console.log("Search:", this.all);
              $scope.tableParams.reload();
            },
          };
          $scope.showingFrom = 0;
          $scope.showingTo = 0;

          // Removes dangerous/private information of object or array
          // $scope.censor = function (licenses: ILicense[]) {
          //   let ret = [];
          //   if (!_.isArray(licenses)) licenses = [licenses];
          //
          //   _.forEach(licenses, (_license) => {
          //     let _cens: ILicense = _.pick(_license, ['activation_at', 'last_payment_at', 'last_payment_value', 'license_expiration_date',
          //       'device.model', 'device.id', 'device.uuid', 'device.sn', 'device.owner.username',
          //       'distributor.id', 'distributor.username', 'distributor.full_name']);
          //     // if (_cens.device?.owner?.username)
          //     //   _cens.device.owner = _cens.device.owner.username;
          //     ret.push(_cens);
          //   })
          //
          //   if (_.isArray(ret) && _.size(ret) == 1)
          //     return ret[0];
          //   else
          //     return ret;
          // }
          // $scope.sendMail = function (email, subject, message) {
          //   $window.open("mailto:" + email + "?subject=" + subject + "&body=" + message, "_blank");
          // };
          //
          // $scope.requestInvoice = {
          //   emailTarget: 'internal@winegrid.com',
          //   request: function (licenses: any) {
          //     let _info = $scope.censor(licenses);
          //     let _subject;
          //     if (_.isArray(_info))
          //       _subject = "License renewal request for " + _.size(_info) + " devices";
          //     else
          //       _subject = "License renewal request for device " + (_info.device?.sn || _info.device?.id);
          //
          //     let _body = ("License info:  %0D%0A" + JSON.stringify(_info, undefined, 2)).replace(/\n/g, '%0D%0A');
          //     $scope.sendMail(this.emailTarget, _subject, _body);
          //   }
          // };

          /**
           * Grab the selected licenses, and output something like:
           *
           * Client: Sogrape Anadia
           * License period: 2023/11/03 to 2025/11/03
           *
           * Model: WP1100 x7 | Total: 2400 € | Resale: 2000 €
           * SN: 798e3d | d71079 | 2fa5e9 | 1ec33b | dcdd4b | a13fcb | 1aabfd
           *
           * Model: e-charmat x3 | Total: 300 € | Resale: 210 €
           * SN: 6924d6 | cd075b | cc3bb1
           */
          $scope.showSelectedLicensesInfo = () => {
            let _info = '';
            let _ownersGrouped = _.groupBy($scope.selection.items, 'device.owner.username')

            _.forEach(_ownersGrouped, (licenses, owner) => {
              if (_info.length > 0)
                _info += '\n\n';
              _info += `Client: ${owner} - ${licenses[0].device.owner.email} \n`;

              let _min_license_start_date = _.minBy(licenses, 'license_start_date')?.license_start_date;
              let _max_license_start_date = _.maxBy(licenses, 'license_start_date')?.license_start_date;
              let _min_license_expiration_date = _.minBy(licenses, 'license_expiration_date')?.license_expiration_date;
              let _max_license_expiration_date = _.maxBy(licenses, 'license_expiration_date')?.license_expiration_date;

              // if _min_license_start_date is more than 1 month away than _max_license_start_date, then show both
              if (moment(_max_license_start_date).diff(moment(_min_license_start_date), 'months') > 1)
                _info += `License period: (${moment(_min_license_start_date).format('YYYY/MM/DD')} - ${moment(_max_license_start_date).format('YYYY/MM/DD')})`;
              else
                _info += `License period: ${moment(_min_license_start_date).format('YYYY/MM/DD')}`;

              if (moment(_max_license_expiration_date).diff(moment(_min_license_expiration_date), 'months') > 1)
                _info += ` to (${moment(_min_license_expiration_date).format('YYYY/MM/DD')} - ${moment(_max_license_expiration_date).format('YYYY/MM/DD')})`;
              else
                _info += ` to ${moment(_max_license_expiration_date).format('YYYY/MM/DD')}`;
              _info += '\n\n';

              _.forEach(_.groupBy(licenses, 'device.model'), (licenses, model) => {
                _info += `Model: ${model} x${licenses.length}`;

                const _sum_client_license_value = _.sumBy(licenses, 'client_license_value');
                if (_sum_client_license_value > 0)
                  _info += ` | Total: ${_sum_client_license_value} €`;
                const _sum_resale_license_value = _.sumBy(licenses, 'resale_license_value');
                if (_sum_resale_license_value > 0)
                  _info += ` | Resale: ${_sum_resale_license_value} €`;
                _info += '\n';

                _info += `SN: ${_.map(licenses, 'device.sn').join(' | ')}\n\n`;
              })
            })

            // show a modal with a text field with the info

            ngDialog.open({
              template: 'app/views/modals/message.html',
              data: {
                title: "Licenses Info:",
                textarea: _info,
              }
            })
          }


          //  ---  Multi actions
          $scope.batchActions = {
            // TODO: Used to show batchActions feedback on the table
            // List of actions available in the dropdown button
            actions: [
              {
                label: 'app.licenses.button.REQUEST_INVOICE',
                action: () => {
                  $scope.renewLicense($scope.selection.items);
                }
              },
              {
                label: 'app.common.EDIT',
                action: () => {
                  $scope.editLicense($scope.selection.items);
                }
              },
              {
                label: 'Export Info',
                action: () => {
                  $scope.showSelectedLicensesInfo($scope.selection.items);
                }
              },
              {
                label: 'Delete',
                accessLevel: 'dev',
                action: () => {
                  $scope.deleteLicenses($scope.selection.items);
                }
              },
            ],
          };

          //  ---  Multi selection
          $scope.selection = {
            items: [],
            all_items_are_selected: false,
            last_item_selected: null,
            // Without using shift
            last_single_item_selected: null,
            reselectFromList: function () {
              // if (WG_debug) console.log("selection reselectFromList");
              // get list of .id from selection.items using map
              let _selected_ids = _.map($scope.selection.items, 'id');

              _.forEach($scope.list_items, function (item) {
                if (_.includes(_selected_ids, item.id)) {
                  item.selected = true;
                } else {
                  item.selected = false;
                }
              });
              $scope.selection.updateSelectedList();
            },
            updateSelectedList: function () {
              // if (WG_debug) console.log("selection ng-change");
              $scope.selection.items = emptyOrCreateArray($scope.selection.items);
              let _tmp_all_selected = true;
              let _at_least_one_visible = false;
              // Add all visible+selected items to the arrays


              _.forEach($scope.list_items, function (item) {
                    if (_.isNil(item['is_visible']) || item['is_visible'] == true) {
                      _at_least_one_visible = true;
                      if (item.selected) {
                        $scope.selection.items.push(item);
                      } else {
                        _tmp_all_selected = false;
                      }
                    }
                  }
              );

              // Are all of them selected?
              $scope.selection.all_items_are_selected = _at_least_one_visible && _tmp_all_selected;

              // console.log("Refreshed list of selected items", $scope.selection.items);
            },
            selectAll: function (value = null) {
              // console.log("Setting all items to " + ($scope.selection.all_items_are_selected ? "Selected" : "Unselected"));
              if (value === true)
                $scope.selection.all_items_are_selected = true;
              else if (value === false)
                $scope.selection.all_items_are_selected = false;

              if (!_.isEmpty($scope.list_items)) {
                for (let item of $scope.list_items) {
                  item.selected = !!$scope.selection.all_items_are_selected;
                }
              }

              $scope.selection.updateSelectedList();
            },
            // Supporting Shift-select multiple items
            select: function (item, index: number, e: JQueryEventObject) {
              if (e?.shiftKey) {
                let _this_index = _.findIndex($scope.list_items, {id: item.id});
                let _last_item_selected_index = _.findIndex($scope.list_items, {id: $scope.selection.last_item_selected?.id});
                let _last_single_index = _.findIndex($scope.list_items, {id: $scope.selection.last_single_item_selected?.id});
                if (_last_single_index == -1)
                  _last_single_index = 0;

                // if (WG_debug) console.log("selection multi-selection, from", _last_single_index, "to", _this_index);
                for (let i = 0; i < _.size($scope.list_items); i++) {
                  if (i >= Math.min(_last_single_index, _this_index) && i <= Math.max(_last_single_index, _this_index)) {
                    $scope.list_items[i].selected = $scope.selection.last_single_item_selected ? $scope.selection.last_single_item_selected.selected : true;
                    // if (WG_debug) console.log("selection Selecting ", i);
                  } else if (_last_item_selected_index > -1 && i >= Math.min(_last_item_selected_index, _this_index) && i <= Math.max(_last_item_selected_index, _this_index)) {
                    $scope.list_items[i].selected = false;
                    // if (WG_debug) console.log("selection UnSelecting ", i);
                  }
                }

              } else {
                $scope.selection.last_single_item_selected = item;
              }
              $scope.selection.last_item_selected = item;
              $scope.selection.updateSelectedList();
            },
          };


          $scope.tableParams = new ngTableParams({
            page: 1,            // show first page
            count: 25,          // count per page
            sorting: {
              created_at: 'des'   // initial sorting
            },
            filter: {}
          }, {
            total: 0, // length of data
            paginationMaxBlocks: 7, // 7 = first, 3 centered, last
            paginationMinBlocks: 3,
            getData: (params) => {
              $scope.loading = true;
              console.log("Get-TD list_items.");
              let search = $scope.search.all;
              let filter = '';
              let obj = params.filter();
              for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                  if (angular.isDefined(obj[key]) && obj[key] !== '')
                    filter += key + '=' + obj[key] + '&';
                }
              }
              let query = '?page_size=' + params.count();

              if (params.orderBy() + '' !== '') {
                query += '&ordering=' + params.orderBy();
              }
              if (angular.isDefined(search) && search !== '') {
                query += '&search=' + search;
              } else {
                query += '&page=' + params.page();
              }
              if (filter !== '') {
                filter = filter.substring(0, filter.length - 1);
                query += '&' + filter;
              }

              // sample_promise
              return $http.get<IDataResponse<ILicense>>('api/dashboard/distributors/license/' + query, {}).then(
                  (response) => {
                    console.log("Got-TD licenses. response:", response);
                    $scope.list_items = _.uniqBy(response.data.results, 'id');

                    if ($scope.list_items.length < response.data.results.length
                        && WG_debug) {
                      console.warn("Duplicates found in the response. Please check the API response.");
                    }

                    _.forEach($scope.list_items, function (item) {
                      if (item.license_expiration_date != null) item.license_expiration_date = new Date(item.license_expiration_date);
                      if (item.license_start_date != null) item.license_start_date = new Date(item.license_start_date);
                      if (item.resale_invoice_date != null) item.resale_invoice_date = new Date(item.resale_invoice_date);
                      if (item.client_invoice_date != null) item.client_invoice_date = new Date(item.client_invoice_date);
                    });

                    $scope.selection.reselectFromList();

                    $scope.count_TOTAL = $scope.list_items.length;
                    $scope.count_ON = 0;
                    $scope.count_OFF = 0;
                    angular.forEach($scope.list_items, function (license) {
                      if (license.license_expiration_date && license.license_expiration_date < $scope.nowTime) {
                        license.expired = true;
                        $scope.count_OFF++
                      } else {
                        license.expired = false;
                        $scope.count_ON++
                      }
                      license.renewable = !_.includes(license.device?.model, "smartbox");
                    });

                    // update table params
                    $scope.updateTotals(response.data.count);
                    $scope.loading = false;
                    return $scope.list_items;
                  }, function (reason) {
                    $scope.loading = false;
                    console.error(reason);
                  });
            }
          });

          //                              --- FUNCTIONS
          $scope.updateTotals = function (total) {
            $scope.tableParams.total(total);
            $scope.showingFrom = ($scope.tableParams.page() - 1) * $scope.tableParams.count() + 1;
            if ($scope.showingFrom > $scope.list_items.length)
              $scope.showingFrom = $scope.list_items.length;
            $scope.showingTo = $scope.showingFrom - 1 + $scope.tableParams.count();
            if ($scope.showingTo > $scope.list_items.length)
              $scope.showingTo = $scope.list_items.length;
            if ($scope.showingTo > $scope.tableParams.total())
              $scope.showingTo = $scope.tableParams.total();

            let _counts: number[] = [10];
            for (let threshold of [25, 50, 100, 200, 500]) {
              if (total > _counts[_counts.length - 1]) {
                _counts.push(threshold);
              } else {
                break;
              }
            }
            $scope.tableParams.settings({counts: _counts});
          };

          $scope.reload = function (force) {
            $state.reload();
          };


          $scope.$on('adminReload', function (event, args) {
            console.log('adminReload', 'admin-elements');
            $scope.tableParams.reload();
          });

          // watch if table is loading,
          // if so only show loading animations
          // if it takes more than 1000 ms
          var promiseTimeoutLoading: angular.IPromise<any>;
          $scope.$watch('tableParams.settings().$loading', function (value) {
            // console.log("$watch('tableParams.settings().$loading'", $scope);
            $timeout.cancel(promiseTimeoutLoading);
            if (value) {
              promiseTimeoutLoading = $timeout(function () {
                $scope.loading = true;
              }, 1000);
            } else {
              $scope.loading = false;
            }
          });

          //  -DEV
          $scope.spitData = function () {
            console.log("spitData. $scope", $scope, "selected=", $scope.see.is());
          };


          $scope.deleteLicenses = function (licenses: ILicense[]) {
            ngDialog.openConfirm({
              template: 'app/views/modals/confirm.html',
              data: {
                title: "Delete License",
                text: "Are you sure you want to delete the selected " + _.size(licenses) + " licenses?",
                ok: "Delete",
                cancel: "Cancel"
              }
            }).then(() => {
              console.log('Deleting Licenses', licenses);

              this.submitted = true;
              this.loading = true;

              Promise.all(
                  licenses.map((_license) => {
                    return this.$http.delete('api/dashboard/distributors/license/' + _license.id)
                  }))
                  .then((response) => {
                    if (response.every((res) => res.status === 201 || res.status === 200)) {
                      this.$scope.$apply(() => {
                        this.success = true;
                        this.loading = false;
                      });

                      setTimeout(() => {
                        this.ngDialog.close();
                      }, 1000);
                    } else {
                      this.requestError = "app.error.UNEXPECTED_ERROR";
                    }
                    this.success = true;
                  })
                  .catch((error) => {
                    this.loading = false;
                    console.log("Failed to delete Licenses", error);
                    this.requestError = error.data;
                  })

            }).finally(() => {
              $scope.tableParams.reload()
            })
          }


          $scope.renewLicense = function (licenses: ILicense[]) {

            //by default the new license will be from the expiration date of the previous license to a year from that
            const newLicenseData = licenses.map((license) => {
              return {
                device: license.device,
                distributor: license.distributor,
                client_manager: license.client_manager,
                license_start_date: new Date(license.license_expiration_date || Date.now()),
                license_expiration_date: moment(new Date(license.license_expiration_date || Date.now())).add(1, 'years')?.toDate() || null,
              }
            })


            $scope.showSubscriptionModal(newLicenseData, ModalBehaviour.RENEW);
          }

          $scope.editLicense = function (licenses: ILicense[]) {

            $scope.showSubscriptionModal(licenses, ModalBehaviour.EDIT)
          }


          $scope.showSubscriptionModal = function (licenses: ILicense[], behaviour: ModalBehaviour) {

            if (!Array.isArray(licenses)) licenses = [licenses];

            //sometimes data comes null from the server
            licenses.forEach((license) => {
              if (license.distributor == null) license.distributor = {} as IDistributor;
              if (license.client_manager == null) license.client_manager = {} as IUser;
            });

            // //deep copy licenses to a new array
            // let newLicenseData = JSON.parse(JSON.stringify(licenses));


            ngDialog.openConfirm({
              template: '<license-subscription-modal new-license-data="newLicenseData" behaviour="behaviour" ></license-subscription-modal>',
              plain: true,
              className: 'ngdialog-theme-default',
              controller: ['$scope', function ($scope) {
                $scope.behaviour = behaviour;
                $scope.newLicenseData = licenses;
              }]
            }).then((data) => {
              console.log('License saved', data);
            }, (reason) => {
              console.log('License save Failed. Reason: ', reason);
            }).finally(() => {
              $scope.tableParams.reload()
            })
          }
        }
      ]);
}