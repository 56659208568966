namespace wg {
  export class NotificationsService {
    static $inject = ['$rootScope', '$http'];

    constructor(private $rootScope: ng.IRootScopeService,
                private $http: ng.IHttpService) {
      if (WG_debug) console.log("NotificationsService constructor");
      this.$rootScope.last_notifications = this.last_notifications;
      this.$rootScope.markMessageAs = this.markMessageAs;
      this.last_notifications.refresh_page = this.changeNotificationsPage;
    }

    public last_notifications: IRootScope['last_notifications'] = {
      list: [], // .id, .read, .published_device.[id|uuid|etc]
      // , .notification.title, .notification.message, .notification.timestamp
      loading: false,
      currentPage: [],
      unread: 0,
      total: 0,
      refresh_page: null,
      settings: {
        page_size: 10,
        page: 1,
        pages_total: 0,
      },
    };

    public loadNotificationPage_timer = null;

    markMessageAs = (notification, read, also_refresh_page = false) => {
      // update_afterwards = (update_afterwards === undefined) ? true : update_afterwards;

      let _notification_id;
      if (angular.isNumber(notification.id)) {
        _notification_id = notification.id;
      } else if (angular.isNumber(notification)) {
        _notification_id = notification;
      } else if (Array.isArray(notification)) {
        _.forEach(notification, (not) => {
          this.markMessageAs(not, read, also_refresh_page);
        });
        return;
      } else {
        console.log("Mark as Read Failed! Missing notification ID");
        return;
      }

      if (angular.isDefined(notification.read)) {
        notification.read = !!read;
        this.last_notifications.unread--;
      }
      this.$http.patch<INotification>('api/dashboard/notification/user/' + _notification_id + '/', {read: !!read}).then((response) => {
        // Get notification object from function-arg or root-list
        let _root_notification = notification;
        if (!_root_notification || !angular.isDefined(_root_notification.read)) {
          _root_notification = this.last_notifications.list.filter((item) => {
            return (item.id && item.id === response.data.id);
          });
        }
        if (_root_notification && angular.isDefined(_root_notification.read)) {
          notification.read = !!response.data.read;
        }
        if (read) {
          _.remove(this.last_notifications.list, {id: response.data.id});
          _.remove(this.last_notifications.currentPage, {id: response.data.id});
        }

      }, (response) => {
        console.log("Failed to update notifications");
      }).finally(() => {
          // Fire-once timer to load notifications list once after an edit
          clearTimeout(this.loadNotificationPage_timer)
          this.loadNotificationPage_timer = setTimeout(() => {
          if (also_refresh_page) {
            this.loadNotificationPage();
          }
            this.loadUnreadNotifications();
          }, 1500);
      });
    }

    // Loads the notifications for the current page to show on the Notifications-view
    public loadNotificationPage = () => {
      // if (AuthService.viewing_as_other_owner()) {
      //   if (WG_debug) console.info("Not getting notifications' page as another user");
      //   return;
      // }

      this.last_notifications.loading = true;
      let query = '?ordering=+read,-notification__timestamp';
      query += '&page=' + this.last_notifications.settings.page;
      query += '&page_size=' + this.last_notifications.settings.page_size;

      this.$http.get<IDataResponse<INotification>>('api/dashboard/notification/user/' + query, {}).then((response) => {
        this.last_notifications.currentPage = emptyOrCreateArray(this.last_notifications.currentPage);
        this.last_notifications.total = response.data.count;
        this.last_notifications.settings.pages_total = Math.ceil(response.data.count / this.last_notifications.settings.page_size);
        _.forEach(response.data.results, (_notif) => {
          // _notif.notification.title = parseData(_notif.notification.title);
          // _notif.notification.message = parseData(_notif.notification.message);
          // val.notification.timestamp = new Date(_notif.notification.timestamp).toLocaleString();
          // val.notification.timestamp = new Date(_notif.notification.timestamp);
          this.last_notifications.currentPage.push(_notif);

        });
      }, (response) => {
        console.log("Failed to load notifications");
      }).finally(() => {
        this.last_notifications.loading = false;
      });
    };

    public changeNotificationsPage = () => {
      // User changed page on the Notifications view
      if (WG_debug) console.log("NotificationsController: Change page");
      this.loadNotificationPage();
      $("body").animate({scrollTop: 70}, "slow");
    }

    // Get the latest 99 unread user's notifications
    public loadUnreadNotifications = () => {
      let query = '?ordering=-notification__timestamp';
      query += '&read=False';
      query += '&page=1';
      query += '&page_size=99';
      this.$http.get<IDataResponse<INotification>>('api/dashboard/notification/user/?read=False&ordering=-notification__timestamp&page_size=60', {}).then((response) => {
        let _notifications_list = [];
        this.last_notifications.unread = response.data.count;
        _.forEach(response.data.results, (_notif) => { // Only page_size are returned and processed here
          // _notif.notification.title = parseData(_notif.notification.title);
          // _notif.notification.message = parseData(_notif.notification.message);
          // _notif.timestamp = _notif.notification.timestamp; // Compatibility reasons
          // val.notification.timestamp = new Date(val.notification.timestamp).toLocaleString();
          // val.notification.timestamp = new Date(val.notification.timestamp);
          _notifications_list.push(_notif);
        });
        this.last_notifications.list = _.orderBy(_notifications_list, 'timestamp', 'desc');

        if (WG_debug) console.log('Processing notification: ', this.last_notifications.list);

        //console.log(this.last_notifications);
      }, (response) => {
        console.log("Failed to load notifications");
      });
    };

    public updateAllNotifications = () => {
      if (WG_debug) console.log("Checking for Notifications");
      this.loadUnreadNotifications();
      // this.loadNotificationPage();
    };

  }

  App.service('NotificationsService', NotificationsService);

  export class NotificationsController {
    static $inject = ['$scope', '$rootScope', 'NotificationsService'];

    constructor(private $scope: ng.IScope,
                private $rootScope: ng.IRootScopeService,
                private NotificationsService: NotificationsService) {
      if (WG_debug) console.log("NotificationsController, constructor");
    }

    public $onInit = () => {
      if (WG_debug) console.log("NotificationsController, OnInit");
      this.NotificationsService.changeNotificationsPage();
    }
  }

  App.controller('NotificationsController', NotificationsController);
}



