namespace wg {

  export class WgTaskDetails {

    public previous_task_data: ITask;
    public task: ITask;

    public protocol: IWinemakingProtocol;

    public static $inject = ['$scope', 'ngDialog', '$http', 'WGApiData', '$translate'];
    public TASK_STATUS_TYPES = wg.TASK_STATUS_TYPES;
    public TASK_TYPES = wg.TASK_TYPES;
    public setLoadingOfParent: (loading: boolean) => void;
    public suggested_quantity: string = null;
    public loading: boolean = true;

    public constructor(private $scope: any, private ngDialog: any, private $http: ng.IHttpService, private WGApiData: WGApiData, private $translate: ng.translate.ITranslateService) {
      if (WG_debug) console.log('WgTaskDetails constructor');

      this.previous_task_data = _.cloneDeep(this.$scope.ngDialogData.task);

      this.task = _.clone(this.$scope.ngDialogData.task);
      this.protocol = _.clone(this.$scope.ngDialogData.protocol);
      this.setLoadingOfParent = this.$scope.ngDialogData.setLoading;

      this.suggested_quantity = this.getSuggestedQuantity();
      this.loading = false;
    }

    public disableSaveButton = false;

    public getSaveButtonName(): string {
      this.disableSaveButton = false;
      if (!this.previous_task_data.ignored && this.task.ignored) {
        return this.$translate.instant("app.winemaking.task.IGNORE_THIS_TASK");
      }
      if (this.previous_task_data.ignored && this.task.ignored) {
        return this.$translate.instant("app.common.SAVE");
      }
      if (this.previous_task_data.completed_at && !this.task.completed_at) {
        return this.$translate.instant("app.winemaking.task.MARK_AS_UNDONE");
      }
      if (!this.previous_task_data.completed_at && this.task.completed_at) {
        this.disableSaveButton = true;
        return this.$translate.instant("app.common.SAVE");
      }
      if (this.previous_task_data.ignored && !this.task.ignored) {
        this.disableSaveButton = true;
        return this.$translate.instant("app.common.SAVE");
      }

      return this.$translate.instant("app.common.SAVE");
    }

    /**
     * Marks the current task as done by setting its completion date.
     * If the task is already marked as done, no changes will be made.
     *
     * @return {Promise} A promise that resolves to the updated task object after saving.
     */
    public markAsDone() {
      if (!this.task.completed_at) {
        this.task.completed_at = new Date();
      }
      return this.save();
    }

    public save() {
      if (WG_debug) console.log('WgTaskDetails save', this.task);

      // If we changed the task, save the changes and estimate the new status
      let status: number = null;
      if (this.task.ignored) { // Ignored tasks are always "completed"
        status = this.TASK_STATUS_TYPES.completed.id;
        if (!this.task.completed_at) { // Save ignored date
          this.task.completed_at = new Date();
        }
      } else if (!this.task.completed_at && this.task.status == this.TASK_STATUS_TYPES.completed.id) { // Unsetting the date, mark as undone
        status = this.TASK_STATUS_TYPES.overdue.id;
      } else if (this.task.completed_at) { // Marking completed
        status = this.TASK_STATUS_TYPES.completed.id;
      } else { // Overdue
        if (WG_debug) console.info("Just saving, don't change status", this.task);
      }

      let data = {
        user_notes: this.task.user_notes,
        completed_at: this.task.completed_at,
        ignored: !!this.task.ignored,
      }

      if (status !== null) {
        data['status'] = status;
      }

      this.$http.patch(`api/dashboard/winemaking/task/${this.task.id}/`, data).then((response) => {
        console.log('Task saved', response.data);
        this.$scope.confirm(response.data);
      }).catch((error) => {
        this.$scope.confirm({error: error});
      }).finally(() => {
        this.WGApiData.WGUnits.changed = true;
        this.WGApiData.update_changed_data();
        this.setLoadingOfParent(false);
        this.loading = false;
      })
    }

    public getSuggestedQuantity(): string {
      if (this.task.type_id !== this.TASK_TYPES.add_product.id)
        return null;

      if (!this.protocol)
        return null;

      let initial_volume = this.protocol.initial_volume; //in L
      let initial_weight = this.protocol.initial_weight; //in KG

      if (!initial_volume && !initial_weight) {
        return null;
      }

      let dosage = this.task.quantity_unit.split('/');
      dosage[0] = dosage[0].trim();
      dosage[1] = dosage[1].toLowerCase().trim();

      // if we don't support the dosage[1], return null
      if (dosage[1] !== 'kg' && dosage[1] !== 'g' &&
          dosage[1] !== 'l' && dosage[1] !== 'hl') {
        return null;
      }

      // 20 g/g
      // 0.020 kg/g
      // 20000 g/kg

      // 20 g/L
      // 2000 g/hl

      let quantity = parseFloat(this.task.quantity);
      let quantity_si = quantity; //in kg or l
      if (dosage[1] === 'g') {
        quantity_si = quantity * 1000.0;
      } else if (dosage[1] === 'hl') {
        quantity_si = quantity / 100.0;
      }

      //dosage is described by mass
      if (dosage[1] === 'kg' || dosage[1] === 'g') {
        // Add (= 2550 L * 20 g/L)
        return (quantity_si * initial_weight).toString() + ' ' + dosage[0] + ' (=' + initial_weight + " kg * " + this.task.quantity + " " + this.task.quantity_unit + ")";
      }
      //dosage is described by volume
      if (dosage[1] === 'l' || dosage[1] === 'hl') {
        return (quantity_si * initial_volume).toString() + ' ' + dosage[0] + ' (=' + initial_volume + " L * " + this.task.quantity + " " + this.task.quantity_unit + ")";
      }

      return null;
    }

  }

  App.component('wgTaskDetails', {
    templateUrl: 'app/views/partials/wg-task-details.html',
    controller: WgTaskDetails as any,
    controllerAs: 'ctrl',
    bindings: {
      selectedPlace: '=',
    }
  })

}