namespace wg {
  App.directive('wgEqualHeight', ['$timeout', function ($timeout) {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        $timeout(function() {
          // Use the selector provided in the directive attribute
          var selector = attrs.wgEqualHeight;
          var taskItems = document.querySelectorAll(selector);
          var maxHeight = 0;

          // Calculate the maximum height
          angular.forEach(taskItems, function(taskItem) {
            var height = taskItem.offsetHeight;
            if (height > maxHeight) {
              maxHeight = height;
            }
          });

          // Set all task items to the maximum height
          angular.forEach(taskItems, function(taskItem) {
            taskItem.style.height = maxHeight + 'px';
          });
        }, 0); // Ensure this runs after the DOM is fully loaded
      }
    };
  }]);
}