namespace wg {
  interface IScope extends ng.IScope {
    selectSmartBox: (item: Partial<INestable['item']>, device?: IDevice) => void,
    monitorSmartBox: (item?: Partial<INestable['item']>, device?: IDevice) => void,
    pingSmartBox: (device: IDevice) => void,
    get_status_pair: (device: IDevice) => void,
    selectedUnitPing: {
      loading: boolean,
      result: string,
      uuid: string,
      on_click: IScope['pingSmartBox'],
      text: string,
      icon: string
    },

    selectedUnit: IDevice,
    smartboxes_uuid: { [uuid: string]: IDevice },
    virtual_smartboxes_uuid: { [uuid: string]: IDevice },
    nest_items: INestable[],
    nest_items_orig: INestable[],
    nest_items_flat: INestable[],

    pairing_wizard: {
      device_type: string,
      ser_nums: {
        value: string,
        id: number,
        val: 0 | 1 | 2
      }[],
      connection_type: "client" | "smartbox",
      method: 'manual' | 'auto',

      [any: string]: any,
    },

    pairing_methods_enabled: {
      MANUAL: boolean,
      AUTO: boolean,
    },

    device_types: {
      [key: string]: {
        name: string,
        type: string,
        has_wifi: boolean,
        has_button?: boolean,
        message?: string,
      },
    },

    alerts: {
      icon: string,
      type: string,
      msg_translate: string,
    }[]
  }

  App.controller("DevicesPairingController", ['$rootScope', '$scope', '$element', '$filter', '$http', '$timeout', '$translate', '$state', '$stateParams', 'AuthService', 'WGApiData',
    function ($rootScope: IRootScope, $scope: IScope, $element, $filter: ng.IFilterService, $http: ng.IHttpService, $timeout: ng.ITimeoutService, $translate: ng.translate.ITranslateService, $state: _IState, $stateParams, AuthService: IAuthService, WGApiData: WGApiData) {
      $scope.reload = function () {
        // $state.go($state.current.name, $stateParams, {reload: 'app.devices.pairing'});
        $state.go($state.current.name, $stateParams, {reload: true});
      };

      var last_item;

      $scope.pairing_methods = {
        MANUAL: 'manual',
        AUTO: 'auto'
      };
      $scope.pairing_methods_enabled = {
        MANUAL: false,
        AUTO: false
      };
      var SIX_WARN_TIMEOUT = 5000;

      var SMTB = {
        PING: 'PING',
        PONG: 'PONG',
        PAIRING: 'PAIRING',
        // AP_STA: 'AP/STA',
        // AP_WPS: 'AP/WPS',
        // AP_WPS_SET: 'AP/WPS_SET',
        AP_WPS_ENROLLEE_SEEN: 'AP/WPS_ENROLLEE_SEEN', // New device
        AP_WPS_REG_SUCCESS: 'AP/WPS_REG_SUCCESS', // Already known
      };

      // var STATE_ON_TIME_WINDOW = 600000;
      let PING_TIMEOUT = 12 * 1000;
      // let PING_TIMEOUT = DataUtils.PING_TIMEOUT;
      let SET_STATE_TIMEOUT = 20 * 1000;
      let PAIRING_TIMEOUT = 31 * 60 * 1000; //31 minutes timeout pairing

      $scope.timeoutPairingMsg = null;


      // Put smartbox on pairing mode
      let pairing_set = function (_device: Partial<IDevice>, state: string, data: object, onSuccess: (result: string) => void, onFail: (result: string) => void) {
        let device = _device;
        if (device.id) {
          device = $rootScope.WGDevices.devices_id[_device.id];
        } else if (device.uuid) {
          device = $rootScope.WGDevices.devices_uuid[_device.uuid];
        }
        console.log('Pairing set state, ', state, device.sn);
        let value = _.assign({state: state}, data);
        let payload = {
          iid: device.iid,
          value: value,
          timestamp: new Date().getTime(),
        };
        var key = '?api_key=' + $rootScope.apiKey;
        var url = $rootScope.base_url + 'data/' + device.path + '/' + SMTB.PAIRING + key;
        // console.log(SMTB.PAIRING, 'url', url, 'payload', payload);
        $http.post(url, payload).then(
            function (response) {
              console.log('Pairing set state,', state, response, device);

              let timeoutPAIRING = $timeout(function () {
                console.warn('Pairing set state, Timedout!', state, device.sn);
                lkmWatchPAIRING();
                onFail('timeout');
              }, SET_STATE_TIMEOUT);

              let lkmWatchPAIRING = $rootScope.$watch('lastKnownMessages["' + device.uuid + '"]["' + SMTB.PAIRING + '"]',
                  function (newValue: IDataResult, oldValue) {
                    if (!newValue || newValue.timestamp === oldValue?.timestamp) return;
                    if (!newValue.payload) return;
                    if (newValue.payload.value === 'status' || newValue.payload.value?.state === 'status') return;
                    console.log('Pairing set state, LKM changed,', state, device.sn, newValue.payload);
                    lkmWatchPAIRING(); // Cancel
                    $timeout.cancel(timeoutPAIRING);
                    if (newValue.payload.value) {
                      onSuccess('lkm received');
                    } else {
                      onFail('lkm received');
                    }
                  }, true);
            }, function (response) {
              console.error('Pairing set state Error:', response);
              onFail('Network error');
            });
      };

      function get_device(device: IDevice, onSuccess: (device: IDevice) => void, onFail: (response) => void) {
        $http.get('api/dashboard/devices/simple/?ordering=+name&iid=' + device.iid, {timeout: 30000}).then(
            function (response: ng.IHttpPromiseCallbackArg<IDevice[]>) {
              if (WG_debug) console.log("Pairing: Got device: ", response);
              let data = response.data;
              if (data.length > 0) {
                onSuccess(data[0]);
              } else {
                onSuccess(device);
              }
            }, function (response) {
              if (WG_debug) console.error("Pairing: Error getting device: ", response);
              onFail(response);
            });
      }


      $scope.get_status_pair = function (device) {
        if (device.model == 'virtual_smartbox') {
          return;
        }
        pairing_set(device, 'status', null
            , function (reason) {
              console.log('Pair get status done:', reason);
            }, function (reason) {
              console.warn('Pair get status fail:', reason);
            });
      };


      $scope.selectedUnitPing = {
        uuid: null,
        loading: false,
        result: '',
        on_click: $scope.pingSmartBox,
        text: 'Test Connection',
        icon: 'icon icon-wg-manual-entry'
      };

      let lkmWatchPONG = null;
      let PONG_timeouts: angular.IPromise<any>[] = [];
      let PONG_LkmWatchers = [];

      $scope.pingSmartBox = function (device) {
        if (device.model == 'virtual_smartbox') {
          $scope.selectedUnitPing.loading = false;
          $scope.selectedUnitPing.result = '';
          return;
        }
        if ($scope.selectedUnitPing.uuid != device.uuid) {
          $scope.selectedUnitPing.uuid = device.uuid;
          $scope.selectedUnitPing.loading = false;

          if (WG_debug) console.log('Changed smartbox. Canceling pings timeouts');
          if (lkmWatchPONG) lkmWatchPONG();
          for (let _t of PONG_timeouts) {
            $timeout.cancel(_t);
          }
          PONG_timeouts = emptyOrCreateArray(PONG_timeouts);
        }

        // console.log('pingSmartBox', device);
        if ($scope.selectedUnitPing.loading) {
          return;
        }

        $scope.selectedUnitPing.result = '';
        $scope.selectedUnitPing.loading = true;

        let payload = {
          iid: device.iid,
          value: {state: "enable", more_info: false},
          timestamp: new Date().getTime(),
        };
        if (device.status !== 'ON') {
          payload.value.more_info = true;
        }
        let key = '?api_key=' + $rootScope.apiKey;
        let url = $rootScope.base_url + 'data/' + device.path + '/' + SMTB.PING + key;
        // console.log('PING', 'url', url, 'payload', payload);
        $http.post(url, payload).then(
            function (response) {
              console.log('Pairing, Ping sent', {more_info: payload.value.more_info});

              PONG_timeouts.push($timeout(function () {
                $rootScope.WGDevices.update_singular(device.id, device.uuid);
              }, PING_TIMEOUT / 2));

              PONG_timeouts.push($timeout(function () {
                console.log('Pairing, Ping timedout', device.sn);
                if (lkmWatchPONG) lkmWatchPONG();
                for (let _t of PONG_timeouts) {
                  $timeout.cancel(_t);
                }
                PONG_timeouts = emptyOrCreateArray(PONG_timeouts);
                $scope.selectedUnitPing.loading = false;
                $scope.selectedUnitPing.result = 'error';

                $rootScope.WGDevices.update_singular(device.id, device.uuid);

                // device.status = 'OFF';
              }, PING_TIMEOUT));

              if (lkmWatchPONG) lkmWatchPONG();
              lkmWatchPONG = $rootScope.$watch('WGDevices.devices_uuid["' + device.uuid + '"].last_comm',
                  function (newValue: number, oldValue) {
                    if (!newValue || newValue === oldValue) return;
                    console.log('Pairing, Pong received', device.sn, newValue);

                    lkmWatchPONG();
                    for (let _t of PONG_timeouts) {
                      $timeout.cancel(_t);
                    }
                    PONG_timeouts = emptyOrCreateArray(PONG_timeouts);
                    $scope.selectedUnitPing.loading = false;
                    $scope.selectedUnitPing.result = 'success';
                  }, true);

            }, function (response) {
              console.error(response);
              $scope.selectedUnitPing.loading = false;
              $scope.selectedUnitPing.result = 'error';
            });
      };

      //  /   /   /   /   WIZARD

      //  /   Serial numbers
      $scope.snObj = {
        warn: {
          msgs: {
            bool: true,
            to: null
          },
          six: {
            class: false,
            bool: false,
            fade_to: null,       //  Time out to animate fade out on warning message
            clear_to: null       // Time out to clear warning message
          },
          repeat: false,
          validated: false
        },
        check_plus: function () {
          let f = $scope.pairing_wizard.ser_nums.length;
          return f === 1 && $scope.pairing_wizard.ser_nums[f - 1].value
        },
        anim_warn: function () {
          console.log("warn");
          this.clear_warn();
          this.warn.six.bool = true;
          //  Time out to animate fade out on warning message
          this.warn.six.fade_to = $timeout(function () {
            console.log("fade out");
            $scope.snObj.warn.six.class = true;
            // Time out to clear warning message
            $scope.snObj.warn.six.clear_to = $timeout(
                $scope.snObj.clear_warn(),
                5000);
          }, SIX_WARN_TIMEOUT);
        },
        clear_warn: function () {
          $timeout.cancel(this.warn.six.fade_to);
          $timeout.cancel(this.warn.six.clear_to);
          $scope.snObj.warn.six.bool = false;
          $scope.snObj.warn.six.class = false;
        },
        do_check_repeat: function () {
          return this.warn.repeat = this.check_repeat();
        },
        check_repeat: function () {
          return _.uniq(_.map($scope.pairing_wizard.ser_nums, (n) => {
            return n.value
          })).length !== $scope.pairing_wizard.ser_nums.length
        },
        add_field: function () {

          this.clear_warn();

          // last serial number
          var lsn = $scope.pairing_wizard.ser_nums[$scope.pairing_wizard.ser_nums.length - 1];

          if (this.check_good(lsn.value) && !this.warn.repeat) {
            $scope.pairing_wizard.ser_nums.push({
              id: lsn.id + 1,
              value: null,
              val: 0
            })
          } else {
            this.anim_warn();

          }
        },
        check_good: function (num) {
          return angular.isDefined(num) && num !== null && num !== '' && num.length === 6
        },
        check_to: null,
        do_check: function () {
          $timeout.cancel($scope.snObj.warn.msgs.to);
          this.check_to = $timeout(function () {
            $scope.snObj.warn.msgs.bool = false;
          }, 2000);
          $timeout.cancel($scope.snObj.check_to);
          this.check_to = $timeout(function () {
            let bad_count = 0;
            let ok_count = 0;
            //check six each -> array + warn, if null||'' -> nothing
            _.forEach($scope.pairing_wizard.ser_nums,
                (sn, ix) => {
                  if (sn.value === '' || sn.value === null) {
                    //  IS EMPTY
                    $scope.pairing_wizard.ser_nums[ix].val = 0;
                  } else if ($scope.snObj.check_good(sn.value)) {
                    // IS GOOD
                    $scope.pairing_wizard.ser_nums[ix].val = 2;
                    ok_count++;
                  } else {
                    // IS BAD
                    $scope.pairing_wizard.ser_nums[ix].val = 1;
                    bad_count++;
                  }
                });

            //check dupes -> warn
            $scope.snObj.do_check_repeat();
            $scope.snObj.warn.validated = ok_count > 0 && bad_count < 1 && !$scope.snObj.warn.repeat;
            console.log("checked. ok_count: " + ok_count + ", bad_count: " + bad_count);
          }, 100);
        }
      };


      $scope.wizard_go = function (wizard, to_step: number) {
        let from_step: number = wizard.step;

        $scope.pairing_wizard.wizard = wizard;
        if (from_step === to_step) {
          return;
        }

        if (WG_debug) console.log('wizard_go', from_step, to_step);
        /*if (from_step === $scope.step.NEW_DEVICES && num <= $scope.step.NEW_DEVICES) {
          return;
        }*/

        if (from_step === $scope.step.DONE) {
          return;
        }

        wizard.go(to_step);

        if (to_step === $scope.step.NEW_DEVICES) {
          $scope.alerts = emptyOrCreateArray($scope.alerts);
          start_pairing();
          return;
        }

        // Going back from New-Devices
        if (from_step === $scope.step.NEW_DEVICES && to_step < $scope.step.NEW_DEVICES) {
          done();
          return;
        }

        if (to_step === $scope.step.DONE) {
          done();
          return;
        }
      };

      let start_pairing = function () {
        $scope.foundDevice = false;
        $scope.timeoutPairingMsg = null;

        // let _device_type = $scope.device_types[$scope.pairing_wizard.device_type] || $scope.device_types.LORA;
        // let msg = _device_type.message || 'app.pairing.wizard.devs.alert.NOTE1';
        //
        // if (!msg && _device_type.has_button)
        //   msg = 'app.pairing.wizard.devs.alert.NOTE1';
        // if (!msg && !_device_type.has_button)
        //   msg = 'app.pairing.wizard.devs.alert.WPS';

        // $scope.alerts.push({
        //   icon: 'fa fa-info-circle',
        //   type: 'warning',
        //   msg_translate: msg,
        // });


        let timeoutPAIRING = $timeout(function () {
          console.log('timeout pairing');
          $scope.timeoutPairingMsg = 'app.pairing.wizard.devs.alert.TIMEOUT';
          $scope.alerts = emptyOrCreateArray($scope.alerts);

          $scope.alerts.push({
            icon: 'fa fa-exclamation-triangle',
            type: 'danger',
            msg_translate: $scope.timeoutPairingMsg
          });

          if ($scope.selectedUnit) {
            pairing_set($scope.selectedUnit, 'disable', null, function (reason) {
              console.log("Pairing disable due to timeout Done", reason);
            }, function (reason) {
              console.warn("Pairing disable due to timeout, Error", reason);
            });
          }
        }, PAIRING_TIMEOUT);

        // console.log("pairing_set get_status_pair", $scope.alerts)
        let state = 'enable';
        let data: IDeviceLKMConfigs = {};
        if ($scope.pairing_wizard.method === $scope.pairing_methods.MANUAL) {
          let uuid = null;
          let SBox = null;
          if (_.size($scope.virtual_smartboxes_uuid) === 1) {
            uuid = Object.keys($scope.virtual_smartboxes_uuid)[0];
            SBox = $scope.virtual_smartboxes_uuid[uuid];
          } else if (_.size($scope.smartboxes_uuid) === 1) {
            uuid = Object.keys($scope.smartboxes_uuid)[0];
            SBox = $scope.smartboxes_uuid[uuid];
          }
          $scope.monitorSmartBox({uuid: uuid}, SBox);
          let devices_sn = [];
          for (let i in $scope.pairing_wizard.ser_nums) {
            let sn = $scope.pairing_wizard.ser_nums[i].value;
            if ($scope.snObj.check_good(sn)) {
              devices_sn.push(sn);
              // console.log('sn added. sn: '+sn);
            } else {
              // console.log('sn not added. sn: '+sn);
            }
          }
          // console.log('devices_sn', devices_sn);
          data.devices_sn = devices_sn;
          state = 'manual_pair';

        } else {

          $scope.monitorSmartBox();
          data.devices_default_configs = {
            wifi: {}
          };
          if ($scope.pairing_wizard.connection_type === 'client'
              && $scope.pairing_wizard.has_wifi()) {
            data.devices_default_configs.wifi = {
              SSID: $scope.pairing_wizard.wifi.ssid,
              PASS: $scope.pairing_wizard.wifi.pass,
            };
          }
        }

        pairing_set($scope.selectedUnit, state, data,
            function (reason) {
              console.log("Pairing set state done, ", state, reason);
            }, function (reason) {
              console.warn("Pairing set state failed, ", state, reason);
            });
      };


      let done = function () {
        if (WG_debug) console.log('Pairing disabling', $scope.pairing_wizard);
        pairing_set($scope.selectedUnit, 'disable', null, function (reason) {
          console.log("Pairing disable Done", reason);
        }, function (reason) {
          console.error("Pairing disable Error", reason);
        });
        $scope.foundDevice = false;
        $rootScope.WGDevices.changed = true;
        $rootScope.WGApiData.update_changed_data();
      };


      //  /   /   /   /   FUNCT
      $scope.value_CPU_TEMP = function (device) {
        // console.log('CPU_TEMP', device.lkm['CPU_TEMP'].payload.value);
        return device.lkm['CPU_TEMP'].payload.value;
      };

      //  /   /   /   /   UI FUNCT
      $scope.closeAlert = function (index) {
        $scope.alerts.splice(index, 1);
      };
      $scope.$watch('vm.searchQuery', function (query) {
        if (!angular.isDefined(query)) {
          return;
        }
        if (_.isEmpty(query)) {
          $scope.nest_items = $scope.nest_items_orig;
          return;
        }
        $scope.nest_items_flat = flatten_nestable($scope.nest_items_orig);
        $scope.nest_items = $filter('filter')($scope.nest_items_flat, {item: {text: query}});
        // console.log(query, $scope.nest_items_flat, $scope.nest_items);
      });


      //  /   /   /   /   ACTIONS
      $scope.selectSmartBox = function (item, sbox_device = null) {
        // console.log("Hello", item, unit);
        if (!sbox_device) {
          if (angular.isDefined(last_item)) {
            last_item.selected = false;
            last_item.style = {};
          }
          last_item = item;
          item.selected = true;
          item.style = {
            'color': '#8b4243'
          };
          sbox_device = $scope.smartboxes_uuid[item.uuid]
        }
        $scope.selectedUnit = sbox_device;

        if (sbox_device.model == 'virtual_smartbox') {
          $scope.pairing_wizard.method = $scope.pairing_methods.MANUAL;
          $scope.pairing_methods_enabled.MANUAL = true;
          $scope.snObj.do_check();
        } else {
          $scope.pairing_wizard.method = $scope.pairing_methods.AUTO;
          $scope.pairing_methods_enabled.MANUAL = false;
        }
        $scope.pingSmartBox(sbox_device);
        // $scope.get_status_pair(sbox_device);
      };

      $scope.monitorSmartBox = function (item = null, sbox_device = null) {
        if (!sbox_device && item?.uuid) {
          sbox_device = $scope.smartboxes_uuid[item.uuid];
        }
        if (!sbox_device) {
          sbox_device = $scope.selectedUnit;
        }
        for (let i in $scope.lkmWatch[sbox_device.uuid]) {
          if ($scope.lkmWatch[sbox_device.uuid][i])
            $timeout.cancel($scope.lkmWatch[sbox_device.uuid][i]);
        }
        console.log('Monitoring for new sensors with SBox ', sbox_device.sn, sbox_device);
        $scope.lkmWatch[sbox_device.uuid] = {};

        $scope.lkmWatch[sbox_device.uuid][SMTB.PAIRING] = $rootScope.$watch('lastKnownMessages["' + sbox_device.uuid + '"]["' + SMTB.PAIRING + '"]'
            , function (newValue: IDataResult, oldValue) {
              if (!newValue || newValue.timestamp === oldValue?.timestamp) return;
              if (!newValue.payload) return;
              let new_state = newValue.payload.value?.state;
              if (_.isNil(new_state)) return;
              console.log('Pairing new_state,', new_state, sbox_device.sn, newValue.payload);

              if (new_state === 'timeout' || new_state.includes('disable')) {
                console.log('timeout pairing');
                $scope.timeoutPairingMsg = 'app.pairing.wizard.devs.alert.TIMEOUT';
                $scope.alerts = emptyOrCreateArray($scope.alerts);
                $scope.alerts.push({
                  icon: 'fa fa-exclamation-triangle',
                  type: 'danger',
                  msg_translate: $scope.timeoutPairingMsg
                });
              }
            }, true);

        $scope.lkmWatch[sbox_device.uuid][SMTB.AP_WPS_ENROLLEE_SEEN] = $rootScope.$watch('lastKnownMessages["' + sbox_device.uuid + '"]["' + SMTB.AP_WPS_ENROLLEE_SEEN + '"]'
            , function (newValue: IDataResult, oldValue) {
              if (!newValue || newValue.timestamp === oldValue?.timestamp)
                return;
              let device = newValue.payload.device;

              $scope.foundDevice = true;

              if (device && (device.id > 0 || device.uuid)) {
                $rootScope.WGDevices.update_singular(device.id, device.uuid);
              } else {
                $rootScope.WGDevices.changed = true;
              }

              function update(_device) {
                // device.status = 'New device, paired';
                _device.status_str = 'app.pairing.wizard.devs.NEW';
                var obj = _.find($scope.pairing_devices, {iid: _device.iid});
                if (!obj) {
                  if (WG_debug) console.log(SMTB.AP_WPS_ENROLLEE_SEEN, _device, 'Paired new');
                  $scope.pairing_devices.push(_device);

                  if (_device.id > 0)
                    $rootScope.WGDevices.update_singular(_device.id, _device.uuid);
                } else {
                  if (WG_debug) console.log(SMTB.AP_WPS_ENROLLEE_SEEN, _device, 'Paired new, previously seen. Extended');
                  angular.extend(obj, _device);
                }
              }

              // TODO:  $rootScope.WGDevices.update_singular(_device.iid) with Callbacks
              get_device(device, function (_device) {
                update(_device);
              }, function (response) {
                update(device);
              });
            }, true);

        // console.log('selectSmartBox', sbox_device, $rootScope.lastKnownMessages[sbox_device.uuid][SMTB.AP_WPS_REG_SUCCESS]);
        $scope.lkmWatch[sbox_device.uuid][SMTB.AP_WPS_REG_SUCCESS] = $rootScope.$watch('lastKnownMessages["' + sbox_device.uuid + '"]["' + SMTB.AP_WPS_REG_SUCCESS + '"]',
            function (newValue: IDataResult, oldValue) {
              if (!angular.isDefined(newValue))
                return;
              if (angular.isDefined(oldValue) && newValue.timestamp === oldValue.timestamp)
                return;
              var device = newValue.payload.device;

              function update(_device) {
                let obj = _.find($scope.pairing_devices, {iid: _device.iid});
                if (!obj) {
                  // obj = device;
                  // _device.status = 'Device already paired';
                  _device.status_str = 'app.pairing.wizard.devs.KNOWN';
                  $scope.pairing_devices.push(_device);
                  if (WG_debug) console.log(SMTB.AP_WPS_REG_SUCCESS, _device, 'Device already paired');
                } else {
                  if (WG_debug) console.log(SMTB.AP_WPS_REG_SUCCESS, _device, 'Device already paired. Previously seen');
                }
              }

              get_device(device, function (_device) {
                update(_device);
              }, function (response) {
                update(device);
              });
            }, true);
      }

      //  /   /   /   /   INIT

      $rootScope.$watch('WGApiData.AllReady', function () {
        if (!WGApiData.AllReady) {
          return;
        }
        var device, _device;
        var user = AuthService.user; // AuthService.getUser();

        $scope.smartboxes_uuid = {};
        $scope.nest_items_orig = [];

        // console.log($scope.$parent.devices);
        _.forEach(WGApiData.WGDevices.devices, function (device) {
          if (SMARTBOX_MODELS.includes(device.model) || device.unit_type === 'smartbox') {
            // Smartbox already on the list:
            if ($scope.smartboxes_uuid[device.uuid]) {
              if (WG_debug) console.log('Smartbox already on the list:', device);
              return;
            }
            $scope.smartboxes_uuid[device.uuid] = device;
            $scope.nest_items_orig.push({
              item: {
                text: device.name,
                uuid: device.uuid,
                isGroup: false
              },
              children: []
            });
            // if (WG_debug) console.log("Smartbox found:", device);
            // } else if (device.model == 'virtual_smartbox') {
            //   if ($scope.virtual_smartboxes_uuid[device.uuid]) {
            //     if (WG_debug) console.log('Virtual_smartbox already on the list:', device);
            //     return;
            //   }
            //   if (AuthService.view_as_owner && AuthService.view_as_owner.id === device.owner.id) {
            //     // Virtual smartboxes are not selectable. We can only work if a single owner is selected, otherwise we can't set it.
            //     if (WG_debug) console.log('Virtual_smartbox of target user found:', device);
            //     $scope.virtual_smartboxes_uuid[device.uuid] = device;
            //   } else if (device.owner.id === user.id) {
            //     if (WG_debug) console.log('Virtual_smartbox found:', device);
            //     $scope.virtual_smartboxes_uuid[device.uuid] = device;
            //   }
          }
        });
        // if (_.size($scope.smartboxes_uuid) === 0) { //&& _.size($scope.virtual_smartboxes_uuid) !== 1) {
        //   $scope.pairing_methods_enabled.AUTO = false;
        // }
        // if (_.size($scope.smartboxes_uuid) !== 1 && _.size($scope.virtual_smartboxes_uuid) !== 1) {
        //   $scope.pairing_methods_enabled.MANUAL = false;
        // }
        $scope.pairing_methods_enabled.AUTO = !_.isEmpty($scope.smartboxes_uuid);
        $scope.pairing_methods_enabled.MANUAL = !_.isEmpty($scope.virtual_smartboxes_uuid); // || $scope.pairing_methods_enabled.AUTO;
        $scope.unable_to_pair = !$scope.pairing_methods_enabled.MANUAL && !$scope.pairing_methods_enabled.AUTO;

        if ($scope.unable_to_pair) {
          // TODO: if no smartbox and no virtual smartbox are available, show warning.
          // console.error("No Smartboxes to pair with. Don't know how to proceed");
        }

        $scope.nest_items = $scope.nest_items_orig;
        if ($scope.nest_items_orig.length === 1) {
          $scope.selectSmartBox($scope.nest_items_orig[0].item);
        }
        if ($scope.nest_items.length > 0) {
        }
      });

      function init() {

        $scope.step = {
          METHOD: 1,
          DEVICE_TYPE: 2,
          CONN_TYPE: 3,
          CONN_SETTINGS: 4,
          NEW_DEVICES: 5,
          DONE: 6
        };
        $scope.manualSteps = {
          SERIAL_NUMS: 1,
          FIND_DEVICES: 2
        };

        $scope.device_types = {
          SMARTBUNG: {
            type: 'SMARTBUNG',
            name: 'e-bung, e-aphrom, smartcellar',
            has_wifi: true,
            has_button: true,
            message: 'app.pairing.wizard.devs.alert.BUTTON3',
            // message: 'app.pairing.wizard.devs.alert.EBUNGS',
          },
          WP: {
            type: 'WP',
            name: 'Wineplus, e-charmat, other',
            has_wifi: false,
            has_button: false,
            message: 'app.pairing.wizard.devs.alert.WPS',
          },
          BP: {
            type: 'BP',
            name: 'barrelplus (BP)',
            has_wifi: false,
            has_button: true,
            message: 'app.pairing.wizard.devs.alert.NOTE1', // TODO: This has a switch physical button.
            // message: 'app.pairing.wizard.devs.alert.WPS',
          },
          // WIFI: {
          //   type: 'WIFI',
          //   name: 'Battery powered sensors (supports Wi-Fi)',
          //   has_wifi: true,
          //   has_button: true,
          //   message: 'app.pairing.wizard.devs.alert.NOTE1',
          // },
          // LORA: {
          //   type: 'LORA',
          //   name: 'Other (LoRa)',
          //   message: 'app.pairing.wizard.devs.alert.NOTE1',
          // },
          // APHROMETER: {
          //   type: 'APHROMETER',
          //   name: 'e-aphrom',
          //   has_button: true,
          //   has_wifi: true,
          //   message: 'app.pairing.wizard.devs.alert.NOTE1',
          // },
          // SMARTCELLAR: {
          //   type: 'SMARTCELLAR',
          //   name: 'smartcellar',
          //   has_button: true,
          //   has_wifi: true,
          //   message: 'app.pairing.wizard.devs.alert.NOTE1',
          // },
        };

        $scope.device_type_selected;
        $scope.vm = {};
        $scope.vm.searchQuery = '';
        $scope.foundDevice = false;
        $scope.pairing_wizard = {
          method: 'auto',
          ser_nums: [{
            id: 0,
            value: '',
            val: 0
          }],
          device_type: null,
          // device_type_forward: null,
          connection_type: 'smartbox',
          wizard: null,
          wifi: {ssid: null, pass: null},
          has_wifi: function () {
            return this.wifi
                && this.wifi.ssid?.length > 0
                && this.wifi.pass?.length >= 8;
          }
        };


        // $scope.$watch('pairing_wizard.device_type', function (device_type: string) {
        //   $scope.device_type_selected = device_type;
        //
        //
        //   if (!angular.isDefined(device_type)) {
        //     return;
        //   }
        //   let _device_type = $scope.device_types[device_type] || $scope.device_types.WP;
        //
        //   //console.log('$watch pairing_wizard.device_type', device_type);
        //   if (_device_type.has_wifi) {
        //     $scope.pairing_wizard.device_type_forward = 'NEXT';
        //   } else {
        //     $scope.pairing_wizard.device_type_forward = 'START';
        //   }
        //
        //
        // });


        $scope.pairing_devices = [];

        $scope.alerts = emptyOrCreateArray($scope.alerts);

        $scope.nest_items = [];
        $scope.nest_items_orig = [];
        $scope.nest_items_flat = [];
        $scope.nest_items_init = {
          initState: "collapsed"
        };

        $scope.selectedUnit = null;
        $scope.smartboxes_uuid = {};
        $scope.virtual_smartboxes_uuid = {};
        $scope.lkmWatch = {};
        $scope.unable_to_pair = false;
        last_item = undefined;
      }

      init();

      //  /   /   /   /   DEV
      $scope.logScope = function () {
        console.log("$scope.pairing_wizard.has_wifi()", $scope.pairing_wizard.has_wifi("speak"),
            "##  $scope  ##", $scope);
      };
    }
  ])
  ;
}