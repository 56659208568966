/**
 * Created by pmec on 07/10/16.
 */
namespace wg {

  interface IScope extends ng.IScope {
    newProcessData: Partial<IProcess>;
  }

  App.controller("CreateProcessModalInstance", ['$rootScope', '$scope', '$filter', '$http', '$location', '$state', '$stateParams', '$window', 'APIUtils', 'ngDialog', 'AuthService', '$translate', 'toaster', '$timeout',
    function ($rootScope: IRootScope, $scope: IScope, $filter: ng.IFilterService, $http: ng.IHttpService, $location: ng.ILocationService, $state: _IState, $stateParams, $window, APIUtils: APIUtils, ngDialog: ng.dialog.IDialogService, AuthService: IAuthService, $translate: ng.translate.ITranslateService, toaster, $timeout) {

      //                                               ---     VARIABLES
      $scope.data_changed = false;
      $scope.selected = {};

      // TODO: If there's protocol_templates, make isProtocol default. Else leave Process as default
      // $scope.isProtocol = $rootScope.WG_is_beta;
      $scope.isProtocol = true;
      $scope.protocolTemplatesList = [];
      $scope.grapeTypes = ["red", "white", "rose", "sparkling", "other"];

      // ---- Initialize OpenMode and Ret Variables
      let _openMode = 'open';
      if (angular.isDefined($scope.ngDialogData.mode)) {
        _openMode = $scope.ngDialogData.mode;
      }


      let parentRet: IReturnResult = {
        loading: false,
        result: '',
        message: '',
      };
      $scope.ret = parentRet;

      let ret_key = $scope.ngDialogData?.result || 'manualEntryRet';
      if ($scope.$parent?.[ret_key]) {
        $scope.$parent[ret_key] = parentRet;
      } else if ($scope.$parent?.$parent?.[ret_key]) {
        $scope.$parent.$parent[ret_key] = parentRet;
      }


      // $scope.toggleHelpSites=false;
      // $scope.toggleHelpDF=false;
      // $scope.toggleHelpDesc=false;
      $scope.isHelpCollapsed = {
        sites: true,
        time: true,
        more: true
      };

      // Used to later compare selected data and detect changes
      let initial_data: Partial<IProcess|IWinemakingProtocol> = {};

      //                                          ---     ACCORDION
      $scope.done = {
        section1: false,
        section2: false,
        section3: false,
        section4: true,
      };
      $scope.toggleHelp = {
        sites: false,
        time: false,
        dynf: false,
        desc: false,
      };
      $scope.validate = {
        unitIsChosen: function () {
          return $scope.selected.unit && $scope.selected.unit.id > 0;
        },
        section1: function () {
          let nameIsChosen = !_.isEmpty($scope.newProcessData.name);

          let protocolIsChosen = !_.isNil($scope.newProcessData.protocol_template?.id);
          // let massIsChosen = !_.isNil($scope.newProcessData.protocol?.initial_weight);
          // let volumeIsChosen = !_.isNil($scope.newProcessData.protocol?.initial_volume);

          if ($scope.isProtocol) {
            $scope.done.section1 = (nameIsChosen && protocolIsChosen);
          } else {
            $scope.done.section1 = (nameIsChosen);
          }

        },
        section2: function () {
          // --------------hide sensor select if no unit selected -------//
          let v1 = this.unitIsChosen();
          $scope.done.section2 = v1;

        },
        section3: function () {
          if (!$scope.newProcessData.started_at) { // Missing Start Time
            $scope.done.section3 = false;
            return;
          }

          if (!!$scope.dates.is_ended) {
            if (!$scope.newProcessData.ended_at) { // Ended selected but still no EndTime
              $scope.done.section3 = false;
              return;
            }

            if ($scope.newProcessData.ended_at < $scope.newProcessData.started_at) { // Ended before started?
              $scope.done.section3 = false;
              return;
            }
          } else {
            // Delete end_date when is_ended is deselected
            $scope.newProcessData.ended_at = null;
          }
          $scope.done.section3 = true;
        },
        section4: function () {
          // these values are no longer necessary
          // let v1 = angular.isDefined($scope.newProcessData.batch);
          // let v2 = angular.isDefined($scope.newProcessData.content_type);
          // // let v3 = angular.isDefined($scope.newProcessData.quantity);
          // $scope.done.section4 = v1 || v2;
          $scope.done.section4 = true;
        }
      };
      $scope.warn_volume = false;
      $scope.volume = {
        warn: false,
        check: function () {
          // warn user if volume of content of process is bigger than volume capacity of selected unit
          // if ($scope.selected.unit) {
          //   let pq = $scope.newProcessData.quantity;
          //   let uv = $scope.selected.unit['total_volume'];
          //   let ifer = pq > 0 && uv > 0;
          //   if (ifer) {
          //     this.warn = pq > uv;
          //   }
          // }
        }
      };

      //- btn-group too complex to have a ng-change
      $scope.$watch('newProcessData.started_at',
          function (newValue, oldValue) {
            // alert('watch');
            $scope.validate.section3();
          }, true
      );
      $scope.$watch('newProcessData.ended_at',
          function (newValue, oldValue) {
            // alert('watch');
            $scope.validate.section3();
          }, true
      );
      $scope.$watch('dates.is_ended',
          function (newValue, oldValue) {
            // alert('watch');
            $scope.validate.section3();
          }
      );

      //                                          ---     ELEMENTS

      //                                  --- DYNAMIC FIELDS
      $scope.df = {
        WARN: false,
        length: function () {
          return _.keys($scope.newProcessData.dynamic_fields).length
        },
        new_values: {key: '', value: ''},
        has_fields: function () {
          return _.keys($scope.newProcessData.dynamic_fields).length > 0
        },
        has_values: function () {
          return this.new_values.key !== '' && this.new_values.value !== ''
          // this.h_val = !(this.new_values.key==='' && this.new_values.value==='');
        },
        removeDynField: function (chave) {
          delete $scope.newProcessData.dynamic_fields[chave];
        },
        addDynField: function (keyvalue, isNew) {
          //\\ keyvalue&isNew are for the purpose of allowing to
          // submit dynamic field with an enter.
          // but it also triggers submit,
          // so the values are just being pre-satisfied on function call
          // if($scope.df.last_df_has_values() && keyvalue===13 && isNew) {
          // alert('check for dups');
          if (!angular.isDefined($scope.newProcessData.dynamic_fields) || $scope.newProcessData.dynamic_fields === null) {
            $scope.newProcessData.dynamic_fields = {};
          }
          let ch = this.new_values.key;
          let vl = this.new_values.value;
          let propriedades = _.keys($scope.newProcessData.dynamic_fields);
          let isDupe = _.indexOf(propriedades, ch) > -1;
          this.WARN = isDupe;
          if (isDupe) {
            // console.log("isDupe", isDupe);
            return
          } else {
            console.log("is NOT Dupe", isDupe);
            if (this.has_values()) {
              $scope.newProcessData.dynamic_fields[ch] = vl;
              this.new_values.key = '';
              this.new_values.value = '';
            }
          }
          // this.length =_.keys($scope.newProcessData.dynamic_fields).length;
          // $scope.dfLength =_.keys($scope.newProcessData.dynamic_fields).length;

          // this.length = _.keys($scope.newProcessData.dynamic_fields).length;
          console.log("addDynFld. length", this.length);
        }
      };

      //                              ---     TYPES
      $scope.processesTypes = {
        FERMENTATION: "fermentation",
        MATURATION: "maturation",
        PRESSING: "pressing",
        AMBIENT: "ambient",
        STORAGE: "storage",
        OTHER: "other"
      };
      if ($rootScope.WG_is_mar2protect) {
        $scope.processesTypes = {
          AMBIENT: "ambient",
          OTHER: "other"
        };
      }

      $scope.processesTypesList = []; // Same, but list of objects
      angular.forEach($scope.processesTypes, function (value, key) {
        $scope.processesTypesList.push({
          name: value,
          type: key
        });
      });

      $scope.isProtocolChanged = function (isProtocol: boolean) {
        $scope.isProtocol = isProtocol;
        console.log("Creating a Protocol:", isProtocol)
        // if ($scope.isProtocol) {
        //   $scope.get_protocolTemplatesList();
        // }
        // $scope.$apply();
      }


      $scope.get_protocolTemplatesList = () => {
        $http.get<IWinemakingProtocol[]>('api/dashboard/winemaking/protocol_template/').then(
            (response) => {
              if (WG_debug) console.log("Got Template Protocols. response.data:", response.data);
              $scope.protocolTemplatesList = _.filter(response.data, {owner: {id: AuthService.view_as_owner.id}});

              if ($scope.newProcessData.protocol_template?.name) {
                return;
              }
              if (!$scope.newProcessData.protocol?.template) {
                return;
              }

              let _template = _.find($scope.protocolTemplatesList, {id: $scope.newProcessData.protocol.template});
              if (_template) $scope.newProcessData.protocol_template = _template;

            },
            (response) => {
              if (WG_debug) console.log("Error getting protocol templates. response: ", response);
            });
      }

      $scope.get_protocolsList = () => {
        $http.get<IWinemakingProtocol[]>('api/dashboard/winemaking/protocol/').then(
            (response) => {
              if (WG_debug) console.log("Got Active Protocols. response.data:", response.data);
              $scope.active_protocolsList = _.filter(response.data, {archived: false});

              if ($scope.newProcessData.protocol?.name) {
                return;
              }
              let _protocol = null
              // If isProtocol and no object or name, set active protocol
              if ($scope.newProcessData.unit?.protocol) {
                _protocol = _.find($scope.active_protocolsList, {id: $scope.newProcessData.unit.protocol});
              } else if ($scope.newProcessData.protocol?.id) {
                _protocol = _.find($scope.active_protocolsList, {id: $scope.newProcessData.protocol.id});
              }
              if (_protocol) {
                $scope.newProcessData.protocol = _protocol;
                let _template = _.find($scope.protocolTemplatesList, {id: _protocol.template});
                if (_template) $scope.newProcessData.protocol_template = _template;
              }

            },
            (response) => {
              if (WG_debug) console.log("Error getting active protocols. response: ", response);
            });
      }


      //                              ---     TIME
      let minimum_process_length = 300000;//5mins // actually, to avoid complications, startMax, and changeStartMax are undone
      function dateTimeNow() {
        return new Date();
      }

      $scope.dates = {
        block: false,
        now: new Date(),
        // Set start date to passed date (optional), and to the start of the minute
        setStart: function (time: number | Date = null) {
          if (!_.isNil(time)) {
            $scope.newProcessData.started_at = new Date(time);
          }
          if (_.isNil($scope.newProcessData.started_at)) {
            $scope.newProcessData.started_at = new Date();
          }
          // this.start.setMilliseconds(0);
        },
        end: null, // Date
        getNow: function () {
          return this.now = new Date();
        },
        // before          : new Date(dateTimeNow() - minimum_process_length),//this doesn't make sense
        is_ended: false,
        startMax: new Date(),
        // endMax          : function(){
        //   //  ver se
        // },
        onChangeEndDate: function () {
          if (!$scope.newProcessData.ended_at)
            $scope.newProcessData.ended_at = new Date();
          $scope.validate.section3();
          // this.startMax =
          //   this.is_ended ?
          //     this.before :
          //     dateTimeNow();
          // start date should not be bigger than now,
          // neither than the end date.
          this.startMax = $scope.newProcessData.ended_at ? new Date($scope.newProcessData.ended_at) : this.getNow();
          // console.log("startMax=", this.startMax);
        },
      };
      $scope.$watch('newProcessData.ended_at', function (nv, ov) {
        $scope.dates.onChangeEndDate();
        if (nv !== null) {
          console.log("ended_at CHANGED. is: ", nv);
        }
      });
      //**********
      //**********
      // let beforeDate = new Date($scope.nowDate - 300000);
      // $scope.maxDate = $scope.nowDate;
      // $scope.nowDate = new Date ();
      // $scope.changeMaxDate = function(){
      //   $scope.maxDate =
      //     $scope.is_ended ?
      //       beforeDate :
      //       $scope.nowDate;
      // };
      // $scope.is_ended = false;
      // $scope.dateTimeNow = function () {
      //   return new Date();
      // };
      //**********
      //**********
      $scope.dateOptions = {
        startingDay: 1,
        showWeeks: false
      };

      //\\                          ---     SELECTED

      $scope.selected = {};
      //                              ---     BATCHES
      $scope.changedSelectedBatch = function () {
        // console.log($scope.selected);
        let nO = angular.copy($scope.selected.batch);
        if (angular.isDefined(nO) && nO !== null) {
          $scope.newProcessData.batch = nO;
        }
        // console.log("batch: ", $scope.newProcessData.batch );
      };
      $scope.batches = [];

      function getBatches() {
        $http.get<IDataResponse<IBatch>>('api/dashboard/batches/?ordering=+name').then(
            function (response) {
              console.log("Got Batches. response.data: ", response.data);

              AuthService.anonymize_entities("Batch", response.data.results);

              $scope.batches = response.data.results;
            },
            function (response) {
              $scope.batches = 'error';
              // console.log("Error getting places. response.data: ", response.data);
              console.error(response);
            });
      }

      //                              ---     UNITS
      $scope.unitsBlock = false;
      $scope.orphanDeviceBlock = false;
      $scope.units_avail_length = null;
      // $scope.selected.unit = {
      //   devices:[
      //     {name: ''}
      //   ],
      //   place:{
      //     name: ''
      //     // name: $translate.instant('app.processes.create.location.NO_LOCATION')
      //   }
      // };
      // $scope.changedSelectedUnit = function(){
      //     console.log($scope.selected);
      //     let ni = angular.copy($scope.selected.unit.id);
      //     if(angular.isDefined(ni) && ni!==null){
      //         alert('verify that unit is available');
      //         $scope.newProcessData.unit_id = ni;
      //     }
      //     console.log("unit_i: ", $scope.newProcessData.unit_id );
      // };

      // Available units
      $scope.units = [];

      function sort_filter_count_units(data) {
        let obj = data;

        let g;
        // let f = _.sortBy(response.data.results, 'last_activity');
        // Workaround for [units with finished process, still show as occupied and unavailable for new processes]
        let f = _.sortBy(_.map(data,
            (d) => {
              // Nullify inactive processes
              d.process = (!d.process || !d.process.active) ? null : d.process;
              return d
            }), 'name');
        obj = _.sortBy(f, function (x) {
          return x.process !== null;
        });

        //  Make count of available units without a process running
        let cx = _.countBy(obj, function (x) {
          return !x.process
        });
        $scope.units_avail_length = cx.true || 0;

        //GO!
        return obj
      }

      function getUnits() {
        $http.get<IDataResponse<IUnit>>('api/dashboard/units/').then(
            function (response) {
              console.log("Got Units. response.data: ",
                  response.data);

              AuthService.anonymize_entities("Unit", response.data.results);

              $scope.units = sort_filter_count_units(response.data.results);

              console.log("Got Units, sorted by 'last_activity', filtered or sorted by 'x.process !== null'", $scope.units);
              // $scope.units = response.data.results;
            },
            function (response) {
              $scope.units = 'error';
              // console.log("Error getting places. response.data: ", response.data);
              console.error(response);
            });
      }

      function setProcessAtUnitAPI(processID, unitID, close_after_success) {
        close_after_success = (close_after_success === undefined) ? false : close_after_success;
        parentRet.loading = true;

        // $rootScope.WGUnits.changed = true;
        $rootScope.WGUnits.processing = true; // Changes pending.
        $http.patch<IUnit>('api/dashboard/units/' + unitID + '/', {process_id: processID}).then(
            function (response) {
              // $rootScope.WGUnits.changed = true;
              // $rootScope.WGProcesses.changed = true;

              console.log("setProcessAtUnitAPI SUCCESS: response", response);
              parentRet.loading = false;
              parentRet.result = 'success';
              $scope.coms.unit = true;

              $rootScope.WGUnits.merge_entry(response.data, true);

              if (close_after_success) {
                $timeout(function () {
                  $scope.cancel(0);
                }, 600);
              }
            }, function (response) {
              console.error(response);
              parentRet.result = 'error';
              parentRet.loading = false;
              $scope.coms.unit = false;
            }
        ).finally(() => {
          $rootScope.WGApiData.update_changed_data_soon();
        });
        // alert('setProcessAtUnitAPI:'+processId+': this feature is not available yet. ' +
        //     'Please contact your local representative of WineGrid');
      }

      //                                          ---     ACTIONS

      // functions
      let saving = {
        isAll: function () {
          return (this.done.processes + this.done.units + this.done.devices) > 2
        },
        done: {
          processes: null,
          units: null,
          devices: null
        },
        end: function (wp) {
          this.done[wp] = true;
        },
      };

      let newThingModalIsOpen = false;
      /**
       * Creates a new Element, of passable types, to use in Modals
       * , allowing to add missing units/places/devices etc
       * @param thing - 'places'|'units|'process'|'batches'
       * @param editable_unit
       */
      $scope.newThingDialog = function (thing, editable_unit) {

        console.log("Create modal for :", thing);
        if (newThingModalIsOpen) {
          console.log("attempt to open second edit modal");
          return;
        }
        newThingModalIsOpen = true;
        var dialog = ngDialog.open({
              template: 'app/views/modals/edit-dialog-' + thing + '.html',
              data: {
                elemType: thing,
                action: editable_unit ? 'edit' : 'create',
                elem: editable_unit || {}
              }
            }
        );
        dialog.closePromise.then(
            function (obj) {
              newThingModalIsOpen = false;
              console.log("closeNewThingDialog. obj:", obj);
              if (obj.value?.item_data) {
                if ($scope.see)
                  $scope.see.item = obj.value.item_data;
                if (thing === 'units' && $scope.selected) {
                  $scope.selected.unit = obj.value.item_data;
                  $scope.validate?.section2?.();
                  let d = _.findIndex($scope.units, function (x: IUnit | IProcess | IPlace) {
                    return x.id === obj.value.item_data.id
                  });
                  if (d < 0) {
                    $scope.units.push(obj.value.item_data);
                    $scope.units.sort(order_names);
                  } else {
                    $scope.units[d] = obj.value.item_data;
                  }
                  getUnits?.();
                }
              }
            });
      };
      $scope.deleteProcess = function () {
        // console.log("delete PROCESS, newProcessData: ", $scope.newProcessData);
        ngDialog.openConfirm({
          template: 'app/views/modals/confirm-danger.html',
          className: 'ngdialog-theme-default',
          data: {
            what: 'process',
            which: $scope.newProcessData.name,
            process_warn: true,
            extra: '§ If the process has finished, you can stop it, and it ' +
                'will be tagged as finished, so you can track your data in the past.' +
                'Deleting it makes it unavailable forever. Do you still wish to continue?'
          },
          controller: 'CreateProcessModalInstance',
          // @ts-ignore
          scope: $scope
        }).then(
            function (response) {
              $rootScope.WGProcesses.delete($scope.newProcessData.id, (response) => {
                if (response == 'error') {
                  console.log("DELETE process FAIL: )", response);
                }
                if (response == 'success' && $scope.confirm)
                  $scope.confirm({response: response, wasDelete: true});
                else
                  $scope.closeThisDialog({response: response, wasDelete: true});
              });
            }, function (reason) {
              console.log('DELETE Modal promise rejected. Reason: ', reason);
            })
      };

      // This is also the close button
      $scope.cancel = function (value) {

        // parentRet.loading = true;
        // parentRet.result = 'success';

        let return_result = {
          isAdd: parentRet.result === 'success',
          type: 'process',
          item_data: $scope.newProcessData,
          // item_data: {
          //   id: $scope.newProcessData.id
          // },
        };

        if (($scope.data_changed || parentRet.result === 'success') && $scope.confirm)
          $scope.confirm(return_result);
        else if ($scope.closeThisDialog)
          $scope.closeThisDialog(return_result);
        else
          $scope.close(0);
      };

      // Create a new process with the data passed from the modal dialog
      $scope.saveData = function () {

        $scope.validate.section1();
        if ($scope.done.section1) {  //im not sure how what to require here but ill make section1 mandatory for now
          if ($scope.isProtocol) {
            $scope.newProcessData.process_type = 'protocol';
            $scope.saveDataProtocol();
          }else{
            $scope.saveDataLegacyProcess();
          }

        }


      };


      $scope.saveDataLegacyProcess = function () {
        console.log('Process data from dialog', $scope.newProcessData);
        parentRet.loading = true;

        let process_data_to_store = completeObject(_.cloneDeep($scope.newProcessData));


        console.log('Completed data', process_data_to_store);

        let url = 'api/dashboard/processes/';
        let http_method = $http.post;
        // $scope.coms.post = true;
        if ($scope.operation === 'edit') {
          http_method = $http.patch;
          if (!process_data_to_store.id) {
            console.error('ID required to edit a process');
            parentRet.result = 'error';
            parentRet.loading = false;
            return;
          }
          url += process_data_to_store.id + '/';
          $scope.coms.post = false;
        }
        $scope.data_changed = true;
        // $rootScope.WGProcesses.changed = true;
        http_method<IProcess>(url, process_data_to_store).then(
            function (response) { // SUCCESS
              // $rootScope.WGProcesses.changed = true;
              // $rootScope.WGUnits.changed = true;
              // $rootScope.WGProcesses.changed_locally = true;

              console.log("PROCESS Http SUCCESS:", $scope.operation,
                  "New process data: ", response.data);

              // FEEDBACK
              parentRet.loading = false;
              parentRet.result = 'success';
              // reload();

              if ($scope.selected.unit) {
                $rootScope.WGUnits.processing = true;

              }
              $rootScope.WGProcesses.merge_entry(response.data, false);


              $scope.newProcessData.id = response.data.id;
              $scope.newProcessData.active = response.data.active;

              // ngDialog.close(0);
              if ($scope.operation === 'create') {
                $scope.coms.process = true;

                // Add the new process to the selected unit/s
                if ($scope.selected.unit) {
                  if ($scope.selected.unit.length) { // Array, therefore multiple units were passed
                    if (WG_debug) console.error("Multiple Units associated with the same process?!?");
                    $scope.newProcessData.units = [];
                    angular.forEach($scope.selected.unit, function (unit) {
                      setProcessAtUnitAPI($scope.newProcessData.id, unit.id, true);
                      $scope.newProcessData.units.push(unit);
                    });
                  } else {
                    setProcessAtUnitAPI($scope.newProcessData.id, $scope.selected.unit.id, true);
                    $scope.newProcessData.units = [$scope.selected.unit];
                  }
                }
              }

              // Exit the modal after a successful save:
              if (!parentRet.loading) {
                $timeout(function () {
                  $scope.cancel(0);
                }, 600);
              }
            }, function (response) { // ERROR
              console.error(response);
              parentRet.result = 'error';
              parentRet.loading = false;
              $scope.coms.process = false;

            }
        ).finally(() => {
          $rootScope.WGApiData.update_changed_data_soon();
          parentRet.loading = false;
        });
      }

      $scope.saveDataProtocol = async function () {
        parentRet.loading = true;

        let url = 'api/dashboard/winemaking/protocol/';
        let http_method = $http.post;
        // $scope.coms.post = true;
        if ($scope.operation === 'edit' && $scope.newProcessData.protocol?.id) {
          http_method = $http.patch;
          url += $scope.newProcessData.protocol.id + '/';

        }

        $scope.data_changed = true;
        $rootScope.WGApiData.WGUnits.changed = true;

        http_method(url, {
          template: $scope.newProcessData.protocol_template.id,
          name: $scope.newProcessData.name,
          unit: $scope.selected.unit.id,
          initial_weight: $scope.newProcessData.protocol?.initial_weight,
          initial_volume: $scope.newProcessData.protocol?.initial_volume,
          batch: $scope.newProcessData.batch?.id,
        }).then((response) => {

          if (!_.isNil($scope.newProcessData.ended_at)) {
            if (WG_debug) console.log("Given stop date. Stopping protocol");
            $rootScope.doStopProtocol($scope.newProcessData.protocol.id || $scope.selected.unit);
          }

          parentRet.loading = false;
          if (!parentRet.loading) {
            $timeout(function () {
              $scope.cancel(0);
            }, 600);
          }
        }).finally( () => {
          $rootScope.WGApiData.update_changed_data();
          parentRet.loading = false;
        });
      }


      //                                          ---     FUNCTIONS
      let passable = {
        // New devices were created and added to this unit and process in the modal.
        push_object: function (whichType, what, device_to_add) {
          if (whichType === 'unit') {
            // Check if there was a device added to the unit
            if (device_to_add) {
              what.devices = [];
              what.devices[0] = device_to_add;
            } else {
              what.devices = null;
            }
            // push or update unit
            let w = _.findIndex($scope.units, {id: what.id});
            if (w < 0)
              $scope.units.push(what);
            else
              $scope.units[w] = what;

            // update listing + set as selected & validate section
            $scope.units = sort_filter_count_units($scope.units);
            $scope.selected.unit = what;
            $scope.validate.section2();

          } else if (whichType === 'batch') {
            $scope.batches.push(what);
            $scope.batches = _.sortBy($scope.batches, 'name');
            $scope.selected.batch = what;
            $scope.changedSelectedBatch();
            $scope.validate.section4();
          } else {
            console.log("error#3499098989884");
          }
        }
      };

      function completeObject(data) {
        let d = data;

        //  -   Pass only editable fields if is EDIT
        if ($scope.operation === 'edit') {
          d = {};
          let editable_fields = [
            "name", "started_at", "ended_at", "batch", "content_type", "quantity", "description", "dynamic_fields"
          ];
          _.forEach(data, function (value, key) {
            if (_.indexOf(editable_fields, key) !== -1) {
              if (key === "batch") {
                if ((value && !initial_data[key])
                    || (!value && initial_data[key])
                    || (value && initial_data[key] && value.id !== initial_data[key].id)) {
                  // Batch was changed
                  d[key] = value;
                }
              } else if (key === "dynamic_fields") {
                if ((value && Object.keys(value).length && !initial_data[key])
                    || (!value && initial_data[key] && Object.keys(initial_data[key]).length)
                    || !_.isEqual(JSON.stringify(value), JSON.stringify(initial_data[key]))) {
                  // dynamic_fields was changed
                  d[key] = value;
                }
              } else if (!_.isEqual(value, initial_data[key])) {
                d[key] = value;
              }
            }
          });
          d.id = data.id;
          console.log("Saving Edits to process.",
              "wholeNewData:", data,
              "changedData?:", d);
        }
        data = d;

        //  -   Check ENDED_AT
        let haveStart = !_.isNil($scope.newProcessData.started_at);
        let haveEnd = !_.isNil($scope.newProcessData.ended_at);

        // if (haveStart && haveEnd && $scope.dates.is_ended === true) {
        //   data.ended_at = $scope.newProcessData.ended_at;
        // } else {
        //   delete data.ended_at;
        // }
        data.active = !haveEnd;

        //  -   Check for other in PROCESS TYPE
        if (data.process_type === 'other') {
          data.process_type = data.type_other;
          delete data.type_other
        }

        //  -   Check for DYNAMIC FIELDS
        if (!angular.isDefined(data.dynamic_fields)) {
          data.dynamic_fields = {};
        }

        ////////////////////set units in array////////////////////////
        // if (!angular.isDefined(data.units)) {
        //   data.units = $scope.ngDialogData.units;
        // }
        // console.log(data.units, $scope.ngDialogData.units, "complete data set units")

        return data;
      }

      function initialize() {
        // console.log("Initializing modal: Edit Process");

        // ---- Initialize variables

        //- for user feedback on API coms
        $scope.coms = {process: null, unit: null};


        // ---- Pre-select given variables according to operation

        $scope.operation = 'create';
        // ???? What? Devices in a process??
        $scope.newProcessData = {
          ended_at: null,
          started_at: new Date(),
        };


        if ($scope.ngDialogData.operation === 'edit') {

          if (!_.isEmpty($scope.ngDialogData.process)) {  // Process is required to allow "Edit"
            $scope.operation = 'edit';
            $scope.isProtocol = false;
            initial_data = _.cloneDeep($scope.ngDialogData.process as Partial<IProcess|IWinemakingProtocol>);
          } else if (!_.isEmpty($scope.ngDialogData.protocol)) {// Process is required to allow "Edit"
            $scope.operation = 'edit';
            $scope.isProtocol = true;
            initial_data = _.cloneDeep($scope.ngDialogData.protocol as Partial<IProcess|IWinemakingProtocol>);
          }

          initial_data.started_at = new Date(initial_data.started_at);

          $scope.newProcessData = _.cloneDeep(initial_data);
          $scope.newProcessData.started_at = new Date($scope.newProcessData.started_at);

          if ($scope.newProcessData.process_type == 'protocol') {
            $scope.isProtocol = true
          } else {
            $scope.isProtocol = false
          }

          //  TYPE
          if (Object.values($scope.processesTypes).indexOf($scope.newProcessData.process_type) === -1) {
            $scope.newProcessData.type_other = $scope.newProcessData.process_type;
            $scope.newProcessData.process_type = 'other';
          }
          //  TIME-SPAN
          if ($scope.newProcessData.ended_at !== null) {
            $scope.dates.is_ended = true;
            // $scope.dates.block = true;
          }
        }

        let _unit = null;

        // Unit passed via process
        if (!_unit && $scope.newProcessData.unit) {
          _unit = $scope.newProcessData.unit;
        }
        // Unit passed via process.units
        if (!_unit && !_.isEmpty($scope.newProcessData.units)) {
          _unit = $scope.newProcessData.units[0];
          if ($rootScope.WGUnits.units_id[_unit?.id]) {
            _unit = $rootScope.WGUnits.units_id[_unit.id];
          } else if (_unit['history_id']) {
            if (_unit.devices?.[0]?.id > 0 && $rootScope.WGDevices.devices) {
              let _device = $rootScope.WGDevices.devices_id[_unit.devices[0].id];
              if (_device?.unit?.id > 0) {
                _unit = $rootScope.WGUnits.units_id[_device.unit.id];
              } else {
                if (WG_debug) console.log("All process.units are history units and not still present");
              }
            }
          }
        }

        // UNIT passed explicitly
        if (!_unit && $scope.ngDialogData.unit) {
          _unit = $scope.ngDialogData.unit;
        }
        if (_unit) {
          $scope.selected.unit = _unit;
          if (_unit.id > 0) {
            $scope.unitsBlock = true;
          }
        }

        // DEVICE: Check if passed units has devices associated, pre-filling them.
        if (_unit?.devices?.length) {
          $scope.selected.devices = _unit.devices;
          // if ($scope.selected.unit.id < 0) {
          //   // Device selected from virtual-unit. Block it and show only units without devices
          //   $scope.orphanDeviceBlock = true;
          // }
        }

        // This is never true while we accept virtual units in the previous check. It's here to avoid future problems
        if (!$scope.selected.devices && $scope.ngDialogData.unit?.devices?.length) {
          $scope.selected.devices = $scope.ngDialogData.unit.devices;
        }

        //  BATCH
        if ($scope.newProcessData.batch) {
          $scope.selected.batch = $scope.newProcessData.batch;
          if ($scope.operation === 'edit') {
            $scope.batchBlock = true;
          }
        }
        if ($scope.ngDialogData.batch  // batch passed
            || ($scope.ngDialogData.hasSmtg && $scope.ngDialogData.hasWhat === 'batch')) {
          $scope.selected.batch = $scope.ngDialogData.batch;
          $scope.changedSelectedBatch();
          if ($scope.operation === 'edit') {
            $scope.batchBlock = true;
          }
        }

        if ($scope.operation === 'create') {
          $scope.dates.is_ended = false;
        }

        //  To create processes with predetermined batch or unit

        // if ($scope.operation === 'create')
        //   $scope.newProcessData.started_at = $scope.dates.getNow();


        // if ($scope.operation !== 'edit') {
        //   // We now allow to change Unit/Batch on a running process if it doesn't already have one
        //   // getUnits();
        //   getBatches();
        // }
        getUnits();
        getBatches();

        // if ($scope.isProtocol) $scope.get_protocolTemplatesList();
        $scope.get_protocolTemplatesList();

        // if ($scope.isProtocol && $scope.operation === 'edit') $scope.get_protocolsList();
        if ($scope.operation === 'edit') $scope.get_protocolsList();


        // Validate sections after initializing
        $scope.validate.section1();
        $scope.validate.section2();
        $scope.validate.section3();
        $scope.validate.section4();


      }

      initialize();
//      ---     UI utils
//      ---     DEV UTIL
//  FORCED

      $scope.spitData = function () {
        console.log("SCOPE", $scope);
      };
    }

  ])
  ;
}