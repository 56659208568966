namespace wg {

  // This is the controller for the modal that allows the user to create or edit a protocol
  // If $scope.ngDialogData.protocol is defined, the user is editing an existing protocol
  // Otherwise, the user is creating a new protocol
  export class CreateOrEditProtocol {

    public orig_protocol: IWinemakingProtocol;
    public protocol: IWinemakingProtocol;
    public WINE_TYPES = wg.WINE_TYPES;
    //if true, we are creating a new protocol, if false, we are editing an existing one
    public creationMode: boolean = true;

    public formErrors: {} = null;
    public responseError: string = null;
    public loading: boolean = false;

    static $inject = ['ngDialog', '$scope', '$http', 'AuthService', '$translate'];

    constructor(private ngDialog: any, private $scope: any, private $http: ng.IHttpService, private AuthService: IAuthService, private $translate: ng.translate.ITranslateService) {
      if (this.$scope.ngDialogData?.protocol) {
        //editing
        this.orig_protocol = this.$scope.ngDialogData.protocol;
        this.protocol = _.cloneDeep(this.$scope.ngDialogData.protocol);
        this.creationMode = false;
        this.shared.sharing_code_displayed = _.isEmpty(this.protocol.sharing_codes) ? null : _.join(this.protocol.sharing_codes, ', ');
        if(_.isNil(this.shared.sharing_code_displayed))
          this.shared.sharing_code_displayed = _.isEmpty(this.protocol.sharing_code) ? null : _.join(this.protocol.sharing_code, ', ');
      }
    }

    public $onInit() {

    }

    public async save() {

      const errors = this.validateForm();
      if (Object.keys(errors).length > 0) {
        return;
      }

      this.loading = true;
      let api_protocol = _.pick(this.protocol, ['id', 'name', 'wine_type_id', 'wine_type_other', "description"]);
      if (api_protocol.wine_type_id != WINE_TYPES.other.id) {
        api_protocol.wine_type_other = null;
      }
      if (this.creationMode) {
        this.$http.post(apiUrls.protocolTemplateURL, api_protocol)
            .then((response: any) => {
              console.log('Created protocol', response.data);
              this.$scope.confirm(response.data);
            })
            .catch((response: any) => {
              const errorCode = response.status
              this.closeWithError("There was an error communicating with the server.\n Error code = " + errorCode);
            }).finally(() => {
          this.loading = false;
        });
      } else {
        this.$http.patch(apiUrls.protocolTemplateURL + this.protocol.id + '/', api_protocol)
            .then((response: any) => {
              console.log('Updated protocol', response.data);
              this.$scope.confirm(response.data);
            })
            .catch((response: any) => {
              const errorCode = response.status
              this.closeWithError("There was an error communicating with the server.\n Error code = " + errorCode);
            }).finally(() => {
          this.loading = false;
        });
      }
    }

    private shared = {
      loading: false,
      sharing_code_displayed: null,
    };

    private generateSharingCode() {
      this.shared.loading = true;
      let data = {"protocol_templates": [this.protocol.id,], "owner": this.AuthService.user.id}
      this.$http.post<{ sharing_code: string }>('api/dashboard/winemaking/protocol_template_share/', data)
          .then((response) => {
            if (response.data?.sharing_code) {
              if (_.isNil(this.protocol.sharing_codes)) this.protocol.sharing_codes = [];
              this.protocol.sharing_codes.push(response.data.sharing_code);
              // Also add to the original protocol instance
              if (_.isNil(this.orig_protocol.sharing_codes)) this.orig_protocol.sharing_codes = [];
              this.orig_protocol.sharing_codes.push(response.data.sharing_code);
              // Edit the
              console.log('Generated sharing_code', response.data);
            } else {
              console.error('No sharing_code in response!', response);
            }
            this.shared.sharing_code_displayed = _.isEmpty(this.protocol.sharing_codes) ? null : _.join(this.protocol.sharing_codes, ', ');
            if(_.isNil(this.shared.sharing_code_displayed))
              this.shared.sharing_code_displayed = _.isEmpty(this.protocol.sharing_code) ? null : _.join(this.protocol.sharing_code, ', ');
          })
          .catch((response) => {
            console.error('Error generating sharing_code', response);
          })
          .finally(() => {
            this.shared.loading = false;
          });
    }

    public closeWithError(error: string) {
      this.responseError = error;
      setTimeout(() => {
        //close the dialog
        this.ngDialog.close();
      }, 5000);

    }


    public cancel() {
      this.ngDialog.close()
    }


    public validateForm() {

      this.formErrors = {};
      if (!this.protocol) this.protocol = {} as IWinemakingProtocol;

      if (!this.protocol.name || this.protocol.name.length == 0) {
        this.formErrors["name"] = [this.$translate.instant("app.error.REQUIRED")];
      }

      if (this.protocol.description?.length > 2048) {
        this.formErrors["description"] = [this.$translate.instant("app.error.TEXT_TOO_LONG")];
      }

      return this.formErrors;
    }
  }

  App.controller('CreateOrEditProtocol', CreateOrEditProtocol);
}