namespace wg {

  export class DistributorPickerController {
    static $inject = ['AuthService', '$scope', '$rootScope'];


    public loading: boolean = true;

    public distributors: any[] = [];

    public onChangeCallback: Function;

    //array of distributors we want to edit or create
    public distributorBinding: IDistributor[];
    //current distributor the dropdown is showing
    public selectedDistributor: IDistributor;

    public isMultipleValue: boolean; //if the incoming binding is an array with more than one different element

    constructor(private AuthService: IAuthService, private $scope: any, private $rootScope: any) {
      this.onMultipleValuesPreventionOverride = this.onMultipleValuesPreventionOverride.bind(this);
    }


    $onInit() {

      // //get the list of distributors
      this.$rootScope.$watch('AuthService.shared_owners', (newVal) => {
        if (WG_debug) console.log("WG_DistributorPicker shared_owners available");
        if (newVal) {
          this.distributors.push(...
              this.AuthService.shared_owners
                  .filter((owner) => owner.role == 'distributor' && owner.id > 0)
                  .map((owner) => {
                    return {username: owner.username, id: owner.id, role: 'distributor'}
                  })
          )

          if (this.distributors.length > 0) {
            this.selectedDistributor = _.find(this.distributors, {id: this.selectedDistributor?.id}) || this.distributors?.[0];

            _.forEach(this.distributorBinding, (distributor) => {
              if (_.isEmpty(distributor)) return;
              // find the distributor with the same id
              let _distributor = _.find(this.distributors, {id: distributor.id});
              if (_distributor) {
                distributor.id = _distributor.id;
                distributor.username = _distributor.username;
              } else {
                // q: is it safe to remove an array element while iterating over it using _.forEach?
                // a: yes, it is safe. _.forEach iterates over a shallow copy of the array
                _.remove(this.distributorBinding, (d) => d.id === distributor.id);
              }
            })
          }

          this.loading = false;
        }
      }, true);


      // console.log("this distributor binding" + this.distributorBinding)
      //
      // //if nothing is passed, default to the first distributor
      // if(!this.distributorBinding) this.distributorBinding = [{
      //     username: this.distributors[0].label,
      //     id: this.distributors[0].value
      // }]
      //
      //
      // //sync up whatever is in the binding with the selected distributor
      // this.selectedDistributor = {
      //     username: this.distributorBinding[0].username,
      //     id: this.distributorBinding[0].id
      // }

      if (WG_debug) console.log("onInit WG_DistributorPicker");
    }

    public onSelectedDistributorChange = (user) => {
      if (WG_debug) console.log("WG_DistributorPicker onSelectedDistributorChange");

      if (this.onChangeCallback) {
        this.onChangeCallback({user: user});
      }

      this.selectedDistributor = {
        username: user.username,
        id: user.id,
        role: 'distributor'
      }

      this.distributorBinding.forEach((distributor) => {
        distributor.username = user.username;
        distributor.id = user.id;
      })
    }


    public onMultipleValuesPreventionOverride(key: any) {

      this.loading = true; //the component will be disabled until the distributors are loaded in the interval below

      const controller = this;

      //if distributors was not yet populated, we wait
      const intervalRef = setInterval(() => {
        if (controller.distributors.length > 0) {
          controller.distributorBinding.forEach((distributor) => {
            distributor.username = controller.distributors[0].label;
            distributor.id = controller.distributors[0].value;
          })
          controller.isMultipleValue = false;
          controller.loading = false;
          //tell angular to update the view
          controller.$scope.$apply();

          clearInterval(intervalRef);
        }
      }, 500);


    }

  }

  App.component('wgDistributorPicker', {
    controller: DistributorPickerController,
    templateUrl: "app/views/directives/wg-distributor-picker.html",
    controllerAs: 'ctrl',
    bindings: {
      distributorBinding: '=',
      selectedDistributor: '=',
      onChangeCallback: '&',
    },
    require: {}

  })
}
