/**=========================================================
 * Module: sidebar-menu.js
 * Handle sidebar collapsible elements
 =========================================================*/
namespace wg {
  App.controller('SidebarController', ['$rootScope', '$scope', '$state', '$http', '$timeout', 'Utils', 'AuthService',
    function ($rootScope: IRootScope, $scope, $state: _IState, $http: ng.IHttpService, $timeout: ng.ITimeoutService, Utils, AuthService: IAuthService) {
      $rootScope.menuItems = $rootScope.menuItems || [];

      var collapseList = [];
      // demo: when switch from collapse to hover, close all items
      $rootScope.$watch('app.layout.asideHover', function (oldVal, newVal) {
        if (newVal === false && oldVal === true) {
          closeAllBut(-1);
        }
      });
      // Check item and children active state
      var isActive = function (item) {
        if (!item)
          return;
        if (!item.sref || item.sref == '#') {
          var foundActive = false;
          angular.forEach(item.submenu, function (value, key) {
            if (isActive(value))
              foundActive = true;
          });
          return foundActive;
        } else {
          if (item.duplicate && $state.is(item.sref) && $state.params && item.params) {
            console.warn('Dangerous compare of arbitrary params');
            return angular.equals($state.params, item.params);
          }
          return $state.is(item.sref) || $state.includes(item.sref);
        }
      };
      // Load menu from json file
      // -----------------------------------
      $scope.getMenuItemPropClasses = function (item) {
        return (item.heading ? 'nav-heading' : '') +
            (isActive(item) ? ' active' : '');
      };

      $scope.loadSidebarMenu = function () {
        let menuJson = window['WG_sidebar_menu'] || 'server/sidebar-menu.json';
        // let menuURL = menuJson;
        let menuURL  = menuJson + '?v=' + WG_version; // jumps cache
        $http.get(menuURL).then(
            function (items: ng.IHttpPromiseCallbackArg<any[]>) {
              // $rootScope.menuItems = items;
              // Replacing current list with an inline-merge, as to not cause refresh on angular
              if (WG_debug) console.info('Loading menu', $rootScope.menuItems, items.data);
              synchronizeArrayOfObjects($rootScope.menuItems, items.data, 'text');
            }, function (data) {
              console.error('Failure loading menu', data);
            });
      };
      $scope.loadSidebarMenu();
      // Handle sidebar collapse items
      // -----------------------------------
      $scope.addCollapse = function ($index, item) {
        collapseList[$index] = $rootScope.app.layout.asideHover ? true : !isActive(item);
      };
      $scope.isCollapse = function ($index) {
        return (collapseList[$index]);
      };
      $scope.toggleCollapse = function ($index, isParentItem) {
        // collapsed sidebar doesn't toggle drodopwn
        if (Utils.isSidebarCollapsed() || $rootScope.app.layout.asideHover)
          return;
        // make sure the item index exists
        if (angular.isDefined(collapseList[$index])) {
          if (!$scope.lastEventFromChild) {
            collapseList[$index] = !collapseList[$index];
            closeAllBut($index);
          }
        } else if (isParentItem) {
          closeAllBut(-1);
        }
        $scope.lastEventFromChild = isChild($index);
        return;
      };

      function closeAllBut(index) {
        index += '';
        for (var i in collapseList) {
          if (index < 0 || index.indexOf(i) < 0)
            collapseList[i] = true;
        }
      }

      function isChild($index) {
        return (typeof $index === 'string') && !($index.indexOf('-') < 0);
      }

      // $scope.canSee = function (item) {
      //   if (!AuthService.canAccess(item.accessLevel)) {
      //     return false;
      //   }
      //   return true;
      //   // let accessible = _.every(_.split(item.accessLevel, '|'), function (level) {
      //   //   if(WG_debug) console.log("testing with:", level, item);
      //   //   return AuthService.canAccess(level || 'admin');
      //   // });
      //   // return accessible;
      // };
    }]);
}
