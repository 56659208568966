namespace wg {
    const DAY_STEP = 1;
    const HOUR_STEP = 1;
    const MINUTE_STEP = 15;

    export class WgTimepicker {

        public ngModel: any;

        public days;
        public hours;
        public minutes;


        constructor() {
        }

        $onInit() {
            if (!this.ngModel) {
                this.ngModel = 0;
            } else {
                this.days = Math.floor(this.ngModel / 86400);
                this.hours = Math.floor((this.ngModel % 86400) / 3600);
                this.minutes = Math.floor(((this.ngModel % 86400) % 3600) / 60);
            }
        }

        public changeDays(direction: "up" | "down") {
            //if not initialized
            if(!this.days) this.days = 0;

            if (direction == "up") {
                if (this.days < 99) {
                    this.days++;
                }
            } else {
                if (this.days > 0) {
                    this.days--;
                }
            }
            this.updateModel();
        }

        public changeHours(direction: "up" | "down") {

            //if not initialized
            if(!this.hours) this.hours = 0;

            if (direction == "up") {
                this.hours += HOUR_STEP;
            } else {
                this.hours -= HOUR_STEP;
            }

            if (this.hours < 0) {
                this.hours = 24;

            }else if (this.hours > 24) {
                this.hours = 0;

            }
            this.updateModel();
        }

        public changeMinutes(direction: "up" | "down") {

            //if not initialized
            if(!this.minutes) this.minutes = 0;

            if (direction == "up") {
                this.minutes += MINUTE_STEP;
            } else {
                this.minutes -= MINUTE_STEP;
            }

            // check if overflow
            if (this.minutes < 0) {
                this.minutes = 60;

            }else if (this.minutes >= 60) {
                this.minutes = 0;

            }

            this.updateModel();
        }


        public updateModel() {

            let days = this.days || 0;
            let hours = this.hours || 0;
            let minutes = this.minutes || 0;
            // ng model is in seconds
            this.ngModel = (days * 86400) + (hours * 3600) + (minutes * 60);
        }

    }

    App.component('wgTimepicker', {
        controller: WgTimepicker as any,
        controllerAs: 'ctrl',
        bindings: {
            ngModel: '='
        },

        templateUrl: 'app/views/wg-timepicker.html'
    });
}