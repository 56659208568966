

namespace wg{


    //use this to setup tests and avoid diving into menus and submenus to see changes
    class testBed{

        static $inject = ['ngDialog','moment'];

        public protocolCtrl:WgProtocolDetailed = {} as WgProtocolDetailed;

        constructor(private ngDialog:ng.dialog.IDialogService,private moment:any){
        }

        $onInit() {


            // console.log(this.protocol);
            //testing createOrEditTask modal
            // this.ngDialog.open({
            //     template:"app/views/modals/create-or-edit-task.html",
            //     controller: ['$scope', 'ngDialog', function ($scope, ngDialog) {
            //
            //         $scope.task = {
            //             name:"first task",
            //             type:"Action",
            //             recipeNotes:"Some sample notes",
            //             rules:[
            //                 {
            //                     type:RuleType.after_stage_start,
            //                     delay:(new Date(0,0,0,0,0,0,0))
            //                 }
            //             ]
            //         }
            //         $scope.stage = {
            //             name:"Reception",
            //             tasks:[$scope.task]
            //         }
            //
            //     }]
            // })
            }
        }

    App.component("testBed",{
        templateUrl:"app/views/test-bed.html",
        transclude:true,
        controller:testBed as any,
        controllerAs:"ctrl",

    })

}



