/**=========================================================
 * Module: edit-units-multiple.js
 * Modal controller for single/multiple Units Move
 =========================================================*/
namespace wg {
  App.controller('editUnitsMultipleController', ['$rootScope', '$scope', '$http', 'ngDialog', '$timeout', 'WGApiData', 'APIUtils',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, ngDialog: ng.dialog.IDialogService, $timeout: ng.ITimeoutService, WGApiData: WGApiData, APIUtils: APIUtils) {

      let parentRet: IReturnResult = {
        loading: false,
        loading_ids: {},
        result: '',
        message: '',
      };
      $scope.ret = parentRet;

      let ret_key = $scope.ngDialogData?.result || 'unitsMultiple';
      if ($scope.$parent?.[ret_key]) {
        $scope.$parent[ret_key] = parentRet;
      } else if ($scope.$parent?.$parent?.[ret_key]) {
        $scope.$parent.$parent[ret_key] = parentRet;
      }

      // Used for loader when saving connection configs
      $scope.editConnectionRet = {
        loading: false,
        result: '',
        message: '',
      } as IReturnResult;

      //            --  UI
      $scope.changedInputs = {};

      $scope.checkChanges = {
        exist: () => {
          for (let unit of $scope.units) {
            if (!unit)
              continue;
            if (unit.id < 0) {
              return true;
            }
            if (unit.place?.id != $scope.selected.place?.id) {
              // Should update unit's place
              return true;
            }
          }
          return false;
        },
        areValid: () => {
          return ($scope.selected.place?.id > 0);
        },
        canDelete: () => {
          let has_virtual_units = false;
          _.forEach($scope.units, (unit) => {
            if (unit && unit.id < 0) {
              has_virtual_units = true;
              return false;
            }
          });
          return !has_virtual_units;
        },
      };

      //                              -- SELECTED
      $scope.selected = {
        place: null,
      };

      //                        --- PLACES

      $scope.changedSelectedPlace = () => {
        if ($scope.selected.place.id) {
          $scope.changedInputs['place'] = true;
        }
      };

      // Show all existing places and allow creating a new one
      $scope.places = null;
      let getPlaces = () => {
        let _places = _.map(WGApiData.WGPlaces.places, item => _.pick(item, ['id', 'name', 'description', 'units']));
        _places = _.filter(_places, _place => (_place.id > 0));
        $scope.places = _.sortBy(_places, 'name');

        if ($scope.selected.place?.id) {
          $scope.selected.place = _.find($scope.places, {id: $scope.selected.place.id}) || $scope.selected.place;
        }
      }

      let newPlaceModalIsOpen = false;
      $scope.newPlace = () => {
        if (newPlaceModalIsOpen) {
          console.log("attempt to open second newPlace modal");
          return;
        }
        newPlaceModalIsOpen = true;

        ngDialog.openConfirm({
          template: 'app/views/modals/edit-dialog-places.html',
          data: {
            action: 'create',
          }
        }).then((result) => {
          console.log("Place created.", result);
          newPlaceModalIsOpen = false;
          let new_place = result?.place || result?.item_data || result?.value?.item_data;
          if (new_place) {
            new_place = _.pick(new_place, ['id', 'name']);
            $scope.selected.place = new_place;
            insertSortedByInPlace($scope.places, new_place, ['name']);

            $scope.changedSelectedPlace();
          }
        }, (reason) => {
          console.error("Error creating place.", reason);
        });
      };


      //                                  --- DEVICES

      let timeout_exit_if_success: angular.IPromise<any>;
      let schedule_exit_if_success = (exit_now_if_done = true, timeout = 2000) => {
        // delete all parentRet.loading_ids that have result == 'success'
        // for (let key in parentRet.loading_ids) {
        //   if (parentRet.loading_ids[key].result == 'success') {
        //     delete parentRet.loading_ids[key];
        //   }
        // }
        $timeout.cancel(timeout_exit_if_success);
        if (exit_now_if_done && _.isEmpty(parentRet.loading_ids)) {
          if (WG_debug) console.log("schedule_exit_if_success: All done loading");
          exit_if_success();
        } else {
          timeout_exit_if_success = $timeout(exit_if_success, timeout);
        }
      }

      let exit_if_success = () => {
        if (WG_debug) console.log("exit_if_success: All done loading");
        $timeout.cancel(timeout_exit_if_success);
        parentRet.loading = false;
        if (parentRet.result != 'success') {
          return;
        }
        parentRet.result = 'success';
        WGApiData.process_data();
        WGApiData.update_changed_data();

        $timeout(() => {
          $scope.close()
        }, 500);
      }

      //                              --- ACTIONS : SAVE // CLOSE

      $scope.cancel = () => {
        parentRet.loading = false;
        parentRet.result = '';
        parentRet.message = 'cancel';

        $scope.closeThisDialog();
      };

      $scope.saveData = () => {
        if (!$scope.checkChanges.areValid()) {
          console.error('Invalid data!', $scope.selected);
          parentRet.loading = false;
          parentRet.result = 'error';
          return;
        }

        // For every Unit with negative ID:
        //   Create it on selected Place (Post)
        //   Add it to Device (Put)
        // else, For every Unit with wrong Place
        //   Edit the Place (Put)


        parentRet.loading_ids = emptyOrCreateDict(parentRet.loading_ids);
        parentRet.loading = true;
        parentRet.result = 'success';

        schedule_exit_if_success(false);
        _.forEach($scope.units, (unit: IUnit) => {
          let current_place = unit.place;
          if (unit.id < 0) {
            let _device = unit.devices?.[0];
            if (!_device) {
              console.error("Missing device to move virtual unit", unit);
              return;
            }

            parentRet.loading_ids[_device.id] = true;

            let data_to_send = {
              name: unit.name,
              place_id: $scope.selected.place.id,
              unit_type: _device.unit_type || "vat",
            };
            WGApiData.process_data_soon(3000);
            $http.post('api/dashboard/units/', data_to_send).then(
                (response: ng.IHttpPromiseCallbackArg<IUnit>) => { // onSuccess
                  let new_unit = response.data;
                  if (_device.id && new_unit?.id) {
                    APIUtils.setUnitAtDevice(_device.id, response.data.id, null,
                        () => {
                          delete parentRet.loading_ids[_device.id];
                          WGApiData.process_data_soon(2000);
                          schedule_exit_if_success(true);
                        },
                        () => {
                          parentRet.result = 'error';
                          delete parentRet.loading_ids[_device.id];
                          schedule_exit_if_success(true);
                        });
                    // setUnitAtDeviceAPI(_device.id, new_unit?.id, parentRet.loading_ids[_device.id]);
                  }
                  WGApiData.WGPlaces.changed_locally = true;
                  _.remove(current_place.units, {id: unit.id}); // Try to immediately remove the previous virtual unit from current place
                  WGApiData.WGUnits.delete_local(unit.id); // delete virtual unit

                  WGApiData.WGUnits.merge_entry(new_unit, true);

                  if (WG_debug) console.log("Unit created", response);
                },
                (reason) => {
                  console.error("HTTP ERROR. reason", reason);
                  parentRet.result = 'error';
                  delete parentRet.loading_ids[_device.id];
                })
                .finally(() => {
                  WGApiData.process_data_soon(2000);
                  schedule_exit_if_success(true);
                });

          } else {
            let data_to_send = {
              place_id: $scope.selected.place.id,
            };

            parentRet.loading_ids[unit.id] = true;
            WGApiData.process_data_soon(3000);
            $http.patch('api/dashboard/units/' + unit.id + '/', data_to_send).then(
                (response: ng.IHttpPromiseCallbackArg<IUnit>) => { // onSuccess
                  WGApiData.WGPlaces.changed_locally = true;
                  _.remove(current_place.units, {id: unit.id}); // Try to immediately remove virtual unit from current place
                  WGApiData.WGUnits.merge_entry(response.data, true);

                  if (WG_debug) console.log("Unit moved", response);
                },
                (reason) => {
                  console.error("HTTP ERROR. reason", reason);
                  parentRet.result = 'error';
                })
                .finally(() => {
                  delete parentRet.loading_ids[unit.id];
                  WGApiData.process_data_soon(2000);
                  schedule_exit_if_success(true);
                });

          }
        });

      };


      let deleteConfirmIsOpen = false;
      //                              --- ACTIONS : SAVE // DELETE // CLOSE
      $scope.deleteAll = () => {
        if (!deleteConfirmIsOpen) {
          deleteConfirmIsOpen = true;
        } else {
          return
        }

        ngDialog.openConfirm({
              template: 'app/views/modals/confirm-danger.html',
              className: 'ngdialog-theme-default',
              data: {
                what: 'units',
                which: _.join(_.map($scope.units, 'name'), ', '),
              }
            }
        ).then(
            (data) => {
              console.log('Delete UNITs, confirmed', data);
              parentRet.loading = true;
              parentRet.result = 'success';
              parentRet.loading_ids = emptyOrCreateDict(parentRet.loading_ids);

              _.forEach($scope.units, (unit: IUnit) => {
                if (!unit || unit.id < 0)
                  return
                parentRet.loading_ids[unit.id] = true;
                schedule_exit_if_success();
                WGApiData.WGUnits.delete(unit.id, (result) => {
                  if (result == 'error') {
                    parentRet.result = 'error';
                  }
                  delete parentRet.loading_ids[unit.id];
                  schedule_exit_if_success();
                });
              });
              deleteConfirmIsOpen = false;
            }, (reason) => {
              deleteConfirmIsOpen = false;
              console.log('DELETE Modal promise rejected. Reason: ', reason);
            });
      };

      $scope.close = () => {
        parentRet.loading = false;

        let return_result = {};

        console.log("Close this dialog", return_result);

        if (parentRet.result === 'success' && $scope.confirm)
          $scope.confirm(return_result);
        else if ($scope.closeThisDialog)
          $scope.closeThisDialog(return_result);
        else
          $scope.close();
      };

      //                                  --- UTILS

      $scope.spitData = () => {
        spitData('edit-units-multiple', $scope, $scope.ngDialogData);
      };

      //-------------------------------------------- INIT------------------------------------------//

      function init() {
        console.log('Initing Unit Move');

        $scope.changedInputs = {};

        let _unhook_updatePlaces = $rootScope.$on('places_updated', () => {
          getPlaces();
        });
        getPlaces();

        $scope.$on('$destroy', () => {
          _unhook_updatePlaces();
        });

        $scope.units = [];

        if (!_.isEmpty($scope.ngDialogData.units)) {
          $scope.units = _.map($scope.ngDialogData.units, item => _.pick(item, ['id', 'name', 'type', 'devices', 'place.id']))
        }

        // If place is available, fill it.
        if ($scope.ngDialogData.place && $scope.ngDialogData.place?.id > 0) {
          $scope.selected.place = $scope.ngDialogData.place;
        }

        $scope.devices = WGApiData.extractDevices($scope.units);
      }

      init();
    }])
}