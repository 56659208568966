/**
 * Created by pmec on 20/11/15.
 */
namespace wg {
  App.controller('CrontabDyanmicModalInstance', ['$rootScope', '$scope', '$http', '$filter', '$timeout', 'ngDialog', 'moment',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $filter: ng.IFilterService, $timeout: ng.ITimeoutService, ngDialog: angular.dialog.IDialogService, moment) {

      var chart = new Highcharts.Chart({

        chart: {
          renderTo: 'container',
          animation: false
        },

        title: {
          text: 'Highcharts draggable points demo'
        },

        xAxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },

        plotOptions: {
          series: {
            point: {
              events: {
                drag: function (e) {
                  // Returning false stops the drag and drops. Example:
                  /*
                  if (e.newY > 300) {
                      this.y = 300;
                      return false;
                  }
                  */

                  $('#drag').html(
                      'Dragging <b>' + this.series.name + '</b>, <b>' + this.category + '</b> to <b>' + Highcharts.numberFormat(e.target.y, 2) + '</b>');
                },
                drop: function (e) {
                  $('#drop').html(
                      'In <b>' + this.series.name + '</b>, <b>' + this.category + '</b> was set to <b>' + Highcharts.numberFormat(e.target.y, 2) + '</b>');
                }
              }
            },
            // [ [millis, temp], [1620000, 26.0], [16210000, 26.5] ]
            // stickyTracking: false,
            findNearestPointBy: 'xy',
          },
          column: {
            stacking: 'normal'
          },
          line: {
            cursor: 'ns-resize'
          }
        },

        tooltip: {
          // @ts-ignore
          yDecimals: 2
        },

        series: [{
          data: [0, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
          //draggableX: true,
          // @ts-ignore
          draggableY: true,
          dragMinY: 0,
          type: 'column',
          // minPointLength: 2
        }, {
          data: [0, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4].reverse(),
          // @ts-ignore
          draggableY: true,
          dragMinY: 0,
          type: 'column',
          // minPointLength: 2
        }, {
          data: [0, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
          // @ts-ignore
          draggableY: true,
          type: 'column',
        }]

      });
    }]);
}