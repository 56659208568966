/**
 * Created by pmec on 09/09/15.
 */

/**
 * AngularJS default filter with the following expression:
 * "person in people | filter: {name: $select.search, age: $select.search}"
 * performs a AND between 'name: $select.search' and 'age: $select.search'.
 * We want to perform a OR.
 */
namespace wg {
  let normal_types = ['pressing', 'maturation', 'fermentation', 'ambient', 'storage'];

  App.filter('propsFilter', [function () {
    // https://gist.github.com/penguinboy/762197
    var flattenObject = function (ob, sep: String = '.') {
      // if (sep == null)
      //   sep = '.';
      var toReturn = {};

      for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) === 'object') {
          var flatObject = flattenObject(ob[i]);
          for (var x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;

            toReturn[i + sep + x] = flatObject[x];
          }
        } else {
          toReturn[i] = ob[i];
        }
      }
      return toReturn;
    };
    return function (items, props, specialProps, specialPropsValue) {
      if (specialProps != null && specialPropsValue != null) {
        if (angular.isArray(specialProps) && angular.isArray(specialPropsValue)) {
          if (specialProps.length === specialPropsValue.length) {
            for (var i = 0; i < specialProps.length; i++) {
              props[specialProps[i]] = specialPropsValue[i];
            }
          }
        }

      }
      var out = [];

      if (angular.isArray(items)) {
        items.forEach(function (item) {
          var itemMatches = false;

          var keys = Object.keys(props);
          for (var i = 0; i < keys.length; i++) {
            var prop = keys[i];
            var text = props[prop].toLowerCase();
            var flat = flattenObject(item);
            if (flat.hasOwnProperty(prop) && flat[prop].toString().toLowerCase().indexOf(text) !== -1) {
              itemMatches = true;
              break;
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    }
  }])
      .filter('timeInterval', ["$filter", function ($filter) {
        return function (interval_millis) {
          return Math.floor(interval_millis / 1000 / 3600).toString() + 'h:'
              + Math.floor((interval_millis / 1000) / 60 % 60).toString().padStart(2, '0') + 'm';
        };
      }])
      .filter('currentdate', ['$filter', function ($filter) {
        return function () {
          return $filter('date')(new Date(), 'dd-MM-yyyy');
        };
      }])
      .filter('currentdate_dm', ['$filter', function ($filter) {
        return function () {
          return $filter('date')(new Date(), 'd/MM');
        };
      }])
      .filter('procType', ["$filter", "$translate", function ($filter: ng.IFilterService, $translate: ng.translate.ITranslateService) {
        return function (type) {
          // let normal_types = ['pressing', 'maturation', 'fermentation', 'ambient', 'storage'];
          return _.includes(normal_types, type) ? $translate.instant('app.processes.TYPE_NAMES.' + type) : type;
        };
      }])
      .filter('byId', ["$filter", function ($filter) {
        return function (items, ids, isInclude) {
          ids.sort();
          return _.filter(
              items,
              function (item) {
                return isInclude ? _.indexOf(ids, item.id) > -1 : _.indexOf(ids, item.id) < 0;
              });
        };
      }])
      .filter('byIdAndName', ["$filter", function ($filter) {
        return function (items, ids, isInclude, search_string) {
          ids.sort();
          return _.filter(
              items,
              function (item) {
                return (isInclude ?
                            _.indexOf(ids, item.id) > -1 :
                            _.indexOf(ids, item.id) < 0
                    ) &&
                    _.includes(item.name, search_string);
              });
        };
      }])
      .filter('byIdNameProp', ["$filter", function ($filter) {
        return function (items, ids, isInclude, search_string, prop_name, prop_value) {
          ids.sort();
          return _.filter(
              items,
              function (item) {
                return (
                        isInclude ?
                            _.indexOf(ids, item.id) > -1 :
                            _.indexOf(ids, item.id) < 0
                    ) &&
                    _.includes(item.name, search_string) &&
                    (prop_value && prop_name ? item[prop_name] === prop_value : true);
              });
        };
      }])
      .filter('objLimitTo', [function () {
        return function (obj, limit) {
          let ret = {};
          if (limit === 0 || _.isEmpty(obj)) return ret;

          let keys = Object.keys(obj);
          if (keys.length < 1) return {};

          let count = 0;
          angular.forEach(keys, function (key, arrayIndex) {
            if (count >= limit) return false;
            ret[key] = obj[key];
            count++;
          });
          return ret;
        };
      }])
      .filter('exportDeviceFilter', ["$filter", function ($filter) {
        return function (devs, filter_obj) {
          // devs, ids, isInclude, term, prop_type, prop_value
          //(prop_value&&prop_name ? dev[prop_name]===prop_value : true)
          let prop_eval = false;
          // let normal_types = ['pressing', 'maturation', 'fermentation', 'ambient', 'storage'];

          return _.filter(
              devs,
              function (dev) {
                prop_eval = false;
                if (filter_obj.prop_type)
                  switch (filter_obj.prop_type) {
                    case 'proc_type':
                      // is in process of type prop_value
                      if (dev.unit && dev.unit.process && dev.unit.process.active)
                        prop_eval = dev.unit.process.process_type === filter_obj.prop_value;
                      break;
                    case 'has_process':
                      prop_eval = dev.unit && dev.unit.process && dev.unit.process.active;
                      break;
                    case 'has_unit':
                      prop_eval = dev.unit !== null;
                      break;
                    case 'all':
                      prop_eval = true;
                      break;
                    default:
                      console.log("error#489484894489");
                      prop_eval = false;
                      break;
                  }
                else
                  prop_eval = true;

                let term_eval =
                    _.includes(dev.name.toUpperCase(), filter_obj.term.toUpperCase()) ||
                    (dev.unit && _.includes(dev.unit.name.toUpperCase(), filter_obj.term.toUpperCase()));
                // return !_.includes(filter_obj.ids, dev.id) &&
                //   term_eval &&
                //   prop_eval
                return term_eval && prop_eval
              }
          );
        }
      }]);
}