namespace wg {

  const SENSORS_SUPPORTING_EQUAL = ['PRESS_MICRO_SWITCH'];
  const SENSORS_ONLY_SUPPORTING_EQUAL = ['WAKEUP_REASON', 'STATUS_TREAT'];

  export class WgAlarmRule {

    //@ts-ignore
    public allLogicalOptions: LogicalOptions[]
    public rule: IAlarmRule;
    public stage: IWinemakingStage;
    public ruleOwner: ITask | IAlarm; //the object that owns the rule
    public errors: Map<IAlarmRule, []>;
    public showErrors: boolean;
    public ruleOwnerType: "task" | "alarm"; //this specifies if the component is being to display rules for a task or an alarm
    public visibleRuleTypes: {};
    public sensors: ISensor[] = [];
    private sensor_selected: ISensor;
    public customParameters: ISensor[];
    public scope: any;

    static $inject = ['$scope', '$rootScope', 'DataUtils', '$translate', 'WGApiData'];

    public constructor(private $scope: any, private $rootScope, private DataUtils: DataUtils, private $translate: ng.translate.ITranslateService, private WGApiData: WGApiData) {
      if (WG_debug) console.log('WgAlarmRule constructor');
      this.visibleRuleTypes = this.ruleOwnerType == "task" ? wg.TASK_RULE_TYPES : wg.ALARM_RULE_TYPES;
      this.scope = $scope;


      this.$scope.$watch('ctrl.rule', (newVal, oldVal) => {
        this.checkForErrors();
      }, true);

      //listen for emit showErrors
      this.$rootScope.$on('showErrors', () => {
        this.showErrors = true;
      });

      this.$rootScope.$on('checkErrors', () => {
        this.checkForErrors();
      });

      if (this.customParameters?.length > 0) {
        this.sensors = this.customParameters;
      } else {
        this.sensors.push($rootScope.WGSensors.sensors_name['TEMP']);
        this.sensors.push($rootScope.WGSensors.sensors_name['LLV_TOF_TREAT_value']);
        this.sensors.push($rootScope.WGSensors.sensors_name['LLVA_LAT_TREAT_value']);
        this.sensors.push($rootScope.WGSensors.sensors_name['VOLUME_TREAT']);
        this.sensors.push($rootScope.WGSensors.sensors_name['QL_TREAT_LDENSA_massDensity']);
        this.sensors.push($rootScope.WGSensors.sensors_name['AI_LDENSA_FermentationKinetic24h']);
        this.sensors.push($rootScope.WGSensors.sensors_name['PRESSURE_TREAT']);
        this.sensors.push($rootScope.WGSensors.sensors_name['HUM']);
        this.sensors.push($rootScope.WGSensors.sensors_name['CO2']);
      }
    }

    $onInit() {
      if (this.rule.delay == undefined) {
        this.rule.delay = 0;
      }

      if (this.rule.parameter) {
        if (this.rule.parameter.id) {
          this.rule.parameter = this.WGApiData.WGSensors.sensors_id[this.rule.parameter['id']];
        } else if (this.rule.parameter && _.isNumber(this.rule.parameter)) {
          this.rule.parameter = this.WGApiData.WGSensors.sensors_id[this.rule.parameter];
        } else if (this.rule.parameter && _.isString(this.rule.parameter)) {
          this.rule.parameter = this.WGApiData.WGSensors.sensors_name[this.rule.parameter];
        } else {
          console.error("Rule parameter is not valid", this.rule.parameter);
          return;
        }
        this.parameterChanged(this.rule.parameter);
      }
      this.convert(this.rule);
    }

    public ruleTypeChanged(typeId: number) {
      this.rule.value_conv = null;
      this.rule.value = null;
    }

    public parameterChanged(sensor: ISensor) {
      sensor = sensor || this.sensor_selected;
      if (sensor && this.rule.parameter?.id != sensor.id) {
        this.rule.value_conv = null;
        this.rule.value = null;
        this.rule.parameter = sensor;
      }
      this.sensor_selected = sensor;

      //Handle custom logical options
      let options: LogicalOptions[] = this.ruleOwnerType === "alarm" ? wg.standardLogicalOptions.concat(wg.alarmLogicalOptions) : wg.standardLogicalOptions;

      if (SENSORS_ONLY_SUPPORTING_EQUAL.includes(sensor.internal_name)) {
        //Sensor only supports equality operators
        options = options.filter(option => option.logical == '==' || option.logical == '!=');
      } else if (!SENSORS_SUPPORTING_EQUAL.includes(sensor.internal_name)) {
        //Sensor supports equality operators
        options = options.filter((option: LogicalOptions) => option.logical != '==' && option.logical != '!=');
      }

      // If rule.logical not in options, set it to the first option
      if (!options.find(option => option.logical == this.rule.logical)) {
        this.rule.logical = options[0].logical;
      }
      this.allLogicalOptions = options;
    }


    public deleteRule(rule: IAlarmRule) {
      if (rule.id) {
        rule.markedForDeletion = !rule.markedForDeletion;
      } else {
        _.remove(this.ruleOwner.rules, rule);
      }
      //clear myErrors
      const myErrors: {} = this.errors?.get(rule);
      for (let key in myErrors) {
        delete myErrors[key];
      }
    }


    public onDelayChange(rule) {
      this.checkForErrors()
    }

    public checkForErrors() {
      //if (WG_debug) console.log('WgAlarmRule checkForErrors', this.rule);
      if (!this.errors) return;

      if (!(this.errors.get(this.rule) instanceof Object)) this.errors.set(this.rule, {} as any);
      const myErrors: {} = this.errors.get(this.rule);

      //clear myErrors
      for (let key in myErrors) {
        delete myErrors[key];
      }


      if (_.isNil(this.rule.type_id)) {
        myErrors["type"] = this.$translate.instant('app.winemaking.errors.MISSING_PRECONDITION');
      }

      if (this.rule.markedForDeletion)
        return;

      switch (this.rule.type_id) {

        case TASK_RULE_TYPES.parameter_reached.id:
          //parameter
          if (!this.rule.parameter) {
            myErrors["parameter"] = this.$translate.instant('app.winemaking.errors.MISSING_PARAMETER');
            return;
          }

          //logical
          if (!this.rule.logical) {
            myErrors["logical"] = this.$translate.instant('app.winemaking.errors.MISSING_OPERATOR');
            return;
          }

          //value
          if (_.isNil(this.rule.value)) {
            myErrors["value"] = this.$translate.instant('app.winemaking.errors.MISSING_VALUE');
            return;
          }

          break

        case TASK_RULE_TYPES.other_task_finished.id:
          if (!this.rule.previousTask) {
            myErrors["task"] = this.$translate.instant('app.winemaking.errors.MISSING_DEPENDENCY');
            return;
          }
          if (this.rule.previousTask == this.ruleOwner.id) {
            myErrors["same_task"] = this.$translate.instant('app.winemaking.errors.INVALID_DEPENDENCY');
            return;
          }

          break;

        case TASK_RULE_TYPES.elapsed_time_since_stage_start.id:
          if (_.isNil(this.rule.delay)) {
            myErrors["delay"] = this.$translate.instant('app.winemaking.errors.MISSING_DELAY');
            return;
            // } else if (this.rule.delay == 0) {
            //     myErrors["delay"] = "Please select a delay greater than 0";
          }
          break;
      }


    }

    public getErrors() {
      return this.errors.get(this.rule);
    }

    public getNumberOfErrors() {
      return Object.keys(this.errors.get(this.rule)).length || 0;
    }

    public getErrorsText(): string[] {
      if (!this.rule) return

      const errorTexts: [] = [];
      //use lodash to iterate over this.errors.get(this.rule))
      for (let key in this.errors.get(this.rule)) {
        errorTexts.push(this.errors.get(this.rule)[key]);
      }
      return errorTexts
    }


    public getRuleText(rule: IAlarmRule) {
      const ruleType = _.find(TASK_RULE_TYPES, {id: rule.type_id});
      return ruleType?.sref;
    }

    public getTaskById(id: number) {
      return this.stage?.tasks?.find(task => task.id == id);
    }

    public convert(rule: IAlarmRule) {
      //@ts-ignore
      rule.value_conv = convert(rule.value, rule.parameter?.conversion?.id, null, false, this.DataUtils);
      if (!_.isNil(rule.parameter?.configs?.decimals)) {
        //@ts-ignore
        rule.value_conv = Math.round(rule.value_conv * Math.pow(10, rule.parameter.configs.decimals + 1)) / Math.pow(10, rule.parameter.configs.decimals + 1);
      }
    }

    public deconvert(rule: IAlarmRule) {
      let parameter = rule.parameter as ISensor;
      rule.value = convert(rule.value_conv, parameter?.conversion?.id, null, true);
      if (!_.isNil(parameter?.configs?.decimals_orig)) {
        rule.value = Math.round(rule.value * Math.pow(10, parameter.configs.decimals_orig + 1)) / Math.pow(10, parameter.configs.decimals_orig + 1);
      }
    }


  }

  App.component('wgAlarmRule', {
    templateUrl: 'app/views/partials/wg-alarm-rule.html',
    controller: WgAlarmRule,
    controllerAs: 'ctrl',
    bindings: {
      rule: '=',
      stage: '<',
      ruleOwner: '=',
      errors: '=',
      visibleRuleTypes: '<',
      // showErrors: '=?',
      ruleOwnerType: '<',
      customParameters: '<',
    }
  })
}