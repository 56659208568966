/**=========================================================
 * Module: edit-units.js
 * Modal controller for Units Create/Edit
 =========================================================*/

namespace wg {

  interface IScope extends ng.IScope {
    ret: IReturnResult,
    addToGraph: GraphController['addToGraph'],

  }

  App.controller('manageUnitEditController', ['$rootScope', '$scope', '$http', 'ngDialog', '$timeout', 'WGApiData',
    function ($rootScope: IRootScope, $scope: IScope, $http: ng.IHttpService, ngDialog: ng.dialog.IDialogService, $timeout: ng.ITimeoutService, WGApiData: WGApiData) {

      $scope.isHelpCollapsed = {
        sensors: true,
        more: true
      };

      let parentRet: IReturnResult = {
        loading: false,
        result: '',
        message: '',
      };
      $scope.ret = parentRet;

      let ret_key = $scope.ngDialogData?.result || 'manualEntryRet';
      if ($scope.$parent?.[ret_key]) {
        $scope.$parent[ret_key] = parentRet;
      } else if ($scope.$parent?.$parent?.[ret_key]) {
        $scope.$parent.$parent[ret_key] = parentRet;
      }


      let deleteConfirmIsOpen = false;
      // let openedWithaDevice = false;
      let oldDevices_ids = [];

      //            --  UI
      let timeToClearComsFeedback = 4 * 1000;//4segundos
      $scope.show = {
        description: false,
        dyn_fields: false
      };
      let isPristine = true;
      $scope.changedInputs = {};

      // ??
      $scope.coms = {
        clear: function () {
          this.post = null;
          this.device = null;
          this.unit = null;
        },
        clearPos: function () {
          // this.post= null;
          this.device = this.device ? null : this.device;
          this.unit = this.unit ? null : this.unit;
        },
        post: null,
        device: null,
        unit: null
      };

      $scope.$watch('changedInputs', function (_new_value, _old_value) {
        if (_new_value === _old_value) { // Initializing watcher
          return;
        }
        // $scope.coms.clear();
        $scope.checkChanges.wereSaved = false;
        isPristine = false;
      }, true);

      $scope.checkChanges = {
        wereSaved: false,
        exist: function () {
          return (!_.isEmpty($scope.changedInputs) && !$.isEmptyObject($scope.changedInputs));
        },
        areValid: function () {
          if (!$scope.unitData || !$scope.unitData.name)
            return false;

          if (!$scope.selected.place)
            return false;

          if (!$scope.selected.unit_type)
            return false;

          return true;
        },

        devices: function () {
          $scope.changedInputs['device'] = true
        },
      };

      $scope.unitData = {} as {
        place: IPlace,
        place_id: number, // ID of target place
      };
      //                              -- SELECTED
      $scope.selected = {
        place: null,
        unit: null,
        unit_type: null,
        device: null,
      };

      //                        --- UNIT TYPE

      $scope.select_unit_type = function () {
        if ($scope.selected.unit_type && $scope.unitData.unit_type !== $scope.selected.unit_type) {
          $scope.unitData.unit_type = angular.copy($scope.selected.unit_type);
          $scope.changedInputs['unit_type'] = true
        }
      }

      //                        --- PLACES
      $scope.changedSelectedPlace = function () {
        if ($scope.selected.place.id && $scope.unitData.place_id !== $scope.selected.place.id) {
          $scope.unitData.place_id = angular.copy($scope.selected.place.id);
          if ($scope.unitData.place_id == $scope.unitData.place?.id) {
            delete $scope.changedInputs['place'];
          } else {
            $scope.changedInputs['place'] = true;
          }
        }
      };

      // Show all existing places and allow creating a new one
      $scope.places = null;

      function getPlaces() {
        let _places = _.map(WGApiData.WGPlaces.places, item => _.pick(item, ['id', 'name', 'description', 'units']));
        _places = _.filter(_places, _place => (_place.id > 0));
        $scope.places = _.sortBy(_places, 'name');

        if ($scope.selected.place?.id) {
          $scope.selected.place = _.find($scope.places, {id: $scope.selected.place.id}) || $scope.selected.place;
        }
      }

      let newPlaceModalIsOpen = false;
      $scope.newPlace = function () {
        if (newPlaceModalIsOpen) {
          console.log("attempt to open second newPlace modal");
          return;
        }
        newPlaceModalIsOpen = true;

        let dialog = ngDialog.open({
          template: 'app/views/modals/edit-dialog-places.html',
          data: {
            action: 'create',
            elem: null,
          }
        });
        dialog.closePromise.then(function (obj) {
          console.log("closePlaceDialog. obj", obj);
          newPlaceModalIsOpen = false;
          // if (obj?.value?.isAdd) {
          //   $scope.places.push(obj.value.item_data); // Will be replaced soon
          //   $scope.places.sort(order_names);
          //   $scope.selected.place = obj.value.item_data;
          //   $scope.changedSelectedPlace();
          // }
        });
      };


      //                                  --- DEVICES
      $scope.dev_avail_length = null;
      $scope.devices = [];
      // $scope.selected.device = {};
      // $scope.changedSelectedDevice = function(){
      //     // console.log($scope.selected);
      //     let ni = angular.copy($scope.selected.device.id);
      //     if(angular.isDefined(ni) && ni!==null){
      //         $scope.unitData.device_id = ni;
      //     }
      //     console.log("device_i: ", $scope.unitData.device_id );
      //     $scope.changedInputs.device = true;
      // };
      function getDevices() {
        let device_list = _.filter(WGApiData.WGDevices.devices, function (_device) {
          return !SMARTBOX_MODELS.includes(_device.model);
        });

        $scope.devices = _.sortBy(device_list, function (_device) {
          // list devices without an assigned unit first
          return _device.unit?.id > 0 ? 1 : 0;
        });

        // Count the number of devices where unit.id is not >0
        $scope.dev_avail_length = _.filter($scope.devices, function (_device) {
          return !(_device.unit?.id > 0);
        })?.length || 0;

      }

      $scope.$watch('selected.device', function (_new_value: IDevice, _old_value: IDevice) {
        if (_new_value === _old_value) { // Initialized
          return;
        }
        console.log("new selected device=", _new_value, !angular.isDefined($scope.selected.device));
        if (!angular.isDefined($scope.selected.device) && _old_value) {
          let _idx = _.findIndex($scope.devices, {id: _old_value.id});
          $scope.devices[_idx].unit = null;
        }

      });

      function setUnitAtDeviceAPI(devID, adding) {
        parentRet.loading = true;
        let device_ID = devID;
        if (device_ID === null)
          if ($scope.selected.device)
            device_ID = $scope.selected.device.id;
          else {
            console.log("error#44404944040.return");
            return
          }
        let data = {unit_id: adding ? $scope.unitData.id : null};
        $http.patch<IDevice>('api/dashboard/devices/' + device_ID + '/', data).then(
            function (response) {
              console.log("setUnitAtDeviceAPI SUCCESS: response.data", response.data);

              WGApiData.WGDevices.merge_entry(response.data);
              WGApiData.process_data_soon();

              parentRet.loading = false;
              parentRet.result = 'success';

              $scope.coms.device = !!adding;

              $scope.checkChanges.wereSaved = true;

              oldDevices_ids = adding ? [device_ID] : [];

              $scope.changedInputs = {};
              // $scope.confirm({response: response.data, item_data: $scope.newData});
              callClearSaved();
              return true;
            },
            function (reason) {
              console.error(reason);
              parentRet.result = 'error';
              parentRet.loading = false;
              $scope.coms.device = false;
              callClearSaved();
              return false;
            });
        return true;
      }

      //                                  --- DATETIMEPICKER
      $scope.maxDate = $scope.nowDate;
      $scope.nowDate = new Date();
      $scope.dateTimeNow = function () {
        return new Date();
      };
      $scope.dateOptions = {
        startingDay: 1,
        showWeeks: false
      };

      //                                  --- DYNAMIC FIELDS
      $scope.df = {
        WARN: false,
        length: function () {
          return _.keys($scope.unitData.dynamic_fields).length
        },
        new_values: {key: '', value: ''},
        has_fields: function () {
          return _.keys($scope.unitData.dynamic_fields).length > 0
        },
        has_values: function () {
          return this.new_values.key !== '' && this.new_values.value !== ''
          // this.h_val = !(this.new_values.key==='' && this.new_values.value==='');
        },
        removeDynField: function (key) {
          delete $scope.unitData.dynamic_fields[key];
          $scope.changedInputs['dynamic_fields'] = true;
        },
        addDynField: function (keyvalue, isNew) {
          //\\ keyvalue&isNew are for the purpose of allowing to
          // submit dynamic field with an enter.
          // but it also triggers submit,
          // so the values are just being pre-satisfied on function call
          // if($scope.df.last_df_has_values() && keyvalue===13 && isNew) {
          // alert('check for dups');
          if (!$scope.unitData.dynamic_fields) {
            $scope.unitData.dynamic_fields = {};
          }
          let ch = this.new_values.key;
          let vl = this.new_values.value;
          let propriedades = _.keys($scope.unitData.dynamic_fields);
          let isDupe = _.indexOf(propriedades, ch) > -1;
          this.WARN = isDupe;
          if (isDupe) {
            // console.log("isDupe", isDupe);
            return
          } else {
            console.log("is NOT Dupe", isDupe);
            if (this.has_values()) {
              $scope.unitData.dynamic_fields[ch] = vl;
              this.new_values.key = '';
              this.new_values.value = '';
            }
          }
          console.log("addDynFld. length", this.length);
          $scope.changedInputs['dynamic_fields'] = true;
        }
      };
      //

      //                                  --- INPUTS
      // $scope.inputChanged = function(which){
      //     console.log("inputChanged", which);
      // };

      //                              --- ACTIONS : SAVE // DELETE // CLOSE
      $scope.deleteThis = function () {
        console.log("Delete UNIT, Data: ", $scope.unitData);

        if (!deleteConfirmIsOpen) {
          deleteConfirmIsOpen = true;
        } else {
          return
        }
        ngDialog.openConfirm({
              template: 'app/views/modals/confirm-danger.html',
              className: 'ngdialog-theme-default',
              data: {
                what: 'units',
                which: $scope.unitData.name,
              }
            }
        ).then(
            function (data) {
              //delete
              console.log('Delete UNIT, confirmed', data);
              parentRet.loading = true;
              WGApiData.WGUnits.delete($scope.unitData.id, function (result) {
                parentRet.result = result;
                parentRet.loading = false;
                if (result == 'success') {
                  if ($scope.confirm)
                    $scope.confirm({wasDelete: true});
                  else
                    $scope.closeThisDialog({wasDelete: true});
                  // ngDialog.close({response: response});
                }
              });
              deleteConfirmIsOpen = false;
            }, function (reason) {
              deleteConfirmIsOpen = false;
              console.log('DELETE Modal promise rejected. Reason: ', reason);
            });
      };

      $scope.cancel = function () {
        parentRet.result = '';
        parentRet.loading = false;
        parentRet.message = 'Cancelled';

        $scope.closeThisDialog();
      };

      $scope.saveData = function () {
        $scope.coms.clear();
        parentRet.loading = true;

        if (!$scope.checkChanges.areValid()) {
          console.log('Invalid data!', $scope);
          parentRet.loading = false;
          parentRet.result = 'error';
          return;
        }

        $scope.unitData.unit_type = $scope.selected.unit_type;

        let data_to_send = normalizeData(angular.copy($scope.unitData));
        console.log('Save Unit', data_to_send);
        var url = 'api/dashboard/units/';
        var http_method = $http.post;
        $scope.coms.post = true;
        if ($scope.action === 'edit') {
          http_method = $http.patch;
          url += $scope.unitData.id + '/';
          // $scope.coms.post = false;
        }

        http_method<IUnit>(url, data_to_send).then(
            function (response) { // onSuccess
              console.log("Unit changed, Ok", response);

              WGApiData.WGUnits.merge_entry(response.data);
              if ($scope.unitData?.id < 0) {
                WGApiData.WGUnits.delete_local($scope.unitData.id)
              }
              WGApiData.process_data_soon();

              //$scope.changedInputs = {};
              console.log("UNIT Http SUCCESS:",
                  "dataset", data_to_send,
                  "response.data", response.data);


              $scope.unitData = response.data;

              if ($scope.action === 'create') {
                $scope.action = 'edit';
              }

              $scope.coms.unit = true;
              if ($scope.changedInputs['device']) {
                $scope.changedInputs = _.pick($scope.changedInputs, 'device');
              } else {
                $scope.changedInputs = {};
              }

              // trying to prepare for multiple devices
              let newDevices_ids = $scope.selected.device ? [$scope.selected.device.id] : [];
              if (oldDevices_ids[0] === newDevices_ids[0] || (oldDevices_ids.length === 0 && newDevices_ids.length === 0)) {
                console.log("save unit. no devices were changed");
                parentRet.loading = false;
                parentRet.result = 'success';

                $scope.coms.device = null;
                callClearSaved();

              } else {
                // console.log("initialDevices:",initialDevices);
                // console.log("nowDevices:", nowDevices);
                console.log("save unit. there are changed devices");

                let devices_count_before = oldDevices_ids.length;
                let devices_count_after = newDevices_ids.length;
                // if there are multiple devices in same unit, something is wrong.
                if (devices_count_before > 1 || devices_count_after > 1) console.log("DevDo ^^")

                // check if device has changed;

                if (oldDevices_ids[0] !== newDevices_ids[0]) {
                  // remove unit from previous device
                  let allChangesDone = true;
                  if (devices_count_before > 0) {
                    console.log("Remove: ", oldDevices_ids[0]);
                    allChangesDone = setUnitAtDeviceAPI(oldDevices_ids[0], false);
                  }
                  // add unit at new device
                  if (devices_count_after > 0) {
                    console.log("Add: ", newDevices_ids[0]);
                    allChangesDone = setUnitAtDeviceAPI(newDevices_ids[0], true);
                  }

                  // again, assuming one device per unit.
                  if (allChangesDone)
                    oldDevices_ids[0] = newDevices_ids[0];
                }

              }

              // Exit the modal 1s after a successful save:
              $timeout(function () {
                $scope.close(0);
              }, 600);

            },
            function (reason) {
              console.error("HTTP ERROR. response", reason);
              parentRet.result = 'error';
              parentRet.loading = false;
              $scope.coms.unit = false;
              callClearSaved();
            });
      };

      $scope.close = function () {
        // parentRet.result = 'cancel';
        parentRet.loading = false;

        // let isAdd = $scope.action !== 'edit';
        // $scope.unitData.devices.push($scope.selected.device);
        let return_result = {
          wasPristine: isPristine,
          isAdd: false,
          item_data: $scope.unitData
        };
        if (!isPristine) {
          return_result.isAdd = true;
          return_result.item_data.devices = [$scope.selected.device];
        }


        console.log("Close this dialog", return_result);

        if (parentRet.result === 'success' && $scope.confirm)
          $scope.confirm(return_result);
        else if ($scope.closeThisDialog)
          $scope.closeThisDialog(return_result);
        else
          $scope.close(0);
      };
      //                                  --- UTILS
      let clearSavedTO: ng.IPromise<any>;

      function callClearSaved() {
        if (angular.isDefined(clearSavedTO)) {
          $timeout.cancel(clearSavedTO);
        }
        // console.log("SET!!!");
        clearSavedTO = $timeout(function () {
          $scope.clearSaved()
        }, timeToClearComsFeedback);
      }

      $scope.clearSaved = function () {
        // console.log("GO!!!");
        // console.log("clearSaved");
        $scope.coms.clearPos();
        // $scope.changedInputs = {};
        // ret = {};
        parentRet.loading = false;
        parentRet.result = '';
      };

      function order_names(a, b) {
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      }

      /**
       * Returns a sendableJSON, with the fields used in the UNITS-API.
       * 'place' becomes 'place_id', 'device' is ignored, all other changedInputs are accepted
       * @param currentData
       * @returns {{}}
       */
      function normalizeData(currentData) {
        let sendableJSON = {};
        // 'id', 'name', 'description', 'unit_type', 'active', 'created_at', 'last_activity', 'place, 'process'
        // TODO: make sure only accepted fields are sent?

        // device is not included because it is changed in devices endpoint ???
        if (!_.isEmpty($scope.changedInputs)) {
          let _changed_keys = _.keys($scope.changedInputs);
          for (let i in _changed_keys) {
            if (_changed_keys[i] === 'place') {
              sendableJSON['place_id'] = $scope.selected.place.id;
            } else if (_changed_keys[i] === 'device') {
              // sendableJSON[_changed_keys[i]] = $scope.selected.device;
            } else {
              sendableJSON[_changed_keys[i]] = currentData[_changed_keys[i]];
            }
          }
        }


        return sendableJSON
      }

      $scope.dateTimeNow = function () {
        return new Date();
      };


      //-------------------------------------------- INIT------------------------------------------//

      function init() {

        $scope.changedInputs = {};

        // $scope.coms={process:null,device:null};
        //      								 ---     ngDialog?
        $scope.inputreadonly = true;
        $scope.disabledinput = false;
        $scope.origin = "manage";


        $scope.action = 'create';
        if ($scope.ngDialogData.action === 'edit')
          $scope.action = 'edit';

        let openMode = 'open';
        if (angular.isDefined($scope.ngDialogData.mode)) {
          openMode = $scope.ngDialogData.mode;
        }
        if (angular.isDefined($scope.ngDialogData.readonly)) {

          $scope.inputreadonly = $scope.ngDialogData.readonly;
          $scope.disabledinput = true;
        }


        let _unhook_updatePlaces = $rootScope.$on('places_updated', function () {
          getPlaces();
        });
        getPlaces();

        let _unhook_updateDevices = $rootScope.$on('devices_updated', function () {
          getDevices();
        });
        getDevices();

        $scope.$on('$destroy', function () {
          _unhook_updatePlaces();
          _unhook_updateDevices();
        });

        // Set unit passed as argument as default data
        if ($scope.ngDialogData.elem) {
          $scope.unitData = _.clone($scope.ngDialogData.elem);
        } else {
          $scope.unitData = {};
        }

        if (!$scope.unitData.id || $scope.unitData.id < 0)
          $scope.action = 'create';

        // If place is available, fill it.
        if ($scope.unitData.place && $scope.unitData.place.id > 0) {
          $scope.selected.place = _.find($scope.places, {id: $scope.unitData.place.id}) || $scope.unitData.place;
        }
 
        // Create unit in given place passed in ngDialogData.place
        // If given place is different than unit, update it
        if ($scope.ngDialogData.place && $scope.ngDialogData.place.id > 0) {
          if (!$scope.unitData.place || $scope.unitData.place.id !== $scope.ngDialogData.place.id) {
            $scope.selected.place = _.find($scope.places, {id: $scope.ngDialogData.place.id}) || $scope.ngDialogData.place;
            $scope.changedSelectedPlace();
          }
        }

        // If Unit_Type was passed, fill it
        if ($scope.unitData.unit_type) { // If place is available, fill it.
          $scope.selected.unit_type = $scope.unitData.unit_type;
        }

        if (!_.isEmpty($scope.unitData.devices)) {
          if (WG_debug) console.log("Pre-selecting passed Device.", $scope.unitData);
          $scope.selected.device = $scope.unitData.devices[0];

          if ($scope.unitData.id > 0) {// The non-virtual unit already contained selected device
            oldDevices_ids = [$scope.selected.device.id];
          }

          //
          if (!$scope.unitData.name && $scope.unitData.devices[0].name) {
            console.log("Using device's name as Unit name");
            $scope.unitData.name = $scope.selected.device.name;
            $scope.changedInputs['name'] = true
          }
        } else if ($scope.ngDialogData.device) {
          $scope.selected.device = _.clone($scope.ngDialogData.device);
        } else if ($scope.ngDialogData.device_id) {
          $scope.selected.device = _.clone(WGApiData.extractDevices($scope.ngDialogData.device_id)?.[0]);
        }

        // Consider everything as new data when creating
        if ($scope.action === 'create') {
          if ($scope.unitData.name)
            $scope.changedInputs['name'] = true

          if ($scope.unitData.place)
            $scope.changedInputs['place'] = true

          if ($scope.unitData.unit_type)
            $scope.changedInputs['unit_type'] = true

          if ($scope.unitData.devices)
            $scope.changedInputs['device'] = true
        }

        $scope.addToGraph = $scope.addToGraph || $scope.ngDialogData?.addToGraph;

      }

      $scope.spitData = function () {
        console.log("SpDt. $scope:", $scope);
        console.log("initialDevices:", oldDevices_ids);
        console.log("ngDialog:", $scope.ngDialogData);
        console.log("selected.device:", $scope.selected.device);
        // console.log("$scope.$parent.$root.devices:",$scope.$parent.$root.devices);
      };

      init();

    }])
}