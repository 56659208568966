namespace wg {
  "use strict";
  
  function get_delta(data: number[][]): number[] {
    let ret = [];
    let _prev = data[0][1]; // First delta entry will always be 0.
    for (let i in data) {
      ret.push(data[i][1] - _prev);
      _prev = data[i][1];
    }
    return ret;
  }
  
  /**
   * Estimates the start and end of a fermentation process
   * @param dens_ai
   * @param temp
   * @param llv_analog
   * @param start_analysis_at_timestamp Point known to belong to the desired process. Will use last point with LLV and stable if falsy
   * @return object containing 'start' and 'end' epochs in ms.
   */
  export function estimate_ferm_process_limits(dens_ai: any = null, temp: any = null, llv_analog: any = null, llv: any = null, start_analysis_at_timestamp = null): {
    start_process?: number,
    end_process?: number
  } {
    let ret = {start_process: llv_analog[0][0], end_process: llv_analog[llv_analog.length - 1][0]}
    
    let llv_analog_diff = get_delta(llv_analog);
    let llv_diff = get_delta(llv);
    
    let _start_llv_index;
    let _start_llv_analog_index;
    for (let i = llv.length - 1; i >= 0; i--) {
      if (start_analysis_at_timestamp) {
        if (llv[i][0] <= start_analysis_at_timestamp) {
          _start_llv_index = i;
          start_analysis_at_timestamp = llv[i][0];
          break;
        }
      } else {
        if (llv[i][1] > 0 && Math.abs(llv_diff[i]) < 50) {
          _start_llv_index = i;
          start_analysis_at_timestamp = llv[i][0];
          break;
        }
      }
    }
    if (!_start_llv_index) {
      console.error("Couldn't find a valid LLV");
      return;
    }
    
    for (let i = llv_analog.length - 1; i >= 0; i--) {
      if (llv_analog[i][0] <= start_analysis_at_timestamp) {
        _start_llv_analog_index = i;
        break;
      }
    }
    
    
    // Detect huge analog_diff swings
    for (let i = _start_llv_analog_index; i > 0; i--) {
      // Start when a huge variation of liquid is detected
      if (Math.abs(llv_analog_diff[i]) > 500) {
        console.log("Detected a huge liquid movement: ", llv_analog[i]);
        ret.start_process = llv_analog[i][0];
        break;
      }
    }
    for (let i = _start_llv_analog_index; i < llv_analog.length; i++) {
      // Stop when a huge variation of liquid is detected
      if (Math.abs(llv_analog_diff[i]) > 500) {
        console.log("Detected a huge liquid movement: ", llv_analog[i]);
        ret.end_process = llv_analog[i - 1][0];
        break;
      }
    }
    
    // detect lvl==0 and following fill-up
    
    
    for (let i = _start_llv_index - 1; i > 0; i--) {
      if (Math.abs(llv[i][1]) < 20) {
        console.log("Detected an empty tank. Searching for fill-up", llv[i]);
        
        for (let j = i + 1; j < llv.length; j++) {
          if (llv[j - 1][0] > ret.start_process) {
            ret.start_process = llv[j - 1][0];
          }
          if (Math.abs(llv_diff[j]) < 10) {
            console.log("Detected the end of fill-up", llv[j - 1]);
            break;
          }
          console.log("Detected fill-up", llv[j - 1]);
          
        }
        break;
      }
    }
    
    return ret;
  }
  
  
}