/**
 * Created by pmec on 19/07/16.
 */

namespace wg {
  "use strict";

  export interface IGraphedSensor {
    sensor: ISensor;
    hasYAxis: boolean;
    yAxis?: Highcharts.AxisOptions;
  }

  export interface IGraphedDeviceSensor {
    sensor: ISensor;
    series?: Highcharts.SeriesLineOptions | Highcharts.SeriesArearangeOptions | Highcharts.SeriesErrorbarOptions | Highcharts.SeriesFlagsOptions; // includes .data

    yAxis?: Highcharts.AxisOptions;
    flags?: boolean; // If flags have already been added for this DeviceSensor
    flags_AI?: boolean; // If flags_AI have already been added for this DeviceSensor

    dataAlign_truncateSeconds?: number; // Seconds
    // dataAlign_lastTimestamp?: number; // Timestamp of the last alignment estimation

    raw_data?: Array<([(number | string), (number | null)] | null | Highcharts.PointOptionsObject)>;
  }

  export interface IGraphedDevice {
    device: IDevice;
    // data: { [internal_name: string]: Highcharts.SeriesLineOptions['data'] }; // === .sensors[].data, === .sensors[].series.data
    sensors: { [internal_name: string]: IGraphedDeviceSensor };
    bands: boolean; // If bands have already been added for this device
  }

  export interface IGraphedSeries {
    device: IDevice,
    sensor: ISensor,
  }

  // export interface IGraphYAxis {
  //   isUserExtremes: boolean;
  //   id: string;
  //   options: Highcharts.AxisOptions;
  //   extremes: Highcharts.Extremes;
  //   min: number;
  //   max: number;
  //   diff?: number;
  //   step?: number;
  // }
  //
  // export interface IGraphXAxis {
  //   id: string;
  //   options: IHighchartsAxisOptions;
  //   extremes: IHighchartsExtremes;
  //   min: number;
  //   max: number;
  // }

//  implements IGraphController
  export class GraphController {
    static $inject = ['$rootScope', '$scope', '$window', '$translate', '$timeout', '$http', '$state', 'ngDialog', 'Data', 'WGApiData', 'DataUtils', 'AuthService'];
    // The main chart object! Direct changes are not guaranteed to produce effects. Use setter functions like update()
    private _chart: Highcharts.Chart;
    // The main chartConfig object! Direct changes are not guaranteed to produce effects. Use setter functions like update()
    public chartConfig: Highcharts.Options;

    private isTouch = false;
    private select_enabled = false;
    private addProcess_enabled = false;
    private addFlag_enabled = false;
    private addManualEntry_enabled = false;

    private creatingNote = false;

    private defaultParams: IDataParams = {
      q: {"payload": ["timestamp", "value"]},
      b: '7y',
      f: 'list',
      l: 200000,
      s: 200000,
    };

    // Dict with graphed timeSeries, containing refs to IDevice and ISensor
    private graphedSeries: { [series_id: string]: IGraphedSeries } = {};
    // Dict with graphed Sensors, to avoid creating new yAxis when they are already present
    private graphedSensors: { [internal_name: string]: IGraphedSensor } = {};
    // Dict with watched Streams per device, to avoid re-watching
    private watchedStreams: { [device_uuid: string]: { [stream: string]: boolean } } = {};
    // Dict with graphed Devices and their Sensors and data
    private graphedDevices: { [device_uuid: string]: IGraphedDevice } = {};

    private devices_capabilities: IDeviceCapabilities = {
      config_density_read: false,
      control_board: false,
      fermentation_prediction: false,
      force_read: false,
      set_offsets: false,
      config_wifi: false,
    };

    private styles = {
      line_color_variations: true,
      line_color_variations_strong: false,
      dashed_lines: false,
      colorful_markers: false,
      markers_size: 2.4,
      markers_opacity: "FF",
      markers_outline_width: 0.8,
      markers_outline_color: "#ffffffFF",

      findNearestPointBy: "x" as ("x" | "xy"),
      dataAlign_auto: true,
      dataGrouping_groupPixelWidth: 4,
      dataGrouping_toggle: () => {
        this.styles.dataGrouping_groupPixelWidth = this.styles.dataGrouping_groupPixelWidth > 0 ? 0 : 4;
        this.styles.reset();
      },
      crosshair: {
        width: 2,
        snap: true,
        label: {
          enabled: false,
          backgroundColor: "#507f99",
          format: '{value:%Y/%m/%d %H:%M}',
        },
      },
      panning: true, // !!isTouch()
      panning_mode: "x" as ("x" | "xy" | "y"),
      zoomType: 'x' as Highcharts.OptionsTypeValue,
      pinchType: 'x' as Highcharts.OptionsTypeValue,
      tooltip_followPointer: false,
      tooltip_followTouchMove: true,
      tooltip_type: "split" as ("split" | "shared" | "none"),
      tooltip_useHTML: true,

      reset: () => {
        this._chart.update({
          chart: {
            panning: {
              enabled: !!this.styles.panning,
              type: this.styles.panning_mode,
            },
            zooming: {
              type: this.styles.zoomType,
              pinchType: this.styles.pinchType,
            },
          },
          xAxis: {
            crosshair: !this.styles.dataAlign_auto ? false : this.styles.crosshair,
          },
          tooltip: {
            followPointer: this.styles.tooltip_followPointer,
            followTouchMove: this.styles.tooltip_followTouchMove,
            split: this.styles.tooltip_type == "split",
            shared: this.styles.tooltip_type == "shared",
            useHTML: this.styles.tooltip_useHTML,
          },
          plotOptions: {
            line: {
              marker: {
                lineWidth: this.styles.markers_outline_width,
                lineColor: this.styles.markers_outline_color,
                radius: this.styles.markers_size,
              },
              findNearestPointBy: this.styles.findNearestPointBy,
            },
            series: {
              dataGrouping: {
                enabled: this.styles.dataGrouping_groupPixelWidth > 0,
                groupPixelWidth: this.styles.dataGrouping_groupPixelWidth,
              },
              findNearestPointBy: this.styles.findNearestPointBy,
            }
          }
        }, true);
        console.log('Graph config reset');
      },
    };

    private used_styles: { sensors: string[], color: string, count?: number, variation?: number }[] = [];
    private all_markers = ['circle', 'triangle', 'triangle-down', 'square', 'diamond'];
    private all_colors = [];
    private color_variations_strong = [];
    private color_variations = [];
    private used_colors = [];

    private dash_styles = [
      'Solid',
      'ShortDash',
      'ShortDot',
      'ShortDashDot',
      'ShortDashDotDot',
      'Dot',
      'Dash',
      'LongDash',
      'DashDot',
      'LongDashDot',
      'LongDashDotDot'
    ];

    private colorlabel = '';

    public show = false;
    // public loading = false;
    public singleSeries = false;

    private flagSensor: ISensor = {
      id: -1,
      name: 'Flags',
      stream: 'FLAG',
      internal_name: 'FLAG',
    };
    private flagAISensor: ISensor = {
      id: -2,
      name: 'Flags AI',
      stream: 'FLAG_AI',
      internal_name: 'FLAG_AI',
    };
    private bandSensor: ISensor = {
      id: -3,
      name: 'Bands',
      stream: 'PLOT_BANDS',
      internal_name: 'PLOT_BANDS',
    };

    public zoomXButtonSel = true;
    public zoomYButtonSel = false;
    public zoomYXButtonSel = false;
    public moveButtonSel = false;
    public scaleButtonSel = false;

    public xAxis_main: Highcharts.Axis;

    private zoomStep = 1.5;

    public yAxisScaleStep: number;
    // public yAxis: { [id: string]: Highcharts.YAxisOptions } = {};
    // public xAxis: { [id: string]: Highcharts.XAxisOptions } = {};

    private scaleData: Array<any>;

    private goToExportData: () => void;
    private error_message: string = "";

    private timerXAxisSetExtremes: any;
    private timerXAxisAfterSetExtremes: any;
    private timerYAxisSetExtremes: any;
    private timerYAxisAfterSetExtremes: any;

    constructor(private $rootScope: IRootScope,
                private $scope: ng.IScope,
                private $window: ng.IWindowService,
                private $translate: ng.translate.ITranslateService,
                private $timeout: ng.ITimeoutService,
                private $http: ng.IHttpService,
                private $state: _IState,
                private ngDialog: angular.dialog.IDialogService,
                private Data: IDataClass,
                private WGApiData: WGApiData,
                private DataUtils: DataUtils,
                private AuthService: IAuthService,
    ) {
      // console.log('graph GraphController');
      // this.$timeout(()=> {
      //   // this.scaleModalButton();
      //   // this.scaleButton();
      // }, 500);
      this.init();
    }

    public init() {
      let graphCtrl: GraphController = this;

      // Download symbol on the Export button
      Highcharts.Renderer.prototype.symbols.download = function (x, y, w, h) {
        return [
          // Arrow stem
          'M', x + w * 0.5, y,
          'L', x + w * 0.5, y + h * 0.7,
          // Arrow head
          'M', x + w * 0.3, y + h * 0.5,
          'L', x + w * 0.5, y + h * 0.7,
          'L', x + w * 0.7, y + h * 0.5,
          // Box
          'M', x, y + h * 0.9,
          'L', x, y + h,
          'L', x + w, y + h,
          'L', x + w, y + h * 0.9
        ];
      };

      this.isTouch = isTouch();

      this.select_enabled = false;
      this.addProcess_enabled = false;
      this.addFlag_enabled = false;
      this.addManualEntry_enabled = false;

      this.graphedSeries = {};
      this.graphedDevices = {};
      this.graphedSensors = {};

      this.devices_capabilities = {
        config_density_read: false,
        control_board: false,
        fermentation_prediction: false,
        force_read: false,
        set_offsets: false,
        config_wifi: false,
      };

      // ### SERIES' COLOR STUFF
      {
        // ### Color Palette Generator: http://vrl.cs.brown.edu/color
        // ### Color Palette evaluator: https://projects.susielu.com/viz-palette

        // this.resetSeriesColors();
        /**
         * sensors = list of sensor.internal_names or master_sensors to use chosen color
         * color = first color to use, then variations.
         */
        this.used_styles = [
          {
            sensors: ['TEMP'],
            // color: '#D22D2D',
            color: '#fe2b1c',
          },
          {
            sensors: ['QL_TREAT_LDENSA_massDensity'],
            color: '#2D77B4',
          },
          {
            sensors: ['AI_LDENSA_FermentationKinetic12h'],
            // color: '#748a34',
            color: '#BCBD22',
          },
          {
            sensors: ['LLV_TOF_TREAT_value', 'LLVA_LAT_TREAT_value'],
            color: '#609111',
          },
          {
            sensors: ['VOLUME_TREAT'],
            color: '#9467BD',
          },
          {
            sensors: ['HUM'],
            // color: '#7895B4',
            color: '#30b6f3',
          },
          {
            sensors: ['CO2'],
            // color: '#64FF64',
            color: '#94fc2e',
          },
          {
            sensors: ['PRESSURE', 'PRESSURE_TREAT'],
            // color: '#2D8C2D',
            color: '#659c7e',
          },
        ];

        this.all_colors = [
          '#52ef99', '#82252a', '#e9ea1f', '#115e41',
          '#2bfafe', '#e71761', '#53348e', '#fb5de7',
          '#c3f094', '#e28196', '#ddc0bd', '#003c70',
          '#3f16f9', '#e37010', '#9525ba', '#f6bd53',
          '#503525',
          // Used statically in specific parameters
          '#fe2b1c', '#2D77B4', '#609111', '#BCBD22',
          '#9467BD', '#30b6f3', '#94fc2e'//, '#659c7e',
        ]; // Last one commented so ( all_colors.length % all_symbols.length != 0 )

        // this.other_colors = [
        //   '#ff7f0e', '#8c564b', '#ff9896', '#c5b0d5',
        //   '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f',
        //   '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf'];

        this.color_variations_strong = [
          // too harsh:
          [-50, -50, -50], // Darker
          [+50, +50, +50], // Lighter

          // medium
          [+55, -55, 0],
          [-55, +55, 0],
          [+55, 0, -55],
          [-55, 0, +55],
          [0, +55, -55],
          [0, -55, +55],

          [-50, -50, +50],
          [+50, +50, -50],
          [-50, +50, -50],
          [+50, -50, +50],
          [+50, -50, -50],
          [-50, +50, +50],


          [+55, +55, 0],
          [-55, -55, 0],
          [+55, 0, +55],
          [-55, 0, -55],
          [0, +55, +55],
          [0, -55, -55],

          // too soft:
          [+60, 0, 0],
          [-60, 0, 0],
          [0, +60, 0],
          [0, -60, 0],
          [0, 0, +60],
          [0, 0, -60],

        ];
        this.color_variations = [
          // too harsh:
          [-40, -40, -40], // Darker
          [+40, +40, +40], // Lighter

          // medium
          [+45, -45, 0],
          [-45, +45, 0],
          [+45, 0, -45],
          [-45, 0, +45],
          [0, +45, -45],
          [0, -45, +45],

          [-40, -40, +40],
          [+40, +40, -40],
          [-40, +40, -40],
          [+40, -40, +40],
          [+40, -40, -40],
          [-40, +40, +40],


          [+45, +45, 0],
          [-45, -45, 0],
          [+45, 0, +45],
          [-45, 0, -45],
          [0, +45, +45],
          [0, -45, -45],

          // too soft:
          [+50, 0, 0],
          [-50, 0, 0],
          [0, +50, 0],
          [0, -50, 0],
          [0, 0, +50],
          [0, 0, -50],

        ];
        this.used_colors = [];
      }

      this.zoomStep = 1.5;
      this.show = false;
      // this.loading = false;
      this.singleSeries = false;

      this.zoomXButtonSel = true;
      this.zoomYButtonSel = false;
      this.zoomYXButtonSel = false;
      this.moveButtonSel = false;
      this.scaleButtonSel = false;

      var start = new Date().getTime();
      var eButtonY = 0;

      this.chartConfig = {
        // useHighStocks: true,
        accessibility: {enabled: false},
        time: {useUTC: false},
        credits: {enabled: false},
        boost: {enabled: false},
        lang: {
          loading: this.$translate.instant('highchart.lang.loading'),
          months: this.$translate.instant('highchart.lang.months').split(', '),
          shortMonths: this.$translate.instant('highchart.lang.shortMonths').split(', '),
          weekdays: this.$translate.instant('highchart.lang.weekdays').split(', '),
          // exportButtonTitle: this.$translate.instant('highchart.lang.exportButtonTitle'),
          // printButtonTitle: this.$translate.instant('highchart.lang.printButtonTitle'),
          rangeSelectorFrom: this.$translate.instant('highchart.lang.rangeSelectorFrom'),
          rangeSelectorTo: this.$translate.instant('highchart.lang.rangeSelectorTo'),
          // rangeSelectorZoom: this.$translate.instant('highchart.lang.rangeSelectorZoom'),
          rangeSelectorZoom: '',
          resetZoom: this.$translate.instant('highchart.lang.resetZoom'),
          resetZoomTitle: this.$translate.instant('highchart.lang.resetZoomTitle'),
          decimalPoint: this.$translate.instant('highchart.lang.decimalPoint'),
          thousandsSep: this.$translate.instant('highchart.lang.thousandsSep'),
          numericSymbols: this.$translate.instant('highchart.lang.numericSymbols').split(', '),
          printChart: this.$translate.instant('highchart.lang.printChart'),
          downloadPNG: this.$translate.instant('highchart.lang.downloadPNG'),
          downloadJPEG: this.$translate.instant('highchart.lang.downloadJPEG'),
          downloadPDF: this.$translate.instant('highchart.lang.downloadPDF'),
          downloadSVG: this.$translate.instant('highchart.lang.downloadSVG'),
          contextButtonTitle: this.$translate.instant('highchart.lang.contextButtonTitle')
        },

        tooltip: {
          followPointer: this.styles.tooltip_followPointer,
          followTouchMove: this.styles.tooltip_followTouchMove,
          split: this.styles.tooltip_type == "split",
          shared: this.styles.tooltip_type == "shared",
          distance: 25,
          padding: 5,
          outside: false, // true | false // Breaks horizontal scroll on mobile?
          // positioner: function (w, h, point) {
          //   let pos = this.getPosition.call(this, w, h, point);
          //   return {
          //     x: pos.x,
          //     y: Math.max(pos.y, this.chart.plotTop)
          //   }
          // },
          useHTML: this.styles.tooltip_useHTML,
          dateTimeLabelFormats: {
            millisecond: '%Y/%m/%d %H:%M:%S', // .%L',
            second: '%Y/%m/%d %H:%M:%S',
            minute: '%Y/%m/%d %H:%M',
            hour: '%Y/%m/%d %H:%M',
            day: '%Y/%m/%d',
            week: '%Y/%m/%d',
            month: '%Y/%m',
            year: '%Y',
          },

          // headerFormat: '<span style="font-size: 10px">{point.key:%Y-%m-%d}</span><br/>',
          headerFormat: '<span style="font-size: 11px">{point.key}</span>&nbsp;',
          // headerFormat: '<small>{point.key}</small><table>',
          // xDateFormat: '%Y-%m-%d',
          // xDateFormat: '%A, %b %e %Y, %H:%M:%S',
          //

          // pointFormat: '<tr><td style="color: {series.color}">{series.name}: </td> <td style="text-align: right"><b>{point.y} EUR</b></td></tr>',
          // footerFormat: '</table>',
          // pointFormat: '<span style="color:{point.color}">{series.symbolUnicode}</span> {series.name}: <b>{point.y}</b><br/>',
          pointFormat: '<span style="color:{point.color}">{series.symbolUnicode}</span> {series.name}: <b>{point.y}</b><br/>',
          // pointFormatter: function () {
          //   //console.log('pointFormatter', this.series.options.wg_graph_type,this.series.state, this);
          //   let series_string = '<span style="color:' + this.color + '">' + this.series.symbolUnicode + '</span> ' + this.series.name + ':';
          //   let value_string = ' <b>' + this.y + '</b>';
          //   let end_string = '<br/>';
          //
          //   if (this.series.options.wg_graph_type == 'rgb') {
          //     value_string = ' <b><span style="color:' + this.color + '">\u2588\u2588</span></b>' + this.color;
          //   }
          //   if (this.series.options.wg_graph_type == 'WAKEUP_REASON') {
          //     value_string = ' <b>' + this.y_txt + '</b>';
          //   }
          //
          //   if (this.series.options.wg_graph_type == 'with_confidence_band') {
          //     end_string = '';
          //   }
          //
          //   if (this.series.options.wg_graph_type == 'confidence_band') {
          //     series_string = '';
          //     value_string = graphCtrl.$translate.instant('app.graph.confidenceBand', {low: this.low, high: this.high});
          //     // pointFormat: graphCtrl.$translate.instant('app.graph.confidenceBand', {low: "{point.low}", high: "{point.high}"}) + '<br/>',
          //   }
          //
          //   if (this.series.state == "hover") {
          //     series_string = '<b>' + series_string + '</b>';
          //   }
          //   //   return this.x.toString();
          //   // var point = Highcharts.splat(this.point);
          //
          //   return series_string + value_string + end_string;
          // },
        },
        legend: {
          enabled: true,
          itemStyle: {
            fontWeight: 'normal',
            fontSize: '10px'
          }
        },
        loading: {
          style: {
            backgroundColor: '#f0f0f0',
          },
          labelStyle: {
            color: '#515253',
            // fontWeight: "bold",
            // position: "relative",
            top: "1%",
          },
        },

        title: {
          align: 'left',
          // text: 'Graph'
          text: ''
          // useHTML: true,
          // text: '<p>&nbsp;</p>'
        },
        chart: {
          // style: {
          //   fontFamily: 'OpenSans-Regular',
          // },
          renderTo: 'HighchartDiv',
          // styledMode: true, // Breaks many Style customizations
          // scrollablePlotArea: {
          //   minHeight: 500,
          //   minWidth: 500,
          // },
          displayErrors: !!WG_debug,
          animation: false,
          height: null, // Scalable-yAxis is bugged, doesn't work if this is set
          // height: '60%', // Percentage of width
          width: null, // Fills div
          // width: 800,
          // backgroundColor:'transparent',

          panning: {
            enabled: this.styles.panning,
            type: this.styles.panning_mode,
          },
          // This is reset according to the selected control mode
          panKey: 'shift',

          alignTicks: false,
          zooming: {
            pinchType: 'x',
            // Decides in what dimensions the user can zoom by dragging the mouse. Can be one of null, 'x', 'y' or 'xy'.
            type: 'x',
            // type: isTouch() || isMobileOrTablet() ? null : 'x',
          },
          events: {
            load: function (event: Event) {
              if (WG_debug) console.log('Event: Chart load', event);
              this.reflow();
              // this.showLoading();
              //   // let chart = <any>event.target as Highcharts.ChartObject;
              //
              //   // console.log('xAxis', this.xAxis);
              //   // console.log('yAxis', this.yAxis);
              //   // for (let id in this.yAxis) {
              //   //   // let yAxis = <Highcharts.Axis>this._chart.get(this.yAxis[id].id);
              //   //   // let yAxis = <Highcharts.Axis>this._chart.get(id);
              //   //
              //   //   let yAxis = <Highcharts.Axis>chart.get(id);
              //   //   console.log('yAxis data', id, 'isUserExtremes', this.yAxis[id].isUserExtremes, this.yAxis[id], yAxis);
              //   //   //yAxis.setExtremes(this.yAxis[id].min, this.yAxis[id].max);
              //   // }
            },
            selection: (event) => {
              if (WG_debug) console.log('Event: Chart selection', event);
              if (event.xAxis && this.addProcess_enabled) {
                this.$scope.$emit('graph.band.selection', event);
                event.preventDefault();
              }
              return true;
            },
            click: (event) => {
              if (WG_debug) console.log('Event: Chart click', event);
              if (!this.addFlag_enabled && !this.addManualEntry_enabled) {
                return false;
              }

              let timestamp = null;
              for (let _xAxis of event?.['xAxis']) {
                let id = _xAxis.id || _xAxis['axis']?.userOptions?.id || _xAxis['axis']?.id;
                if (!id || !id.startsWith('navigator')) {
                  timestamp = (Math.floor((_xAxis.value) / 1000.0 / 60.0) * 60 * 1000);
                  break;
                }
              }

              let device = _.values(this.graphedSeries)[0]?.device || _.values(graphCtrl.graphedDevices)[0]?.device;

              if (this.addFlag_enabled) {
                this.addFlag(device, null, timestamp);
              } else if (this.addManualEntry_enabled) {
                this.addManualEntry(device, null, timestamp, null);
                // } else {
                //   this.$scope.$emit('graph.chart.click', event);
              }
              return true;
            },
            // @ts-ignore
            mousewheel: (event: Event): boolean => {
              if (WG_debug) console.log('Event: Chart mousewheel', event);
              return true;
            },
          }
        },
        scrollbar: {
          enabled: true,
          liveRedraw: true,
        },
        navigator: {
          enabled: true,
          adaptToUpdatedData: true, // Safe to use. Disable when we use async data-loading based on selected ranges
          stickToMax: undefined,
          // xAxis: {
          //   overscroll: 60 * 60 * 1000,
          // },
          series: [{
            // type: 'areaspline',
            type: 'line',
            fillOpacity: 0.05,
            dataGrouping: {
              enabled: true
            },
            lineWidth: 0.8,
            marker: {
              enabled: true
            },
          }]
        },
        rangeSelector: {
          // selected: undefined,
          // selected: 2,
          allButtonsEnabled: false,
          enabled: !this.isTouch, // true
          inputEnabled: false,
          buttons: [
            {
              type: 'day',
              count: 1,
              offsetMin: -5 * 60 * 1000,
              offsetMax: 5 * 60 * 1000,
              // query: '1d',
              text: '1' + this.$translate.instant('highchart.buttons.d')
            }, {
              type: 'week',
              count: 1,
              offsetMin: -10 * 60 * 1000,
              offsetMax: 10 * 60 * 1000,
              // query: '1w',
              text: '1' + this.$translate.instant('highchart.buttons.w')
            }, {
              type: 'month',
              count: 1,
              offsetMin: -30 * 60 * 1000,
              offsetMax: 30 * 60 * 1000,
              // query: '1mth',
              text: '1' + this.$translate.instant('highchart.buttons.m')
            }, {
              type: 'month',
              count: 3,
              offsetMin: -30 * 60 * 1000,
              offsetMax: 30 * 60 * 1000,
              // query: '3mth',
              text: '3' + this.$translate.instant('highchart.buttons.m')
            }, {
              type: 'month',
              count: 6,
              offsetMin: -60 * 60 * 1000,
              offsetMax: 60 * 60 * 1000,
              // query: '6mth',
              text: '6' + this.$translate.instant('highchart.buttons.m')
            }, {
              type: 'year',
              count: 1,
              offsetMin: -60 * 60 * 1000,
              offsetMax: 60 * 60 * 1000,
              // query: '1y',
              text: '1' + this.$translate.instant('highchart.buttons.y')
            }, {
              type: 'all',
              offsetMin: -60 * 60 * 1000,
              offsetMax: 60 * 60 * 1000,
              // query: '30y',
              text: this.$translate.instant('app.common.ALL')
            }]
        },
        // navigation: {
        //   buttonOptions: {
        //     'verticalAlign': 'bottom',
        //     y: -20,
        //     theme: {
        //       fill: '#f7f7f7',
        //       stroke: '#444444',
        //       // r: 0,
        //       // 'stroke-width': 0,
        //       // style: {
        //       //   color: '#444',
        //       //   cursor: 'pointer',
        //       //   fontWeight: 'normal'
        //       // },
        //       // states: {
        //       //   hover: {
        //       //     fill: '#e7e7e7'
        //       //   },
        //       //   select: {
        //       //     fill: '#e7f0f9',
        //       //     style: {
        //       //       color: 'black',
        //       //       fontWeight: 'bold'
        //       //     }
        //       //   }
        //       // }
        //     }
        //   }
        // },
        exporting: {
          // url: 'https://charts.winegrid.com/',
          url: 'https://export.highcharts.com/',
          enabled: false,
          sourceWidth: 1200,
          sourceHeight: 700,
          scale: 1.5,
          filename: ``,
          // fallbackToExportServer: true,
          fallbackToExportServer: false,
          buttons: {
            contextButton: {
              // height: 40,
              // width: 40,
              x: -10,
              y: 0,
              // symbolX: 17,
              // symbolY: 16,
              // symbolSize: 32,
              symbol: 'download',
              text: '',
              titleKey: 'exportButtonTitle',
              menuItems: ['export', 'png', 'jpg', 'jpg_2', 'jpg_3', 'pdf', 'pdf_2', 'pdf_3', 'pdf_local', 'pdf_local2', 'svg', 'svg_2']
            },
          },
          menuItemDefinitions: {
            export: {
              text: this.$translate.instant('app.overview.multi.EXPORT_DATA'),
              onclick: () => {
                this.goToExportData();
              }
            },
            png: {
              text: this.$translate.instant('highchart.lang.downloadPNG'),
              onclick: () => {
                if (WG_debug) console.warn('Exporting PNG', this);
                this._chart.exportChartLocal({type: 'image/png'}, this._chart.options);
              }
            },
            jpg: {
              text: this.$translate.instant('highchart.lang.downloadJPEG'),
              onclick: () => {
                if (WG_debug) console.warn('Exporting JPG', this);
                this._chart.exportChart({type: 'image/jpeg'}, null);
              }
            },
            jpg_2: {
              text: this.$translate.instant('highchart.lang.downloadJPEG') + ' 2',
              onclick: () => {
                if (WG_debug) console.warn('Exporting JPG chartConfig', this);
                this._chart.exportChart({type: 'image/jpeg'}, this.chartConfig);
              }
            },
            jpg_3: {
              text: this.$translate.instant('highchart.lang.downloadJPEG') + ' 3',
              onclick: () => {
                if (WG_debug) console.warn('Exporting JPG _chart.options', this);
                this._chart.exportChart({type: 'image/jpeg'}, this._chart.options);
              }
            },
            pdf: {
              text: this.$translate.instant('highchart.lang.downloadPDF'),
              onclick: () => {
                if (WG_debug) console.warn('Exporting PDF', this);
                this.exportChartAs('pdf');
                // this._chart.exportChart({type: 'application/pdf'}, null);
              }
            },
            pdf_2: {
              text: this.$translate.instant('highchart.lang.downloadPDF') + ' 2',
              onclick: () => {
                if (WG_debug) console.warn('Exporting PDF chartConfig', this);
                this._chart.exportChart({type: 'application/pdf'}, this.chartConfig);
              }
            },
            pdf_3: {
              text: this.$translate.instant('highchart.lang.downloadPDF') + ' 3',
              onclick: () => {
                if (WG_debug) console.warn('Exporting PDF _chart.options', this);
                this._chart.exportChart({type: 'application/pdf'}, this._chart.options);
              }
            },
            pdf_local: {
              text: this.$translate.instant('highchart.lang.downloadPDF') + ' local',
              onclick: () => {
                if (WG_debug) console.warn('Exporting local PDF', this);
                this._chart.exportChartLocal({type: 'application/pdf'}, this._chart.options);
              }
            },
            pdf_local2: {
              text: this.$translate.instant('highchart.lang.downloadPDF') + ' local 2',
              onclick: () => {
                if (WG_debug) console.warn('Exporting local PDF 2', this);
                this._chart.exportChartLocal({type: 'application/pdf'}, this.chartConfig);
              }
            },
            svg: {
              text: this.$translate.instant('highchart.lang.downloadSVG') + ' local',
              onclick: () => {
                if (WG_debug) console.warn('Exporting local SVG chartConfig', this);
                this._chart.exportChartLocal({type: 'image/svg+xml'}, this.chartConfig);
              }
            },
            svg_2: {
              text: this.$translate.instant('highchart.lang.downloadSVG') + '',
              onclick: () => {
                if (WG_debug) console.warn('Exporting SVG chartConfig', this);
                this._chart.exportChart({type: 'image/svg+xml'}, this.chartConfig);
              }
            },
            // },
            // buttons: {
            //   contextButton: {
            //     // height: 32,
            //     // width: 34,
            //     // symbolX: 17,
            //     // symbolY: 16,
            //     // symbolSize: 20,
            //     symbol: 'download',
            //     text: '',
            //     titleKey: 'exportButtonTitle',
            //     // y: eButtonY,
            //     menuItems: ['export', 'png', 'jpg', 'pdf', 'svg']
            //   },
          },
        },

        xAxis: {
          type: 'datetime', // 'datetime', 'linear'
          title: {text: ''},
          ordinal: false,
          crosshair: (!this.styles.dataAlign_auto ? false : this.styles.crosshair),
          alignTicks: true,
          startOnTick: false,
          endOnTick: false,
          // overscroll: 60 * 60 * 1000,
          maxPadding: 0.005, // Breaks RangeSelector's button selection when sticking to start/end
          minPadding: 0.005,
          minRange: 5 * 60 * 1000, // 5 times 1min reads
          min: null, // This has to be null, so setExtremes(null, any) works respecting minPadding
          max: null, // This has to be null, so setExtremes(any, null) works respecting maxPadding
          events: {
            setExtremes: function (event) {
              // if (WG_debug) console.log('xAxis setExtremes:', event.trigger, _.cloneDeep(this), _.cloneDeep(event));

              // if (event.trigger === 'rangeSelectorButton') {
              //   // if (WG_debug) console.log('rangeSelector Zooming');
              //   //   let _extremes = this.getExtremes();
              //   //   // let dataMax = isFinite(_extremes.dataMax) ? _extremes.dataMax : null;
              //   //   // let dataMin = isFinite(_extremes.dataMin) ? _extremes.dataMin : null;
              //   //   //
              //   let min = isFinite(event.min) ? event.min : null;
              //   let max = isFinite(event.max) ? event.max : null;
              //   //
              //   // if (!isFinite(event.max) || !isFinite(event.min)) {
              //   //   if (WG_debug) console.log('rangeSelectorButton\'s bug detected. Reset with dataMin/dataMax', event);
              //   //   event.preventDefault();
              //   //   setTimeout(function () {
              //   //     graphCtrl.xAxis_main.setExtremes(min, max, true);
              //   //   }, 1);
              //   // }
              //   return;
              //   //   //   }
              // }


              // Detect when we are after the dataMin/dataMax
              let _extremes = this.getExtremes();
              let dataMax = isFinite(_extremes.dataMax) ? _extremes.dataMax : null;
              let dataMin = isFinite(_extremes.dataMin) ? _extremes.dataMin : null;

              let min = isFinite(event.min) ? event.min : null;
              let max = isFinite(event.max) ? event.max : null;

              if (min && dataMin && event.min <= dataMin) {
                min = null;
              }
              if (max && dataMax && event.max >= dataMax) {
                max = null;
              }

              if ((min == null && event.min != null) || (max == null && event.max != null)) {
                // if (WG_debug) console.warn('Setted extremes to support Pading!');
                event.preventDefault();
                this.setExtremes(min, max, true);
                return;
              }

              if (event.trigger === 'navigator' || event.trigger === 'zoom') {
                return;
              }

              if (event.trigger === 'rangeSelectorButton') {
                //   if (!isFinite(event.max) || !isFinite(event.min)) {
                //     if (WG_debug) console.log('rangeSelectorButton\'s bug detected. Reset with dataMin/dataMax', event);
                //     event.preventDefault();
                //     graphCtrl.xAxis_main?.setExtremes(min, max, true); // event.trigger is null/undefined in this call
                return;
                //   }
              }

              // Called when new sensor/parameter is added or data arrives
              if (event.trigger === 'updatedData') {

                // if (WG_debug) console.info("Added to graph: ", id);
                // let configured_min = graphCtrl.get_graph_start(graphCtrl, true);
                // let configured_max = graphCtrl.get_graph_end(graphCtrl, true);
                // if (configured_min != min || configured_max != max) {
                //   // Keep previous zoom if set
                //   min = configured_min || min;
                //   max = configured_max || max;
                //
                //   event.preventDefault();
                //   graphCtrl.xAxis_main?.setExtremes(min, max, true); // event.trigger is null/undefined in this call
                //   return;
                // }
              }
            },
            afterSetExtremes: function (event) {
              //if (WG_debug) console.log('xAxis afterSetExtremes:', event.trigger, _.cloneDeep(this), _.cloneDeep(event));
              // if (event.trigger != 'rangeSelectorButton')
              graphCtrl.setZoomStateParams(event);
              // if (event.trigger === 'rangeSelectorButton') {
              //   event.preventDefault();
              // }
              return true;
            },
            // @ts-ignore
            mousewheel: (event: Event): boolean => {
              if (WG_debug) console.log('Event: xAxis mousewheel', event);
              return true;
            },
          },
        },
        yAxis: [], // Added later
        series: [], // Added later

        // The plotOptions is a wrapper object for config objects for each series type.
        // Options for all series in a chart are given in the plotOptions.series object.
        // Options for all series of a specific type are given in the plotOptions of that type, for example plotOptions.line.
        // The config objects for each series can also be overridden for each series item as given in the series array.
        plotOptions: {
          line: {
            // cropThreshold: 500,
            // boostThreshold: 0,
            // turboThreshold: 0,
            dataLabels: {enabled: false},
            lineWidth: 2,
            opacity: 0.8,
            marker: { // Used to indicate where there is actual data
              enabled: true,
              lineWidth: this.styles.markers_outline_width,
              lineColor: this.styles.markers_outline_color,
              radius: this.styles.markers_size,
              // lineWidth: 1, // Outline. becomes 1px when hovering
              // lineColor: '#FFFFFF10', // Outline

              symbol: 'circle', // Changed per series.
              // fillOpacity: 0.3, // Useless as fillColor is always specified later while creating the series
              // fillColor: undefined, // Changed per series.
              states: {
                hover: {
                  radiusPlus: 4,
                  lineWidthPlus: 0.8,
                },
              },
            },
            // Determines whether the series should look for the nearest point in both dimensions or just the x-dimension when hovering the series.
            findNearestPointBy: this.styles.findNearestPointBy,
            dataGrouping: {
              //   enabled: true,
              //   groupPixelWidth: this.styles.dataGrouping_groupPixelWidth,
              anchor: 'middle',
              firstAnchor: 'middle',
              lastAnchor: 'middle',
              dateTimeLabelFormats: {
                millisecond: ['%Y/%m/%d %H:%M:%S', '%Y/%m/%d %H:%M:%S', '-%H:%M:%S'],
                second: ['%Y/%m/%d %H:%M:%S', '%Y/%m/%d %H:%M:%S', '-%H:%M:%S'],
                minute: ['%Y/%m/%d %H:%M', '%Y/%m/%d %H:%M', '-%H:%M'],
                hour: ['%Y/%m/%d %H:00-59', '%Y/%m/%d %H:%M', '-%H:%M'],
                day: ['%Y/%m/%d', '%Y/%m/%d', '-%m/%d'],
                week: ['%Y/%m/%d', '%Y/%m/%d', '-%m/%d'],
                month: ['%Y/%m', '%Y/%m', '-%m'],
                year: ["%Y", "%Y", "-%Y"],
              },
            }
          },
          series: {
            showInNavigator: true,
            // allowPointSelect: true, // Breaks events, exception when clicking using customEvents
            // lineWidth: 2,
            // dataLabels: {enabled: true}, // Breaks events, exception when clicking a label
            dataGrouping: {
              enabled: true, // This breaks some things: Binary sensors. RGB. wrong impression on spikes/extreme values
              // enabled: false,
              groupAll: true,
              groupPixelWidth: this.styles.dataGrouping_groupPixelWidth,
              anchor: 'middle',
              firstAnchor: 'middle',
              lastAnchor: 'middle',
              // approximation: 'average',
              dateTimeLabelFormats: {
                millisecond: ['%Y/%m/%d %H:%M:%S', '%Y/%m/%d %H:%M:%S', '-%H:%M:%S'],
                second: ['%Y/%m/%d %H:%M:%S', '%Y/%m/%d %H:%M:%S', '-%H:%M:%S'],
                minute: ['%Y/%m/%d %H:%M', '%Y/%m/%d %H:%M', '-%H:%M'],
                hour: ['%Y/%m/%d %H:00-59', '%Y/%m/%d %H:%M', '-%H:%M'],
                day: ['%Y/%m/%d', '%Y/%m/%d', '-%m/%d'],
                week: ['%Y/%m/%d', '%Y/%m/%d', '-%m/%d'],
                month: ['%Y/%m', '%Y/%m', '-%m'],
                year: ["%Y", "%Y", "-%Y"],
              },
            },
            cropThreshold: 200,
            boostThreshold: 0,
            turboThreshold: 0,
            // Determines whether the series should look for the nearest point in both dimensions or just the x-dimension when hovering the series.
            findNearestPointBy: this.styles.findNearestPointBy,
            // getExtremesFromAll: false, // Whether to use the Y extremes of the total chart width or only the zoomed area when zooming in on parts of the X axis.

            events: {
              click: function (event: Event): boolean {
                if (WG_debug) console.log('Event: Series click', event);
                return true;
              },
              // @ts-ignore
              dblclick: function (event: Event): boolean {
                if (WG_debug) console.log('Event: Series dblclick', event);
                return true;
              },
              touchstart: function (event: Event): boolean {
                if (WG_debug) console.log('Event: Series touchstart', event);
                return true;
              },
              contextmenu: function (event: Event): boolean {
                if (WG_debug) console.log('Event: Series contextmenu', event);
                return true;
              },
              mousewheel: function (event: Event): boolean {
                if (WG_debug) console.log('Event: Series mousewheel', event);
                return true;
              },
            },

            point: {
              events: {
                click: function (event): boolean {
                  if (WG_debug) console.log('Event: Point click', event);
                  // this - Highcharts.Point, If dragging, this holds the previous point config!
                  // e.target - Highcharts.Point, the new point config
                  // e.x and e.y - are the new coordinates. Updating series.data after return;
                  // Returning false stops the drag and drops.

                  let _point = <Highcharts.Point>(event.point || event.target?.['point']);
                  let _series_id = _point?.series?.options?.id || _point?.series?.userOptions?.id;
                  if (!_series_id) {
                    if (WG_debug) console.log('Event: Point click canceled', event);
                    return false; // Cancels point selection
                  }
                  let device: IDevice = graphCtrl.graphedSeries[_series_id].device;
                  let sensor: ISensor = graphCtrl.graphedSeries[_series_id].sensor || null;
                  let timestamp = (Math.floor((_point.x) / 1000.0 / 60.0) * 60 * 1000);

                  if (graphCtrl.addManualEntry_enabled) {
                    graphCtrl.addManualEntry(device, sensor, timestamp, null);
                  } else if (graphCtrl.addFlag_enabled) {
                    graphCtrl.addFlag(device, null, timestamp);
                  } else if (sensor?.configs?.manual === true) {
                    // Edit clicked manual entry
                    graphCtrl.addManualEntry(device, sensor, null, _.cloneDeep(_point));
                  } else if (_point.series?.type === 'flags' && (!sensor || sensor.name !== 'Flags AI')) {
                    // Edit clicked flag
                    graphCtrl.addFlag(device, _.cloneDeep(_point), null);
                  } else {
                    graphCtrl.$scope.$emit('graph.point.click', event);
                  }
                  return true; // Allows point selection
                },
                // select: function (event: Event): boolean {
                //   if (WG_debug) console.log('Event: Point select', event);
                //   var point: HighchartsPointObject = <HighchartsPointObject><any>event.target;
                //   // var point: HighchartsPointObject = this;
                //
                //   if (!angular.isDefined(point.x) || point.x == null || new Date(point.x).getTime() == 0) {
                //     console.log('select   series point x missing', point.series.options.id, 'point', point.x, point.y);
                //     return false; // Cancels selection
                //   }
                //   point.update({
                //     marker: {
                //       enabled: true,
                //       states: {
                //         select: {
                //           enabled: true,
                //           // radius: 8
                //         }
                //       }
                //     }
                //   });
                //
                //   graphCtrl.$scope.$emit('graph.point.select', event);
                //   return true;
                // },
                // unselect: function (event: Event): boolean {
                //   if (WG_debug) console.log('Event: Point unselect', event);
                //   var point: HighchartsPointObject = <HighchartsPointObject><any>event.target;
                //   // var point: HighchartsPointObject = this;
                //
                //   graphCtrl.$scope.$emit('graph.point.unselect', event);
                //   // console.log('unselect series', point.series.options.id, 'point', point.x, point.y);
                //   point.update({
                //     marker: {
                //       enabled: false,
                //       states: {
                //         select: {
                //           enabled: false,
                //         }
                //       }
                //     }
                //   });
                //   return true;
                // },
                // @ts-ignore
                dblclick: function (event: Event): boolean {
                  if (WG_debug) console.log('Event: Point dblclick', event);
                  return true;
                },
                // @ts-ignore
                touchstart: function (event: Event): boolean {
                  if (WG_debug) console.log('Event: Point touchstart', event);
                  return true;
                },
                contextmenu: function (event: Event): boolean {
                  if (WG_debug) console.log('Event: Point contextmenu', event);
                  return true;
                },
                mousewheel: function (event: Event): boolean {
                  if (WG_debug) console.log('Event: Point mousewheel', event);
                  return true;
                },
              }
            },
            states: {
              inactive: {
                opacity: 0.5,
              },
            },
          },
          flags: {y: -40,},
        },
      };

      Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
        // @ts-ignore
        this.symbolUnicode = {
          circle: '●',
          diamond: '♦',
          square: '■',
          triangle: '▲',
          'triangle-down': '▼'
          // @ts-ignore
        }[this.symbol] || '●';
      });

      // Initiates the chart!
      graphCtrl._chart = new Highcharts.StockChart(graphCtrl.chartConfig);


      _.forEach(this._chart?.xAxis, (xAxis) => {
        if (!xAxis?.options?.id?.startsWith('navigator')) {
          graphCtrl.xAxis_main = xAxis;
          return false;
        }
      });
      //if (WG_debug) console.log('graphCtrl.xAxis_main', graphCtrl.xAxis_main);

      graphCtrl.xAxis_main.setExtremes(this.get_graph_start(this, true), this.get_graph_end(this, true), false);

      graphCtrl.yAxisScaleStep = 0.10;
      // Used only for debug purposes ??
      // graphCtrl.yAxis = graphCtrl.$scope['yAxis'] = {};
      // graphCtrl.xAxis = graphCtrl.$scope['xAxis'] = {};


      graphCtrl.goToExportData = function () {
        // id required
        let _devices_id = [];
        let _params_id = [];

        _.forEach(graphCtrl.graphedSeries, function (_series) {
          if (_series?.device?.id) {
            _devices_id.push(_series.device.id);
          }
          if (_series?.sensor?.id) {
            _params_id.push(_series.sensor.id);
          }
        });

        if (_.isEmpty(_devices_id) || _.isEmpty(_params_id)) {
          console.error('No devices or params passed?');
          return;
        }

        let extremes = {min: undefined, max: undefined};
        if (graphCtrl.xAxis_main?.getExtremes) {
          extremes = graphCtrl.xAxis_main.getExtremes();
        }

        graphCtrl.$state.go('app.devices.export', {
          'devices': _devices_id,
          'params': _params_id,
          'start': extremes.min,
          'end': extremes.max,
        });
      };

      // graphCtrl.setZoomStateParams();
    }

    private translate(obj: any) {
      return this.DataUtils.translate(obj);
      // return obj[this.$translate.use()] || obj['en-GB'] || obj;
    }

    private exportData(format: string) {
      this.ngDialog.openConfirm({
        template: 'graphModalDialogExport',
        className: 'ngdialog-theme-default',
        data: {
          devices: this.graphedDevices,
          extremes: this.xAxis_main.getExtremes(),
        }
      }).then((data: { selected: { device: IDevice, sensor: ISensor } }) => {
        console.log('graphModalDialogExport', data);
        let extremes = this.xAxis_main.getExtremes();
        let filename = this._chart.options.exporting.filename || this.chartConfig.exporting.filename || this._chart.userOptions.exporting.filename;
        this.$window.open(this.Data.getUrlExport(data.selected.device, data.selected.sensor, extremes.dataMin, extremes.dataMax, filename, format), '_blank');
      }, (reason) => {
        console.log('Modal promise rejected. Reason: ', reason);
      });
    }

    private setZoomType(zoomType: Highcharts.OptionsTypeValue, scalable_yAxis: boolean = false): void {
      if (WG_debug) console.log('graph setZoomType: ', zoomType);

      // _.forEach(this._chart.yAxis, (_yAxis: Highcharts.Axis) => {
      //   _yAxis.options.scalable = scalable_yAxis;
      // });

      // this.styles.panning = false;
      // this._chart.update({chart: {panning: {enabled: false}}}, false);
      this.styles.zoomType = zoomType;
      this._chart.update({chart: {zooming: {type: zoomType}}}, false);

    }

    private setZoomAxis(axis: Highcharts.Axis, zoomRatio: number, redraw = true): void {
      // if (WG_debug) console.log('graph setZoomAxis: ', axis, zoomRatio);
      let extremes = axis.getExtremes();
      if (_.isNaN(extremes.min) || _.isNaN(extremes.max)) {
        return;
      }
      let min = extremes.min;
      let max = extremes.max;

      // if(!_.isNaN(extremes.dataMin) && !_.isNaN(extremes.dataMax)) {
      //   min = Math.max(min, extremes.dataMin);
      //   max = Math.min(max, extremes.dataMax);
      // }

      // console.log('             ', 'min', extremes.min, 'max', extremes.max);
      // console.log('         data', 'min', extremes.dataMin, 'max', extremes.dataMax);
      // console.log('         user', 'min', extremes.userMin, 'max', extremes.userMax);

      let w = Math.abs(max - min);
      if (w === 0) {
        w = 0.5;
      }

      let newMin = min + (w * (1 - zoomRatio) / 2.0);
      let newMax = max - (w * (1 - zoomRatio) / 2.0);

      // Swap them to ensure order
      if (newMin > newMax) {
        let _newMin = newMin;
        newMin = newMax;
        newMax = _newMin;
      }

      if (!_.isNaN(extremes.dataMin) && newMin <= extremes.dataMin) {
        newMin = null;
      }
      if (!_.isNaN(extremes.dataMax) && newMax >= extremes.dataMax) {
        newMax = null;
      }

      if (_.isNaN(newMin) || _.isNaN(newMax)) {
        return;
      }
      axis.setExtremes(newMin, newMax, redraw, false);
    }


    public redraw(): void {
      // if (WG_debug) console.time("Graph redraw");

      // let _graphed_series = this.graphedSeries;
      let _graphed_devices = this.graphedDevices;

      this.init();

      // for (let _id in _graphed_series) {
      //   this.getSensor(_graphed_series[_id].device, _graphed_series[_id].sensor);
      // }

      _.forEach(_graphed_devices, (_device) => {
        _.forEach(_device.sensors, (_sensor) => {
          if (_.isEmpty(_sensor.raw_data))
            return;
          this.addData(_device.device, _sensor.sensor, _.cloneDeep(_sensor.raw_data), false, null, true);
          // If we need to re-get the data, to undo any pre-processing/alignment:
          // this.getSensor(_device.device, _sensor.sensor);
        });
      });

      for (let _uuid in _graphed_devices) {
        for (let _internal_name in _graphed_devices[_uuid].sensors) {
          if (_.isEmpty(_graphed_devices[_uuid].sensors[_internal_name].raw_data))
            continue;
          this.addData(_graphed_devices[_uuid].device, _graphed_devices[_uuid].sensors[_internal_name].sensor, _.cloneDeep(_graphed_devices[_uuid].sensors[_internal_name].raw_data));
          // If we need to re-get the data, to undo any pre-processing/alignment:
          // this.getSensor(_graphed_devices[_uuid].device, _graphed_devices[_uuid].sensors[_internal_name].sensor);
        }
      }

      // this._chart?.redraw();
      // this._chart?.reflow();
      // if (WG_debug) console.timeEnd("Graph redraw");
    }


    private reset_dataGrouping() {
      let enable = true;
      if (this.addFlag_enabled || this.addManualEntry_enabled) {
        enable = false;
      }
      // this._chart.update({plotOptions: {series: {dataGrouping: {enabled: enable}}, line: {dataGrouping: {enabled: enable}}}}, true);
      this._chart.update({plotOptions: {series: {dataGrouping: {enabled: enable}}}}, true);
    }

    private activate_crosshair() {

      this._chart.update({
        xAxis: {
          crosshair: {
            snap: false,
            label: {
              enabled: true,
              backgroundColor: this.styles.crosshair.label.backgroundColor,
              format: this.styles.crosshair.label.format,
            },
          },
        },
        tooltip: {enabled: false,},
        plotOptions: {
          series: {states: {hover: {enabled: false}}},
          line: {states: {hover: {enabled: false}}}
        },
      }, true);
    }

    private deactivate_crosshair() {
      this._chart.update({
        xAxis: {crosshair: (!this.styles.dataAlign_auto ? false : this.styles.crosshair),},
        tooltip: {enabled: this.styles.tooltip_type != "none",},
        plotOptions: {
          series: {states: {hover: {enabled: true}}},
          line: {states: {hover: {enabled: true}}}
        },
      }, true);
    }

    public addProcess_enable(enable: boolean): void {
      this.addManualEntry_enabled = false;
      this.addFlag_enabled = false;
      this.addProcess_enabled = enable;
      if (enable) {
        this.activate_crosshair();
      } else {
        this.deactivate_crosshair();
      }
      // this.reset_dataGrouping();
    }

    public addFlag_enable(enable: boolean): void {
      this.addManualEntry_enabled = false;
      this.addFlag_enabled = enable;
      this.addProcess_enabled = false;
      if (enable) {
        this.activate_crosshair();
      } else {
        this.deactivate_crosshair();
      }
      // this.reset_dataGrouping();
    }

    public addManualEntry_enable(enable: boolean): void {
      this.addManualEntry_enabled = enable;
      this.addFlag_enabled = false;
      this.addProcess_enabled = false;
      if (enable) {
        this.activate_crosshair();
      } else {
        this.deactivate_crosshair();
      }
      // this.reset_dataGrouping();
    }

    public addProcess(): void {
      // TODO: add device selection, process creation modal, plot new bands.
      this.addProcess_enable(true);
      let on = this.$scope.$on('graph.band.selection', (_emit_event, event: Highcharts.ChartSelectionContextObject) => {
        on();
        if (!this.addProcess_enabled) {
          return;
        }
        this.addProcess_enable(false);

        var device = {name: 'foo'};
        var id = `plot-band-${new Date().getTime()}`;
        var plotBand: Highcharts.XAxisPlotBandsOptions = {
          id: id,
          from: event.xAxis[0].min,
          to: event.xAxis[0].max,
          color: '#F7F7F7',
        };
        this.xAxis_main.addPlotBand(plotBand);
        this.ngDialog.openConfirm({
          template: 'graphModalDialogAddFlagBand',
          className: 'ngdialog-theme-default',
          data: {
            type: 'Band',
            input: {
              title: true,
            },
            device: device,
          }
          // scope: this.$scope
        }).then((data) => {
          console.log(data);
          plotBand = {
            id: id,
            from: event.xAxis[0].min,
            to: event.xAxis[0].max,
            color: '#FCFFC5',
            label: {
              text: data.title
            }
          };
          this.xAxis_main.removePlotBand(id);
          this.xAxis_main.addPlotBand(plotBand);

          // var payload = {
          //   iid: device.iid,
          //   value: {
          //     text: data.title
          //   },
          //   timestamp: point.x
          // };
          //
          // this.Data.post(device, this.flagSensor, {}, payload).then((resultData: IDataPostResponse) => {
          //   if (resultData.message === 'Success') {
          //     var flag = {
          //       x: payload.timestamp,
          //       title: payload.value.title,
          //       text: payload.value.text,
          //     };
          //     if (this.devices[device.uuid].series[this.flagSensor.internal_name].length == 0) {
          //       this.devices[device.uuid].series[this.flagSensor.internal_name].push(flag);
          //     } else {
          //       var series = [];
          //       angular.copy(this.devices[device.uuid].series[this.flagSensor.internal_name], series);
          //       series.push(flag);
          //       series = _.sortBy(series, function (e: any) {
          //         return e.x;
          //       });
          //       this.devices[device.uuid].series[this.flagSensor.internal_name].length = 0;
          //       series.forEach((f) => {
          //         this.devices[device.uuid].series[this.flagSensor.internal_name].push(f);
          //       });
          //     }
          //   }
          // }, (reason) => {
          //   console.log('Failed to add band. Reason: ', reason);
          // });
        }, (reason) => {
          console.log('Modal promise rejected. Reason: ', reason);
          this.xAxis_main.removePlotBand(id);
        });
      });
    }

    /**
     *
     * @param device
     * @param point - To Edit, instead of Adding
     * @param timestamp - To Add at selected timestamp
     */
    public addFlag(device: IDevice, point: Highcharts.Point = null, timestamp: number = null): void {
      this.addFlag_enable(false);

      // var point: HighchartsPointObject = event['point'] || event.target?.['point'];
      // var device: IDevice = this.series[point?.series?.options?.id]?.device;
      if (!device || (!point?.series?.options?.id && !timestamp)) {
        return;
      }
      console.log('addFlag: ', point?.series.options.id, 'point', point?.x || timestamp, point?.y, point?.series.options.type);

      let data = {
        timestamp: point?.x || timestamp,
      };
      var mode = 'add';
      if (point?.series.options.type === 'flags') {
        console.log('will edit flag');
        data['title'] = point['title'] || '';
        data['text'] = point['text'] || '';
        mode = 'edit';
      } else {
        if (WG_debug) console.log('Will add Flag');
      }
      this.ngDialog.openConfirm({
        template: 'graphModalDialogAddFlagBand',
        className: 'ngdialog-theme-default',
        data: {
          type: 'Flag',
          mode: mode,
          input: {
            title: true,
            text: true,
          },
          data: data,
          device: device,
        },
      }).then((data) => {
        this.addFlag_enable(false);
        let payload = {
          iid: device.iid,
          value: {
            title: data.title || '',
            text: data.text || '',
            deleted: !!data.deleted,
          },
          timestamp: data.timestamp,
        };
        console.log('Saving flag changes', this.flagSensor, payload);
        this.Data.post(device, this.flagSensor, {}, payload).then((resultData: IDataPostResponse) => {
          if (resultData.message === 'Success') {
            var flag = {
              x: payload.timestamp,
              title: payload.value.title,
              text: payload.value.text,
              deleted: !!payload.value.deleted,
            };
            if (WG_debug) console.log('Adding flag', flag);

            let graphDeviceSensor = this._getGraphDeviceSensor(device, this.flagSensor, false);
            let graphedData = graphDeviceSensor?.series?.data;
            let _series = graphDeviceSensor?.series && <Highcharts.Series>this._chart.get(graphDeviceSensor.series.id);
            if (_.isNil(graphedData)) {
              this.getFlags(device, this.flagSensor);
            } else {
              if (payload.value.deleted == true) {
                removeFromArray(graphedData, flag, 'x');
                removeFromArray(graphedData, flag, 0);
              } else {
                insertSortedByInPlace(graphedData, flag, [0, 'x']);
              }

              _series.setData(graphedData, true, false, false);
            }
          }
        }, (reason) => {
          console.log('Failed to add flag. Reason: ', reason);
        });
      }, (reason) => {
        console.log('Modal promise rejected. Reason: ', reason);
      });
    }

    /**
     * Show modal allowing deletion of Flags and Manual Entries
     */
    public addManualEntry(device: IDevice, sensor: ISensor = null, timestamp: number = null, point: Highcharts.Point = null) {

      if (!device) {
        this.error_message = "Missing device.";
        return;
      }
      if(_.startsWith(sensor?.internal_name, 'external')) {
        console.info("Can't edit entries added from API");
        return;
      }

      // if (sensor?.configs?.manual === true) {
      //   console.info('manualEntry edit');
      // } else {
      //   console.info('manualEntry add');
      // }
      this.ngDialog.openConfirm({
        template: 'app/views/modals/manual-entry.html',
        controller: 'ManualEntryModalInstance',
        // scope: $scope,
        data: {
          mode: 'openConfirm',
          device: device,
          sensor: sensor,
          timestamp: point?.['x_orig'] || point?.x || timestamp,
          value: (_.isFinite(point?.y) ? point?.y : undefined),
          observation: point?.['observation'] || undefined,
        },
      }).then((data) => {
        if (WG_debug) console.log('manualEntry return', data);
        this.removeSensor(data.device, data.sensor);
        this.getSensor(data.device, data.sensor, {}, true);
        this.addManualEntry_enable(false);
      }, (reason) => {
        if (WG_debug) console.log('manualEntry Fail', reason);
        this.addManualEntry_enable(false);
      })
    }

    private get_graph_start(graphCtrl: GraphController = this, only_params = false): number {
      let _params_val = _.toInteger(graphCtrl.$state?.params?.xAxisMin) || null;
      if (only_params) {
        return _params_val;
      }

      let extremes = graphCtrl.xAxis_main?.getExtremes();
      return _.toInteger(extremes?.min) || _.toInteger(extremes?.dataMin) || _params_val;
    }

    private get_graph_end(graphCtrl: GraphController = this, only_params = false) {
      let _params_val = _.toInteger(graphCtrl.$state?.params?.xAxisMax) || null;
      if (only_params) {
        return _params_val;
      }

      let extremes = graphCtrl.xAxis_main?.getExtremes();
      return _.toInteger(extremes?.max) || _.toInteger(extremes?.dataMax) || _params_val;
    }

    private predicting_fermentation = false;
    private predicting_fermentation_band_listener;

    public predictFermentation(graphCtrl: GraphController = this, _start: number = null, _end: number = null, model: string = ''): void {
      let _device = _.values(graphCtrl.graphedDevices)[0]?.device;

      if (!_device) {
        console.error('No device passed');
        return;
      }
      console.info('main modalFermentationPrediction');
      let options = {
        template: 'app/views/modals/fermentation-prediction.html',
        className: 'medium-Modal ngdialog-theme-default',
        data: {
          device: _device,
          result: 'showFermentationPrediction',
          mode: 'openConfirm',
          start_at: _start,
          end_at: _end,
          auto_detect_start: true,//!(_start && _end),
        }
      };
      if (graphCtrl.AuthService.canAccess('admin') || graphCtrl.AuthService.user.id == 173) {
        options.data.start_at = _start || graphCtrl.get_graph_start(graphCtrl);
        options.data.end_at = _end || graphCtrl.get_graph_end(graphCtrl);
      }
      graphCtrl.ngDialog.openConfirm(options).then(function (data) {
        if (WG_debug) console.log('modalFermentationPrediction processing', data);
        graphCtrl.getFermentSimulatorData(_device, data, model, WG_debug);
      }, function (reason) {
        if (WG_debug) console.log('modalFermentationPrediction Fail. Reason: ', reason);
      });
      return;
    }

    private filter_data_between_timestamps(data: number[][], start_at: number, end_at: number): number[][] {
      if (!(data?.[0]?.[0] || data?.[0]?.['x'])) {
        console.warn("Something's wrong. Wrong data series");
        return null;
      }
      let ret = [];
      for (let i in data) {
        let _time_millis = data[i][0] || data[i]['x'];
        if (start_at && _time_millis < start_at) {
          continue;
        }
        if (end_at && _time_millis > end_at) {
          break;
        }
        ret.push(data[i])
      }
      return ret;
    }

    private AI_processing = false;

    public AI_process(model: string = ''): void {
      let graphCtrl: GraphController = this;
      if (!graphCtrl.AuthService.canAccess('admin') && graphCtrl.AuthService.view_as_owner.id != 297) {
        return; // Safeguard during dev
      }

      if (WG_debug) console.log("AI_process");
      graphCtrl.error_message = "";
      graphCtrl.AI_processing = true;

      let _device_uuid = _.keys(graphCtrl.graphedDevices)[0];
      if (!_device_uuid) {
        graphCtrl.error_message = "Missing device.";
        return;
      }

      let start_at = this.get_graph_start(graphCtrl);
      let end_at = this.get_graph_end(graphCtrl);

      // // Get data series shown in graph
      // let _LLVA_LAT = this.filter_data_between_timestamps(_device_graph.series.LLVA_LAT, start_at, end_at);
      // let _TEMP = graphCtrl.filter_data_between_timestamps(_device_graph.series.TEMP, start_at, end_at);
      // let _LLVA_LAT_TREAT_value = this.filter_data_between_timestamps(_device_graph.series.LLVA_LAT_TREAT_value, start_at, end_at);
      // let _QL_TREAT_LDENSA_massDensity = this.filter_data_between_timestamps(_device_graph.series.QL_TREAT_LDENSA_massDensity, start_at, end_at);
      // let _AI_LDENSA_FermentationKinetic24h = this.filter_data_between_timestamps(_device_graph.series.AI_LDENSA_FermentationKinetic24h, start_at, end_at);
      //
      // if (_.isEmpty(_LLVA_LAT)) {
      //   graphCtrl.error_message = "Missing LLVA_LAT on graph";
      //   return;
      // }
      // if (_.isEmpty(_AI_LDENSA_FermentationKinetic24h)) {
      //   graphCtrl.error_message = "Missing _AI_LDENSA_FermentationKinetic24h on graph";
      //   return;
      // }
      // let ferm_process = estimate_ferm_process_limits(_AI_LDENSA_FermentationKinetic24h, null, _LLVA_LAT, _LLVA_LAT_TREAT_value, null);


      this.get_ferm_process_limits(_device_uuid, start_at, end_at, (graphCtrl: GraphController, _start: number, _end: number) => {

        // Zoom to found extremes
        // let __axis = _.find(graphCtrl._chart?.xAxis, (xAxis: Highcharts.Axis) => {
        //   return xAxis?.options?.id != 'navigator-x-axis'
        // });
        // __axis?.setExtremes(_start, _end, true); // event.trigger is null/undefined in this call

        graphCtrl.predictFermentation(graphCtrl, _start, _end, model);
      })

      graphCtrl.AI_processing = false;
    }

    private getLineSeriesId(device: IDevice, sensor: ISensor): string {
      return `lineSeries-${device.uuid}-${sensor.internal_name}`;
    }

    private getYAxisId(sensor: ISensor): string {
      //if(WG_debug) console.log(' yAxis', sensor.internal_name);
      return `yAxis-${sensor.internal_name}`;
    }

    private _get_sensor_unit(sensor: ISensor) {
      if (sensor.unit && sensor.unit != 'NA' && sensor.unit != 'N/A') {
        return sensor.unit;
      }
      return undefined;
    }

    private resetSeriesColors(): void {
      for (let _color_config of this.used_styles) {
        _color_config.count = 0;
        _color_config.variation = 0;
      }
      this.used_colors = emptyOrCreateArray(this.used_colors);
    }

    /**
     * Get a series-style from the color-palette and symbols-list, respecting statically-defined colors
     * @param internal_name
     * @param master_sensor_name
     * @private
     */
    private getSeriesStyle(internal_name, master_sensor_name): {
      color: string,
      variation: number[],
      dash: string,
      marker: string,
      marker_color: string
    } {
      let _dash = this.dash_styles[0];
      let _marker = this.all_markers[0];
      let _marker_color = this.all_colors[_.random(0, this.all_colors.length - 1, false)];
      let _coefs = [0, 0, 0]; // Variation to chosen color

      let _style = null;
      // Search for a configured color for this internal_name|Master_Sensor_name
      for (let _color_config of this.used_styles) {
        if (_color_config.sensors.includes(master_sensor_name) || _color_config.sensors.includes(internal_name)) {
          _style = _color_config;
          break;
        }
      }
      if (!_style) {
        _style = {
          sensors: [master_sensor_name || internal_name],
          color: null,
          count: 0,
          variation: 0,
        }
        this.used_styles.push(_style);
      }
      if (!_style.color) {
        for (let _color of this.all_colors) {
          if (!this.used_colors.includes(_color)) {
            this.used_colors.push(_color);
            _style.color = _color;
            break;
          }
        }
      }
      if (!_style.color) {
        // Else, not enough colors? get a random one
        _style.color = rgbToHex(_.random(0, 255, false), _.random(0, 255, false), _.random(0, 255, false));
        this.used_colors.push(_style.color);
      }

      // if (WG_debug) console.log("Graph Color found.", _color_config);
      _style.count = _style.count || 0;


      if (_style.count > 0 && (this.styles.line_color_variations || this.styles.line_color_variations_strong)) {
        if (this.styles.line_color_variations_strong) {
          this.color_variations = this.color_variations_strong;
        }

        _style.variation = _style.variation || 0;
        // Generate a hard-variation for 2º+ entry of same parameter
        let _rgb = hexToRgb(_style.color);
        do {
          _coefs = this.color_variations[_style.variation % this.color_variations.length];

          _style.variation++;
          if (_style.variation >= this.color_variations.length * 2) {
            break; // Sanity. Avoid buggy infinite loops
          }
          // If significant saturation occurred with this variation, try next one
        } while (_rgb[0] + _coefs[0] > 270 || _rgb[0] + _coefs[0] < -15
        || _rgb[1] + _coefs[1] > 270 || _rgb[1] + _coefs[1] < -15
        || _rgb[2] + _coefs[2] > 270 || _rgb[2] + _coefs[2] < -15)

        _style.variation = _style.variation % this.color_variations.length;
        if (_coefs) {
          _style.color = rgbToHex(_rgb[0] + _coefs[0], _rgb[1] + _coefs[1], _rgb[2] + _coefs[2]);
        }
      }

      if (_style.count > 0 && this.styles.dashed_lines) {
        _dash = this.dash_styles[_style.count % this.dash_styles.length];
      }
      _marker = this.all_markers[_style.count % this.all_markers.length];

      _marker_color = _style.color;
      if (_style.count > 0 && this.styles.colorful_markers) {
        _marker_color = this.all_colors[(_style.count - 1) % this.all_colors.length];
      }

      _style.count++;

      return {color: _style.color, variation: _coefs, dash: _dash, marker: _marker, marker_color: _marker_color};
    }

    private newLineSeries(device: IDevice, sensor: ISensor, data: Highcharts.SeriesLineOptions['data']): IGraphedDeviceSensor['series'] {
      let graphCtrl = this;

      if (!sensor.configs) {
        sensor.configs = {};
      }
      let _series_style = this.getSeriesStyle(sensor.internal_name, sensor.configs.masterSensor || null);
      this.colorlabel = _series_style.color; // Save globally to use same color in yAxis

      let sensor_name: string = this.$translate.instant(sensor.name_sref || sensor.name);
      if (sensor.stream.startsWith("MESHVINES_SIMULATOR")) {
        sensor_name = "MV " + sensor_name;
      }

      let _unit = this.WGApiData.WGDevices?.devices_id?.[device.id]?.unit;
      let series_name = (_unit?.name || device.name) + `: ` + sensor_name;

      //if (WG_debug) console.log('adding Line Series', _series_style, device, sensor);
      let newSeries: (Highcharts.SeriesLineOptions | Highcharts.SeriesArearangeOptions | Highcharts.SeriesErrorbarOptions) = {
        type: 'line',
        visible: true,
        name: series_name,
        data: data,
        id: this.getLineSeriesId(device, sensor),
        color: _series_style.color,
        dataGrouping: {},
        findNearestPointBy: this.styles.findNearestPointBy,
        marker: {
          symbol: _series_style.marker,
          // color: '#00AA00F0',
          // lineWidth: this.styles.markers_outline_width,
          // lineColor: this.styles.markers_outline_color,
          // radius: this.styles.markers_size,
          fillColor: _series_style.marker_color + this.styles.markers_opacity,
        },
        tooltip: {
          valueDecimals: sensor.configs.decimals,
        },
        // @ts-ignore
        wg_graph_type: sensor.configs.graph_type || '',
      };

      let sensor_unit = this._get_sensor_unit(sensor);
      if (angular.isDefined(sensor_unit)) {
        newSeries.tooltip.valueSuffix = ` ${sensor_unit}`;
        newSeries['unit'] = sensor_unit;
      }


      if (sensor.configs.manual) {
        if (newSeries.type !== "errorbar") {
          newSeries.marker = {
            enabled: true,
            radius: 6,
            symbol: 'circle',
          };
        }
        newSeries.dashStyle = 'Dash';
        newSeries.dataGrouping = _.assign(newSeries.dataGrouping, {enabled: false});
        if (WG_debug) console.log('Graph manual series', newSeries);
      }
      if (sensor.configs.graph_type === 'rgb') {
        newSeries = <Highcharts.SeriesLineOptions>newSeries;
        newSeries.marker = {
          enabled: true,
          radius: 8,
          lineWidth: 0.1,
          // symbol: 'circle',
          symbol: 'square',

          lineColor: '#000000',
          states: {
            hover: {
              radius: 16,
              lineWidth: 0.7,
            }
          }
        };
        // newSeries.cropThreshold = 50;
        newSeries.boostThreshold = 0;
        newSeries.turboThreshold = 0;

        // newSeries.dataGrouping = _.assign(newSeries.dataGrouping, {enabled: false});
        if (WG_debug) console.log('Setting dataGrouping approximation: custom RGB (last)');
        newSeries.dataGrouping = _.assign(newSeries.dataGrouping, {
          enabled: true,
          groupPixelWidth: this.styles.dataGrouping_groupPixelWidth,
          // approximation: "close",
          // approximation: "low",
          // approximation: "average",
          approximation: function (groupData) {
            if (!this.dataGroupInfo.length || _.isEmpty(groupData))
              return;
            // let last = _.last(groupData)
            // Get raw_data including color directly from options.data
            let last_raw = this.options.data[this.dataGroupInfo.start + this.dataGroupInfo.length - 1];

            // if (WG_debug) console.info("RGBs!", groupData);
            // Individual point options can be applied to the grouped points
            this.dataGroupInfo.options = {
              // @ts-ignore
              color: last_raw.color,
              rgb: last_raw.rgb,
            };

            return last_raw.y;
          },
        });

        // lineSeries.type = 'scatter';
        newSeries.lineWidth = 0;
        // lineSeries.tooltip.pointFormat = '<span style="color:{point.color}">\u25CF</span> {series.name}: <b><span style="color:{point.color}">\u25A0</span></b><br/>'
        newSeries.tooltip.pointFormat = '<span style="color:{point.color}">{series.symbolUnicode}</span> {series.name}: <b><span style="color:{point.color}">\u2588\u2588</span> {point.color} - ({point.rgb})</b><br/>';
        if (WG_debug) console.log('Graph rgb series', newSeries);
      }

      if (sensor.internal_name === "CO2") {
        newSeries.zones = [{
          value: 1000,
          color: rgbToHex(100 + _series_style.variation[0], 255 + _series_style.variation[1], 100 + _series_style.variation[2]),
          // color: 'rgb(100,255,100,1)',
        }, {
          value: 1500,
          color: rgbToHex(255 + _series_style.variation[0], 255 + _series_style.variation[1], 100 + _series_style.variation[2]),
          // color: 'rgb(255,255,100,1)',
        }, {
          value: 2000,
          color: rgbToHex(255 + _series_style.variation[0], 200 + _series_style.variation[1], 100 + _series_style.variation[2]),
          // color: 'rgb(255,200,100,1)',
        }, {
          value: 3000,
          color: rgbToHex(255 + _series_style.variation[0], 100 + _series_style.variation[1], 100 + _series_style.variation[2]),
          // color: 'rgb(255,100,100,1)',
        }, {
          color: rgbToHex(180 + _series_style.variation[0], 0 + _series_style.variation[1], 0 + _series_style.variation[2]),
          // color: 'rgb(180,0,0,1)',
        }];
      }


      if (sensor.internal_name === "WAKEUP_REASON") {
        newSeries = <Highcharts.SeriesLineOptions>newSeries
        newSeries.lineWidth = 0;
        // newSeries.color = "#231226";
        newSeries.states = {hover: {lineWidthPlus: 0}};
        // @ts-ignore
        newSeries.translations = { // Statically define translations to support conversions on custom dataGrouping.approximation
          0: this.DataUtils.convert_wakeup_reason(0, device),
          1: this.DataUtils.convert_wakeup_reason(1, device),
          2: this.DataUtils.convert_wakeup_reason(2, device),
          3: this.DataUtils.convert_wakeup_reason(3, device),
          4: this.DataUtils.convert_wakeup_reason(4, device),
          5: this.DataUtils.convert_wakeup_reason(5, device),
          6: this.DataUtils.convert_wakeup_reason(6, device),
          7: this.DataUtils.convert_wakeup_reason(7, device),
          8: this.DataUtils.convert_wakeup_reason(8, device),
          9: this.DataUtils.convert_wakeup_reason(9, device),
          10: this.DataUtils.convert_wakeup_reason(10, device),
          11: this.DataUtils.convert_wakeup_reason(11, device),
        };

        // TODO: This is not working as expected. The translation/y_txt is not being shown on the tooltip
        if (WG_debug) console.log('Setting dataGrouping approximation: custom WAKE');
        newSeries.dataGrouping = _.assign(newSeries.dataGrouping, {
          enabled: true,
          groupPixelWidth: this.styles.dataGrouping_groupPixelWidth,
          // approximation: "low",
          approximation: function (groupData) {
            if (!this.dataGroupInfo.length || _.isEmpty(groupData))
              return;
            if (this.dataGroupInfo.length == 1)
              return groupData[0];

            let min = _.min(groupData);

            if (WG_debug && min == 3) {
              // Alternative method, getting raw_data including y_txt directly from options.data
              let _raw_data = this.options.data.slice(this.dataGroupInfo.start, this.dataGroupInfo.start + this.dataGroupInfo.length);
              console.warn("Button_Pressed!", min, _raw_data);
            }

            let entries = (_.uniq(groupData)?.sort() || [min]) as number[];
            let _y_txts: string[] = [];
            for (let entry of entries) {
              _y_txts.push(this.options.translations[entry]);
            }

            // Individual point options can be applied to the grouped points
            this.dataGroupInfo.options = {
              y_txt: _.join(_y_txts, '+'),
            };

            return min;
          },
        });

        newSeries.marker = _.assign(newSeries.marker, {
          enabled: true,
          radius: 6,
          symbol: _series_style.marker || "diamond",
          lineColor: _series_style.marker_color + 'F0',
          fillColor: _series_style.marker_color + 'E0',
        });
        // if (!newSeries.wg_graph_type) {
        //   newSeries.wg_graph_type = "WAKEUP_REASON";
        // }
        newSeries.tooltip.pointFormat = "<span style='color:{series.color}'>{series.symbolUnicode}</span> {series.name}: {point.y} <b>{point.y_txt}</b><br/>";
        // newSeries.tooltip.pointFormat = "<span style='color:{series.color}'>{series.symbolUnicode}</span> {series.name}: <b>{point.y_txt}</b>";
        if (WG_debug) console.log('Graph line with_confidence_band. Removing <br/>', newSeries.tooltip.pointFormat);
      }

      if (sensor.configs.graph_type === 'with_confidence_band') {
        newSeries.tooltip = _.assign(newSeries.tooltip, {pointFormat: _.replace(this.chartConfig.tooltip.pointFormat, '<br/>', '')});
        if (WG_debug) console.log('Graph line with_confidence_band. Removing <br/>', newSeries.tooltip.pointFormat);
      }
      if (sensor.configs.graph_type === 'arearange' || sensor.configs.graph_type === 'confidence_band') {
        newSeries = <Highcharts.SeriesArearangeOptions><unknown>newSeries
        newSeries.type = 'arearange';
        newSeries.lineWidth = 1.0;
        newSeries.fillOpacity = 0.4;
        newSeries.opacity = 0.4;
        newSeries.states = {hover: {enabled: false}};
        newSeries.marker = {enabled: false};
        // newSeries.findNearestPointBy = 'xy';
        if (sensor.configs.graph_type === 'confidence_band') {
          if (WG_debug) console.log('Graph Confidence_band: connecting to previous series');
          newSeries.linkedTo = ':previous';
          newSeries.tooltip = _.assign(newSeries.tooltip, {
            pointFormat: this.$translate.instant('app.graph.confidenceBand', {
              low: "{point.low}",
              high: "{point.high}"
            }) + '<br/>'
          });
        }
        if (WG_debug) console.log('Setting dataGrouping approximation: range');
        newSeries.dataGrouping = _.assign(newSeries.dataGrouping, {approximation: "range"});
      }
      if (sensor.configs.graph_type === 'errorbar') {
        newSeries = <Highcharts.SeriesErrorbarOptions><unknown>newSeries;
        newSeries.type = 'errorbar';
        newSeries.whiskerLength = "100%";
        newSeries.whiskerWidth = 3;
        // newSeries.linkedTo = ':previous';
        // newSeries.tooltip = _.assign(newSeries.tooltip, {pointFormat: this.$translate.instant('app.graph.confidenceBand', {low: "{point.low}", high: "{point.high}"}) + '<br/>'});
        // newSeries.tooltip.pointFormat = '(error range: {point.low}-{point.high}°C)<br/>';
        // }
      }

      if (!_.isNil(sensor.configs.graph_options?.tooltip)) {
        if (WG_debug) console.log('Graph custom tooltip config', sensor.configs.graph_options.tooltip);
        newSeries.tooltip = _.assign(newSeries.tooltip, sensor.configs.graph_options.tooltip);
      }
      if (!_.isNil(sensor.configs.graph_options?.dataGrouping)) {
        if (WG_debug) console.log('Graph custom dataGrouping config', sensor.configs.graph_options.dataGrouping);
        if (sensor.configs.graph_options.dataGrouping === false) {
          if (WG_debug) console.log('Setting dataGrouping enabled: false');
          newSeries.dataGrouping = _.assign(newSeries.dataGrouping, {enabled: false});
        } else {
          if (WG_debug) console.log('Setting dataGrouping: sensor.configs.graph_options.dataGrouping');
          newSeries.dataGrouping = _.assign(newSeries.dataGrouping, sensor.configs.graph_options.dataGrouping);
        }
      }

      if (!_.isNil(sensor.configs.graph_options?.dashStyle)) {
        if (WG_debug) console.log('Graph custom dashStyle config', sensor.configs.graph_options.dashStyle);
        newSeries.dashStyle = sensor.configs.graph_options.dashStyle;
      }

      if (!newSeries.dashStyle) {
        newSeries.dashStyle = <Highcharts.DashStyleValue>(_series_style.dash || 'Solid');
      }
      //
      // this.graphedSeries[newSeries.id] = {
      //   device: device,
      //   sensor: sensor,
      // };

      return newSeries;
    }

    private newYAxis(sensor: ISensor): Highcharts.AxisOptions {
      //if(WG_debug) console.log('new yAxis', sensor.internal_name, sensor.configs.decimals);
      let graphCtrl: GraphController = this;
      let unit = this._get_sensor_unit(sensor);
      if (!sensor.configs) {
        sensor.configs = {};
      }
      if (!sensor.configs.graph_options) {
        sensor.configs.graph_options = {};
      }
      if (_.isNil(sensor.configs.decimals)) {
        sensor.configs.decimals = 3;
      }
      let unit_text = unit ? ` (${unit})` : '';
      let sensor_name: string = this.$translate.instant(sensor.name_sref || sensor.name);
      let yAxis: Highcharts.YAxisOptions = {
        id: this.getYAxisId(sensor),
        opposite: true,
        minRange: sensor.configs.graph_options.minRange || undefined,
        floor: sensor.configs.graph_options.floor || undefined,
        min: sensor.configs.graph_options.min || null,
        max: sensor.configs.graph_options.max || null,
        softMin: sensor.configs.graph_options.softMin || undefined,
        softMax: sensor.configs.graph_options.softMax || undefined,

        maxPadding: 0.005,
        minPadding: 0.005,
        endOnTick: false,
        startOnTick: false,
        // tickAmount: 4,
        tickPixelInterval: 30,
        showEmpty: false,
        title: {
          text: this.$window.innerWidth < 480 ? null : `${sensor_name}${unit_text}`,
          style: {
            color: this.colorlabel
          }
        },
        labels: {
          align: 'left',
          x: 1,
          y: 3,

          style: {
            color: this.colorlabel,
            fontSize: "8px",
          },
          format: `{value:.${sensor.configs.decimals}f}`,
          // formatter: function () {
          //   var label = this.axis.defaultLabelFormatter.call(this);
          //
          //   // Use thousands separator for four-digit numbers too
          //   // if (/^[0-9]{4}$/.test(label)) {
          //   //   return Highcharts.numberFormat(this.value, 0);
          //   // }
          //
          //   if (!_.isNil(sensor.conversion?.decimals)) {
          //       return Highcharts.numberFormat(this.value, sensor.conversion.decimals);
          //     // return _.round(this.value, sensor.conversion.decimals);
          //   }
          //   if (!_.isNil(sensor.configs?.decimals)) {
          //     return Highcharts.numberFormat(this.value, sensor.configs.decimals);
          //     // return _.round(this.value, sensor.configs.decimals);
          //   }
          //   //   if(WG_debug) console.log('yAxis label formatter', sensor.configs.decimals, label, this.label);
          //   return label;
          // },
          // formatter: function() {
          //   let value = this.axis.defaultLabelFormatter.call(this);
          //   if(WG_debug) console.log('yAxis label formatter', sensor.configs.decimals, value, this.value);
          //   return value;
          // }
        },
        gridLineWidth: 0,
        scalable: true,
        events: {

          setExtremes: function (event) {
            if (WG_debug) console.log('yAxis setExtremes:', event.trigger, _.cloneDeep(this), _.cloneDeep(event));
          },
          //afterSetExtremes: function (event) {
          //if (WG_debug) console.log('yAxis afterSetExtremes:', event.trigger, _.cloneDeep(this), _.cloneDeep(event));
          //   let yAxis = this || event.target as unknown as Highcharts.Axis;
          //   // if (WG_debug) console.log("yAxis afterSetExtremes", event);
          //   // Redo the zoom considering the visible data only
          //   if (graphCtrl.timerYAxisAfterSetExtremes)
          //     graphCtrl.$timeout.cancel(graphCtrl.timerYAxisAfterSetExtremes);
          //   graphCtrl.timerYAxisAfterSetExtremes = graphCtrl.$timeout(function () {
          //     // anything you want can go here and will safely be run on the next digest.
          //     // cb();
          //     if (yAxis.options.id !== undefined && yAxis.options.id.indexOf('yAxis-') != -1) {
          //       let extremes = yAxis.getExtremes();
          //       // if (WG_debug) console.log("yAxis afterSetExtremes", yAxis.options.id, extremes);
          //       let dataMin = !_.isNil(extremes.userMin) ? extremes.userMin : extremes.dataMin;
          //       let dataMax = !_.isNil(extremes.userMax) ? extremes.userMax : extremes.dataMax;
          //       let diff = dataMax - dataMin;
          //       if (!_.isNil(dataMin)) {
          //         dataMin = Number(dataMin.toFixed(5));
          //       }
          //       if (!_.isNil(dataMax)) {
          //         dataMax = Number(dataMax.toFixed(5));
          //       }
          //
          //       yAxis.setExtremes(dataMin, dataMax, true);
          //       // graphCtrl.yAxis[yAxis.options.id] = {
          //       //   // isUserExtremes: isUserExtremes,
          //       //   id: yAxis.options.id,
          //       //   // options: yAxis.options,
          //       //   // extremes: extremes,
          //       //   min: dataMin,
          //       //   max: dataMax,
          //       //   // diff: diff,
          //       //   step: diff * graphCtrl.yAxisScaleStep,
          //       // }
          //
          //     }
          //   });
          //
          //},
          // @ts-ignore
          mousewheel: function (event: Event): boolean {
            if (WG_debug) console.log('Event: yAxis mousewheel', event);
            return true;
          },
        }
      };

      if (sensor.internal_name === "WAKEUP_REASON") {
        yAxis.visible = false;
        yAxis.softMin = -0.5;
        yAxis.softMax = 3.5;
        if (this.AuthService.canAccess('admin')) {
          yAxis.softMax = 9.5;
        }
      }
      if (sensor.configs.graph_type === 'rgb') {
        yAxis.floor = 0;
        yAxis.ceiling = 1.0;
        yAxis.max = 1.0;
        yAxis.softMax = 1.0;
        yAxis.min = 0;
        yAxis.softMin = 0;
        yAxis.allowDecimals = false;
        yAxis.labels.enabled = false;
        yAxis.title.text = '';
      }
      // // First yAxis to be added. convert to array
      // if (!angular.isArray(this.chartConfig.yAxis)) {
      //   this.chartConfig.yAxis = [yAxis];
      // } else {
      //   // TODO: This resets the config of min/max. We should avoid using highcharts-ng, has it doesn't have all required features...
      //   //   Use chart.addAxis() instead
      //   (<Highcharts.AxisOptions[]>this.chartConfig.yAxis).push(yAxis);
      // }
      this._chart?.addAxis(yAxis, false, true);

      return yAxis;
    }

    public removeSensor(device: IDevice, sensor: ISensor, also_delete_variations = false): void {
      let graphDevice = this._getGraphDevice(device, false);
      if (!graphDevice || !sensor) {
        // Nothing to delete
        return;
      }
      // // var graphSensor: IGraphSensor = this._getGraphSensor(sensor, false);
      // let graphDeviceSensor: IGraphSensor = this._getGraphDeviceSensor(device, sensor, false);

      _.forEach(graphDevice.sensors, (graphDeviceSensor, _internal_name) => {
        if (!graphDeviceSensor) {
          // Nothing to delete
          return;
        }
        if (_internal_name == sensor.internal_name ||
            (also_delete_variations && _internal_name.startsWith(sensor.internal_name + "-"))) { // May have multiple related series: "$id", "$id-confidence_band", "$id-markers", etc

          if (WG_debug) console.log('removeSensor', device.name, _internal_name);

          if (graphDeviceSensor.series) {
            let _series = <Highcharts.Series>this._chart.get(graphDeviceSensor.series.id);
            _series?.remove(true);

            delete this.graphedSeries[graphDeviceSensor.series.id];
            graphDeviceSensor.series.data = emptyOrCreateArray(graphDeviceSensor.series.data);
            delete graphDeviceSensor.series;
          }

          delete graphDevice.sensors[_internal_name];
          // for (let i = this.chartConfig.series.length - 1; i >= 0; i--) {
          //   if (this.chartConfig.series[i].id == _id) {
          //     this.chartConfig.series.splice(i, 1);
          //                   break;
          //   }
          // }
          // graphDevice.data[_internal_name] = emptyOrCreateArray(graphDevice.data[_internal_name]);
          // delete graphDevice.data[_internal_name];

          // graphDeviceSensor.series = emptyOrCreateDict(graphDeviceSensor.series);
          // delete graphDeviceSensor.series;
          // graphDeviceSensor.graph = false;

        }
      });
    }

    /**
     * Hides all series except passed one || First one, returning it
     * @param series_to_keep
     */
    public hideAllSeriesExcept(series_to_keep: IGraphedDeviceSensor['series'] = null): IGraphedDeviceSensor['series'] {
      let found = false;
      for (let _series of this._chart.series) {
        let _seriesOptions = (_series.options) as Highcharts.SeriesLineOptions;

        if (_seriesOptions.xAxis?.toString()?.includes('navigator') ||
            _seriesOptions.yAxis?.toString()?.includes('navigator')) {
          continue;
        }

        if (!series_to_keep) { // Show only the first one
          series_to_keep = _seriesOptions;
        }

        if (_series && _seriesOptions.id === series_to_keep.id) {
          found = true;
          _series.setVisible(true, false);
        } else {
          _series.setVisible(false, false);

          // let yAxis = this._chart.get(<string>_seriesOptions.yAxis);
          // if (!yAxis && typeof _seriesOptions.yAxis == 'number') {
          //   yAxis = this._chart.yAxis[_seriesOptions.yAxis];
          // }
          // if (yAxis) {
          // yAxis.visible = false;
          // }
        }
      }
      if (!found && series_to_keep) {
        let _series = <Highcharts.Series>this._chart.get(series_to_keep.id);
        if (_series && WG_debug) console.error('Found series but not on chartConfig.series!!');
        // series_to_keep.visible = true;
        // this._chart.addSeries(series_to_keep, false);
        // this.chartConfig.series.push(series);
      }
      this._chart.redraw();
      return series_to_keep;
    }

    public hideSensor(device: IDevice, sensor: ISensor, onToggleCb): void {
      //if(WG_debug) console.log('hideSensor', device.name, sensor.stream, sensor.internal_name);

      let graphDeviceSensor = this._getGraphDeviceSensor(device, sensor);
      // var graphSensor: IGraphSensor = this._getGraphSensor(sensor);
      if (graphDeviceSensor.series) {
        // graphDeviceSensor.series.visible = false;
        let _series = <Highcharts.Series>this._chart.get(graphDeviceSensor.series.id);
        _series?.setVisible(false, true);
      }
    }


    public addToGraph(device_uuid: string, sensor_internal_name: string, forced = false, add_to_url = true): boolean {
      if (sensor_internal_name == 'FERMENT_SIMULATOR_endTime') { // Clicking on EndTime graphs density and predicted density
        sensor_internal_name = 'FERMENT_SIMULATOR_dens_cole';
      }
      if (sensor_internal_name == 'FERMENT_SIMULATOR_MANUAL_endTime') {
        sensor_internal_name = 'FERMENT_SIMULATOR_MANUAL_dens_cole';
      }
      if (sensor_internal_name.includes('FERMENT_SIMULATOR_dens_cole') || sensor_internal_name.includes('FERMENT_SIMULATOR_MANUAL_dens_cole')
          || sensor_internal_name.includes('MESHVINES_SIMULATOR_dens_boulton') || sensor_internal_name.includes('MESHVINES_SIMULATOR_MANUAL_dens_boulton')) {
        // Make sure density is graphed before
        this.addToGraph(device_uuid, 'QL_TREAT_LDENSA_massDensity', forced, false);
      }

      if (!this.WGApiData.WGSensors.sensors_name[sensor_internal_name]) {
        // Sensor not defined?? how?
        if (WG_debug) console.log("Adding to graph failed:", device_uuid, sensor_internal_name);
        return false;
      }

      let _device = this.WGApiData.WGDevices.devices_uuid[device_uuid];
      let _lkm = this.$rootScope.lastKnownMessages[device_uuid];
      let _sensor = this.WGApiData.WGSensors.sensors_name[sensor_internal_name];

      // TODO Scroll down
      // var graphElement = angular.element(document.getElementById('unit-graph'));
      // graphElement.scroll();
      // $document.scrollToElementAnimated(graphElement, 75);
      // if(WG_debug) console.log(graphElement);

      let realtime_disabled = _device?.configs?.unit_view?.disable_realtime === true
          || _device?.configs?.['disable_realtime'] === true
          || _sensor.configs.graph_options?.realtime === false;
      if (WG_debug && realtime_disabled == true) {
        console.warn("Realtime disabled for", device_uuid, sensor_internal_name);
      }

      if (_lkm && !realtime_disabled) {
        if (!this.watchedStreams[device_uuid]) {
          this.watchedStreams[device_uuid] = {};
        }
        if (this.watchedStreams[device_uuid][_sensor.stream] === true) {
          if (WG_debug) console.log("Already watched:", device_uuid, _sensor.stream);
        } else {
          //if (WG_debug) console.log("Watching:", device_uuid, _sensor.stream);
          this.watchedStreams[device_uuid][_sensor.stream] = true
          this.$rootScope.$watch('lastKnownMessages["' + device_uuid + '"]["' + _sensor.stream + '"]',
              (_new_value, _old_value) => {
                if (_.isNil(_new_value) || _.isEqual(_new_value, _old_value)) {
                  return;
                }
                let graphDevice = this._getGraphDevice(_device, false);
                if (!graphDevice) {
                  // Nothing to delete
                  return;
                }

                _.forEach(graphDevice.sensors, (graphDeviceSensor, sensor_internal_name) => {
                  if (!graphDeviceSensor) {
                    // Not graphed
                    return;
                  }
                  let sensor = graphDeviceSensor.sensor || this.WGApiData.WGSensors.sensors_name[sensor_internal_name];
                  if (sensor?.configs?.graph_options?.realtime === false || sensor.stream.startsWith('FERMENT_SIMULATOR') || sensor.stream.startsWith('MESHVINES_SIMULATOR')) {
                    return;
                  }
                  if (sensor.stream != _sensor.stream) {
                    // if (WG_debug) console.log('Graph skip, not this stream', {this: sensor.stream, target: _sensor.stream});
                    return;
                  }
                  if (WG_debug) console.log('Graph real time value', _device.id, sensor_internal_name, _new_value);
                  this.addValue(_device, sensor, _new_value, true);
                });
              }, true);
        }
      }
      let ret = this.getSensor(_device, _sensor, null, forced);

      // if (WG_debug) console.log("Added to graph:", device_uuid, sensor_internal_name, ret);
      if (add_to_url) {
        // Add to URL
        let i = 1;
        for (; i < 19; i++) {
          if (!this.$state.params['device' + i]) {
            // First free parameter found
            break;
          }
          if (this.$state.params['device' + i] == device_uuid && this.$state.params['param' + i] == sensor_internal_name) {
            // Already filled
            i = 999999;
            break;
          }
        }
        if (i < 19) {
          let _params = {};
          _params['device' + i] = device_uuid;
          _params['param' + i] = sensor_internal_name;
          this.AuthService.update_url(_params, false, false, true);
        }
      }

      return ret;
    }


    public getSensor(device: IDevice, sensor: ISensor, params?: IDataParams, forced?: boolean): boolean {

      let _sensor = _.cloneDeep(sensor);
      if (!angular.isDefined(device)) {
        console.log("No Device selected");
        return false;
      }
      if (!angular.isDefined(_sensor)) {
        console.log("No Parameter selected");
        return false;
      }
      if (!_sensor.configs?.graph) {
        console.warn("Parameter is not Graphicable");
        return false;
      }
      forced = !!forced;


      // console.log("Graph. getSensor:", sensor, device);


      let graphDeviceSensor = this._getGraphDeviceSensor(device, _sensor);

      this._setExportFilename();

      if (graphDeviceSensor.series) {
        // This parameter is already processed. Just show it.

        let _series = <Highcharts.Series>this._chart.get(graphDeviceSensor.series.id);
        _series?.setVisible(true, true);

        if (this.singleSeries) {
          this.hideAllSeriesExcept(graphDeviceSensor.series);
          return true;
        } else {
          if (!forced) {
            return false;
          }
        }
      }

      // if (WG_debug) console.time("Graph getSensor dev" + device.id + " " + _sensor.internal_name);


      // Check if it's a Fermentation_Prediction and if the stream is already in the LKM
      if ((_sensor.stream.startsWith("FERMENT_SIMULATOR") || _sensor.stream.startsWith("MESHVINES_SIMULATOR"))
          && _sensor.configs?.query?.['payload']?.[1]?.value?.['current_estimated_values']) {
        let q = _sensor.configs.query['payload'][1].value['current_estimated_values']; // Visualization uses "current_estimated_values", we want the raw one indicated by it

        let _target_dev = this.WGApiData.WGDevices.devices_uuid[device.uuid];
        let _timestamp = _target_dev.lkm[_sensor.stream]?.payload?.timestamp || 0;
        if (_timestamp == 0) {
          if (WG_debug) console.log('No sensor data available', _sensor.stream, _target_dev.lkm);
          // if (WG_debug) console.timeEnd("Graph getSensor dev" + device.id + " " + _sensor.internal_name);
          return false;
        }
        let _data = _target_dev.lkm[_sensor.stream]?.payload?.value[q];
        let _band_data, _band_sensor = null;

        sortByInPlace(_data, [0, 'x']); // Sort by timestamp

        // Support confidence_bands in the same matrix
        if (_data[0].length == 4) {
          _band_data = _.map(_data, x => [x['0'], x['2'], x['3']]);
          _data = _.map(_data, x => [x['0'], x['1']]);
        } else if (q == "dens_cole" && _target_dev.lkm[_sensor.stream].payload.value['low']) {
          _band_data = [];
          let _low = _target_dev.lkm[_sensor.stream].payload.value['low'];
          let _high = _target_dev.lkm[_sensor.stream].payload.value['high'];
          for (let i in _low) {
            if (_.isNil(_high[i][1])) {
              console.error("Error. Predicted fermentation confidences of different sizes. Please report");
              break;
            }
            _band_data.push([(_low[i][0] || _low[i]['x']), _low[i][1], _high[i][1]]);
          }
        }
        // Removing past-predictions for non-dev users and non-Manual predictions
        if (_timestamp > 0 && (
            _.includes(['FERMENT_SIMULATOR_dens_cole', 'MESHVINES_SIMULATOR_dens_boulton'], _sensor.internal_name)
            || (_.includes(['FERMENT_SIMULATOR_MANUAL_dens_cole', 'MESHVINES_SIMULATOR_MANUAL_dens_boulton'], _sensor.internal_name) && !this.AuthService.isDev() && this.AuthService.view_as_owner.id != 297))
        ) {
          let _pred_timestamp = _target_dev.lkm[_sensor.stream].payload?.value?.current_estimated_values?.current_time || _timestamp;
          // _data = _.filter(_data, val => ((val[0] || val['x']) >= _pred_timestamp - 1 * 60 * 1000));
          _.remove(_data, val => ((val[0] || val['x']) < _pred_timestamp - 1 * 60 * 1000));
          // _band_data = _.filter(_band_data, val => ((val[0] || val['x']) >= _pred_timestamp - 1 * 60 * 1000));
          _.remove(_band_data, val => ((val[0] || val['x']) < _pred_timestamp - 1 * 60 * 1000));
        }

        if (_band_data) {
          // Configure sensor to have a confidence band. Don't add NewLine
          // Clone related sensor making it a band/arearange with custom tooltip
          _band_sensor = _.merge({}, _sensor, {
            internal_name: _sensor.internal_name + '-confidence_band',
            configs: {
              convert_all_streams: true,
              graph_type: 'confidence_band',
              // graph_options: {
              // linkedTo: ':previous',
              // dataGrouping: {
              //   approximation: "range",
              // },
              // tooltip: {
              //   pointFormat: this.$translate.instant('app.graph.confidenceBand', {low: "{point.low}", high: "{point.high}"}) + '<br/>',
              // }
              // },
            },
          });
          _sensor = _.merge(_sensor, {
            configs: {
              graph_type: 'with_confidence_band',
              graph_options: {dashStyle: 'Dash'},
            },
          })
        } else {
          _sensor = _.merge(_sensor, {
            configs: {
              graph_options: {dashStyle: 'Dash'},
            },
          })
        }
        // if (WG_debug) console.timeEnd("Graph getSensor dev" + device.id + " " + _sensor.internal_name);
        this.$timeout(() => {
          this.addData(device, _sensor, this.process_and_convert(_sensor, device, _data), false, params, forced);
          if (_band_sensor && !_.isEmpty(_band_data)) {
            this.addData(device, _band_sensor, this.process_and_convert(_band_sensor, device, _band_data), false, params, forced);
          }
        }, 2000);

        return true;
      }

      // Else build the params object and query storage-API for the data

      let _params: IDataParams = _.cloneDeep(this.defaultParams);

      if (!_.isNil(device.configs?.graph_data_params)) {
        _.assign(_params, device.configs.graph_data_params);
        // _.merge(_params, device.configs.graph_data_params);
      }

      if (!_.isNil(params)) {
        _.assign(_params, params);
      }

      if (_sensor.configs?.query) {
        _params.q = _sensor.configs.query;
      }

      // this.loading = true;
      this._chart.showLoading("Loading " + _sensor.name);
      // if (WG_debug) console.log('Getting sensor data with', _params);
      this.Data.get(device, _sensor, _params).then(
          (data) => {
            // this.loading = false;
            this._chart.hideLoading();
            // if (WG_debug) console.timeLog("Graph getSensor dev" + device.id + " " + _sensor.internal_name);
            if (_.isEmpty(data) || _.isNil(data[0])) {
              if (WG_debug) console.log('No sensor data returned');
              return false;
            }

            // if (WG_debug) console.timeEnd("Graph getSensor dev" + device.id + " " + _sensor.internal_name);
            // this.$timeout(() => {
            this.addData(device, _sensor, this.process_and_convert(_sensor, device, data), false, params, forced)
            // }, 10);
          },
          (reason) => {
            // this.loading = false;
            this._chart.hideLoading();
            if (WG_debug) console.warn("Failed to get Sensor Data", reason);
            // if (WG_debug) console.timeEnd("Graph getSensor dev" + device.id + " " + _sensor.internal_name);
          }
      );
      return true;
    }

    /**
     * Returns a new array with every point of data ready to graph, and converted according to the sensor/user's configuration.
     * @param sensor
     * @param device - Required for unit-based conversions (Level -> Volume)
     * @param data
     * @return New array, containing same or converted values
     */
    private process_and_convert(sensor: ISensor, device: IDevice, data: Array<([(number | string), (number | null)] | null | Highcharts.PointOptionsObject)>): Array<([(number | string), (number | null)] | null | Highcharts.PointOptionsObject)> {
      let ret: IDataResult[] = this.DataUtils.preprocess_data(sensor, _.cloneDeep(data), device);
      let isAdmin = this.AuthService.canAccess('admin');

      if (_.isNil(sensor.configs)) {
        sensor.configs = {};
      }

      if (sensor.configs.manual === true) {
        // Remove "deleted" entries
        ret = _.map(ret, (e) => {
          let _y = (_.isNil(e[1]) ? e['y'] : e[1]);
          if (_y['deleted'] === true) {
            return;
          }
          let _ret = {
            x: (_.isNil(e[0]) ? e['x'] : e[0]),
            y: (_.isNil(_y['value']) ? _y : _y['value']),
          };
          if (_y['observation']) {
            _ret['observation'] = _y['observation'];
          }
          return _ret;
        });
        if (WG_debug) console.info("Manual data converted", ret);
      }


      if (sensor.configs.graph_type === 'rgb') {
        ret = _.map(ret, function (e) {
          let _color = getColor(e[1], e[2]);
          let _rgb = wgHexToRGB(e[1] as string) || "";
          return {
            x: (_.isNil(e[0]) ? e['x'] : e[0]),
            y: 1.0,
            color: _color,
            rgb: _rgb.toString(),
            marker: {
              fillColor: _color,
              states: {
                hover: {
                  fillColor: _color,
                }
              }
            }
          }
        });

      }
      if (sensor.internal_name === 'WAKEUP_REASON') {
        ret = _.map(ret, (e) => {
          if (!e)
            return;
          let _x = _.isNil(e[0]) ? e['x'] : e[0];
          let _y = _.isNil(e[1]) ? e['y'] : e[1];
          if (_.isNil(_x) || _.isNil(_y)) {
            return;
          }
          if (!angular.isDate(new Date(_x))) {
            return;
          }
          if (_y['deleted'] === true) {
            return;
          }
          return {
            x: _x,
            y: _y,
            y_txt: this.DataUtils.convert_wakeup_reason(_y, device),
          };
        });

      }
      if (sensor.conversion?.id) {
        if (_.size(ret[0]) < 2) {
          if (WG_debug) console.error('Conversion Error. 1-col matrix?', ret);
        }
        ret = _.map(ret, function (e) {
          if (_.size(e) < 2) {
            if (WG_debug) console.error('Conversion Error. 1-col matrix?', e, ret);
            return;
          }
          if (sensor.configs.convert_all_streams) {
            for (let i in e) {
              if (i == '0' || i == 'x') continue; // don't convert x-axis streams
              e[i] = convert(<number>e[i], sensor.conversion.id, null);
            }
          } else {
            if (!_.isNil(e[1])) {
              e[1] = convert(<number[]>e, sensor.conversion.id, null);
            } else { // manuals use a dict
              e['y'] = convert(<number[]>e, sensor.conversion.id, null);
            }
          }
          return e;
        });
        // if (WG_debug) console.info('Converted', sensor.conversion?.id, _.cloneDeep( ret));

      }

      // Remove undefined entries from ret
      _.remove(ret, (e) => _.isNil(e));

      return ret;
    }

    /**
     * Add to the Graph already converted data and in array/highcharts format
     *
     * @param device
     * @param sensor
     * @param data - Converted data to show on the graph
     * @param append - If data[] should be added to the existing time-series data
     * @param params
     * @param forced
     */
    public addData(device: IDevice, sensor: ISensor, data: Array<([(number | string), (number | null)] | null | Highcharts.PointOptionsObject)>, append?: boolean, params?: IDataParams, forced?: boolean): boolean {
      if (_.isEmpty(data)) {
        // console.log("No data to graph");
        this.error_message = "No data to graph";
        return;
      }
      let _now = new Date().getTime();

      //if (WG_debug) console.log("Graph addData dev" + device.id + " " + sensor.internal_name, _.cloneDeep(data));
      this.error_message = "";

      this.show = true;
      // if (WG_debug) console.log('Add Data to graph', device, sensor, data, params);

      let graphSensor = this._getGraphSensor(sensor); // To merge yAxis of similar sensors
      // let graphDevice = this._getGraphDevice(device);
      let graphDeviceSensor = this._getGraphDeviceSensor(device, sensor);

      let _series = graphDeviceSensor.series && <Highcharts.Series>this._chart.get(graphDeviceSensor.series.id);
      let editing_existing_series = !!_series;
      let adding_first_series = _.isEmpty(this.graphedSeries);

      if (_.isNil(graphDeviceSensor.series?.data)) {
        // Make sure all appends have a valid data structure already.
        append = false;
      }
      if (!append && editing_existing_series) {
        _series.setVisible(true, false);
      }

      if (editing_existing_series) {
        // Already graphed
        // Ignore request if it's not an append
        // Always force redraw if singleSeries is selected
        if (!forced && !append && !this.singleSeries) {
          return false;
        }
      }

      if (_.size(this.graphedSeries) >= 12) {
        this.error_message = this.$translate.instant("app.graph.LABELS_LIMIT");
      }

      //
      let _data_to_process: Array<([(number | string), (number | null)] | null | Highcharts.PointOptionsObject)>;
      // The final reference holding the data
      let graphedData: Array<([(number | string), (number | null)] | null | Highcharts.PointOptionsObject)> = [];
      let new_data_first_timestamp: number = 0;
      let new_data_first_index: number = 0

      let align_truncateSeconds: number;
      let align_offsetSeconds: number;
      let _align_steps_seconds = [
        60 * 60,
        30 * 60,
        15 * 60,
        5 * 60,
        2 * 60,
        1 * 60,
        30,
        15,
        5,
        1];


      // Select data to process. Avoid processing already processed data when appending
      if (!append || _.isEmpty(graphDeviceSensor.raw_data)) {
        graphDeviceSensor.raw_data = data;
        sortByInPlace(graphDeviceSensor.raw_data, [0, 'x']);
        uniqBySortedInPlace(graphDeviceSensor.raw_data, [0, 'x']);

        _data_to_process = _.cloneDeep(graphDeviceSensor.raw_data);

      } else { // Append
        graphedData = graphDeviceSensor.series.data as Array<([(number | string), (number | null)] | null | Highcharts.PointOptionsObject)>;

        new_data_first_index = graphDeviceSensor.raw_data.length - 1;
        let _time_millis: number;
        let _tmp_i: number;
        for (let _p of data) {
          _tmp_i = insertSortedByInPlace(graphDeviceSensor.raw_data, _p, [0, 'x'], true);

          // Save value and index of first new data point to use later
          new_data_first_index = Math.min(new_data_first_index, _tmp_i);

          _time_millis = _p[0] || _p['x'];

          if (!new_data_first_timestamp || _time_millis < new_data_first_timestamp) {
            new_data_first_timestamp = _time_millis;
          }
        }

        let _prev_last_entry_millis = (graphDeviceSensor.raw_data[new_data_first_index][0] || graphDeviceSensor.raw_data[new_data_first_index]['x']);

        // Delete graphed Data from _prev_last_entry_millis-2h.
        // Process raw->graphed from _prev_last_entry_millis-3h onwards.
        while (graphedData?.length) {
          let _last = _.last(graphedData);

          // Delete previous null-padding
          if (_.isNil(_last[1]) && _.isNil(_last['y'])) {
            graphedData.pop();
            continue;
          }
          let _last_millis = _last[0] || _last['x'];
          if (_last_millis >= _prev_last_entry_millis - 2 * 60 * 60 * 1000) {
            graphedData.pop();
            continue;
          }
          break;
        }

        _data_to_process = _.filter(_.cloneDeep(graphDeviceSensor.raw_data), val => ((val?.[0] || val?.['x']) >= _prev_last_entry_millis - 3 * 60 * 60 * 1000));
      }

      // if (WG_debug) console.timeLog("Graph addData dev" + device.id + " " + sensor.internal_name);

      if (sensor.internal_name == "SLEEP_interval") {
        // set the value ([1] or ['y']) with the time difference (interval) to previous point
        for (let i = 1; i < _data_to_process.length; i++) {
          if (!_data_to_process[i] || !_data_to_process[i - 1])
            continue;

          let _curr = _data_to_process[i][0] || _data_to_process[i]['x'];
          let _prev = _data_to_process[i - 1][0] || _data_to_process[i - 1]['x'];

          if (_data_to_process[i][1]) {
            _data_to_process[i][1] = _prev ? ((_curr - _prev) / 1000) : null;
          } else if (_data_to_process[i]['y']) {
            _data_to_process[i]['y'] = _prev ? ((_curr - _prev) / 1000) : null;
          }
        }
      }

      if (this.styles.dataAlign_auto !== true
          || _data_to_process.length < 2) {
        if (!append) {
          graphedData = _data_to_process;
        } else {
          for (let _p of _data_to_process) {
            insertSortedByInPlace(graphedData, _p, [0, 'x'], true);
          }
        }
      } else {
        // Round x-values, without loosing continuity, to align between series
        let _current_seconds: number = 0;
        let _prev_seconds: number = 0;
        let _next_seconds: number = 0;
        let min_diff_seconds: number;
        _.forEach(_data_to_process, (_point, _index) => {
          if (!_point) {
            return;
          }

          _prev_seconds = _current_seconds;

          _current_seconds = (_point[0] || _point['x']) / 1000;
          min_diff_seconds = Math.abs(_current_seconds - _prev_seconds);

          if (_index < _data_to_process.length - 1 && _data_to_process[_index + 1]) {
            _next_seconds = (_data_to_process[_index + 1][0] || _data_to_process[_index + 1]['x']) / 1000;
            min_diff_seconds = Math.min(min_diff_seconds, Math.abs(_next_seconds - _current_seconds));
          }

          align_truncateSeconds = 1; // Selected interval to align
          for (let _step_seconds of _align_steps_seconds) {
            if (min_diff_seconds > 1.3 * _step_seconds) { // Choose a step that is at least 30% smaller than the minimum distance to previous/next point
              align_truncateSeconds = _step_seconds;
              break;
            }
          }
          align_offsetSeconds = -0.30 * align_truncateSeconds; // Shift the point forward 30% of the step before round-flooring, allowing it to be 30% before and 70% after the step

          if (_point[0]) {
            _point[0] = Math.floor((_point[0] / 1000.0 - align_offsetSeconds) / align_truncateSeconds) * align_truncateSeconds * 1000;
          } else if (_point['x']) {
            if (_point['x_orig']) {
              if (WG_debug) console.warn("x_orig already exists!", _.cloneDeep(_point));
            }
            _point['x_orig'] = _point['x'];
            _point['x'] = Math.floor((_point['x'] / 1000.0 - align_offsetSeconds) / align_truncateSeconds) * align_truncateSeconds * 1000;
          }

          insertSortedByInPlace(graphedData, _point, [0, 'x'], true);

        });
      }


      // if (WG_debug) console.timeLog("Graph addData dev" + device.id + " " + sensor.internal_name);

      let _last = _.last(graphedData);
      let last_data_time: number = _last[0] || _last['x'];

      // Add a null past the end? To pad the xAxis without messing with xAxis configs

      // let new_val = Array.from({length: _.size(graphedData[0])}, (v, i) => null);
      // new_val[0] = last_data_timestamp + 10000;
      // graphedData.splice(graphedData.length, 0, new_val);

      // Disconnect jumps
      // If difference between consecutive points > 24h+1h, or >4 samples in the last week, add a Null in the middle, to prevent graphing the interpolation
      if (!sensor.stream.includes("_SIMULATOR")) {
        let sensor_interval = device.sample_interval;
        if (sensor.configs.manual) {
          sensor_interval = 1 * 24 * 60 * 60 * 1000; // Manual data is expected to be infrequent
        }

        // Go in reverse to allow Splicing/inserting into the same array
        let curr_time_millis;
        let next_time_millis = last_data_time;
        for (let i = graphedData.length - 2; i >= 0; i--) {
          if (!graphedData[i] || !graphedData[i + 1])
            continue;
          curr_time_millis = graphedData[i][0] || graphedData[i]['x'];
          next_time_millis = graphedData[i + 1][0] || graphedData[i + 1]['x'];

          if (new_data_first_timestamp > next_time_millis) {
            break;
          }

          // Fill gaps with a hole, instead of a line.
          // Hole = [4.5 missed measurements in the last 2 weeks, at least 4.5h, 1.5days before that, so we don't need to analyze previous sample_interval]
          let max_line_interval = Math.max(4.5 * 60 * 60 * 1000, 4.5 * sensor_interval)
          if (_now - curr_time_millis > 14 * 24 * 60 * 60 * 1000) {
            max_line_interval = Math.max(1.5 * 24 * 60 * 60 * 1000, 4.5 * sensor_interval);
          }

          if (sensor.stream.startsWith("PRESS_VOLUME_TOTALIZER")) {
            // Totalizer decreases are not allowed, only when setting the totalizer to 0. Show hole
            if (graphedData[i][1] && graphedData[i + 1][1] < graphedData[i][1]) {
              let new_val = Array.from({length: _.size(graphedData[0])}, (v, i) => null)
              new_val[0] = Math.floor((curr_time_millis + next_time_millis) / 2);
              graphedData.splice(i + 1, 0, new_val);
            }
          } else if (Math.abs(next_time_millis - curr_time_millis) > max_line_interval) {

            let new_val = Array.from({length: _.size(graphedData[0])}, (v, i) => null)
            new_val[0] = Math.floor((curr_time_millis + next_time_millis) / 2);

            graphedData.splice(i + 1, 0, new_val);
          }
        }
      }

      // Configure graph and add data to target structures
      if (editing_existing_series) {
        //if (WG_debug) console.log("graph setData", graphedData);
        _series.setData(graphedData, true, false, true);
        // _series.setData(graphedData, true, false, false);
        // _series.setData(graphedData, true, true, true);
        // if (_.size(data) == 1) {
        //   _series.addPoint(data[0], true, false, false);
        // } else {
        //   _series.setData(graphedData, true, true, true);
        // }
      } else {
        //if (WG_debug) console.log("graph creating new series");
        // This specific device+parameter wasn't graphed yet
        let lineSeries = <Highcharts.SeriesLineOptions>this.newLineSeries(device, sensor, graphedData);

        if (!graphSensor.hasYAxis) {
          graphSensor.yAxis = this.newYAxis(graphSensor.sensor);
          graphSensor.hasYAxis = true;
        }
        lineSeries.yAxis = graphSensor.yAxis.id;

        // graphDeviceSensor.graph = true;
        graphDeviceSensor.series = lineSeries;


        if (this.styles.dataAlign_auto && !this.singleSeries && !_.isEmpty(this.graphedSeries)) {
          lineSeries.findNearestPointBy = 'x';
        }

        this._chart.addSeries(graphDeviceSensor.series, true);

        if (adding_first_series || this.singleSeries) {
          let process_start_time = device.unit?.process?.started_at ? Date.parse(device.unit?.process?.started_at as string) : null;
          let _xAxisMin = this.$state?.params?.xAxisMin || null;
          // setTimeout(() => {
          // First series to Add. Zoom accordingly
          if (_xAxisMin) {
            if (WG_debug) console.log("Graph Zooming to state.params");
            let _max = this.xAxis_main.max;
            if (this.$state?.params?.xAxisMax) {
              _max = this.get_graph_end(this, true);
            }
            this.xAxis_main.setExtremes(this.get_graph_start(this, true), _max, true);
          } else if (process_start_time > 0 && _.isFinite(process_start_time) && last_data_time >= process_start_time) {
            // There's an active process with some data and younger than 6 months
            if (process_start_time < _now - 6 * 30 * 24 * 60 * 60 * 1000) {
              process_start_time = _now - 6 * 30 * 24 * 60 * 60 * 1000;
            }
            if (WG_debug) console.log("Graph Zooming to current Process", process_start_time);
            this.xAxis_main.setExtremes(process_start_time - 30 * 60 * 1000, null, true);
          } else {
            if (WG_debug) console.log("Graph Zooming to 1w");
            let _max = this.xAxis_main.max;
            this.xAxis_main.setExtremes(_max - 1 * 7 * 24 * 60 * 60 * 1000, _max, true);
            // this._chart.update({rangeSelector: {selected: 1}}, true);
            //   // this.chartConfig.rangeSelector.selected = 1;
            //   // this._chart.redraw();
            //   // this._chart['rangeSelector'].buttons[1].setState(3);
            //   // this._chart['rangeSelector'].clickButton(1, {type: 'week', count: '1'}, true);
          }
          // }, 1000);
        }

        this.graphedSeries[lineSeries.id] = {
          device: device,
          sensor: sensor,
        };

      }
      //if (WG_debug) console.log("Graph addData", graphDeviceSensor.series);

      if (WG_debug && graphDeviceSensor.series.data && graphedData !== graphDeviceSensor.series.data) {
        console.error("Something is wrong. Lost graphed data ref!");
      }

      if (this.singleSeries) {
        this.hideAllSeriesExcept(graphDeviceSensor.series);
      }

      // if (WG_debug) console.timeEnd("Graph addData dev" + device.id + " " + sensor.internal_name);


// Add to graph in the next cycle to allow Highcharts to build
      this.$timeout(() => {

        if (!append) {
          // Get Flags/Bands
          let _params = _.assign({}, this.defaultParams, device.configs?.graph_data_params, params);


          let f_params: IDataParams = {
            e: _params.e,
            b: _params.b,
          };
          this.getFlags(device, sensor, f_params);
          // if (this.DataUtils.has_device_AI_LDENSA(device)) {
          if (this.DataUtils.has_device_AI_LDENSA(device)
              && sensor.internal_name.includes('LDENSA')) {
            this.getFlags(device, sensor, f_params, 'flagsAI');
          }
          if (adding_first_series || this.singleSeries) {
            this.getBands(device, f_params);
          }
        }
        // this.styles.reset();
      }, 10);
      return true;
    }

    public addValue(device: IDevice, sensor: ISensor, payload: any, append ?: boolean, maxPoints ?: number, params ?: IDataParams, forced ?: boolean): void {
      if (WG_debug) console.log("Graph addValue, " + device.id + " " + sensor.internal_name, payload.timestamp, payload);
      // if (WG_debug) console.time("Graph addValue dev" + device.id + " " + sensor.internal_name);
      append = !!append;
      forced = !!forced;

      // this.show = true;
      var graphDevice = this._getGraphDevice(device);
      var graphDeviceSensor = this._getGraphDeviceSensor(device, sensor);
      // var graphSensor: IGraphSensor = this._getGraphSensor(sensor);

      if (!graphDeviceSensor.series) {
        if (WG_debug) console.warn("Device_Sensor not graphed. Not adding value", {device: device, data: payload});
        // if (WG_debug) console.timeEnd("Graph addValue dev" + device.id + " " + sensor.internal_name);
        return;
      }

      let _query = params?.q || sensor?.configs?.query || this.defaultParams.q;
      let value = angular.copy(select(_query, payload)) as [number, any];
      if (_.isEmpty(value)) {
        if (WG_debug) console.warn("Failed to convert LKM to highcharts format", {query: _query, data: payload});
        return;
      }
      // if (WG_debug) console.timeEnd("Graph addValue dev" + device.id + " " + sensor.internal_name);
      if (WG_debug) console.log("Graph addValue, " + device.id + " " + sensor.internal_name, payload.timestamp, payload);
      this.addData(device, sensor, this.process_and_convert(sensor, device, [value]), true, params);
      return;
    }

    public getFlags(device: IDevice, sensor: ISensor, params ?: IDataParams, flagType = 'flags'): void {
      if (flagType !== 'flagsAI') {
        if (this.AuthService.anonymize && !this.AuthService.anonymize_exceptions.includes(this.AuthService.view_as_owner?.id)) {
          if (WG_debug) console.debug("Graph getFlags not valid when Anonymized");
          return;
        }
      }
      // if (WG_debug) console.time("Graph getFlags dev" + device.id + " " + sensor.internal_name);

      let corflag;
      let shapeflag;
      let textsize;
      let fontweight;
      this.show = true;
      // let onAxis = true;

      let graphCtrl: GraphController = this;

      let _params = _.assign({}, this.defaultParams, device.configs?.graph_data_params, params);

      let flagSensor = _.cloneDeep(this.flagSensor);
      if (flagType === 'flagsAI') {
        flagSensor = _.cloneDeep(this.flagAISensor);
        flagSensor.stream = 'AI_LDENSA';
        flagSensor.internal_name = 'AI_LDENSA';

        _params.q = {"payload": ["timestamp", {"value": ["Fermentation_Kinetic_16h", "Mass_Dens_class", "Density_Median_24h", "Density_Median_2h", "Fermentation_Kinetic_24h", "Fermentation_Kinetic_36h"]}]};
        // console.log('flagSensor', flagSensor, _params);
      }
      let graphDeviceSensor = this._getGraphDeviceSensor(device, flagSensor);
      if (graphDeviceSensor[flagType]) {
        if (WG_debug) console.log("This type of flags has already been added for this device");
        return;
        // }
        graphDeviceSensor[flagType] = true;
      }

// if (WG_debug) console.log('Getting flags with', _params);

      this.Data.get(device, flagSensor, _params).then((data) => {
        if (_.isEmpty(data) || (data.length === 1 && _.isNil(data[0]))) {
          //if (WG_debug) console.log('Graph getFlags no data');
          return;
        }
        // if (WG_debug) console.timeLog("Graph getFlags dev" + device.id + " " + sensor.internal_name);
        let filteredData = _.filter(data, function (e) {
          if (_.isNil(e)) {
            return false;
          }
          let _x = (_.isNil(e[0]) ? e['x'] : e[0])
          let _y = (_.isNil(e[1]) ? e['y'] : e[1])
          if (_.isNil(_x) || _.isNil(_y)) {
            return false;
          }
          if (!angular.isDate(new Date(_x))) {
            return false;
          }
          // if (WG_debug) console.log('Filtering flag data', e);
          if (_y['deleted'] === true) {
            return false;
          }
          return true;
        });
        if (_.isEmpty(filteredData)) {
          // if (WG_debug) console.log('No flag data returned');
          // if (WG_debug) console.timeEnd("Graph getFlags dev" + device.id + " " + sensor.internal_name);
          return;
        }

        sortByInPlace(filteredData, [0, 'x']);

        // console.log('got flags', data);
        // TODO: Move this code to an helper file
        // TODO: This is just on beta mode. To be able to calculate on the fly.
        if (flagType === 'flagsAI') {
          let _data = [];
          let has_ended = true;
          let last_type = null;
          let last_mass_dens = null;
          let last_mass_timestamp = null;
          let last_stagnated_timestamp = null;
          let one_week_millis = 7 * 24 * 60 * 60 * 1000;
          let one_day_millis = 24 * 60 * 60 * 1000;
          let one_and_half_day_millis = one_day_millis * 1.5;

          _.forEach(filteredData, function (e, idx) {
            if (_.size(e) != 7) {
              return;
            }
            let timestamp = (_.isNil(e[0]) ? e['x'] : e[0]);
            let fermentation_kinetic_16h = e[1];
            let mass_dens = e[2];
            let mass_dens_median_24h = e[3];
            let mass_dens_median_2h = e[4];
            let fermentation_kinetic_24h = e[5];
            let fermentation_kinetic_36h = e[6];
            if (!isFinite(fermentation_kinetic_16h) || !isFinite(mass_dens) || !isFinite(mass_dens_median_24h) || !isFinite(mass_dens_median_2h)) {
              if (WG_debug) console.log('Missing data for fermentation detection:', e);
              return;
            }
            let _best_kinetic: number = (fermentation_kinetic_16h || fermentation_kinetic_24h || fermentation_kinetic_36h);
            let append = false;
            let msg = {text: '', title: '', type: ''};
            let type = '';
            if (last_mass_dens !== null) {
              // the start was not detected but there is a big change in density, so it can show STAGNATED or END
              if (mass_dens - last_mass_dens > 0.050) {
                // if (WG_debug) console.log('New', timestamp, mass_dens, last_mass_dens)
                type = 'FERMENTATION_NEW';
                // msg = {text: '', title: 'n'};
                // append = last_type !== type;
                has_ended = false;
              }
            }
            last_mass_dens = mass_dens;
            if (last_mass_timestamp !== null) {
              // the start was not detected but it has passed more than one week, so it can show STAGNATED or END
              if (timestamp - last_mass_timestamp > one_week_millis) {
                // console.log('On', timestamp, last_mass_timestamp)
                // type = 'SENSOR_ON';
                // msg = {text: '', title: 'On'};
                // redundant for now
                // append = last_type !== type;
                has_ended = false;
              }
            }
            last_mass_timestamp = timestamp;
            /* this can not be calculated with AI_LDENSA stream must be with SLEEP */
            // if (idx + 1 < data.length && data[idx + 1][0] - timestamp > one_and_half_day_millis) {
            //   console.log('Off', timestamp, data[idx + 1][0])
            //   type = 'SENSOR_OFF';
            //   msg = {text: '', title: 'Off'};
            //   append = last_type !== type;
            // }
            if (mass_dens_median_24h >= 1.070) {
              // if (fermentation_kinetic_16h >= 0.007) {
              if (_best_kinetic >= 0.004) {
                // console.log('FERMENTATION_STARTED');
                type = 'FERMENTATION_STARTED';
                // msg = {text: '', title: 'S'};
                msg = {
                  text: graphCtrl.$translate.instant('app.ai_notifications.FERMENTATION_STARTED_TITLE'),
                  // title: '▸',
                  title: '▶',
                  // title: '▶',
                  // Add title as a Play unicode char
                  type: type
                };
                // msg = {text: 'Fermentation Started', title: '▶'};
                append = last_type !== type;
                has_ended = false;
                timestamp = timestamp - 8 * 60 * 60 * 1000;
              }
            } else if (1.010 < mass_dens_median_2h && mass_dens_median_2h < 1.070) {
              // do not repeat between STAGNATED and END
              if (_best_kinetic <= 0.0008 && _best_kinetic != 0.0 && !has_ended) {
                // if (fermentation_kinetic_16h <= 0.0008 && fermentation_kinetic_16h != 0.0 && (last_stagnated_timestamp === null || timestamp - last_stagnated_timestamp >= one_day_millis)) {
                // console.log('FERMENTATION_STAGNATED');
                type = 'FERMENTATION_STAGNATED';
                // msg = {text: '', title: '-'};
                msg = {
                  text: graphCtrl.$translate.instant('app.ai_notifications.FERMENTATION_STAGNATED_TITLE'),
                  title: '‖',
                  // title: '⏸', // broken
                  // Add title as Pause unicode character
                  type: type
                };
                // msg = {text: 'Fermentation Stagnated', title: '⏸'};
                append = last_type !== type || (last_stagnated_timestamp === null || timestamp - last_stagnated_timestamp >= one_day_millis);
                last_stagnated_timestamp = timestamp;
              }
            } else if (mass_dens_median_2h <= 1.010) {
              // do not repeat between STAGNATED and END
              if (_best_kinetic <= 0.0008 && _best_kinetic != 0.0 && !has_ended) {
                // console.log('FERMENTATION_END');
                type = 'FERMENTATION_END';
                // msg = {text: '', title: 'E'};
                msg = {
                  text: graphCtrl.$translate.instant('app.ai_notifications.FERMENTATION_END_TITLE'),
                  title: '■',
                  // title: '■',
                  // title: '▄',
                  // title: '⏹', // broken
                  // Add title as Stop unicode character
                  type: type
                };
                // msg = {text: 'Fermentation End', title: '⏹'};
                append = last_type !== type;
                has_ended = true;
              }
            }
            if (append) {
              last_type = type;
              // if (WG_debug) console.log("AI tags created", type, append, [timestamp, msg], new Date(timestamp).toISOString(), fermentation_kinetic_16h, mass_dens, mass_dens_median_24h, mass_dens_median_2h);
              // _data.push([timestamp, msg]);
              insertSortedByInPlace(_data, [timestamp, msg], [0, 'x'], false);
            }
          });
          filteredData = _data;
          // sortByInPlace(filteredData, [0, 'x']); // No need. Sorted during insertion
          corflag = "#ff9e3f";
          shapeflag = "circlepin";
          textsize = "12px";
          fontweight = "bold";
        } else {
          corflag = "#4d748a";
          shapeflag = "squarepin";
          textsize = "12px";
          fontweight = "normal";
          // onAxis = false;
        }


        let mappedData = _.map(filteredData, function (e) {
          return {
            x: (_.isNil(e[0]) ? e['x'] : e[0]),
            // y: 0,
            title: (_.isNil(e[1]) ? e['y'] : e[1])?.['title'] || undefined,
            text: (_.isNil(e[1]) ? e['y'] : e[1])?.['text'] || (typeof e[1] === 'string' ? e[1] : undefined),
          };
        });
        // graphDevice.data[flagSensor.internal_name] = mappedData;
        let yAxis_id = _.find(graphCtrl._chart?.yAxis, (yAxis) => {
          return yAxis?.options?.id && !yAxis.options.id.startsWith('navigator-');
        })?.options.id || undefined;

        let _unit = this.WGApiData.WGDevices?.devices_id?.[device.id]?.unit;
        let series_name = (_unit?.name || device.name) + ` (` + flagSensor.name + `)`;

        let flagSeries: Highcharts.SeriesFlagsOptions = {
          type: 'flags',
          name: series_name,
          data: mappedData,
          visible: true,
          shape: shapeflag,
          color: corflag,
          fillColor: "#ffffff",
          zIndex: 99999999999,
          findNearestPointBy: 'x',
          yAxis: yAxis_id,
          //   allowPointSelect: true,
          style: {
            // text style
            color: corflag,
            cursor: "pointer",
            fontSize: textsize,
            fontWeight: fontweight
          },
          states: {
            hover: {
              fillColor: '#f3f3f3',
              // zIndex: 999999999,
              // cursor: "pointer",
            }
          },
          tooltip: {

            pointFormat: '- {point.title}<br/>{point.text}',
          }
        };
        // if (onAxis) {
        flagSeries.id = `flagSeries-${device.uuid}-${flagSensor.internal_name}`;
        // } else {
        //   flagSeries.id = `flagSeries-${device.uuid}-${sensor.internal_name}-${flagSensor.internal_name}`;
        //   flagSeries.onSeries = this.getLineSeriesId(device, sensor);
        // }

        this.graphedSeries[flagSeries.id] = {
          device: device,
          sensor: flagSensor,
        };

        graphDeviceSensor.series = flagSeries;
        this._chart.addSeries(flagSeries, true);

        // this.chartConfig.series.push(flagSeries);
        // if (WG_debug) console.timeEnd("Graph getFlags dev" + device.id + " " + sensor.internal_name);
      }, (reason) => {
        // this.error_message = "";
        if (WG_debug) console.warn("Failed to get Flags", reason);
        // if (WG_debug) console.timeEnd("Graph getFlags dev" + device.id + " " + sensor.internal_name);
      });
    }

    public getBands(device: IDevice, params ?: IDataParams): void {
      this.show = true;
      let graphDevice = this._getGraphDevice(device);

      if (graphDevice.bands) {
        if (WG_debug) console.log("Graph getBands have already been added");
        return;
      }
      graphDevice.bands = true;
      // if (WG_debug) console.time("Graph getBands dev" + device.id);

      let _params = _.assign({}, this.defaultParams, device.configs?.graph_data_params, params);

      let get_process = (device_id) => {
        this.$http.get('api/dashboard/processes/?device_id=' + device_id).then(
            (response: ng.IHttpPromiseCallbackArg<any>) => {
              if (!response?.data?.count) {
                if (WG_debug) console.log("Graph getBands no results", device_id);
              } else {
                // if (WG_debug) console.timeLog("Graph getBands dev" + device_id);

                // console.log('process', response.data.results);
                _.forEach(response.data.results, (process: IProcess) => {

                  this.AuthService.anonymize_entity("Process", process);

                  let started_at, ended_at;
                  if (process.started_at != null) {
                    started_at = new Date(Date.parse(process.started_at as string));
                  }

                  let plot_id = `plot-line-start-${new Date().getTime()}`;
                  let plotLine: Highcharts.AxisPlotLinesOptions = {
                    id: plot_id,
                    value: started_at,
                    color: 'green',
                    width: 2,
                    dashStyle: 'longdashdot' as Highcharts.DashStyleValue,
                  };
                  this.xAxis_main.addPlotLine(plotLine);

                  if (process.ended_at != null) {
                    ended_at = new Date(Date.parse(process.ended_at as string));
                    let plot_end_id = `plot-line-end-${new Date().getTime()}`;
                    let plotLineEnd: Highcharts.AxisPlotLinesOptions = {
                      id: plot_end_id,
                      value: ended_at,
                      color: 'red',
                      width: 2,
                      dashStyle: 'longdashdot' as Highcharts.DashStyleValue,
                    };
                    this.xAxis_main.addPlotLine(plotLineEnd);
                  } else {
                    // console.log('Process not finished!');
                    ended_at = new Date();
                  }
                  // console.log(started_at.getTime(), ended_at.getTime());
                  let id = `plot-band-${new Date().getTime()}`;

                  let series_name = process.name + ` - ` + device.name;
                  let _unit = this.WGApiData.WGDevices?.devices_id?.[device.id]?.unit;
                  if (_unit?.name) {
                    series_name = process.name + ` - ` + _unit.name;
                  }
                  let plotBand: Highcharts.AxisPlotBandsOptions = {
                    id: id,
                    from: started_at,
                    to: ended_at,
                    // color: '#F7F7F7',
                    color: '#FCFFC5',
                    label: {
                      text: series_name,
                      // rotation: 90,
                      // textAlign: 'left',
                    }
                  };
                  this.xAxis_main.addPlotBand(plotBand);
                });

                // if (WG_debug) console.timeEnd("Graph getBands dev" + device_id);
              }
            }, (response: ng.IHttpPromiseCallbackArg<any>) => {
              console.error("Graph getBands Failed", response);
              // if (WG_debug) console.timeEnd("Graph getBands dev" + device_id);
              graphDevice.bands = false;
            });
      };

      get_process(device.id);
    }

// Analyze data between given timestamps searching for the last fermentation process
// (sequencial data with liquid/density without fillups)
// Calls callback_fn with arguments (this, new_start, new_end)
    public get_ferm_process_limits(device_uuid: string, start_at: number | "auto", end_at: number, callback_fn): any {
      // if (WG_debug) console.time("Graph get_ferm_process_limits dev" + device_uuid);
      this.error_message = "";
      this.show = true;
      // let onAxis = true;
      if (start_at == "auto")
        start_at = null;
      let params: IDataParams = _.cloneDeep(this.defaultParams);
      let config = {
        params: {
          api_key: this.$rootScope.apiKey,
          // b: '1m',
          b: start_at,
          e: end_at,
          // auto_detect_start: params.auto_detect_start,
          //  ferm_limit: true,
        },
      }
      let data = {
        uuid: device_uuid,
      }

      let graphCtrl = this;

      // this.loading = true;
      this._chart.showLoading("Predicting");
      // if (WG_debug) console.log('Getting ferment_simulator with ', data, config);
      this.$http.post<any>('https://simulator.winegrid.com/data/event_detection', data, config).then(
          (response) => {
            // this.loading = false;
            this._chart.hideLoading();
            if (WG_debug) console.log("Event detection response:", response);

            if (!response.data) {
              console.error("event_detection response error: ", response.status, response.statusText);
              // if (WG_debug) console.timeEnd("Graph get_ferm_process_limits dev" + device_uuid);
              return;
            }

            let start = response.data.state?.[0]?.[0];
            let end = response.data.state?.[1]?.[0];
            callback_fn(graphCtrl, start, end);

            // if (WG_debug) console.timeEnd("Graph get_ferm_process_limits dev" + device_uuid);
            return;
          }, (reason) => {
            // this.loading = false;
            this._chart.hideLoading();
            this.error_message = reason?.data?.message;
            if (WG_debug) console.warn("Failed to get event_detection", reason?.data || reason);
            // if (WG_debug) console.timeEnd("Graph get_ferm_process_limits dev" + device_uuid);
          })
    }

    public getFermentSimulatorData(device: IDevice, params ?: IDataParams, model: string = '', add_to_lkm ?: boolean): void {
      let graphCtrl = this;
      // if (WG_debug) console.time("Graph getFermentSimulatorData dev" + device.id);
      this.error_message = "";
      this.show = true;
      let service_name = 'ferment_simulator'
      if (model == 'meshvines')
        service_name = 'meshvines_simulator'

      let graphDevice = this._getGraphDevice(device);
// if (graphDevice[service_name]) {
//   return;
// } else {
//   graphDevice[service_name] = true;
// }

      let _params: IDataParams = _.cloneDeep(this.defaultParams);
      if (!angular.isDefined(params)) {
        params = {};
      }
      angular.extend(_params, params);
      let config = {
        params: {
          api_key: this.$rootScope.apiKey,
          // b: '1m',
          b: params.start_at,
          e: params.end_at,
          auto_detect_start: params.auto_detect_start,
          //  ferm_limit: true,
        },
      }
      let data = {
        uuid: device.uuid,
        manual_temperature: params.manual_temperature,
      }
// this.loading = true;
      this._chart.showLoading("Predicting");
// if (WG_debug) console.log('Getting ferment_simulator with ', data, config);
      this.$http.post<any>('https://simulator.winegrid.com/data/' + service_name, data, config).then(
          (response) => {
            // graphCtrl.loading = false;
            this._chart.hideLoading();
            // if (WG_debug) console.timeLog("Graph getFermentSimulatorData dev" + device.id);
            if (WG_debug) console.log("Ferment simulator response:", response);

            if (!response.data || !response.data.current_estimated_values) {
              console.error("ferment_simulator response error: ", response.status, response.statusText);
              // if (WG_debug) console.timeEnd("Graph getFermentSimulatorData dev" + device.id);
              return;
            }

            let _timestamp = response.data.current_estimated_values.current_time || response.data.current_estimated_values.end || params.end_at;
            let _payload = {
              iid: device.iid,
              timestamp: _timestamp,
              value: response.data,
            };

            let _stream = "FERMENT_SIMULATOR";
            let _pred_sensors_list = PREDICTION_SENSORS_LIST;

            if (model == 'meshvines') {
              _stream = 'MESHVINES_SIMULATOR'
              _pred_sensors_list = MESHVINES_PREDICTION_SENSORS_LIST;
            }

            let _new_stream = _stream + "_MANUAL";

            let show_this_sensor_when_done = null;
            // Delete previous manually estimated series. Iterate over a copy to allow deleting on original array
            _.forEach(_.cloneDeep(_pred_sensors_list), function (_internal_name) {
              if (_internal_name?.includes(_new_stream)) {
                _.pull(_pred_sensors_list, _internal_name);

                let _sensor_entry = graphCtrl.WGApiData.WGSensors.sensors_name[_internal_name];
                if (_sensor_entry)
                  graphCtrl.removeSensor(device, _sensor_entry, true);

                graphCtrl.WGApiData.WGSensors.delete_local(_internal_name);

                if (device.last_values?.[_internal_name]) {
                  delete device.last_values[_internal_name];
                  delete graphCtrl.$rootScope.lastSensorValues[device.uuid][_internal_name];
                }

                if (device.lkm?.[_new_stream]) {
                  delete device.lkm[_new_stream];
                  delete graphCtrl.$rootScope.lastKnownMessages[device.uuid][_new_stream];
                }
              }
            });

            // Store now-processed predictions in a new "_manual" stream
            _.forEach(_pred_sensors_list, function (_internal_name) {
              if (_internal_name.includes(_new_stream)) {
                // Sanity check
                return;
              }

              let _new_internal_name = _internal_name.replace(_stream, _new_stream);
              if (_pred_sensors_list.includes(_new_internal_name)) // Already exists
                return;

              let _sensor_entry = graphCtrl.WGApiData.WGSensors.sensors_name[_internal_name];
              if (!_sensor_entry) {
                if (WG_debug) console.warn("No sensor entry", _internal_name, graphCtrl.WGApiData.WGSensors.sensors_name)
                return;
              }
              if (_sensor_entry.stream != _stream || _sensor_entry.stream == _new_stream) {
                // Already exists
                return;
              }

              let _new_sensor_entry = _.cloneDeep(_sensor_entry);

              // _new_sensor_entry.configs.manual = true;
              _new_sensor_entry.stream = _new_stream;
              _new_sensor_entry.internal_name = _new_internal_name;
              _new_sensor_entry.configs.under_icon = 'icon-wg-manual-entry';

              _pred_sensors_list.push(_new_internal_name);

              graphCtrl.WGApiData.WGSensors.add_local(_new_sensor_entry);

              if (_.includes(_internal_name, '_dens_cole') ||
                  _.includes(_internal_name, '_dens_boulton')) {
                show_this_sensor_when_done = _new_sensor_entry;
              }
            });

            graphCtrl.WGApiData.WGDevices.processLastKnownMessage(device.uuid, _new_stream, _timestamp, _payload, false);

            if (show_this_sensor_when_done) {
              graphCtrl.getSensor(device, show_this_sensor_when_done, null, true);
              // Expand xMax 4 days into the future
              let extremes = graphCtrl.xAxis_main?.getExtremes();
              if (extremes.max) {
                extremes.max = extremes.max + 4 * 24 * 60 * 60 * 1000;
                if (extremes.dataMin && extremes.min < extremes.dataMin) {
                  extremes.min = null;
                }
                if (extremes.dataMax && extremes.max > extremes.dataMax) {
                  extremes.max = null;
                }
                graphCtrl.xAxis_main?.setExtremes(extremes.min, extremes.max, true);
              }
            }

            // if (WG_debug) console.timeEnd("Graph getFermentSimulatorData dev" + device.id);
            return;
          }, (reason: ng.IHttpPromiseCallbackArg<any>) => {
            // graphCtrl.loading = false;
            this._chart.hideLoading();
            graphCtrl.error_message = reason?.data?.message;
            if (WG_debug) console.warn("Failed to get Fermentation Prediction", reason?.data || reason);
            // if (WG_debug) console.timeEnd("Graph getFermentSimulatorData dev" + device.id);
          })
    }

    private _setExportFilename(): void {
      let _filename_arr = ["Chart"];

      let _sensors_array = [];
      let _dev_array = [];

      _.forEach(this.graphedSeries, (_series) => {
        if (_series.sensor) {
          _sensors_array.push(this.$translate.instant(_series.sensor.name_sref) || _series.sensor.name);
        }
        if (_series.device) {
          _dev_array.push(this.WGApiData.WGDevices?.devices_id?.[_series.device.id]?.unit?.name || _series.device.name || _series.device.sn);
        }
      });

      _dev_array = _.uniq(_dev_array);
      _sensors_array = _.uniq(_sensors_array);

      if (!
          _.isEmpty(_dev_array)
      ) {
        _filename_arr.push(_.join(_dev_array, "+"));
      }

      if (!_.isEmpty(_sensors_array)
          && 1 + _filename_arr.join('-').length + _sensors_array.join('+').length <= 48) {
        // Only allow filenames up to 48 of size
        _filename_arr.push(_.join(_sensors_array, "+"));
      }

      this._chart.update({exporting: {filename: _.join(_filename_arr, "-")}});
      return;
    }

    /**
     * Returns specific configuration for this Device
     * (this.devices[device.uuid])
     */
    private _getGraphDevice(device: IDevice, create_if_missing = true): IGraphedDevice {
      if (_.isNil(this.graphedDevices[device.uuid])) {
        if (!create_if_missing) {
          return null;
        }
        // Adding device to list of shown devices
        this.graphedDevices[device.uuid] = {
          device: device,
          sensors: {},
          bands: false,
        };

        this.devices_capabilities.config_density_read ||= (device?.root_device || device)?.capabilities?.config_density_read;
        this.devices_capabilities.control_board ||= (device?.root_device || device)?.capabilities?.control_board;
        this.devices_capabilities.fermentation_prediction ||= (device?.root_device || device)?.capabilities?.fermentation_prediction;
        this.devices_capabilities.force_read ||= (device?.root_device || device)?.capabilities?.force_read;
        this.devices_capabilities.config_wifi ||= (device?.root_device || device)?.capabilities?.config_wifi;
      }
      return this.graphedDevices[device.uuid];
    }

    /**
     * Returns specific configuration for this Device + Parameter
     * (this.devices[device.uuid].sensors[sensor.internal_name])
     */
    private _getGraphDeviceSensor(device: IDevice, sensor: ISensor, create_if_missing = true): IGraphedDeviceSensor {
      //console.log('getGraphDeviceSensor', device, sensor.internal_name);
      let graphDevice = this._getGraphDevice(device, create_if_missing);
      if (!graphDevice) {
        return null;
      }
      if (!angular.isDefined(graphDevice.sensors[sensor.internal_name])) {
        if (!create_if_missing) {
          return null;
        }
        graphDevice.sensors[sensor.internal_name] = {
          sensor: sensor,
          flags: false,
          // graph: false,
        };
      }
      return graphDevice.sensors[sensor.internal_name];
    }

    /**
     * Returns global configuration for this particular Parameter
     * (this.graphedSensors[sensor.internal_name])
     */
    private _getGraphSensor(sensor: ISensor, create_if_missing = true): IGraphedSensor {
      let _sensor = sensor;

      if (this.graphedSensors[_sensor.internal_name])
        return this.graphedSensors[_sensor.internal_name];

      if (sensor.configs?.masterSensor && this.WGApiData.WGSensors.sensors_name[sensor.configs.masterSensor]) {
        _sensor = this.WGApiData.WGSensors.sensors_name[sensor.configs.masterSensor];
        if (this.graphedSensors[_sensor.internal_name])
          return this.graphedSensors[_sensor.internal_name];
      }

      if (!create_if_missing) {
        return null;
      }

      // if (WG_debug) console.log('new GraphSensor', _sensor);
      this.graphedSensors[_sensor.internal_name] = {
        sensor: _sensor,
        hasYAxis: false,
      };
      return this.graphedSensors[_sensor.internal_name];
    }

    public zoomXButton(): void {
      this.zoomXButtonSel = true;
      this.zoomYButtonSel = false;
      this.zoomYXButtonSel = false;
      this.moveButtonSel = false;
      this.scaleButtonSel = false;

      this.setZoomType('x');
    }

    public zoomYButton(): void {
      this.zoomXButtonSel = false;
      this.zoomYButtonSel = true;
      this.zoomYXButtonSel = false;
      this.moveButtonSel = false;
      this.scaleButtonSel = false;

      this.setZoomType('y');
    }

    public zoomYXButton(): void {
      this.zoomXButtonSel = false;
      this.zoomYButtonSel = false;
      this.zoomYXButtonSel = true;
      this.moveButtonSel = false;
      this.scaleButtonSel = false;

      this.setZoomType('xy');
    }

    public moveButton(): void {
      this.zoomXButtonSel = false;
      this.zoomYButtonSel = false;
      this.zoomYXButtonSel = false;
      this.moveButtonSel = true;
      this.scaleButtonSel = false;

      this.setZoomType(<Highcharts.OptionsTypeValue>'');

      // this.styles.panning = true;
      // this._chart.update({chart: {panning: {enabled: true}}}, false);
    }

    public scaleButton(): void {
      if (WG_debug) console.log('graph scaleButton:');
      this.zoomXButtonSel = false;
      this.zoomYButtonSel = false;
      this.zoomYXButtonSel = false;
      this.moveButtonSel = false;
      this.scaleButtonSel = true;

      // this.setZoomType('', true);
      // this.setZoomType(<Highcharts.OptionsTypeValue>'');


      // _.forEach(this._chart.yAxis, (_yAxis: Highcharts.Axis) => {
      //   _yAxis.options.scalable = true;
      //   _yAxis.scalable = true;
      //   // _yAxis.options.panningEnabled = false;
      // });
      //
      // _.forEach(this.chartConfig.yAxis, (yAxis: Highcharts.Axis) => {
      //   yAxis.options.scalable = true;
      //   yAxis.scalable = true;
      //   // yAxis.options.panningEnabled = false;
      // });
      //
      // this._chart.update({chart: {panning: {enabled: false}}}, false);
    }


    public resetButton(): void {
      if (WG_debug) console.log('graph resetButton:');

      _.forEach(this._chart?.xAxis, function (xAxis) {
        let xExtremes = xAxis.getExtremes();
        // xAxis.setExtremes(xExtremes.dataMin, xExtremes.dataMax, false);
        xAxis.setExtremes(null, null, false);
      });
      _.forEach(this._chart?.yAxis, function (yAxis) {
        let yExtremes = yAxis.getExtremes();
        // yAxis.setExtremes(yExtremes.dataMin, yExtremes.dataMax, false);
        yAxis.setExtremes(null, null, false);
      });
      this._chart.redraw();

      this.setZoomStateParams();
    }

    public zoomOutButton(): void {
      // _.forEach(this._chart?.yAxis, (yAxis) => {
      //   if (!yAxis?.options?.id?.startsWith('navigator'))
      //     this.setZoomAxis(yAxis, this.zoomStep, false);
      // });

      this.setZoomAxis(this.xAxis_main, this.zoomStep, true);
    }

    public zoomInButton(): void {
      // _.forEach(this._chart?.yAxis, (yAxis) => {
      //   if (!yAxis?.options?.id?.startsWith('navigator'))
      //     this.setZoomAxis(yAxis, 1.0/this.zoomStep, false);
      // });

      this.setZoomAxis(this.xAxis_main, 1.0 / this.zoomStep, true);
    }

    public scaleAxisButton(axis: Highcharts.YAxisOptions, limit: string, action: string): void {
      let amount = this.yAxisScaleStep;
      if (action == 'minus') {
        amount *= -1;
      }

// console.log(axis.id, limit, action, axis[limit], amount);

      let yAxis = <Highcharts.Axis>this._chart.get(axis.id);
      if (limit == 'min') {
        yAxis.setExtremes(axis.min + amount, axis.max)
      } else if (limit == 'max') {
        yAxis.setExtremes(axis.min, axis.max + amount)
      }
      this.setZoomStateParams();
    }

    public scaleAxis(axis: Highcharts.AxisOptions, limit: string): void {
      // console.log(axis.id, limit);
      let yAxis = <Highcharts.Axis>this._chart.get(axis.id);
      yAxis?.setExtremes(axis.min, axis.max)
    }

    public scaleModalButton(): void {
      // console.log('scaleModalButton');
      // this._scale_aux();
      this.ngDialog.openConfirm({
        template: 'graphModalDialogScale',
        className: 'ngdialog-theme-default',
        data: {
          axis: this.$scope['scaleData']
        }
      }).then((data: any) => {
        console.log('graphModalDialogScale', data);
      }, (reason) => {
        console.log('Modal promise rejected. Reason: ', reason);
      });
    }

// private _scale_aux() {
//   this.scaleData = this.$scope['scaleData'] = [];
//   this._chart.yAxis.forEach((yAxis: Highcharts.Axis) => {
//     if (yAxis.options.id !== undefined && yAxis.options.id.indexOf('yAxis-') != -1) {
//       let extremes = <Highcharts.Extremes>yAxis.getExtremes();
//       let dataMin = extremes.userMin !== undefined ?  extremes.userMin : extremes.dataMin;
//       let dataMax = extremes.userMax !== undefined ?  extremes.userMax : extremes.dataMax;
//       console.log(yAxis.options.id, dataMin, dataMax, extremes, this._chart.get(yAxis.options.id));
//       this.$scope['scaleData'].push({
//         id: yAxis.options.id,
//         options: yAxis.options,
//         extremes: extremes,
//         min: dataMin,
//         max: dataMax
//       });
//
//       // axis.setExtremes(newMin, newMax);
//       this.$scope.$watch('scaleData', (data: any[]) => {
//         data.forEach((d) => {
//           let yAxis = <Highcharts.Axis>this._chart.get(d.id);
//           console.log('scaleData', d, yAxis.setExtremes(d.min, d.max));
//         });
//       }, true)
//     }
//   });
// }

// public setTitle(): void {
//   // this._chart.setTitle({text: 'Foo Baz'});
//   // this._chart.setTitle({text: ' - '});
//   // console.log('setTitle');
// }

    private timer_setZoomStateParams: NodeJS.Timeout;

    public setZoomStateParams(extremes: Highcharts.ExtremesObject = null) {
      let graphCtrl = this;
      if (this.timer_setZoomStateParams)
        clearTimeout(this.timer_setZoomStateParams);
      this.timer_setZoomStateParams = setTimeout(function () {
        if (!extremes || !(extremes.min || extremes.max || extremes.dataMin || extremes.dataMax)) {
          extremes = graphCtrl.xAxis_main?.getExtremes();
        }
        if (!extremes || !(extremes.min || extremes.max || extremes.dataMin || extremes.dataMax)) {
          return;
        }
        //if (WG_debug) console.log("graph setZoomStateParams:", extremes)
        // Replace previous history,
        graphCtrl.AuthService.update_url({
          'xAxisMin': (extremes.min && extremes.min > extremes.dataMin) ? extremes.min.toFixed(0) : "auto",
          'xAxisMax': (extremes.max && extremes.max < extremes.dataMax) ? extremes.max.toFixed(0) : null,
        }, false, false, true);
      }, 100);
    }

    public exportChartAs(type: "png" | "jpg" | "svg" | "pdf" | string = 'pdf') {
      // console.log(this.devices);
      let axis = this.xAxis_main;
      var extremes = axis.getExtremes();
      // var dateExtremes = {
      //   min: new Date(extremes.min),
      //   max: new Date(extremes.max),
      //   dataMin: new Date(extremes.dataMin),
      //   dataMax: new Date(extremes.dataMax),
      // };

      // normalize type:
      if (type.includes('jpg')) {
        type = 'image/jpeg';
      } else if (type.includes('png')) {
        type = 'image/png';
      } else if (type.includes('svg')) {
        type = 'image/svg+xml';
      } else if (type.includes('pdf')) {
        type = 'application/pdf';
      }

      // console.log(extremes, dateExtremes);
      // let svg0 = this._chart.getSVGForExport?.({type: 'image/png'});
      // let svg1 = this._chart.exportChartLocal();
      // let svg2 = this._chart.exportChartLocal({ type: "image/jpeg" });
      // let svg3 = this._chart.exportChartLocal({type: "image/svg+xml"})
      // let svg3 = this._chart.exportChart({type: 'image/png'});
      // let svg4 = this._chart.exportChart({type: 'image/png'}, null);
      // let svg5 = this._chart.exportChart({type: "image/svg+xml"}, null);
      // let svg6 = this._chart.exportChartLocal({type: 'image/png'}, this.chartConfig);
      // let svg7 = this._chart.exportChart({type: 'image/png'}, this.chartConfig);
      // let svg8 = this._chart.exportChart({type: 'image/svg+xml'}, this.chartConfig);
      // let svg9 = this._chart.getSVG(null);
      // let svg5 = this._chart.getSVGForExport?.(this.chartConfig);
      // let svg10 = this._chart.getSVGForExport?.({type: 'image/png'}, this.chartConfig);
      // let svg11 = this._chart.getSVGForExport?.();
      // let svg11 = this._chart.getSVGForLocalExport?.();
      let svg10 = this._chart['getSVGForLocalExport']?.({type: 'image/svg+xml'}, null, (reason) => { // Fail
        // let svg10 = this._chart.getSVGForLocalExport?.({type: 'image/png'}, null, (reason) => { // Fail
        console.warn("Fail Export!", reason);
      }, (svg) => { // Success

        // save "svg" as a local file
        let blob = new Blob([svg], {type: 'image/svg+xml'});
        let url = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'chart.svg';
        a.click();
        URL.revokeObjectURL(url);

        let data = {
          filename: 'chart',
          type: type,
          width: 0, // IE8 fails to post undefined correctly, so use 0
          scale: 1.0,
          svg: svg,
          async: true,
        };

        // Generate URL with file
        let exportUrl = 'https://charts.winegrid.com/';
        // let exportUrl = 'https://export.highcharts.com/';
        this.$http.post(exportUrl, data).then(
            (result: ng.IHttpPromiseCallbackArg<string>) => {
              console.log(result.data);
              // download the file at ${exportUrl}${result.data}
              console.log(exportUrl + result.data);

            }, () => {
            });

      });
    }

    public createNote() {
      // console.log(this.devices);
      let axis = this.xAxis_main;
      var extremes = axis.getExtremes();
      // var dateExtremes = {
      //   min: new Date(extremes.min),
      //   max: new Date(extremes.max),
      //   dataMin: new Date(extremes.dataMin),
      //   dataMax: new Date(extremes.dataMax),
      // };

      // console.log(extremes, dateExtremes);
      // let svg0 = this._chart.getSVGForExport?.({type: 'image/png'});
      // let svg1 = this._chart.exportChartLocal();
      // let svg2 = this._chart.exportChartLocal({ type: "image/jpeg" });
      // let svg3 = this._chart.exportChartLocal({type: "image/svg+xml"})
      // let svg3 = this._chart.exportChart({type: 'image/png'});
      // let svg4 = this._chart.exportChart({type: 'image/png'}, null);
      // let svg5 = this._chart.exportChart({type: "image/svg+xml"}, null);
      // let svg6 = this._chart.exportChartLocal({type: 'image/png'}, this.chartConfig);
      // let svg7 = this._chart.exportChart({type: 'image/png'}, this.chartConfig);
      // let svg8 = this._chart.exportChart({type: 'image/svg+xml'}, this.chartConfig);
      // let svg9 = this._chart.getSVG(null);
      // let svg5 = this._chart.getSVGForExport?.(this.chartConfig);
      // let svg10 = this._chart.getSVGForExport?.({type: 'image/png'}, this.chartConfig);
      // let svg11 = this._chart.getSVGForExport?.();
      // let svg11 = this._chart.getSVGForLocalExport?.();
      let svg10 = this._chart['getSVGForLocalExport']?.({type: 'image/svg+xml'}, null, (reason) => { // Fail
        // let svg10 = this._chart.getSVGForLocalExport?.({type: 'image/png'}, null, (reason) => { // Fail
        console.warn("Fail Export!", reason);
      }, (svg) => { // Success

        let data = {
          filename: 'chart',
          type: 'image/svg+xml', // PNG/JPEG is buggy, marker's symbols appear black
          width: 0, // IE8 fails to post undefined correctly, so use 0
          scale: 1.0,
          svg: svg,
          async: true,
        };
        this.creatingNote = true;

        // Generate URL with file
        let exportUrl = 'https://charts.winegrid.com/';
        this.$http.post(exportUrl, data).then(
            (result: ng.IHttpPromiseCallbackArg<string>) => {
              console.log(result.data);
              let _data_2 = {
                url: `${exportUrl}${result.data}`,
              };
              // Send the URL to our API to save the file
              this.$http.post('api/dashboard/notes/files/', _data_2).then(
                  (_result_2: ng.IHttpPromiseCallbackArg<any>) => {
                    // console.log(result.data);
                    this.creatingNote = false;
                    this.$state.go('app.notes', {
                      action: 'new',
                      note: null,
                      attachments: [{
                        id: _result_2.data.id,
                        file: _result_2.data.file
                      }]
                    })
                  }, () => {
                    this.creatingNote = false;
                  });
            }, () => {
              this.creatingNote = false;
            });

      });
      // var doc = document,
      //   win = window,
      //   createElement = Highcharts.createElement,
      //   merge = Highcharts.merge,
      //   HIDDEN = 'hidden',
      //   NONE = 'none',
      //   name,
      //   form;
      // // create the form
      // form = createElement('form', merge({
      //   method: 'post',
      //   action: exportUrl,
      //   enctype: 'multipart/form-data'
      // }, {}), {
      //   display: NONE
      // }, doc.body);
      //
      // // add the data
      // for (name in data) {
      //   createElement('input', {
      //     type: HIDDEN,
      //     name: name,
      //     value: data[name]
      //   }, null, form);
      // }
      //
      // // submit
      // console.log(form.submit());

      // this._chart.exportChart({type: 'image/jpeg'});
    }
  }

  function GraphDirective(): ng.IDirective {
    // console.log('graph GraphDirective');
    return {
      restrict: 'E',
      controller: GraphController,
      controllerAs: 'graphCtrl',

      link: (scope, element: ng.IAugmentedJQuery, attributes: ng.IAttributes, controller: GraphController): void => {
        // Intentionally blank, for now
      },
      transclude: true,
      templateUrl: 'app/views/directives/graph.html',
    };
  }

  App.directive('wgGraph', GraphDirective);

}