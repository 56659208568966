namespace wg {
  App.controller("configControlModalInstance", ['$rootScope', '$scope', '$filter', '$http', '$q', '$timeout', '$interval', '$element', '$controller', '$location', '$stateParams', '$window', 'ngDialog', 'AuthService', '$translate' /*, '$transitions'*/, 'APIUtils', 'configControlModalUtils',
    function ($rootScope: IRootScope, $scope, $filter: ng.IFilterService, $http: ng.IHttpService, $q, $timeout: ng.ITimeoutService, $interval, $element, $controller, $location: ng.ILocationService, $stateParams, $window, ngDialog: angular.dialog.IDialogService, AuthService: IAuthService, $translate /*, $transitions,*/, APIUtils: APIUtils, configControlModalUtils) {
      $scope.NUMBER_OF_OUTPUTS = 1;
      $scope.spitData = function () {
        spitData("Control Modal, spit", $scope);
      }

      var openMode = 'openConfirm';
      if (angular.isDefined($scope.ngDialogData.mode)) {
        openMode = $scope.ngDialogData.mode;
      }

      $scope.ctrl = {};

      $scope.retApply = {
        loading: false,
        result: '',
      };

      let parentRet: IReturnResult = {
        loading: false,
        result: '',
        message: '',
      };
      $scope.retSave = parentRet;

      let ret_key = $scope.ngDialogData?.result || 'configControlBoard';
      if ($scope.$parent?.[ret_key]) {
        $scope.$parent[ret_key] = parentRet;
      } else if ($scope.$parent?.$parent?.[ret_key]) {
        $scope.$parent.$parent[ret_key] = parentRet;
      }


      $scope.device = $rootScope.WGDevices.devices_uuid[$scope.ngDialogData.device.uuid];

      function set_n_ctrl_manual_output(n, status) {
        if (n < 1 || n > $scope.NUMBER_OF_OUTPUTS) {
          return;
        }
        n -= 1;
        if (status) {
          $scope.ctrl.configs_data.ctrl_manual_output |= 1 << n;
        } else {
          $scope.ctrl.configs_data.ctrl_manual_output &= ~(1 << n);
        }
      }

      function get_ctrl_output(output) {
        let ret = {};
        for (let n = 0; n < $scope.NUMBER_OF_OUTPUTS; ++n) {
          ret[(n + 1) + ''] = ((output >> n) & 0x01) === 1;
        }
        return ret;
      }

      function parse(configs, data, lkm) {
        if (!data) {
          console.warn("No current data");
        } else {
          _.forEach([['TEMP', 'temperature'], ['IN_TEMP', 'in_temperature'], ['IN_HUMF', 'in_humidity']], function (stream_to_key) {
            if (data[stream_to_key[0]] && Number.isFinite(data[stream_to_key[0]].val_numeric)) {
              $scope.ctrl.current_data[stream_to_key[1]] = data[stream_to_key[0]].val;
            }
          })
        }
        if (!configs) {
          console.warn("No current configs");
          return;
        }
        if (configs.data) {
          if (WG_debug) console.warn("Current configs", configs);
          configs = configs.data;
        }

        let _data = {};
        // TODO: Change this to pointer-style to automatically update value when another user changes it
        _.forEach(['ctrl_cycle_time', 'ctrl_temp_mode', 'ctrl_temp_set_point', 'ctrl_temp_hysteresis', 'ctrl_output', 'ctrl_manual_output'], function (key) {
          if (!_.isNil(configs[key])) {
            _data[key] = Number(configs[key]);
          } else if (!_.isNil(lkm[key.toUpperCase()])) {
            _data[key] = Number(lkm[key.toUpperCase()].payload.value);
          }
          if (!_.isNil(_data[key])) {
            $scope.ctrl.current_configs_data[key] = _data[key];
            $scope.ctrl.configs_data[key] = _data[key];
            if (['ctrl_temp_set_point', 'ctrl_temp_hysteresis'].indexOf(key) >= 0) {
              $scope.ctrl.current_configs_data[key] = _.round(_data[key], 2);
              $scope.ctrl.configs_data[key] = _.round(_data[key], 2);
            }
          }
        });

        if (!_.isNil(_data['ctrl_temp_mode'])) {
          if (!_.isNil($scope.ctrl.CTRL_TEMP_MODES_name[_data['ctrl_temp_mode']])) {
            $scope.ctrl.current_configs_data['ctrl_temp_mode'] = $scope.ctrl.CTRL_TEMP_MODES_name[_data['ctrl_temp_mode']];
          }
          $scope.ctrl.configs_data['ctrl_temp_mode'] = _data['ctrl_temp_mode'] + '';
        }
        if (!_.isNil(_data['ctrl_output'])) {
          $scope.ctrl.current_configs_data['ctrl_output_obj'] = get_ctrl_output(_data['ctrl_output']);
          $scope.ctrl.configs_data['ctrl_output_obj'] = get_ctrl_output(_data['ctrl_output']);
        }
        if (!_.isNil(_data['ctrl_manual_output'])) {
          $scope.ctrl.current_configs_data['ctrl_manual_output_obj'] = get_ctrl_output(_data['ctrl_manual_output']);
          $scope.ctrl.configs_data['ctrl_manual_output_obj'] = get_ctrl_output(_data['ctrl_manual_output']);
        }
      }

      function init() {
        $scope.ctrl = {
          save: function (form, apply) {
            if (_.isNil(apply)) {
              apply = false;
            }
            if (!configControlModalUtils.validate_form(form)) {
              return;
            }
            let data: IDeviceLKMConfigs = {}

            // Set config value if changed
            if ($scope.ctrl.configs_data.hasOwnProperty('ctrl_cycle_time')) {
              data.ctrl_cycle_time = parseInt($scope.ctrl.configs_data.ctrl_cycle_time);
            }
            if ($scope.ctrl.configs_data.hasOwnProperty('ctrl_temp_mode')) {
              data.ctrl_temp_mode = parseInt($scope.ctrl.configs_data.ctrl_temp_mode);
            }
            if ($scope.ctrl.configs_data.hasOwnProperty('ctrl_temp_hysteresis')) {
              data.ctrl_temp_hysteresis = parseFloat($scope.ctrl.configs_data.ctrl_temp_hysteresis);
            }
            if ($scope.ctrl.configs_data.hasOwnProperty('ctrl_temp_set_point')) {
              data.ctrl_temp_set_point = parseFloat($scope.ctrl.configs_data.ctrl_temp_set_point);
            }
            if ($scope.ctrl.configs_data.hasOwnProperty('ctrl_manual_output')) {
              data.ctrl_manual_output = parseInt($scope.ctrl.configs_data.ctrl_manual_output);
            }
            // console.log(data, "data");

            if (apply) {
              $scope.retApply.loading = true;
              $scope.retApply.result = '';
            } else {
              $scope.retSave.loading = true;
              $scope.retSave.result = '';
            }
            APIUtils.save_device_configs($scope.device, data, function (response) {
              console.log('Done save configs', data, response.data)
              if (apply) {
                // Read Now:
                APIUtils.force_device_read($scope.device, false, false, function (response) {
                  $scope.retApply.loading = false;
                  $scope.retApply.result = 'success';
                }, function (response) {
                  $scope.retApply.loading = false;
                  $scope.retApply.result = 'error';
                });
              } else {
                $scope.retSave.loading = false;
                $scope.retSave.result = 'success';
              }
            }, function (response) {
              console.error('Save control failed', response);
              if (apply) {
                $scope.retApply.loading = false;
                $scope.retApply.result = 'error';
              } else {
                $scope.retSave.loading = false;
                $scope.retSave.result = 'error';
              }
            })
            // configControlModalUtils.set_configs(data).then(parse_configs);
          },
          manual_output_changed: function (key) {
            console.log('K' + key, $scope.ctrl.configs_data.ctrl_manual_output_obj[key]);
            set_n_ctrl_manual_output(parseInt(key), $scope.ctrl.configs_data.ctrl_manual_output_obj[key]);
          },
          current_data: {
            temperature: NaN,
            in_temperature: NaN,
            in_humidity: NaN,
          },
          current_configs_data: {},

          configs_data: {
            ctrl_temp_mode: null,
            ctrl_manual_output: NaN,
            ctrl_manual_output_obj: {},
            ctrl_temp_set_point: NaN,
            ctrl_temp_hysteresis: NaN,
            ctrl_cycle_time: NaN,
          },

          show_data_fields: {
            temperature: 'current_data',
            // in_temperature: 'current_data',
            // in_humidity: 'current_data',
          },
          show_config_fields: {
            // ctrl_cycle_time: 'current_configs_data',
            ctrl_temp_mode: 'current_configs_data',
            ctrl_temp_set_point: 'current_configs_data',
            ctrl_temp_hysteresis: 'current_configs_data',
          },
          field_name: {
            temperature: $translate.instant('app.modal.control.TEMP'),
            in_temperature: $translate.instant('app.modal.control.INT_TEMP'),
            in_humidity: $translate.instant('app.modal.control.INT_HUM'),
            ctrl_cycle_time: $translate.instant('app.modal.control.CYCLE_TIME'),
            ctrl_temp_mode: $translate.instant('app.modal.control.MODE'),
            ctrl_output: $translate.instant('app.modal.control.CURRENT_OUTPUT'),
            ctrl_manual_output: $translate.instant('app.modal.control.MANUAL_OUTPUT'),
            ctrl_output_title: $translate.instant('app.modal.control.OUTPUT_TITLE'),
            ctrl_temp_set_point: $translate.instant('app.modal.control.TEMP_SET_POINT'),
            ctrl_temp_hysteresis: $translate.instant('app.modal.control.TEMP_HYST'),
          },
          field_icon: {
            temperature: "icon-wg-temperature",
            in_temperature: 'icon-wg-temperature',
            in_humidity: 'icon-wg-hum',
            ctrl_cycle_time: "m10 fa fa-clock-o",
            ctrl_temp_mode: "icon-wg-temperature_control",
            ctrl_output: "",
            ctrl_manual_output: "",
            ctrl_temp_set_point: "icon-wg-temperature_setpoint",
            ctrl_temp_hysteresis: "icon-wg-temperature_histerisis",
          },
          field_unit: {
            temperature: "ºC",
            in_temperature: 'ºC',
            in_humidity: '%RH',
            ctrl_cycle_time: "sec",
            ctrl_temp_mode: "",
            ctrl_output: "",
            ctrl_manual_output: "",
            ctrl_temp_set_point: "ºC",
            ctrl_temp_hysteresis: "ºC",
          },
          CTRL_TEMP_MODES: {
            CTRL_TEMP_MODE_MANUAL: 0,
            CTRL_TEMP_MODE_COOLING: 1,
            CTRL_TEMP_MODE_HEATING: 2
            // CTRL_TEMP_MODE_AUTO: 3,
          },
          CTRL_OUTPUTS_name: {
            true: 'On',
            false: 'Off',
          },
          CTRL_TEMP_MODES_name: {},
        }
        $scope.ctrl.configs_data.ctrl_manual_output_obj = get_ctrl_output($scope.ctrl.configs_data.ctrl_manual_output);

        $scope.ctrl.CTRL_TEMP_MODES_name[$scope.ctrl.CTRL_TEMP_MODES.CTRL_TEMP_MODE_MANUAL] = $translate.instant('app.modal.control.MANUAL');
        $scope.ctrl.CTRL_TEMP_MODES_name[$scope.ctrl.CTRL_TEMP_MODES.CTRL_TEMP_MODE_COOLING] = $translate.instant('app.modal.control.COOLING');
        $scope.ctrl.CTRL_TEMP_MODES_name[$scope.ctrl.CTRL_TEMP_MODES.CTRL_TEMP_MODE_HEATING] = $translate.instant('app.modal.control.HEATING');
        //$scope.ctrl.CTRL_TEMP_MODES_name[$scope.ctrl.CTRL_TEMP_MODES.CTRL_TEMP_MODE_AUTO] = 'Auto';
      }

      init();

      $scope.updateDataConfigs = function () {

        if (!$rootScope.lastKnownMessages) {
          console.warn("parse, Fail! LKMs not yet available.");
          return;
        }
        let lkm = $rootScope.lastKnownMessages[$scope.device.uuid] || {};

        let _lkm_configs = {};
        // Do not trust the $scope.device.configs, they are not being updated via mqtt.
        if (!_.isNil(lkm?.configs?.payload?.value)) {
          _lkm_configs = lkm.configs.payload.value;
        }

        parse(_lkm_configs, $scope.device.last_values, lkm);
      };

      $scope.unWatchDeviceLKM = $rootScope.$watch('lastKnownMessages["' + $scope.device.uuid + '"]', $scope.updateDataConfigs, true);
      $scope.unWatchDeviceLSV = $scope.$watch('device.last_values', $scope.updateDataConfigs, true);

    }]);

  App.service('configControlModalUtils', ['$rootScope', '$http', '$q', '$location',
    function ($rootScope: IRootScope, $http: ng.IHttpService, $q, $location) {
      function validate_form(form) {
        if (!form || typeof form == 'undefined' || form.$invalid) {
          console.error('invalid form', form);
          return false;
        }
        return true;
      }

      return {
        validate_form: validate_form,
      }
    }]);


}