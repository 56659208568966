/**
 * Created by silva on 14-09-2016.
 */

App.controller("GeositeController", ['$rootScope', '$scope', '$http', 'uiGmapIsReady', function ($rootScope, $scope, $http, uiGmapIsReady) {
  $scope.map = {
    center: {latitude: 40.6327325, longitude: -8.6549854},
    zoom: 2,
    bounds: {},
    originalBounds: {}
  };
  $scope.options = {scrollwheel: true};
  $scope.maploading = true;
  
  $scope.deviceMarkers = [];
  $scope.statewatch = false;
  
  $scope.$watch(function () {
    return $scope.map.bounds;
  }, function (nv, ov) {
    // Only need to regenerate once
    if (!ov.southwest && nv.southwest) {
      angular.extend($scope.map.originalBounds, $scope.map.bounds);
    }
  }, true);
  
  uiGmapIsReady.promise(1).then(function () {
    $scope.options = {scrollwheel: false, mapTypeId: google.maps.MapTypeId.ROADMAP};
    //$scope.options = {scrollwheel: false};
    $scope.loadMapMakers();
    $scope.maploading = false;
  });
  
  $scope.loadMapMakers = function () {
    if ($scope.statewatch == false) {
      $scope.statewatch = true;
      $scope.$parent.$watch('gotDevices', function () {
        $scope.loadMapMakers();
      }, true);
    }
    
    if (typeof (google) === 'undefined')
      return;
    if (typeof (google.maps) === 'undefined')
      return;
    
    console.log("devices", $rootScope.userDevices);
    if ($rootScope.userDevices.length == 0)
      return;
    
    var devices = $rootScope.userDevices; //$scope.$parent.devices;
    var markers = [];
    for (var i = 0; i < devices.length; i++) {
      if (devices[i].lat == null || devices[i].lon == null)
        continue;
      markers.push({
        id: devices[i].name,
        latitude: devices[i].lat,
        longitude: devices[i].lon,
        title: devices[i].name,
        icon: "https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|" + devices[i].name + "|939393|FFFFFF"
        //icon: "http://chart.apis.google.com/chart?chst=d_map_spin&chld=1|0|ffffff|10|_|" + devices[i].name
      });
    }
    
    if (markers.length == 0)
      return;
    $scope.deviceMarkers = markers;
    
    var bounds = new google.maps.LatLngBounds();
    angular.forEach(markers, function (marker) {
      bounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude));
    });
    var northeast = bounds.getNorthEast();
    var southwest = bounds.getSouthWest();
    $scope.map.bounds = {
      northeast: {
        latitude: northeast.lat(),
        longitude: northeast.lng()
      },
      southwest: {
        latitude: southwest.lat(),
        longitude: southwest.lng()
      }
    };
    angular.extend($scope.map.originalBounds, $scope.map.bounds);
  }
}]);