/*
* [x] Early Exits!!!
* [x] Feedback if no devices
* [x] Feedback if no params
* [x] translate
* [x] translate processes types
* [x] undo filter number of devices
* [x] (un)select batch
* [x] collapse help
* [x] on success modal, close params modal
* [x] check variable names for http request
* [ ] check possible http error messages
* [x] clear console.log's
* [x] clear unnecessary comments
* [x] BREAKS IN IE!!!
* [x] hovering help panel
*
* [x] doesn't disable submit button in parameter modal

* */
namespace wg {
  enum ExportStateEnum {
    PENDING = "PENDING",
    STARTED = "STARTED",
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
  }

  interface ExportState {
    id: string,
    info?: {
      current: number,
      file_name: string,
      total: number
    },
    state: ExportStateEnum
  }

  App.controller("ExportDataController", ['$rootScope', '$scope', '$http', '$timeout', '$state', '$stateParams', '$translate', 'ngDialog', 'AuthService', 'Data', 'DataUtils', 'WGApiData',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $timeout: ng.ITimeoutService, $state: _IState, $stateParams, $translate: ng.translate.ITranslateService, ngDialog: angular.dialog.IDialogService, AuthService: IAuthService, Data, DataUtils: DataUtils, WGApiData: WGApiData) {

      $scope.normal_types = ['pressing', 'maturation', 'fermentation', 'ambient', 'storage'];

      $scope.exportable_devices = [];
      $scope.isHelpCollapsed = true;

      //                --- OBJECTS
      $scope.process_types = {
        available: []
      };
      $scope.dates = {
        block: false,
        now: new Date(),
        getNow: function () {
          return this.now = new Date();
        },
        startMax: new Date(),
        onChangeEndDate: function () {
          // start date should not be bigger than now,
          // neither than the end date.
          this.startMax = $scope.exportData.ended_at ? new Date($scope.exportData.ended_at) : this.getNow();
        },
      };

      $scope.dOrg = {
        filter: {
          results: [],
          obj: {
            text: $translate.instant("app.export.devices.search.NO_FILTER"),
            term: '',
            prop_type: 'all',
            prop_value: 'default'
          },
          ui_f: [
            {
              text: $translate.instant("app.export.devices.search.NO_FILTER"),
              ppt: 'all',
              ppv: 'default'
            },
            {
              text: $translate.instant("app.export.devices.search.IN_UNT"),
              ppt: 'has_unit',
              ppv: null
            },
            {
              text: $translate.instant("app.export.devices.search.IN_PROC"),
              ppt: 'has_process',
              ppv: null
            }
          ],
          apply: function (obj) {
            if (obj.ppt === 'none')
              return;
            // console.log("applied filter");
            $scope.dOrg.filter.obj.text = obj.text;
            $scope.dOrg.filter.obj.prop_type = obj.ppt;
            $scope.dOrg.filter.obj.prop_value = obj.ppv;
          },
          clear: function () {
            $scope.dOrg.filter.obj.prop_type = null;
            $scope.dOrg.filter.obj.prop_value = null;
          }
        },
        sorter: {
          current: {
            text: $translate.instant("app.export.devices.search.BY_NM"),
            code: 'default',
            rvrs: false,
            default: true
          },
          ui_s: [
            {
              text: $translate.instant("app.export.devices.search.BY_NM"),
              code: 'name',
              rvrs: false,
            },
            {
              text: $translate.instant("app.export.devices.search.BY_NM_RV"),
              code: 'name',
              rvrs: true
            },
            {
              text: $translate.instant("app.export.devices.search.BY_UNT"),
              code: 'unit.name',
              rvrs: false
            },
            {
              text: $translate.instant("app.export.devices.search.BY_UNT_RV"),
              code: 'unit.name',
              rvrs: true
            },
            {
              text: $translate.instant("app.export.devices.search.BY_PRC"),
              code: 'unit.process.process_type',
              rvrs: false
            },
            {
              text: $translate.instant("app.export.devices.search.BY_PRC_RV"),
              code: 'unit.process.process_type',
              rvrs: true
            }
          ],
          apply: function (obj) {
            $scope.dOrg.sorter.current = obj;
          }
        }
      }
      $scope.itButtonData = {
        'status': 'Select All'
      };
      $scope.shown = true;
      $scope.changeStatus = function (btn) {
        btn.status = btn.status === "Select All" ? "Unselect All" : "Select All";
        console.log(btn);
      };
      $scope.exportData = {
        // Date
        start: null,
        // Set start date to passed date (optional), and to the start of the minute
        setStart: function (time: number | Date = null) {
          if (!_.isNil(time)) {
            this.start = new Date(time);
          }
          if (_.isNil(this.start)) {
            this.start = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
          }
          this.start.setSeconds(0, 0);
        },
        end: null, // Date
        setEnd: function (time: number | Date = null) {
          if (!_.isNil(time)) {
            this.end = new Date(time);
          }
          if (_.isNil(this.end)) {
            this.end = new Date();
          }
          this.end.setSeconds(59, 999);
        },
        ids: [],
        params: [],
        clicker: function (id) {
          console.log(id, this, this.isSelected(id))
          if (this.isSelected(id))
            this.unselect(id);
          else
            this.select(id);
        },
        select: function (id) {
          if (_.includes(this.ids, id))
            return;
          this.ids.push(id);
          // $scope.dOrg.filter.obj.ids.push(id);
        },
        unselect: function (id) {
          if (!_.includes(this.ids, id))
            return;
          this.ids = _.without(this.ids, id);
          // $scope.dOrg.filter.obj.ids = _.without($scope.dOrg.filter.ids, id);
        },
        change_batch: function (dir) {
          if (dir) {
            _.forEach(
                $scope.dOrg.filter.results,
                function (x) {
                  $scope.exportData.select(x.id);
                }
            )
          } else {
            _.forEach(
                $scope.exportData.ids,
                function (x) {
                  $scope.exportData.unselect(x);
                }
            )
          }
        },
        isSelected: function (idx) {
          return _.includes(this.ids, idx);
        },
        isDone: function () {
          let startdate = this.start ? new Date(this.start) : null;
          let haveStart = startdate !== null;
          let haveEnd = angular.isDefined(this.end) && this.end !== null;
          let difIsPos = haveEnd && haveStart ? this.end > startdate : false;
          return this.ids.length > 0 && difIsPos;
        }
      };

      // if (WG_debug) {
      //   $scope.exportData.start = new Date(2001, 1, 1, 0, 0, 0);
      //   $scope.exportData.end = new Date();
      // }
      //                --- FUNCTIONS

      function getParametersFromDevices() {
        let fullSelectedParms = [];
        let _allStreamsWithData = []; // All parameters from user's devices except smartboxes

        // Get All streams from selected devices
        _.forEach($scope.exportable_devices, function (x_dev) {
          if (_.includes($scope.exportData.ids, x_dev.id)) {
            _allStreamsWithData = _.union(_.keys(x_dev.lkm), _allStreamsWithData);
          }
        });

        // _.forEach(ALL_SENSORS_NAMES, function (parameter_internal_name) {
        _.forEach(WGApiData.WGSensors.sensors_name, function (_sensor, _internal_name) {
              if (!_sensor) {
                if (WG_debug) console.info('User parameter not in root-sensors?');
                return;
              }
              if (!DataUtils.canUserAccessSensor(_internal_name)) {
                return;
              }
              let _selected = false;
              if ($stateParams) {
                if ($stateParams.params) {
                  if (_.isArray($stateParams.params)) {
                    _selected = _.includes($stateParams.params, _sensor.id);

                  } else if (_.isPlainObject($stateParams.params)) {
                    _selected = !!_.find($stateParams.params, {id: _sensor.id});
                  }
                }

                for (let i = 1; i < 17; i++) {
                  if ($stateParams['param' + i]) {
                    if (WGApiData.WGSensors.sensors_name?.[$stateParams['param' + i]]?.id == _sensor.id) {
                      _selected = true;
                      break;
                    }
                  } else {
                    break;
                  }
                }
              }

              // Sensor is available in user's devices
              if (_.includes(_allStreamsWithData, _sensor.stream))
                fullSelectedParms.push({
                  id: _sensor.id,
                  internal_name: _sensor.internal_name,
                  stream: _sensor.stream,
                  name: _sensor.name,
                  name_sref: _sensor.name_sref,
                  admin: (_sensor.configs.accessLevel === 'admin'),
                  accessLevel: _sensor.configs.accessLevel,
                  manual: _sensor.configs.manual,
                  selected: _selected,
                });
            }
        );

        // GO!
        return fullSelectedParms;
      }

      function getDevicesObjs() {
        // iterate exportData.ids
        return _.map($scope.exportData.ids,
            function (xid, i, l) {
              // get device from devices
              let d = _.find($scope.exportable_devices, {id: xid});
              // return obj with desired properties
              return {
                id: xid,
                iid: d.iid,
                uuid: d.uuid,
                path: d.path,
                name: d.name
                // ,configs: d.configs
              };
            });
      }

      //                --- ACTIONS

      let paramsModal = {} as ng.dialog.IDialogOpenResult;
      $scope.proceed_to_params = function () {
        // prevent duplicate
        if (ngDialog.isOpen(paramsModal.id)) {
          // console.log('tried to open seocnd time');
          return
        }

        //get parameteres
        let paramsForModal = getParametersFromDevices();

        //get devices
        let devicesForModal = getDevicesObjs();

        // Open modal
        paramsModal = ngDialog.open({
          id: 'myOwnId',
          template: 'exportModal',
          controller: 'ExportDataModalController',
          closeByDocument: false,
          data: {
            start: $scope.exportData.start,
            end: $scope.exportData.end,
            devices: devicesForModal,
            params: paramsForModal,
          }
        } as angular.dialog.IDialogOpenOptions);
        paramsModal.closePromise.then(function (obj) {
          // console.log("close paramsModal. obj",obj);
        });
      };

      //                --- INIT
      function make_sorter_filter() {
        // add process types into filter
        // ccording to process types existing among the devices
        if ($scope.process_types.available.length > 0) {
          $scope.dOrg.filter.ui_f.push({
            text: 'line',
            ppt: 'none',
            ppv: null
          });
        }
        _.forEach(
            $scope.process_types.available,
            function (tp_txt, ix) {
              // var nameArr = ["fermentation","maturation","pressing","ambient","storage","other"];
              // var name = nameArr.indexOf(tp_txt)>-1 ? $translate.instant('app.processes.TYPE_NAMES.'+tp_txt) : tp_txt;
              let is_n = _.includes($scope.normal_types, tp_txt);
              $scope.dOrg.filter.ui_f.push({
                text: is_n ? $translate.instant('app.processes.TYPE_NAMES.' + tp_txt) : tp_txt,
                ppt: 'proc_type',
                ppv: tp_txt
              });
            }
        );
      }

      function get_process_types() {
        $scope.process_types.available = [];
        _.forEach($scope.exportable_devices, function (exp_dev) {
          let pt = exp_dev.unit && exp_dev.unit.process && exp_dev.unit.process.active ?
              exp_dev.unit.process.process_type ?
                  exp_dev.unit.process.process_type :
                  $translate.instant('app.admin.UNDEFINED') :
              null;
          let repeated_pt = _.indexOf($scope.process_types.available, pt);
          if (pt && !repeated_pt)
            $scope.process_types.available.push(pt);
        });
        return true
      }

      function get_devices() {
        $scope.loading = true;
        if ($rootScope.userDevices.length < 1) {
          $scope.loading = false;
          return false
        }

        //  NORMALIZE // ?GET STATUS READS? // FILTER OUT SMARTBOX
        _.forEach($rootScope.userDevices,
            function (device, index) {
              if (device.model !== "smartbox" && device.model !== "virtual_smartbox")
                $scope.exportable_devices.push(device);
            });

        return true
      }

      // $rootScope.$on('devices_updated', function (event, args) {
      $rootScope.$watch('WGApiData.AllReady', function () {
        if (!$rootScope.WGApiData.AllReady) {
          return;
        }
        console.log("initiated exports:", $scope.dOrg.filter.results, $stateParams);
        if (get_devices())
          if (get_process_types())
            make_sorter_filter();

        if ($stateParams) {
          for (let i = 1; i < 19; i++) {
            if ($stateParams['device' + i]) {
              if (WGApiData.WGDevices.devices_uuid?.[$stateParams['device' + i]]?.id)
                $scope.exportData.select(WGApiData.WGDevices.devices_uuid?.[$stateParams['device' + i]]?.id);
            } else {
              break;
            }
          }

          // Select devices passed by params
          if (!_.isEmpty($stateParams.devices)) {
            if (WG_debug) console.log("Selecting devices passed by params:", $stateParams.devices);

            _.forEach($stateParams.devices, function (device) {
              if (_.isFinite(parseInt(device)))
                $scope.exportData.select(device);
              else if (device.id)
                $scope.exportData.select(device.id);
            });
          }

          // Legacy. Same as "devices"
          if (!_.isEmpty($stateParams.sensors)) {
            console.log("Selecting devices passed by params:", $stateParams.sensors);

            _.forEach($stateParams.sensors, function (sensor) {
              if (_.isFinite(parseInt(sensor)))
                $scope.exportData.select(sensor);
              else if (sensor.id)
                $scope.exportData.select(sensor.id);
            });
          }

          if ($stateParams.xAxisMin === 'auto') {
            $scope.exportData.setStart(0);
          } else if (_.isFinite(parseInt($stateParams.start))) {
            $scope.exportData.setStart(parseInt($stateParams.start));
          } else if (_.isFinite(parseInt($stateParams.xAxisMin))) {
            $scope.exportData.setStart(parseInt($stateParams.xAxisMin));
          }
          if (_.isNil($scope.exportData.start)) {
            // set default start date to 1 week ago
            $scope.exportData.setStart();
          }

          if (_.isFinite(parseInt($stateParams.end))) {
            $scope.exportData.setEnd(parseInt($stateParams.end));
          } else if (_.isFinite(parseInt($stateParams.xAxisMax))) {
            $scope.exportData.setEnd(parseInt($stateParams.xAxisMax));
          }
          if (_.isNil($scope.exportData.end)) {
            // set default end date to Now
            $scope.exportData.setEnd();
          }
        }
        $scope.loading = false;
      });

      //\\ DEV //\\
      $scope.spitData = function () {
        console.log("export SD : ", $scope);
      };
    }])
      .controller("ExportDataModalController", ['$rootScope', '$scope', '$filter', '$http', '$timeout', '$state', '$stateParams', '$translate', 'ngDialog', '$document', '$interval', 'AuthService', 'Data', 'WGApiData',
        function ($rootScope: angular.IRootScopeService, $scope: angular.IScope, $filter: ng.IFilterService, $http: ng.IHttpService, $timeout: ng.ITimeoutService, $state: _IState, $stateParams, $translate: ng.translate.ITranslateService, ngDialog: angular.dialog.IDialogService, $document, $interval, AuthService: IAuthService, Data, WGApiData: WGApiData) {
          //  MODAL   ***   MODAL   ***   MODAL   ***   MODAL   ***   MODAL

          // console.log('ExportDataModalController params', $scope.ngDialogData.params);
          // validate inputs and disable submit
          $scope.hasSelParams = function () {
            return _.find($scope.ngDialogData.params, {selected: true});
          };

          // get params' obj with desired properties
          function getParamsObj() {
            let pck = [];
            _.forEach($scope.ngDialogData.params, function (_param) {
              if (!_param.selected) {
                return;
              }
              let _sensor = WGApiData.WGSensors.sensors_name[_param.internal_name];
              if (!_sensor) {
                if (WG_debug) console.error("Didn't find rootSensor. Please report", _param.internal_name, WGApiData.WGSensors.sensors_name);
                _sensor = _.find(WGApiData.WGSensors.sensors, {id: _param.id});
                if (!_sensor) {
                  console.error("Missing required sensor for export. Please report");
                  return;
                }
              }
              // pck.push(p.internal_name); // Minimum required
              var e = {
                id: _sensor.id,
                name: _param.name,
                internal_name: _sensor.internal_name,
                stream: _sensor.stream,
                configs: _sensor.configs,
                unit: _sensor.unit,
                icon: _sensor.configs.icon,
                // @ts-ignore
                code_name: _sensor.configs.code_name, // Something used in Export API only
                si_type: _sensor.configs.si_type,
                decimals: _sensor.configs.decimals,
                query: _sensor.configs.query,
                sub_query: _sensor.configs.sub_query,
                exportQuery: _sensor.configs.exportQuery,
              }
              if (WG_debug) console.info("Exporting with sensor:", _sensor, e);
              pck.push(e);
            });
            return pck
          }

          // submit data
          $scope.sendRequest = function () {
            let postData = {
              devices: $scope.ngDialogData.devices,
              sensors: getParamsObj(),
              desired_conversions: AuthService.user.configs?.conversions,
              configs: {timestamp_offset_to_utc: ((new Date().getTimezoneOffset()) * 60 * 1000)},
            };
            let options = {
              responseType: "arraybuffer",
              params: {
                b: new Date($scope.ngDialogData.start).getTime(),
                e: new Date($scope.ngDialogData.end).getTime(),
                async_task: true,
              }
            };

            // console.log("Exporting package:", postData);

            var key = '?api_key=' + $rootScope.apiKey;
            // var url = 'https://api.winegrid.com/data/export' + key;
            var url = $rootScope.base_url + 'data/export' + key;
            console.log('url', url)
            $http.post(url, postData, options).then(
                function (response) {
                  // console.log("Success getting exported data 1:", response);
                  if (response.status === 202) {
                    let location = response.headers('location');
                    if (response.headers('content-type') === "application/json") {
                      let data: ExportState = toJson(response.data)
                      console.log('data', data);
                      console.log('location', location);
                      location = $rootScope.base_url + 'data/export/state/' + data.id
                      console.log('location', location);
                      let status_modal = open_status_modal(location);
                      return;
                    }
                  } else {
                    download(response);
                  }

                  function open_status_modal(location) {
                    ngDialog.open({
                      controller: ['$scope', function ($scope) {
                        $scope.progress = 0;
                        get_status(location, $rootScope.apiKey, function (data, progress) {
                          $scope.progress = progress;
                        });
                      }],
                      template: 'app/views/modals/export-status.html',
                      className: 'ngdialog-theme-default',
                      preCloseCallback: function (v) {
                        $scope.closeThisDialog(1);
                        return true
                      }
                    });
                  }

                  function get_status(task_url, api_key, on_status) {
                    let options = {
                      responseType: "arraybuffer",
                      params: {
                        api_key: api_key
                      }
                    }
                    $http.get(task_url, options).then(
                        function (response) {
                          if (response.status === 200) {
                            let content_type = response.headers('content-type');
                            if (content_type === "application/json") {
                              let data: ExportState = toJson(response.data);
                              console.log(data);
                              if (data.state === 'STARTED') {
                                let progress = data.info.current / data.info.total * 100;
                                console.log('progress', progress)
                                if (on_status) {
                                  on_status(data, progress)
                                }
                              }
                              if (data.state === 'PENDING' || data.state === 'STARTED') {
                                $timeout(function () {
                                  get_status(task_url, api_key, on_status);
                                }, 1000)
                              }
                            } else if (content_type === "application/excel") {
                              if (on_status) {
                                on_status(null, 100);
                              }
                              download(response);
                            }
                          }
                        });
                  }

                  function toJson(data) {
                    if ('TextDecoder' in window) {
                      // Decode as UTF-8
                      let dataView = new DataView(data);
                      let decoder = new TextDecoder('utf8');
                      return JSON.parse(decoder.decode(dataView));
                    } else {
                      // Fallback decode as ASCII
                      let decodedString = String.fromCharCode.apply(null, new Uint8Array(data));
                      return JSON.parse(decodedString);
                    }
                  }

                  function download(response, open_modal = false) {
                    // SOME SORT OF FEEDBACK?
                    if (open_modal) {
                      ngDialog.open({
                        template: 'app/views/modals/export-status.html',
                        // template: '<span class="fa icon-arrow-down-circle text-success w-100 text-lg-imp text-center mt-lg mb-lg"></span>' +
                        //     '<h4 class="text-success">' + $translate.instant('app.export.fb.SUCCESS') + '</h4>' +
                        //     '<div class="text-center">' +
                        //     '<button class="btn btn-success btn-sm" type="button" ng-click="closeThisDialog(1)">close</button>' +
                        //     '</div>',
                        // plain: true,
                        className: 'ngdialog-theme-default',
                        preCloseCallback: function (v) {
                          $scope.closeThisDialog(1);
                          return true
                        }
                      });
                    }
                    // // DOWNLOAD FILE
                    // // Try to find out the filename from the content disposition `filename` value
                    // var filename = get_content_disposition_filename(response, 'data_export.xlsx');
                    var t = new Date();
                    var filename = 'WG Export Data ' +
                        t.getFullYear() + '-' +
                        t.getMonth() + '-' +
                        t.getDate() + ' ' +
                        t.getHours() + '-' +
                        t.getMinutes() +
                        '.xlsx';

                    // The actual download
                    var blob = new Blob([response.data], {type: 'application/excel'});
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                }
                , function (response) {
                  console.log("error getting exported data:", response);
                  let msgTitle = response.status < 500 ?
                      $translate.instant('app.export.fb.CLNT_ERROR_1') :
                      $translate.instant('app.export.fb.SRVR_ERROR_1');
                  let msgText = response.status < 500 ?
                      $translate.instant('app.export.fb.CLNT_ERROR_2') :
                      $translate.instant('app.export.fb.SRVR_ERROR_2');

                  ngDialog.openConfirm({
                    template: '<span class="fa fa-warning text-warning w-100 text-center text-lg-imp mt-lg mb-lg"></span>' +
                        '<h4 class="text-warning">' +
                        msgTitle + '</h4>' +
                        '<p>' + msgText + '</p>' +
                        '<div class="text-center">' +
                        '<button class="btn btn-warning btn-sm" type="button" ng-click="confirm(1)">OK</button>' +
                        '</div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                  });
                });

          };

          // DEV \\
          $scope.spitData = function () {
            console.log("ExportDataModalController SD : ", $scope);
          };
        }]);
}