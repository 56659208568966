/**=========================================================
 * Module: edit-batches
 * Create/Edit Batches
 =========================================================*/
namespace wg {
  App.controller('manageBatchesEditController', ['$rootScope', '$scope', '$http', 'ngDialog', '$translate',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, ngDialog: ng.dialog.IDialogService, $translate: ng.translate.ITranslateService) {
      //      								 ---     ngDialog?

      $scope.isMoreHelpCollapsed = true;

      var openMode = 'open';
      var deleteConfirmIsOpen = false;
      if (angular.isDefined($scope.ngDialogData.mode)) {
        openMode = $scope.ngDialogData.mode;
      }


      let parentRet: IReturnResult = {
        loading: false,
        result: '',
        message: '',
      };
      $scope.ret = parentRet;

      let ret_key = $scope.ngDialogData?.result || 'manualEntryRet';
      if ($scope.$parent?.[ret_key]) {
        $scope.$parent[ret_key] = parentRet;
      } else if ($scope.$parent?.$parent?.[ret_key]) {
        $scope.$parent.$parent[ret_key] = parentRet;
      }


      $scope.show = {
        description: true,
        dyn_fields: true
      };

      // $scope.new_local = {
      //     name:'', is_active: true,
      //     dynamic_fields : []};
      let action = 'create';
      if ($scope.ngDialogData.action === 'edit') {
        action = 'edit';
        $scope.elementData = angular.copy($scope.ngDialogData.elem);
      } else {
        $scope.elementData = {};
      }

      //                                  --- DATETIMEPICKER
      $scope.nowDate = new Date();
      $scope.maxDate = new Date($scope.nowDate)
      $scope.maxDate.setDate($scope.maxDate.getDate() + 1);

      $scope.dateTimeNow = function () {
        return new Date();
      };
      $scope.dateOptions = {
        startingDay: 1,
        showWeeks: false
      };
      //                                  --- DYNAMIC FIELDS
      $scope.df = {
        WARN: false,
        length: function () {
          return _.keys($scope.elementData.dynamic_fields).length
        },
        new_values: {key: '', value: ''},
        has_fields: function () {
          return _.keys($scope.elementData.dynamic_fields).length > 0
        },
        has_values: function () {
          return this.new_values.key !== '' && this.new_values.value !== ''
          // this.h_val = !(this.new_values.key==='' && this.new_values.value==='');
        },
        removeDynField: function (chave) {
          delete $scope.elementData.dynamic_fields[chave];
        },
        addDynField: function (keyvalue, isNew) {
          //\\ keyvalue&isNew are for the purpose of allowing to
          // submit dynamic field with an enter.
          // but it also triggers submit,
          // so the values are just being pre-satisfied on function call
          // if($scope.df.last_df_has_values() && keyvalue===13 && isNew) {
          // alert('check for dups');
          if (!angular.isDefined($scope.elementData.dynamic_fields) || $scope.elementData.dynamic_fields === null) {
            $scope.elementData.dynamic_fields = {};
          }
          var ch = this.new_values.key;
          var vl = this.new_values.value;
          var propriedades = _.keys($scope.elementData.dynamic_fields);
          var isDupe = _.indexOf(propriedades, ch) > -1;
          this.WARN = isDupe;
          if (isDupe) {
            // console.log("isDupe", isDupe);
            return
          } else {
            console.log("is NOT Dupe", isDupe);
            if (this.has_values()) {
              $scope.elementData.dynamic_fields[
                  ch
                  ] = vl;
              this.new_values.key = '';
              this.new_values.value = '';
            }
          }
          // this.length =_.keys($scope.elementData.dynamic_fields).length;
          // $scope.dfLength =_.keys($scope.elementData.dynamic_fields).length;

          // this.length = _.keys($scope.elementData.dynamic_fields).length;
          console.log("addDynFld. length", this.length);
        }
      };

      //                              --- ACTIONS // SAVE // DELETE
      $scope.saveData = function (dataset) {
        parentRet.loading = true;

        dataset = normalizeData($scope.elementData);
        console.log("Save BATCH, elementData", $scope.elementData, 'saveData', dataset);
        var url = 'api/dashboard/batches/';
        var http_method = $http.post;
        if ($scope.ngDialogData.action === 'edit') {
          url += $scope.elementData.id + '/'
          http_method = $http.patch;
        }

        $rootScope.WGBatches.changed = true;
        http_method(url, dataset).then(
            function (response) {
              $rootScope.WGBatches.changed = true;
              console.log("Save BATCH SUCCESS, dataset", dataset,
                  "response.data", response.data);


              parentRet.loading = false;
              parentRet.result = 'success';
              // reload();

              $scope.elementData = response.data;
              // ngDialog.close(0);
              if ($scope.ngDialogData.action === 'create') {
                console.log("Action is now Edit");
                $scope.ngDialogData.action = 'edit';
              }
              // Exit the modal 1s after a successful save:
              setTimeout(function () {
                $scope.cancel(0);
              }, 600);

            }, function (response) {
              console.error("HTTP ERROR. response", response);
              parentRet.result = 'error';
              parentRet.loading = false;
            });
      };

      $scope.deleteThis = function () {
        console.log("delete BATCH, elementData: ", $scope.elementData);
        var extraString = '';
        extraString = create_confirmDelete_string();
        if (!deleteConfirmIsOpen) {
          deleteConfirmIsOpen = true;
        } else {
          return
        }
        ngDialog.openConfirm({
          template: 'app/views/modals/confirm-danger.html',
          className: 'ngdialog-theme-default',
          data: {
            what: 'batches',
            which: $scope.ngDialogData.elem.name,
            extra: extraString
          }
        }).then(
            function (data) {
              //delete
              console.log('DELETE BATCH CONFIRM', data);
              $rootScope.WGBatches.changed = true;
              $http.delete('api/dashboard/batches/' + $scope.elementData.id + '/').then(
                  function (response) {
                    $rootScope.WGBatches.changed = true;
                    console.log("DELETE BATCH response:", response);

                    if (response.status >= 200 && response.status < 300) {
                      console.log("DELETE BATCH SUCCESS");
                      // $scope.closeThisDialog({response: response, data: $scope.elementData});
                      // ngDialog.close({response: response});
                      // $scope.closeThisDialog({response: response, item_data: $scope.elementData, wasDelete:true} );
                      $scope.closeThisDialog({response: response, wasDelete: true});

                    }
                    //$scope.loading = false;
                  }, function (response) {
                    console.error("HTTP ERROR. response", response);
                    parentRet.result = 'error';
                    parentRet.loading = false;
                  });

              deleteConfirmIsOpen = false;
            }, function (reason) {
              deleteConfirmIsOpen = false;
              console.log('DELETE Modal promise rejected. Reason: ', reason);
            })
      };
      $scope.cancel = function (value) {
        parentRet.loading = false;

        var isAdd = action === 'create' && angular.isDefined($scope.elementData);
        var return_result = {
          isAdd: isAdd,
          item_data: $scope.elementData
        };

        if (parentRet.result === 'success' && $scope.confirm)
          $scope.confirm(return_result);
        else if ($scope.closeThisDialog)
          $scope.closeThisDialog(return_result);
        else
          $scope.close(0);
      };

      //                                  --- FUNCTIONS

      /**
       * Returns something like:
       * "This " + TYPE + " has UNITS AND/OR PROCESSES associated,"
       * "If you delete it, all associated elements will also be deleted.";
       */
      function create_confirmDelete_string() {
        // If place, it can have one or more units,
        // and each unit will know if it has one or more processes.
        // if a batch, it will have one or more processes.
        let stringToReturn = '';
        let _has_units = $scope.elementData && $scope.elementData.units && $scope.elementData.units.length > 0;

        let _has_processes = $scope.elementData && $scope.elementData.processes && $scope.elementData.processes.length > 0;
        _has_processes = _has_processes || ($scope.elementData && $scope.elementData.process);

        if (_has_units || _has_processes) {
          stringToReturn += $translate.instant('app.manage.delete.associated.THIS')
              + ' ' + $translate.instant('app.manage.batches.SINGULAR');

          if (_has_units && _has_processes) {
            stringToReturn += ' ' + $translate.instant('app.manage.delete.associated.UNIT_PROC');
          } else if (_has_units) {
            stringToReturn += ' ' + $translate.instant('app.manage.delete.associated.UNIT');
          } else if (_has_processes) {
            stringToReturn += ' ' + $translate.instant('app.manage.delete.associated.PROC');
          }
        }

        return stringToReturn
      }

      //                                  --- UTILS
      var iter = null;

      // Make sure only shallow and accepted fields are sent?
      function normalizeData(currentData) {
        if (!currentData) {
          return {};
        }
        let sendableJSON = {};
        let _valid_keys = ['id', 'name', 'description',
          'active', 'started_at', 'ended_at', 'created_at', 'dynamic_fields',]

        // // put empty json in Dyn Fields
        // if(!angular.isDefined(currentData.dynamic_fields)) {
        // currentData.dynamic_fields = {};
        // }

        for (let _key of _valid_keys) {
          if (currentData[_key]) {
            sendableJSON[_key] = currentData[_key];
          }
        }

        return sendableJSON
      }


      //\\-/ DEV \-//\\
      // $scope.$watch(
      //     'dynamic_fields',
      //     function(){
      //         console.log('dynamic_fields',$scope.dynamic_fields);
      //     }
      // )
      $scope.spitData = function () {
        console.log("SpDt. $scope", $scope, $scope.$parent,
            "ngDialog", $scope.ngDialogData);
      };
    }]);
}