/**
 * Created by pmec on 03/12/15.
 */

namespace wg {

  App.directive('loader', [function () {
    'use strict';

    const loaders = {
      "ball-pulse": 3,
      "ball-grid-pulse": 9,
      "ball-clip-rotate": 1,
      "ball-clip-rotate-pulse": 2,
      "square-spin": 1,
      "ball-clip-rotate-multiple": 2,
      "ball-pulse-rise": 5,
      "ball-rotate": 1,
      "cube-transition": 2,
      "ball-zig-zag": 2,
      "ball-zig-zag-deflect": 2,
      "ball-triangle-path": 3,
      "ball-scale": 1,
      "line-scale": 5,
      "line-scale-party": 4,
      "ball-scale-multiple": 3,
      "ball-pulse-sync": 3,
      "ball-beat": 3,
      "line-scale-pulse-out": 5,
      "line-scale-pulse-out-rapid": 5,
      "ball-scale-ripple": 1,
      "ball-scale-ripple-multiple": 3,
      "ball-spin-fade-loader": 8,
      "line-spin-fade-loader": 8,
      "triangle-skew-spin": 1,
      "pacman": 5,
      "ball-grid-beat": 9,
      "semi-circle-spin": 1,

      "la-ball-8bits": 16,
      "la-ball-atom": 4,
      "la-ball-beat": 3,
      "la-ball-circus": 5,
      "la-ball-climbing-dot": 4,
      "la-ball-clip-rotate": 1,
      "la-ball-clip-rotate-multiple": 2,
      "la-ball-clip-rotate-pulse": 2,
      "la-ball-elastic-dots": 5,
      "la-ball-fall": 3,
      "la-ball-fussion": 4,
      "la-ball-grid-beat": 9,
      "la-ball-grid-pulse": 9,
      "la-ball-newton-cradle": 4,
      "la-ball-pulse": 3,
      "la-ball-pulse-rise": 5,
      "la-ball-pulse-sync": 3,
      "la-ball-rotate": 1,
      "la-ball-running-dots": 5,
      "la-ball-scale": 1,
      "la-ball-scale-multiple": 3,
      "la-ball-scale-pulse": 2,
      "la-ball-scale-ripple": 1,
      "la-ball-scale-ripple-multiple": 3,
      "la-ball-spin": 8,
      "la-ball-spin-clockwise": 8,
      "la-ball-spin-clockwise-fade": 8,
      "la-ball-spin-clockwise-fade-rotating": 8,
      "la-ball-spin-fade": 8,
      "la-ball-spin-fade-rotating": 8,
      "la-ball-spin-rotate": 2,
      "la-ball-square-clockwise-spin": 8,
      "la-ball-square-spin": 8,
      "la-ball-triangle-path": 3,
      "la-ball-zig-zag": 2,
      "la-ball-zig-zag-deflect": 2,
      "la-cog": 1,
      "la-cube-transition": 2,
      "la-fire": 3,
      "la-line-scale": 5,
      "la-line-scale-party": 5,
      "la-line-scale-pulse-out": 5,
      "la-line-scale-pulse-out-rapid": 5,
      "la-line-spin-clockwise-fade": 8,
      "la-line-spin-clockwise-fade-rotating": 8,
      "la-line-spin-fade": 8,
      "la-line-spin-fade-rotating": 8,
      "la-pacman": 6,
      "la-square-jelly-box": 2,
      "la-square-loader": 1,
      "la-square-spin": 1,
      "la-timer": 1,
      "la-triangle-skew-spin": 1,

      "css-load1": 1,
      "css-load2": 1,
      "css-load3": 1,
      "css-load4": 1,
      "css-load5": 1,
      "css-load6": 1,
      "css-load7": 1,
      "css-load8": 1
    };

    return {

      restrict: 'EA',

      replace: true,

      scope: {},

      template: function (elem, attr) {
        return '\
        <div class="{{loaderClass}}">\
          <div class="loader-inner {{loader}} {{loaderInClass}}">\
            <div ng-repeat="node in nodes"></div>\
          </div>\
        </div>';

        //if (attr.loader.indexOf("la-") == -1) {
        // return '<div class="loader"><div class="loader-inner  {{loader}}"><div ng-repeat="node in nodes" class="{{loaderInClass}}"></div></div></div>';
        //} else {
        //  return '<div class="loader {{loader}}"><div ng-repeat="node in nodes" class="{{loaderInClass}}"></div></div>'
        //}
      },

      link: function (scope, elem, attr) {
        attr.$observe('loader', init);

        init();

        function init() {
          scope.nodes = [];
          scope.loader = attr.loader;
          scope.loaderClass = attr.loaderClass || 'loader';
          scope.loaderInClass = attr.loaderInClass;
          scope.loaderSize = attr.loaderSize || 'sm';

          if (scope.loader.indexOf('la-') >= 0) {
            //scope.loaderInClass = scope.loaderInClass || 'la-sm la-dark';
            if (scope.loaderSize === 'sm') {
              scope.loaderInClass = scope.loaderInClass || 'la-sm';
            }
          } else if (scope.loader.indexOf('css-') >= 0) {
            if (scope.loaderSize === 'sm') {
              scope.loaderInClass = scope.loaderInClass || 'loader-sm';
            }
            scope.loader = scope.loader.replace('css-', '');
          }
          for (let i = 0; i < loaders[scope.loader]; i++) {
            scope.nodes.push(i);
          }
          return true;
        }

      }

    };
  }]);

  App.directive('sweetAlertLoader', [function () {
    'use strict';
    return {

      restrict: 'EA',

      replace: true,

      scope: {
        state: '=sweetAlertLoader'
      },

      template: function (elem, attr) {
        //  {{state}} {{error}}:{{state==error}} {{success}}:{{state==success}} {{warning}}:{{state==warning}}
        return '<div class="sweet-alert-loader {{sweetAlertLoaderClass}}">\
      <div ng-if="state==error" class="sa-icon {{sweetAlertLoaderSize}} sa-error animateErrorIcon">\
        <span class="sa-x-mark animateXMark"><span class="sa-line sa-left"></span>\
        <span class="sa-line sa-right"></span></span>\
      </div>\
      <div ng-if="state==success" class="sa-icon {{sweetAlertLoaderSize}} sa-success animate">\
        <span class="sa-line sa-tip animateSuccessTip"></span>\
        <span class="sa-line sa-long animateSuccessLong"></span>\
        <div class="sa-placeholder"></div>\
        <div class="sa-fix"></div>\
      </div>\
      <div ng-if="state==warning" class="sa-icon {{sweetAlertLoaderSize}} sa-warning pulseWarning">\
        <span class="sa-body pulseWarningIns"></span>\
        <span class="sa-dot pulseWarningIns"></span>\
      </div>\
      </div>';
      },

      link: function (scope, elem, attr) {
        scope.error = 'error';
        scope.success = 'success';
        scope.warning = 'warning';
        //attr.$observe('sweetAlertLoader', init);
        init();

        function init() {
          scope.sweetAlertLoaderClass = attr.sweetAlertLoaderClass || 'sa-loader';
          scope.sweetAlertLoaderSize = attr.sweetAlertLoaderSize || 'sa-sm';
          //sa-loader-inline

          //console.log('change', scope.state);
          //elem.animate({
          //  //width: shown ? '+=25px' : '-=25px'
          //  width: '+=25px'
          //});
        }
      }
    }
  }]);

}