namespace wg {
  export let WG_debug: boolean = window['WG_debug'];
  export let WG_version: string = window['WG_version'];
  export let WG_site_name: string = window['WG_site_name'];
  export let screenLog: {init: any, log: any} = window['screenLog'];
}
let WG_debug = wg.WG_debug;
let WG_version = wg.WG_version;
let WG_site_name = wg.WG_site_name;

// let _;