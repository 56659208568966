namespace wg {


    export class WgBatchActionController {
        static $inject = [];

        wgTableCtrl: WgTableController;

        constructor() {

        }

        $onInit = () => {

        }

        doBatchAction = (action:wgTableAction) => {


            //turns something like [true,false,true] into [{row0},{row2}]
            const selectedRows= this.wgTableCtrl.isRowSelected
                                    .map((isSelected,index) => { return isSelected ? this.wgTableCtrl.data[index] : null })
                                    .filter((row) => row !== null);

            action.callback(selectedRows);


        }

    }


    App.component('wgBatchActions', {
        controller: WgBatchActionController as any,
        templateUrl: "app/views/directives/wg-batch-actions.html",
        require: {wgTableCtrl: '^wgTable'},
        bindings: {
            actions: '<'
        },
        transclude: true,
    });
}