/**=========================================================
 * Module: mqtt.js
 * Handles MQTT re-connections and message handling
 =========================================================*/

namespace wg {

  export class MQTTService {
    static $inject = ['$rootScope', 'AuthService', '$timeout'];

    public topics_to_subscribe: string[];
    public enabled: boolean = true;
    public log_enabled: boolean = true;

    // Override this implementation
    public onMessageArrived: Paho.MQTT.OnMessageHandler = (message) => {
      if (this.log_enabled && WG_debug) console.log("MQTT Message arrived:", message);
    };

    constructor(private $rootScope: IRootScope, private AuthService: IAuthService, private $timeout) {
      console.log('MQTT Service Initializing');
      $rootScope.mqtt_status = "Disabled";
      $rootScope.mqtt_status_color = "grey";
      $rootScope.mqtt_status_extra = '';
      $rootScope.mqtt_status_extra_2 = '';
    }


    /**************************************************
     *********************** MQTT *********************
     **************************************************/


    private mqtt_client: Paho.MQTT.Client = null;

    private connecting = false;

    // "ws://"+(ipv6?"["+host+"]":host)+":"+port+path;
    private URIs = [
      'wss://ws.winegrid.com:443/mqtt',
      'wss://ws.watgrid.com:443/mqtt',
      // ## Invalid ports:
      // 'wss://ws.winegrid.biz:443/mqtt',
      // 'wss://wine.watgrid.com:443/mqtt_alt',
      // 'wss://ws.winegrid.io:48554/mqtt',
      // 'wss://ws.winegrid.com:8883/mqtt',
      // 'wss://ws.winegrid.com:1883/mqtt',
      // 'wss://ws.winegrid.com:21/mqtt',
      // 'wss://ws.winegrid.com:993/mqtt',
      // 'wss://ws.winegrid.com:53/mqtt',
      // 'wss://ws.winegrid.com:465/mqtt',
      // 'wss://ws.winegrid.com:23/mqtt',
    ]
    private current_uri_i = this.URIs.length - 1;

    private useTLS = true;

    private cleanSession = false;
    private reconnectStepTimeout = 5 * 1000;
    private reconnectBaseTimeout = this.reconnectStepTimeout;
    private reconnectMaxTimeout = 5 * 60 * 1000;
    private reconnectMaxCount = 300; // 300*5 min ~ 1 day
    private reconnectCount = 0;
    private reconnectTimeout = this.reconnectBaseTimeout;

    private connect_timer: NodeJS.Timeout = null;
    private subscribe_timer: NodeJS.Timeout = null;

    private clientId: string = null;

    public disconnect() {
      let uri = this.mqtt_client?.uri || this.URIs[this.current_uri_i];
      this.$timeout(() => {
        this.$rootScope.mqtt_status = "Fail";
        this.$rootScope.mqtt_status_color = "red";
        this.$rootScope.mqtt_status_extra = "Disconnecting from " + uri;
        this.$rootScope.mqtt_status_extra_2 = '';
      }, 1)
      if (this.log_enabled) console.log("MQTT disconnect");

      if (this.mqtt_client && this.mqtt_client.isConnected()) {
        this.connecting = false;
        try {
          this.mqtt_client.disconnect();
        } catch (e) {
        }
      }
    }

    private subscribe(uri: string = null) {
      if (this.connecting) {
        this.reconnectCount = 0;
        this.connecting = false;
      }
      if (!uri)
        uri = this.mqtt_client?.uri || this.URIs[this.current_uri_i];
      this.$timeout(() => {
        this.$rootScope.mqtt_status = "Connecting";
        this.$rootScope.mqtt_status_color = "blue";
        this.$rootScope.mqtt_status_extra = "Subscribing to " + uri;
        this.$rootScope.mqtt_status_extra_2 = '';
      }, 1)
      if (_.isEmpty(this.topics_to_subscribe)) {
        if (this.log_enabled) console.log('MQTT Not subscribing to any topic');
        return;
      }
      let topics: string[] = []
      _.forEach(this.topics_to_subscribe, function (topic) {
        topics.push(topic + '/#');
      });
      if (this.log_enabled && WG_debug) console.log('MQTT Subscribing to topics', topics);

      if (this.subscribe_timer && uri) {
        // Use the subscription with the Passed URI parameter, it has more info
        clearTimeout(this.subscribe_timer);
        this.subscribe_timer = null;
      }

      this.mqtt_client.subscribe(topics, {
        qos: 1,
        timeout: 20,
        onSuccess: (ret) => {
          if (this.log_enabled) {
            if (WG_debug) {
              console.log("MQTT Subscribe Success:", ret);
            } else {
              console.log("MQTT Connected successfully");
            }
          }
          if (this.connect_timer) {
            clearTimeout(this.connect_timer);
            this.connect_timer = null;
          }
          this.$timeout(() => {
            this.$rootScope.mqtt_status = "Ok";
            this.$rootScope.mqtt_status_color = this.$rootScope.WG_is_mar2protect ? "lawngreen" : "green";
            this.$rootScope.mqtt_status_extra = "Connected to " + uri;
            this.$rootScope.mqtt_status_extra_2 = '';
          }, 1)
        },
        onFailure: (result) => {
          if (this.log_enabled) console.log("MQTT Subscribe Failure:", result)
          if (!uri)
            uri = this.mqtt_client?.uri || this.URIs[this.current_uri_i];
          this.$timeout(() => {
            this.$rootScope.mqtt_status = "Fail";
            this.$rootScope.mqtt_status_color = "red";
            this.$rootScope.mqtt_status_extra = "Failed to subscribe to " + uri + ", " + _.toString(result.errorMessage);
            this.$rootScope.mqtt_status_extra_2 = '';
          }, 1)
          this.reconnect();
        },
      });
      return;
    }

    private onSuccess: Paho.MQTT.OnSuccessCallback = (response) => {
      if (this.log_enabled && WG_debug) console.log('MQTT onSuccess. ', response);
    }

    private onConnected: Paho.MQTT.OnConnectionSuccess = (from_a_reconnect, uri = null) => {
      if (this.log_enabled && WG_debug) console.log('MQTT onConnected. ', from_a_reconnect, uri, this.mqtt_client.isConnected());

      if (this.connect_timer) {
        clearTimeout(this.connect_timer);
        this.connect_timer = null;
      }

      // Connection succeeded; subscribe to our topic
      if (this.subscribe_timer && uri) {
        // Use the subscription with the Passed URI parameter, it has more info
        clearTimeout(this.subscribe_timer);
        this.subscribe_timer = null;
      }
      if (!this.subscribe_timer) {
        this.subscribe_timer = setTimeout(() => {
          this.subscribe(uri);
        }, 1000);
      }
    }


    private onConnectFailure: Paho.MQTT.OnFailureCallback = (response) => {
      let _this: MQTTService = response.invocationContext;
      _this.connecting = false;
      if (!_this.AuthService.isLogged) {
        console.error('MQTT User logged out');
        return;
      } else {
        if (_this.log_enabled) console.log('MQTT onConnectFailure: ', response.errorMessage);
      }
      this.$timeout(() => {
        _this.$rootScope.mqtt_status = "Fail";
        this.$rootScope.mqtt_status_color = "red";
        _this.$rootScope.mqtt_status_extra = "Failed to connect to " + _this.URIs[_this.current_uri_i] + ", " + _.toString(response.errorMessage);
        _this.$rootScope.mqtt_status_extra_2 = '';
      }, 1);
      setTimeout(() => {
        _this.reconnect()
      }, _this.reconnectStepTimeout);
    }

    private onConnectionLost: Paho.MQTT.OnConnectionLostHandler = (response) => {
      if (this.log_enabled) {
        console.warn('MQTT onConnectionLost: ', response.errorMessage);
      }
      this.$timeout(() => {
        this.$rootScope.mqtt_status = "Fail";
        this.$rootScope.mqtt_status_color = "red";
        this.$rootScope.mqtt_status_extra = "Connection lost to " + this.URIs[this.current_uri_i] + ", " + _.toString(response.errorMessage);
        this.$rootScope.mqtt_status_extra_2 = '';
      }, 1)
      this.connecting = false;
      setTimeout(() => {
        this.reconnect()
      }, this.reconnectStepTimeout);
    };

    private reconnect() {
      if (this.reconnectCount >= this.reconnectMaxCount) {
        console.warn('MQTT Max reconnect retries');
        return;
      }
      this.reconnectCount++;
      this.reconnectTimeout = Math.min(this.reconnectBaseTimeout + this.reconnectStepTimeout * this.reconnectCount, this.reconnectMaxTimeout);

      if (this.log_enabled) console.info('MQTT Re-Connecting in ', this.reconnectTimeout);
      if (this.reconnectCount % 10 == 0) {
        this.disconnect();
      }
      this.connect_timer = setTimeout(() => {
        this.connect()
      }, this.reconnectTimeout);
    }

    public connect(reconnect_if_connected: boolean = false) {
      if (this.connect_timer) {
        clearTimeout(this.connect_timer);
        this.connect_timer = null;
      }

      if (!this.enabled) {
        this.$timeout(() => {
          this.$rootScope.mqtt_status = "Disabled";
          this.$rootScope.mqtt_status_color = "grey";
          this.$rootScope.mqtt_status_extra = '';
          this.$rootScope.mqtt_status_extra_2 = '';
        }, 1)
        return;
      }

      if (!(this.AuthService.user?.username)) {
        if (WG_debug) console.error('MQTT Unable to connect. User logged out?', this.AuthService.user);
        return
      }

      if (this.log_enabled) console.log('MQTT Enabled:', this.enabled);

      if (this.connecting) {
        if (this.log_enabled) console.log('MQTT Already trying to connect.');
        return;
      }
      if (!this.$rootScope.apiKey) {
        console.error('MQTT Unable to connect (missing api_key).');
        return
      }

      this.current_uri_i = (this.current_uri_i + 1) % this.URIs.length;

      this.$timeout(() => {
        this.$rootScope.mqtt_status = "Connecting";
        this.$rootScope.mqtt_status_color = "blue";
        this.$rootScope.mqtt_status_extra = "Connect to " + this.URIs[this.current_uri_i];
        this.$rootScope.mqtt_status_extra_2 = '';
      }, 1)

      if (!this.clientId)
        this.clientId = ('web_' + Math.round(Math.random() * 0xFFFFFF).toString(16) + '_' + this.AuthService.user.username).substring(0, 23);

      let username = this.$rootScope.apiKey;
      let password = this.$rootScope.apiKey;

      let connect_options: Paho.MQTT.ConnectionOptions = {
        timeout: 20,
        keepAliveInterval: 70 * 60, // The server disconnects this client if there is no activity for this number of seconds
        useSSL: this.useTLS,
        cleanSession: this.cleanSession,
        userName: username,
        password: password,
        // willMessage: null,
        mqttVersion: 4,
        // mqttVersionExplicit: true,
        onSuccess: this.onSuccess,
        onFailure: this.onConnectFailure,
        // uris: this.URIs,
        reconnect: false, // Internally tries to reconnect, gradually increasing sleep up to 2min
        invocationContext: this,
      };


      if (this.mqtt_client && this.mqtt_client.isConnected()) {
        if (WG_debug) console.log('MQTT client already connected. Reusing');
        if (reconnect_if_connected) {
          if (this.log_enabled) console.log("MQTT Already connected! Disconnecting and Connecting");
          try {
            this.mqtt_client.disconnect();
          } catch (e) {
          }
        } else {
          if (this.log_enabled) console.log("MQTT Already connected! Resubscribing");
          this.subscribe();
        }
      } else {
        this.connecting = true;

        // @ts-ignore
        let MQTTClient: typeof Paho.MQTT.Client = Paho.MQTT?.Client || Paho.Client;
        this.mqtt_client = new MQTTClient(this.URIs[this.current_uri_i], this.clientId);

        this.mqtt_client.onConnectionLost = this.onConnectionLost;
        this.mqtt_client.onConnected = this.onConnected;
        this.mqtt_client.onMessageArrived = this.onMessageArrived;

        if (this.log_enabled) console.log('MQTT Connecting to Winegrid MQTT server: ', this.URIs[this.current_uri_i], ', TLS:', this.useTLS, ', clientId:', this.clientId, ', cleanSession:', this.cleanSession);

        this.mqtt_client.connect(connect_options);
      }
    }
  }

  App.service('MQTTService', MQTTService);
}