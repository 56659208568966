/**=========================================================
 * Module: access-register.js
 * Demo for register account api
 =========================================================*/
namespace wg {
  App.controller('RecoverConfirmFormController', ['$scope', '$rootScope', '$http', '$state', '$timeout', 'AuthService',
    function ($scope, $rootScope: IRootScope, $http: ng.IHttpService, $state: _IState, $timeout: ng.ITimeoutService, AuthService: IAuthService) {
      if (AuthService.isLogged) {
        $state.go(AuthService.homeState);
        return;
      }

      // bind here all data from the form
      $scope.account = {};

      $scope.retStatus = {
        loading: false,
        result: null,
        message: '',
      } as IReturnResult;

      var dataError = null;
      $scope.reset_confirm = function () {
        if ($scope.retStatus.loading)
          return;
        $scope.retStatus.message = '';
        $scope.retStatus.loading = true;
        $scope.retStatus.result = null;

        let timeoutRequest = $timeout(function () {
          $scope.retStatus.loading = false;
          $scope.retStatus.message = 'app.error.TIMEOUT';
          $scope.retStatus.result = "error";
        }, 10000);

        AuthService.recover_confirmation($state.params["uid"], $state.params["token"], $scope.account.new_password1, $scope.account.new_password2).then(
            function (data) {
              $scope.retStatus.message = data.detail;
              $rootScope.parsley_reset('resetInput', dataError);
              if (AuthService.response.status === 200) {
                $scope.retStatus.result = "success";
                $state.go(AuthService.loginState, {
                  detail: data.detail,
                  status: AuthService.response.status
                }, {location: false, inherit: false});
              } else {
                $scope.retStatus.result = "error";
              }
            }, function (data) {
              $rootScope.parsley_reset('resetInput', dataError);
              dataError = data;
              var used_errors = $rootScope.parsley_set('resetInput', dataError);
              if (data.non_field_errors) {
                $scope.retStatus.message = data.non_field_errors[0];
                // if token: ['Invalid value'] returned, show a message
              } else if (data.token && data.token[0].indexOf('nvalid') !== -1) {
                $scope.retStatus.message = 'pages.recover_confirm.INVALIDTOKEN';
              } else {
                $scope.retStatus.message = 'pages.recover_confirm.SERVERREQUEST';
              }
              $scope.retStatus.result = "error";
            })
            .finally(function () {
              $scope.retStatus.loading = false;
              $timeout.cancel(timeoutRequest);
            });


      };
    }]);
}