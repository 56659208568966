/**
 * Created by Liliana Carreto on 06-04-2020.
 */
namespace wg {

  interface IScope {
    selectedPlace: IPlace;
    updateURL_timer: NodeJS.Timeout;
    filter: {
      conditions?: IDashboardConfigs['overview_filter'],
      filtersChanged: () => void,
      filterByProperties: (unit: IUnit) => IUnit,
    };

    graphCtrl: GraphController;
    addToGraph: GraphController['addToGraph'];

    [key: string]: any;
  }

  interface ITableColumns {
    field: string,
    type?: string,
    title: string,
    sortable: string,
    manual?: boolean,
    show: boolean,
  }


  App.controller("DevicesUnitsCardController", ['ngTableParams', 'Utils', 'DataUtils', 'WGApiData', 'APIUtils', '$window', '$rootScope', '$scope', '$filter', '$timeout', '$state', '$stateParams', '$translate', 'ngDialog', 'AuthService', 'numberFilter', '$http',
    function (ngTableParams: NgTable.ITableParamsConstructor<IUnit>, Utils, DataUtils: DataUtils, WGApiData: WGApiData, APIUtils: APIUtils, $window: ng.IWindowService, $rootScope: IRootScope, $scope: IScope, $filter: ng.IFilterService, $timeout: ng.ITimeoutService, $state: _IState, $stateParams, $translate: ng.translate.ITranslateService, ngDialog: angular.dialog.IDialogService, AuthService: IAuthService, numberFilter, $http: ng.IHttpService) {

      $scope.updateURL_timer = undefined;
      $scope.posX = 0;
      $scope.posY = 0;


      // animate scroll tabs to the rigth to show Tour step
      $scope.moveTabsRight = function () {

        var divscroll = angular.element("#scrollable")
        // var divWidth = angular.element(document.querySelectorAll(".nav-tabs")[0]).innerWidth();
        divscroll.animate({scrollLeft: 50000}, 500);
      };

      // animate scroll tabs to the left to show prev Tour step
      $scope.moveTabsLeft = function () {
        var divscroll = angular.element("#scrollable")
        divscroll.animate({scrollLeft: 0}, 500);
      };

      // open Collapsable Menu to show  Tour step
      $scope.openFilterMenu = function () {
        $scope.filterMenu.isOpen = true
      }


      $scope.origin = "dashboard";
      // $scope.myUnitPopover = {isOpen: false};
      // $scope.myNotificationPopover = {isOpen: false};
      // $scope.myDevicePopover = {isOpen: false};
      // $scope.myProcessPopover = {isOpen: false};


      // --------------------- Table stuff --------------------------//
      //datatable
      $scope.tableParams = new ngTableParams({ // parameters
        page: 1,            // show first page
        count: 10,          // count per page
        filter: {},
        sorting: {
          priority: 'desc'   // initial sorting
        },
        // group: {'id': 'asc'}
      }, { // settings
        // $loading: false,  // True while reloading
        // dataset: null, // Set this to provide a local dataset, and getData() will not be called at every filter/page update
        // debugMode: true,  // Activates logging of ngTable
        total: 0, // value less than count hide pagination.
        // defaultSort: 'desc',
        // filterOptions: {
        //             filterComparator: undefined,
        //             filterDelay: 500,
        //             filterDelayThreshold: 10000,
        //             filterFilterName: undefined,
        //             filterFn: undefined,
        //             filterLayout: 'stack', // alternative: 'horizontal'
        //         },
        // groupOptions: {
        //             defaultSort: 'asc',
        //             isExpanded: true,
        //         },
        // counts: [10, 25, 50, 100],  // [] hide page counts control
        // interceptors: [],
        paginationMaxBlocks: 7, // 7 = first, 3 centered, last
        paginationMinBlocks: 3,
        // sortingIndicator: 'span',
        // getGroups(),  // data = getGroups() when reloading and groups are defined
        // getDeviceSensorData(),  // data = getDeviceSensorData() when reloading and no groups are defined

        getData: (params) => {
          if (!$scope.selectedPlace || !$scope.selectedPlace.units) {
            return [];
          }
          let data = $scope.selectedPlace.units;
          let params_sorting = params.sorting();
          // let _params_sorting = params.sorting();
          // if (_params_sorting && !_params_sorting['-priority'] && !_params_sorting['priority'] && !_params_sorting['+priority']) {
          //   // console.log('Sorting changed! ', _params_sorting);
          //   $scope.sort.selectedProperty = "other";
          // }
          let orderByNoNulls = function (): string[] {
            let sorting = [];
            // let params_sorting = params.sorting();
            for (var column in params_sorting) {
              if (params_sorting.hasOwnProperty(column)) {
                // https://jsfiddle.net/an45t97b/
                // sorting.push("!" + column);
                sorting.push(column + '==undefined');
                sorting.push(column + '==null');
                sorting.push(column + '=="-"');
                sorting.push((params_sorting[column] === "asc" ? "+" : "-") + column);
              }
            }
            return sorting;
          };

          // Original functions
          // var fData = params.hasFilter() ? $filter('filter')(data, $scope.filter.filterByProperties) : data;
          // var orderBy = params.orderBy();

          // Our new functions
          let fData = $filter('filter')(data, {is_visible: true}); // $scope.filter.filterByProperties);
          let orderBy = orderByNoNulls();

          let _sort_param = _.last(orderBy);
          //if (WG_debug) console.log('Sorting: ', _sort_param, AuthService.dashboardConfigs.overview_sort);
          if (!_.isEqual(_sort_param, AuthService.dashboardConfigs.overview_sort)) {
            $scope.sort.sortByChanged(_sort_param, false);
          }

          // if (WG_debug) console.log('Getting table data', params.url(), params, data, fData);
          let _count = isFinite(params.count()) ? params.count() : 100000;
          let orderedData = orderBy.length ? $filter('orderBy')(fData, orderBy) : fData;

          let _page = (isFinite(params.page()) && params.page() > 0) ? params.page() : 1;
          let _max_page = Math.ceil(orderedData.length / _count);
          if (_page > _max_page) {
            _page = _max_page;
            params.page(_page);
          }
          let pagedData = orderedData.slice((_page - 1) * _count, _page * _count);
          params.total(orderedData.length);// set total for recalc pagination
          // if (WG_debug) console.log('ngTableDefaultGetData fData', orderBy, params.filter(true), fData);

          $timeout(function () {
            $scope.units_selection.updateSelectedList()
          }, 1);
          return pagedData;
        }
      });


      /**
       * Re-builds ngTable structure: columns, paging, etc
       */
      function refresh_table() {
        if ($scope.dashboard_viewMode !== 'table') {
          return;
        }

        if (!WGApiData.WGPlaces.ready ||
            !WGApiData.WGUnits.ready ||
            !WGApiData.WGDevices.ready ||
            !WGApiData.WGSensors.ready) {
          return;
        }

        if (!$scope.selectedPlace
            // || _.isEmpty($scope.selectedPlace.last_values)
            // || _.isEmpty($scope.selectedPlace.units)
        ) {
          // Data not yet available
          console.log('Refresh table postponned');
          return;
        }

        let _counts = []; // [10, 25, 50, 100]
        if ($scope.selectedPlace.units.length > 10) {
          _counts.push(10);
          if ($scope.selectedPlace.units.length > 25) {
            _counts.push(25);
            if ($scope.selectedPlace.units.length > 50) {
              _counts.push(50);
              if ($scope.selectedPlace.units.length > 100) {
                _counts.push(100);
              }
            }
          }
          _counts.push($translate.instant('app.common.ALL'));
        }

        $scope.tableParams.settings({
          data: $scope.selectedPlace.units,
          counts: _counts,
        });

        let new_cols: ITableColumns[] = [];

        new_cols.push({
          field: 'non_sensor_CHECKBOXES',
          title: null,
          sortable: null,
          show: true
        });
        new_cols.push({
          field: 'non_sensor_NAME',
          title: $translate.instant('app.manage.edit.NAME'),
          sortable: 'name',
          show: true
        });
        // new_cols.push({
        //   field: 'non_sensor_TYPE',
        //   title: $translate.instant('app.manage.units.UNIT_TYPE'),
        //   title_sref: 'app.manage.units.UNIT_TYPE',
        //   sortable: 'unit_type',
        //   show: true
        // });
        new_cols.push({
          field: 'non_sensor_DEVICE',
          title: $translate.instant('app.manage.devices.SENSOR'),
          sortable: 'devices[0].status',
          show: true
        });
        new_cols.push({
          field: 'non_sensor_PROCESS',
          title: $translate.instant('app.processes.PROCESS'),
          sortable: 'process',
          show: true
        });

        new_cols.push({
          field: 'non_sensor_LAST_READ',
          title: $translate.instant('app.overview.device.LAST_READ'),
          sortable: 'devices[0].last_read',
          show: true
        });

        new_cols.push({
          field: 'non_sensor_NEXT_READ',
          title: $translate.instant('app.overview.device.NEXT_READ'),
          sortable: 'devices[0].next_read',
          show: true,
        });

        new_cols.push({
          field: 'non_sensor_INTERVAL',
          title: 'Interval',
          sortable: 'devices[0].sample_interval',
          show: true
        });

        if (AuthService.canAccess('dev')) {
          new_cols.push({
            field: 'non_sensor_LAST_COMM',
            title: $translate.instant('app.overview.device.LAST_COMM'),
            sortable: 'devices[0].last_comm',
            show: true
          });
          new_cols.push({
            field: 'non_sensor_LAST_COMMAND',
            title: 'Last command',
            sortable: 'devices[0].last_command',
            show: true
          });
        }

        if (AuthService.canAccess('admin')) {
          new_cols.push({
            field: 'non_sensor_VERSION',
            title: 'Version',
            sortable: 'devices[0].version',
            show: true
          });
          new_cols.push({
            field: 'non_sensor_COMM_MODE',
            title: 'Mode',
            sortable: 'devices[0].comm_status.last_mode',
            show: true
          });
        }

        _.forEach($scope.selectedPlace.last_values, function (last_value, _internal_name) {
          if (!last_value || !DataUtils.canUserAccessSensor(_internal_name))
            return;

          if (_.find(new_cols, {field: _internal_name}))
            return;

          new_cols.push({
            type: 'SENSORS_LIST',
            field: _internal_name,
            // icon: WGApiData.WGSensors.sensors_name[_internal_name].configs?.icon,
            title: WGApiData.WGSensors.sensors_name[_internal_name].name,
            sortable: "last_values['" + _internal_name + "'].val_numeric",
            manual: last_value.sensor?.configs?.manual,
            show: true
          });
        });

        // new_cols.push({
        //   field: 'non_sensor_FAVORITES',
        //   title: $translate.instant('app.overview.units.FAVORITE'),
        //   // title_sref: 'app.overview.units.FAVORITE',
        //   sortable: null,
        //   show: true
        // });


        if (AuthService.canAccess('admin')) {
          if ($rootScope.WG_debug) {
            new_cols.push({
              field: 'non_sensor_PRIORITY',
              title: 'Dev: priority',
              sortable: 'priority',
              show: true
            });
          }

        }

        $scope.cols = $scope.cols || [];

        // Replacing current list with an inline-merge, as to avoid refresh on DOMs
        synchronizeArrayOfObjects($scope.cols, new_cols, 'field');

        // console.log('Refreshed table: ', $scope.cols);
      }


      // Define last sensor-values list for showing in table for quick-access
      function update_places_sensor_list(places = WGApiData.WGPlaces.places) {
        if ($scope.dashboard_viewMode !== 'table') {
          return;
        }
        if (!WGApiData.WGPlaces.ready ||
            !WGApiData.WGUnits.ready ||
            !WGApiData.WGDevices.ready ||
            !WGApiData.WGSensors.ready) {
          return;
        }

        _.forEach(places, function (place) {
          if (!place) {
            return;
          }
          // if (!place.last_values) {
          //   place.last_values = {};
          // }
          place.last_values = emptyOrCreateDict(place.last_values);
          if (_.isEmpty(place.units)) {
            return;
          }

          let tableview_sensors = _.cloneDeep($rootScope.tableview_sensors_list);
          if (tableview_sensors.includes('MANUAL_Entries')) {
            _.remove(tableview_sensors, 'MANUAL_Entries');
            _.forEach(WGApiData.WGSensors.manual_sensors, function (_sensor) {
              tableview_sensors.push(_sensor.internal_name);
            });
            tableview_sensors = _.uniq(tableview_sensors);
          }

          _.forEach(tableview_sensors, function (_sensor_internal_name) {
            if (_sensor_internal_name === 'MANUAL_Entries') {
              return;
            }

            if (!DataUtils.canUserAccessSensor(_sensor_internal_name))
              return;

            let _found = false;
            _.forEach(place.units, function (_unit) {
              if (_found) {
                return false;
              }
              let unit = WGApiData.WGUnits.units_id[_unit.id];
              if (!unit || unit.is_visible === false) {
                return;
              }
              _.forEach(unit.devices, function (_device) {
                if (_found) {
                  return false;
                }
                let device = WGApiData.WGDevices.devices_id[_device.id];
                if (device.last_values && device.last_values[_sensor_internal_name]) {
                  place.last_values[_sensor_internal_name] = device.last_values[_sensor_internal_name];
                  _found = true;
                  return false;
                }
              });
            });
          });
        });

        refresh_table();
      }


      $rootScope.$on('places_updated', (event, args) => {
        if (WG_debug) console.log('places_updated');
        // if ($scope.selectedPlace && !WGApiData.WGPlaces.places_id[$scope.selectedPlace.id]) {
        //   console.log('Selected place gone away. Choosing another one.');
        select_best_place();
        // }
        update_places_counts(WGApiData.WGPlaces.places);
        $scope.filter.filtersChanged();
      })
      $rootScope.$on('units_updated', (event, args) => {
        if (WG_debug) console.log('units_updated');
        select_best_place();
        update_places_counts(WGApiData.WGPlaces.places);
        $scope.filter.filtersChanged();
      })
      $rootScope.$on('devices_updated', (event, args) => {
        console.log('Devices updated. Refreshing');
        select_best_place();
        update_places_counts(WGApiData.WGPlaces.places);
        $scope.filter.filtersChanged();
      })
      // let update_places_sensor_list_timer: angular.IPromise<any> = null;
      $rootScope.$on('new_parameter_received', (event, device_id, stream) => {
        update_places_sensor_list([$scope.selectedPlace]);
        // if (_.find($scope.selectedPlace.units, function (_unit: IUnit) {
        //   return WGApiData.WGUnits.units_id[_unit.id]?.devices?.[0]?.id === device_id;
        // })) {
        //   if (WG_debug) console.log('new_parameter_received on this place', device_id, stream);
        //   $timeout.cancel(update_places_sensor_list_timer);
        //   update_places_sensor_list_timer = $timeout(() => {
        //     update_places_sensor_list([$scope.selectedPlace]);
        //   }, 500, false);
        // }
      })
      $rootScope.$on('processes_updated', (event, args) => {
        if (WG_debug) console.log('processes_updated');
        update_places_counts(WGApiData.WGPlaces.places);
        $scope.filter.filtersChanged();
      })


      // $rootScope.$watchGroup(['WGPlaces.loading', 'WGUnits.loading', 'WGDevices.loading', 'WGSensors.loading', 'WGProcesses.loading'], function () {
      //   // if (!WGApiData.WGPlaces.ready) {
      //   //   // return; // Run anyway. Individual functions define their requirements
      //   // } else {
      //   //   if ($scope.selectedPlace && !WGApiData.WGPlaces.places_id[$scope.selectedPlace.id]) {
      //   //     // $timeout(function () {
      //   //     console.log('Selected place gone away. Choosing another one.');
      //   //     select_best_place();
      //   //     // }, 10);
      //   //   }
      //   // }
      //
      //   if (!WGApiData.WGPlaces.ready ||
      //       !WGApiData.WGUnits.ready ||
      //       !WGApiData.WGDevices.ready ||
      //       !WGApiData.WGSensors.ready ||
      //       !WGApiData.WGProcesses.ready) {
      //     // return; // Run anyway. Indidivual functions define their requirements
      //   } else {
      //     console.log('data_updated');
      //   }
      //
      //   // update_places_counts(WGApiData.WGPlaces.places);
      //
      //   // This possibly changed visible units. Refresh filters and list of visible units
      //   // $scope.filter.filtersChanged();
      // });


      $scope.dashboard_viewMode = $scope.dashboard_viewMode || 'grid';
// switch grid table or task view
      $scope.selectView = function (viewType) {
        if ($scope.dashboard_viewMode === viewType) {
          // if (WG_debug) console.error("Changing viewMode to the current one...");
          return;
        }
        // TODO: Directly use rootScoped vars in jade, instead of scoped
        $scope.dashboard_viewMode = viewType;
        update_places_sensor_list([$scope.selectedPlace]);

        AuthService.update_url({'view': viewType});
        AuthService.setDashboardConfigs({'overview_viewType': viewType});


        if (viewType == 'grid' || viewType == "task") {
          // Reset this as angular-reset will take a very long time
          $scope.filtered_units = $scope.selectedPlace?.units;
          $scope.units_selection.updateSelectedList();
        }
      };

      // collapsable header
      $scope.filterMenu = {
        isOpen: true,
      };


// ---------------------Select all/ checkboxes --------------------------//

      $scope.task_selection = {


        all_tasks_are_selected: false,


        updateSelectedList: function () {
          // if (WG_debug) console.log("tasks_selection ng-change");
          $scope.task_selection.selected_tasks = emptyOrCreateArray($scope.task_selection.selected_tasks);
          let _tmp_all_selected = true;
          let _at_least_one_visible = false;
          // Add all visible+selected tasks to the arrays
          if ($scope.dashboard_viewMode == 'task') {
            $scope.filtered_tasks = $scope.tableParams.data;
          }
          if ($scope.selectedPlace) {
            _.forEach($scope.filtered_tasks, function (task) {
              if (task.is_visible) {
                _at_least_one_visible = true;
                if (task.selected) {
                  $scope.task_selection.selected_tasks.push(task);
                } else {
                  _tmp_all_selected = false;
                }
              }
            });
          }
          // Are all of them selected?
          $scope.task_selection.all_tasks_are_selected = _at_least_one_visible && _tmp_all_selected;
        },


        selectAll: function () {
          console.log("Setting all tasks to " + ($scope.task_selection.all_tasks_are_selected ? "Selected" : "Unselected"));
          if ($scope.dashboard_viewMode == 'task') {
            $rootScope.$broadcast('selectAllTasks', this.all_tasks_are_selected);

            return
          }
          if (!_.isEmpty($scope.filtered_tasks)) {
            for (let task of $scope.filtered_tasks) {
              task.selected = !!$scope.task_selection.all_tasks_are_selected;
            }
          }
          $scope.task_selection.updateSelectedList();
        },

      };

      $scope.units_selection = {
        selected_units: [],
        all_units_are_selected: false,
        last_selected: null,
        last_single_selected: null,

        canForceRead: false,
        canSetAlarm: false,
        canSetReadInterval: false,
        canShowGraphs: false,
        canExportData: false,

        canConfigReadDensity: false,
        // canToggleFavorite: false,
        canCreateProcess: false,
        canStopProcess: false,

        updateSelectedList: function () {
          // if (WG_debug) console.log("units_selection ng-change");
          $scope.units_selection.selected_units = emptyOrCreateArray($scope.units_selection.selected_units);
          let _tmp_all_selected = true;
          let _at_least_one_visible = false;
          // Add all visible+selected units to the arrays

          if ($scope.dashboard_viewMode == 'table') {
            $scope.filtered_units = $scope.tableParams.data;
          } else {
            $scope.filtered_units = $scope.selectedPlace?.units;
          }

          if ($scope.selectedPlace) {
            _.forEach($scope.filtered_units, function (unit) {
                  if (unit.is_visible) {
                    _at_least_one_visible = true;
                    if (unit.selected) {
                      $scope.units_selection.selected_units.push(unit);
                    } else {
                      _tmp_all_selected = false;
                    }
                  }
                }
            );
          }

          // Are all of them selected?
          $scope.units_selection.all_units_are_selected = _at_least_one_visible && _tmp_all_selected;

          // Can any of them Force_Read a sensor?
          $scope.units_selection.canForceRead = $scope.canAnyForceRead($scope.units_selection.selected_units);

          // Can any of them Stop making sensor Readings?
          $scope.units_selection.canStopReadDensity = $scope.canAnyConfigReadDensity($scope.units_selection.selected_units, false);

          // Can any of them Stop making sensor Readings?
          $scope.units_selection.canStartReadDensity = $scope.canAnyConfigReadDensity($scope.units_selection.selected_units, true);

          // Can any of them set Alarms?
          $scope.units_selection.canSetAlarm = $scope.canAnySetAlarm($scope.units_selection.selected_units);

          // Can any of them set Read Time Interval?
          $scope.units_selection.canSetReadInterval = $scope.canAnySetReadInterval($scope.units_selection.selected_units);

          // Can any of them Show Graphs?
          $scope.units_selection.canShowGraphs = $scope.canAnyShowGraphs($scope.units_selection.selected_units);

          // Can any of them Export Data?
          $scope.units_selection.canExportData = $scope.canAnyExportData($scope.units_selection.selected_units);

          // Can any of them Set Favorite?
          // $scope.units_selection.canToggleFavorite = $scope.canAnyToggleFavorite($scope.units_selection.selected_units);

          // Can any of them start a new process?
          $scope.units_selection.canCreateProcess = $scope.canAnyCreateProcess($scope.units_selection.selected_units);

          // Can any of them stop a running process?
          $scope.units_selection.canStopProcess = $scope.canAnyStopProcess($scope.units_selection.selected_units);

          // console.log("Refreshed list of selected units", $scope.units_selection.selected_units);
        },
        selectAll: function () {
          // console.log("Setting all units to " + ($scope.units_selection.all_units_are_selected ? "Selected" : "Unselected"));

          if ($scope.dashboard_viewMode == 'task') {
            // $scope.filtered_tasks = $scope.tableParams.data;
            alert('selectAll');
            return
          }

          if ($scope.dashboard_viewMode == 'table') {
            $scope.filtered_units = $scope.tableParams.data;
          } else {
            $scope.filtered_units = $scope.selectedPlace?.units;
          }

          if (!_.isEmpty($scope.filtered_units)) {
            for (let unit of $scope.filtered_units) {
              unit.selected = !!$scope.units_selection.all_units_are_selected;
            }
          }

          $scope.units_selection.updateSelectedList();
        },
        // Supporting Shift-select multiple units
        select: function (unit: IUnit, index: number, e: JQueryEventObject) {
          if (e?.shiftKey) {
            if ($scope.dashboard_viewMode == 'table') {
              $scope.filtered_units = $scope.tableParams.data;
            } else {
              $scope.filtered_units = $scope.selectedPlace?.units;
            }
            let _this_index = _.findIndex($scope.filtered_units, {id: unit.id});
            let _last_selected_index = _.findIndex($scope.filtered_units, {id: $scope.units_selection.last_selected?.id});
            let _last_single_index = _.findIndex($scope.filtered_units, {id: $scope.units_selection.last_single_selected?.id});
            if (_last_single_index == -1)
              _last_single_index = 0;

            // if (WG_debug) console.log("units_selection multi-selection, from", _last_single_index, "to", _this_index);
            for (let i = 0; i < _.size($scope.filtered_units); i++) {
              if (i >= Math.min(_last_single_index, _this_index) && i <= Math.max(_last_single_index, _this_index)) {
                $scope.filtered_units[i].selected = $scope.units_selection.last_single_selected ? $scope.units_selection.last_single_selected.selected : true;
                // if (WG_debug) console.log("units_selection Selecting ", i);
              } else if (_last_selected_index > -1 && i >= Math.min(_last_selected_index, _this_index) && i <= Math.max(_last_selected_index, _this_index)) {
                $scope.filtered_units[i].selected = false;
                // if (WG_debug) console.log("units_selection UnSelecting ", i);
              }
            }

          } else {
            $scope.units_selection.last_single_selected = unit;
          }
          $scope.units_selection.last_selected = unit;
          $scope.units_selection.updateSelectedList();
        },
      };


// --------------  New Stuff regarding Sensors and Alarms

      function addAlarmSens(dest_array = [], sensor_name) {
        if (!Array.isArray(dest_array)) {
          return;
        }
        if (!WGApiData.AllReady) {
          return;
        }

        // return;
        // console.log('addAlarmSens', sens, a, WGApiData.WGSensors.sensors_name[sens]);
        // if (DataUtils.canUserAccessSensor(sens)) {
        if (DataUtils.canUserAccessSensor(sensor_name)) {
          // console.log('addAlarmSens', sens, 'adding');
          dest_array.push(WGApiData.WGSensors.sensors_name[sensor_name]);
        }
      }


// --------------  Multi-actions

      $scope.doSetOffset = function (items = []) {
        // let _already_read_one = false;
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (!device.path) {
            return;
          }

          APIUtils.device_set_threshold(device, false, null, function (response) {
            console.log("Device offset reset Ok!", device, response);
          }, function (response) {
            console.error("Device offset reset Fail!", device, response);
          });
        });
      };

      $scope.doForceRead = function (items = [], just_ping = false) {
        // let _already_read_one = false;
        // console.log("doForceRead", $scope.units_selection.selected_units);
        let schedule = 0;
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (!device.path) {
            return;
          }
          setTimeout(function () {
            APIUtils.force_device_read(device, false, just_ping, function (response) {
              // console.log("Unit sent packet", device, response);
            }, function (response) {
              console.error("Error Force-Reading some device.", device, response);
            });
          }, schedule);
          if (device.comm_status?.last_lora_mode == "LoRa_WAN") {
            schedule += 500;
          } else {
            schedule += 4000;
          }
        });
      };

      /** Copy SNs of selected devices to the clipboard
       *
       * @param items list of entities to extract Devices from
       */
      $scope.doCopySNs = function (items = []) {
        let _sn_list = [];
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (!device.path) {
            return;
          }
          _sn_list.push(device.sn);
        });
        if (_.isEmpty(_sn_list)) {
          return;
        }
        let _sn_string = _sn_list.join('\n');
        navigator.clipboard.writeText(_sn_string).then(function () {
          console.log('Copying to clipboard was successful!', _sn_string);
        }, function (err) {
          console.warn('Could not copy text: ', err, _sn_string);
        });
      };

      /** Copy a Troubleshoot String useful for debugging
       *
       * @param items list of entities to extract Devices from
       */
      $scope.doCopyTroubleshootString = function (items = []) {
        let _sn_list = [];
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (!device.path) {
            return;
          }
          _sn_list.push(device.sn);
          _sn_list.push(device.iid);
          _sn_list.push(device.uuid);
          _sn_list.push(device.internal_name);
        });
        if (_.isEmpty(_sn_list)) {
          return;
        }
        let _sn_string = _sn_list.join('|');
        navigator.clipboard.writeText(_sn_string).then(function () {
          console.log('Copying to clipboard was successful!', _sn_string);
        }, function (err) {
          console.warn('Could not copy text: ', err, _sn_string);
        });
      };


      $scope.doOTAUpdate = function (items = [], enable = true) {
        let ota_config = {ota: (enable ? 1 : 0)};
        console.log("doOTAUpdate, configuring ota=", ota_config);
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (!device.path) {
            return;
          }
          APIUtils.save_device_configs(device, ota_config, (response) => {
            DataUtils.update_device_status_soon(device);
          }, null);
        });
        if (!enable) {
          return;
        }

        let mark_ota = function (device: IDevice) {
          if (!device.path || device.status_ota === "ONGOING") {
            return;
          }
          let ota_timeout = 5 * 60 * 1000;
          device.status_ota = "ONGOING";
          $timeout(function () {
            device.status_ota = null;
            DataUtils.update_device_status_soon(device);
          }, ota_timeout);
        }

        let schedule = 0;
        console.log("doOTAUpdate, send a READ_NOW with configs and without force_read");
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (!device.path || !DataUtils.can_device_force_read(device)) {
            return;
          }
          setTimeout(function () {
            APIUtils.force_device_read(device, true, false, (response) => {
              mark_ota(device);
            }, null);
          }, schedule);
          schedule += 2100;
        });

        // console.log("doOTAUpdate, send a Ping for v8.2+, Force-reads for others");
        // _.forEach(WGApiData.extractDevices(items), function (device) {
        //   if (!device.path || !DataUtils.can_device_force_read(device)) {
        //     return;
        //   }
        //   setTimeout(function () {
        //     APIUtils.force_device_read(device, false, vprog_gte(device.version, '8.1.7'), (response) => {
        //       mark_ota(device);
        //     }, null);
        //   }, schedule);
        //   schedule += 3000;
        // });

        // console.log("doOTAUpdate, Force-reads for All");
        // _.forEach(WGApiData.extractDevices(items), function (device) {
        //   if (!device.path || !DataUtils.can_device_force_read(device)) {
        //     return;
        //   }
        //   setTimeout(function () {
        //     APIUtils.force_device_read(device, false, false, (response) => {
        //       mark_ota(device);
        //     }, null);
        //   }, schedule);
        //   schedule += 3000;
        // });
      };

      $scope.doRefreshData = function (items = []) {
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (!device.id) return;
          WGApiData.WGDevices.update_singular(device.id);
        });
      };

      $scope.doSetAlarm = function (items = []) {

        let _devices = [];
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (!device.iid || !device.uuid || !device.path) {
            return;
          }
          _devices.push({
            type: 'device',
            name: device.name,
            iid: device.iid,
            id: device.id,
            sn: device.sn,
            uuid: device.uuid,
            path: device.path,
            configs: device.configs,
          });

        });
        if (_.isEmpty(_devices)) {
          console.error('No devices to set Alarm');
          return;
        }

        if (_devices.length > 1) {
          console.error('We only support setting Alarm to 1 device at a time');
          return;
        }

        var options = {
          template: 'app/views/modals/alarm.html',
          controller: 'AlarmModalInstance',
          class: "wg-dialog",
          data: {
            // devices: _devices,
            device: _devices[0], // {} Required: [.id, .iid, .uuid, .path, .name], optional: [.configs]
            // sensors: Object.values(_sensors), // [{}] Required: [.name, .stream, .unit]
          }
        };
        ngDialog.openConfirm(options).then(
            function (data) { // onSuccess
              console.log('Set Alarm, Ok', data);
              WGApiData.WGAlarms.changed = true;
            }, function (reason) { // onError
              console.log('Set Alarm closed', reason);
              WGApiData.WGAlarms.changed = true;
            }
        ).finally(() => {
          WGApiData.update_changed_data_soon();
        });
      };

      $scope.doSetReadInterval = function (items) {
        // uuid, iid, and path required
        let _devices = [];
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (!device.uuid || !device.iid || !device.path) {
            return;
          }
          _devices.push({uuid: device.uuid, iid: device.iid, path: device.path});
        });
        if (!_devices || !_devices.length) {
          console.error('No devices to set Time Interval');
          return;
        }

        var options = {
          template: 'app/views/modals/crontab.html',
          controller: 'CrontabModalInstance',
          data: {
            devices: _devices,
          }
        };

        if (WG_debug) console.log('Opening modal for read time interval', options);
        ngDialog.openConfirm(options).then(
            (data) => { // onSuccess
              console.log('Finished setting read interval', data);
              WGApiData.WGDevices.changed = true;
            }, (reason) => { // onError
              console.log('Failed setting read interval: Canceled');
            }
        ).finally(() => {
          WGApiData.update_changed_data_soon();
        });
      };

      $rootScope.manualEntry = $scope.manualEntry = function (items) {

        let _devices = [];
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (!device.uuid || !device.iid || !device.path) {
            return;
          }
          _devices.push({id: device.id, uuid: device.uuid, iid: device.iid, path: device.path, name: device.name, unit: {name: device.unit.name}});
        });
        if (!_devices || !_devices.length) {
          console.warn('No devices to add a Manual Entry');
          return;
        }


        if (WG_debug) console.info('Opening manual entry modal');
        ngDialog.openConfirm({
          template: 'app/views/modals/manual-entry.html',
          className: 'ngdialog-theme-default',
          controller: 'ManualEntryModalInstance',
          // @ts-ignore
          scope: $scope,
          data: {
            device: _devices[0],
            result: 'selectedUnitManualEntry',
            mode: 'openConfirm'
          }
        }).then(
            function (data) {
              // console.log('manual-entry', data);
              // $rootScope.lastKnownMessages[data.device.uuid] = $rootScope.lastKnownMessages[data.device.uuid] || {};
              // $rootScope.lastKnownMessages[data.device.uuid][data.sensor.stream] = $rootScope.lastKnownMessages[data.device.uuid][data.sensor.stream] || {};
              // $rootScope.lastKnownMessages[data.device.uuid][data.sensor.stream].payload = data.payload;
            }, function (reason) {
              // console.log('Modal promise rejected. Reason: ', reason);
            });


      };

      $scope.doShowGraphs = function (items) {
        // id required
        let _devices_ids = [];
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (device.id) {
            _devices_ids.push(device.id);
          }
        });
        if (_.isEmpty(_devices_ids)) {
          console.error('No devices passed');
          return;
        }

        $state.go('app.devices.compare', {'devices': _devices_ids});
      };

      $scope.doExportData = function (items) {
        // id required
        let _devices_ids = [];
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (device.id) {
            _devices_ids.push(device.id);
          }
        });
        if (!_devices_ids || !_devices_ids.length) {
          console.error('No devices passed');
          return;
        }

        $state.go('app.devices.export', {'devices': _devices_ids});
      };


      $scope.confirmModal = function (title, text, ok, cancel, on_accept_fn, on_accept_args) {
        let options = {
          template: 'app/views/modals/confirm.html',
          data: {
            title: title,
            text: text,
            ok: ok,
            cancel: cancel,
          }
        };
        ngDialog.openConfirm(options).then(
            function (data) {
              on_accept_fn.apply(undefined, on_accept_args);
            }, function (reason) {
            });
      }

      $scope.confirmConfigReadDensity = function (items, enable = true) {
        if (enable) {
          $scope.doConfigReadDensity(items, enable)
          return;
        }
        let options = {
          template: 'app/views/modals/confirm.html',
          data: {
            title: 'app.modal.stopreading.TITLE',
            text: 'app.modal.stopreading.MSG',
            ok: 'app.common.CONTINUE',
            cancel: 'app.common.CANCEL',
          }
        };
        ngDialog.openConfirm(options).then(
            function (data) {
              $scope.doConfigReadDensity(items, enable)
            }, function (reason) {
            });
      }

      $rootScope.doConfigReadDensity = function (items, enable = true) {
        // uuid, iid, and path required
        _.forEach(WGApiData.extractDevices(items), function (device) {
          if (DataUtils.can_device_config_density_read(device, enable)) {
            let _after_edit_callback = function (_device: IDevice) {
              DataUtils.update_device_status(_device);
              WGApiData.update_units_status(_device.unit);
              // if (!_.isEmpty($scope.units_selection.selected_units)) {
              //   $scope.units_selection.updateSelectedList();
              // }
            };
            APIUtils.configure_density_reading(device, enable, _after_edit_callback);
          }
        });

        $scope.filter.filtersChanged();
      };

      // $scope.doToggleFavorite = function (items) {
      //   let _units = WGApiData.extractUnits(items);
      //   if (!_units.length) {
      //     return;
      //   }
      //
      //   let are_all_favorites = true;
      //   _.forEach(_units, function (unit) {
      //     // Create local structure if missing
      //     if (!unit.config_fields) {
      //       unit.config_fields = {};
      //     }
      //     if (!unit.config_fields.is_user_favorite) {
      //       unit.config_fields.is_user_favorite = {};
      //     }
      //     if (!unit.config_fields.is_user_favorite[AuthService.user.uuid]) {
      //       are_all_favorites = false;
      //     }
      //   });
      //
      //   let _set_to = !are_all_favorites;
      //
      //   APIUtils.setUnitsAsUserFavorite(_units, _set_to);
      //
      //   $scope.filter.filtersChanged();
      // }

      /**
       * view notifications
       */
      $scope.viewUserNotificationsIsOpen = false;
      $scope.viewAlarms = function (alarmType, unit) {
        let options = {
          template: 'app/views/modals/cards_ai_notifications.html',
          data: {},
          className: 'ngdialog-theme-default custom-width-800',
          scope: $scope,
        };

        if (alarmType === "AI") {
          options.template = 'app/views/modals/cards_ai_notifications.html';
          options.data['status_notifications'] = unit.alarms.status_notifications;
          options.data['ai_notifications'] = unit.alarms.ai_notifications;
        } else if (alarmType === "User") {
          options.template = 'app/views/modals/cards_alarms_notifications.html';
          options.data['notifications'] = unit.alarms.user_notifications;
        }
        // console.log("View notifications", alarmType, unit, options);
        $scope.viewUserNotificationsIsOpen = true;
        // @ts-ignore
        ngDialog.openConfirm(options).then(
            function (data) {
              $scope.viewUserNotificationsIsOpen = false;
              // WGApiData.process_notifications_updates();
              refresh_table();
            }, function (reason) {
              $scope.viewUserNotificationsIsOpen = false;
              // WGApiData.process_notifications_updates();
              refresh_table();
            });
      }
      /**
       * Create or Edit a Place
       * @param action: 'create' or 'edit'
       * @param place: Place to edit
       */
      $rootScope.doEditPlace = function (action, place) {
        if (WG_debug) console.log("Edit Place", action, place);
        /**
         * Uses:
         * .mode
         * .result
         * .action
         *
         * .elem .id
         * .elem .name
         * .elem .description
         * .elem .dynamic_fields
         * .elem .process
         * .elem .units
         */
        let options = {
          template: 'app/views/modals/edit-dialog-places.html',
          data: {
            action: action,
            elem: place,
          }
        };
        ngDialog.openConfirm(options).then(
            function (response) { // onSuccess
              console.log('Finished editing place.', response);
            }, function (reason) {
              console.log('Edit exited. Reason: ', reason);
              WGApiData.process_data_soon();
              WGApiData.update_changed_data_soon();
            });
      }

      /**
       * Edit a device, associating it to the given unit
       * @param action : 'create' or 'edit'
       * @param device : device to edit
       * @param unit : unit to associate with
       */
      $rootScope.doEditDevice = function (action: string, device: IDevice, unit: IUnit) {
        let _device = _.first(WGApiData.extractDevices([device]));
        if (WG_debug) console.log("doEditDevice", action, _device);

        if (action === 'edit' && !_device) {
          console.log('Edit device, Fail! No device found', _device);
          return;
        } else {
          if (WG_debug) console.log("Edit device", action, _device);
        }

        let options = {
          template: 'app/views/modals/edit-device.html',
          controller: "EditDeviceModalInstance",
          data: {
            result: 'selectedUnitEditDevice',
            device: _device,
            addToGraph: $scope.addToGraph,
            // reload: true, // Causes multiple refreshes and bugs with data not reloaded
          }
        };
        ngDialog.openConfirm(options).then(
            function (data) { // onSuccess
              console.log('Edit device, Ok', data);
              if (device && device.id === data.item_data.id) {
                device.name = data.item_data.name;
              }
              WGApiData.WGDevices.merge_entry(data.item_data, true);
            }, function (reason) { // onError
              //console.log('Edit device, Fail!', reason);
              WGApiData.process_data_soon();
            }
        );
      }

      /**
       * Moves 1-N units to a place, opening a modal to select/confirm the target place. Creates all units if not yet created
       * @param units
       * @param target_place
       */

      $scope.doMoveUnits = function (units: IUnit, target_place) {
        let _items = [];
        _.forEach(WGApiData.extractUnits(units as any as IUnit[]), function (item) {
          if (!item.id) {
            return;
          }
          // _items.push({id: item.id});
          _items.push(item);
          if (_.isNil(target_place) && item.place?.id > 0) {
            target_place = item.place;
            // target_place = {
            //   id: item.place.id,
            //   name: item.place.name,
            // };
          }
        });
        if (!_items || !_items.length) {
          console.error('No valid units passed');
          return;
        }

        let options = {
          template: 'app/views/modals/edit-dialog-units-multiple.html',
          data: {
            units: _items,
            place: target_place, // pre-filled
          },
        };
        ngDialog.openConfirm(options).then(
            function (data) { // onSuccess
              console.log('Finished moving units', data);
              WGApiData.update_changed_data_soon();
            }, function (reason) { // onError
              console.warn('Canceled moving units!', reason);
              WGApiData.update_changed_data_soon();
            }
        );


      }

      /**
       * Create or Edit a Unit, associating it to the given place
       * @param action: 'create' or 'edit'
       * @param unit: unit to edit
       * @param place: associate it with given place
       */
      $rootScope.doEditUnit = function (action, unit, place) {
        if (action === 'move') {
          $scope.doMoveUnits(unit, place);
          return;
        }
        if (action === 'edit' && !unit) {
          console.error("Edit Unit without a selected unit?!?!");
          return;
        }
        if (action === 'edit' && unit.id < 0) {
          if (WG_debug) console.info("Creating from virtual Unit");
          action = 'create';
        }
        if (WG_debug) console.log("Edit Unit", action, unit);

        /**
         * Possible fields:
         * action           - 'create' | 'edit'
         * result           -
         * origin           - $scope.origin
         * mode             - OpenMode, optional
         * readonly         - $scope.inputreadonly and $scope.disabledinput
         *
         * elem             - Copied to $scope.unitData
         * elem.name        - Required to Edit and Delete, Show user what will be deleted
         * elem.id          - Required. Used to DELETE and set device's parent unit
         * elem.unit_type   - optional, pre-fills the field
         * elem.place       - Used to update $scope.devices
         *
         * elem.process.id  -
         * elem.process.name-
         *
         * elem.devices[].id  -
         * elem.devices[].name-
         *
         * elem.description -
         *
         * place            - copied to $scope.selected.place
         * place.id         - copied to $scope.unitData.place_id
         *
         */

            // Get a shallow copy of the unit
        let shallow_unit;
        // TODO: make the modal compatible with circular units
        if (unit) {
          shallow_unit = _.pick(unit, ['id', 'name', 'name_sref', 'description', 'unit_type', 'config_fields', 'dynamic_fields', 'owner.id', 'place.id', 'place.name', 'process.id', 'devices']) as IUnit;

          if (shallow_unit.place?.id < 0) {
            delete shallow_unit.place;
          }

          if (unit.devices?.[0]?.id) {
            shallow_unit.devices = [{
              id: unit.devices[0].id,
              name: unit.devices[0].name,
              model: unit.devices[0].model,
            }];
          }
        }

        let shallow_place;
        if (place?.id > 0) {
          shallow_place = {
            id: place.id,
            name: place.name,
          };
        }

        let options = {
          template: 'app/views/modals/edit-dialog-units.html',
          // controller: "manageUnitEditController",
          // disableAnimation: true,
          data: {
            origin: "dashboard",
            mode: 'openConfirm',
            action: action,
            // elem: shallow_unit,
            // place: shallow_place, // Set to pre-define a place different from elem.place
            elem: unit,
            place: place,
            addToGraph: $scope.addToGraph,
          }
        };
        ngDialog.openConfirm(options).then(
            function (response) { // onSuccess
              console.log('Finished editing unit. Updating', response);
              WGApiData.update_changed_data_soon();
            }, function (reason) { // onError
              console.log('Failed editing unit!', reason);
              WGApiData.update_changed_data_soon();
            }
        );
      }

      /**
       * Create or Edit a process, associating it to the given units
       * @param action: 'create' or 'edit'
       * @param process: process to edit
       * @param items: int, Unit, Unit[] to associate with the process
       */
      $rootScope.doEditProcess = function (action, process, items = null, type='process') {
        console.log('doEditProcess:', process);
        if (action === 'stop') {
          $scope.doStopProcesses(process);
          return;
        }
        let units = WGApiData.extractUnits(items || {type: "process", units: process?.units});
        $scope.origin = "dashboard"
        let options = {
          template: 'app/views/modals/edit-dialog-process.html',
          // className: 'ngdialog-theme-default',
          // controller: 'CreateProcessModalInstance',
          scope: $scope,
          data: {
            operation: action,
            origin: "dashboard",
            mode: 'openConfirm',
            protocol: type == 'protocol'? process : null,
            process: type == 'process'? process : null,
            hasSmtg: !_.isEmpty(units),
            hasWhat: !_.isEmpty(units) ? 'unit' : null,
            unit: units[0], // Only one unit allowed for now
            // batch: batch,
            // units: units,
          }
        }

        // @ts-ignore
        ngDialog.openConfirm(options).then(
            function (response) { // onSuccess
              console.log('Finished editing process.', response);
              WGApiData.update_changed_data_soon();
            },
            function (reason) { // onError
              console.log('Failed editing process!', reason);
              WGApiData.update_changed_data_soon();
            }
        );
      };

      $rootScope.doEditProtocol = function (action, process, items = null) {
        // For now
        return $rootScope.doEditProcess(action, process, items, 'protocol')
      }
      /*
          protocols: [id, id, ...]
       */
      $rootScope.askStopProtocol = function (protocols:IWinemakingProtocol[] = null) {



        let _options: angular.dialog.IDialogOpenConfirmOptions = {
          controller: ['$scope', function ($scope) {
            $scope.protocols = protocols;
            $scope.stopAll = _.isEmpty(protocols);
            $scope.time_to_end = new Date();

            $scope.dateTimeNow = () => { return new Date() }

          }],
          template: 'StopProtocolTemplate.html',
        };

        ngDialog.openConfirm(_options).then(
            (data) => { // onSuccess
              $rootScope.doStopProtocol(protocols,data.time_to_end);

              console.log('Stopping processes, OK', data);
              WGApiData.update_changed_data_soon();

            }, function (reason) { // onError
              console.log('Stopping processes, Failed', reason);
              WGApiData.update_changed_data_soon();
            }
        );


      }

      $rootScope.doStopProtocol = function (items = null, ended_at = new Date() ) {
        let _protocols = [];

        // Array of Protocol IDs passed
        if (_.isArray(items)) {
          //is it an array of ids or an array of objects?
          if (_.isNumber(items[0])) {
            _protocols = items;
          } else {
            _.forEach(items, function (item) {
              if (item.id) {
                _protocols.push(item.id);
              }
            });
          }
        }

        // Protocol ID passed
        if (_.isEmpty(_protocols) && _.isNumber(items)) {
          _protocols.push(items);
        }

        // something with .protocols passed
        if (_.isEmpty(_protocols) && !_.isEmpty(items?.protocols)) {
          _.forEach(items.protocols, function (protocol) {
            if (protocol.id) {
              _protocols.push(protocol.id);
            }
          });
        }

        // Maybe we passed a list of some entities
        if (_.isEmpty(_protocols)) {
          let _units = WGApiData.extractUnits(items);
          if (_.isEmpty(_units)) {
            _units = items.id ? [items.id] : [];
          }

          if (_.isEmpty(_units)) {
            console.warn('No units selected');
            return;
          }

          // Extract all protocols from _units:
          _units.forEach(unit => {
            if (unit.protocols) {
              _protocols = _protocols.concat(unit.protocols);
            }
            if (unit.protocol) {
              _protocols = _protocols.concat(unit.protocol);
            }
          });
        }

        _protocols = _.uniq(_protocols);

        if (_.isEmpty(_protocols)) {
          console.warn('No protocols to stop');
          return;
        }

        const promises = _protocols.map(protocolId => {
          if (!_.isNil(protocolId)) {
            console.log('Stopping protocols', _protocols);
            return $http.patch(`/api/dashboard/winemaking/protocol/${protocolId}/`, {
              archived: true
            })
          }
        })

        Promise.all(promises).then(() => {
          console.log('Stopping protocols, OK', _protocols);
          WGApiData.WGUnits.changed = true;
          WGApiData.update_changed_data();
          $rootScope.$emit('tasksChangedStatus', {});
        }).catch((reason) => {
          console.log('Stopping protocols, Failed', reason);
          WGApiData.WGUnits.changed = true;
          WGApiData.update_changed_data_soon();
        });
      }

      $rootScope.doStopProcesses = function (items = null) {
        let _processes = WGApiData.extractProcesses(items);

        if (!_processes || !_processes.length) {
          console.error('No processes to stop');
          return;
        }

        let _options: angular.dialog.IDialogOpenConfirmOptions = {
          template: 'app/views/modals/see_process_run.html',
          controller: 'SeeRunStopController',
          data: {
            isMany: false,
            id: _processes[0].id,
            name: _processes[0].name,
            process: _processes[0],
            start_time: new Date(_processes[0].started_at),
            isRunning: _processes[0].active,
            // run_length: run_length,
            // end_time: end_time,
          }
        };
        if (_processes.length > 1) {
          _options.template = 'app/views/modals/stop_many.html';
          _options.data = {
            processes: _processes
          }
        }

        ngDialog.openConfirm(_options).then(
            (data) => { // onSuccess
              $rootScope.doStopProtocol(items);

              console.log('Stopping processes, OK', data);
              WGApiData.update_changed_data_soon();

            }, function (reason) { // onError
              console.log('Stopping processes, Failed', reason);
              WGApiData.update_changed_data_soon();
            }
        );
      };


// --- Validate conditions on selected items for batch operations

      /**
       * Checks if the selected elements are associated with a device that can force a sensor-read.
       *
       * @param items - anything with ['type'] field - places|units|processes|devices|batches
       * @returns {boolean}
       */
      $scope.canAnyForceRead = function (items) {
        $scope.multiForceReadStatus = '\n' + $translate.instant('app.overview.multi.CANNOT_PERFORM');
        // console.log("canAnyForceRead? ", items)
        let one_valid_seen = false;
        // let one_LoRaP2P_seen = false;
        for (let _device of WGApiData.extractDevices(items)) {
          if (_device.capabilities?.force_read || DataUtils.can_device_force_read(_device)) {
            one_valid_seen = true;
            $scope.multiForceReadStatus = '';
            break;
          } else {
            continue;
          }
          // if (_device.comm_status.last_mode === "LoRa_P2P") {
          //   if (one_LoRaP2P_seen) {
          //     $scope.multiForceReadStatus = '\n' + $translate.instant("app.modal.forcereading.ONLY1P2P");
          //     return false;
          //   }
          //   one_LoRaP2P_seen = true;
          // }
        }
        return one_valid_seen;
      }

      /**
       * Checks if the selected elements are associated with a device that can stop it's density reading.
       *
       * @param items - anything with ['type'] field - places|units|processes|devices|batches
       * @param to_enable - If we are trying to Enable or Disable density reading
       * @returns {boolean}
       */
      $scope.canAnyConfigReadDensity = function (items, to_enable = false) {
        for (let _device of WGApiData.extractDevices(items)) {
          // Just check if it can change, don't care about the status
          if (DataUtils.can_device_change_density_read(_device)) {
            return true;
          }
        }
        return false;
      }

      /**
       * Checks if a device or unit, or any of a list, can set an Alarm: Has any device
       *
       * @param items one or array of devices/sensors
       * @returns {boolean}
       */
      $scope.canAnySetAlarm = function (items) {
        for (let _device of WGApiData.extractDevices(items)) {
          // Any active device can
          if (_device.management_active !== false) {
            return true;
          }
        }
        return false;
      }

      /**
       * Checks if a device or unit, or any of a list, can set the Read Interval: Has any device
       *
       * @param items one or array of devices/sensors
       * @returns {boolean}
       */
      $scope.canAnySetReadInterval = function (items) {
        for (let _device of WGApiData.extractDevices(items)) {
          // Any active device can
          if (_device.management_active !== false) {
            return true;
          }
        }
        return false;
      }

      /**
       * Checks if a device or unit, or any of a list, can Show Graphs: Devices have any LKM
       *
       * @param items one or array of devices/sensors
       * @returns {boolean}
       */
      $scope.canAnyShowGraphs = function (items) {
        // Any device can
        if (WGApiData.extractDevices(items).length) {
          return true;
        }
        return false;
      }

      /**
       * Checks if a device or unit, or any of a list, has data to export
       *
       * @param items one or array of devices/sensors
       * @returns {boolean}
       */
      $scope.canAnyExportData = function (items) {
        // Any device can
        if (WGApiData.extractDevices(items).length) {
          return true;
        }
        return false;
      }

      // $scope.canAnyToggleFavorite = function (items) {
      //   // Just check if we have some unit that is not an unPlaced device/process
      //   let _units = WGApiData.extractUnits(items);
      //   for (let i in _units) {
      //     if (_units[i].id > 0) {
      //       return true;
      //     }
      //   }
      //   return false;
      // }

      $scope.canAnyCreateProcess = function (items) {
        // Checks if some unit doesn't have an active process
        let _units = WGApiData.extractUnits(items);
        for (let i in _units) {
          if (!_units[i].process || !_units[i].process.active) {
            return true;
          }
        }
        return false;
      }

      $scope.canAnyStopProcess = function (items) {
        // Checks if any unit has an active process
        let _units = WGApiData.extractUnits(items);
        for (let i in _units) {
          if (_units[i].process && _units[i].process.active) {
            return true;
          }
        }
        return false;
      }

// $scope.ConfirmStopProcesses = function (process) {
//   let options = {
//     template: 'app/views/modals/confirm-stop-read.html',
//     data: {
//       elem: process,
//     }
//   };
//   ngDialog.openConfirm(options).then(
//     function (data) {
//       $scope.doStopProcesses(process)
//     }, function (reason) {
//       console.log('User canceled', reason);
//     });
//
// }


// --------------------- Filter stuff --------------------------//

      $scope.filter = {
        conditions: {},

        // Updates list of visible units according to the filter criteria,
        // Then refreshes the list of useful/visible parameters/columns
        // Then refreshes the table
        filtersChanged: function () {
          if ($scope.dashboard_viewMode === 'task' || $scope.dashboard_viewMode === 'timeline') {

            let searchText = $scope.filter.conditions['searchText'];
            let filter = $scope.filter.conditions['filter'];

            $rootScope.$emit('taskFilterChanged', searchText, filter);
            return;
          }

          if (!$scope.selectedPlace) {
            return;
          }
          if (!WGApiData.WGPlaces.ready ||
              // !WGApiData.WGProcesses.ready ||
              !WGApiData.WGDevices.ready ||
              !WGApiData.WGUnits.ready) {
            return;
          }
          // console.log("Filter conditions changed", $scope.filter);

          // Remove unused params
          // if (!$scope.filter.conditions['filter']) $scope.filter.conditions['filter'] = null;
          // if (!$scope.filter.conditions['searchText']) $scope.filter.conditions['searchText'] = null;
          // if (!$scope.filter.conditions['favorites']) $scope.filter.conditions['favorites'] = null;

          AuthService.update_url($scope.filter.conditions);
          AuthService.setDashboardConfigs({'overview_filter': $scope.filter.conditions});

          _.forEach($scope.selectedPlace.units, function (unit) {
            if ($scope.filter.filterByProperties(unit)) {
              unit.is_visible = true;
            } else {// Unset selection of hidden units
              unit.selected = false;
              unit.is_visible = false;
            }
          });

          $scope.units_selection.updateSelectedList();
          update_places_sensor_list([$scope.selectedPlace]);
          $scope.tableParams.reload();
        },
        filterByProperties: function (unit: IUnit) {
          // console.log("checking filter conditions");

          if (!$scope.filter.conditions || _.isEmpty($scope.filter.conditions)) { // No filters defined
            return unit;
          }

          if ($scope.filter.conditions['searchText']) {
            let _unit_valid = false;
            let _searchText = $scope.filter.conditions['searchText'].toLowerCase();
            _searchText = _.replace(_searchText, 'https://link.winegrid.com/sn/', '|');
            _searchText = _.replace(_searchText, 'https://link.watgrid.com/sn/', '|');
            // Replace SNs surrounded by spaces with the SN followed by |
            _searchText = _.replace(_searchText, /(^|\r?\n|\s|,|\\|)([a-fA-F0-9]{4,)(,|\s)/g, '$1$2|');
            // split at |, newlines, tab, or comma surrounded by spacings
            for (let _search_for of _searchText.split(/\r?\n|\||\t|\s,|,\s/)) {
              _search_for = _search_for.trim();
              if (_.isEmpty(_search_for)) {
                continue;
              }

              if (_unit_valid) {
                break;
              }

              if (unit.name?.toLowerCase().includes(_search_for)
                  || unit.id?.toString().includes(_search_for)
                  || unit.type?.toLowerCase().includes(_search_for)
                  || unit.process?.name?.toLowerCase().includes(_search_for)) {
                _unit_valid = true;
                break;
              }

              if (unit.devices?.[0]) {
                let _device_info = '' + unit.devices[0].name + '|' + unit.devices[0].sn + '|' + unit.devices[0].uuid + '|' + unit.devices[0].id + '|' + unit.devices[0].iid + '|'
                    + unit.devices[0].status + '|' + unit.devices[0].model + '|' + unit.devices[0].model_name + '|' + unit.devices[0].version + '|' + unit.devices[0].hw_version;
                if (_device_info.toLowerCase().includes(_search_for)) {
                  _unit_valid = true;
                  break;
                }
              }
            }
            if (!_unit_valid) {
              // SearchText not found on unit
              return;
            }
          }

          // if ($scope.filter.conditions['favorites']
          //     && !(unit.config_fields?.is_user_favorite?.[AuthService.user.uuid])) {
          //   return;
          // }

          if ($scope.filter.conditions['filter'] === 'process_running' && !(unit.process?.active)) {
            return;
          }

          if ($scope.filter.conditions['filter'] === 'device_on' && !(unit.devices[0]?.status === "ON" || unit.devices[0]?.status === "FAULT")) {
            return;
          }

          if ($scope.filter.conditions['filter'] === 'unit_type_barrel' && unit.unit_type !== "barrel") {
            return;
          }

          if ($scope.filter.conditions['filter'] === 'unit_type_vat' && unit.unit_type !== "vat") {
            return;
          }

          if ($scope.filter.conditions['filter'] === 'unit_type_bottle' && unit.unit_type !== "bottle") {
            return;
          }

          if ($scope.filter.conditions['filter'] === 'unit_type_press' && unit.unit_type !== "press") {
            return;
          }

          if ($scope.filter.conditions['filter'] === 'unit_type_cellar' && unit.unit_type !== "cellar") {
            return;
          }

          return unit;
        }
        ,
      };


// --------------------- Sort stuff --------------------------//

      $scope.sort = {
        selectedProperty: '',
        orderBy: '',

        /**
         * Sorts the table by the given property or "selectedProperty".
         * Converts table-sorting to a valid "selectedProperty"
         * Optionally sorts.
         * Saves the option to dashboardConfigs and updates URL
         * @param _new_property
         * @param sort
         */
        sortByChanged: function (_new_property = null, sort = true) {
          let _property = _new_property || $scope.sort.selectedProperty || '-priority';
          if (_property === 'other') {
            return;
          }
          _property = _property.replace(/^\+/, '');
          $scope.sort.orderBy = _property;

          let _column = _property.replace(/^-/, '').replace(/^\+/, '');
          let _direction = _.first(_property) === '-' ? 'desc' : 'asc';

          // Decide what to show on the dropdown
          switch (_column) {
            case 'priority':
              $scope.sort.selectedProperty = '';
              break;
            case 'name':
              $scope.sort.selectedProperty = 'name';
              break;
            case 'unit_type':
              $scope.sort.selectedProperty = 'unit_type';
              break;
            case 'process':
            case 'process.name':
              $scope.sort.selectedProperty = 'process';
              _column = 'process.name';
              break;
            case 'devices':
            case 'devices[0].name':
              $scope.sort.selectedProperty = 'devices';
              _column = 'devices[0].name';
              break;
            default:
              $scope.sort.selectedProperty = 'other';
              break;
          }

          if (sort) {
            // False when order was changed in the table-headers. No need to sort again
            $scope.tableParams.sorting(_column, _direction);
          }
          // console.log("Sort By changed to:", _column, _direction);

          if (_property != '-priority') {
            AuthService.update_url({'sort': _property});
            AuthService.setDashboardConfigs({'overview_sort': _property});
          } else {
            AuthService.update_url({'sort': null});
            AuthService.setDashboardConfigs({'overview_sort': null});
          }

        },
      }


// --------------------- Select a Place (Tab) --------------------------//

      $scope.selectedPlace = null;
      $scope.filteredTasks = [];
      $scope.allTasks = [];
      // Used to support ng-model from html. $scope.selectedPlace_id[ID]=true to select

      // --- Method 1
      // $scope.selectedPlace_id = {[-AuthService.view_as_owner.id]: true};
      // $rootScope.dashboard_selectedPlaceID = $scope.dashboard_selectedPlaceID = -AuthService.view_as_owner.id;

      // --- Method 2
      $rootScope.dashboard_selectedPlaceID = $scope.selectedPlace_id = -AuthService.view_as_owner.id;

// Sets the $scope.selectedPlace variable with data from the specified Place
      function select_place(place: IPlace, should_update_url = true) {
        // if (WG_debug) console.debug("select_place", {
        //   place_id: place.id,
        //   should_update_url: should_update_url,
        //   dashboard_selectedPlaceID: $scope.dashboard_selectedPlaceID,
        //   selectedPlace_id: $scope.selectedPlace_id,
        // });

        $scope.selectedPlace = WGApiData.extractPlaces([place])?.[0] || {} as IPlace;


        // --- Method 1
        //   if ($rootScope.dashboard_selectedPlaceID != place.id && $scope.selectedPlace_id[$rootScope.dashboard_selectedPlaceID]?.active) {
        //     $scope.selectedPlace_id[$rootScope.dashboard_selectedPlaceID] = false;
        //     $rootScope.dashboard_selectedPlaceID = place.id;
        //   }
        //   _.merge($scope.selectedPlace_id, {[place.id]: true});

        // --- Method 1.2
        // if ($rootScope.dashboard_selectedPlaceID != place.id) {
        //   $scope.selectedPlace_id = {[place.id]: true};
        //   $rootScope.dashboard_selectedPlaceID = $scope.dashboard_selectedPlaceID = place.id;
        // }

        // --- Method 2
        if ($scope.selectedPlace_id != place.id) {
          $rootScope.dashboard_selectedPlaceID = $scope.selectedPlace_id = place.id;
        }

        // if (!should_update_url) { // $scope.selectedPlace_id is automatically set from Jade
        // if ($rootScope.dashboard_selectedPlaceID != place.id) {
        //   $scope.selectedPlace_id[$rootScope.dashboard_selectedPlaceID] = false;
        // }
        // $scope.selectedPlace_id[place.id] = true;
        // $rootScope.dashboard_selectedPlaceID = place.id;
        // $scope.selectedPlace_id = {[place.id]: true};
        // $scope.selectedPlace_id = place.id;
        // }


        // Only save changes when all data is loaded
        if (!WGApiData.WGPlaces.ready ||
            !WGApiData.WGUnits.ready ||
            !WGApiData.WGDevices.ready) {
          if (WG_debug) console.log("Selecting place. Didn't save changes. Still loading data");

        } else {
          let _replace_history = false;

          // Replace previous URL if none was selected
          if (_.isEmpty($state?.params) ||
              (_.isEmpty($state.params.place) && _.isEmpty($state.params.unit) && _.isEmpty($state.params.device))) {
            if (WG_debug) console.log("Saving place. None was previously selected");
            should_update_url = true;
            _replace_history = true;
          } else if (!_.isEmpty($state?.params?.place) && !WGApiData.WGPlaces.places_id[parseInt($state.params.place)]) { // User doesn't have access to selected place
            if (WG_debug) console.log("Saving place. User doesn't have access to selected place");
            should_update_url = true;
            _replace_history = true;
          }

          if (should_update_url) {
            // console.log("Load finished. Saving Selected Place:", place.id);
            AuthService.update_url({
              'place': _.toString(place.id),
              'device': null,
              'unit': null,
            }, false, false, _replace_history);
            AuthService.setDashboardConfigs({'overview_place': _.toString(place.id)});
          }
        }

        WGApiData.update_units_status(place);

        $scope.filter.filtersChanged();
        // $scope.units_selection.updateSelectedList(); // Called by FiltersChanged
      }

      // Selects a visible place matching the ID, if given.
      // Returns true if a place was selected
      let first_ignored = false;
      // let select_place_timer: angular.IPromise<any> = null;
      $scope.select_place_if_valid = (place_id_to_select: number, should_update_url = false, from_jade = true): boolean => {
        // if (WG_debug) console.debug("select_place_if_valid", {
        //   place_id_to_select: place_id_to_select,
        //   should_update_url: should_update_url,
        //   from_jade: from_jade,
        //   WGPlaces_ready: WGApiData.WGPlaces.ready,
        //   WGUnits_ready: WGApiData.WGUnits.ready,
        //   WGDevices_ready: WGApiData.WGDevices.ready
        // });
        if (from_jade && !first_ignored) {
          first_ignored = true;
          if (WG_debug) console.log("Selecting place from uib-tab. First ignored", place_id_to_select, should_update_url, from_jade);
          return false;
        }

        if (!WGApiData.WGPlaces.ready)
          return false;

        if (place_id_to_select < 0 && !_.isEmpty(WGApiData.WGPlaces.places_id[place_id_to_select]?.units)) {
          // Desired unPlace has units. Maybe set a timer to ensure HTML has disgested it
          // if (WG_debug) console.log("Selecting desired virtual place", place_id_to_select, should_update_url, from_jade);
          select_place(WGApiData.WGPlaces.places_id[place_id_to_select], should_update_url);
          return true;
        }

        if (place_id_to_select > 0 && WGApiData.WGPlaces.places_id[place_id_to_select]) {
          // Desired place exists
          // if (WG_debug) console.log("Selecting desired place", place_id_to_select, should_update_url);
          select_place(WGApiData.WGPlaces.places_id[place_id_to_select], should_update_url);
          return true;
        }

        if (WG_debug) console.log("Selecting first available non-virtual Place?", place_id_to_select, should_update_url);
        for (let place of WGApiData.WGPlaces.places) {
          if (place.id < 0 && _.isEmpty(place.units)) {
            continue;
          }
          select_place(place, should_update_url);
          return true;
        }
        // }, 10);
        return false;
      }

      /***
       * Analyzes State params, selecting desired place.
       * Returns: False if no data yet to choose best place. True if desired place was selected.
       */
      let best_place_selected = false;

      function select_best_place(): boolean {
        if (best_place_selected)
          return true;

        if (!WGApiData.WGPlaces.ready) {
          return false;
        }
        if (WG_debug) console.debug("select_best_place", $state.params);

        // Choose the selected_place according to URL or previous values
        let _places_to_show;

        let _wanted_place = $state.params?.place || $state.params?.place_id || AuthService.dashboardConfigs?.overview_place;
        if (_wanted_place) {
          if (parseInt(<string>_wanted_place) < 0 &&
              (!WGApiData.WGUnits.ready || !WGApiData.WGDevices.ready)) {
            // Unplaced places are only ready after getting units and devices
            return false;
          }
          _places_to_show = WGApiData.extractPlaces(_wanted_place);
          if (WG_debug) console.log("Selected place based on $state.params.place or dashboardConfigs ", _places_to_show);
        }
        let _wanted_unit = $state.params?.unit || $state.params?.unit_id;
        if (_.isEmpty(_places_to_show) && _wanted_unit) {
          if (!WGApiData.WGUnits.ready) {
            return false;
          }
          _places_to_show = WGApiData.extractPlaces(WGApiData.extractUnits(_wanted_unit));
          if (WG_debug) console.log("Selected place based on $state.params.unit", _places_to_show);
        }
        let _wanted_device = $state.params?.device || $state.params?.device_uuid || $state.params?.device_id || $state.params?.device_sn || $state.params?.device1;
        if (_.isEmpty(_places_to_show) && _wanted_device) {
          if (!WGApiData.WGDevices.ready || !WGApiData.WGUnits.ready) {
            return false;
          }
          _places_to_show = WGApiData.extractPlaces(WGApiData.extractDevices(_wanted_device));
          if (WG_debug) console.log("Selected place based on $state.params.device", _places_to_show);
        }

        if (_.isEmpty(_places_to_show) && $rootScope.dashboard_selectedPlaceID && WGApiData.WGPlaces.places_id[$rootScope.dashboard_selectedPlaceID]) {
          _places_to_show = [WGApiData.WGPlaces.places_id[$rootScope.dashboard_selectedPlaceID]];
          if (WG_debug) console.log("Selected place based on last-viewed", _places_to_show);
        }

        best_place_selected = $scope.select_place_if_valid(_places_to_show?.[0]?.id || 0, false, false); // Selects chosen or first valid. Got form parameters, so don't update the URL
        return best_place_selected;
      }

      // Updates # of Processes and Alarms to show on header
      function update_places_counts(items = WGApiData.WGPlaces.places) {
        if (!WGApiData.WGPlaces.ready ||
            !WGApiData.WGUnits.ready) {
          return;
        }

        _.forEach(WGApiData.extractPlaces(items), (place) => {
          place.counts = _.assign(place.counts, {
            devices: 0,
            alarms: 0,
            processes: 0,
          });


          _.forEach(place.units, (_unit) => {
            let unit = WGApiData.WGUnits.units_id[_unit.id];
            if (!unit) {
              return;
            }
            place.counts.devices += _.size(unit.devices);

            if (unit.process && WGApiData.WGProcesses.processes_id[unit.process.id]?.active) {
              place.counts.processes++;
            }

            if (unit.alarms) {
              place.counts.alarms += _.size(unit.alarms.user_notifications);
              place.counts.alarms += _.size(unit.alarms.ai_notifications);
              // place.counts.alarms += _.size(unit.alarms.status_notifications);
            }
          });
        });
      }


      //
      $rootScope.$watch('AuthService.user.configs', (_new_value, _old_value) => {
        if (_new_value === _old_value) { // Initializing watcher
          return;
        }
        console.log("User configs have changed!", _new_value)
        WGApiData.update_units_status();

        update_places_counts()
        refresh_table();
      }, true);

      // --------------  INIT stuff

      //get wg-protocol-view controller


      var reset_all_data = () => {
        WGApiData.reset_all_data();
        // AuthService.update_url({'place': null, 'place_id': null}, false, false, false);
        $scope.selectedPlace = null as IPlace;
      }


      // Graphs Part

      $scope.hasSelectedUnitGraph = false;
      $scope.selectedGraphSensors = {};

      $scope.addToGraph = (device_uuid, internal_names: string | string[], forced = false, add_to_url = true) => {
        let ret = false;
        if ($scope.graphCtrl) {
          if (!_.isArray(internal_names)) {
            internal_names = [internal_names];
          }
          _.forEach(internal_names, (internal_name) => {
            if ($scope.graphCtrl.addToGraph(device_uuid, internal_name, forced, add_to_url)) {
              $scope.hasSelectedUnitGraph = true;
              $scope.last_selected_sensor = internal_name;
              ret = true;
            }
          });
        }
        return ret;
      };

      $scope.clearSelectedSensors = function () {
        if ($scope.graphCtrl?.init) {
          $scope.graphCtrl.init();
        }
        // $scope.last_selected_sensor = null;
        $scope.selectedGraphSensors = {};
        $scope.hasSelectedUnitGraph = false;


        // Delete from URL
        let _params = {
          'xAxisMin': null,
          'xAxisMax': null,
        };
        for (let i = 1; i < 19; i++) {
          _params['device' + i] = null;
          _params['param' + i] = null;
        }
        AuthService.update_url(_params);
      };

      // $scope.spitData = function () {
      //   spitData("Dashboard Overview, spit", $scope);
      // }

      // Called everytime this state is entered, on $stateChangeStart
      let init = function () {

        if ($scope.$parent.clearSelectedSensors) {
          $scope.$parent.clearSelectedSensors();
        }

        if (!AuthService.isLogged) {
          console.log("Overview: Not logged in")

          // console.log('app main go', AuthService.loginState, $state.current.name);
          $state.go(AuthService.loginState, {user: null});
          return;
        }

        // console.log("Initializing")
        let stateParams = $state?.params || {};

        // Choose the viewMode according to URL or previous values
        $scope.dashboard_viewMode = stateParams['view'] || AuthService.dashboardConfigs?.overview_viewType || $scope.dashboard_viewMode;
        if (stateParams['view'] != $scope.dashboard_viewMode) {
          AuthService.update_url({'view': $scope.dashboard_viewMode}, false, false, true);
        }

        $scope.filter.conditions['filter'] = stateParams['filter'] || AuthService.dashboardConfigs?.overview_filter?.filter || $scope.filter.conditions['filter'];
        $scope.filter.conditions['searchText'] = stateParams['searchText'] || AuthService.dashboardConfigs?.overview_filter?.searchText || $scope.filter.conditions['searchText'];
        // $scope.filter.conditions['favorites'] = stateParams['favorites'] || AuthService.dashboardConfigs?.overview_filter?.favorites || $scope.filter.conditions['favorites'];
        // $scope.filter.conditions['favorites'] = false;
        $scope.filter.filtersChanged();

        let sortBy = stateParams['sort'] || AuthService.dashboardConfigs?.overview_sort || $scope.sort.selectedProperty;
        if (sortBy)
          $scope.sort.sortByChanged(sortBy, true);


        // console.log($window.innerWidth, "$window.innerWidth")
        if ($window.innerWidth < 480) {
          $scope.filterMenu.isOpen = false
        }

        // Does nothing if there's data missing. Otherwise selects best place to show
        if (WG_debug) console.log('Init, selecting best place.');
        select_best_place();
        update_places_counts();

        // If we have graphics from URL that should be shown, schedule to shown them when data finishes loading
        if (stateParams['device1']) {
          let unhook1 = $scope.$watchCollection('graphCtrl', function () {
            if (!$scope.graphCtrl) {
              return;
            }

            unhook1();
            let unhook2 = $rootScope.$watchGroup(['WGSensors.ready', 'WGDevices.ready'], function () {
              if (!$scope.graphCtrl ||
                  !WGApiData.WGSensors.ready ||
                  !WGApiData.WGDevices.ready) {
                return;
              }
              for (let i = 1; i < 19; i++) {
                if ($state.params['device' + i] && $state.params['param' + i]) {
                  $scope.addToGraph($state.params['device' + i], $state.params['param' + i], false, false);
                } else {
                  break;
                }
              }
              unhook2();
            });
          });
        }

      };

      init();
    }

  ]);

}