/**=========================================================
 * Module: constants.js
 * Define constants to inject across the application
 =========================================================*/
namespace wg {
  App
      .constant('APP_COLORS', {
        'primary': '#5d9cec',
        'success': '#27c24c',
        'info': '#23b7e5',
        'warning': '#ff902b',
        'danger': '#f05050',
        'inverse': '#131e26',
        'green': '#37bc9b',
        'pink': '#f532e5',
        'purple': '#7266ba',
        'dark': '#3a3f51',
        'yellow': '#fad732',
        'gray-darker': '#232735',
        'gray-dark': '#3a3f51',
        'gray': '#dde6e9',
        'gray-light': '#e4eaec',
        'gray-lighter': '#edf1f2'
      })
      .constant('APP_MEDIAQUERY', {
        'desktopLG': 1200,
        'desktop': 992,
        'tablet': 768,
        'mobile': 480
      })
      .constant('APP_REQUIRES', (function () {
        var APP_REQUIRES = {
          // jQuery based and standalone scripts
          scripts: {
            // 'whirl': ['vendor/whirl/whirl.css'],
            'modernizr': ['vendor/npm-modernizr/modernizr.js'],
            'icons': ['vendor/font-awesome/css/font-awesome.min.css',
              'vendor/simple-line-icons/css/simple-line-icons.css',
              'vendor/famfamfam-flags/dist/sprite/famfamfam-flags.min.css',
            ],
            // 'parsley': [// 'vendor/parsleyjs/dist/parsley.min.js', // Loaded on Base
            //   'vendor/parsleyjs/dist/i18n/en.js',
            //   'vendor/parsleyjs/dist/i18n/pt-pt.js',
            //   'vendor/parsleyjs/dist/i18n/fr.js',
            //   // 'vendor/parsleyjs/dist/i18n/it.js',
            //   'vendor/parsleyjs/dist/i18n/es.js'],
            'slimscroll': ['vendor/jquery-slimscroll/jquery.slimscroll.min.js'],
            'loadGoogleMapsJS': ['vendor/gmap/load-google-maps.js'],
            'google-map': ['vendor/jQuery-gMap/jquery.gmap.min.js'],
            // 'paho-mqtt': ['vendor/paho-mqtt/paho-mqtt.js'],
            // 'lodash':             ['vendor/lodash/lodash.min.js'],
            // 'chart': ['vendor/chart.js/Chart.min.js'],
            'bootstrap-progressbar': ['vendor/bootstrap-progressbar/bootstrap-progressbar.js'],
            'timestring': ['vendor/timestring/dist/timestring.min.js'],


            'highcharts-customEvents': ['vendor/highcharts-customEvents/customEvents.js'],

            'highcharts-css': ["vendor/highcharts/css/highcharts.css"],
            // 'highcharts-draggable-points': ["vendor/highcharts/modules/draggable-points.js"],

            // 'highcharts-export': [// 'vendor/highstock-release/modules/exporting.js',
            //   'vendor/highstock-release/modules/offline-exporting.js'],
            // 'highcharts-yaxis-panning': ['vendor/highcharts-yaxis-panning/yaxis-panning.js'],
            // 'highcharts-scalable-yaxis': ['vendor/highcharts-scalable-yaxis/scalable-yaxis.js'],
            // 'highcharts-draggable-points': ['vendor/highcharts-draggable-points/draggable-points.js'],
            // 'highcharts-customEvents': ['vendor/highcharts-customEvents/customEvents.js'],
            'filestyle': ['vendor/bootstrap-filestyle/src/bootstrap-filestyle.min.js'],
            'loader': ['vendor/loaders.css/loaders.min.css',
              'vendor/load-awesome/docs/assets/loaders.css'],
            //'vendor/css-loaders/css/load8.css']
            'inputmask': ['vendor/inputmask/dist/jquery.inputmask.bundle.js'],
          },
          // Angular based script (use the right module name)
          modules: [
            {
              name: 'angular-click-outside',
              files: ['vendor/@iamadamjowett/angular-click-outside/clickoutside.directive.js']
            },
            {
              name: 'toaster', files: ['vendor/angularjs-toaster/toaster.min.js',
                'vendor/angularjs-toaster/toaster.min.css']
            },
            {name: 'ui.gravatar', files: ['vendor/angular-gravatar/build/angular-gravatar.min.js']},
            {
              name: 'ngTable', files: ['vendor/ng-table/ng-table.js',
                'vendor/ng-table/ng-table.css']
            },
            {
              name: 'ngDialog', files: ['vendor/ng-dialog/js/ngDialog.min.js',
                'vendor/ng-dialog/css/ngDialog.min.css',
                'vendor/ng-dialog/css/ngDialog-theme-default.min.css']
            },
            {
              name: 'localytics.directives', files: ['vendor/chosen-js/chosen.jquery.min.js',
                'vendor/chosen-js/chosen.min.css',
                'vendor/angular-chosen-localytics/dist/angular-chosen.min.js']
            },
            //{name: 'angularMoment',           files: ['vendor/angular-moment/angular-moment.js'] },
            {name: 'uiGmapgoogle-maps', files: ['vendor/angular-google-maps/dist/angular-google-maps.min.js']},
            // {
            //   name: 'highcharts-ng', files: [// 'vendor/highstock-release/highstock.js', // Loaded on Base.
            //     // 'vendor/highstock-release/highcharts-more.js', // Loaded on Base. This requires highstock to be loaded already!
            //     'vendor/highcharts-ng/dist/highcharts-ng.min.js' // This requires highstock/highchart to be loaded already!
            //   ]
            // },
            // {
            //   name: 'chart.js', files: ['vendor/angular-chart.js/dist/angular-chart.min.js',
            //     'vendor/angular-chart.js/dist/angular-chart.css']
            // },
            // {
            //   name: 'colorpicker.module',
            //   files: ['vendor/angular-bootstrap-colorpicker/js/bootstrap-colorpicker-module.min.js',
            //     'vendor/angular-bootstrap-colorpicker/css/colorpicker.min.css']
            // },
            {
              name: 'jsonFormatter', files: ['vendor/jsonformatter/dist/json-formatter.min.js',
                'vendor/jsonformatter/dist/json-formatter.min.css']
            },
            {
              name: 'ng-nestable', files: ['vendor/angular-nestable/jquery.nestable.js',
                'vendor/angular-nestable/angular-nestable.js']
            },
            {
              name: 'textAngular', files: ['vendor/textangular/dist/textAngular.css'
                //'vendor/textangular/dist/textAngular-rangy.min.js',
                //'vendor/textangular/dist/textAngular-sanitize.js',
                //'vendor/textangular/src/globals.js',
                //'vendor/textangular/src/factories.js',
                //'vendor/textangular/src/DOM.js',
                //'vendor/textangular/src/validators.js',
                //'vendor/textangular/src/taBind.js',
                //'vendor/textangular/src/main.js',
                //'vendor/textangular/dist/textAngularSetup.js',
                //'vendor/textangular/dist/textAngular-rangy.min.js',
                //'vendor/textangular/dist/textAngular-sanitize.min.js',
                //'vendor/textangular/dist/textAngularSetup.js',
                //'vendor/textangular/configs.js',
                //'vendor/textangular/dist/textAngular.js'
                //'vendor/textangular/dist/textAngular.min.js'
              ]
            },
            // {
            //   name: 'ui.select', files: ['vendor/ui-select/dist/select.min.js',
            //     'vendor/ui-select/dist/select.min.css'
            //   ]
            // },
            // {
            //   name: 'ui.bootstrap.datetimepicker', files: [//'vendor/angular-ui-bootstrap-datetimepicker/datetimepicker.css',
            //     'vendor/angular-ui-bootstrap-datetimepicker/datetimepicker.js']
            // },
            // {name: 'angular-confirm', files: ['vendor/angular-confirm/angular-confirm.min.js']},
            {name: 'ng-FitText', files: ['vendor/ngFitText/dist/ng-FitText.min.js']},
            // {
            //   name: 'angularSpectrumColorpicker', files: ['vendor/spectrum-colorpicker/spectrum.js',
            //     'vendor/spectrum-colorpicker/spectrum.css',
            //     'vendor/angular-spectrum-colorpicker/dist/angular-spectrum-colorpicker.min.js']
            // },
            {name: 'text-mask', files: ['vendor/angular1-text-mask/dist/angular1TextMask.js']},
            {
              name: 'ui.sortable', files: ['vendor/angular-ui-sortable/dist/sortable.min.js',
                'vendor/jquery-ui-dist/jquery-ui.min.js',
                'vendor/jquery-ui-touch-punch/jquery.ui.touch-punch.min.js']
            },
            {
              name: 'JSONedit', files: ['vendor/angular-ui-sortable/dist/sortable.min.js',
                'vendor/JSONedit/js/directives.js',
                'vendor/JSONedit/css/styles.css']
            },
            // {name: 'angularFileUpload', files: ['vendor/angular-file-upload/dist/angular-file-upload.min.js']}, // Provides "FileUpload"
            // {name: 're-tree', files: ['vendor/re-tree/re-tree.min.js']},
            // {name: 'ua-device-detector', files: ['vendor/ua-device-detector/ua-device-detector.min.js']},
            // {
            //   name: 'ng.deviceDetector', files: ['vendor/re-tree/re-tree.min.js',
            //     'vendor/ua-device-detector/ua-device-detector.min.js',
            //     'vendor/ng-device-detector/ng-device-detector.min.js'] // Provides "deviceDetector"
            // }

          ]
        };
        // return APP_REQUIRES;
        var app_requires = {
          scripts: {},
          modules: []
        };

        function v(url) {
          if (url !== null && angular.isDefined(url) && angular.isString(url)) {
            url += (url.indexOf("?") === -1 ? "?" : "&");
            url += "v=" + window['get_WG_version']?.();
          }
          return url;
        }

        for (var property in APP_REQUIRES.scripts) {
          if (APP_REQUIRES.scripts.hasOwnProperty(property)) {
            app_requires.scripts[property] = [];
            APP_REQUIRES.scripts[property].forEach(function (url) {
              app_requires.scripts[property].push(v(url));
            });

          }
        }
        for (var i = 0; i < APP_REQUIRES.modules.length; ++i) {
          var module = {
            name: APP_REQUIRES.modules[i].name,
            files: []
          };
          APP_REQUIRES.modules[i].files.forEach(function (url) {
            module.files.push(v(url));
          });
          app_requires.modules.push(module);
        }
        return app_requires;
      })()).constant('angularMomentConfig', {
    //preprocess: 'unix', // optional
    //preprocess: 'utc', // optional
    //timezone: 'Europe/London' // optional
    timezone: 'Europe/Lisbon' // optional
  })
  ;
}