/**
 * Created by pmec on 30/01/15.
 */
// This is the parent of most views. Runs before
namespace wg {
  App.controller("DevicesController", ['$rootScope', '$scope', '$http', '$translate', 'amMoment', 'AuthService', 'WGApiData',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $translate: ng.translate.ITranslateService, amMoment, AuthService: IAuthService, WGApiData: WGApiData) {
      //console.log('Entrou no Devices-Controller');

      if (!AuthService.isLogged) {
        return;
      }

      $scope.devices = [] as IDevice[];
      // Devices' sensor data
      // data[device_uuid][sensor] = {beginTimestamp, endTimestamp,
      $scope.data = {};
      $scope.dataLoaded = false;

      $scope.serverDown = false;

      $scope.gotDevices = false;
      var devicesLoaded = {};

      function refreshTimestamps(device_uuid, sensor, timestamp) {
        if (WG_debug) console.log("refreshTimestamps? What are we doing here?");
        if (!angular.isDefined($scope.data[device_uuid][sensor].beginTimestamp)) {
          $scope.data[device_uuid][sensor].beginTimestamp = timestamp;
          $scope.data[device_uuid][sensor].endTimestamp = timestamp;
        } else {
          if ($scope.data[device_uuid][sensor].beginTimestamp > timestamp)
            $scope.data[device_uuid][sensor].beginTimestamp = timestamp;
          if ($scope.data[device_uuid][sensor].endTimestamp < timestamp)
            $scope.data[device_uuid][sensor].endTimestamp = timestamp;
        }
      }

      // Deprecated
      $scope.getUrlExport = function (device_uuid, topic, min, max, filename, extension) {
        var key = '&api_key=' + $rootScope.apiKey;
        var url = $rootScope.base_url + 'data/' + $rootScope.WGDevices.devices_uuid[device_uuid].path +
            "/" + topic + '?b=' + min + '&q={"payload": ["timestamp", "value"]}&e=' +
            max + '&n=' + filename + extension + key;
        return url;
      };

      $scope.getDeviceSensorData = function (device_uuid, sensor, begin, end, next, callback, callbackArgs, force) {
        //console.log('getDeviceSensorData', $scope.data, device_uuid, sensor);
        //console.log("Request getDeviceSensorData: (begin, end, data)", begin, end, $scope.data[device_uuid][sensor]);
        if (force == null)
          force = false;
        if (begin == null)
          return;
        if (end == null)
          end = new Date().getTime();
        if (begin > $scope.data[device_uuid][sensor].beginTimestamp && !force) {
          callback(callbackArgs);
          return;
        }

        if (end > $scope.data[device_uuid][sensor].beginTimestamp && !force)
          end = $scope.data[device_uuid][sensor].beginTimestamp - 1;

        $scope.data[device_uuid][sensor].beginTimestamp = begin;

        //   $scope.getDataProcess(device_uuid, sensor, begin, end, next, callback, callbackArgs, force);
        // };
        //
        // $scope.getDataProcess = function (device_uuid, sensor, begin, end, next, callback, callbackArgs, force) {
        let key = '&api_key=' + $rootScope.apiKey;
        let url;

        if (next) {
          url = next + key;
        } else {
          url = $rootScope.base_url + 'data/' + $rootScope.WGDevices.devices_uuid[device_uuid].path + '/' + sensor +
              '?b=' + begin + '&s=50000&q={"payload": ["timestamp", "value"]}&f=list' + key;
          if (angular.isDefined(end) && end != null) {
            url = updateURLParameter(url, 'e', end);
          }
        }

        $http.get<IDataResponse<IDevice>>(url, {}).then(
            function (response) {
              var data = response.data;
              //data.results = _.sortBy(data.results, function (element) {
              //    return element[0];
              //});
              if (data.results.length === 0 &&
                  angular.isDefined($rootScope.lastKnownMessages[device_uuid]) &&
                  angular.isDefined($rootScope.lastKnownMessages[device_uuid][sensor])) {
                var newBegin = $rootScope.lastKnownMessages[device_uuid][sensor].timestamp;
                if (typeof (begin) === 'number' && newBegin < begin) {
                  if (angular.isDefined(end) && end != null && typeof (end) === 'number') {
                    newBegin -= end - begin
                  }
                  $scope.getDeviceSensorData(device_uuid, sensor, newBegin, end, null, callback, callbackArgs, force);
                  return;
                }
              }
              angular.forEach(data.results, function (element) {
                $scope.data[device_uuid][sensor].seriesData.push(element);
                refreshTimestamps(device_uuid, sensor, element[0]);
              });
              if (data.next) {
                $scope.getDeviceSensorData(device_uuid, sensor, begin, end, $rootScope.base_url + data.next, callback, callbackArgs);
              } else {
                angular.extend($scope.data[device_uuid][sensor].seriesData, _.sortBy($scope.data[device_uuid][sensor].seriesData, function (data) {
                  return data[0];
                }));
                //$scope.data[device_uuid][sensor].seriesData = _.sortBy($scope.data[device_uuid][sensor].seriesData, function (data) {
                //  return data[0];
                //});
                //console.log($scope.data);
                callback(callbackArgs);
              }
            }, function (response) {
              console.error(response);
            });
      };

      // Define a $scope.data[][] object for each device_uuid and sensor
      $scope.checkDataDefinition = function (device_uuid, stream) {
        if (!$scope.data[device_uuid]) {
          $scope.data[device_uuid] = {};
        }
        if (stream && !$scope.data[device_uuid][stream]) {
          $scope.data[device_uuid][stream] = {
            seriesData: [],
            beginTimestamp: undefined,
            endTimestamp: undefined,
          };
        }
      };

      $rootScope.$watch('WGApiData.AllReady', function () {
        $scope.dataLoaded = false;
        if (!WGApiData.AllReady) {
          return;
        }
        console.log('Devices, devices_updated');
        // $rootScope.$on('devices_updated', function (event, args) {
        $scope.devices = WGApiData.WGDevices.devices;
        $scope.devices_uuid = WGApiData.WGDevices.devices_uuid;

        _.forEach($scope.devices, function (device: IDevice) {
          if (SMARTBOX_MODELS.includes(device.model)) {
            // Ignore Smartboxes
            return;
          }

          _.forEach(device.streams, function (stream) {
            $scope.checkDataDefinition(device.uuid, stream);
          });
        })

        $scope.gotDevices = true;
        $scope.dataLoaded = true;
      });
    }]);
}