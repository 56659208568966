/**=========================================================
 * Module: top-navbar.js
 * Handle top-navbar logic
 =========================================================*/
namespace wg {
  App.controller('TopNavBarController', ['$rootScope', '$scope', '$translate', 'AuthService', 'ngDialog',
    function ($rootScope: IRootScope, $scope, $translate: ng.translate.ITranslateService, AuthService: IAuthService, ngDialog: angular.dialog.IDialogService) {
      // If this ng-model variable is in this scope, it's updated After the ng-change, and causes bugs...
      // $scope.selected_view_as_owner = AuthService.view_as_owner;

      // $scope.APIUtils = APIUtils;
      if ($rootScope.releaseNotes) {
        $rootScope.releaseNotes.open = function () {
          $rootScope.releaseNotes.text = emptyOrCreateArray($rootScope.releaseNotes.text);
          for (let i = 1; i <= 3; i++) {
            let releasenotes_text = $translate.instant('app.releasenotes.text_' + i);
            if (!releasenotes_text || releasenotes_text == 'app.releasenotes.text_' + i) {
              continue;
            }
            releasenotes_text.replace(/\r\n|\r/g, '\n');
            $rootScope.releaseNotes.text.push(releasenotes_text.split('\n'))
          }

          ngDialog.openConfirm({
            template: 'ReleaseNotesTemplate.html',
          }).then(function (value) {
            // console.log('ReleaseNotes Seen.');
          }, function (reason) {
            // console.log('ReleaseNotes Rejected.');
          });

          console.log('ReleaseNotes seen: ', $rootScope.releaseNotes.currentVersion);
          if ($rootScope.releaseNotes.userSeen < $rootScope.releaseNotes.currentVersion) {
            $rootScope.releaseNotes.userSeen = $rootScope.releaseNotes.currentVersion
            $rootScope.releaseNotes.isOpen = false;
            $rootScope.releaseNotes.updateSeen($rootScope.releaseNotes.currentVersion);
          }
          // $rootScope.releaseNotes.updateSeen(0);
        }
      }

      $scope.view_as_owner_change = function (selected_view_as_owner) {
        if (!selected_view_as_owner || !selected_view_as_owner.id) {
          selected_view_as_owner = AuthService.default_owner;
        }
        AuthService.selected_view_as_owner = selected_view_as_owner;
        // Check if the user was changed
        if (AuthService.view_as_owner?.id === selected_view_as_owner.id && AuthService.userData?.view_as_owner?.id == selected_view_as_owner.id) {
          // Still the same
          return;
        }

        let _target_state = null;
        if (selected_view_as_owner.role == 'distributor' && AuthService.view_as_owner.role != 'distributor') {
          _target_state = 'app.manage.licenses';
          AuthService.homeState = _target_state;
        } else if (selected_view_as_owner.role != 'distributor' && AuthService.view_as_owner.role == 'distributor') {
          _target_state = 'app.devices.dashboard';
          AuthService.homeState = _target_state;
        }
        if (WG_debug) _target_state && console.log('Changing state to ', _target_state);

        AuthService.view_as_owner = selected_view_as_owner;
        AuthService.view_as_owner_role = userRoles[AuthService.view_as_owner?.role || 'user'];
        AuthService.setUserData({view_as_owner: selected_view_as_owner});

        console.log('View_as_user changed to ', selected_view_as_owner);
        let _parameters = {'user': _.toString(selected_view_as_owner.id)};
        AuthService.update_url(_parameters, true, true, undefined, undefined, _target_state);
      }

      $scope.set_clients_view = function (value: boolean) {
        if (WG_debug) console.log('Set clients_view to ', value);
        AuthService.clients_view = value;
        AuthService.setUserData({clients_view: !!AuthService.clients_view});
        $rootScope.WGAlarms.reset();
        $rootScope.WGApiData.update_changed_data();
        $rootScope.reload?.();
      }

      $scope.set_presentation_mode = function (value: boolean) {
        if (WG_debug) console.log('Set presentation_mode to ', value);
        AuthService.presentation_mode = !!value;
        AuthService.setUserData({presentation_mode: AuthService.presentation_mode});
        // if (!AuthService.presentation_mode)
        //   AuthService.setUserData({view_as_owner: null});
        window.location.reload();
      }

    }]);
}