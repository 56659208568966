/**=========================================================
 * Module: filestyle.js
 * Initializes the fielstyle plugin
 =========================================================*/
namespace wg {
  export class FileStyleDirective implements ng.IDirective {
    restrict = 'A';

    link(scope: ng.IScope, element: JQuery) {
      let options = element.data();
      // old usage support
      options.classInput = element.data('classinput') || options.classInput;
      // @ts-ignore
      element.filestyle?.(options);
    }
  }

  App.directive('filestyle', () => new wg.FileStyleDirective());
}
