/**
 * Created by pmec on 28/01/15.
 */

App.controller("GroupsController", ['$scope', '$http', function ($scope, $http) {
  $scope.me = {};
  $scope.getGroups = function () {
    $http.get('api/auth/me/', {}).then(
        function (response) {
          var data = response.data;
          //console.log(data);
          $scope.me = data;
        }, function (response) {
          console.error(response);
        });
  };
  $scope.getGroups();
}]);