/**
 * Created by silva on 15-09-2016.
 * Used in manage.processes
 */

namespace wg {
  interface IScope extends ng.IScope {
    changeSelection(item: IPlace | IUnit | IDevice | IProcess | IBatch): void;

    changeAllSelection(value: boolean): void;

    list_items: IPlace[] | IUnit[] | IDevice[] | IProcess[] | IBatch[];
    selection: IPlace[] | IUnit[] | IDevice[] | IProcess[] | IBatch[];
    checkboxes: {
      checked: boolean,
      items: { [id: number]: boolean }
    };
  }

  App.controller("TableProcessesController", ['$rootScope', '$scope', '$filter', '$http', '$timeout', '$state', '$translate', 'ngDialog', 'ngTableParams', '$document', '$interval', 'AuthService', 'Data', '$window',
    function ($rootScope: IRootScope, $scope: IScope, $filter: ng.IFilterService, $http: ng.IHttpService, $timeout: ng.ITimeoutService, $state: _IState, $translate: ng.translate.ITranslateService, ngDialog: ng.dialog.IDialogService, ngTableParams: NgTable.ITableParamsConstructor<IProcess>, $document, $interval, AuthService: IAuthService, Data, $window) {
      // $scope.pop = function(){
      //     alert("tableParams"+$scope.tableParams.total());
      // };
      //                              ---     VARIABLES

      // let entity_type = $state.current.url.slice(1);
      // @ts-ignore
      let entity_type = $state.current?.url?.match?.("\/?([^\?\/&]+).*")[1];
      if (_.isEmpty(entity_type)) {
        console.error("Error, trying to access ", $state.current.url);
      }
      let entity_type_singular = 'process';

      $scope.loading = false;

      //                  --- SELECTED
      let animateSelected: ng.IPromise<any>;
      $scope.see = {
        item: null,
        is: function () {
          return !_.isNil($scope.see.item);
        },
        select: function (item) {
          AuthService.update_url({process: item.id}, false, false, false);

          $scope.see.item = null;

          if (angular.isDefined(animateSelected)) {
            $timeout.cancel(animateSelected);
          }
          animateSelected = $timeout(function () {
            $scope.see.item = item;
          }, 1);
          $('html, body').animate({scrollTop: $(document).height()}, 800, 'swing');
        },
        clear: function () {
          $scope.see.item = null;
          let _url_params = {};
          _url_params[entity_type_singular] = null;
          AuthService.update_url(_url_params, false, false, false);
        }
      };

      $scope.counts = {
        ON: 0,
        OFF: 0,
        TOTAL: 0,
      };

      // --- Multi selection and actions
      // Selected entries
      $scope.selection = [];
      $scope.checkboxes = {
        checked: false, // all selected
        items: {},  // checked items
      };

      $scope.changeSelection = function (item) {
        // remove or add an item to selection array, based on item.id
        let obj = _.find($scope.selection, {id: item.id});
        if (!$scope.checkboxes.items[item.id]) {
          // De-selected
          if (obj)
            $scope.selection = _.without($scope.selection, item);
        } else {
          if (!obj) { // @ts-ignore
            $scope.selection.push(item);
          }
        }

        if (!$scope.list_items) {
          return;
        }
        var checked = 0, unchecked = 0;
        var total = $scope.list_items.length;
        angular.forEach($scope.list_items, function (item) {
          if ($scope.checkboxes.items[item.id]) {
            checked++;
          } else {
            unchecked++;
          }
        });

        // Select_all logic
        if (total > 0 && checked === total) {
          $scope.checkboxes.checked = true;
        } else {
          $scope.checkboxes.checked = false;
        }
      };

      $scope.changeAllSelection = function (value) {
        if (WG_debug) console.log("changeAllSelection", value);
        $scope.checkboxes.checked = value;
        //v// Select or de-select all items
        if (value) {
          _.forEach($scope.list_items, function (item: IPlace | IUnit | IDevice | IProcess | IBatch) {
            if (!_.isNil(item?.id)) {
              $scope.checkboxes.items[item.id] = value;
            }
          });
          $scope.selection = $scope.list_items;
        } else {
          $scope.checkboxes.items = {};
          $scope.selection = [];
        }
      };

      //                       ---     DATA-TABLE
      $scope.batch = {
        feedback: null,
        states: {success: 'success', error: 'error', loading: 'loading'},
        action: null,
        actions: {delete: 'delete', stop: 'stop'},
        items: [],
        do: function () {
          console.log("Process batch action ", this.action);
          let _this = this;

          this.items = [];
          //  *Create array for feedback
          _.forEach($scope.selection, function (process, key) {
            _this.items[key] = null;
          });

          //  ACTIONs

          if (_this.action === _this.actions.stop) {
            if (modalIsOpen) {
              console.log("batch stop modal already open");
              return;
            }
            let stopIts = [];
            //  *create pass array for dialog if action===stop
            _.forEach($scope.selection, function (process: IProcess, key) {
              if (process.active)
                stopIts.push({
                  id: process.id,
                  name: process.name,
                  started_at: process.started_at,
                  active: process.active,
                  batch_key: key,
                });
            });
            modalIsOpen = true;
            //  *Batch stop with dialog
            let manyStop = ngDialog.open({
              template: 'app/views/modals/stop_many.html',
              controller: 'SeeRunStopController',
              className: 'ngdialog-theme-default',
              data: {
                isMany: true,
                procs: stopIts
              }
            });
            manyStop.closePromise.then(
                function (data) {
                  console.log("Process Stop dialog closed.",
                      "data:", data);
                  $scope.reload(true);
                });
            // $scope.stop(process, key);
          } else if (_this.action === _this.actions.delete) {
            this.feedback = this.states.loading;
            //  *Batch delete
            _.forEach($scope.selection, function (process: IPlace | IUnit | IDevice | IProcess | IBatch, key) {
                  //  maybe try this:
                  // $scope.[this.action](process, key);
                  $scope.delete(process, key);
                  if ($scope.see.item !== null && $scope.see.item.id === process.id)
                    $scope.see.clear();
                }
            );
          }
        },
      };

      $scope.$watch('batch.items', function (o, n) {
        console.log("Batch.items changed:", $scope.batch.items, "state:", $scope.batch.feedback);
        if (!_.isEmpty($scope.batch.items)) {
          var k = _.indexOf($scope.batch.items, false);
          if (k > -1) {
            $scope.batch.feedback = $scope.batch.states.error;
          } else {
            $scope.batch.feedback = $scope.batch.states.success;
          }
        }
      }, true);

      //                  --- DATA-TABLE
      $scope.list_items = [];

      $scope.search = {
        all: '',
        do_search: function () {
          console.log("Search:", this.all);
          $scope.tableParams.reload();
        },
      };
      $scope.showingFrom = 0;
      $scope.showingTo = 0;

      $scope.tableParams = new ngTableParams({
        page: 1,            // show first page
        count: 25,          // initial count per page
        sorting: {
          active: 'desc',   // Active first
          created_at: 'asc',
        },
        filter: {}
      }, {
        total: 0, // length of data
        paginationMaxBlocks: 7, // 7 = first, 3 centered, last
        paginationMinBlocks: 3,
        getData: (params) => {
          $scope.loading = true;
          console.log("Get-TD processes.");
          let search = $scope.search.all;
          let filter = '';
          let obj = params.filter();
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (angular.isDefined(obj[key]) && obj[key] !== '')
                filter += key + '=' + obj[key] + '&';
            }
          }
          let query = '';
          if (params.orderBy() + '' !== '') {
            query += '&ordering=' + params.orderBy();
          }
          if (angular.isDefined(search) && search !== '') {
            query += '&search=' + search;
          } else {
            query += '&page=' + params.page();
          }
          if (filter !== '') {
            filter = filter.substring(0, filter.length - 1);
            query += '&' + filter;
          }

          return $http.get<IDataResponse<IProcess>>('api/dashboard/processes/?page_size=' + params.count() + query, {}).then(
              (response) => {
                console.log("Got-TD processes. response:", response);

                AuthService.anonymize_entities("process", response.data.results);

                $scope.changeAllSelection(false);

                $scope.list_items = response.data.results;

                $scope.counts.ON = 0;
                $scope.counts.OFF = 0;
                $scope.counts.TOTAL = $scope.list_items.length;

                let _current_time = new Date().getTime();
                angular.forEach($scope.list_items, function (process) {

                      if (!process.active) {
                        // Fix for processes that are active but not marked as such
                        // When process started in the past, and not yet finished
                        if (process.started_at && Date.parse(process.started_at) < _current_time) {
                          if (_.isNil(process.ended_at) || Date.parse(process.ended_at) > _current_time) {
                            // if (WG_debug) console.warn("Process inactive but still not ended!", {process: process});
                            process.active = true;
                          }
                        }
                      }

                      if (process.active) {
                        $scope.counts.ON++
                      } else {
                        $scope.counts.OFF++
                      }

                      if (_.toInteger($state?.params?.['process']) == process.id) {
                        $scope.see.select(process);
                      }
                    }
                )

                // update table params
                $scope.updateTotals(response.data.count);
                $scope.loading = false;
                return $scope.list_items;
              }, function (response) {
                $scope.loading = false;
                console.error(response);
              });
          // $scope.getGroups();
        }
      });

      //                              --- ACTIONS

      let modalIsOpen = false;
      $scope.delete_success = null;
      $scope.del_suc_null = function () {
        $scope.delete_success = null
      };
      $scope.editProcess = function (isEdit) {
        console.log(isEdit ? "Edit PROCESS" : "Create new PROCESS");
        if (modalIsOpen) {
          console.log("tried second NEW Process");
          return;
        }
        modalIsOpen = true;
        // initProcessEdit = angular.copy($scope.process);
        ngDialog.openConfirm({
          template: 'app/views/modals/edit-dialog-process.html',
          className: 'ngdialog-theme-default',
          closeByEscape: false,
          closeByDocument: false,
          // width: 768f,
          data: {
            process: isEdit ? $scope.selectedUnit.process : {},
            mode: 'openConfirm',
            operation: isEdit ? 'edit' : 'create'
            // result: 'selectedUnitEditProcess',
          }
        }).then(
            function (data) {
              modalIsOpen = false;
              console.log(isEdit ? "Edit ProcessModalInstance" : "Create ProcessModalInstance", data);
              $scope.tableParams.reload();
            }, function (reason) {
              $scope.tableParams.reload();
              modalIsOpen = false;
              console.log('Modal promise rejected. Reason: ', reason);
            })
      };
      $scope.delete = function (process, batchKey) {
        $rootScope.WGProcesses.changed = true;
        $http.delete('api/dashboard/processes/' + process.id + '/').then(
            function (response) {
              $rootScope.WGProcesses.changed = true;
              process.active = false;
              // process.$edit = false;

              //  -Array para definir os items seleccionados
              var obj = _.find($scope.selection, {id: process.id});
              if (angular.isDefined(obj)) {
                $scope.selection = _.without($scope.selection, obj) as IPlace[] | IUnit[] | IDevice[] | IProcess[] | IBatch[];
              }

              //  -Array para popular a dataTable
              obj = _.find($scope.list_items, {id: process.id});
              if (angular.isDefined(obj)) {
                $scope.list_items.splice(_.indexOf($scope.list_items, obj), 1);
                //$scope.users = _.without($scope.users, obj);
                $scope.checkboxes.items[process.id] = false;
                $scope.updateTotals($scope.tableParams.total() - 1);
                if ($scope.list_items.length == 0 || $scope.selection.length == 0) {
                  $scope.reload();
                }
              }

              if (batchKey) {
                //  -Batch User Feedback
                $scope.batch.items[batchKey] = true;
              }
            }, function (response) {

              if (batchKey) {
                //  -Batch User Feedback
                $scope.batch.items[batchKey] = false;
              }
              console.error(response);
            });
      };

      $scope.see_process_run = function (process) {
        if (modalIsOpen) {
          console.log("tried second modal");
          return;
        }
        modalIsOpen = true;
        let run_length, ened, end_time;
        let ini = new Date(process.started_at).getTime();
        if (process.active) {
          ened = new Date().getTime();
        } else {
          end_time = new Date(process.ended_at);
          ened = end_time.getTime();
        }
        run_length = ened - ini;

        let seeRunDialog = ngDialog.open({
          template: 'app/views/modals/see_process_run.html',
          controller: 'SeeRunStopController',
          className: 'ngdialog-theme-default',
          data: {
            isMany: false,
            id: process.id,
            name: process.name,
            process: process,
            start_time: new Date(process.started_at),
            isRunning: process.active,
            run_length: run_length,
            end_time: end_time,
            update: function () {
              $scope.tableParams.reload();
            }
          }
          // controller: 'CreateProcessModalInstance',
          // scope: $scope
        });
        seeRunDialog.closePromise.then(
            function (data) {
              console.log('See Run Done. data:', data);
              modalIsOpen = false;
              // $scope.tableParams.reload();
            });
      };

      //                              --- FUNCTIONS
      $scope.updateTotals = function (total) {
        $scope.tableParams.total(total);
        $scope.showingFrom = ($scope.tableParams.page() - 1) * $scope.tableParams.count() + 1;
        if ($scope.showingFrom > $scope.list_items.length)
          $scope.showingFrom = $scope.list_items.length;
        $scope.showingTo = $scope.showingFrom - 1 + $scope.tableParams.count();
        if ($scope.showingTo > $scope.list_items.length)
          $scope.showingTo = $scope.list_items.length;
        if ($scope.showingTo > $scope.tableParams.total())
          $scope.showingTo = $scope.tableParams.total();
      };

      $scope.reload = function (force) {
        // $state.reload();
        $rootScope.$broadcast('adminReload');
      };
      // watch for new search value after a few ms,
      // so that the table can be reloaded
      // var promiseTimeoutSearch: ng.IPromise<any>;
      // $scope.$watch('search.all', function (_new_value, _old_value) {
      //   if (_new_value === _old_value) {
      //     return;
      //   }
      //   $timeout.cancel(promiseTimeoutSearch);
      //   console.log("SEARCH:", _new_value, $scope.search);
      //   // $scope.tableParams.filter({$:$scope.search.all});
      //   promiseTimeoutSearch = $timeout(function () {
      //     $scope.tableParams.reload();
      //   }, 350);
      // });

      $scope.$on('adminReload', function (event, args) {
        console.log('adminReload', 'admin-elements');
        $scope.tableParams.reload();
      });
      var promiseTimeoutLoading: ng.IPromise<any>;
      $scope.$watch('tableParams.settings().$loading', function (value) {
        // console.log("$watch('tableParams.settings().$loading'", $scope);
        $timeout.cancel(promiseTimeoutLoading);
        if (value) {
          promiseTimeoutLoading = $timeout(function () {
            $scope.loading = true;
          }, 1000);
        } else {
          $scope.loading = false;
        }
      });
      // $scope.spitData = function () {
      //   console.log("DevProcesses SD scope:", $scope);
      // }
    }]);

  App.controller("SeeRunStopController", ['$rootScope', '$scope', '$http', '$translate', 'ngDialog', '$timeout',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $translate: ng.translate.ITranslateService, ngDialog: ng.dialog.IDialogService, $timeout) {
      // create/edit process modal
      // TODO: both on ManageProcesses, and newOverview, ngDialogData comes with the attribute [process], and several process atributes separated. it should be cleaned.

      $scope.feedback = {
        state: null,
        states: {success: 'success', error: 'error', loading: 'loading'},
        items: []
      };
      $scope.running_processes = [];
      $scope.stopped_processes = [];
      $scope.list_items = []; // list_items to analyze/stop

      if ($scope.ngDialogData.process) // Legacy, support for ManageProcesses, and newOverView
        $scope.list_items.push($scope.ngDialogData.process)
      // legacy reasons, while refactoring
      angular.forEach($scope.ngDialogData.procs, function (process) {
        $scope.list_items.push(process);
      });
      angular.forEach($scope.ngDialogData.processes, function (process) {
        $scope.list_items.push(process);
      });

      angular.forEach($scope.list_items, function (process, key) {
        process.started_at = new Date(process.started_at);
        process.batching_key = key;
        if (process.active || process.archived === false) {
          $scope.feedback.items[key] = null;
          $scope.running_processes.push(process);


        } else {
          $scope.feedback.items[key] = 'none';
          $scope.stopped_processes.push(process);


        }

      });

      // minimum time for batch stopping
      $scope.minDateMany = new Date();
      if ($scope.running_processes && $scope.running_processes.length) {
        let b = _.maxBy($scope.running_processes, (x: IProcess) => {
          return new Date(x.started_at).getTime()
        });
        $scope.minDateMany = new Date(b.started_at);
        console.log("min date for batch stopping is:", $scope.minDateMany);
      }

      $scope.$watch('feedback.items', function (_new_value, _old_value) {
        if (_new_value === _old_value) {
          return;
        }

        let b_items = $scope.feedback.items;
        let last_idx = b_items.length - 1;
        console.log("Batch.items changed:", $scope.feedback.items,
            "state:", $scope.feedback.state);
        let n_of_runng_procs = $scope.running_processes.length;
        let n_of_results = _.without(b_items, 'none', null).length;
        if (b_items.length > 0 && n_of_runng_procs === n_of_results) {
          let k = _.indexOf($scope.feedback.items, false);
          if (k > -1) {
            $scope.feedback.state = $scope.feedback.states.error;
          } else {
            // All "stop actions" were SUCCESS
            $scope.feedback.state = $scope.feedback.states.success;

            $timeout(function () {
              if ($scope.confirm)
                $scope.confirm($scope.time_to_end);
              else if ($scope.closeThisDialog)
                $scope.closeThisDialog($scope.time_to_end);
              else
                $scope.close(0);
            }, 600 + ($scope.free_sensor ? 2000 : 0));
          }
          console.log("Stop batch success!!");
        }
      }, true);

      $scope.time_to_end = null;
      $scope.ngDialogData.free_sensor = null;
      $scope.ngDialogData.free_sensor_success = null;

      $scope.stop = function () {
        $scope.feedback.state = $scope.feedback.states.loading;

        angular.forEach($scope.running_processes, function (process) {
          doStop(process);
        });
      };

      function doStop(process) {
        if (!$scope.time_to_end) {
          console.error("Missing dates, can't stop process", $scope.time_to_end);
          return
        }

        let dat = new Date($scope.time_to_end);
        let dataset = {
          ended_at: dat,
          finished: true
        };

        // if(1){
        //     console.log("**************INTERRUPTED******************");
        //     return
        // }

        $rootScope.WGProcesses.changed = true;
        $http.patch<IProcess>('api/dashboard/processes/' + process.id + '/', dataset).then(
            function (response) {
              $rootScope.WGProcesses.changed = true;
              console.log("Stop success. response:", response);
              $scope.ngDialogData.isRunning = false;
              process.active = false;
              if (false && $scope.ngDialogData.free_sensor) {
                if (process.unit?.devices?.length)
                  freeSensor(process.unit.devices[0].id, response.data);
                if (process.units?.[0]?.devices.length)
                  freeSensor(process.units[0].devices[0].id, response.data);
              } else {
                updateTableAtManage(response);
              }
              if (process.batching_key >= 0) { //  -Batch User Feedback
                $scope.feedback.items[process.batching_key] = true;
              }
            }, function (response) {
              console.log("Stop fail. response:", response);
              $scope.ngDialogData.isRunning = 'error';
              if (process.batching_key >= 0) { //  -Batch User Feedback
                $scope.feedback.items[process.batching_key] = false;
              }
            });
      }

      function updateTableAtManage(response) {
        // update table @ManageProcesses
        let d = $scope.ngDialogData;
        if (angular.isDefined(d.update)) {
          let device_id = false;
          // here we consider that processes only have one unit,
          // and units only one device
          // if($state.current.url.slice(1)!=='list'){
          let p = d.process;
          if (p.units.length > 0)
            if (p.units[0].devices.length > 0)
              device_id = p.units[0].devices[0].id;
          // }

          d.update(response.data, device_id);
        }
      }

      /**
       * Disassociate Unit from Device
       * @param device_ID
       * @param dataProcess
       */
      function freeSensor(device_ID, dataProcess) {
        //TODO http request to remove unit from device has returned 404. Device id is correct
        console.log("Freeing Sensor.", "device_ID", device_ID, "dataProcess", dataProcess);
        if (device_ID === null || device_ID || !angular.isDefined(device_ID))
          console.log("error#73738493849");

        $rootScope.WGDevices.changed = true;
        $http.patch<IDevice>('api/dashboard/devices/' + device_ID + '/', {unit_id: null}).then(
            function (response) {
              $rootScope.WGDevices.changed = true;
              console.log("Sensor freeded SUCCESS:", response.data);
              // parentRet.loading = false;
              // parentRet.result = 'success';

              updateTableAtManage(dataProcess);
              $scope.ngDialogData.free_sensor_success = true;

              return true;
            }, function (response) {
              console.error(response);
              $scope.ngDialogData.free_sensor_success = false;
              return false;
            });
      }

      $scope.spitData = function () {
        console.log("SeeRunStopController SD scope:", $scope);
      };

      $scope.dateTimeNow = function () {
        return new Date();
      };
    }]);
}