namespace wg {
  class CreateBatchAlarms {
    public devices: IDevice[] = [];
    /* holds the alarm info to be created for all the devices */
    public alarmMaster: IAlarm
    public masterRulesErrors: Map<string, string> = new Map();
    public alarmForm: any;
    public upstreamError: string;
    public loading: boolean = false

    public static $inject = ['$scope', 'WGApiData', '$translate', 'AuthService', '$rootScope'];

    constructor(private $scope: any, private WGApiData: wg.WGApiData, private $translate: ng.translate.ITranslateService, private AuthService: wg.IAuthService, private $rootScope: any) {
    }

    public $onInit() {
      this.devices = this.$scope.ngDialogData.devices;
      this.alarmMaster = {
        status: 1,
        device: null,
        name: '',
        rules: [],
        rearm_interval: wg.availableRearmOptions.find((option) => option.key == 'auto').secondsDuration,
        rearm_interval_option: wg.availableRearmOptions.find((option) => option.key == 'auto'),
        rulesErrors: new Map(),
        actions: {},
        notification_targets: []
      }

      this.AuthService.me().then((response: angular.IHttpPromiseCallbackArg<IUser>) => {
      })
    }

    public saveAlarms(alarmToSave: IAlarm) {
      //keep the form from submitting until the errors are fixed
      this.$rootScope.$emit('showErrors');
      if (this.alarmForm.$invalid || this.WGApiData.WGAlarms.checkAlarmForErrors(alarmToSave)) {
        //mark the form elements as dirty so that the errors can be shown
        this.alarmForm.$setDirty();
        Object.keys(this.alarmForm).forEach((key) => {
          if (this.alarmForm[key] && this.alarmForm[key].$setTouched) {
            this.alarmForm[key].$setTouched();
          }
        })
        return;
      }

      this.loading = true;

      const done: boolean[] = this.devices.map((device) => {
        return false
      })
      const callback = (error: string, device: IDevice) => {
        if (error) {
          this.upstreamError = device.name +
              ":\n" + this.$translate('app.modal.alarm.ERROR_SAVING') +
              ":\n" + error
        }
        done[this.devices.indexOf(device)] = true
        if (done.every((d) => d)) {
          this.loading = false
          this.$scope.closeThisDialog()
        }
      }
      for (const device of this.devices) {
        //@ts-ignore
        const alarm: IAlarm = {
          ...this.alarmMaster,
          device: device,
          // @ts-ignore
          notification_targets: _.map(this.alarmMaster.notification_targets, 'id'),
        }
        this.WGApiData.WGAlarms.saveAlarm(alarm, (e) => callback(e, device))
      }
    }

  }

  App.controller('CreateBatchAlarms', CreateBatchAlarms);
}