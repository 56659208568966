/**
 * Created by pmec on 20/11/15. copied by mateus on 21/6/18
 */
namespace wg {
  App.controller('CrontabReportModalInstance', ['$rootScope', '$scope', '$http', '$filter', '$timeout', 'ngDialog', 'moment',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $filter: ng.IFilterService, $timeout: ng.ITimeoutService, ngDialog: ng.dialog.IDialogService, moment) {
      activate();

      function activate() {
        //$scope.device = $scope.ngDialogData.device;
        //      ---     VARIABLES       ----        ***
        $scope.reportID = $scope.ngDialogData.report_id; //*/
        //var ret = 'crontabRet';
        // if (angular.isDefined($scope.ngDialogData.result)) {
        //   ret = $scope.ngDialogData.result;
        // }
        let curDate = new Date();
        $scope.data = {
          first_time: (new Date()).getTime(),
          availableOptions: [
            {id: '1', name: '1h', duration: 1, key: 'hours', crontab: '0 0 * * * *'},
            {id: '2', name: '2h', duration: 2, key: 'hours', crontab: '0 0 */2 * * *'},
            {id: '3', name: '4h', duration: 4, key: 'hours', crontab: '0 0 */4 * * *'},
            {id: '4', name: '6h', duration: 6, key: 'hours', crontab: '0 0 */6 * * *'},
            {id: '5', name: '8h', duration: 8, key: 'hours', crontab: '0 0 */8 * * *'},
            {id: '6', name: '12h', duration: 12, key: 'hours', crontab: '0 0 */12 * * *'},
            {id: '7', name: '24h', duration: 24, key: 'hours', crontab: '0 0 0 * * *'},     // 1 dia
            {id: '8', name: '2d', duration: 48, key: 'hours', crontab: '0 0 0 */2 * *'},   // 2 dias
            {id: '9', name: '5d', duration: 120, key: 'hours', crontab: '0 0 0 */5 * *'},  // 5 dias
            {id: '10', name: '1s', duration: 1, key: 'weeks', crontab: '0 0 0 * * 0'}     // 1 semana
          ],
          selectedOption: {id: '10', name: '1s', duration: 138, key: 'hours', crontab: '0 0 0 * * 0'}, //This sets the default value of the select in the ui
          time: null,
          iterations: 5,
          error: false
        };

        //      ---     INIT            ----        ***
        function init() {
          $scope.data.availableOptions.forEach(
              function (value, key) {
                if ($scope.ngDialogData.report_crontab === value.crontab) {
                  $scope.data.selectedOption = $scope.data.availableOptions[key];
                }
              });
          angular.forEach(
              $scope.data.availableOptions,
              function (option) {
                // @ts-ignore
                option.humanDuration = $filter('amDurationFormat')(option.duration, option.key);
                option.millisDuration = moment.duration(option.duration, option.key);
              });
        }

        init();
        parseData();

        //      ---     FUNCTIONS       ----        ***
        $scope.selectChange = function () {
          parseData();
        };

        function parseData() {
          $scope.parserNumTests = $scope.data.iterations || 5;
          $scope.parsed_data = [];
          $scope.parser = CronParser.parseExpression(
              $scope.data.selectedOption.crontab,
              {startDate: $scope.data.first_time}
          );
          for (let i = 0; i < $scope.parserNumTests; i++) {
            $scope.parsed_data.push($scope.parser.next())
          }
        }

        function passDataToParent() {
          $scope.$parent.new_report.first_issue.timestamp = $scope.data.first_time;
          $scope.$parent.new_report.first_issue.date = new Date($scope.data.first_time);
          $scope.$parent.new_report.issue_interval.crontab = $scope.data.selectedOption.crontab;
          $scope.$parent.new_report.issue_interval.humanDuration = filterHumanDurationString($scope.data.selectedOption.id);
        }

        //      ---     SAVE & CLOSE       ----        ***
        $scope.save = function (data) {
          console.log("try to save: NO! ahahah HAHAH, just joking, it's saved, but only on cache. Work that Backend little dev!");
          passDataToParent();
          $scope.closeThisDialog();

        };

        //      ---     UTILS       ----        ***

        function filterHumanDurationString(str) {
          return (str === '1') ? '1 hour' : (str === '7') ? 'day' : (str === '10') ? 'week' : str;
        }

        $scope.spillBeans = function () {
          console.log("spillBeans: first_time", new Date($scope.data.first_time), "humanDuration", $scope.data.selectedOption.humanDuration, "$scope", $scope);
        };
        $scope.dateTimeNow = function () {
          $scope.data.first_time = new Date().getTime();
        };


      }
    }]);
}