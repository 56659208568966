/**=========================================================
 * Module: access-register.js
 * Demo for register account api
 =========================================================*/
namespace wg {
  App.controller('AccountConfirmFormController', ['$scope', '$rootScope', '$http', '$state', '$timeout', 'AuthService',
    function ($scope, $rootScope: IRootScope, $http: ng.IHttpService, $state: _IState, $timeout: ng.ITimeoutService, AuthService: IAuthService) {
      if (AuthService.isLogged) {
        $state.go(AuthService.homeState);
        return;
      }

      $scope.retStatus = {
        loading: false,
        result: null,
        message: '',
      } as IReturnResult;

      $scope.account_confirm = function () {
        if ($scope.retStatus.loading)
          return;
        $scope.retStatus.message = '';
        $scope.retStatus.loading = true;
        $scope.retStatus.result = null;

        let timeoutRequest = $timeout(function () {
          $scope.retStatus.loading = false;
          $scope.retStatus.message = 'app.error.TIMEOUT';
          $scope.retStatus.result = "error";
        }, 10000);

        AuthService.account_confirmation($state.params["key"]).then(
            function (data) {
              $scope.retStatus.message = data.detail;
              if (AuthService.response.status === 200) {
                $scope.retStatus.result = "success";

                $timeout(() => {
                  $state.transitionTo(AuthService.loginState,
                      {
                        detail: data.detail,
                        status: AuthService.response.status
                      },
                      {
                        location: 'replace', // This makes it update URL and replace current history entry, or not.
                        inherit: false,
                        notify: true, // false makes it not angular' $digest
                        reload: true,
                      }
                  )
                  //
                  // if (reload) {
                  //   setTimeout(function () {
                  //     window.location.reload(); // Ensures page is reloaded. If assign does it, this is not executed
                  //   }, 10);
                  // }
                }, 10);
                // $state.go(AuthService.loginState
                //     , {
                //       detail: data.detail,
                //       status: AuthService.response.status
                //     }
                //     , {location: 'replace', inherit: false, notify: true, reload: true});
              } else {
                $scope.retStatus.result = "error";
              }
            }, function (data) {
              if (data.non_field_errors) {
                $scope.retStatus.message = data.non_field_errors[0];
              } else {
                $scope.retStatus.message = 'pages.account_confirm.SERVERREQUEST';
              }
              $scope.retStatus.result = "error";
            })
            .finally(function () {
              $scope.retStatus.loading = false;
              $timeout.cancel(timeoutRequest);
            });


      };
    }]);
}