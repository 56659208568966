namespace wg {
  App.controller('shareAccountCtrl', ['$scope', 'AuthService', 'APIUtils',
    function ($scope, AuthService: IAuthService, APIUtils: APIUtils) {
      if (WG_debug) console.log('Share Account controller');

      $scope.data = {};

      $scope.save = function (data: { username: string, email: string, readonly: boolean, comment: string }) {
        console.log('Share Account submitted', data);
        if (_.isEmpty(data.username) || _.isEmpty(data.email)) {
          $scope.closeModal("Missing required data");
          return;
        }

        let title = "Share Account, " + _.toString(AuthService.user.username) + " -> " + _.toString(data.username);
        let message = "Username: " + _.toString(data.username);
        message += "\n\r \t Email: " + _.toString(data.email);
        message += "\n\r \t ReadOnly: " + _.toString(data.readonly === true);
        if (AuthService.view_as_owner?.id != AuthService.user?.id) {
          message += "\n\r \t Viewing as user: " + _.toString(AuthService.view_as_owner.username) + " - " + _.toString(AuthService.view_as_owner.id);
        }
        if (data.comment) {
          message += "\n\r \t User comment: " + _.toString(data.comment);
        }

        if (WG_debug) console.log('Share Account saving:', title, message);
        APIUtils.save_feedback_with_attachments(title, message, null, null);

        $scope.closeModal();
      }

      $scope.closeModal = function (fail_reason = null) {
        if (!fail_reason && $scope.confirm)
          $scope.confirm();
        else if ($scope.closeThisDialog)
          $scope.closeThisDialog(fail_reason);
        else if ($scope.close)
          $scope.close(fail_reason);
      }

    }]);
}