/**
 * Created by mateus on 1-6-18
 */

App.controller("FAQController", ['$scope', '$translate', '$translateLocalStorage', '$http',
  function ($scope, $translate, $translateLocalStorage, $http) {
//TODO: make excel file automated to generate a json
//TODO: replace translatable strings
    $scope.faqer = {};
    
    function initit() {
      $http.get('app/i18n/en-GB.json').then(
          function (response) {
            console.log("http.GET", response);
            let faquer = response.data.app.faq;
            let seccoes = [];
            // let questoes = [];
            _.forEach(faquer.sections,
                function (valueS, keyS) {
                  // let n = parseInt[key.slice(-1)];
                  let each_set_que = [];
                  _.forEach(valueS.questions,
                      function (valueQ, keyQ) {
                        let each_que = {
                          pergunta: valueQ.PERGUNTA,
                          resposta: valueQ.RESPOSTA
                        };
                        each_set_que[parseInt(keyQ.slice(-1) - 1)] = each_que;
                      });
                  let each_sec = {
                    title: valueS.TITLE,
                    description: valueS.DESCRIPTION,
                    questions: each_set_que
                  };
                  seccoes[parseInt(keyS.slice(-1) - 1)] = each_sec;
                });
            faquer.sections = seccoes;
            console.log("faquer", faquer);
            $scope.faqer = faquer;
          });
    }
    
    initit();
    
    //\\ DEV //\\
    
    $scope.spitData = function () {
      // $translate
      console.log("faq SD : ", $scope);
      console.log("$translate", $translate);
      // console.log("$translateLocalStorage",$translateLocalStorage);
      // console.log("$translateLocalStorage.get()",$translateLocalStorage.get());
      console.log("$translate.storage().get()",
          $translate.storage().get());
      
      
      // console.log("$translate.getTranslationTable()",
      //   $translate.getTranslationTable());
      
      // console.log("$translationTable",$translationTable);
    };
  }]);