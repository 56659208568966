namespace wg {

  export class CreateOrEditSingleAlarm {
    public loading: boolean = true;
    public isEditMode: boolean = false;
    public upstreamError: string;
    public parentCollection: IAlarm[] = []; // array that contains the alarm/alarm_template
    public alarmOriginal: IAlarm; //can be an alarm or an alarm_template
    public alarmShortLived: IAlarm; //can be an alarm or an alarm_template
    public alarmForm: any;
    public isAlarmTemplate: boolean = false;
    static $inject = ['$scope', 'ngDialog', 'WGApiData', 'AuthService', '$http', '$rootScope'];

    public constructor(private $scope, private ngDialog: ng.dialog.IDialogService, private WGApiData: WGApiData, private AuthService: IAuthService, private $http: ng.IHttpService, private $rootScope: any) {
      this.isAlarmTemplate = $scope.ngDialogData.isAlarmTemplate || false;
      this.parentCollection = $scope.ngDialogData.parentCollection || [];
      this.isEditMode = $scope.ngDialogData.isEditMode || false;


      this.alarmOriginal = $scope.ngDialogData.alarm || {
        // id: null,
        name: "new preset",
        status: 1,
        active: true,
        rules: this.isAlarmTemplate ? undefined : [],
        rule_templates: this.isAlarmTemplate ? [] : undefined,
        actions: {email: false, sms: false},
        rearm_interval: wg.availableRearmOptions[0].secondsDuration,
        rearm_interval_option: wg.availableRearmOptions[0],
        notification_targets: [],
        sensor: undefined,
        rulesErrors: new Map()
      }
      if (!this.alarmOriginal.rulesErrors) {
        this.alarmOriginal.rulesErrors = new Map();
      }
      this.alarmOriginal.active = this.alarmOriginal.status > 0;
      this.alarmShortLived = _.cloneDeep(this.alarmOriginal);
      //@ts-ignore some fields need to be renamed to match what JADE expects
      this.alarmShortLived.rules = (this.isAlarmTemplate ? this.alarmShortLived.rule_templates : this.alarmShortLived.rules) || [];
      //normalize rules, to match what JADE expects
      this.alarmShortLived.rules.forEach((rule) => {
        rule.type_id = wg.TASK_RULE_TYPES.parameter_reached.id;

        if (rule.parameter?.['id']) {
          rule.parameter = WGApiData.WGSensors?.sensors_id?.[rule.parameter['id']];
        } else if (rule.parameter && _.isNumber(rule.parameter)) {
          rule.parameter = WGApiData.WGSensors?.sensors_id?.[rule.parameter];
        } else if (rule.parameter && _.isString(rule.parameter)) {
          rule.parameter = WGApiData.WGSensors?.sensors_name?.[rule.parameter];
        } else {
          console.error("Rule parameter is not valid", rule.parameter);
        }
        rule.value_conv = convert(rule.value, rule.parameter?.conversion?.id, null, false);
        if (!_.isNil(rule.parameter?.configs?.decimals)) {
          rule.value_conv = Math.round(rule.value_conv * Math.pow(10, rule.parameter.configs.decimals + 1)) / Math.pow(10, rule.parameter.configs.decimals + 1);
        }
      })
    }

    public $onInit() {
      this.loading = false;

    }


    public saveAlarm() {
      // error and loading handling
      this.$rootScope.$emit('showErrors');
      if (this.alarmForm.$invalid || this.WGApiData.WGAlarms.checkAlarmForErrors(this.alarmShortLived)) {
        //mark the form elements as dirty so that the errors can be shown
        this.alarmForm.$setDirty();
        Object.keys(this.alarmForm).forEach((key) => {
          if (this.alarmForm[key] && this.alarmForm[key].$setTouched) {
            this.alarmForm[key].$setTouched();
          }
        })
        return;
      }
      this.loading = true;

      //@ts-ignore
      const objectAdded = {
        id: this.alarmShortLived.id,
        name: this.alarmShortLived.name,
        sensor: this.alarmShortLived.sensor,
        rearm_interval: this.alarmShortLived.rearm_interval,
        rules: !this.isAlarmTemplate ? this.alarmShortLived.rules : undefined,
        rule_templates: this.isAlarmTemplate ? this.alarmShortLived.rule_templates : undefined,
        actions: this.alarmShortLived.actions,
        notification_targets: _.map(this.alarmShortLived.notification_targets, 'id'),
        auto_apply: this.alarmShortLived.auto_apply,
      } as Partial<IAlarm>;

      // If active changed/toggled, update status
      if (!!this.alarmShortLived.active != (this.alarmShortLived.status > 0)) {
        objectAdded.status = this.alarmShortLived.active ? 1 : 0;
      }

      this.WGApiData.WGAlarms.saveAlarm(objectAdded, (error) => {

            if (error) {
              this.upstreamError = error
              this.loading = false;
              return;
            }

            //convert notification targets back to objects ?
            objectAdded.notification_targets = this.WGApiData.WGAlarms.extractNotificationTargets(objectAdded.notification_targets);

            if (this.isEditMode) {
              Object.assign(this.alarmOriginal, objectAdded);
              if (this.isAlarmTemplate) {
                this.alarmOriginal.rules = this.alarmOriginal.rule_templates;
              } else {
                this.alarmOriginal.rule_templates = this.alarmOriginal.rules;
              }
              this.alarmOriginal.active = !!(this.alarmOriginal.status != 0)

            } else {
              this.parentCollection.push(objectAdded as IAlarm);
            }

            this.ngDialog.closeAll("alarm_preset_saved");
            this.loading = false;

          },
          this.isAlarmTemplate)

    }

  }

  App.controller('CreateOrEditSingleAlarm', CreateOrEditSingleAlarm);
}

