/**=========================================================
 * Module: admin-groups.js
 * Groups management
 =========================================================*/
namespace wg {
  App.controller('AdminGroupsController', ['$rootScope', '$scope', '$http', '$filter', '$q', '$timeout', 'ngTableParams', 'ngDialog',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $filter: ng.IFilterService, $q, $timeout: ng.ITimeoutService, ngTableParams: NgTable.ITableParamsConstructor<any>, ngDialog: ng.dialog.IDialogService) {
      $scope.groups = [];
      $scope.showingFrom = 0;
      $scope.showingTo = 0;
      $scope.search = '';
      $scope.loading = false;
      $scope.actions = {action: null, delete: 'DELETE'};
      $scope.filterUsers = [{'id': '', 'title': ''}];
      $scope.editUsers = [];
      $scope.checkboxes = {checked: false, items: {}};
      $scope.selection = [];

      $scope.getUsers = function () {
        $http.get<IUser[]>('api/auth/users/all/', {}).then(
            function (response) {
              var data = response.data;
              $scope.editUsers = data;
              $scope.filterUsers.length = 0;
              $scope.filterUsers.push({'id': '', 'title': ''});
              angular.forEach(data, function (user) {
                $scope.filterUsers.push({'id': user.username, 'title': user.username});
              });
            }, function (response) {
              console.error(response);
            });
      };

      $scope.updateTotals = function (total) {
        // if(WG_debug) console.log('updateTotals', total);
        $scope.tableParams.total(total);
        $scope.showingFrom = ($scope.tableParams.page() - 1) * $scope.tableParams.count() + 1;
        if ($scope.showingFrom > total)
          $scope.showingFrom = total;
        $scope.showingTo = $scope.showingFrom - 1 + $scope.tableParams.count();
        if ($scope.showingTo > total)
          $scope.showingTo = total;
      };

      $scope.tableParams = new ngTableParams({
        page: 1,            // show first page
        count: 10,          // count per page
        sorting: {
          name: 'asc'       // initial sorting
        },
        filter: {}
      }, {
        total: 0, // length of data
        getData: (params) => {
          $scope.loading = true;
          var search = $scope.search;
          var filter = '';
          var obj = params.filter();
          for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (angular.isDefined(obj[key]) && obj[key] !== '')
                filter += key + '=' + obj[key] + '&';
            }
          }
          var query = '';
          if (params.orderBy() + '' !== '') {
            query += '&ordering=' + params.orderBy();
          }
          if (angular.isDefined(search) && search !== '') {
            query += '&search=' + search;
          } else {
            query += '&page=' + params.page();
          }
          if (filter !== '') {
            filter = filter.substring(0, filter.length - 1);
            query += '&' + filter;
          }
          $scope.getUsers();
          return $http.get<IDataResponse<IGroup>>('api/auth/groups/?page_size=' + params.count() + query, {}).then(
              (response) => {
                $scope.groups = response.data.results;
                $scope.changeAllSelection(false);
                //angular.forEach(data, function(group) {
                //  var obj = _.find($scope.groups, {id: group.id});
                //  if (angular.isDefined(obj)) {
                //    angular.extend(obj, group);
                //    $scope.checkboxes.items[group.id] = false;
                //  } else {
                //    $scope.groups.push(group);
                //  }
                //});
                // update table params
                $scope.loading = false;
                $scope.updateTotals(response.data.count);
                return $scope.groups;
              }, function (response) {
                $scope.loading = false;
                console.error(response);
              });
        }
      });

      $scope.reload = function () {
        $rootScope.$broadcast('adminReload');
      };

      $scope.$on('adminReload', function (event, args) {
        console.log('adminReload', 'admin-groups');
        $scope.tableParams.reload();
      });

      $scope.changeSelection = function (group) {
        var obj = _.find($scope.selection, {id: group.id});
        if (angular.isDefined(obj)) {
          $scope.selection = _.without($scope.selection, group);
        } else {
          $scope.selection.push(group);
        }
      };

      $scope.newGroup = function () {
        var dialog = ngDialog.open({
          template: 'adminGroupNewDialog',
          data: {}
        });
        dialog.closePromise.then(
            function (dialogData) {
              $scope.reload();
            });
      };

      $scope.edit = function (group) {
        $scope.changeAllSelection(false);
        group.editUsers = objArrayDiff(group.users, $scope.editUsers, 'id');
        //user.editUsersNames = _.map($scope.editUsers, 'username');
        group.usersNames = _.map(group.editUsers, 'username');
        //user.selectedUsersNames = _.map(group.groups, 'username');
        group.$edit = true;
      };

      $scope.save = function (group) {
        $http.patch<IGroup>('api/auth/groups/' + group.id + '/', group).then(
            function (response) {
              group.$edit = false;
              angular.extend(group, response.data);
            }, function (response) {
              console.error(response);
              if (response.status === 400 && response.data) {
                for (var key in response.data) {
                  var specificField = $('#id-' + key + '-' + group.id).parsley();
                  var i = 0;
                  angular.forEach(response.data[key], function (error) {
                    try {
                      specificField.addError('error-' + i, {message: error});
                      // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                    } catch (e) {
                    }
                    i++;
                  });
                }
              }
            });
      };

      $scope.delete = function (group) {
        $http.delete('api/auth/groups/' + group.id + '/', group).then(
            function (response) {
              group.$edit = false;
              var obj = _.find($scope.selection, {id: group.id});
              if (angular.isDefined(obj)) {
                $scope.selection = _.without($scope.selection, obj);
              }
              obj = _.find($scope.groups, {id: group.id});
              if (angular.isDefined(obj)) {
                $scope.groups.splice(_.indexOf($scope.groups, obj), 1);
                //$scope.groups = _.without($scope.groups, obj);
                $scope.checkboxes.items[group.id] = false;
                $scope.updateTotals($scope.tableParams.total() - 1);
                if ($scope.groups.length === 0 || $scope.selection.length === 0) {
                  $scope.reload();
                }
              }
            }, function (response) {
              console.error(response);
            });
      };

      $scope.action = function (action) {
        if (action === $scope.actions.delete) {
          angular.forEach($scope.selection, function (group) {
            $scope.delete(group);
          });
        }
      };

      $scope.addToGroup = function (group) {
        if (angular.isDefined(group.toAddName))
          group.toAdd = _.find(group.editUsers, {username: group.toAddName});
        if (!angular.isDefined(group.toAdd))
          return;
        var toAdd = group.toAdd;
        angular.extend(toAdd, {pending: true, added: false});
        $http.post('api/auth/groups/' + group.id + '/users/', {username: toAdd.username}).then(
            function (response) {
              //user.$edit = false;
              if (response.status === 201) {
                toAdd.pending = false;
                toAdd.added = true;
                group.editUsers = removeFromArray(group.editUsers, toAdd, 'id');
                group.usersNames = _.map(group.editUsers, 'username');
                //user.selectedUsersNames = _.map(group.users, 'username');
              }
            }, function (response) {
              console.error(response);
            });
        group.users.push(toAdd);
      };

      $scope.removeFromGroup = function (group, user) {
        if (!angular.isDefined(user))
          return;
        var toRemove = user;
        var obj = {};
        angular.copy(toRemove, obj);
        angular.extend(toRemove, {pending: true, removed: false});
        $http.delete('api/auth/groups/' + group.id + '/users/' + toRemove.id + '/').then(
            function (response) {
              if (response.status === 204) {
                toRemove.pending = false;
                toRemove.removed = true;
                group.editUsers.push(obj);
                group.usersNames = _.map(group.editUsers, 'username');
                //group.selectedUsersNames = _.map(group.groups, 'username');
              }
            }, function (response) {
              console.error(response);
            });
      };

      $scope.changeAllSelection = function (value) {
        $scope.checkboxes.checked = value;
        if (value) {
          angular.forEach($scope.groups, function (item) {
            if (angular.isDefined(item.id)) {
              $scope.checkboxes.items[item.id] = value;
            }
          });
          $scope.selection = $scope.groups;
        } else {
          $scope.checkboxes.items = {};
          $scope.selection = [];
        }
      };

      // watch for check all checkbox
      $scope.$watch('checkboxes.checked', function (value) {
        $scope.changeAllSelection(value)
      });

      // watch for data checkboxes
      $scope.$watch('checkboxes.items', function (values) {
        if (!$scope.groups) {
          return;
        }
        var checked = 0, unchecked = 0;
        var total = $scope.groups.length;
        angular.forEach($scope.groups, function (item) {
          if ($scope.checkboxes.items[item.id]) {
            checked++;
          } else {
            unchecked++;
          }
        });
        if (total === 0) {
          $scope.checkboxes.checked = false;
        } else if ((unchecked === 0) || (checked === 0)) {
          $scope.checkboxes.checked = (checked === total);
        }
        // grayed checkbox
        angular.element(document.getElementById("select_all")).prop("indeterminate", (checked !== 0 && unchecked !== 0));
      }, true);

      // watch for new search value after 750 ms, so that the table can be reloaded
      // var promiseTimeoutSearch;
      $scope.$watch('search', function (value) {
        // $timeout.cancel(promiseTimeoutSearch);
        // promiseTimeoutSearch = $timeout(function () {
        $scope.tableParams.reload();
        // }, 750);
      });

      // watch if table is loading, if so only show loading animations if it takes more than 1000 ms
      // var promiseTimeoutLoading;
      // $scope.$watch('tableParams.settings().$loading', function (value) {
      //   $timeout.cancel(promiseTimeoutLoading);
      //   if (value) {
      //     promiseTimeoutLoading = $timeout(function () {
      //       $scope.loading = true;
      //     }, 1000);
      //   } else {
      //     $scope.loading = false;
      //   }
      // });
    }]);

  App.controller('AdminGroupsNewController', ['$scope', '$http', 'ngDialog',
    function ($scope, $http: ng.IHttpService, ngDialog: ng.dialog.IDialogService) {
      $scope.data = {};
      $scope.save = function (data) {
        $http.post('api/auth/groups/', $scope.data).then(
            function (response) {
              if (response.status === 201) {
                // @ts-ignore
                ngDialog.close();
              }
            }, function (response) {
              console.error(response);
              if (response.status === 400 && response.data) {
                for (var key in response.data) {
                  var specificField = $('#id-' + key).parsley();
                  var i = 0;
                  angular.forEach(response.data[key], function (error) {
                    try {
                      specificField.addError('error-' + i, {message: error});
                      // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                    } catch (e) {
                    }
                    i++;
                  });

                }
              }
            });
      };
    }]);
}