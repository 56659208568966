/**=========================================================
 * Module: anchor.js
 * Disables null anchor behavior
 =========================================================*/

App.directive('href', [function () {

  return {
    restrict: 'A',
    compile: function (element, attr) {
      return function (scope, element) {
        // if (attr.ngClick || attr.href === '' || attr.href === '#') {
        //   if (!element.hasClass('dropdown-toggle'))
        //     element.on('click', function (e) {
        //       console.warn("href empty preventDefault");
        //       e.preventDefault();
        //       e.stopPropagation();
        //     });
        // }
      };
    }
  };
}]);