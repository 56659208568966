/**
 * Created by mateus on 1-6-18
 */

App.controller("ReportController", ['$rootScope', '$scope', '$state', '$stateParams', '$translate', '$filter', 'ngDialog', function ($rootScope, $scope, $state, $stateParams, $translate, $filter, ngDialog) {
// App.controller("ReportController", ['$scope', '$translate', '$filter', 'ngDialog', function ($scope, $translate, $filter, ngDialog) {
// App.controller("ReportController", ['$scope', '$translate', function ($scope, $translate){
// TODO: include search for choosing devices on the report model
// TO-DO: fix responsive wrapping in device list - Review Device List
// TO-DO: https://davidwells.io/snippets/disable-scrolling-with-javascript/
// TODO: $watch - calculate means
  
  $scope.getNewReports = function () {
    console.log("getNewReport function log");
  };
  $scope.newNewReport = function () {
    console.log("newNewReport function log");
    $scope.selectNewReport();
  };
  
  //      ---     VARIABLES
  let isCreate = false;
  $scope.reports = angular.copy($rootScope.reports);
  
  //      ---     INIT
  function init() {
    getReports();
  }
  
  //init();       -----------------------------------------------------------TODO: VITOR
  
  
  //      ---     ACTIONS
  $scope.NOTselectNewReport = function (selReport) {
    
    if (!angular.isDefined(selReport)) {
      isCreate = !angular.isDefined(selReport);
      selReport = {
        'id': '',
        "created_at": null,
        "last_actvity": null,
        'title': '',
        "crontab": {
          string: '0 0 10 * *',
          humanString: 'at 10h \n *'
        },
        'descritpion': '',
        'mean_interval': null,
        'is_horizontal': false,
        'observations_col': true,
        'is_active': true,
        'is_pdf2email': false,
        'parameters': [],
        "owner": {
          "id": null,
          "username": null,
          "email": null,
          "full_name": null,
          "phone_number": null,
          "home_state": null,
          "groups_count": null
        },
        "devices": []
      };
    }
    let fakeDevices = $scope.$parent.devices;
    fakeDevices.push({
      id: 665,
      name: "Sensor Fake 665",
      streams: ["QL_BN4_CH1", "IN_TEMP", "TEMP", "QL_BN4_CH3", "PRESSA_TREAT", "QL_TREAT", "QL_BN1_CH3", "QL_BN1_CH2", "QL_BN1_CH1", "QL_BN2_CH1", "QL_BN3_CH1", "SLEEP", "configs", "QL_BN3_CH2", "QL_BN2_CH3", "QL_BN3_CH3", "QL_TREAT", "IN_HUMF"],
    });
    fakeDevices.push({
      id: 667,
      name: "Sensor Fake 667",
      streams: ["DO", "IN_TEMP", "TEMP", "AQ_SAT", "PRESSA_TREAT", "QL_TREAT", "QL_BN1_CH3", "AQ_LEVEL", "SAL", "HUM", "QL_BN3_CH1", "SLEEP", "configs", "QL_BN3_CH2", "QL_BN2_CH3", "QL_BN3_CH3", "QL_TREAT", "IN_HUMF"]
    });
    ngDialog.openConfirm({
      template: 'app/views/modals/report-edit.html',
      width: 900,
      className: 'ngdialog-theme-default',
      closeByEscape: false,
      closeByDocument: false,
      controller: 'ReportModalController',
      data: {
        // isCreate: isCreate,
        report: selReport,
        available_devices: fakeDevices
      }
    })
        .then(function (new_report, isDelete) {
              //action = 0-delete || 1-save
              console.log("Check 0", $scope.reports);
              notifyUI(new_report, isDelete);
            }, function (reason) {
              console.log('Modal promise rejected. Reason: ', reason);
            }
        )
  };
  
  
  //      ---     FUNCTIONS
  function getReports() {
    $http.get('api/dashboard/reports/all/', {}).then(
        function (response) {
          $scope.reports = response.data;
          
          $scope.loading = false;
        }, function (response) {
          console.error(response);
        });
  }
  
  function notifyUI(new_report, isDelete) {
    
    if (isDelete) {
      ngDialog.open({
        template: 'app/views/modals/success.html',
        className: 'ngdialog-theme-default',
        width: 393,
        data: {
          purpose: 'report',
        },
        controller: ['', function () {
        }]
      });
      $scope.reports.splice(indx, 1);
    } else {
      if (isCreate) {
        $scope.reports.push(new_report);
      } else {
        console.log("Check 1", $scope.reports);
        let key = $scope.reports.map(a => a.id).indexOf(new_report.id);
        $scope.reports[key] = new_report;
        console.log("key", key, "Check 2", $scope.reports);
      }
      // console.log('ReportModalController', new_report, "$scope?", $scope);
    }
  }
  
  $scope.selectNewReport = function (reportId) {
    $rootScope.reportToEditID = reportId;
    $state.go('app.devices.edit-report', {});
  };
  
  $scope.spitData = function () {
    console.log("Spit Data Reports. scope", $scope);
  };
  
  
  //      ---     UTILS
  // $scope.stopScrollAtY = 0;
  // function noscroll() {
  //     window.scrollTo( 0, $scope.stopScrollAtY );
  // }
  // add listener to disable scroll behind the modal
  //         $scope.stopScrollAtY = window.scrollTop();
  // window.addEventListener('scroll', noscroll);
  // Remove listener to disable scroll
  //  window.removeEventListener('scroll', noscroll);
  //
  // function translate(obj) {
  //     return obj[$translate.use()] || obj['en-GB'] || obj;
  // }
  
  
}]);