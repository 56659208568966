namespace wg {

  interface IScope extends ng.IScope {
    tableParams: NgTableParams<IDevice>;
    list_items: IDevice[];
  }

  export class UnlicensedPageController {
    static $inject = ['$scope', '$http', 'ngDialog', 'ngTableParams', 'AuthService', '$state'];

    constructor(private $scope: IScope,
                private $http: ng.IHttpService,
                private ngDialog: ng.dialog.IDialogService,
                private ngTableParams: NgTable.ITableParamsConstructor<IDevice>,
                private AuthService: IAuthService,
                private $state: ng.ui.IStateService) {
    }

    search = {
      all: '',
      do_search: () => {
        console.log("Search:", this.search.all);
        this.$scope.tableParams.reload();
      },
    };

    // If we want only pending licenses for current Distributor, or all unlicensed devices
    pendingLicenses = false;
    loading = false;
    showingFrom = 0;
    showingTo = 0;
    error: string = undefined;

    private checkboxes = {
      items: {},
      allSelected: false,
    };

    //  ---  Multi selection
    private selection = {
      items: [],
      all_items_are_selected: false,
      // Without using shift
      // last_single_item_selected: null,
      last_item_selected: null,
      reselectFromList: () => {
        // if (WG_debug) console.log("selection reselectFromList");
        // get list of .id from selection.items using map
        let _selected_ids = _.map(this.selection.items, 'id');

        _.forEach(this.list_items, function (item) {
          if (_.includes(_selected_ids, item.id)) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
        this.selection.updateSelectedList();
      },
      updateSelectedList: () => {
        // if (WG_debug) console.log("selection ng-change");
        this.selection.items = emptyOrCreateArray(this.selection.items);
        let _tmp_all_selected = true;
        let _at_least_one_visible = false;
        // Add all visible+selected items to the arrays


        _.forEach(this.list_items, (item) => {
              if (_.isNil(item['is_visible']) || item['is_visible'] == true) {
                _at_least_one_visible = true;
                if (item.selected) {
                  this.selection.items.push(item);
                } else {
                  _tmp_all_selected = false;
                }
              }
            }
        );

        // Are all of them selected?
        this.selection.all_items_are_selected = _at_least_one_visible && _tmp_all_selected;

        // console.log("Refreshed list of selected items", this.selection.items);
      },
      selectAll: (value = null) => {
        // console.log("Setting all items to " + (this.selection.all_items_are_selected ? "Selected" : "Unselected"));
        if (value === true)
          this.selection.all_items_are_selected = true;
        else if (value === false)
          this.selection.all_items_are_selected = false;

        if (!_.isEmpty(this.list_items)) {
          for (let item of this.list_items) {
            item.selected = !!this.selection.all_items_are_selected;
          }
        }

        this.selection.updateSelectedList();
      },
      // Supporting Shift-select multiple items
      select: (item, index: number, e: JQueryEventObject) => {
        if (e?.shiftKey) {
          let _this_index = _.findIndex(this.list_items, {id: item.id});
          let _last_item_selected_index = _.findIndex(this.list_items, {id: this.selection.last_item_selected?.id});
          // let _last_single_index = _.findIndex(this.list_items, {id: this.selection.last_single_item_selected?.id});
          // if (_last_single_index == -1)
          //   _last_single_index = 0;

          // if (WG_debug) console.log("selection multi-selection, from", _last_single_index, "to", _this_index);
          for (let i = Math.min(_last_item_selected_index, _this_index); i <= Math.max(_last_item_selected_index, _this_index); i++) {
            this.list_items[i].selected = item.selected;
          }
          // if (WG_debug) console.log("selection multi-selection, from", _last_single_index, "to", _this_index);
          // for (let i = 0; i < _.size(this.list_items); i++) {
          //   if (i >= Math.min(_last_single_index, _this_index) && i <= Math.max(_last_single_index, _this_index)) {
          //     this.list_items[i].selected = this.selection.last_single_item_selected ? this.selection.last_single_item_selected.selected : true;
          //     // if (WG_debug) console.log("selection Selecting ", i);
          //   } else if (_last_item_selected_index > -1 && i >= Math.min(_last_item_selected_index, _this_index) && i <= Math.max(_last_item_selected_index, _this_index)) {
          //     this.list_items[i].selected = false;
          //     // if (WG_debug) console.log("selection UnSelecting ", i);
          //   }
          // }

          // } else {
          //   this.selection.last_single_item_selected = item;
        }
        this.selection.last_item_selected = _.cloneDeep(item);
        this.selection.updateSelectedList();
      },
    };

    public list_items: IDevice[] = [];

    public batchActions = [
      {
        label: 'app.licenses.ASSIGN_DISTRIBUTOR',
        action: () => {
          this.showDistributorAssignModal(this.selection.items)
        }
      },
      {
        label: 'app.licenses.ASSIGN_LICENSE',
        action: () => {
          this.showSubscribeModal(this.selection.items)
        }
      },


    ];


    updateTotals(total) {
      this.$scope.tableParams.total(total);
      this.showingFrom = (this.$scope.tableParams.page() - 1) * this.$scope.tableParams.count() + 1;
      this.showingTo = this.showingFrom - 1 + this.$scope.tableParams.count();
      if (this.showingFrom > this.list_items.length)
        this.showingFrom = this.list_items.length;
      if (this.showingTo > this.$scope.tableParams.total())
        this.showingTo = this.$scope.tableParams.total();

      let _counts: number[] = [10];
      for (let threshold of [25, 50, 100, 200, 500]) {
        if (total > _counts[_counts.length - 1]) {
          _counts.push(threshold);
        } else {
          break;
        }
      }
      this.$scope.tableParams.settings({counts: _counts});
    };


    showSubscribeModal = (deviceData: IDevice[]) => {

      this.ngDialog.openConfirm({
        template: '<license-subscription-modal new-license-data="newLicenseData" behaviour="behaviour"></license-subscription-modal>',
        plain: true,
        className: 'ngdialog-theme-default',
        controller: ['$scope', function ($scope) {
          $scope.nowDate = new Date();
          $scope.nowDate.setHours(0, 0, 0, 0);

          $scope.behaviour = wg.ModalBehaviour.CREATE;

          if (!Array.isArray(deviceData)) deviceData = [deviceData];


          $scope.newLicenseData = deviceData.map((device) => {
            return {
              license_start_date: $scope.nowDate,
              client_invoice_date: $scope.nowDate,
              resale_invoice_date: $scope.nowDate,
              numberOfInvoices: 2,
              device: device,
              distributor: {},
              client_manager: {},
            }
          });
        }]
      }).then((data) => {
        console.log('License saved', data);
      }, (reason) => {
        console.log('License save Failed. Reason: ', reason);
      }).finally(() => {
        this.$scope.tableParams.reload()
      })

    };


    showDistributorAssignModal = (deviceData: IDevice[]) => {

      this.ngDialog.openConfirm({
        template: "<distributor-assign-modal device-data='deviceData'></distributor-assign-modal>",
        plain: true,
        className: 'ngdialog-theme-default',
        controller: ['$scope', function ($scope) {
          if (!Array.isArray(deviceData)) deviceData = [deviceData];
          $scope.deviceData = deviceData;
        }]
      }).then((data) => {
        console.log('Distributor saved', data);
      }, (reason) => {
        console.log('Distributor save Failed. Reason: ', reason);
      }).finally(() => {
        this.$scope.tableParams.reload()
      })
    };


    public $onInit = () => {
      this.$scope.tableParams = this.buildTableParams(this, this.list_items);

      // Get the pending parameter from the state
      this.pendingLicenses = !!this.$state?.params?.['pending'];
      if (WG_debug) console.log("Pending Licenses:", this.pendingLicenses);
    }


    buildTableParams(controller: UnlicensedPageController, licensesData: any[]): NgTableParams<IDevice> {
      return new controller.ngTableParams({
        page: 1,            // show first page
        count: 25,          // count per page
        sorting: {
          lkm__last_timestamp: 'desc'   // initial sorting
        },
        filter: {}
      }, {
        total: 0, // length of data
        paginationMaxBlocks: 7, // 7 = first, 3 centered, last
        paginationMinBlocks: 3,
        getData: (params) => {
          controller.loading = true;

          // console.log("Get-TD list_items.");
          // let search = this.search.all;

          // let query = '?page_size=' + params.count();

          // let filter = '';
          // let obj = params.filter();
          // for (let key in obj) {
          //   if (obj.hasOwnProperty(key)) {
          //     if (angular.isDefined(obj[key]) && obj[key] !== '')
          //       filter += key + '=' + obj[key] + '&';
          //   }
          // }
          // if (filter !== '') {
          //   filter = filter.substring(0, filter.length - 1);
          //   query += '&' + filter;
          // }
          // if (!_.isEmpty(search)) {
          //   query += '&search=' + search;
          // }
          // if (params.orderBy() + '' !== '') {
          //   query += '&ordering=' + params.orderBy();
          // }

          // query += '&owner__username__ne=admin.script';

          // if(this.pendingLicenses) {
          //   query += '&distributor__username=' + this.AuthService.view_as_owner.username;
          // }

          let url_parameters: {} = _.defaults({},
              {
                page_size: params.count() || 25,
                page: params.page() || 1,
                search: this.search.all || undefined,
                ordering: _.map(params.orderBy(), (item) => {
                  return _.replace(item, '+', '')
                }) || undefined, // Replace all + with empty string
                owner__username__ne: 'admin.script',
                distributor__username: this.pendingLicenses ? this.AuthService.view_as_owner.username : undefined,
              },
              params.filter(true),
          );

          url_parameters = _.pickBy(url_parameters, _.negate(_.isNil));

          let query = $.param(url_parameters, true);
          // if (WG_debug) console.log("Query:", query);

          return this.$http.get<IDataResponse<IDevice>>('api/dashboard/distributors/unlicensed/?' + query)
              .then(
                  (response) => {
                    // if (WG_debug) console.log("Got-TD licenses. response:", response);
                    // controller.list_items = response.data.results as IDevice[];
                    controller.list_items = _.uniqBy(response.data.results, 'id');

                    if (controller.list_items.length < response.data.results.length
                        && WG_debug) {
                      console.warn("Duplicates found in the response. Please check the API response.");
                    }

                    // convert all Dates inside controller.list_items to dates
                    _.forEach(controller.list_items, function (item) {
                      // if (item.created_at) item.created_at = new Date(item.created_at);
                      if (item.lkm) {
                        if (item.lkm.last_activity) item.lkm.last_activity = new Date(item.lkm.last_activity);
                        if (item.lkm.first_timestamp) item.lkm.first_timestamp = new Date(item.lkm.first_timestamp);
                        if (item.lkm.last_timestamp) item.lkm.last_timestamp = new Date(item.lkm.last_timestamp);
                      }
                    });

                    // this.selection.selectAll(true);
                    this.selection.reselectFromList();

                    controller.loading = false;
                    // update table params
                    controller.updateTotals(response.data.count);
                    // $defer.resolve(controller.list_items);
                    return controller.list_items;
                  }, function (reason) {
                    controller.loading = false;
                    console.error(reason);
                  }
              );
        }
      });
    }
  }

  App.controller('UnlicensedPageController', UnlicensedPageController);
}



