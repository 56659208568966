/**
 * Created by pmec on 02/09/15.
 */
namespace wg {
  App.controller('NotesController', ['$rootScope', '$scope', '$http', '$stateParams', '$window', 'AuthService', 'ngDialog',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $stateParams, $window, AuthService: IAuthService, ngDialog: angular.dialog.IDialogService) {
      // $stateParams = {
      //   action: 'new',
      //   note: null,
      //   attachments: [
      //     {
      //       id: 25,
      //       file: "/api/dashboard/notes/files/2016/12/08/chart.e0a5c1c86be447a897c7ef57798bc9f8.png"
      //     }
      //   ]
      // };
      $scope.gotoBottom = function () {
        if ($window.innerWidth < 768) {
          var _target_div = $('#bottom');
          if (_target_div && _target_div.offset())
            $('html, body').animate({scrollTop: (_target_div.offset().top - 50)}, 800, 'swing');
        }
      }

      var action_new = false;
      if (angular.isDefined($stateParams.action) && $stateParams.action != null) {
        if ($stateParams.action == 'new') {
          action_new = true;
        }
      }


      $scope.notes = [];
      $scope.reports = [];
      $scope.selectedNote = null;
      $scope.searchQuery = '';

      $scope.getNotes = function () {
        $scope.loading = true;
        $http.get('api/dashboard/notes/all/', {}).then(
            function (response: ng.IHttpPromiseCallbackArg<IDataResponse>) {
              var data = response.data;
              $scope.notes = [];
              $scope.reports = [];
              angular.forEach(data, function (note) {
                if (!note.is_report) {
                  $scope.notes.push(note);
                } else {
                  $scope.reports.push(note);
                }
              });

              if (action_new) {
                newNoteFromParams();
              } else {
                // if (data.length > 0) {
                //   $scope.selectedNote = data[0];
                //   //$scope.selectedNote.edit = true;
                // }
              }
              //angular.forEach(data, function(note) {
              //  $scope.notes[note.id] = note;
              //});
              // $scope.notes = data;
              $scope.loading = false;
            }, function (response) {
              console.error(response);
            });
      };

      function newNoteFromParams() {
        var location = window.location.origin;
        $scope.newNote();
        $scope.selectedNote.body = '';
        for (var i = 0; i < $stateParams.attachments.length; ++i) {
          $scope.selectedNote.attachments.push($stateParams.attachments[i]);
          $scope.selectedNote.body += '<p><img src="' + location + $stateParams.attachments[i].file + '"></p>';
        }
      }

      // var extend = function (a, b) {
      //   var n;
      //   if (!a) {
      //     a = {};
      //   }
      //   for (n in b) {
      //     a[n] = b[n];
      //   }
      //   return a;
      // };
      //
      // var
      //   UNDEFINED,
      //   win = window,
      //   doc = win.document,
      //   userAgent = (win.navigator && win.navigator.userAgent) || '',
      //   isOpera = win.opera,
      //   isMS = /(msie|trident|edge)/i.test(userAgent) && !isOpera,
      //   SVG_NS = 'http://www.w3.org/2000/svg',
      //   hasSVG = doc && doc.createElementNS && !!doc.createElementNS(SVG_NS, 'svg').createSVGRect;
      // function css(el, styles) {
      //   if (isMS && !hasSVG) { // #2686
      //     if (styles && styles.opacity !== UNDEFINED) {
      //       styles.filter = 'alpha(opacity=' + (styles.opacity * 100) + ')';
      //     }
      //   }
      //   extend(el.style, styles);
      // }
      //
      // function createElement(tag, attribs, styles, parent, nopad) {
      //   var el = doc.createElement(tag);
      //   if (attribs) {
      //     extend(el, attribs);
      //   }
      //   if (nopad) {
      //     css(el, { padding: 0, border: 'none', margin: 0 });
      //   }
      //   if (styles) {
      //     css(el, styles);
      //   }
      //   if (parent) {
      //     parent.appendChild(el);
      //   }
      //   return el;
      // }

      // http://stackoverflow.com/questions/16670754/angularjs-simple-file-download
      function download(fileName, fileDownload) {
        console.debug('Downloading a new file');
        // @ts-ignore
        var isFirefox = typeof InstallTrigger !== 'undefined';
        var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
        // @ts-ignore
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        // @ts-ignore
        var isEdge = !isIE && !!window.StyleMedia;
        // @ts-ignore
        var isChrome = !!window.chrome && !!window.chrome.webstore;
        // @ts-ignore
        var isOpera = (!!window.opr && !!opr?.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        var isBlink = (isChrome || isOpera) && !!window.CSS;

        if (isFirefox || isIE || isChrome) {
          if (isChrome || isFirefox) {
            console.log('Manage Firefox or Google Chrome download');
            var url = window.URL || window.webkitURL;
            var fileURL = url.createObjectURL(fileDownload);
            var downloadLink = angular.element('<a></a>');//create a new  <a> tag element
            downloadLink.attr('href', fileURL);
            downloadLink.attr('download', fileName);
            downloadLink.attr('target', '_self');
            downloadLink[0].click();//call click function
            url.revokeObjectURL(fileURL);//revoke the object from URL
          }
          if (isIE) {
            console.log('Manage IE download>10');
            // @ts-ignore
            window.navigator?.msSaveOrOpenBlob?.(fileDownload, fileName);
          }
        } else {
          console.error('SORRY YOUR BROWSER IS NOT COMPATIBLE');
        }
      }

      $scope.exportNote = function (note) {
        console.log(note);
        var exportUrl = 'api/dashboard/notes/' + note.id + '/export/?jwt=' + AuthService.getToken().jwt;
        $window.open(exportUrl, '_blank');
        // $http.get(exportUrl).then(function (response) {
        //   console.log(response);
        //   download('note_' + note.id + '.pdf', new Blob([response.data], {type: 'application/pdf'}))
        // });


        // var
        //   HIDDEN = 'hidden',
        //   NONE = 'none',
        //   name,
        //   form;
        // // create the form
        // form = createElement('form', {
        //   method: 'get',
        //   action: exportUrl,
        //
        //   // enctype: 'multipart/form-data'
        // }, {
        //   display: NONE
        // }, doc.body);
        //
        // // submit
        // console.log(form.submit());
      };

      $scope.newNote = function () {
        $scope.selectedNote = {};
        $scope.selectedNote.edit = true;
        $scope.selectedNote.edited = true;
        $scope.selectedNote.owner = {};
        $scope.selectedNote.owner.id = AuthService.user.id;
        $scope.selectedNote.owner.username = AuthService.user.username;
        $scope.selectedNote.attachments = [];
      };

      $scope.newReport = function () {
        $scope.selectedNote = {};
        $scope.selectedNote.body = '';
        $scope.selectedNote.edit = true;
        $scope.selectedNote.edited = true;
        $scope.selectedNote.owner = {};
        $scope.selectedNote.owner.id = AuthService.user.id;
        $scope.selectedNote.owner.username = AuthService.user.username;
        $scope.selectedNote.attachments = [];
        $scope.selectedNote.is_report = true;
        ngDialog.openConfirm({
          template: 'notesModalDialogAddReport',
          className: 'ngdialog-theme-default',
          controller: ['$scope', function ($scope) {
            $scope.data = {
              selected: [],
              order: []
            };
            $scope.gotoBottom = function () {
              if ($window.innerWidth < 768) {
                var _target_div = $('#bottom');
                if (_target_div && _target_div.offset())
                  $('html, body').animate({scrollTop: (_target_div.offset().top - 50)}, 800, 'swing');
              }
            }
            console.log('controller $scope', $scope);
            $scope.onChange = function ($index, note, selected) {
              //$scope.selectedNotesHtml = '';
              $scope.log = '' + $index + ' ' + note.title + ': ' + selected;

              var arrayNotes = [];

              if (selected) {
                $scope.data.order.push(note);
              } else {
                var index = $scope.data.order.indexOf(note);
                $scope.data.order.splice(index, 1);
              }
              // Re-Order list of notes
              $.each($scope.data.order, function (i, nota) {
                arrayNotes.push(nota);
                $scope.data.selected[i] = true;
              });
              $.each($scope.ngDialogData.notes, function (k, noto) {
                if (!arrayNotes.includes(noto)) {
                  var x = arrayNotes.push(noto);
                  $scope.data.selected[x - 1] = false;
                }
              });
              $scope.ngDialogData.notes = arrayNotes;
              //console.log("object: ", $scope.ngDialogData.notes, "arrayNotes: ",arrayNotes, "selected: ", $scope.data.selected);

            }
          }],
          data: {
            notes: $scope.notes
          }
        }).then(function (data) {
          console.log('notesModalDialogAddReport', data);
          angular.forEach(data.order, function (note) {
            console.log('note', note.title);
            $scope.selectedNote.body += '<h2>' + note.title + '</h2>\n';
            $scope.selectedNote.body += note.body + '\n</br>\n';
          });
          $scope.gotoBottom();
        }, function (reason) {
          console.log('Modal promise rejected. Reason: ', reason);
        })
      };

      $scope.saveNote = function (note) {
        $scope.loading = true;
        if (!angular.isDefined(note.id)) {
          $http.post('api/dashboard/notes/', note).then(
              function (response) {
                if (response.status === 201) {
                  note.edit = false;
                  note.preview = true;
                  note.edited = false;
                  if (!note.is_report) {
                    $scope.notes.push(response.data);
                  } else {
                    $scope.reports.push(response.data);
                  }
                  $scope.selectedNote = response.data;
                }
                $scope.loading = false;
              }, errorFn);
        } else {
          $http.patch('api/dashboard/notes/' + note.id + '/', note).then(
              function (response) {
                if (response.status === 200) {
                  note.edit = false;
                  note.preview = true;
                  note.edited = false;
                }
                $scope.loading = false;
              }, errorFn);
        }
      };


      $scope.deleteNote = function (note) {
        if (!angular.isDefined(note.id)) {
          $scope.selectedNote = null;
          // $scope.selectedNote = $scope.notes[0];
        } else {

          ngDialog.openConfirm({
            template: 'app/views/modals/confirm-danger.html',
            className: 'ngdialog-theme-default',
            controller: 'NotesController'
          }).then(function (data) {
            $http.delete('api/dashboard/notes/' + note.id + '/', note).then(function (response) {
              if (response.status == 204) {
                var obj;
                if (note.is_report) {
                  obj = _.find($scope.notes, {id: note.id});
                  if (angular.isDefined(obj)) {
                    $scope.notes = _.without($scope.notes, obj);
                  }
                  // $scope.selectedNote = null;
                  // $scope.selectedNote = $scope.notes[0];
                } else {
                  obj = _.find($scope.reports, {id: note.id});
                  if (angular.isDefined(obj)) {
                    $scope.reports = _.without($scope.reports, obj);
                  }
                  // $scope.selectedNote = null;
                  // $scope.selectedNote = $scope.reports[0];
                }
                $scope.selectedNote = null;
                // $scope.selectedNote = $scope.notes[0];
                $scope.getNotes();
              }
              $scope.loading = false;
            }, errorFn);
          }, function (reason) {
            console.log('Delete Note modal rejected. Reason', reason);
          });


        }
      };
      $scope.selectNote = function (note) {
        $scope.selectedNote = note;

      };

      function errorFn(response) {
        $scope.loading = false;
        console.error(response);
        $('.parsley-error-0').remove();
        if (response.status === 400 && response.data) {
          for (var key in response.data) {
            $('#id-' + key).parsley('destroy');
            var specificField = $('#id-' + key).parsley();
            var i = 0;
            angular.forEach(response.data[key], function (error) {
              try {
                specificField.addError('error-' + i, {message: error});
                // window.ParsleyUI.addError(specificField, 'error-' + i, error);
              } catch (e) {
              }
              i++;
            });
          }
        }
      }

      $rootScope.$on('FileUploadModal:onSuccessItem', function (e, fileItem, response) {
        $scope.selectedNote.attachments.push(response);
      });

      $scope.getNotes();

      console.log($scope);
    }]);
}