namespace wg {

  class TranslationFlagController {
    public static $inject = ['$scope','$translate','$rootScope'];
    public language:any

    constructor(private $scope: any,private $translate: ng.translate.ITranslateService,private $rootScope: IRootScope) {
      this.$scope = $scope;
      this.language = $rootScope.language;

    }
  }

  App.component('wgTranslationFlag', {
    templateUrl: 'app/views/wg-translation-flag.html',
    controller: TranslationFlagController,
    controllerAs: 'ctrl',
    bindings: {
      verbose:'<'
    }
  })
}