/**
 * Created by pmec on 23/12/14.
 */


/**=========================================================
 * Module: auth.js
 * Services to authenticate the client
 =========================================================*/

namespace wg {
  enum Roles {
    public,
    user,
    staff,
    admin,
    distributor,
  }

  enum AccessLevels {
    public,
    anon,
    user,
    admin,
  }

  // User Local data
  interface IUserData {
    view_as_owner?: IViewAsOwner;
    clients_view?: boolean;
    presentation_mode?: boolean;

    remember?: boolean;
    last_user_id?: number;

    last_state?: {
      name: string,
      url: string,
      href: any,
    };

    hideWelcome?: boolean; // Legacy
    // [key: string]: any;
  }

  interface IUserConfigs {
    conversions?: { [key: string]: string };
    // favorite_units?: object [];
    custom_overview?: {
      internal_name: string,
      // show_last?: boolean,
      sensor?: ISensor, // Not stored on DB
    } [];
    hasSeenTour?: { mainVersion: number };
    hasSeenReleaseNotes?: { version: number };

    ai_notifications?: any;

    // [key: string]: any;
  }

  export interface IUser {
    id: number;
    role?: "admin" | "staff" | "user" | "public" | "distributor" | "deactivated";
    username: string;
    email?: string;
    uuid?: string;

    api_key?: string;
    home_state?: string; // sref saved in django. Always the default...

    company?: string;
    url?: string;
    full_name?: string;
    phone_number?: string;

    last_login?: string;
    date_joined?: string;

    groups?: { id: number, name: string, users_count: number, devices_count: number }[];
    userData?: IUserData;
    configs?: IUserConfigs;

    read?: boolean;
    write?: boolean;
    // list of accounts this user has access to
    shared_owners_relation?: any;
    // list of users with access to this account
    permitted_users_relation?: any;

    notification_targets?: INotificationTarget[];
    // [key: string]: any;
  }

  export interface IGroup {
    id: number;
    name: string;
    users_count: number;
    devices_count: number;
  }

  interface IViewAsOwner {
    id: IUser["id"],
    username: IUser["username"],
    company?: IUser["company"],
    email: IUser["email"],
    uuid?: IUser["uuid"],
    role?: IUser["role"],

    // Permissions of this "sharing"
    read?: boolean,
    write: boolean,

    aliases?: string, // future use

    // UI only
    display_name: string,
    // UI only
    search_name: string,
  }

  interface IToken {
    jwt?: string;
    rft?: string;
  }

  export interface IAuthService {
    deleteToken(): void;

    getToken(): IToken;

    hasToken(): boolean;

    setToken(token: IToken): void;

    getUser(): IUser;


    setUser(user: IUser): void;

    deleteUser(): void;

    getUserData(): IUserData;

    setUserData(userData: IUserData): void;

    deleteUserData(): void;

    updateUserConfigs(newConfigs: IUserConfigs, returnRef?: IReturnResult, _setUser?: boolean): void;

    setDashboardConfigs(dashboardConfigs: IDashboardConfigs): void;

    // Other account selected.
    viewing_as_other_owner(): boolean;

    get_company_name(company: string, email: string): string;

    get_shared_owners_list(callBack: Function): void;

    get_best_view_as_owner(): void;

    update_url(new_stateParams: object, clear_all?: boolean, reload?: boolean, replace_history_entry?: boolean, _this?: any, _new_state?: string): void;

    init(): void;

    login(username: string, password: string): ng.IPromise<{ user: IUser, token: string }>;

    logout(): ng.IPromise<ng.IHttpPromiseCallbackArg<IDataPostResponse>>;

    register(username: string, email: string, password1: string, password2: string, activation_token: string, company: string, country: string, distributor: string): ng.IPromise<any>;

    recover(email: string): ng.IPromise<any>;

    recover_confirmation(uid: string, token: string, new_password1: string, new_password2: string): ng.IPromise<any>;

    resend_confirmation(username: string, password: string): ng.IPromise<any>;

    account_confirmation(key: string): ng.IPromise<any>;

    me(): Promise<ng.IHttpPromiseCallbackArg<IUser>>

    // resolvePendingState(promise: ng.IPromise<any>): ng.IPromise<any>;

    get_display_name(username: string, company: string, email: string, add_company: boolean): string;

    canLoggedUserAccess(accessLevelName: string): boolean;

    canAccess(accessLevelName: string): boolean;

    anonymize_lkm(stream: string, payload: any): void;

    anonymize_entity_recursive(entity_type: string, entry: IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm): void;

    anonymize_entity(entity_type: string, entry: IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm): void;

    anonymize_entities(entity_type: string, entries: (IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm)[]): void;

    setUserViewPerms(): void;

    set_notification_targets(): void;

    isDev(): boolean;

    isDemoAccount(): boolean;

    checkVersion(version: string): boolean;

    isLoginState(state: string): boolean;

    isPageState(state: string): boolean;

    user: IUser;
    userRole: IUserRole;
    userData: IUserData;
    token: IToken;
    tokenExpired: boolean;
    isLogged: boolean;
    default_owner: IViewAsOwner,
    view_as_owner?: IViewAsOwner,
    selected_view_as_owner?: IViewAsOwner,
    view_as_owner_role: IUserRole;
    // View as the target user sees. Admin only. view as non-admin, their alarms, etc.
    clients_view?: boolean,
    presentation_mode?: boolean,
    // Notification target of logged in user, or of target user if clients_view
    viewing_notification_targets?: INotificationTarget[];
    // We should anonymize all data
    anonymize: boolean;
    // Don't hide these accounts' data
    anonymize_exceptions: number[];
    shared_owners?: IViewAsOwner[];
    user_is_admin?: boolean;
    user_view_as_admin?: boolean;
    user_is_dev?: boolean;
    user_view_as_dev?: boolean;
    user_view_as_debug?: boolean;

    pendingStateChange: any;
    doneLoading: boolean;
    errorState: string;
    logoutState: string;
    loginState: string;
    registerState: string;
    recoverState: string;
    recoverConfirmState: string;
    accountConfirmState: string;
    homeState: string;
    response: any;
    dashboardConfigs?: IDashboardConfigs;
  }

  App.service('AuthService', ['$rootScope', '$http', '$window', '$q', '$cookies', '$timeout', '$state', '$stateParams', '$location', '$translate', 'jwtHelper',
    function ($rootScope: IRootScope, $http: ng.IHttpService, $window, $q, $cookies, $timeout: ng.ITimeoutService, $state: _IState, $stateParams, $location: ng.ILocationService, $translate: ng.translate.ITranslateService, jwtHelper) {
      var localStorage = getLocalStorage($window.localStorage);
      // var localStorage = $window.localStorage;

      let AuthService: IAuthService = {
        deleteToken: deleteToken,
        getToken: getToken,
        hasToken: hasToken,
        setToken: setToken,
        getUser: getUser,
        setUser: setUser,
        deleteUser: deleteUser,
        getUserData: getUserData,
        setUserData: setUserData,
        deleteUserData: deleteUserData,
        updateUserConfigs: updateUserConfigs,
        setDashboardConfigs: setDashboardConfigs,
        viewing_as_other_owner: viewing_as_other_owner,
        get_company_name: get_company_name,
        get_shared_owners_list: get_shared_owners_list,
        get_best_view_as_owner: get_best_view_as_owner,
        update_url: update_url,
        init: init,
        login: login,
        logout: logout,
        register: register,
        recover: recover,
        recover_confirmation: recover_confirmation,
        resend_confirmation: resend_confirmation,
        account_confirmation: account_confirmation,
        me: me,
        // resolvePendingState: resolvePendingState,
        get_display_name: get_display_name,
        canLoggedUserAccess: canLoggedUserAccess,
        canAccess: canAccess,
        anonymize_lkm: anonymize_lkm,
        anonymize_entity_recursive: anonymize_entity_recursive,
        anonymize_entity: anonymize_entity,
        anonymize_entities: anonymize_entities,
        setUserViewPerms: setUserViewPerms,
        set_notification_targets: set_notification_targets,
        isDev: isDev,
        isDemoAccount: isDemoAccount,
        checkVersion: checkVersion,
        isLoginState: isLoginState,
        isPageState: isPageState,

        /**
         * Public properties
         */
        userRole: null,
        user: {id: null, uuid: null, email: null, role: null, username: null, company: null, notification_targets: []},
        view_as_owner: {id: null, username: null, display_name: null, search_name: null, write: false, email: null},
        selected_view_as_owner: null,
        view_as_owner_role: null,
        clients_view: false,
        presentation_mode: false,
        anonymize: false,
        anonymize_exceptions: [61, 67, 173], // Dev, admin.script, Winegrid_Winery
        default_owner: {id: null, username: null, display_name: null, search_name: null, write: false, email: null},
        userData: {},
        token: {},
        tokenExpired: null,
        isLogged: null,
        user_is_admin: false,
        user_view_as_admin: false,
        user_is_dev: false,
        user_view_as_dev: false,
        user_view_as_debug: false,


        pendingStateChange: null,
        doneLoading: null,
        errorState: 'app.error',
        logoutState: 'page.login',
        loginState: 'page.login',
        registerState: 'page.register',
        recoverState: 'page.recover',
        recoverConfirmState: 'page.recover-confirm',
        accountConfirmState: 'page.account-confirm',
        homeState: 'app.devices.dashboard',
        response: null,
        dashboardConfigs: {},
      };

      let refreshToken_timer: NodeJS.Timeout;
      let refreshToken_timer_max_wait = 6 * 60 * 60 * 1000; // refresh token every few hours to keep a user logged in

      const default_user_data: IUser = {
        email: "",
        id: 0,
        role: "public",
        username: null,
        company: null,
      };

      // ---- ReleaseNotes Stuff

      // releaseNotes structure
      $rootScope.releaseNotes = {
        userSeen: 0,
        currentVersion: 20, // Current Dashboard version
        text: [],
        updateSeen: function (version = this.currentVersion) {
          let _configs = {hasSeenReleaseNotes: {version: version}};
          updateUserConfigs(_configs);
        },
        isOpen: false, // If it should start opened, due to new version being available
        open: function () {
          console.log('releaseNotes.open() Not yet loaded');
        },

      };


      function deleteToken() {
        localStorage.removeItem('jwt');
        localStorage.removeItem('rft');
        // localStorage.removeItem('jwt_cookie');
        // $cookies.remove('JWT');
        $cookies.remove('jwt');
        // console.log($cookies.get('JWT'));
        delete $http.defaults.headers.common['Authorization'];
        AuthService.token = {};
      }

      function getToken() {
        return {
          jwt: localStorage.getItem('jwt'),
          rft: localStorage.getItem('rft')
        };
      }

      function setToken(token) {
        if (!token) return;
        AuthService.token = token;
        $rootScope.token = token;
        localStorage.setItem('jwt', token.jwt);
        localStorage.setItem('rft', token.rft);

        if (!!token.jwt) {
          $http.defaults.headers.common['Authorization'] = 'JWT ' + token.jwt.toString();
        }
      }

      function hasToken() {
        var token = getToken();
        // return !!(token.jwt);
        return !!(token.jwt || token.rft);
      }

      function check_token_valid() {
        if (!AuthService.token?.jwt) {
          return false;
        }
        if (!AuthService.isLogged) {
          return false;
        }

        AuthService.tokenExpired = jwtHelper.isTokenExpired(AuthService.token.jwt);
        if (AuthService.tokenExpired) {
          console.warn("Login token expired. Logging out!");
          // AuthService.isLogged = false;
          // AuthService.deleteToken();
          logout();
          return false;
        }

        let time_to_expire = jwtHelper.getTokenExpirationDate(AuthService.token.jwt) - (new Date()).getTime();
        let time_to_recheck = time_to_expire;

        // Refresh in the last 2 hours if configured to do so
        if (AuthService.userData?.remember && time_to_expire < 2 * 60 * 60 * 1000) {
          console.log('AuthService refreshing token');
          $http.post('api/auth/token/refresh/', {token: AuthService.token.jwt}).then(
              function (response: ng.IHttpPromiseCallbackArg<{ token: string }>) { // onSuccess
                let token: IToken = {jwt: response.data.token, rft: ''};
                AuthService.setToken(token);
                console.info("Login-token refreshed");
                schedule_check_token(refreshToken_timer_max_wait);
              }, function (reason) { // onError
                console.error("Error refreshing login-token", reason);
                schedule_check_token(5 * 60 * 1000);
              });
        }

        // Re-schedule check
        if (time_to_recheck < 0) {
          console.warn("Login token expired? Clock error?", time_to_recheck);
          time_to_recheck = 5 * 60 * 1000;
        } else if (AuthService.userData?.remember) {
          time_to_recheck -= 30 * 60 * 1000; // check and refresh 30 minutes before expiration
        } else {
          time_to_recheck += 10 * 1000; // 10 seconds after expiration
        }
        if (time_to_recheck > refreshToken_timer_max_wait) {
          time_to_recheck = refreshToken_timer_max_wait;
        } else if (time_to_recheck < 0) {
          time_to_recheck = 5 * 60 * 1000;
        }
        schedule_check_token(time_to_recheck);

        return true;
      }

      function schedule_check_token(_timeout = refreshToken_timer_max_wait) {
        clearTimeout(refreshToken_timer)

        if (!AuthService.isLogged)
          return;

        refreshToken_timer = setTimeout(function () {
          check_token_valid();
        }, _timeout);
      }

      function getUser() {
        // console.log('AuthService', 'getUser');
        return parseData(localStorage.getItem('user'), null) as IUser;
      }

      function setUser(_user) {
        if (WG_debug) console.log('AuthService, setUser', _.cloneDeep(_user));
        // update user

        _user.configs = parseData(_user.configs, null, "Error processing user configs") as IUserConfigs;
        _user.configs = process_configs(_user.configs);

        $rootScope.apiKey = _user.api_key;

        if (_user.home_state && $state.get(_user.home_state)) {
          AuthService.homeState = _user.home_state;
        } else {
          if (WG_debug) console.warn("Wrong homeState: ", _user.home_state, ". Using default: ", AuthService.homeState);
          _user.home_state = AuthService.homeState;
          updateUserConfigs({}, null, false, {home_state: AuthService.homeState});
        }

        _.assign(AuthService.user, _user);

        set_notification_targets();

        // update userRole
        AuthService.userRole = userRoles[_user.role];

        localStorage.setItem('user', JSON.stringify(AuthService.user));

        get_shared_owners_list(get_best_view_as_owner);
        return;
      }

      // Updates and saves some user.configs
      function updateUserConfigs(newConfigs: Partial<IUserConfigs> = {}, returnRef: IReturnResult = null, _setUser = true, newUserData: Partial<IUser> = {}) {
        // TODO: Get current configs from DB and merge to allow multiple users to edit at the same time.

        let data: Partial<IUser> = {};
        if (!_.isEmpty(newConfigs)) {
          let configs = _.assign({}, AuthService.user.configs, newConfigs);
          // @ts-ignore
          data.configs = JSON.stringify(configs)
        }
        if (!_.isEmpty(newUserData)) {
          if (newUserData.home_state) {
            data.home_state = newUserData.home_state
          }
        }

        if (returnRef) {
          returnRef.loading = true;
          returnRef.result = '';
        }
        $http.patch<IUser>('api/auth/me/', data).then(
            function (response) {
              if (returnRef) {
                returnRef.loading = false;
                returnRef.result = 'success';
              }
              if (WG_debug) console.log('Updated user configs', response.data);
              if (_setUser)
                AuthService.setUser(response.data);
            }, function (reason) {
              if (returnRef) {
                returnRef.loading = false;
                returnRef.result = 'error';
              }
              console.error(reason);
            });
      }

      function deleteUser() {
        return localStorage.removeItem('user');
      }

      function getVersion() {
        return parseData(localStorage.getItem('version'), {});
      }

      function setVersion(version) {
        return localStorage.setItem('version', JSON.stringify(version));
      }

      function deleteVersion() {
        return localStorage.removeItem('version');
      }

      /**
       * Gets new user-configs from server if version differs from cache
       * @param Website version
       * @returns {boolean} True if version differs
       */
      function checkVersion(new_version) {
        var cached_version = getVersion();
        if (new_version !== cached_version) {
          if (AuthService.isLogged) {
            if (WG_debug) console.info("App version updated. Refreshing user", cached_version);
            $http.get<IUser>('api/auth/me/', {}).then(
                function (response) {
                  AuthService.response = response;
                  AuthService.setUser(response.data);
                  setVersion(new_version);
                  return response;
                }, function (response) {
                  AuthService.response = response;
                  console.error(response);
                  return $q.reject(response);
                });
          }
          return true;
        } else {
          return false;
        }
      }

      function process_configs(_configs: IUserConfigs = null) {

        _configs = _configs || AuthService.user.configs || {};

        if (_.isEmpty(_configs)) {
          console.info('No user configs found!', AuthService.user);
        }

        // Set default configs
        _.defaults(_configs, {
          conversions: {},
          custom_overview: [],
          hasSeenTour: {mainVersion: 0},
          hasSeenReleaseNotes: {version: 0},
          ai_notifications: {},
        });

        // Rebuild configured card-view
        if (_.isEmpty(_configs.custom_overview)) {
          $rootScope.cardview_sensors_list = CARDVIEW_SENSORS_LIST;
        } else {
          $rootScope.cardview_sensors_list = _.map(_configs.custom_overview, 'internal_name');
        }


        // ai_notifications
        ['DEVICE_MISSING', 'DENS_AFTER_FERMENTATION'
          , 'FERMENTATION_STARTED', 'FERMENTATION_STAGNATED', 'FERMENTATION_END'
        ].forEach(function (required_ai_notifications) {
          if (!_configs.ai_notifications[required_ai_notifications]) {
            _configs.ai_notifications[required_ai_notifications] = {
              active: true,
              email: false,
              notification: true,
            };
          }
          // Force false for now
          // TODO: implement emails and delete this line
          _configs.ai_notifications[required_ai_notifications].email = false;
        });

        // Tour
        $rootScope.TourVersion_UserSeen = _configs.hasSeenTour?.mainVersion || 0;
        // $rootScope.TourVersion_UserSeen = 0; // For debug


        // Release Notes
        $rootScope.releaseNotes.userSeen = _configs.hasSeenReleaseNotes?.version || 0;
        // $rootScope.releaseNotes.userSeen = 0; // For debug


        if ($rootScope.releaseNotes.userSeen < $rootScope.releaseNotes.currentVersion) {
          $rootScope.releaseNotes.isOpen = true;

          // TODO: Make this a dedicated function to handle necessary changes during app-updates
          if (WG_debug) console.info("Release Notes updated from ", $rootScope.releaseNotes.userSeen);
          if (!$rootScope.releaseNotes.userSeen || $rootScope.releaseNotes.userSeen < 2) {

            if (!_.includes($rootScope.cardview_sensors_list, 'FERMENT_SIMULATOR_endTime')) {
              if (WG_debug) console.info("Adding FERMENT_SIMULATOR_endTime");
              $rootScope.cardview_sensors_list.push('FERMENT_SIMULATOR_endTime');
              if (!_.isEmpty(_configs.custom_overview)) {
                _configs.custom_overview.push({
                  internal_name: 'FERMENT_SIMULATOR_endTime',
                });
                updateUserConfigs(_configs);
              }
            }
          }
          if (!$rootScope.releaseNotes.userSeen || $rootScope.releaseNotes.userSeen < 8) {
            if (_.includes($rootScope.cardview_sensors_list, 'PRESSURE') && !_.includes($rootScope.cardview_sensors_list, 'PRESSURE_TREAT')) {
              if (WG_debug) console.info("cardview_sensors_list, replacing PRESSURE with PRESSURE_TREAT");
              _.pull($rootScope.cardview_sensors_list, 'PRESSURE');
              $rootScope.cardview_sensors_list.push('PRESSURE_TREAT');
              if (!_.isEmpty(_configs.custom_overview)) {
                _.remove(_configs.custom_overview, {internal_name: 'PRESSURE'});
                _configs.custom_overview.push({
                  internal_name: 'PRESSURE_TREAT',
                  // show_last: false,
                });
                updateUserConfigs(_configs);
              }
            }
          }
        }

        // if (WG_debug) console.log('Loaded user configs: ', _configs);
        return _configs;
      }

      function get_display_name(username, company, email, add_company = true) {
        let display_name = username;
        if (add_company) {
          let _company = get_company_name(company, email);
          if (_company && !(display_name.toLowerCase().includes(_company.toLowerCase()))) {
            display_name += ' - ' + _company;
          }
        }
        return display_name;
      }

      function normalize_viewAsOwner(viewAsOwner: IViewAsOwner, add_company = false) {
        viewAsOwner.display_name = "";
        let anonymize = AuthService.anonymize && !AuthService.anonymize_exceptions.includes(viewAsOwner.id);

        viewAsOwner.display_name = (viewAsOwner.role == 'distributor' ? 'Dist - ' : '');
        viewAsOwner.display_name += get_display_name(viewAsOwner.username, viewAsOwner.company, viewAsOwner.email, add_company);

        viewAsOwner.search_name = viewAsOwner.display_name;

        if (anonymize) {
          // Anonymize stored default_owner if required
          viewAsOwner.display_name = (viewAsOwner.role == 'distributor' ? 'Dist - ' : 'User ') + hash_md5(viewAsOwner.id, 4);
        }

        if (viewAsOwner.write === false) {
          viewAsOwner.display_name += ', Read Only';
        }

        if (viewAsOwner.role == 'deactivated') {
          viewAsOwner.display_name += ', Deactivated';
          viewAsOwner.search_name += ' Deactivated';
        }

        viewAsOwner.search_name += ' ' + viewAsOwner.email;
        if (viewAsOwner.aliases) {
          // split by new_line and add all search_name
          viewAsOwner.aliases.split('\n').forEach(function (alias) {
            viewAsOwner.search_name += ' ' + alias;
          });
        }

        if (anonymize) {
          // Viewing Anonymous is always read-only
          viewAsOwner.write = false;
          viewAsOwner.email = null;
          viewAsOwner.company = null;
        }

        return viewAsOwner;
      }

      function setUserViewPerms() {
        AuthService.user_is_admin = canLoggedUserAccess('admin');
        AuthService.user_view_as_admin = AuthService.user_is_admin && !AuthService.clients_view;
        AuthService.user_is_dev = AuthService.user_is_admin && isDev();
        AuthService.user_view_as_dev = AuthService.user_is_dev && !AuthService.clients_view;
        AuthService.user_view_as_debug = AuthService.user_is_dev && !AuthService.clients_view && WG_debug;
        // Don't allow non-admin to impersonate other users
        if (!AuthService.user_is_admin) AuthService.clients_view = false;
      }

      function set_notification_targets() {
        AuthService.viewing_notification_targets = emptyOrCreateArray(AuthService.viewing_notification_targets);
        if (AuthService.viewing_as_other_owner() && AuthService.clients_view) {
          // Get notification_targets of selected user, not ours
          console.log("Getting notification targets of selected user", AuthService.view_as_owner.id);
          $http.get<IUser>('api/auth/users/' + AuthService.view_as_owner.id + '/')
              .then((response) => {
                if (response.data?.notification_targets) {
                  synchronizeArrayOfObjects(AuthService.viewing_notification_targets, response.data.notification_targets);
                  process_notification_targets();
                }
              });
        } else {
          // Our own notification_targets are always available in AuthService.user
          synchronizeArrayOfObjects(AuthService.viewing_notification_targets, AuthService.user.notification_targets);
          process_notification_targets();
        }
      }

      function process_notification_targets() {
        // Fill the notification type with it's type-object to allow translations
        _.forEach(AuthService.viewing_notification_targets || [], (target) => {
          let _type = _.find(notificationTargetTypes, {name: target.notification_endpoint_type});
          if (_type?.sref) {
            target.notification_endpoint_type_sref = _type.sref;
          } else {
            target.notification_endpoint_type_sref = null;
          }
        });
      }

      function init() {
        if (WG_debug) console.log('AuthService init()');

        AuthService.token = AuthService.getToken();
        AuthService.tokenExpired = true;

        if (!AuthService.token.jwt || _.isEmpty(AuthService.user)) {
          AuthService.user = _.cloneDeep(default_user_data);
          AuthService.userData = {};
          AuthService.token = {};
          AuthService.tokenExpired = true;
          AuthService.userRole = userRoles.public;
          AuthService.view_as_owner_role = userRoles.public;
          AuthService.clients_view = false;
          AuthService.presentation_mode = false;
          AuthService.isLogged = false;
          AuthService.deleteToken();
          AuthService.doneLoading = true;
          return;
        }

        let user = AuthService.getUser();
        if (_.isNil(user?.username))
          user = AuthService.user;
        if (_.isNil(user?.username))
          user = _.cloneDeep(default_user_data);

        getUserData();
        getDashboardConfigs();

        const remember = !!AuthService.userData.remember;

        AuthService.user = user;
        AuthService.userRole = userRoles[user.role];
        AuthService.view_as_owner_role = userRoles[user.role];
        AuthService.clients_view = !!AuthService.userData.clients_view;
        AuthService.presentation_mode = !!AuthService.userData.presentation_mode;
        AuthService.isLogged = true;
        AuthService.doneLoading = true;

        $rootScope.apiKey = user.api_key;
        if (WG_debug) console.info('User API_Key set:', user.api_key);

        $http.defaults.headers.common['Authorization'] = 'JWT ' + AuthService.token.jwt.toString();
        if (!check_token_valid()) {
          if (WG_debug) console.warn('Invalid token!', AuthService.token);
          return;
        }
        // Async refresh user data
        AuthService.me();

        $rootScope.token = AuthService.token;
        process_configs(AuthService.user.configs);

        setUserViewPerms();

        if (canLoggedUserAccess('admin')) {
          AuthService.default_owner = {
            id: 173,
            username: 'Winegrid_Winery',
            display_name: 'Winegrid_Winery',
            search_name: 'Winegrid_Winery',
            role: 'user',
            write: true,
            email: "info@winegrid.com",
          };
        } else {
          AuthService.default_owner = {
            id: AuthService.user.id,
            username: AuthService.user.username,
            display_name: AuthService.user.username,
            search_name: AuthService.user.username,
            company: AuthService.user.company,
            role: AuthService.user.role,
            write: true,
            email: AuthService.user.email,
          };
        }

        if (AuthService.user_is_admin && AuthService.presentation_mode) {
          AuthService.anonymize = true;
          AuthService.anonymize_exceptions.push(AuthService.user.id); // Never anonymize own account
        }

        normalize_viewAsOwner(AuthService.default_owner, false);

        if (AuthService.userData.last_user_id && AuthService.user?.id && AuthService.userData.last_user_id !== AuthService.user.id) {
          // User changed. Save last_user_id
          // deleteUserData(); // Does nothing. Next line resets it...
          setUserData({last_user_id: AuthService.user.id});
        }

        if (AuthService.userData.view_as_owner) {
          AuthService.view_as_owner = _.cloneDeep(AuthService.userData.view_as_owner);
          normalize_viewAsOwner(AuthService.view_as_owner, false);
        } else {
          AuthService.view_as_owner = AuthService.default_owner;

          // setUserData({view_as_owner: AuthService.default_owner});
        }
        set_notification_targets()

        AuthService.selected_view_as_owner = AuthService.view_as_owner;
        // Override userRole with the one View_as
        AuthService.view_as_owner_role = userRoles[AuthService.view_as_owner?.role || 'user'];

        if (AuthService.view_as_owner.role == 'distributor') {
          AuthService.homeState = 'app.manage.licenses';
        } else {
          if (AuthService.homeState == 'app.manage.licenses') {
            AuthService.homeState = 'app.devices.dashboard';
          }
        }

        $rootScope.tableview_sensors_list = _.cloneDeep(TABLEVIEW_SENSORS_LIST);


        if (WG_debug) console.log('AuthService init()', AuthService.view_as_owner, $state.params, $stateParams);

        // Detects when back/forward button causes 'user' to change in the URL
        // If we have the selected user's data, set it on userData and reload. Prevents one reload. Else reload without userData.
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
              if ($rootScope.ongoingStateChange !== false) {
                // Do nothing when first loading (undefined) or change was ordered by us (state.go, etc)
                return;
              }
              $rootScope.ongoingStateChange = true;

              if (WG_debug) console.log('AuthService $stateChangeStart', {
                fromState: fromState,
                fromParams: fromParams,
                toState: toState,
                toParams: toParams,
              });
              if (toState) {
                // User defined in URL and userData and Different
                if (toParams?.['user']) {
                  let new_user_id = parseInt(toParams['user']);
                  let _prev_user_id = AuthService.userData?.view_as_owner?.id || 0;
                  if (_prev_user_id > 0 && new_user_id != _prev_user_id) {
                    let new_owner: IViewAsOwner = null;

                    if (_.size(AuthService.shared_owners) > 1) {
                      new_owner = _.find(AuthService.shared_owners, {id: new_user_id});
                      if (!new_owner) {
                        // We have a list of valid users and selected one is not valid. Roll'it'back
                        console.error("Error detected! Possible user-injection. Falling back to previous user");
                        update_url({'user': _.toString(_prev_user_id)}, false, false, true);
                        return;
                      }
                    }
                    if (new_owner) {
                      if (WG_debug) console.warn("AuthService $stateChangeStart. User has changed via Back/Forward. Configuring and reloading.", fromParams['user'], ' -> ', toParams['user'], AuthService.view_as_owner);
                    } else {
                      if (WG_debug) console.warn("AuthService $stateChangeStart. User has changed via Back/Forward. Not on list. Reloading.", fromParams['user'], ' -> ', toParams['user'], AuthService.view_as_owner);
                      // leave new_owner null, removes from userData and will cause a get of new list of valid users
                      window.location.reload();
                      return;
                    }
                    // Set or deletes user config (if  null/undefined)
                    // setUserData({view_as_owner: new_owner});
                    // AuthService.view_as_owner = new_owner;
                    // AuthService.view_as_owner_role = userRoles[AuthService.view_as_owner?.role || 'user'];

                    // let _parameters = {'user': _.toString(new_user_id)};
                    update_url(toParams, true, true, undefined, undefined, toState);
                    // window.location.reload();
                  }
                }
              }
            }
        );
        // Detects when a new controller finishes loading and its $state.params are available
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
          $rootScope.ongoingStateChange = false;
          if (WG_debug) console.log('AuthService $stateChangeSuccess', {
            fromState: fromState,
            fromParams: fromParams,
            toState: toState,
            toParams: toParams,
          });
          if (toState) {
            synchronize_state();
          }
        });
        // Detects when a new controller finishes loading and its $state.params are available
        let prev_user_id = 0;
        $rootScope.$watch('$state.params', function (new_data, old_data) {
          if ($rootScope.ongoingStateChange === true || _.isEmpty(new_data)) {
            // Do nothing while state is still loading, otherwise we cancel the transition
            return;
          }
          if (!new_data['user'] || !_.isFinite(parseInt(new_data['user']))) {
            if (WG_debug) console.log("AuthService $state.params available, but invalid user given", new_data?.['user']);
            return;
          }
          if (parseInt(new_data['user']) == prev_user_id) {
            // if (WG_debug) console.log("AuthService $state.params available, but user didn't change", prev_user_id);
            return;
          }
          prev_user_id = parseInt(new_data['user']);
          if (WG_debug) console.log('AuthService $state.params available. New user: ', prev_user_id, {
            new_data: new_data,
            old_data: old_data,
          });
          if ($state.params) {
            synchronize_state();
          }
        }, true);

      }

      function synchronize_state() {
        if ($rootScope.ongoingStateChange !== true && !_.isEmpty(AuthService.shared_owners) && !_.isEmpty($state?.params)) {
          if (WG_debug) console.log('AuthService synchronize_state. Ongoing: ' + $rootScope.ongoingStateChange + ', owners: ' + _.size(AuthService.shared_owners) + ", StatePrams: " + !_.isEmpty($state.params), $state.params);

          // We have everything.
          get_best_view_as_owner();
        }
      }

      function login(username, password) {
        return $http.post('api/auth/login/', {username: username, password: password}).then(
            function (response: ng.IHttpPromiseCallbackArg<{ user: IUser, token: IToken }>) {
              AuthService.response = response;
              if (!!response.data.token) {
                $rootScope.ongoingStateChange = true; // This prevents login from being canceled with an update_url
                // setup token
                AuthService.setToken(response.data.token);
                AuthService.isLogged = true;
                // update user
                AuthService.setUser(response.data.user);
                AuthService.init();
                return response.data;
              } else {
                return $q.reject(response.data);
              }
            }, function (response) {
              AuthService.response = response;
              console.error(response.data);
              if (response.status == 401 && response.data?.detail == "User account is disabled.") {
                $state.go('page.disabled', {user: null});
              }
              return $q.reject(response.data);
            });
      }

      // function resolvePendingState(httpPromise) {
      //   var checkUser = $q.defer(),
      //       self = this,
      //       pendingState = self.pendingStateChange;
      //
      //   // When the $http is done, we register the http result into loginHandler, `data` parameter goes into loginService.loginHandler
      //   httpPromise.success(function (response) {
      //     if (WG_debug) console.warn('resolvePendingState httpPromise success', response);
      //     AuthService.response = response;
      //     // setup token
      //     AuthService.setToken(response.data.token);
      //     // flag true on isLogged
      //     AuthService.isLogged = true;
      //     // update user
      //     AuthService.setUser(response.data.user);
      //     return response;
      //   });
      //
      //   httpPromise.then(
      //       function success(response) {
      //         if (WG_debug) console.warn('resolvePendingState httpPromise then success', response);
      //         AuthService.response = response;
      //         self.doneLoading = true;
      //         // duplicated logic from $stateChangeStart, slightly different, now we surely have the userRole informations.
      //         if (pendingState.toState.accessLevel === undefined || pendingState.toState.accessLevel.bitMask & self.userRole.bitMask) {
      //           checkUser.resolve();
      //         } else {
      //           checkUser.reject('unauthorized');
      //         }
      //       }, function reject(response) {
      //         if (WG_debug) console.warn('resolvePendingState httpPromise then reject', response);
      //         AuthService.response = response;
      //         checkUser.reject(response.status + '');
      //       }
      //   );
      //   /**
      //    * I setted up the state change inside the promises success/error,
      //    * so i can safely assign pendingStateChange back to null.
      //    */
      //   self.pendingStateChange = null;
      //   return checkUser.promise;
      // }

      function logout() {
        return $http.post<IDataPostResponse>('api/auth/logout/', {}).then(
            function (response) {
              AuthService.response = response;
              AuthService.deleteToken();
              AuthService.deleteUser();
              // setUserData({view_as_owner: null});
              AuthService.userRole = userRoles.public;
              AuthService.view_as_owner_role = userRoles.public;
              AuthService.user = null;
              AuthService.userData = {};
              AuthService.isLogged = false;
              // window.location.reload();
              $state.go(AuthService.loginState, {user: null}, {location: false, reload: true});
              return response;
            }, function (response) {
              AuthService.response = response;
              AuthService.deleteToken();
              AuthService.deleteUser();
              // setUserData({view_as_owner: null});
              AuthService.userRole = userRoles.public;
              AuthService.view_as_owner_role = userRoles.public;
              AuthService.user = null;
              AuthService.userData = {};
              AuthService.isLogged = false;
              console.error(response);
              // window.location.reload();
              $state.go(AuthService.loginState, {user: null}, {location: false, reload: true});
              return $q.reject(response);
            });
      }

      function register(username: string, email: string, password1: string, password2: string, activation_token: string, company: string, country: string, distributor: string) {
        return $http.post('api/auth/registration/', {
          username: username,
          email: email,
          password1: password1,
          password2: password2,
          activation_token: activation_token,
          company: company,
          country: country,
          language: $rootScope.language.selected?.localeId,
          user_distributor: distributor
        }).then(
            function (response) {
              AuthService.response = response;
              return response.data;
            }, function (response) {
              AuthService.response = response;
              console.error(response.data);
              return $q.reject(response.data);
            });
      }

      function recover(email) {
        return $http.post('api/auth/password/reset/', {email: email}).then(
            function success(response) {
              AuthService.response = response;
              return response.data;
            }, function reject(response) {
              AuthService.response = response;
              console.error(response.data);
              return $q.reject(response.data);
            });
      }

      function recover_confirmation(uid, token, new_password1, new_password2) {
        return $http.post('api/auth/password/reset/confirm/', {
          uid: uid,
          token: token,
          new_password1: new_password1,
          new_password2: new_password2
        }).then(
            function success(response) {
              AuthService.response = response;
              return response.data;
            }, function reject(response) {
              AuthService.response = response;
              console.error(response.data);
              return $q.reject(response.data);
            });
      }

      function account_confirmation(key) {
        return $http.post('api/auth/registration/verify-email/', {
          key: key
        }).then(
            function success(response) {
              AuthService.response = response;
              return response.data;
            }, function reject(response) {
              AuthService.response = response;
              console.error(response.data);
              return $q.reject(response.data);
            });
      }

      function resend_confirmation(username, password) {
        return $http.post('api/auth/login/?resend_confirmation=t', {
          username: username,
          password: password
        }).then(
            function success(response) {
              AuthService.response = response;
              return response.data;
            }, function reject(response) {
              AuthService.response = response;
              console.error(response.data);
              return $q.reject(response.data);
            });
      }

      // Gets User's details: name, api_key, groups, last-logins, etc.
      function me() {
        console.log("Calling auth.me()");
        return $http.get<IUser>('api/auth/me/', {}).then(
            function meSuccessFn(response) {
              AuthService.response = response;
              AuthService.setUser(response.data);
              get_shared_owners_list(get_best_view_as_owner);
              return response;
            }, function meErrorFn(response) {
              AuthService.response = response;
              console.error(response);
              return $q.reject(response);
            }) as Promise<ng.IHttpPromiseCallbackArg<IUser>>;
      }

      /**
       * Checks if logged-in User's role can access a level
       * Similar to canAccess(), but doesn't check view_as_owner's role, only logged user
       * @param accessLevelName 'user', 'admin', 'dev' or 'distributor'
       */
      function canLoggedUserAccess(accessLevelName) {
        if (accessLevelName == 'dev') {
          if (!(isDev() && isBeta())) {
            return false;
          }
          accessLevelName = 'admin';
        }

        let accessLevel = accessLevels?.[accessLevelName];

        // Return false if there's no access level defined
        if (_.isNil(accessLevel)) {
          console.error("accessLevel undefined!");
          return false;
        }

        return !!(accessLevel.bitMask & AuthService.userRole.bitMask);
      }

      /**
       * Checks if the logged user can access a level
       * If view_as_client is selected, checks if the selected client's role can access a level
       * @param target_accessLevelName 'user', 'admin', 'dev' or 'distributor'
       */
      function canAccess(target_accessLevelName: string = 'admin') {
        if (target_accessLevelName == 'dev') {
          // Should be a Developer, be in Beta/Dev site, and have Admin level
          if (!(isDev() && isBeta())) {
            return false;
          }
          target_accessLevelName = 'admin';
        } else if (target_accessLevelName == 'debug') {
          // Should be a Developer, be in Beta/Dev site, have Debug active (by default only localhost/index_debug.html), and have Admin level
          if (!(isDev() && isBeta() && WG_debug)) {
            return false;
          }
          target_accessLevelName = 'admin';
        } else if (target_accessLevelName == 'beta') {
          // Should be in Beta or have Debug active (by default only localhost/index_debug.html)
          if (!(isBeta() || WG_debug)) {
            return false;
          }
          target_accessLevelName = 'user';
        }


        let target_accessLevel = accessLevels?.[target_accessLevelName];

        // Return false if there's no access level defined
        if (_.isNil(target_accessLevel)) {
          console.error("accessLevel undefined!");
          return false;
        }

        if (AuthService.view_as_owner_role && AuthService.view_as_owner_role.title == 'distributor') {
          // If distributor is selected, see only distributor stuff.
          return !!(target_accessLevel.bitMask & AuthService.view_as_owner_role.bitMask);
        } else if (AuthService.userRole?.title == 'admin' || AuthService.userRole?.title == 'staff') {
          if (AuthService.clients_view) {
            // Admin, but choosing to see as regular user level
            // return !!(accessLevel.bitMask & AuthService.view_as_owner_role.bitMask);
            return !!(target_accessLevel.bitMask & userRoles['user'].bitMask);
          } else {
            // Admins have access to admin-level, regardless of selected user
            return !!(target_accessLevel.bitMask & AuthService.userRole.bitMask);
          }
        } else if (AuthService.view_as_owner_role &&
            AuthService.view_as_owner_role.title != 'admin' && AuthService.view_as_owner_role.title != 'staff') {
          // Regular users have access to the selected user's permissions (if non-admin)
          return !!(target_accessLevel.bitMask & AuthService.view_as_owner_role.bitMask);
        }
        return !!(target_accessLevel.bitMask & AuthService.userRole?.bitMask);
      }

      function anonymize_entity_recursive(entity_type, entry: IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm | IUser) {
        let hash_length = 4;
        if ("description" in entry && entry.description === null) {
          if (WG_debug) console.log("Anonymization in Loop detected");
          return;
        }

        if ("name" in entry && entry.name)
          entry.name = entity_type + " " + hash_md5(entry.id || entry.name, hash_length);
        if ("username" in entry && entry.username)
          entry.username = entity_type + " " + hash_md5(entry.id || entry.username, hash_length);
        if ("email" in entry && entry.email)
          entry.email = "email@invalid.com";
        if ("sn" in entry && entry.sn)
          entry.sn = hash_md5(entry.sn, hash_length);
        if ("content_type" in entry && entry.content_type)
          entry.content_type = null;
        if ("description" in entry)
          entry.description = null;


        if ("place" in entry && entry.place)
          anonymize_entity_recursive('Place', entry.place);

        if ("unit" in entry && entry.unit)
          anonymize_entity_recursive('Unit', entry.unit);
        if ("units" in entry && !_.isEmpty(entry.units)) {
          for (let _sub_entry of entry.units) {
            anonymize_entity_recursive('Unit', _sub_entry);
          }
        }

        if ("device" in entry && entry.device) {
          // @ts-ignore
          anonymize_entity_recursive('Device', entry.device);
        }
        if ("devices" in entry && !_.isEmpty(entry.devices)) {
          for (let _sub_entry of entry.devices) {
            anonymize_entity_recursive('Device', _sub_entry);
          }
        }

        if ("groups" in entry && !_.isEmpty(entry.groups)) {
          for (let _sub_entry of entry.groups) {
            anonymize_entity_recursive('Group', _sub_entry);
          }
        }

        if ("owner" in entry && !_.isEmpty(entry.owner)) {
          anonymize_entity_recursive('User', entry.owner);
        }

        if ("process" in entry && entry.process)
          anonymize_entity_recursive('Process', entry.process);
        if ("processes" in entry && !_.isEmpty(entry.processes)) {
          for (let _sub_entry of entry.processes) {
            anonymize_entity_recursive('Process', _sub_entry);
          }
        }

        if ("batch" in entry && entry.batch)
          anonymize_entity_recursive('Batch', entry.batch);

        // @ts-ignore
        if ("alarm" in entry && entry.alarm) {
          // @ts-ignore
          anonymize_entity_recursive('Alarm', entry.alarm);
        }
        if ("alarms" in entry && _.isArray(entry.alarms) && !_.isEmpty(entry.alarms)) {
          for (let _sub_entry of entry.alarms) {
            anonymize_entity_recursive('Alarm', _sub_entry);
          }
        }
      }

      function anonymize_lkm(stream, payload) {
        if (!stream || !payload || !this.anonymize || this.anonymize_exceptions.includes(this.view_as_owner.id)) {
          return;
        }

        if (stream === 'device_configs' && payload.value?.wifi?.PASS) {
          payload.value.wifi.PASS = "WiFi_Password";
        }
        if (stream === 'device_configs' && payload.value?.wifi?.SSID) {
          payload.value.wifi.SSID = "WiFi_SSID";
        }
        if (stream === 'configs' && payload.value?.wifi?.PASS) {
          payload.value.wifi.PASS = "WiFi_Password";
        }
        if (stream === 'configs' && payload.value?.wifi?.SSID) {
          payload.value.wifi.SSID = "WiFi_SSID";
        }
        if (stream === 'SSID' && payload.value) {
          payload.value = "WiFi_SSID";
        }
        if (stream === 'WIFI_SSID' && payload.value) {
          payload.value = "WiFi_SSID";
        }
      }

      function anonymize_entity(entity_type, entry: IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm) {
        if (this.anonymize && !this.anonymize_exceptions.includes(this.view_as_owner.id)) {
          this.anonymize_entity_recursive(entity_type, entry);
        }
      }

      function anonymize_entities(entity_type, entries: (IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm)[]) {
        if (this.anonymize && !this.anonymize_exceptions.includes(this.view_as_owner.id)) {
          for (let entry of entries) {
            this.anonymize_entity_recursive(entity_type, entry);
          }
        }
      }

      function isDev() {
        return _.includes([
          '5fb72afe-6415-4db4-a95b-0b697b04e84a', // joao.rodrigues
          'c1de0e12-57f6-46ee-89e4-b5fa57871c91', // pedro.costa
          'cfe83ee3-3dda-4d36-9db3-d94e14852b75', // lucia.bilro
          'c235587f-77f4-4f10-a81c-15b99609150a', // dduarte
          '96162328-4cd2-4b38-bca8-e59772c8475b', // fabio.goncalves
          'd2fe8c5d-a843-49aa-9b84-9188e85d02ef', // mario.angelo
          '866deaec-da65-4a25-bdef-542d362f8f9a', // gabriel.salvador
          '2de07612-60be-4e50-9c60-666fcd992513', // joao.marques
          '1d6b8ddb-9760-46a5-a2bd-bfff21431d50', // zulmira.neves
          '116f59f2-d676-423a-9f03-cf40c3b67ecb' // dev
        ], AuthService.user?.uuid);
      }

      function isDemoAccount() {
        return _.includes([
          173, // Winegrid_Winery
          339, // Winegrid_Winery_Enartis
        ], AuthService.view_as_owner?.id || AuthService.user?.id);
      }


      function getUserData(): IUserData {
        AuthService.userData = parseData(localStorage.getItem('userData'), {}) as object;
        return AuthService.userData;
      }

      function setUserData(userData) {
        angular.extend(AuthService.userData, userData);
        return localStorage.setItem('userData', JSON.stringify(AuthService.userData));
      }


      function deleteUserData() {
        return localStorage.removeItem('userData');
      }

      /**
       * Save come configs that should persist through the App lifecycle.
       * Some are also saved at local_storage, those that represent a user preference
       * @param new_dashboardConfigs object with new_configs to merge and save
       **/
      function setDashboardConfigs(new_dashboardConfigs: IDashboardConfigs) {
        _.assign(AuthService.dashboardConfigs, new_dashboardConfigs);
        let persistent_dashboardConfigs = _.pick(AuthService.dashboardConfigs, [
          'overview_viewType',
          'overview_sort',
        ]);
        return localStorage.setItem('dashboardConfigs', JSON.stringify(persistent_dashboardConfigs));
      }

      function getDashboardConfigs(): IDashboardConfigs {
        AuthService.dashboardConfigs = _.assign(AuthService.dashboardConfigs, parseData(localStorage.getItem('dashboardConfigs'), {}));
        return AuthService.dashboardConfigs;
      }

      function viewing_as_other_owner() {
        return (AuthService.view_as_owner && AuthService.view_as_owner.id !== AuthService.user.id);
      }

      // Get a descriptive Company name
      function get_company_name(company, email) {
        let _company = company;
        if (email && _.isEmpty(_company)) {
          let _from = _.indexOf(email, '@');
          let _till = _.lastIndexOf(email, '.');
          _till = _till > 0 ? _till : email.length;

          _company = email.substring(_from + 1, _till);
        }
        if (_company.includes('gmail')
            || _company.includes('outlook')
            || _company.includes('hotmail')
            || _company.includes('yopmail')
            || _company.includes('invalid')
            || _company.includes('sapo')) {
          _company = null;
        }

        return _company;
      }

// Gets list of shared accounts, passing it to callbackFn() when available
      function get_shared_owners_list(callbackFn) {

        if (AuthService.user_is_admin) {
          // AuthService.shared_owners = emptyOrCreateArray(AuthService.shared_owners);
          $http.get<IViewAsOwner[]>('api/auth/users/all/', {}).then(
              function (response) {
                AuthService.shared_owners = emptyOrCreateArray(AuthService.shared_owners);
                AuthService.shared_owners.push({
                  id: -2,
                  username: $translate.instant("app.common.ALL"),
                  display_name: $translate.instant("app.common.ALL") + " - " + $translate.instant("app.profile.shared_list.READ_ONLY"),
                  search_name: $translate.instant("app.common.ALL") + " - " + $translate.instant("app.profile.shared_list.READ_ONLY"),
                  write: false,
                  email: null,
                });
                AuthService.shared_owners.push({
                  id: -1,
                  username: "All DANGEROUS",
                  display_name: $translate.instant("app.common.ALL") + " - DANGEROUS",
                  search_name: $translate.instant("app.common.ALL") + " - DANGEROUS",
                  write: true,
                  email: null,
                });

                let _distributors: IViewAsOwner[] = [];

                // _distributors.push({
                //   id: -4,
                //   username: $translate.instant("app.common.ALL") + " Dists",
                //   display_name: "DIST - " + $translate.instant("app.common.ALL") + " - " + $translate.instant("app.profile.shared_list.READ_ONLY"),
                //   role: 'distributor',
                //   write: false,
                //   email: null,
                // });

                // let _deactivated: IViewAsOwner[] = [];

                // 'api/auth/users/all/' API doesn't specify WRITE/READ permissions. Since we are an Admin, set all to Write_.forEach(response.data, function (owner) {
                _.forEach(response.data, function (owner) {
                  if (owner.read === false) {
                    // Don't show users which we don't even have Read Access
                    return;
                  }

                  normalize_viewAsOwner(owner, true);

                  let _entry: IViewAsOwner = {
                    // @ts-ignore
                    id: parseInt(owner.id),
                    username: owner.username,
                    display_name: owner['display_name'],
                    search_name: owner['search_name'] || owner['display_name'],
                    company: owner.company || null,
                    role: owner.role || 'user',
                    write: (owner.write !== false),
                    email: owner.email,
                    uuid: owner.uuid || null,
                  };

                  // For Admins, list deactivated accounts at the end
                  if (owner.role == 'distributor') {
                    _distributors.push(_entry);
                  } else if (owner.role == 'deactivated') {
                    // _deactivated.push(_entry);
                  } else {
                    AuthService.shared_owners.push(_entry);
                  }
                });

                if (isBeta()) {
                  AuthService.shared_owners.push(..._distributors);
                  // AuthService.shared_owners.push(..._deactivated);
                }
                callbackFn(AuthService.shared_owners);
              }, function (response) {
                console.error("Failed to load owners");
                AuthService.shared_owners = emptyOrCreateArray(AuthService.shared_owners);
                callbackFn(AuthService.shared_owners);
              });
        } else if (!_.isEmpty(AuthService.user.shared_owners_relation)) { //not admin
          AuthService.shared_owners = emptyOrCreateArray(AuthService.shared_owners);
          AuthService.shared_owners.push(AuthService.default_owner);
          AuthService.shared_owners.push({
            id: -2,
            username: $translate.instant("app.common.ALL"),
            display_name: $translate.instant("app.common.ALL") + " - " + $translate.instant("app.profile.shared_list.READ_ONLY"),
            search_name: $translate.instant("app.common.ALL") + " - " + $translate.instant("app.profile.shared_list.READ_ONLY"),
            write: false,
            email: null,
          });
          // AuthService.shared_owners.push({id: -1, username: "All", write: true});
          _.forEach(AuthService.user.shared_owners_relation, function (relation) {
            if (!relation.owner)
              return;

            if (relation.owner.id == AuthService.default_owner.id)
              return;

            if (relation.owner.role == 'deactivated')
              return;

            if (relation.read === false) {
              // Don't show users which we don't even have Read Access
              return;
            }

            let _view_as: IViewAsOwner = relation.owner;
            if (_view_as.role && AuthService.user.role != _view_as.role) {
              return; // Don't allow normal users to see distributors, and vice-versa, even in Beta, for now
            }
            if (_view_as.role && _view_as.role == 'distributor' && !isBeta()) {
              return; // Distributors only visible in Beta Dashboard
            }

            if (_.isNil(_view_as.write)) {
              _view_as.write = relation.write;
            }

            normalize_viewAsOwner(_view_as as unknown as IViewAsOwner, true);

            let _entry: IViewAsOwner = {
              // @ts-ignore
              id: parseInt(_view_as.id),
              username: _view_as.username,
              display_name: _view_as.display_name,
              search_name: _view_as.search_name || _view_as.display_name,
              company: _view_as.company || null,
              role: _view_as.role || 'user',
              write: _view_as.write !== false,
              email: _view_as.email,
              uuid: _view_as.uuid || null,
            };

            AuthService.shared_owners.push(_entry)
          })
          callbackFn(AuthService.shared_owners);
        } else {
          AuthService.shared_owners = emptyOrCreateArray(AuthService.shared_owners);
          AuthService.shared_owners.push(AuthService.default_owner);
          callbackFn(AuthService.shared_owners);
        }

        return;
      }


      function get_best_view_as_owner(_valid_owners = AuthService.shared_owners, _userData = getUserData()) {
        if (WG_debug) console.log("get_best_view_as_owner");
        if (_.isEmpty($state.params)) {
          if (WG_debug) console.log("get_best_view_as_owner. State.params still Empty!");
          return; // Not Yet
        }
        if (_.isEmpty(_valid_owners)) {
          if (WG_debug) console.log("get_best_view_as_owner. _valid_owners list is still Empty!");
          return;
        }
        // We have everything needed. We must leave this function with a valid user selected.
        let _new_view_as_owner: IViewAsOwner = null;
        let _reload = false;
        let _replace = false;

        if ($state.params?.['user']) { // Get from Params
          _new_view_as_owner = _.find(_valid_owners, {id: parseInt($state.params['user'])});
          if (_new_view_as_owner)
            if (WG_debug) console.log("get_best_view_as_owner. Got User from URL parameters", _new_view_as_owner);
        }
        if (!_new_view_as_owner && _userData.view_as_owner) {
          _new_view_as_owner = _.find(_valid_owners, {id: _userData.view_as_owner.id});
          if (_new_view_as_owner) {
            if (WG_debug) console.log("get_best_view_as_owner. Got User from userData", _new_view_as_owner);
          }
        }
        if (!_new_view_as_owner) {
          _new_view_as_owner = AuthService.default_owner;
          if (WG_debug) console.log("get_best_view_as_owner. Got User from Defaults", _new_view_as_owner);
        }

        if (_new_view_as_owner && !$state.params?.['user']) {
          _replace = true; // None was present in URL. Replace history
        }
        if (!_userData.view_as_owner ||
            _new_view_as_owner.id != _userData.view_as_owner.id ||
            _new_view_as_owner.username != _userData.view_as_owner.username ||
            _new_view_as_owner.email != _userData.view_as_owner.email ||
            _new_view_as_owner.role != _userData.view_as_owner.role) {
          if (WG_debug) console.log('get_best_view_as_owner. New View_as_user, set in userData.', _userData.view_as_owner?.id, " to ", _new_view_as_owner?.id);
          setUserData({view_as_owner: _new_view_as_owner});
        }
        if (_new_view_as_owner.id != AuthService.view_as_owner.id ||
            _new_view_as_owner.username != AuthService.view_as_owner.username ||
            _new_view_as_owner.email != AuthService.view_as_owner.email ||
            _new_view_as_owner.role != AuthService.view_as_owner.role) {
          console.log('get_best_view_as_owner. New View_as_user set. Reloading!', _new_view_as_owner);
          AuthService.view_as_owner = _new_view_as_owner;
          AuthService.view_as_owner_role = userRoles[AuthService.view_as_owner?.role || 'user'];
          _reload = true;
          // If the user was already in the URL but not configured, we must replace current browser history entry.
          if (_new_view_as_owner.id == $state.params['user']) {
            _replace = true;
          }
        }
        if (_reload || _replace || _new_view_as_owner.id != $state.params['user']) {
          if (WG_debug) console.log("get_best_view_as_owner. Adding user to URL" + (_reload ? " and Reloading" : ""));
          update_url({'user': _.toString(_new_view_as_owner.id)}, false, _reload, _replace);
        }
        if (WG_debug) console.log("get_best_view_as_owner. Done");
      }

      function isLoginState(state) {
        return state === AuthService.loginState;
      }

      function isPageState(state) {
        return state === AuthService.registerState ||
            state === AuthService.recoverState ||
            state === AuthService.recoverConfirmState ||
            state === AuthService.accountConfirmState;
      }

      /**
       * Helper function to alter the shown URL
       * @param new_stateParams Params to add/update or delete (null)
       * @param clear_all Replace all current state params with given
       * @param reload Also reload the entire page.
       * @param replace_history_entry Replace the last browser-history entry with the new data
       * @param _this Service/controller to get the stateParams from. Otherwise gets $state.params (all)
       * @param _new_state Target state to change to
       */
      function update_url(new_stateParams, clear_all = false, reload = false, replace_history_entry = false, _this = this, _new_state: string = null) {

        let _state: _IState = (_this?.$state || this?.$state || $state);
        if (!_state) {
          if (WG_debug) console.error("update_url. Couldn't get $state.");
          return;
        }
        let _updated_stateParams = _state.params || $state.params;

        if (!reload && obj_contained_in_obj(new_stateParams, _updated_stateParams)) {
          //if (WG_debug) console.log("update_url. Nothing to change in the URL:", new_stateParams, _updated_stateParams, clear_all, reload, replace_history_entry);
          return;
        }
        if (!!clear_all) {
          _updated_stateParams = new_stateParams;
        } else {
          _.assign(_updated_stateParams, new_stateParams);
        }

        // When we just want to force a reload, messing with windows.location is better.
        // Changing states must be done using ngRouter (e.g. $state.transitionTo)
        if (reload && !_new_state) {
          $rootScope.ongoingStateChange = true;

          if (WG_debug) console.log("update_url. Reloading to:", _.omitBy(_updated_stateParams, _.isNil), replace_history_entry, reload);

          $location.search(_updated_stateParams);

          // if (replace_history_entry) {
          //   $location.replace();
          // }
          // if (_new_state) {
          //   $location.path(_new_state);
          // }

          if (replace_history_entry) {
            window.location.replace($location.absUrl())
          } else {
            window.location.assign($location.absUrl()); // This does not reload the page if only parameters are changed, only the current controller
          }

          // if (WG_debug) console.log("update_url. Reloading:", _.omitBy(_updated_stateParams, _.isNil));
          window.location.reload(); // Ensures page is reloaded. If assign does it before, this is not executed
        } else {
          // if (WG_debug) console.log("update_url. Scheduled:", _.omitBy(_updated_stateParams, _.isNil));
          $timeout(() => {
            if (replace_history_entry) {
              // if (WG_debug) console.log("update_url. Just updating:", _.omitBy(_updated_stateParams, _.isNil), replace_history_entry, reload);
            } else {
              // if (WG_debug) console.log("update_url. Transitioning to:", _.omitBy(_updated_stateParams, _.isNil), replace_history_entry, reload);
            }
            _state.transitionTo(
                // @ts-ignore
                _new_state || _state.current.name,
                _updated_stateParams, // gets saved in URL as $stateParams
                {
                  location: replace_history_entry ? 'replace' : true, // This makes it update URL and replace current history entry, or not.
                  inherit: !clear_all,
                  relative: _state.$current,
                  // TODO: Try with notify=True instead of using $timeout
                  notify: reload, // false makes it not angular' $digest
                  reload: reload,
                }
            )
            //
            // if (reload) {
            //   setTimeout(function () {
            //     window.location.reload(); // Ensures page is reloaded. If assign does it, this is not executed
            //   }, 10);
            // }
          }, 1);
        }
      }

      if (WG_debug) console.log("AuthService Loaded");

      return AuthService;

    }]);
}