// Device status configurations
import isBeta = wg.isBeta;

const STATE_ON_TIME_WINDOW = 20 * 60 * 1000;    // Only allow OFF-status after this time. 20 mins
const ALLOWED_PROCESSING_DELAY = 5 * 60 * 1000; // This long after another parameter arrives, we consider it "MISSED". Only allow '-' or FAULT-status after this time.
const PARAMETER_MISSED_TIMEOUT = 30 * 1000;     // 30s after another parameter, is "MISSED"
const MANUAL_MEASUREMENTS_ALLOWED_DELAY = 7 * 24 * 60 * 60 * 1000;

// Parameter status configurations
const SENSOR_READ_AGE_TIMEOUT = 1 * 30 * 24 * 60 * 60 * 1000; // After 2 months is "OLD"

// Parameters Internal Names
const ALL_SENSORS_NAMES = ['BAT_TREAT', 'BAT'
  , 'WIFI_INFOS_Quality', 'WIFI_INFOS_Signal', 'WIFI_RSSI'
  , 'LORA_INFOS_Quality', 'LORA_INFOS_RSSI', 'LORA_INFOS_SNR', 'LORA_RSSI', 'LORA_SNR'
  , 'QL_TEMP', 'TEMP', 'IN_TEMP'
  , 'IN_HUMF', 'IN_HUM'
  , 'LLV_TREAT_value', 'LLVA'
  , 'LLV_TOF_TREAT_value', 'LLV_TOF'
  , 'LLVA_LAT', 'LLVA_LAT_TREAT_value', 'DISTANCE'
  , 'VOLUME_TREAT'
  , 'QL_TREAT_LDENSA_massDensity', 'LDENSA', 'LDENSAF', 'LDENSA_STD',
  , 'QL_TREAT_LDENSA_refractiveIDX'
  , 'QL_TREAT_LDENSA_sugar'
  , 'HUM'
  , 'PRESSURE_TREAT', 'PRESSA_TREAT', 'PRESSURE_20C_TREAT'
  , 'PRESSURE', 'PRESSA' // Legacy "PRESSURE"
  , 'AQ_FLOW', 'AQ_LEVEL', 'AQ_SAT', 'AQ_TDS', 'AQ_SAL', 'AQ_SG', 'AQ_EC', 'AQ_PH', 'AQ_DO'
  , 'QL_TREAT_mb_turbidity', 'QL_TREAT_turbidity', 'TURB'
  , 'QL_TREAT_b_abs', 'QL_TREAT_g_abs', 'QL_TREAT_r_abs', 'QL_TREAT_rgb'
  , 'QL_TREAT_colorIntensity', 'QL_TREAT_colorHue'
  , 'ULTSNC', 'ULTSNC_LLV', 'ETHANOL_TREAT_value'
  , 'CO2'
  , 'AI_LDENSA_FermentationKinetic2h', 'AI_LDENSA_FermentationKinetic4h', 'AI_LDENSA_FermentationKinetic8h', 'AI_LDENSA_FermentationKinetic12h', 'AI_LDENSA_FermentationKinetic24h', 'AI_LDENSA_FermentationKinetic48h'
  // , 'AI_LDENSA_FermentationKinetic6h', 'AI_LDENSA_FermentationKinetic16h', 'AI_LDENSA_FermentationKinetic36h'
  , 'PRESS_CONDUCTIVITY'
  , 'PRESS_TEMPERATURE'
  , 'PRESS_FLOW'
  , 'PRESS_PRESSURE'
  , 'PRESS_PH'
  , 'PRESS_REDOX'
  , 'PRESS_PH_TEMPERATURE'
  , 'PRESS_MICRO_SWITCH'
  , 'PRESS_VOLUME_TOTALIZER'
  , 'CTRL_TEMP_SET_POINT'
  , 'CTRL_TEMP_HYSTERESIS'
  , 'PUMP_ON_TIME_DO', 'PUMP_ON_TIME_UP'
  , 'SPECTRAL_TREAT_turbidity', 'SPECTRAL_TREAT_mb_turbidity', 'SPECTRAL_TREAT_rgb'
  , 'SPECTRAL_TREAT_CieLab_L', 'SPECTRAL_TREAT_CieLab_a', 'SPECTRAL_TREAT_CieLab_b', 'SPECTRAL_TREAT_CieLab_C', 'SPECTRAL_TREAT_CieLab_H'
  // , 'SPECTRAL_VIS_TRANS_X' , 'SPECTRAL_VIS_TRANS_Y' , 'SPECTRAL_VIS_TRANS_D' , 'SPECTRAL_VIS_TRANS_C' , 'SPECTRAL_VIS_TRANS_Z' , 'SPECTRAL_VIS_TRANS_NIR'
  // , 'SPECTRAL_IR_TRANS_X' , 'SPECTRAL_IR_TRANS_Y' , 'SPECTRAL_IR_TRANS_D' , 'SPECTRAL_IR_TRANS_C' , 'SPECTRAL_IR_TRANS_Z' , 'SPECTRAL_IR_TRANS_NIR'
  // , 'SPECTRAL_VIS_CENT_X' , 'SPECTRAL_VIS_CENT_Y' , 'SPECTRAL_VIS_CENT_D' , 'SPECTRAL_VIS_CENT_C' , 'SPECTRAL_VIS_CENT_Z' , 'SPECTRAL_VIS_CENT_NIR'
  // , 'SPECTRAL_IR_CENT_X' , 'SPECTRAL_IR_CENT_Y' , 'SPECTRAL_IR_CENT_D' , 'SPECTRAL_IR_CENT_C' , 'SPECTRAL_IR_CENT_Z' , 'SPECTRAL_IR_CENT_NIR'
  // , 'SPECTRAL_VIS_RECV_X' , 'SPECTRAL_VIS_RECV_Y' , 'SPECTRAL_VIS_RECV_D' , 'SPECTRAL_VIS_RECV_C' , 'SPECTRAL_VIS_RECV_Z' , 'SPECTRAL_VIS_RECV_NIR'
  // , 'SPECTRAL_IR_RECV_X' , 'SPECTRAL_IR_RECV_Y' , 'SPECTRAL_IR_RECV_D' , 'SPECTRAL_IR_RECV_C' , 'SPECTRAL_IR_RECV_Z' , 'SPECTRAL_IR_RECV_NIR'
  , 'WAKEUP_REASON'
  , 'PUMP_CLEAN_COUNT'
  , 'VPROG', 'VPROG_ID'
];

// SENSORS HERE ARE ALLOWED TO BE VIEWED IN CARD THROUGH PROFILE-CONFIGURATION
const TABLEVIEW_SENSORS_LIST = ['QL_TEMP', 'TEMP', 'IN_TEMP'
  , 'IN_HUMF', 'IN_HUM'
  , 'PRESSURE_TREAT', 'PRESSURE', 'PRESSURE_20C_TREAT'
  , 'LLV_TREAT_value', 'LLV_TOF_TREAT_value', 'LLVA_LAT_TREAT_value', 'DISTANCE'
  , 'LLVA', 'LLV_TOF', 'LLVA_LAT'
  , 'VOLUME_TREAT'
  , 'QL_TREAT_mb_turbidity', 'QL_TREAT_turbidity', 'QL_TREAT_rgb', 'QL_TREAT_colorIntensity', 'QL_TREAT_colorHue'
  , 'QL_TREAT_LDENSA_massDensity', 'LDENSA', 'LDENSAF', 'LDENSA_STD'
  , 'AI_LDENSA_FermentationKinetic2h', 'AI_LDENSA_FermentationKinetic4h', 'AI_LDENSA_FermentationKinetic24h', 'AI_LDENSA_FermentationKinetic48h'
  , 'HUM'
  , 'ETHANOL_TREAT_value'
  , 'CO2'
  , 'FERMENT_SIMULATOR_endTime'
  , 'MANUAL_Entries'

  , 'CTRL_TEMP_SET_POINT'
  , 'CTRL_TEMP_HYSTERESIS'
  , 'PRESS_CONDUCTIVITY'
  , 'PRESS_TEMPERATURE'
  , 'PRESS_FLOW'
  , 'PRESS_PRESSURE'
  , 'PRESS_PH'
  , 'PRESS_REDOX'
  , 'PRESS_PH_TEMPERATURE'
  , 'PRESS_MICRO_SWITCH'
  , 'PRESS_VOLUME_TOTALIZER'
  , 'SPECTRAL_TREAT_turbidity', 'SPECTRAL_TREAT_mb_turbidity', 'SPECTRAL_TREAT_rgb'
  , 'SPECTRAL_TREAT_CieLab_L', 'SPECTRAL_TREAT_CieLab_a', 'SPECTRAL_TREAT_CieLab_b', 'SPECTRAL_TREAT_CieLab_C', 'SPECTRAL_TREAT_CieLab_H'
  , 'PUMP_ON_TIME_DO', 'PUMP_ON_TIME_UP'
  , 'PUMP_CLEAN_COUNT'

  , 'BAT_TREAT', 'BAT'
  , 'WIFI_INFOS_Quality', 'WIFI_INFOS_Signal', 'WIFI_RSSI'
  , 'LORA_INFOS_Quality', 'LORA_INFOS_RSSI', 'LORA_INFOS_SNR', 'LORA_RSSI', 'LORA_SNR'
  , 'WAKEUP_REASON'
  , 'ACQUISITION_TIME'
  , 'SLEEP', 'SLEEP_interval'
];

// Default cards parameter and order.
// Also define which parameters update device.last_read
const CARDVIEW_SENSORS_LIST = ['TEMP', 'QL_TEMP'
  , 'LLV_TREAT_value', 'LLV_TOF_TREAT_value', 'LLVA_LAT_TREAT_value', 'DISTANCE'
  , 'VOLUME_TREAT'
  , 'QL_TREAT_mb_turbidity'
  , 'QL_TREAT_LDENSA_massDensity'
  , 'HUM'
  , 'PRESSURE_TREAT'
  , 'ETHANOL_TREAT_value'
  , 'CO2'
  , 'CTRL_TEMP_SET_POINT'
  , 'CTRL_TEMP_HYSTERESIS'
  , 'PRESS_CONDUCTIVITY'
  , 'PRESS_FLOW'
  , 'PRESS_PRESSURE'
  , 'PRESS_PH'
  , 'PRESS_REDOX'
  , 'PRESS_MICRO_SWITCH'
  , 'PRESS_VOLUME_TOTALIZER'
  , 'SPECTRAL_TREAT_mb_turbidity', 'SPECTRAL_TREAT_rgb'
  , 'SPECTRAL_TREAT_CieLab_L', 'SPECTRAL_TREAT_CieLab_a', 'SPECTRAL_TREAT_CieLab_b', 'SPECTRAL_TREAT_CieLab_C', 'SPECTRAL_TREAT_CieLab_H'
  , 'FERMENT_SIMULATOR_endTime'
  , 'MANUAL_Entries'
];


// Sensors here are shown in the "Table-Mode" of the "technical View"
const TECHNICAL_TABLEVIEW_SENSORS_LIST = ['QL_TEMP', 'TEMP', 'IN_TEMP'
  , 'IN_HUMF', 'IN_HUM'
  , 'LLV_TREAT_value', 'LLVA', 'LLV_TOF_TREAT_value', 'LLV_TOF', 'LLVA_LAT_TREAT_value', 'LLVA_LAT', 'DISTANCE'
  , 'VOLUME_TREAT'
  , 'QL_TREAT_mb_turbidity', 'QL_TREAT_turbidity'
  , 'QL_TREAT_rgb', 'QL_TREAT_colorIntensity', 'QL_TREAT_colorHue'
  , 'QL_TREAT_LDENSA_massDensity', 'LDENSA', 'LDENSAF', 'LDENSA_STD'
  , 'HUM'
  , 'PRESSURE_TREAT', 'PRESSURE'
  , 'ETHANOL_TREAT_value'
  , 'CO2'
  , 'AI_LDENSA_FermentationKinetic2h', 'AI_LDENSA_FermentationKinetic24h'
  , 'WIFI_INFOS_Quality', 'WIFI_INFOS_Signal', 'WIFI_RSSI', 'LORA_INFOS_Quality', 'LORA_INFOS_RSSI', 'LORA_RSSI'
  , 'BAT_TREAT', 'BAT'
  , 'PRESS_CONDUCTIVITY'
  , 'PRESS_TEMPERATURE'
  , 'PRESS_FLOW'
  , 'PRESS_PRESSURE'
  , 'PRESS_PH'
  , 'PRESS_REDOX'
  , 'PRESS_PH_TEMPERATURE'
  , 'PRESS_MICRO_SWITCH'
  , 'PRESS_VOLUME_TOTALIZER'
  , 'CTRL_TEMP_SET_POINT'
  , 'CTRL_TEMP_HYSTERESIS'
  , 'SPECTRAL_TREAT_turbidity', 'SPECTRAL_TREAT_mb_turbidity', 'SPECTRAL_TREAT_rgb'
  , 'SPECTRAL_TREAT_CieLab_L', 'SPECTRAL_TREAT_CieLab_a', 'SPECTRAL_TREAT_CieLab_b', 'SPECTRAL_TREAT_CieLab_C', 'SPECTRAL_TREAT_CieLab_H'
  , 'WAKEUP_REASON'
  , 'PUMP_CLEAN_COUNT'
];

// Manual Predictions are not included!
let SIMULATOR_STREAMS = ['FERMENT_SIMULATOR', 'MESHVINES_SIMULATOR'];
let SIMULATOR_STREAMS_MANUAL = ['FERMENT_SIMULATOR_MANUAL', 'MESHVINES_SIMULATOR_MANUAL'];

let PREDICTION_SENSORS_LIST = ['FERMENT_SIMULATOR_dens_cole'
  , 'FERMENT_SIMULATOR_active_biomass'
  , 'FERMENT_SIMULATOR_total_biomass'
  , 'FERMENT_SIMULATOR_ethanol'
  , 'FERMENT_SIMULATOR_sugar'
  , 'FERMENT_SIMULATOR_yan'
  , 'FERMENT_SIMULATOR_endTime'
];

let MESHVINES_PREDICTION_SENSORS_LIST = ['MESHVINES_SIMULATOR_EthInh'
  , 'MESHVINES_SIMULATOR_Viability'
  , 'MESHVINES_SIMULATOR_InitialNitrogen'
  , 'MESHVINES_SIMULATOR_Lag'
  , 'MESHVINES_SIMULATOR_Maintenance'
  , 'MESHVINES_SIMULATOR_nitrogen'
  , 'MESHVINES_SIMULATOR_ethanol'
  , 'MESHVINES_SIMULATOR_sugar'
  , 'MESHVINES_SIMULATOR_glucose'
  , 'MESHVINES_SIMULATOR_fructose'
  , 'MESHVINES_SIMULATOR_biomass'
  , 'MESHVINES_SIMULATOR_dens_boulton'
];

const SMARTBOX_READ_STREAMS = [
  "CPU_TEMP", "CPU_LOAD",
  "TRAFFIC",
  "PONG", "STATUS"
]

// These are removed when streams arrive, to reduce frontend processing
let REMOVED_STREAMS = ['GEOIP'
  , 'SET_DATETIME'
  , 'QL_TREAT_BAUME', 'QL_TREAT_BRIX'
  , 'MOBILE_MODEM'
  , 'FW_TEST'
  , 'CPU', 'CRONTAB', 'MAC', 'RAM_F', 'RAM_U'
  , 'DATE', 'TIME', 'SOUND', 'TMST', 'TUP', 'TXT', 'UUID', 'VAPI'
  , 'VBOOT', 'QL_VBOOT', 'QL_VPROG'
  , 'X-ESP32-PARTITION-COUNT'
  , 'X-ESP32-PARTITION-1', 'X-ESP32-PARTITION-2', 'X-ESP32-PARTITION-3', 'X-ESP32-PARTITION-4', 'X-ESP32-PARTITION-5', 'X-ESP32-PARTITION-6'
  // , 'PWR_OUT_STATUS_1_2', 'PWR_OUT_STATUS_3_4', 'PWR_ST_1_2', 'PWR_ST_3_4'
];
if (!WG_debug && !isBeta()) {
  REMOVED_STREAMS.push('FREE_HEAP', 'SLEPT');
}

// List of stream-commands sent to devices. THESE ARE ONLY USED TO CALCULATE "LAST_COMMAND"
const COMMANDS_STREAMS = [
  , 'CONFIGS', 'DEVICE_CONFIGS'
  , 'PING', 'READ_NOW'
  , 'SET_DATETIME'
  , 'SET_NEO_PIXEL_RGB'
];

// THESE ARE IGNORED WHEN CALCULATING "LAST_READ" timestamps, but not "LAST_COMM"
const AUXILIARY_STREAMS = ['BAT_TREAT', 'BAT'
  , 'WIFI_INFOS', 'WIFI_RSSI', 'RSSI', 'SNR'
  , 'LORA_INFOS', 'LORA_INFOS_RSSI', 'LORA_INFOS_SNR', 'LORA_RSSI', 'LORA_SNR'
  , 'IN_TEMP'
  , 'PONG'
  , 'VPROG', 'VPROG_ID'
  , 'ERRORS', 'FREE_HEAP', 'MODE'
  , 'SLEEP', 'SLEEP_INFOS', 'SLEPT', 'ACQUISITION_TIME'
  , 'WAKEUP_REASON'
  , 'PUMP_CLEAN_COUNT'
  , "GEOIP"
  , "CPU_TEMP", "CPU_LOAD", "TRAFFIC"
];

// THESE ARE IGNORED WHEN CALCULATING "LAST_READ" and "LAST_COMM" timestamps, and cannot be marked as FAIL/OLD
const IGNORED_STREAMS = [
  , 'CONFIGS'
  , 'DEVICE_CONFIGS' // Upstream configs, sent by device after local config or reset. Merged with device.configs by Gateway
  , 'PING', 'READ_NOW'
  , 'SET_DATETIME'
  , 'SLEEP', 'SLEEP_INFOS', 'SLEEP_INTERVAL'
  , 'STATUS', 'STATUS_TREAT'
  , 'FLAG'
  , 'PAIRING', 'AP/WPS_REG_SUCCESS', 'AP/WPS_ENROLLEE_SEEN'
  , 'CONNECTION_TEST'
  , 'PWR_OUT_STATUS_1_2', 'PWR_OUT_STATUS_3_4', 'PWR_ST_1_2', 'PWR_ST_3_4'
];


const DISABLE_FUNCTIONS_BITS = {
  'DISABLE_DENSITY': 0, // bit 1, ...001
  'DISABLE_PNEUMATIC_SYSTEM': 1, // bit 2, ...010
  'DISABLE_BACKFLOW_PROTECTION': 2, // bit 3, ...100
};

// For e-ahpros, e-bungs and smartcellars
const DISABLE_FUNCTIONS_BITS_2 = {
  'DISABLE_PWR_SW': 0, // ((uint8_t) 1 << 0), bit 1, ...0001
  'DISABLE_CONFIG_SW': 1, // ((uint8_t) 1 << 1)
  'DISABLE_ACC_INT1': 2, // ((uint8_t) 1 << 2)
  'DISABLE_ACC': 3, // ((uint8_t) 1 << 3)
};


// If any of these streams is missing in any sensor read, device is marked as FAULT (in the future, comms may happen transmitting only non-sensor data - Pairing, checking something, rescheduling, retransmitting, etc)
// Sub-Array = possible streams. First one defines the name shown on dashboard
const REQUIRED_PARAMS_PER_MODEL = {
  'user': {
    'wp': ['TEMP'],
    'smartbung': ['TEMP', ['LLV_TOF_TREAT', 'LLVA', 'LLV_TOF']],
    'aphrometer': ['TEMP', ['PRESSURE_TREAT', 'PRESSURE', 'PRESSA']],
    'smartcellar': ['TEMP', 'HUM'],
    'bp': ['TEMP'],
    'charmat': ['IN_TEMP', 'PRESSURE'],
    'press_box': [],
    'ultrasonic': [],
    'evolum': [],
  },
  'admin': {
    'wp': ['TEMP', 'IN_TEMP', ['IN_HUMF', 'IN_HUM']],
    'smartbung': ['TEMP', ['LLV_TOF_TREAT', 'LLVA', 'LLV_TOF']],
    'aphrometer': ['TEMP', ['PRESSURE_TREAT', 'PRESSURE', 'PRESSA']],
    'smartcellar': ['TEMP', 'HUM'],
    'bp': ['TEMP'],
    'charmat': ['IN_TEMP', 'PRESSURE', ['IN_HUMF', 'IN_HUM']],
    'press_box': [],
    'ultrasonic': [],
    'evolum': [],
  }
};

const ALLOWED_PARAMS_IN_ALARMS = ['BAT_TREAT', 'STATUS_TREAT', 'LORA_INFOS_RSSI', 'WIFI_RSSI']


const FIXED_ALARM_PARAMS_PER_MODEL = {
  'wp': ['TEMP', 'LLVA_LAT_TREAT_value', 'QL_TREAT_LDENSA_massDensity', 'AI_LDENSA_FermentationKinetic24h', 'STATUS_TREAT'],
  'smartbung': ['TEMP', 'LLV_TOF_TREAT', 'BAT_TREAT', 'STATUS_TREAT'],
  'aphrometer': ['TEMP', 'PRESSURE_TREAT', 'BAT_TREAT', 'STATUS_TREAT'],
  'smartcellar': ['TEMP', 'HUM', 'CO2', 'BAT_TREAT', 'STATUS_TREAT'],
  'bp': ['TEMP', 'QL_TREAT_LDENSA_massDensity', 'AI_LDENSA_FermentationKinetic24h', 'STATUS_TREAT'],
  'charmat': ['TEMP', 'PRESSURE_TREAT', 'STATUS_TREAT'],
  'press_box': [],
  'ultrasonic': [],
  'evolum': [],
  'smartbox': ['STATUS_TREAT'],
};

const DEVICE_MODELS_THAT_CAN_FORCE_READ = ['wp1100', 'wp1110', 'charmat', 'wp1101', 'wp1111', 'volume', 'sv-mw', 'press_box'];
const DEVICE_MODELS_THAT_HAS_DENSITY = ['wp1000', 'wp1100', 'wp1110', 'bp1000', 'bp1010', 'bp1011'];

const SMARTBOX_MODELS = ['smartbox', 'virtual_smartbox'];

/**
 * BitMask for received ERRORs.
 * Dict with bit-position and description
 * #define WG_ERRORS_TEMP_NOT_FOUND               0
 * #define WG_ERRORS_TEMP_READ_ERROR              1
 * #define WG_ERRORS_TEMPB_NOT_FOUND              2
 * #define WG_ERRORS_TEMPB_READ_ERROR             3
 * #define WG_ERRORS_IN_TEMPERATURE_READ_ERROR    4
 * #define WG_ERRORS_IN_HUMIDITY_READ_ERROR       5
 * #define WG_ERRORS_AMB_HUM_TEMP_READ_ERROR      6
 * #define WG_ERRORS_AMB_CO2_TEMP_HUM_READ_ERROR  7
 * #define WG_ERRORS_PRESSURE_READ_ERROR          8
 * #define WG_ERRORS_PRESSA_READ_ERROR            9
 * #define WG_ERRORS_LLVA_READ_ERROR             10
 * #define WG_ERRORS_LLV_TOF_READ_ERROR          11
 * #define WG_ERRORS_ACC_READ_ERROR              12
 * #define WG_ERRORS_BAT_READ_ERROR              13
 * #define WG_ERRORS_SPECTRAL_READ_ERROR         14
 */
const ERRORS = {
  0: "TEMP_NOT_FOUND", // 1
  1: "TEMP_READ_ERROR", // 2
  2: "TEMPB_NOT_FOUND", // 4
  3: "TEMPB_READ_ERROR", // 8
  4: "IN_TEMPERATURE_READ_ERROR", // 16
  5: "IN_HUMIDITY_READ_ERROR", // 32
  6: "LLVA_LAT_READ_ERROR", // 64
  7: "LDENSA_READ_ERROR", // 128
  8: "PRESSURE_READ_ERROR", // 256
  9: "PRESSA_READ_ERROR", // 512
  10: "LLVA_READ_ERROR", // 1024
  11: "LLV_TOF_READ_ERROR", // 2048
  12: "ACC_READ_ERROR", // 4096
  13: "BAT_READ_ERROR", // 8192
  14: "SPECTRAL_READ_ERROR", // 16384
}

let ERRORS_to_string = (value) => {
  let errors = [];
  for (let i = 0; i < 15; i++) {
    if (value & (1 << i)) {
      errors.push(ERRORS[i]);
    }
  }
  return errors.join(', ');
}