namespace wg {

  export class WgTimelineItem {
    public loading: boolean;
    public task: ITask;
    public protocol: IWinemakingProtocol;

    /**
     * The row that this task belongs to. has info about the unit and the all the tasks, completed, overdue, next and planned
     */
    public row: TimelineTableEntry;

    public TASK_STATUS_TYPES = wg.TASK_STATUS_TYPES;
    public TASK_TYPES = wg.TASK_TYPES;

    public static $inject = ['$rootScope', 'ngDialog', 'moment', '$filter', '$scope'];

    public constructor(private $rootScope: any, private ngDialog: any, private moment: any, private $filter: ng.IFilterService, private $scope: ng.IScope) {

      // console.log("WgTimelineItem constructor", this.task, this.row)
    }

    //need non-static declaration for the ng-click to work
    public onTaskClick(task: ITask, controller: any) {
      WgTimelineItem.onTaskClick(task, controller)
    }

    public static onTaskClick(task: ITask, controller: any) {
      if (!(task.status == TASK_STATUS_TYPES.overdue.id ||
          task.status == TASK_STATUS_TYPES.completed.id ||
          task.status == TASK_STATUS_TYPES.next.id
      )) {
        return;
      }

      if (WG_debug) console.log("onTaskClick", task, this);

      controller.loading = true;
      let _protocol: IWinemakingProtocol = controller.protocol || (controller.row && controller.row['protocol']) || controller.row?.current_stage?.protocol || controller?.row?.unit?.protocol || task.unit?.protocol;
      let _protocol_id = task.current_stage?.protocol;
      if (_protocol_id) _protocol = _.find(task.unit.protocols, {id: _protocol_id});

      controller.ngDialog.openConfirm({
        template: 'app/views/partials/wg-task-details.html',
        className: 'ngdialog-theme-default',
        data: {
          task: task,
          setLoading: controller.setLoading,
          protocol: _protocol,
        },
        controllerAs: 'ctrl',
        controller: WgTaskDetails

      }).then((task: ITask) => {

        //TODO: change this to two way binding
        controller.$rootScope.$emit('tasksChangedStatus', {task: task});

      }, (reason) => {
        console.log('Modal promise rejected. Reason: ', reason);
      }).finally(() => {
        controller.loading = false;
      });


    }

    public getStatusById(id) {
      return _.find(this.TASK_STATUS_TYPES, {id: id});
      // for (let key in wg.TASK_STATUS_TYPES) {
      //   if (wg.TASK_STATUS_TYPES[key] == id) {
      //     return key
      //   }
      // }
    }


    public getTaskDisplayText() {
      // If task is from another Stage, show "Stage: name"
      if (this.task.stage_template && this.task.stage_template != this.row.current_stage.template) {
        return this.$filter('translate')('app.winemaking.DUE_AT_NEXT_STAGE')
      }

      //get all rules, call getRuleDisplayText and join them with commas

      let rules = this.task.rules || []
      if ((this.task as any).rule_templates) {
        rules = rules.concat(this.task['rule_templates'])
      }

      let ruleTexts = rules.map((rule) => {
        return (this.getRuleDisplayText(rule) || "")
      })
      //remove empty strings
      ruleTexts = ruleTexts.filter((text) => text.length > 0)

      const AND = this.$filter('translate')('app.common.AND')
      let text = ruleTexts.join(" " + AND + " ")
      //remove trailing comma
      text = text.replace(/,\s*$/, "")
      //remove trailing and
      text = text.replace(new RegExp(AND + '\\s*$'), "");

      return this.$filter('translate')('app.winemaking.DUE_AT') + ": " + text

    }

    public setLoading = (loading) => {
      this.loading = loading;
    }

    public getRuleDisplayText(rule: IAlarmRule) {

      const AT = this.$filter('translate')('app.winemaking.AT_TIME')

      let currentDate = new Date();
      let due_at;
      let text = ""

      // We shouldn't change the original object here
      // if (rule.matched_at) {
      //   rule.matched_at = new Date(Date.parse(rule.matched_at as unknown as string));
      // }

      switch (rule.type_id) {

        case TASK_RULE_TYPES.elapsed_time_since_stage_start.id:
          if (rule.matched_at) {
            due_at = new Date(Date.parse(rule.matched_at as unknown as string));
            if (rule.delay > 0) {
              {
                due_at.setSeconds(due_at.getSeconds() + rule.delay)
              }
            }

            // If due at is in the current day, show time. Else show date
            if (due_at.getDate() == currentDate.getDate() && due_at.getMonth() == currentDate.getMonth()) {
              // text = "at " + this.moment(due_at).format('HH:mm')
              text = AT + " " + this.$filter('date')(due_at, 'shortTime')
            } else {
              // text = "at " + this.moment(due_at).format('YYYY/MM/DD HH:mm')
              text = AT + " " + this.$filter('date')(due_at, 'short')
            }
          } else {
            if (rule.delay > 0) {
              text += this.$filter('translate')('app.winemaking.STAGE_START') + " + " + this.moment.duration(rule.delay, 'seconds').humanize()
            }
          }


          break
        case TASK_RULE_TYPES.other_task_finished.id:
          // Already matched, so other task already finished. Just show the delay
          if (rule.matched_at) {
            due_at = new Date(Date.parse(rule.matched_at as unknown as string));
            if (rule.delay > 0) {
              {
                due_at.setSeconds(due_at.getSeconds() + rule.delay)
              }
            }

            // If due at is in the current day, show time. Else show date
            if (due_at.getDate() == currentDate.getDate() && due_at.getMonth() == currentDate.getMonth()) {
              // text = "at " + this.moment(due_at).format('HH:mm')
              text = AT + " " + this.$filter('date')(due_at, 'shortTime')
            } else {
              // text = "at " + this.moment(due_at).format('YYYY/MM/DD HH:mm')
              text = AT + " " + this.$filter('date')(due_at, 'short')
            }
          } else {

            const dependencyID = (rule as any).previous_task_template || (rule as any).previous_task;

            if (dependencyID) {
              const lookupSpace = _.flatMap(this.row.tasks)
              let taskWeDependOn = _.find(lookupSpace, {template: dependencyID})
              if (taskWeDependOn) {
                (taskWeDependOn as any).name

                // taskWeDependOn and rule.matched_at should always be defined together
                if (taskWeDependOn.completed_at) {
                  if (WG_debug) console.info("taskWeDependOn has completed_at but rule.matched_at is not defined!!", taskWeDependOn, rule)
                }
                if (taskWeDependOn.completed_at) {
                  // Deprecate this. Should not be needed
                  let due_at = new Date(taskWeDependOn.completed_at)
                  if (rule.delay > 0) {
                    {
                      due_at.setSeconds(due_at.getSeconds() + rule.delay)
                    }
                  }
                  // If due at is in the current day, show time. Else show date
                  if (due_at.getDate() == currentDate.getDate() && due_at.getMonth() == currentDate.getMonth()) {
                    // text = "at " + this.moment(due_at).format('HH:mm')
                    text = AT + " " + this.$filter('date')(due_at, 'shortTime')
                  } else {
                    // text = "at " + this.moment(due_at).format('YYYY/MM/DD HH:mm')
                    text = AT + " " + this.$filter('date')(due_at, 'short')
                  }
                } else {
                  text += this.$filter("translate")('app.winemaking.AFTER_OF') + " \"" + taskWeDependOn.name + "\"" + (rule.delay > 0 ? " + " + this.moment.duration(rule.delay, 'seconds').humanize() : "")
                }
              }
            }
          }


          break

        case TASK_RULE_TYPES.parameter_reached.id:

          let _sensor = _.find(this.$rootScope.WGSensors?.sensors, {id: rule.parameter});

          if (_sensor && _sensor.name) {
            let PARAMETER = this.$filter('translate')('app.common.PARAMETER')
            text += (_sensor.name ? _sensor.name : PARAMETER) + " " + rule.logical + " " + rule.value
          }

          break
      }

      return text

    }
  }


  App.component('wgTimelineItem', {
    templateUrl: 'app/views/directives/wg-timeline-item.html',
    controller: WgTimelineItem as any,
    controllerAs: 'ctrl',
    bindings: {
      task: '=',
      row: '<',
      loading: '=',
      protocol: '<'
    }
  })

}