// import {ICtrlScope, parseData} from "../utils/common";

/**
 * Created by pmec on 30/05/16.
 */

namespace wg {
  "use strict";

  declare var WG_debug: boolean;
  declare var WG_is_mar2protect: boolean;

    export abstract class WGObject {
        constructor(data: any) {
        // TODO: move the init logic from the child WGAlarms etc to here
      }

    // returns the object with all the nested objects
    public normalizeCopy(WGApiData: WGApiData) {
      const normalizedObject = _.cloneDeep(this);
      normalizedObject.normalizeInPlace(WGApiData);
      return normalizedObject;
    }

    public abstract normalizeInPlace(WGApiData: WGApiData) ;
  }

  export interface IRootScope extends ng.IScope {
    WG_debug: boolean;
    WG_is_beta: boolean;
    WG_is_mar2protect: boolean;

    apiKey: string;
    base_url: string;
    // When the last data arrived from the server, to detect stalled info
    last_data_received: number;

    lastKnownMessages: { [device_uuid: string]: ILastKnownMessages };
    lastSensorValues: { [device_uuid: string]: ILastSensorValues };

    WGApiData: WGApiData;
    WGPlaces: WGApiData['WGPlaces'];
    // WGPlaces2: WGPlaces;
    WGUnits: IWGApiUnits;
    WGDevices: IWGApiDevices;
    WGSensors: IWGApiSensors;
    WGProcesses: IWGApiProcesses;
    WGBatches: IWGApiBatches;
    WGAlarms: IWGApiAlarms;

    cardview_sensors_list: string[];
    tableview_sensors_list: string[];

    mqtt_status?: string;
    mqtt_status_color?: 'lawngreen' | 'green' | 'blue' | 'red' | 'yellow' | 'grey';
    mqtt_status_extra?: string;
    mqtt_status_extra_2?: string;

    last_notifications: {
      list: INotification[],
      loading: boolean,
      currentPage: INotification[],
      unread: number,
      total: number,
      refresh_page: () => void,
      settings: {
        page_size: number,
        page: number,
        pages_total: number,
      },
    };
    language: {
      selected: {
        localeId: string,
        name: string,
        iconid: string,
      },
      available: {
        [localeId: string]: {
          localeId: string,
          name: string,
          iconid: string,
        }
      }
      init: () => void,
      change: (lang: string, e: JQueryEventObject) => void,
      set: (lang: string, e: JQueryEventObject) => void,
      configLanguage: (lang: string, e: JQueryEventObject) => void,
    }
  }

  // Sensor Parameter
  export interface ISensor {
    readonly id: number;
    url?: string;
    stream: string;
    internal_name: string;
    name?: string; // If it's a i18 structure, it is already translated here.
    name_sref?: string; // i18 name to be displayed
    configs?: ISensorConfigs;

    // icon?: any;
    // str_icon?: string; // when previous icon is not defined
    admin?: boolean; // If only admin can access this sensor

    unit?: string; // nice name to be displayed 'ºC'
    unit_sref?: string; // i18 unit name to be displayed
    unit_orig?: string; // before conversion
    unit_orig_sref?: string; // before conversion

    // conversion selected by user for this sensor
    conversion?: ISensorConversion;
    // conversion_unit_name?: string; // name to be used on conversion query 'fahrenheit' 'inches'

    description?: string;
    created_at?: string;
    last_activity?: string;

    unit_type?: string;

    preferred?: boolean; // temporary flag to support ordering
  }

  export interface IGraphOptions {
    dataGrouping?: boolean;
    realtime?: boolean;

    dashStyle?: Highcharts.DashStyleValue;
    tooltip?: {
      pointFormat?: string;
    };
    minRange?: number;
    floor?: number;
    min?: number;
    max?: number;
    softMin?: number;
    softMax?: number;
  }

  export interface ISensorConfigs {
    masterSensor?: string;
    graph?: boolean;
    graph_type?: string;
    graph_options?: IGraphOptions;
    decimals?: number;
    decimals_orig?: number;

    query?: Object; // Used to get [timestamp,value] from /api/data/
    exportQuery?: Object; // Used by the Export Service
    sub_query?: Object; // Used to extract [value] from LKM

    manual?: boolean;
    accessLevel?: string;
    accessException?: string[]; // Array of user-UUIDs with access
    accessDeny?: string[]; // Array of user-UUIDs without access

    name_sref?: string; // i18 name to be displayed

    icon?: string;
    str_icon?: string; // If there's no icon, show this Text
    over_icon?: string; // Icon to show in superscript, over the main icon
    under_icon?: string; // Icon to show in subscript, under the main icon

    unit_sref?: string; // i18 unit to be displayed

    si_type?: string;
    conversions?: { [conversion_key: string]: ISensorConversion };
    convert_all_streams?: boolean;

    device_models?: string[]; // Whitelist of device models that can use this sensor
    device_models_blacklist?: string[]; // Blacklist of device models that can use this sensor

    classes?: { [key: string]: ISensor };

    // Avoid using this "any-key" entries, to allow TS to detect unexpected keys
    // [key: string]: any;
  }

  export interface ISensorConversion {
    id: string;
    decimals?: number;
    unit: string;
    unit_sref?: string;

    graph_options?: IGraphOptions;

    minRange?: number;
    floor?: number;
    min?: number;
    max?: number;
    softMin?: number;
    softMax?: number;
  }

  export interface ISensorReading {
    sensor: ISensor; // TODO: Uncycle
    internal_name: string;
    timestamp: number;
    // age?: string;
    val_orig?: string | number; // Unconverted
    val: string | number; // Textual
    val_numeric: number; // Converted Numeric value
    color?: string;
    style?: object;
    status?: "OK" | "DISABLED" | "MISSED" | "WP_BOOTING" | "DENS_NO_LIQUID" | "DENS_HIGH_STD" | "FAIL" | "OLD";
    status_sref?: string;
    // Missed = This parameter missed last sample. FAIL = This parameter missing for 3 samples.

    deleted?: boolean; // Implements soft-deletion
  }

  export interface ILastKnownMessages {
    configs?: IDeviceLKMConfigs;
    value?: any;
    created_at?: Date;
    last_activity?: Date;
    first_timestamp?: Date;
    last_timestamp?: Date;

    [stream: string]: any | IDataResult;
  }

  export interface ILastSensorValues {
    [internal_name: string]: ISensorReading;
  }

  export interface IDevice {
    readonly id: number;
    root_device?: IDevice; // Legacy, migrating to new format
    status_update_timer?: ng.IPromise<any>;

    uuid: string;
    iid: string;
    sn: string;
    internal_name?: string;
    model: "smartbox" | "virtual_smartbox" | 'wp1000' | 'wp1100' | 'wp1110' | 'wp1111' | 'bp1000' | 'bp1010' | 'bp1011' | 'press_box' | 'smartbung' | 'aphrometer' | 'echarmat' | 'smartcellar' | 'beacon';
    // Model to show in the UI, to allow translations, converting old devices to new models, etc
    model_name: string;
    name?: string;
    // Used for Demos and "Manual Entries" only
    is_virtual?: boolean;
    selected?: boolean;

    icon?: string; // Used in Overview
    tech_icon?: string; // Used in technical_view
    type?: string; // Always equal to "device"
    path: string;
    version?: string;
    version_status?: null | "OLD" | "UPDATED";
    hw_version?: string;
    url?: string;
    description?: string;
    created_at?: string;
    last_activity?: string;
    unit_type?: string;

    // Local/Browser time of the last_comm, to estimate time-drift.
    received_time?: number;
    // ms between Smartbox and Browser. Positive = Browser in the future, Smartbox behind. Not compensated for MQTT delay
    time_drift?: number;

    // Smartbox-time timestamp of the last upstream comm
    last_comm?: number;
    // Smartbox-time timestamp of the last sensor measurement
    last_read?: number;

    // Browser-time Timestamp of the last command sent to the device
    last_command?: number;

    // Time between samples in ms, based on last sleep.real_interval, not on crontab
    sample_interval?: number;
    // 0 = "incoming"/"now", NaN = Hide
    // in ms, based on last sleep.real_interval, not on crontab
    next_read?: number;


    capabilities: IDeviceCapabilities;

    via?: string; // to deprecate
    via_mode?: string; // to deprecate
    comm_status: ICommStatus;

    management_active?: boolean;
    status: "ON" | "OFF" | "FAULT" | "DEACTIVATED" | "DEMO";
    status_extra?: string;
    status_level_reading?: boolean;
    status_density_reading?: boolean;

    status_ota?: null | "PENDING" | "ONGOING";

    rotation_status?: {
      status?: "OK" | "WARN" | "ERROR",
      status_sref?: string,
      status_extra_sref?: string, // sref
      status_extra_2_sref?: string, // sref
      admin_status_extra?: string, // Can be present, even if status is "OK"
      icon?: string,
      angle?: number, // Angle to rotate symbol
      angles?: [side: number, front: number, total: number], // Array of rotation angles the device's ACC has detected
    }

    configs?: IDeviceConfigs;
    users_count?: number;
    owner?: IUser;
    groups?: IGroup[];
    groups_count?: number;

    // Might not exist, e.g. for Smartbox
    unit?: IUnit;
    // lat?: number;
    // lon?: number;
    sensors?: ISensor[];
    alarms?: IAlarm[];

    streams?: string[];
    last_known_message?: ILastKnownMessages;
    lkm?: ILastKnownMessages;

    last_values?: ILastSensorValues;
    last_card_values?: ILastSensorValues;
    cards_count: number;
  }

  // Device configurations from Django Back-end. Not updated via MQTT/Posts
  export interface IDeviceConfigs {
    graph_data_params?: IDataParams;
    // Legacy, to force a device to accept an rgb_alarm
    alarms_actions?: { neo_pixel_rgb: boolean };
    unit_view?: {
      disable_realtime: boolean;
      hide_sensors: string[];
      crontab_manual?: boolean;
    };
    can_force_read?: boolean;
    // legacy. Same as can_force_read
    force_read?: boolean;
    sn?: string;

    // Defines preferred comm mode, either WiFi or LoRa
    // mode?: ICommStatus['preferred_mode'];
    // wifi?: { // Defines default wifi network that sensors connect to
    //   MODE?: string,
    //   SSID?: string,
    //   PASS?: string
    // }
  }

  export interface IDeviceLKMConfigs {
    // Just to help IDE allow parsing of the LKM
    payload?: { value?: IDeviceLKMConfigs };

    // For smartboxes
    devices_sn?: string[], // List of paired devices
    devices_default_configs?: {
      wifi?: { // Defines default wifi network that sensors connect to
        SSID?: string,
        PASS?: string
      }
    },

    // For devices
    crontab?: string,
    crontab_automatic?: { active: boolean },
    ota?: number,
    // BitMask. ...001 = density disabled, ...010 = density disabled
    disable_functions?: number,

    mode?: ICommStatus['preferred_mode'];
    wifi?: { // Defines default wifi network that sensors connect to
      MODE?: string,
      SSID?: string,
      PASS?: string
    },

    // For density sensors
    // Number of reads when getting density
    ldensa_n_reads?: number,
    // Number of reads required to get density accurately. Defined during calibration
    ldensa_n_reads_enabled_value?: number,

    // For control boards
    ctrl_cycle_time?: number,
    ctrl_temp_mode?: number,
    ctrl_temp_hysteresis?: number,
    ctrl_temp_set_point?: number,
    ctrl_manual_output?: number,
  }

  export interface ICommStatus {
    // ALL: 'All', // For future use
    // WIFI: 'WiFi', // For battery devices: ebung, aphrometer, smartcellar
    // LORA: 'LoRa', // For battery devices: ebung, aphrometer, smartcellar
    // LORA_P2P: 'LoRa_P2P', // For WP, BP, e-charmat
    // LORA_WAN: 'LoRa_WAN', // For WP, BP, e-charmat

    // mode confirmed by the device (lkm.MODE)
    preferred_mode?: null | "WiFi" | "LoRa" | "LoRa_P2P" | "LoRa_WAN" | "All";

    last_gw_id?: number;
    last_gw?: string; // legacy: via. e.g.: "wg-gw86"
    last_mode?: ICommStatus['last_wifi_mode'] | ICommStatus['last_lora_mode']; // legacy: via_mode, e.g.: "Smartbox Wi-Fi"
    last_description?: string;

    last_wifi_description?: string;
    last_wifi_timestamp?: number;
    last_wifi_mode?: "Wi-Fi" | "Smartbox Wi-Fi" | "Local Wi-Fi";
    last_wifi_ssid?: string;
    last_wifi_gw_id?: number;
    last_wifi_gw?: string;
    last_wifi_rssi?: number;
    last_wifi_snr?: number;
    last_wifi_quality?: number;
    last_wifi_class?: string; // CSS class

    last_lora_description?: string;
    last_lora_timestamp?: number;
    last_lora_mode?: null | "LoRa" | "LoRa_P2P" | "LoRa_WAN";
    last_lora_gw_id?: number; // gw_id of the last used gw
    last_lora_gw?: string; // Name of the last used gw
    last_lora_rssi?: number;
    last_lora_snr?: number;
    last_lora_quality?: number;
    last_lora_class?: string; // CSS class
  }

  export interface IDeviceCapabilities {
    set_alarm: boolean;
    set_read_interval: boolean;
    force_read: boolean;
    config_density_read: boolean;
    control_board: boolean;
    fermentation_prediction: boolean;
    set_offsets: boolean;
    config_wifi: boolean;
  }

  export interface IPlace {
    readonly id: number;
    name?: string;
    name_sref?: string;
    type?: string; // Always equal to "place"
    description?: string;
    owner?: {
      id: number,
      username: string,
    };

    counts?: {
      devices: number,
      processes: number,
      alarms: number
    };
    last_values?: ILastSensorValues;

    units?: IUnit[]; // TODO: Uncycle
  }

  export interface IUnit {
    readonly id: number;
    name: string;
    name_sref?: string;
    type?: string; // Always equal to "unit"
    description?: string;

    unit_type: string;
    icon?: string;
    owner?: IUser;
    config_fields?: IUnitConfigs;

    lat?: number;
    lon?: number;

    priority?: number;
    alarms?: {
      //  Deprecated. user_rules are in device.alarms
      // user_rules?: object[],
      user_notifications?: object[],
      ai_notifications?: object[],
      status_notifications?: object[],
      beacon_alarms?: object[],
    };

    is_visible?: boolean; // False if filtered out of view
    selected?: boolean;

    last_values?: ILastSensorValues;

    protocol?: IWinemakingProtocol; // Not used, deprecate
    protocols?: IWinemakingProtocol[]; // All WinemakingProtocol, active or not.
    // current_stage?: IWinemakingStage; // ID of active WinemakingStage

    devices?: IDevice[]; // TODO: Uncycle
    process?: IProcess; // TODO: Uncycle
    place?: IPlace; // TODO: Uncycle
  }

  export interface IUnitConfigs {
    // is_user_favorite?: object;
    conversions?: ISensorConfigs['conversions'];
  }

  export interface IProcess {
    id: number;
    name?: string;
    type?: string; // Always equal to "process"
    description?: string;

    active?: boolean;
    started_at?: string | Date;
    ended_at?: string | Date;
    duration?: number;

    process_type?: string;
    type_other?: string;
    protocol?: IWinemakingProtocol; // if this process is a winemaking protocol
    protocol_template?: IWinemakingProtocol; // Selected protocol_template for this process

    weight?: number;
    volume?: number;

    dynamic_fields?: object;

    owner?: IUser;
    groups?: IGroup[];

    content_type?: string;
    batch?: IBatch;

    // Where the process is taking place
    unit?: IUnit;

    // History of units that have been part of this process
    units?: IUnit[];

  }


  // From back-end
  // TaskTypesId = [
  //     (0, "Other"),
  //     (1, "Action"),
  //     (2, "Add Product")
  // ]
  export const TASK_TYPES = {
    // other: {id: 0, name: "Other", sref: "Other"}, // unused
    action: {id: 1, name: "Action", sref: "app.common.ACTION"},
    add_product: {id: 2, name: "Add Product", sref: "app.winemaking.ADD_PRODUCT"}
  }


  /**
   * (0, "unknown"),
   * (1, "completed"),  # Done: == archived, quando o user marca Completed
   * (2, "overdue"),  # Overdue: quando todas as suas Rules fizerem match e passo o delay definido. O user já deve estar a faze-la.
   * (3, "next"),  # Next: quando ficarem activas, em monitorizacao
   * (4, "planned"),  # Planned: enquanto forem so Templates
   */
  export const TASK_STATUS_TYPES = {
    completed: {id: 1, text: "Completed"},
    overdue: {id: 2, text: "Overdue"},
    next: {id: 3, text: "Next"},
    planned: {id: 4, text: "Planned"},
  }


  export interface ITask {
    // TODO: Make IDs numeric
    id?: number;
    // stage: IWinemakingStage;

    description?: string;
    rules?: IAlarmRule[];
    required_to_end_stage?: boolean; // true if required to end current stage
    requires_enologist_validation: boolean; // true if requires enologist validation
    template?: number; // ID of the template task this is based on
    stage_template?: number; // Only for task_templates. ID of the template stage this is based on
    stage?: number; // ID of the stage this task belongs to

    type_id?: number;
    // Action, or Product name
    name?: string;
    // quantity sent and received from API
    quantity?: string;
    quantity_txt?: string;
    quantity_unit?: string;
    added_quantity?: number;

    // Active/History
    status?: number | 1 | 2 | 3 | 4; // One of TASK_STATUS_TYPES
    archived?: boolean; // Belongs to history
    ignored?: boolean;
    user_notes?: string;
    template_notes?: string;
    due_at?: Date; // From backend, When Rules are met, or ESTIMATED in the future
    completed_at?: Date; // When user mark as done

    // Frontend helpers
    nextTask_ids?: ITask['id'][]; //reference to a task that has a rule with this task as a dependency
    current_stage?: IWinemakingStage;
    unit?: IUnit; // if currently associated with a unit
    notification_targets?: INotificationTarget[];
  }


  export interface IWinemakingStage {
    id?: number;
    name: string;
    description?: string;
    // nextStage_id?: number; // Linked List or indexed list
    previous_stage_template?: number; // Linked List or indexed list
    tasks?: ITask[];

    // For template stages:
    protocol_template?: number; // ID of the template protocol this is based on

    // For active stages:
    protocol?: number; // ID of the protocol this stage belongs to
    template?: number; // ID of the template stage this is based on

    owner?: IUser;
    configs?: object;

    // Active/History
    active?: boolean;
    started_at?: string | Date;
    ended_at?: string | Date;
    unit?: IUnit; // if undefined, this is a template stage
  }

  export interface IWinemakingProtocol {
    id: number;
    name: string;
    description: string;
    stages: IWinemakingStage[];
    wine_type_id?: number | 0 | 1 | 2 | 3; // From API. One of WINE_TYPES
    wine_type_other?: string; // From API
    wine_type_text?: string; // translated, frontend only
    sharing_codes?: string[]; // Created sharing codes for this protocol
    sharing_code?: string[]; // Legacy. renamed. Delete soon
    template?: string; // ID of the template protocol this is based on

    owner?: IUser;
    configs?: object;

    // Active/History
    archived?: boolean;
    started_at?: string | Date;
    ended_at?: string | Date;
    batch?: IBatch;
    unit: IUnit;
    initial_weight?: number;
    initial_volume?: number;
  }


  /**
   *     (0, 'Other'),
   *     (1, 'Red'),
   *     (2, 'White'),
   *     (3, 'Rose'),
   *     (4, 'Sparkling'),
   *     (5, 'Fortified'),
   */
  export const WINE_TYPES = {
    other: {id: 0, text: "Other", sref: "app.common.OTHER"},
    red: {id: 1, text: "Red", sref: "app.winemaking.protocol.WINE_TYPE.red"},
    white: {id: 2, text: "White", sref: "app.winemaking.protocol.WINE_TYPE.white"},
    rose: {id: 3, text: "Rosé", sref: "app.winemaking.protocol.WINE_TYPE.rose"},
    // 4: {id: 4, text: "Sparkling", sref: "sparkling"},
  }
  WINE_TYPES[0] = WINE_TYPES.other;
  WINE_TYPES[1] = WINE_TYPES.red;
  WINE_TYPES[2] = WINE_TYPES.white;
  WINE_TYPES[3] = WINE_TYPES.rose;


  export interface IBatch {
    readonly id: number;
    name?: string;
    type?: string; // Always equal to "batch"
    description?: string;

    processes?: IProcess[], // TODO: Uncycle
  }

  export interface INotification {
    id: number;
    read: boolean;
    read_at?: string;
    notification: {
      title: string;
      message: string;
      timestamp: number;
      published_alarm?: IAlarm;
      published_device?: IDevice; // Almost never present
      published_sensor?: IAlarm; // Almost never present
      config_fields?: string | object;
    },
    // For UI usage
    unit_id?: number;
    device_id?: number;
    process_id?: number;
    actions?: {
      title?: string | object,
      title_sref?: string,
      on_click: (notification: INotification) => void,
    }[];
  }


  export interface IAlarm {
    id?: number
    name: string
    type?: string; // Always equal to "alarm"
    //deprecated in v2
    sensor?: Partial<ISensor>
    device: IDevice
    owner?: IUser

    rules: IAlarmRule[]
    rule_templates?: IAlarmRule[]
    template?: number // ID of the template alarm this is based on

    active?: boolean, // UI Only
    status: 0 | 1 | 2, // 0: OFF, 1: ARMED, 2: FIRED
    auto_apply?: boolean,
    rearm_interval: number,
    rearm_interval_option?: any,

    actions: {
      email?: boolean,
      sms?: boolean,
      neo_pixel_rgb_active?: boolean,
      neo_pixel_rgb?: string,
      beacon_rgb_active?: boolean,
      beacon_rgb_uuid?: string,
      beacon_rgb?: string,
    }
    notification_targets?: INotificationTarget[]

    //frontend helpers
    selected?: boolean
    rulesErrors?: Map<IAlarmRule, []>

  }

  export interface IAlarmTemplate extends IAlarm {
    rule_templates?: IAlarmRuleTemplate[];
    disabled?: boolean; // If not available for current devices being edited due to whitelist/blacklist
  }

  export class WGAlarm extends WGObject implements IAlarm {
    id?: number;
    name: string;
    type?: string;
    sensor?: Partial<ISensor>;
    device: IDevice;
    owner?: IUser;
    rules: IAlarmRule[];
    rule_templates?: IAlarmRule[];
    template?: number;
    active?: boolean;
    status: 0 | 1 | 2;
    auto_apply?: boolean;
    rearm_interval: number;
    rearm_interval_option?: any;
    actions: { email?: boolean; sms?: boolean; neo_pixel_rgb_active?: boolean; neo_pixel_rgb?: string; beacon_rgb_active?: boolean; beacon_rgb_uuid?: string; beacon_rgb?: string; };
    notification_targets?: INotificationTarget[];
    selected?: boolean;
    rulesErrors?;
    constructor(data: IAlarm) {
      super(data);
      Object.keys(data).forEach(key => {
        this[key] = data[key];
      });
    }
    //makes sure all the nested objects are objects instead of ids
    public normalizeInPlace(WGApiData: WGApiData): WGAlarm {
      this.rules = this.rules.map(rule => new WGAlarmRule(rule).normalizeInPlace(WGApiData))
      this.rearm_interval_option = wg.availableRearmOptions.find((option) => option.secondsDuration === this.rearm_interval);

      if (this.notification_targets && this.notification_targets.some(target => typeof target === 'number')) {
        this.notification_targets = WGApiData.WGAlarms.extractNotificationTargets(this.notification_targets);
      }
      this.actions = this.actions || {} as IAlarm['actions'];
      
      return this;

    }
  }

  export class WGAlarmTemplate extends WGAlarm implements IAlarmTemplate {
    rule_templates?: WGAlarmRuleTemplate[];

    constructor(data: IAlarmTemplate) {
      super(data);
      Object.keys(data).forEach(key => {
        if (!(key in this)) {
          this[key] = data[key];
        }
      });
      this.rules?.forEach(rule => {
        rule.type_id = wg.ALARM_RULE_TYPES.parameter_reached.id; // BE is not sending type_id for templates, but it should
      });
    }

    //returns an object in the Alarm format
    public toAlarm(): IAlarm {
      const alarm = {
        ...this,
        rules: this.rule_templates.map(rule => new WGAlarmRuleTemplate({...rule, alarm: this}).toRule())
      };
      alarm.rule_templates = undefined;
      return alarm;
    }

    //makes sure all the nested objects are objects instead of ids
    public normalizeInPlace(WGApiData: WGApiData): WGAlarmTemplate {
      //in order to use the super.normalizeInPlace, we need to temporarily change the names of "rule_templates" to "rules" and then change it back
      this.rules = this.rule_templates;
      const normalizedAlarm = super.normalizeInPlace(WGApiData);
      this.rule_templates = normalizedAlarm.rules as WGAlarmRuleTemplate[];
      delete this.rules;
      return this;
    }
  }


  /**
   *     (0, "after_stage_start"),  # Elapsed time since stage start
   *     (1, "other_task_finished"),  # Other task finished
   *     (2, "parameter_reached"),  # Parameter reached
   */
  export const TASK_RULE_TYPES = {
    elapsed_time_since_stage_start: {id: 0, text: "After stage start", sref: "app.winemaking.AFTER_STAGE_STARTS"},
    other_task_finished: {id: 1, text: "Other task finished", sref: "app.winemaking.OTHER_TASK_FINISHED"},
    parameter_reached: {id: 2, text: "Parameter reached", sref: "app.winemaking.PARAMETER_REACHED"}
  }

  export const ALARM_RULE_TYPES = {
    parameter_reached: TASK_RULE_TYPES.parameter_reached,
  }

  export interface IAlarmRule {
    id?: number
    alarm?: IAlarm
    name?: string
    type_id?: number | 0 | 1 | 2 // One of TASK_RULE_TYPES[id]
    parameter: ISensor // Either the ISensor object, the internal_name (unique) or the id
    selectedOption?: IAlarmRuleOptions //UI only
    logical?: string
    expression?: string // deprecated
    value?: number //UI only, on user selected conversion
    unit?: string //UI g, C, etc,
    value_conv?: number // Converted value to unit_SI. received/sent from/to the server
    matched_at?: Date // When the rule was matched
    task_template?: number //id of the template for this task

    previousTask?: number; //id of the task that needs to be completed before this rule is monitored/matched
    delay?: number  // Wait this amount of seconds after matched_at to trigger the overdue-event.

    // UI only
    markedForDeletion?: boolean;
  }

  export interface IAlarmRuleTemplate extends IAlarmRule {
    alarm_template?: IAlarm;
  }

  export class WGAlarmRule implements IAlarmRule {
    id?: number;
    alarm?: IAlarm;
    name?: string;
    type_id?: number | 0 | 1 | 2;
    parameter: ISensor;
    selectedOption?: IAlarmRuleOptions;
    logical?: string;
    expression?: string;
    value?: number;
    unit?: string;
    value_conv?: number;
    matched_at?: Date;
    task_template?: number;
    previousTask?: number;
    delay?: number;
    markedForDeletion?: boolean;

    constructor(data: IAlarmRule) {
      Object.keys(data).forEach(key => {
        if (!(key in this)) {
          this[key] = data[key];
        }
      });
    }

    public normalizeInPlace(WGApiData: WGApiData): WGAlarmRule {

      if(_.isNumber(this.parameter)) {
        this.parameter = WGApiData.WGSensors.sensors_id[this.parameter]
      }
      if(_.isNil(this.value_conv)){
        this.value_conv = this.value;
      }
      return this;
    }
  }

  export interface IAlarmRuleOptions {
    id: string,
    name?: string,
    name_sref?: string,
    symbol: string,
    logical: string,
  }

  export class WGAlarmRuleTemplate extends WGAlarmRule {
    alarm_template?: IAlarmTemplate;

    constructor(data: IAlarmRuleTemplate) {
      super(data);
      Object.keys(data).forEach(key => {
        if (!(key in this)) {
          this[key] = data[key];
        }
      });
    }

    normalizeInPlace(WGApiData: WGApiData): WGAlarmRuleTemplate {
      //in order to use the super.normalizeInPlace, we need to temporarily change the names of "alarm_template" to "alarm" and then change it back
      this.alarm = this.alarm_template;
      const normalizedAlarmRule = super.normalizeInPlace(WGApiData)
      this.alarm_template = normalizedAlarmRule.alarm
      delete normalizedAlarmRule.alarm
      return this;
    }

    //returns an object in the AlarmRule format
    toRule(): any {
      return new WGAlarmRule({...this, alarm: this.alarm_template})
    }

  }

  export interface INotificationTarget {
    id: number,
    contact_name: string,
    notification_endpoint_type: string,
    notification_endpoint_type_sref?: string, // UI Only
    contact_info: string,
    contact_info_extra: string, // Named contact_info_extra on backend
    validated?: boolean,
    //Frontend helpers
    markedForDeletion?: boolean;
    selected?: boolean;
  }

  export const notificationTargetTypes = [
    // {name: "other", sref: "app.common.OTHER", admin: true},
    // {name: "sms", sref: "app.common.SMS", admin: true},
    {name: "email", sref: "app.common.EMAIL"},
    {name: "whatsapp", sref: "app.common.WHATSAPP"},
  ]

  export const availableRearmOptions = [
    {id: '-1', name: 'auto', duration: null, key: 'auto', secondsDuration: -1},
    // {id: '0', name: '30s', duration: 30, key: 'second', secondsDuration: 30},
    // {id: '0', name: 'always', duration: null, key: 'always', secondsDuration: 30},
    {id: '1', name: '1h', duration: 1, key: 'hours', secondsDuration: 1 * 60 * 60},
    {id: '2', name: '2h', duration: 2, key: 'hours', secondsDuration: 2 * 60 * 60},
    {id: '4', name: '4h', duration: 4, key: 'hours', secondsDuration: 4 * 60 * 60},
    {id: '8', name: '8h', duration: 8, key: 'hours', secondsDuration: 8 * 60 * 60},
    {id: '12', name: '12h', duration: 12, key: 'hours', secondsDuration: 12 * 60 * 60},
    {id: '24', name: '24h', duration: 24, key: 'hours', secondsDuration: 24 * 60 * 60},
    {id: '48', name: '48h', duration: 48, key: 'hours', secondsDuration: 2 * 24 * 60 * 60},
    {id: '168', name: '168h', duration: 168, key: 'hours', secondsDuration: 7 * 24 * 60 * 60},
    {id: '336', name: '336h', duration: 336, key: 'hours', secondsDuration: 14 * 24 * 60 * 60},
  ];

  export type LogicalOptions = {
    id: string,
    name: string,
    name_sref: string,
    symbol: ">" | "<" | "≥" | "≤" | "=" | "≠"
    logical: ">" | "<" | ">=" | "<=" | "==" | "!="
  }

  export const standardLogicalOptions: LogicalOptions[] = [
    { id: '1', name: '> (Greater-Than)', name_sref: 'app.modal.alarm.options.GREATER', symbol: '>', logical: '>' },
    { id: '2', name: '< (Less-Than)', name_sref: 'app.modal.alarm.options.LESS', symbol: '<', logical: '<' },
  ];
  export const alarmLogicalOptions: LogicalOptions[] = [
    { id: '3', name: '≥ (Greater-Than-or-Equal-to)', name_sref: 'app.modal.alarm.options.GREATER_EQUAL', symbol: '≥', logical: '>=' },
    { id: '4', name: '≤ (Less-Than-or-Equal-to)', name_sref: 'app.modal.alarm.options.LESS_EQUAL', symbol: '≤', logical: '<=' },
    { id: '5', name: '= (Equal-to)', name_sref: 'app.modal.alarm.options.EQUAL', symbol: '=', logical: '==' },
    { id: '6', name: '≠ (Not-Equal-to)', name_sref: 'app.modal.alarm.options.NOT_EQUAL', symbol: '≠', logical: '!=' },
  ];

  export const allLogicalOptions = standardLogicalOptions.concat(alarmLogicalOptions);

// Local Browser Configs
  export interface IDashboardConfigs {
    overview_place?: string;
    overview_orderBy?: string;
    overview_viewType?: 'grid' | 'table' | 'task' | 'timeline';
    overview_sort?: string;
    overview_filter?: {
      filter?: string,
      searchText?: string,
      // favorites?: boolean,
    }
    // [key: string]: any;
  }

  export interface _IState extends ng.ui.IStateService {
    params: {
      nest_by?: string,
      devices?: string[],
      device?: string,
      device1?: string,
      device_id?: number,
      device_sn?: string,
      device_uuid?: string,
      sensor?: string,
      sensors?: string[],
      parameter?: string,
      parameters?: string[],
      unit?: string,
      unit_id?: number,
      place?: string,
      place_id?: number,
      xAxisMin?: string,
      xAxisMax?: string,
      protocol?: string,
      share_code?: string,
    }
  }

  // export interface IGraphData {
  //   x?: number;
  //   y?: any;
  //
  //   [key: string]: any;
  // }

  export interface IDataResult {
    0?: any;
    x?: any;
    1?: any;
    y?: any;

    timestamp?: number;
    value?: any;
    payload?: any;

    deleted?: boolean;
    observation?: string;

    // [key: string]: any;
  }

// export interface IDataResponse<T> {
  export interface IDataResponse<T = IDataResult> {
    count: number;
    length?: number;
    next: string;
    previous: string;
    results: T[];
    // results: IDataResult[];

    // [key: string]: any;
  }

  export interface IDataPostResponse {
    detail: {
      rc: number;
      rc_str: string;
    }
    message: string;

    // [key: string]: any;
  }

  export interface IMultiSelect<T> {
    items: T[],
    all_items_are_selected: boolean,
    last_item_selected: T,
    last_single_item_selected: T,

    updateSelectedList(): void,

    reselectFromList(): void,

    selectAll(value?: boolean): void,

    select(item: T, index: number, e: JQueryEventObject): void,
  }

  export interface ILicense {
    readonly id?: number;

    device?: IDevice,
    distributor?: IDistributor,
    client_manager?: IUser,

    created_at?: Date
    license_start_date?: Date
    license_expiration_date?: Date
    client_invoice_id?: string
    client_invoice_date?: Date
    client_license_value?: number
    resale_invoice_id?: string
    resale_invoice_date?: Date
    resale_license_value?: number
    owned_by_distributor?: boolean
    notes?: string

    expired?: boolean
    renewable?: boolean
    selected?: boolean
  }

  export interface IDistributor extends IUser {
    readonly role?: "distributor";
  }


  export interface INestable {
    item: {
      text: string;
      admin?: boolean;
      dev?: string;

      id?: number;
      sn?: string;
      uuid?: string;
      device_name?: string;
      device?: Partial<IDevice>;

      sensor?: string;

      // [data: string]: any;

      // If the entry is selected on the list
      selected?: boolean;
      // CSS Style to be applied using ng-style
      style?: { [css_class: string]: any; };

      isGroup: boolean,
      children?: INestable[],
    },
    children: INestable[],
  }

  export interface IReturnResult {
    // Is it loading
    loading: boolean,
    // Which ids are loading
    loading_ids?: {},
    // Used by SweetAlert Loader
    result: "" | "success" | "error",
    // Error message, when something goes wrong
    message?: string,
  }
}