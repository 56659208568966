namespace wg {
  class WgDataTableDirective implements ng.IDirective {
    static factory(): ng.IDirectiveFactory {
      const directive = ($location: ng.ILocationService, $anchorScroll: ng.IAnchorScrollService) =>
          new WgDataTableDirective($location, $anchorScroll);
      directive.$inject = ['$location', '$anchorScroll'];
      return directive;
    }

    constructor(private $location: ng.ILocationService, private $anchorScroll: ng.IAnchorScrollService) {
    }

    restrict = 'E';
    scope = false;


    templateUrl: any = (elem: ng.IAugmentedJQuery, attr: ng.IAttributes) => {
      let str = 'app/pages/404.html';
      if (angular.isDefined(attr.datascope)) {
        str = `app/views/directives/wg-data-table-${attr.datascope}.html`;
      }
      return str;
    };

    link = ($scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
      $scope['gotoBottom'] = () => {
        const _target_div = $('#details_area');
        if (_target_div && _target_div.offset()) {
          $('html, body').animate({scrollTop: _target_div.offset().top}, 800, 'swing');
        }
      };
    };
  }

  App.directive('wgDataTable', WgDataTableDirective.factory());
}
