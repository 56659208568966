/**=========================================================
 * Module: main.js
 * Main Application Controller
 =========================================================*/

App.controller('AppController', ['$rootScope', '$scope', '$state', '$stateParams', '$translate', '$window', '$localStorage', '$timeout', '$location', '$http', 'Utils', 'APIUtils', 'DataUtils', 'WGApiData', 'toggleStateService', 'colors', 'browser', 'AuthService', '$interval', 'ngDialog', 'toaster', 'MQTTService', 'NotificationsService',
  function ($rootScope, $scope, $state, $stateParams, $translate, $window, $localStorage, $timeout, $location, $http, Utils, APIUtils, DataUtils, WGApiData, toggle, colors, browser, AuthService, $interval, ngDialog, toaster, MQTTService, NotificationsService) {
    "use strict";
    console.log('App main module loading');
    
    // Lodash required in some HTMLs
    $rootScope._ = _;
    $rootScope.base_url = '';
    
    MQTTService.enabled = true; // Enables MQTT
    // MQTTService.enabled = false; // Disables MQTT
    MQTTService.log_enabled = AuthService.isDev() || WG_debug; // Enables MQTT related non-warning logging
    
    if (!AuthService.isLogged) {
      console.log('Need to login first', $state.current.name);
      // TODO: Useless. app.init is already redirecting
      $state.go(AuthService.loginState, {
        user: null, //   location: true,
        //   inherit: false,
        //   notify: true,
        //   reload: true
      });
      // return;
    }
    
    //
    // // ---- Tour Stuff
    //
    // // Current tour version. Increase when steps are updated
    // $rootScope.TourVersion_Current = 2;
    //
    // // Call with arg>0 to set that the user has seen the tour
    // $scope.updateSeenTour = function (version) {
    //   version = (version === undefined) ? $rootScope.TourVersion_Current : version;
    //   let _configs = {hasSeenTour: {mainVersion: version}};
    //   AuthService.updateUserConfigs(_configs);
    // };
    // // let tour = uiTourService.getTourByName('dashTour');
    // let tour = uiTourService.getTour();
    // $rootScope.tourComplete = function () {
    //   console.log("Tour Complete OK");
    //   $scope.updateSeenTour($rootScope.TourVersion_Current);
    // };
    //
    // // tour.end().then(function () {
    // //   console.log("Tour Ended OK"); // Called on webpage loads
    // //   // $scope.updateSeenTour(1);
    // //   // return $state.go('app.devices.dashboard');
    // //   // }).then(function () {
    // //   //   console.log("Tour Ended Fail");
    // //   //   // return tour.startAt('placeStep');
    // // });
    //
    // // ---- End of Tour Stuff
    
    $scope.ispageDashboard = false;
    
    
    $scope.closeSubNav = function () {
      if ($rootScope.is_mobile === true) {
        $rootScope.app.layout.isSubNavHidden = true
      }
    }
    
    $scope.$on('$viewContentLoaded', function () {
      if ($location.url().indexOf('dashboard') > -1) {
        
        $scope.ispageDashboard = true;
      } else {
        $scope.ispageDashboard = false;
      }
    });
    
    $(window).resize(function () {
      // if (WG_debug) console.info("Resize detected");
      $scope.$apply(function () {
        detectWidth();
      });
    });
    
    function detectWidth() {
      if ($window.innerWidth === $scope.previous_innerWidth) {
        return;
      }
      $scope.previous_innerWidth = $window.innerWidth;
      if ($window.innerWidth < 960) {
        // $scope.filterMenu.isOpen = false
        $rootScope.is_mobile = true
      } else {
        // $scope.filterMenu.isOpen = true
        $rootScope.is_mobile = false
      }
      if ($window.innerWidth < 768) {
        $rootScope.app.layout.isSubNavHidden = true
        let sidebar = angular.element('.aside-inner');
        if (sidebar)
          sidebar.css("padding-top", "55px");
      } else {
        $rootScope.app.layout.isSubNavHidden = false
      }
    }
    
    detectWidth();
    
    // function detectMobile() {
    //   if (isMobile() === true) {
    //     $rootScope.is_mobile = true
    //     // $rootScope.app.layout.isSubNavHidden = true
    //     // var sidebar = angular.element('.aside-inner');
    //     // sidebar.css("padding-top", "55px");
    //   } else {
    //     $rootScope.is_mobile = false
    //     // $rootScope.app.layout.isSubNavHidden = false
    //   }
    // }
    // detectMobile();
    
    
    /**************************************************
     *************** USER LOGIN WATCHER ***************
     **************************************************/
        
        // User logged-in
    let loginWatch = $rootScope.$watch('AuthService.isLogged', function () {
          // function initialize() {
          // This runs at every start of dashboard.
          // console.log("app main $watch 'AuthService.isLogged'");
          if (!AuthService.isLogged) {
            loginWatch();
            MQTTService.disconnect();
            return;
          }
          initialize();
        }, true);
    
    let mqtt_last_msg = {};
    MQTTService.onMessageArrived = function (message) {
      if (!AuthService.isLogged) {
        return;
      }
      let res = $rootScope.getResources(message.destinationName);
      if (!res['device'] || !res['stream']) {
        if (WG_debug) console.error('MQTT. Failed to get device or stream from destination', message);
        return;
      }
      
      let _stream = res['stream'];
      let _stream_upper = _stream.toUpperCase();
      let device_uuid = res['device'];
      
      let payload = parseData(parseData(message.payloadString)); // Some messages' payload are double-stringified
      
      if (!payload || _.isNil(payload.timestamp)) {
        if (WG_debug && !_stream.endsWith("STATUS")) console.error('MQTT. Message doesn\'t contain payload or timestamp', message);
        return;
      }
      
      let _current_date = new Date();
      if (WG_debug && payload.timestamp > _current_date.getTime() + 5 * 1000) {
        console.warn("MQTT. Message timestamp is in the future", message);
      }
      
      WGApiData.last_data_received = payload.timestamp;
      
      let _target_dev = WGApiData.WGDevices.devices_uuid[device_uuid];
      if (!_target_dev) {
        // We can receive MQTT_data from devices that we do not have permission to see. Just ignore
        // if (WG_debug && MQTTService.log_enabled && ![61, 67].includes(AuthService.view_as_owner.id)) { // dev, admin.script
        //   console.warn("MQTT. We received an LKM of a sensor not on our list", device_uuid, message);
        // }
        return;
      }
      
      if (_stream_upper.includes("/ALARM") || _stream_upper.includes("/NOTIFICATION") || _stream_upper.includes("ALARM_AI")) {
        // if (['NOTIFICATION', 'ALARM', 'ALARM_AI'].includes(_stream_upper)) {
        
        let type = payload.type || null;
        type = type || _stream_upper.includes("ALARM_AI") ? "ALARM_AI" : null;
        type = type || _stream_upper.includes("ALARM") ? "ALARM" : null;
        type = type || _stream_upper.includes("NOTIFICATION") ? "NOTIFICATION" : null;
        type = type || 'INFO';
        
        
        let title = payload.title[$scope.language.selected.localeId] || payload.title['en-GB'] || payload.title || '';
        title = title.trim();
        
        let message = payload.message[$scope.language.selected.localeId] || payload.message['en-GB'] || payload.message || '';
        message = message.trim();
        
        if (payload.published_user && payload.published_user.id && AuthService.user.id !== payload.published_user.id) {
          console.log("MQTT. Notification or alarm targeting another user!");
          // if (AuthService.canAccess('admin')) {
          //   title = 'User ' + payload.published_user.id + ': ' + title;
          // } else {
          return;
          // }
        }
        console.log("MQTT. Notification received ", payload);
        // In 0.5s, get the notifications themselves from the DB
        $timeout(function () {
          NotificationsService.last_notifications.unread++;
          NotificationsService.loadUnreadNotifications();
          toaster.pop(type, title, message);
        }, 500);
        return;
      }
      
      if (_target_dev) {
        if (!message.retained) {
          if (WG_debug) {
            console.log(_current_date.toLocaleTimeString() + ', received MQTT messages for device', _target_dev.name, _stream, payload);
          } else if (!IGNORED_STREAMS.includes(_stream.toUpperCase())) {
            if (SMARTBOX_MODELS.includes(_target_dev.model.toLowerCase()) && WG_debug) {
              console.log(Highcharts.dateFormat('%H:%M:%S.%L', _current_date) + ', received MQTT messages for ' + _target_dev.name + ' - ' + _stream, payload);
              
            } else if ((_.isNil(mqtt_last_msg[device_uuid]) || mqtt_last_msg[device_uuid] < _current_date.getTime() - 5 * 1000)) { // Report new messages arriving for a sensor at most every 15s
              mqtt_last_msg[device_uuid] = _current_date.getTime();
              if (MQTTService.log_enabled) {
                console.log(_current_date.toLocaleTimeString() + ', received MQTT messages for device', _target_dev.name);
              }
            }
          }
        }
        let processed = $rootScope.WGDevices.processLastKnownMessage(device_uuid, _stream, payload['timestamp'], payload, !message?.retained);
        
        // DataUtils.update_device_card_parameters(_target_dev, false, _.map(_.filter($rootScope.WGSensors.sensors, {'stream': _stream}), 'internal_name'));
        if (_stream === 'PONG' && processed) {
          // Force a Digest now
          DataUtils.global_apply_schedule(null, 0);
        } else {
          DataUtils.global_apply_schedule(message.destinationName);
        }
      }
    }
    
    let initialize = function () {
      // MQTT-subscribes for every device's users, after being received and loaded.
      $rootScope.$watch('WGDevices.ready', function () {
        if (!WGApiData.WGDevices.ready) {
          return;
        }
        console.log('devices_updated. MQTT-reSubscribe if changed');
        
        let sub_topics = {};
        _.forEach(WGApiData.WGDevices.devices, function (_device) {
          // MQTT-Subscribe to all distinct "tenants"
          let tmp = $rootScope.getResources(_device.path);
          if (tmp && tmp['tenant']) {
            sub_topics[tmp['tenant']] = true;
          }
        });
        
        // check if all sub_topics keys are already in subscribed
        let all_topics_subscribed = true;
        _.forEach(sub_topics, function (value, key) {
          if (!MQTTService.topics_to_subscribe?.includes(key)) {
            all_topics_subscribed = false;
          }
        })
        if (!all_topics_subscribed) {
          MQTTService.topics_to_subscribe = Object.keys(sub_topics);
          MQTTService.connect(false);
        }
        
        NotificationsService.loadUnreadNotifications();
      });
      
      WGApiData.init();
    }
    
    
    /**************************************************
     ***************** LAYOUT SUFF ********************
     **************************************************/
// iPad may presents ghost click issues
// if( ! browser.ipad )
// FastClick.attach(document.body);

// Close submenu when sidebar change from collapsed to normal
    $rootScope.$watch('app.layout.isCollapsed', function (_new_value, _old_value) {
      if (_new_value === false) $rootScope.$broadcast('closeSidebarMenu');
    });
// $rootScope.$watch('app.layout.isSubNavHidden', function (newValue, oldValue) {
//   if (angular.isDefined(newValue))
//     console.log('app.layout.isSubNavHidden', newValue);
// });
    
    // Restore layout settings
    // try {
    //   if (angular.isDefined($localStorage.layout)) {
    //     // $rootScope.app.layout = $localStorage.layout;
    //     $rootScope.app.layout.isCollapsed = $localStorage.layout.isCollapsed;
    //     $rootScope.app.layout.isSubNavHidden = $localStorage.layout.isSubNavHidden;
    //     if (WG_debug) console.log('Restored layout', $rootScope.app.layout);
    //   }
    //   // else
    //   //   $localStorage.layout = $rootScope.app.layout;
    // } catch (e) {
    //   console.log('Error restoring layout', e);
    // }
    
    $rootScope.$watch("app.layout", function (_new_value, _old_value) {
      if (_new_value === _old_value) {
        // Simply initializing watcher
        return;
      }
      $localStorage.layout = $rootScope.app.layout;
      if (WG_debug) console.log('Saved changed layout', _.cloneDeep($rootScope.app.layout));
      // $rootScope.setTopNavBarOffset();
    }, true);


// Allows to use branding color with interpolation
// {{ colorByName('primary') }}
    $scope.colorByName = colors.byName;

// Hides/show user avatar on sidebar
    $scope.toggleUserBlock = function () {
      $scope.$broadcast('toggleUserBlock');
    };

// Restore application classes state
    toggle.restoreState($(document.body));

// cancel click event easily
    $rootScope.cancel = function ($event) {
      $event.stopPropagation();
    };
    
    
    /********************
     * time now watcher *
     ********************/
    $rootScope.now = {time: new Date().getTime()};
// this was make angular do some unnecessary internal calls
// $interval(function () {
//   return;
//   // $rootScope.now.time = new Date().getTime();
// }, 10000);
    
    
    /**************************************************
     ******** GLOBAL UTILITIES FUNCTIONS **************
     **************************************************/
// TODO: Move these to a service or similar
    
    /** Same as calling a modal from jade directly, but here the variables are not expanded to JSON.
     *  It's faster and compatible with circular data structures
     *  @param {IDevice} device - device where the alarm is set
     *  @param {string} sensor - Sensor internal_name to use
     *  @param {string[]} sensors - all sensors of the device
     *  @param {string} result - Key in parent scope to store result
     *  @return {angular.IPromise<any>} ngDialog object
     */
    $rootScope.modalSetAlarm = (device, sensor = null, sensors = null, result = null) => {
      console.info('main modalSetAlarm');
      if (!device) {
        console.error('No device passed');
        return;
      }
      
      let options = {
        template: 'app/views/modals/alarm.html',
        controller: 'AlarmModalInstance',
        class: "wg-dialog",
        data: {
          device: device,
          sensor: sensor,
          sensors: sensors,
          result: result,
        },
      };
      return ngDialog.open(options);
    };
    
    $rootScope.modalCrontab = (device, result) => {
      if (!device) {
        console.error('No device passed');
        return;
      }
      
      let options = {
        template: 'app/views/modals/crontab.html', controller: 'CrontabModalInstance', class: "wg-dialog", data: {
          device: device, result: result,
        }
      };
      return ngDialog.open(options);
    };
    
    $rootScope.modalEditDevice = (device, result, reload, addToGraph = null) => {
      reload = (reload === undefined) ? false : reload;
      if (!device) {
        console.error('No device passed');
        return;
      }
      
      let options = {
        template: 'app/views/modals/edit-device.html',
        controller: 'EditDeviceModalInstance',
        class: "wg-dialog",
        data: {
          device: device,
          result: result,
          reload: reload,
          addToGraph: addToGraph,
        }
      };
      return ngDialog.open(options);
    };
    
    $rootScope.modalEditDeviceConnection = (device, result) => {
      if (!device) {
        console.error('No device passed');
        return;
      }
      
      let options = {
        template: 'app/views/modals/edit-device-connection.html',
        // controller: 'EditDeviceConnectionModalInstance',
        class: "wg-dialog",
        data: {
          device: device,
          result: result,
        }
      };
      return ngDialog.open(options);
    };
    
    $rootScope.modalManualEntry = (device) => {
      console.info('main modalManualEntry');
      if (!device) {
        console.error('No device passed');
        return;
      }
      
      let options = {
        template: 'app/views/modals/manual-entry.html',
        controller: 'ManualEntryModalInstance',
        class: "wg-dialog",
        data: {
          device: device,
        }
      };
      return ngDialog.open(options);
    };
    
    
    $rootScope.modalConfigControl = (_device) => {
      if (!_device) {
        console.error('No device passed');
        return;
      }
      console.info('main modalConfigControl');
      
      let options = {
        template: 'app/views/modals/config-control-board.html', className: ' large-Modal ngdialog-theme-default', // controller: 'configControlModalInstance',
        scope: $scope, data: {
          device: _device, result: 'configControlBoard', mode: 'openConfirm'
        }
      };
      return ngDialog.openConfirm(options).then(function (data) {
        // console.log('modalConfigControl Ok', data);
      }, function (reason) {
        // console.log('modalConfigControl Fail. Reason: ', reason);
      });
    };
    
  }
])
;
