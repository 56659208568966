/**=========================================================
 * Module: sidebar-menu.js
 * Handle sidebar collapsible elements
 =========================================================*/
namespace wg {
  App.controller('SidebarController', ['$rootScope', '$scope', '$state', '$http', '$timeout', 'Utils', 'AuthService',
    function ($rootScope: IRootScope, $scope, $state: _IState, $http: ng.IHttpService, $timeout: ng.ITimeoutService, Utils, AuthService: IAuthService) {
      $rootScope.menuItems = $rootScope.menuItems || [];

      var collapseList = [];
      // demo: when switch from collapse to hover, close all items
      $rootScope.$watch('app.layout.asideHover', function (oldVal, newVal) {
        if (newVal === false && oldVal === true) {
          closeAllBut(-1);
        }
      });

      // When state changes, get Active state for all items:
      $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        // console.log('sidebar $stateChangeSuccess', toState, fromState);
        if (toState.name != fromState.name)
          getActiveStates();
      });
      // $rootScope.$watch('$state.current.name', function (newVal, oldVal) {
      //   console.log('sidebar $state.current.name', newVal, oldVal);
      //   if (newVal != oldVal)
      //     getActiveStates();
      // });

      // Get Active state for all items:
      let getActiveStates = function (items = $rootScope.menuItems, found_active = false) {
        // console.log('sidebar getActiveStates');
        _.forEach(items, function (item) {
          if (item.submenu && item.submenu.length) {
            if (found_active) {
              item.active = false;
              getActiveStates(item.submenu, found_active);
            } else {
              item.active = getActiveStates(item.submenu, found_active);
            }
          } else {
            if (found_active) {
              item.active = false;
            } else {
              item.active = isActive(item);
            }
          }

          if (item.active) {
            found_active = true;
          }
        });
        return found_active;
      }
      // Check item and children active state
      let isActive = function (item) {
        // console.log('isActive', item.text, item.sref, $state.current.name);
        if (!item)
          return;
        if (!item.sref || item.sref == '#') {
          var foundActive = false;
          angular.forEach(item.submenu, function (value, key) {
            if (isActive(value))
              foundActive = true;
          });
          return foundActive;
        } else {
          // if (item.duplicate && $state.is(item.sref) && $state.params && item.params) {
          //   console.debug('Never selecting Duplicate menu entry for now.');
          //   // _.forEach(item.params, function (value, key) {
          //   //   if ($state.params[key] != value) {
          //   //     return false;
          //   //   }
          //   // });
          //   return false;
          // }
          return $state.is(item.sref) || $state.includes(item.sref);
        }
      };
      // Load menu from json file
      // -----------------------------------
      // $scope.getMenuItemPropClasses = function (item) {
      //   return (item.heading ? 'nav-heading' : '') +
      //       (isActive(item) ? ' active' : '');
      // };

      $scope.loadSidebarMenu = function () {
        let menuJson = window['WG_sidebar_menu'] || 'server/sidebar-menu.json';
        // let menuURL = menuJson;
        let menuURL = menuJson + '?v=' + WG_version; // jumps cache
        $http.get<any[]>(menuURL).then(
            function (items) {
              // Remove items containing translate including "Winemaking" if we are on mar2protect
              if ($rootScope.WG_is_mar2protect) {
                _.remove(items.data, function (item) {
                  return item.translate?.indexOf('app.winemaking') > -1;
                });
              }
              if (WG_debug) console.info('Loading menu', $rootScope.menuItems, items.data);
              synchronizeArrayOfObjects($rootScope.menuItems, items.data, 'text');
              getActiveStates();
            }, function (data) {
              console.error('Failure loading menu', data);
            });
      };
      $scope.loadSidebarMenu();
      // Handle sidebar collapse items
      // -----------------------------------
      $scope.addCollapse = function ($index, item) {
        collapseList[$index] = $rootScope.app.layout.asideHover ? true : !isActive(item);
      };
      $scope.isCollapse = function ($index) {
        return (collapseList[$index]);
      };
      $scope.toggleCollapse = function ($index, isParentItem) {
        // collapsed sidebar doesn't toggle drodopwn
        if (Utils.isSidebarCollapsed() || $rootScope.app.layout.asideHover)
          return;
        // make sure the item index exists
        if (angular.isDefined(collapseList[$index])) {
          if (!$scope.lastEventFromChild) {
            collapseList[$index] = !collapseList[$index];
            closeAllBut($index);
          }
        } else if (isParentItem) {
          closeAllBut(-1);
        }
        $scope.lastEventFromChild = isChild($index);
        return;
      };

      function closeAllBut(index) {
        index += '';
        for (var i in collapseList) {
          if (index < 0 || index.indexOf(i) < 0)
            collapseList[i] = true;
        }
      }

      function isChild($index) {
        return (typeof $index === 'string') && !($index.indexOf('-') < 0);
      }

      // $scope.canSee = function (item) {
      //   if (!AuthService.canAccess(item.accessLevel)) {
      //     return false;
      //   }
      //   return true;
      //   // let accessible = _.every(_.split(item.accessLevel, '|'), function (level) {
      //   //   if(WG_debug) console.log("testing with:", level, item);
      //   //   return AuthService.canAccess(level || 'admin');
      //   // });
      //   // return accessible;
      // };
    }]);
}
