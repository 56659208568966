App.directive('progressbarVertical', function() {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        controller: ["$scope", function($scope) {
          var self = this,
            //animate = angular.isDefined($attrs.animate) ? $scope.$parent.$eval($attrs.animate) : progressVerticalConfig.animate;
            animate = true;

          this.bars = [];
          //$scope.max = angular.isDefined($attrs.max) ? $scope.$parent.$eval($attrs.max) : progressVerticalConfig.max;
          $scope.max = 100;

          this.addBar = function (bar, element) {
            if (!animate) {
              element.css({'transition': 'none'});
            }

            this.bars.push(bar);

            bar.$watch('value', function (value) {
              bar.percent = +(100 * value / $scope.max).toFixed(2);
            });

            bar.$on('$destroy', function () {
              element = null;
              self.removeBar(bar);
            });
          };

          this.removeBar = function (bar) {
            this.bars.splice(this.bars.indexOf(bar), 1);
          }
        }],
        scope: {
            value: '=',
            type: '@',
            color: '='
        },
        template: "<div class=\"progress vertical bottom\">\n" +
    "  <div class=\"progress-bar\" ng-class=\"type && 'progress-bar-' + type\" role=\"progressbar\" aria-valuenow=\"{{value}}\" aria-valuemin=\"0\" aria-valuemax=\"{{max}}\" ng-style=\"{height: percent + '%', 'background-color': color}\" aria-valuetext=\"{{percent | number:0}}%\" ng-transclude></div>\n" +
    //"  <div class=\"progress-bar\" ng-class=\"type && 'progress-bar-' + type\" role=\"progressbar\" data-transitiongoal=\"{{value}}\" aria-valuemin=\"0\" aria-valuemax=\"{{max}}\" ng-transclude></div>\n" +
    "</div>",
        link: function(scope, element, attrs, progressVerticalCtrl) {
            var widthDefault = 80;
            var heightDefault = 400;
            element.css({
              width: attrs.width || widthDefault,
              height: attrs.height || heightDefault
            });
            progressVerticalCtrl.addBar(scope, angular.element(element.children()[0]));
        }
    };
});

App.directive('progressbarLevelVertical', function() {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        controller: ["$scope", function($scope) {
          var self = this,
            //animate = angular.isDefined($attrs.animate) ? $scope.$parent.$eval($attrs.animate) : progressVerticalConfig.animate;
            animate = true;

          this.bars = [];
          //$scope.max = angular.isDefined($attrs.max) ? $scope.$parent.$eval($attrs.max) : progressVerticalConfig.max;
          $scope.mim = $scope.mim || 0;
          $scope.max = $scope.mim || 100;
          $scope.stripe = $scope.stripe || 2;

          this.addBar = function (bar, element) {
            if (!animate) {
              element.css({'transition': 'none'});
            }

            var height = parseFloat(element.css('height'));
            var width = parseFloat(element.css('width'));


            this.bars.push(bar);

            bar.$watch('value', function (value) {
              var percent = +((value - $scope.min) / ($scope.max - $scope.min) * 100).toFixed(2);

              if (percent >= 50) {
                percent -= 50;
                element.css({'bottom': '50%'});
                element.css({'top': 'auto'});
                //element.css({'line-height': '21px'});
              } else {
                element.css({'bottom': '0px'});
                element.css({'top': '50%'});
                percent = 50 - percent;
                //element.css({'line-height': parseFloat(element.parent().css('height'))*(percent/100) + 'px'});
              }
              bar.percent = percent;

              //var height = parseFloat(element.css('height'));
              //var width = parseFloat(element.css('width'));
              //console.log(percent, height, width, element.css('top'), element.css('bottom'));
            });

            bar.$on('$destroy', function () {
              element = null;
              self.removeBar(bar);
            });
          };

          this.removeBar = function (bar) {
            this.bars.splice(this.bars.indexOf(bar), 1);
          }
        }],
        scope: {
            value: '=',
            min: '@min',
            max: '@max',
            stripe: '@stripe',
            type: '@',
            color: '='
        },
        template: "<div class=\"progress vertical bottom\">\n" +
    "  <div class=\"progress-bar\" ng-class=\"type && 'progress-bar-' + type\" role=\"progressbar\" aria-valuenow=\"{{stripe}}\" aria-valuemin=\"{{0}}\" aria-valuemax=\"{{100}}\" ng-style=\"{height: stripe + '%', bottom: (50 - (stripe / 2)) +'%', 'background-color': 'black', 'z-index': 10}\" aria-valuetext=\"{{percent | number:0}}%\"></div>\n" +
    "  <div class=\"progress-bar\" ng-class=\"type && 'progress-bar-' + type\" role=\"progressbar\" aria-valuenow=\"{{value}}\" aria-valuemin=\"{{min}}\" aria-valuemax=\"{{max}}\" ng-style=\"{height: percent + '%', 'background-color': color}\" aria-valuetext=\"{{percent | number:0}}%\" ng-transclude></div>\n" +
    //"  <div class=\"progress-bar\" ng-class=\"type && 'progress-bar-' + type\" role=\"progressbar\" data-transitiongoal=\"{{value}}\" aria-valuemin=\"0\" aria-valuemax=\"{{max}}\" ng-transclude></div>\n" +
    "</div>",
        link: function(scope, element, attrs, progressVerticalCtrl) {
            var widthDefault = "50px";
            var heightDefault = "150px";
            element.css({
              width: attrs.width || widthDefault,
              height: attrs.height || heightDefault
            });
            progressVerticalCtrl.addBar(scope, angular.element(element.children()[1]));
        }
    };
});
