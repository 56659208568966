namespace wg {
  export class WgNotificationTargetsController {
    static $inject = ['$rootScope', '$scope', 'AuthService', 'WGApiData'];


    notificationTargets: any[];
    hideLabel: boolean;
    taskOrAlarm: ITask | IAlarm;


    constructor(private $rootScope: any, private $scope: any, private AuthService: IAuthService, private WGApiData: WGApiData) {
    }

    $onInit() {

      if (!this.notificationTargets) {
        this.notificationTargets = [];
      }
      synchronizeArrayOfObjects(this.notificationTargets, this.AuthService.viewing_notification_targets);

      if (!this.taskOrAlarm.id) {
        const firstEmail = _.find(this.AuthService.viewing_notification_targets, {notification_endpoint_type: "email"});
        if (firstEmail) {
          this.taskOrAlarm.notification_targets.push(_.cloneDeep(firstEmail));
        }
      } else {
        //convert IDS to objects
        if (Array.isArray(this.taskOrAlarm.notification_targets)) {
          this.taskOrAlarm.notification_targets = this.taskOrAlarm.notification_targets.map(id => {
            if (typeof id === 'number') {
              return this.WGApiData.WGAlarms.extractNotificationTargets([id])[0];
            }
            return id;
          }).filter(nt => nt !== undefined);
        }
        this.updateSelection();
      }


      this.$rootScope.$on('presetApplied', (event, args) => {
        this.updateSelection();
      });


      this.$rootScope.$watch("AuthService.viewing_notification_targets", (newVal, oldVal) => {
        if (_.isNil(newVal) || _.isEqual(newVal, oldVal)) {
          return;
        }
        if (WG_debug) console.log('Available notification targets changed:', newVal);

        synchronizeArrayOfObjects(this.notificationTargets, this.AuthService.viewing_notification_targets);
        this.updateSelection();
      });

    }

    updateSelection() {
      this.notificationTargets.forEach((target) => {
        target.selected = this.taskOrAlarm.notification_targets.some((nt) => nt.id === target.id);
      });
    }

    public onTargetSelectionChange(target: INotificationTarget) {
      this.taskOrAlarm.notification_targets.length = 0; //erase everything and add to avoid data unsync
      this.taskOrAlarm.notification_targets.push(...this.notificationTargets.filter((nt) => nt.selected));
    }


  }

  App.component('wgNotificationTargets', {
    controller: WgNotificationTargetsController as any,
    templateUrl: "app/views/directives/wg-notification-targets.html",
    controllerAs: 'ctrl',
    bindings: {
      notificationTargets: '=',
      hideLabel: '<?',
      taskOrAlarm: '=',
    },
  });
}
