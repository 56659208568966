//- this controller is used for both creation/editing of alarms and alarm presets/templates
//- it is what shows up inside the modals


namespace wg {
  class wgAlarmController {

    public showErrors = false;

    isAlarmTemplate: boolean;

    actions_available = {
      neo_pixel_rgb: false,
      beacon_rgb: false,
    }

    // The devices to edit, either a single device or a list of devices
    public batchDevices: IDevice[];

    beacon_devices?: IDevice[] = [];

    selected_beacon_device?: IDevice; // setting selected_beacon_device
    public beacon_devices_ready = true;

    public spectrum_color_picker_options: any;
    public availableRearmOptions = wg.availableRearmOptions;

    public alarm: IAlarm;
    public owner: Partial<IUser>; //ask JR how to get this
    public rulesErrors: Map<IAlarmRule, []>;
    //this is the list of parameters that alarm can use, will be filled by the parent controller
    public myParameters: ISensor[] = [];

    /* ALARM TEMPLATES */
    public allAlarmTemplates: IAlarmTemplate[] = [];
    public alarmTemplatesLoading = false;
    public alarmTemplatesError = false;
    public selectedAlarmTemplate: IAlarm | null = null;
    /* END ALARM TEMPLATES */



    static $inject = ['AuthService', 'moment', '$translate', 'WGApiData', '$rootScope', 'DataUtils', '$http', '$scope']

    public constructor(private AuthService: IAuthService, private moment: any, private $translate: ng.translate.ITranslateService, private WGApiData: WGApiData, private $rootScope: IRootScope, private DataUtils: DataUtils, private $http: ng.IHttpService, private $scope: ng.IScope) {
      //configure UI options
      this.configureUIOptions();

      if (WG_debug) console.log('wgAlarmController constructor for alarm:', this.alarm);

      this.owner = {
        id: this.AuthService.view_as_owner.id,
        username: this.AuthService.view_as_owner.username,
        email: this.AuthService.view_as_owner.email,
      };

      //beacon devices
      this.getBeaconDevices(this.beacon_devices)


      this.$rootScope.$on('showErrors', () => {
        this.showErrors = true;
      });
      this.alarm.rearm_interval_option = this.alarm.rearm_interval_option || wg.availableRearmOptions.find((option) => option.secondsDuration === this.alarm.rearm_interval);

    }

    public $onInit() {

      if (!_.isEmpty(this.batchDevices)) {
        this.batchDevices = this.WGApiData.extractDevices(this.batchDevices.map(d => d.id));
      } else if (!_.isNil(this.alarm.device)) {
        this.batchDevices = [this.alarm.device];
      } else {
        this.batchDevices = [];
      }
      if (!_.isEmpty(this.batchDevices)) {
        // ensure a device is defined, to use as default for some UIs
        this.alarm.device = this.batchDevices[0];
      }

      //GET ALARM TEMPLATES to apply to non-template
      if (!this.isAlarmTemplate) {
        this.WGApiData.WGAlarms.getAlarmTemplates().then((templates) => {
          this.allAlarmTemplates = templates
          _.forEach(this.allAlarmTemplates, (template) => {
            template.disabled = !this.isTemplateCompatible(this.batchDevices, template);
          });
          this.alarmTemplatesError = false;
          this.alarmTemplatesLoading = false;
        }).catch((error) => {
          this.alarmTemplatesError = true;
          this.alarmTemplatesLoading = false;
        });
      }

      //initialize alarm
      this.onAlarmChange();

      this.$scope.$watch("ctrl.alarm", (newVal, oldVal) => {
        if (!newVal || newVal === oldVal) {
          return;
        }
        if (WG_debug) console.log('Alarm has changed:', newVal);
        this.onAlarmChange();
      });

      if (this.WGApiData.WGSensors.ready) {
        this.setFallbackParameters();

        // parameters
        if (_.isEmpty(this.myParameters)) {
          // Parameters were not passed from parent. Extract from devices now
          this.myParameters = this.WGApiData.extractAlarmParameters(this.batchDevices, this.myParameters);
          if (_.isEmpty(this.myParameters)) {
            synchronizeArrayOfObjects(this.myParameters, FALLBACK_PARAMETERS);
          }
        }
      }
      this.$rootScope.$on('sensors_updated', (event, args) => {
        if (WG_debug) console.log('sensors_updated');
        this.setFallbackParameters();

        // parameters
        if (_.isEmpty(this.myParameters)) {
          // Parameters were not passed from parent. Extract from devices now
          this.myParameters = this.WGApiData.extractAlarmParameters(this.batchDevices, this.myParameters);
          if (_.isEmpty(this.myParameters)) {
            //hardcoded list for templates ?
            synchronizeArrayOfObjects(this.myParameters, FALLBACK_PARAMETERS);
          }
        }
      })

      this.$rootScope.$on('devices_updated', (event, args) => {
        if (WG_debug) console.log('devices_updated');
        this.getBeaconDevices(this.beacon_devices);
        if (!_.isEmpty(this.batchDevices)) {
          this.myParameters = emptyOrCreateArray(this.myParameters);
          this.myParameters = this.WGApiData.extractAlarmParameters(this.batchDevices, this.myParameters);
        }
      })


    }

    private setFallbackParameters() {
      if (!this.WGApiData.WGSensors.ready) {
        return;
      }
      emptyOrCreateArray(FALLBACK_PARAMETERS);
      const sensorNames = [
        'TEMP', 'LLV_TOF_TREAT_value', 'LLVA_LAT_TREAT_value', 'VOLUME_TREAT',
        'QL_TREAT_LDENSA_massDensity', 'AI_LDENSA_FermentationKinetic24h',
        'PRESSURE_TREAT', 'HUM', 'CO2', 'STATUS_TREAT', 'BAT_TREAT', 'WAKEUP_REASON'
      ];

      sensorNames.forEach(sensorName => {
        const sensor = this.WGApiData.WGSensors.sensors_name[sensorName];
        if (sensor !== null && sensor !== undefined) {
          FALLBACK_PARAMETERS.push(sensor);
        }
      });
    }

    public onAlarmChange() {

      //temp fix maybe? actions are coming as stringified json objects (sometimes with u' prefix)
      if (typeof this.alarm.actions === 'string') {
        this.alarm.actions = parseData(this.alarm.actions, {});
      }
      this.alarm.actions = this.alarm.actions || {} as IAlarm['actions'];
      this.alarm.actions.beacon_rgb_active = this.alarm.actions.beacon_rgb_active || false;
      this.alarm.actions.beacon_rgb = this.alarm.actions.beacon_rgb || "#FF0000";
      this.alarm.actions.beacon_rgb_uuid = this.alarm.actions.beacon_rgb_uuid || null;

      //if device supports neo_pixel_rgb, activate it
      if (!_.isEmpty(this.batchDevices)) {
        if (this.DataUtils.can_device_set_RGB(this.batchDevices)) {
          this.actions_available.neo_pixel_rgb = true;
        }
      }
    }

    public addRule() {
      const rulesOrRulesTemplate = this.alarm.rules || this.alarm.rule_templates;

      rulesOrRulesTemplate.push({
        type_id: RULE_TYPES.parameter_reached.id,
        logical: '>',
        value: 0,
        parameter: null,
      });
    }

    public onRearmIntervalChange(alarm: IAlarm) {
      alarm.rearm_interval = alarm.rearm_interval_option.secondsDuration;
    }


    public getBeaconDevices(beacon_devices: IDevice[]) {
      if (this.WGApiData.WGDevices.ready) {
        if (WG_debug) console.log('Getting beacon devices');

        // Get devices with model=='beacon' in the account, to use as target for alarms
        _.forEach(this.WGApiData.WGDevices.devices, (beacon) => {
          if (beacon.model != 'beacon') return;

          // If I'm admin and this beacon is currently defined in actions, show it
          if (this.AuthService.user_view_as_admin && this.alarm.actions?.beacon_rgb_uuid == beacon.uuid) {
            // show it regardless of the owner
          } else if (this.AuthService.viewing_as_other_owner() && this.AuthService.clients_view) {
            // Don't show when viewing as client if the beacon.owner is not the target client. For when we implement Supporter-role
            if (beacon.owner.id != this.AuthService.view_as_owner.id) return;
          } else {
            // Don't show if the beacon.owner is not the logged user
            if (beacon.owner.id != this.AuthService.user.id) return;
          }

          let _name = beacon.name;
          if (beacon.unit?.name && beacon.unit.name != beacon.name) _name += ' - ' + beacon.unit.name
          if (_name.indexOf(beacon.sn) == -1) _name += ' (' + beacon.sn + ')'
          beacon_devices.push({name: _name, id: beacon.id, uuid: beacon.uuid} as IDevice);
        });
        if (beacon_devices.length > 0) {
          this.actions_available.beacon_rgb = true;
        }
        this.selected_beacon_device = _.find(beacon_devices, {uuid: this.alarm?.actions?.beacon_rgb_uuid});
        this.beacon_devices_ready = true;
      }
    }

    public configureUIOptions() {
      // For Admins and if 30s is not there, add it
      if (this.AuthService.user_view_as_admin && !_.find(this.availableRearmOptions, {secondsDuration: 30})) {
        this.availableRearmOptions.push({id: '0', name: '30s', duration: 30, key: 'seconds', secondsDuration: 30});
      }

      this.availableRearmOptions.forEach((option: any) => {
        option.humanDuration = option.key == 'auto' ? this.$translate.instant('app.modal.alarm.REARM_ONCE') : this.moment.duration(option.duration, option.key).humanize();
      });

      this.spectrum_color_picker_options = {
        theme: 'sp-wg',
        hideAfterPaletteSelect: true,
        allowEmpty: false,
        showPalette: true,
        clickoutFiresChange: false,
        showPaletteOnly: true,
        togglePaletteOnly: true,
        togglePaletteMoreText: '+',
        togglePaletteLessText: '-',
        palette: [
          ['#FF0000', '#FF9900', '#ffff00'],
          ['#00FF00', '#00FFFF', '#0000FF'],
          ['#9900FF', '#FF00FF', '#FFFFFF']
        ],
        cancelText: this.$translate.instant('app.common.CANCEL'),
        chooseText: this.$translate.instant('spectrum_color_picker.chooseText'),
        clearText: this.$translate.instant('spectrum_color_picker.clearText'),
        noColorSelectedText: this.$translate.instant('spectrum_color_picker.noColorSelectedText'),
        disabled: false
      };
    }

    public onSelectBeaconDevice(beacon: IDevice) {
      this.alarm.actions.beacon_rgb_uuid = beacon.uuid;
    }

    public onDeviceColorChange(color: string) {
      this.alarm.actions.neo_pixel_rgb = color;
    }

    public applyAlarmTemplate() {
      if (!this.selectedAlarmTemplate) return;

      // WGAlarmTemplate garantees all the nested objects are complete, we get a copy to prevent side effects 
      const incomingPreset = new WGAlarmTemplate(this.selectedAlarmTemplate).normalizeCopy(this.WGApiData).toAlarm();

      // Copy alarm properties (excluding specific fields)
      const excludedProps = ['id', 'active', 'rules'];
      const alarmProps = _.omit(incomingPreset, excludedProps);
      _.assign(this.alarm, alarmProps);

      // Handle rules: clear errors and delete
      this.alarm.rules.forEach(rule => {
        rule.markedForDeletion = true
        const myErrors: {} = this.rulesErrors?.get(rule);
        for (let key in myErrors) {
          delete myErrors[key];
        }
      });
      _.remove(this.alarm.rules, rule => !rule.id);
      incomingPreset.rules.forEach(rule => {
        rule.type_id = RULE_TYPES.parameter_reached.id; // BE is not sending type_id for templates, but it should
        rule.id = undefined;
        this.alarm.rules.push(rule);
      });
      //Update selected beacon device
      this.selected_beacon_device = _.find(this.beacon_devices, {uuid: this.alarm?.actions?.beacon_rgb_uuid});
      this.$rootScope.$emit('presetApplied');
    }



    public isTemplateCompatible(devicesToTestAgainst: IDevice[], template: IAlarmTemplate): boolean {
      if (_.isEmpty(devicesToTestAgainst)) {
        if (WG_debug) console.warn('No devices to test against', devicesToTestAgainst);
        return true;
      }
      // Check each rule's parameters against all selected devices
      if (!template.rule_templates) {
        if (WG_debug) console.warn('Template has no rules', template);
        return true;
      }

      return template.rule_templates.every(rule => {

        let ruleNormalized = new WGAlarmRule(rule).normalizeInPlace(this.WGApiData);

        const configs = ruleNormalized.parameter.configs;
        if (_.isEmpty(configs?.device_models) && _.isEmpty(configs?.device_models_blacklist)) {
          return true;
        }

        // Check each device against the parameter configs
        return devicesToTestAgainst.every(device => {
          if (_.isNil(device)) {
            return false;
          }

          // Check whitelist (device_models)
          if (!_.isEmpty(configs.device_models)) {
            return configs.device_models.includes(device.model);
          }

          // Check blacklist (device_models_blacklist) 
          if (!_.isEmpty(configs.device_models_blacklist)) {
            return !configs.device_models_blacklist.includes(device.model);
          }

          // If no restrictions defined, consider compatible
          return true;
        });
      });
    }

  }

  App.component('wgAlarm', {
    controller: wgAlarmController,
    controllerAs: 'ctrl',
    templateUrl: "app/views/wg-alarm.html",
    bindings: {
      alarm: '=',
      rulesErrors: '=',
      myParameters: '<',
      isAlarmTemplate: '<',
      batchDevices: '<', // when we are editing multiple devices
    }
  });


  const RULE_TYPES = {
    parameter_reached: {id: 2, text: "Parameter reached", sref: "app.winemaking.PARAMETER_REACHED"},
  }

  const FALLBACK_PARAMETERS = []
}
