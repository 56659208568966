namespace wg {


  export interface TimelineTableEntry {
    unit: IUnit,
    current_stage: IWinemakingStage,
    tasks: {
      overdue: ITask[],
      planned: ITask[],
      completed: ITask[],
      next: ITask[]
    }
  }

  class WGProtocolTimelineView {

    public TASK_STATUS_TYPES = wg.TASK_STATUS_TYPES;
    public TASK_TYPES = wg.TASK_TYPES;

    public searchText: string;
    public filters: any;
    public tableParams: any;
    public selectedPlace: IPlace;
    public allTasks: ITask[] = [];

    //each element of this array is an object mapping 1:n unit to tasks
    public tasksByUnit: TimelineTableEntry[] = [];

    static $inject = ['$rootScope', '$scope', 'ngTableParams', '$http', 'WGApiData'];
    public loading: boolean = true;
    public addToGraph: (any) => void;
    public clearSelectedSensors: (any) => void;

    // private reload_timer: NodeJS.Timeout;

    public constructor(private $rootScope: IRootScope, private $scope: ng.IScope, private ngTable: any, private $http: ng.IHttpService, private WGApiData: WGApiData) {
      if (WG_debug) console.log('WGProtocolTimelineView constructor');

      this.addToGraph = $scope.$parent?.$parent?.addToGraph || $scope.$parent?.addToGraph || null;
      this.clearSelectedSensors = $scope.$parent?.$parent?.clearSelectedSensors || $scope.$parent?.clearSelectedSensors || null;

      if (!$rootScope.protocol_timeline) {
        $rootScope.protocol_timeline = {};
      }

      $rootScope.protocol_timeline.cancel_taskFilterChanged?.();
      $rootScope.protocol_timeline.cancel_taskFilterChanged = $rootScope.$on('taskFilterChanged', (event, searchText, filters) => {
        if (WG_debug) console.log('taskFilterChanged', searchText, filters);
        this.filters = filters;
        this.searchText = searchText;
        this.$onChanges({searchText: this.searchText, filters: this.filters})
      });

      // q: How to avoid having multiple listeners running from multiple constructors? Can I make a constructor/class Singleton?
      // a: I can use $rootScope.$on only once, and then use $rootScope.$emit to trigger the event

      // q: is there a App.component() onDestroy or similar, to clear the listener?
      // a: Yes, there is a $onDestroy method that can be used to clear the listener

      // Q: How can I cancel a $rootScope.$on listener?


      //TODO: change this to data binding
      $rootScope.protocol_timeline.cancel_tasksChangedStatus?.();
      $rootScope.protocol_timeline.cancel_tasksChangedStatus = $rootScope.$on('tasksChangedStatus', (event, data) => {
        if (WG_debug) console.log('tasksChangedStatus', data);
        // TODO: Refresh only the data. But existing lines were not being deleted.
        // if (_.isEmpty(this.tableParams)) {
        //   this.tableParams = this.buildNgTableParams(this);
        // } else {
        //   this.tableParams.reload();
        // }
        this.tableParams = this.buildNgTableParams(this);
      });

      // Clear and Set a repeater-timer emitting a 'tasksChangedStatus' every 2 minutes
      if ($rootScope.protocol_timeline.reload_timer) clearInterval($rootScope.protocol_timeline.reload_timer);
      $rootScope.protocol_timeline.reload_timer = setInterval(() => {
        if (WG_debug) console.log('Protocol Tasks Reloading');
        // this.tableParams = this.buildNgTableParams(this);
        $rootScope.$emit('tasksChangedStatus', {});
      }, 2 * 60 * 1000);

    }

    public async $onInit() {

      const response = await this.$http.get<IWinemakingProtocol[]>("api/dashboard/winemaking/protocol/full/")
      const protocols = response.data;
      // Wait for Units to be ready
      let unhook = this.$rootScope.$watchGroup(['WGUnits.ready', 'WGProcesses.ready'], () => {
        if (!this.WGApiData?.WGUnits?.ready || !this.WGApiData?.WGProcesses?.ready) {
          return;
        }
        // Cycle all units and define their "protocol" if exists
        // _.forEach(this.$rootScope.WGUnits.units, (unit: IUnit) => {

        //   let _protocol = _.find(protocols, {unit: unit.id});
        //   // if yes, unit.process.protocol = _protocol;
        //   if (_protocol) {
        //     if (!unit.process) {
        //       unit.process = {} as IProcess;
        //     }
        //     unit.process.protocol = _protocol as IWinemakingProtocol;
        //
        //   }
        //
        //   // TODO for v2
        //   // Link manual tasks (confirm with JM how they arrive)
        //   // Link protocol.stages.tasks to unit.tasks
        //   // for each protocols
        //   // for each protocol.stages
        //   // for each stage.tasks
        //   // if not there, add it unit.tasks.push(task)
        // })
        //
        // const unitsWithProtocols = this.$rootScope.WGUnits.units.filter(u => u?.process?.protocol);
        // //only show units in the selected place
        // _.remove(unitsWithProtocols, (unit: IUnit) => {
        //   return unit.place.id !== this.selectedPlace.id;
        // })

        // this.groupTasksByUnit(unitsWithProtocols);
        this.tableParams = this.buildNgTableParams(this);
        unhook();
      })

    }

    public $onDestroy() {
      console.log('WGProtocolTimelineView $onDestroy');
      if (this.$rootScope.protocol_timeline_reload_timer)
        clearInterval(this.$rootScope.protocol_timeline_reload_timer);
    }

    $onChanges(changes: any) {

      if (!this.WGApiData?.WGUnits?.ready || !this.WGApiData?.WGProcesses?.ready) {
        return;
      }

      if (!changes) return;

      if (changes.selectedPlace && changes.selectedPlace.currentValue) {
        this.tableParams = this.buildNgTableParams(this);
      }

      if ((changes.searchText || changes.filters)) {

        // const processes = changes.processes.currentValue;
        //
        // this.tasksByUnit = [];
        // processes.filter((p: IProcess) => p != null && p.process_type === "winemaking").forEach((p: IProcess) => {
        //     this.tasksByUnit.push({
        //         unit: p.unit,
        //         tasks: {
        //             overdue: p.tasks.filter((t: ITask) => t.status === "overdue" ),
        //             planned: p.tasks.filter((t: ITask) => t.status === "planned"),
        //             completed: p.tasks.filter((t: ITask) => t.status === "done" ),
        //             next: p.tasks.filter((t: ITask) => t.status === "next")
        //         }
        //     })
        // });
        //
        // // filter tasks by search text
        // if (this.searchText) {
        //     _.forEach(this.tasksByUnit, (element) => {
        //         element.tasks.overdue = _.filter(element.tasks.overdue, (t: ITask) => {
        //             return _.includes(t.name.toLowerCase(), this.searchText.toLowerCase());
        //         });
        //         element.tasks.planned = _.filter(element.tasks.planned, (t: ITask) => {
        //             return _.includes(t.name.toLowerCase(), this.searchText.toLowerCase());
        //         });
        //         element.tasks.completed = _.filter(element.tasks.completed, (t: ITask) => {
        //             return _.includes(t.name.toLowerCase(), this.searchText.toLowerCase());
        //         });
        //     });
        // }
      }


    }


    public buildNgTableParams(ctrl: WGProtocolTimelineView): any {
      return new ctrl.ngTable({
        page: 1,            // show first page
        count: 10,          // count per page
        sorting: {
          name: 'asc'     // initial sorting
        }
      }, {
        counts: [], // hide page counts control
        total: ctrl.tasksByUnit.length,
        getData: function ($defer: any, params: any) {
          ctrl.loading = true;
          //response is an array of units with fields `tasks` and `task_planned`
          ctrl.$http.get<{
            current_stage: IWinemakingStage,
            protocols: IWinemakingProtocol[],
            tasks: ITask[],
            planned_tasks: ITask[],
            id: number, // Unit ID
            place: number // Place ID
          }[]>("api/dashboard/units/winemaking/").then((response) => {
            const _tasksByUnit = response.data;
            ctrl.tasksByUnit = []
            _tasksByUnit.forEach((winemaking_active_unit) => {
              let _unit = _.find(ctrl.selectedPlace.units, {id: winemaking_active_unit.id})
              //if unit is not in the selected place, skip
              if (!_unit) {
                return;
              }

              if (_.isEmpty(winemaking_active_unit.tasks) && _.isEmpty(winemaking_active_unit.planned_tasks)) {
                return;
              }

              const unitTasks = winemaking_active_unit.tasks;
              const tasksByStatus = _.groupBy(unitTasks, 'status');

              let planedTasks = winemaking_active_unit.planned_tasks || [];
              // Planned tasks are Templates in BE. Fake their Status and template_id.
              planedTasks.forEach((t) => {
                t.status = wg.TASK_STATUS_TYPES.planned.id;
                t.template = t.id;
              });

              ctrl.tasksByUnit.push({
                unit: _unit,
                current_stage: winemaking_active_unit.current_stage,
                tasks: {
                  overdue: tasksByStatus[wg.TASK_STATUS_TYPES.overdue.id] || [],
                  planned: planedTasks,
                  completed: tasksByStatus[wg.TASK_STATUS_TYPES.completed.id] || [],
                  next: tasksByStatus[wg.TASK_STATUS_TYPES.next.id] || []
                }
              });
            });

            if (WG_debug) console.log('tasksByUnit', ctrl.tasksByUnit);


            const sortField = Object.keys(params.sorting())[0];
            let sortingAlgorithm = undefined;
            if (sortField === 'overdue') {
              const order = params.sorting()[sortField] === 'asc' ? 1 : -1;
              sortingAlgorithm = ctrl.sortOverdue(order);
            } else if (sortField === 'followup') {
              sortingAlgorithm = ctrl.getFollowupSortingFunction(params, sortField);
            } else if (sortField === 'unit') {
              sortingAlgorithm = ctrl.getUnitSortingFunction(params, sortField);
            } else if (sortField === 'completed') {
              sortingAlgorithm = ctrl.getCompletedSortingFunction(params, sortField);

            }

            const orderedData = params.sorting() ? ctrl.tasksByUnit.sort(sortingAlgorithm) : ctrl.tasksByUnit;

            ctrl.loading = false;
            $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));

          });
        }
      });
    }



    public getFollowupSortingFunction(params, sortField) {
      return (a: TimelineTableEntry, b: TimelineTableEntry) => {

        const taskAmountA = a.tasks.next.length + a.tasks.planned.length || 0;
        const taskAmountB = b.tasks.next.length + b.tasks.planned.length || 0;

        return (params.sorting()[sortField] === 'asc' ? 1 : -1) * (taskAmountA - taskAmountB);

      }
    }

    public getCompletedSortingFunction(params, sortField) {
      return (a: TimelineTableEntry, b: TimelineTableEntry) => {
        const lastCompletedA = a.tasks.completed[a.tasks.completed.length - 1];
        const lastCompletedB = b.tasks.completed[b.tasks.completed.length - 1];

        const nameA = lastCompletedA?.name || '';
        const nameB = lastCompletedB?.name || '';

        return (params.sorting()[sortField] === 'asc' ? 1 : -1) * (nameA.localeCompare(nameB));
      }
    }

    public getUnitSortingFunction(params, sortField) {
      return (a: TimelineTableEntry, b: TimelineTableEntry) => {
        return (params.sorting()[sortField] === 'asc' ? 1 : -1) * (a.unit.name.localeCompare(b.unit.name));
      }
    }

    public sortOverdue(order: number) {
      return (a: TimelineTableEntry, b: TimelineTableEntry) => {
        const sumOverdueA = a.tasks.overdue.reduce((acc, t) => acc + t.due_at.getTime(), 0);
        const sumOverdueB = b.tasks.overdue.reduce((acc, t) => acc + t.due_at.getTime(), 0);
        return (order * (sumOverdueA - sumOverdueB));
      }
    }

    public deleteProtocol(_unit: IUnit) {
      this.$rootScope.doStopProtocol?.(_unit);
      return;

      // let unit = this.WGApiData.WGUnits.units_id[_unit.id];
      // if (!unit.process?.protocol?.id) {
      //   console.warn("Nothing to delete??", unit);
      // } else {
      //   this.$http.delete(`api/dashboard/winemaking/protocol/${unit.process?.protocol?.id}/`).then((response) => {
      //     this.tasksByUnit = this.tasksByUnit.filter((entry: TimelineTableEntry) => entry.unit.id !== unit.id);
      //     this.tableParams = this.buildNgTableParams(this);
      //     this.tableParams.reload();
      //   })
      // }
    }

    // public resetProtocol(_unit: IUnit) {
    //   //delete protocol
    //   let unit = this.WGApiData.WGUnits.units_id[_unit.id];
    //   if (!unit.process?.protocol?.id) {
    //     console.warn("Nothing to delete??", unit);
    //   } else {
    //     this.$http.delete(`api/dashboard/winemaking/protocol/${unit.process?.protocol?.id}/`).then((response) => {
    //       //create new protocol
    //       this.$http.post<IWinemakingProtocol>("api/dashboard/winemaking/protocol/", {unit: unit.id}).then((response) => {
    //         unit.process.protocol = response.data;
    //
    //
    //       })
    //     })
    //   }
    // }

    public getLastCompletedTask(completeds: ITask[]): ITask {
      return _.maxBy(completeds, (t) => t.completed_at);
    }


  }


  App.component('wgProtocolTimelineView', {
    templateUrl: 'app/views/partials/wg-protocol-timeline-view.html',
    controller: WGProtocolTimelineView as any,
    controllerAs: 'ctrl',
    bindings: {
      selectedPlace: '<',
      timelineData: '='
    }
  })
}
