namespace wg {
  export class WgDatePickerController {
    static $inject = [];
    //internal state
    dropdownOpen: boolean = false;

    //overridable from outside
    selectedDate: Date | null;
    disabled: string | boolean;
    displayNowButton: boolean;
    displayHour: boolean
    displayMinutes: boolean

    displayResetButton: boolean;

    onDateChangeCallback: (date: any) => void;

    constructor() {
    }

    onChange() {
      if (this.onDateChangeCallback) {
        this.onDateChangeCallback({date: this.selectedDate});
      }
      this.dropdownOpen = false;
    }

    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    }

    closeDropdown() {
      this.dropdownOpen = false;
    }

    setToday() {
      this.selectedDate = new Date();
      this.onChange();
    }

    resetDate() {
      this.selectedDate = null;
      this.onChange();
    }

    changeDate(date: Date) {
      this.selectedDate = date;
    }

    $onInit = () => {
      // if(WG_debug) console.log("WgDatePickerController $onInit", this.selectedDate, this.disabled);
      this.disabled = (this.disabled === true || this.disabled == "disabled");
    }
  }

  App.component('wgDatePicker', {
    controller: WgDatePickerController as any,
    templateUrl: "app/views/directives/wg-date-picker.html",
    controllerAs: 'ctrl',
    bindings: { // component works self-contained but if you input these objects, it will update the parent scope
      selectedDate: '=?',
      disabled: '=?',
      onDateChangeCallback: '&?',
      displayNowButton: '=?',
      displayResetButton: '=?',
      displayHour: '=?',
      displayMinutes: '=?'
    },
  });
}
