/**=========================================================
 * Module: config.js
 * App routes and resources configuration
 =========================================================*/

// App.config(function ($provide) {
//   $provide.decorator('$state', function ($delegate) {
//
//     // let's locally use 'state' name
//     var state = $delegate;
//
//     // let's extend this object with new function
//     // 'baseGo', which in fact, will keep the reference
//     // to the original 'go' function
//     state.baseGo = state.go;
//
//     // here comes our new 'go' decoration
//     var go = function (to, params, options) {
//       options = options || {};
//
//       // only in case of missing 'reload'
//       // append our explicit 'true'
//       if (angular.isUndefined(options.reload)) {
//
//         options.reload = true;
//       }
//
//       // return processing to the 'baseGo' - original
//       console.log('----->  go', to, params, options);
//       this.baseGo(to, params, options);
//     };
//
//     // assign new 'go', right now decorating the old 'go'
//     state.go = go;
//
//     return $delegate;
//   });
// });
namespace wg {

  export const apiUrls = {
    protocolTemplateURL: 'api/dashboard/winemaking/protocol_template/',
    winemaking: 'api/dashboard/winemaking/',
  }

  App.config(['$stateProvider', '$locationProvider', '$urlRouterProvider', 'APP_REQUIRES', 'RouteHelpersProvider',
    function ($stateProvider, $locationProvider, $urlRouterProvider, appRequires, helper) {
      'use strict';
      // Set the following to true to enable the HTML5 Mode
      // You may have to set <base> tag in index and a routing configuration in your server
      $locationProvider.html5Mode(false);

      $urlRouterProvider.otherwise(function ($injector, $location) {
            console.log('App config Otherwise');
            var ret = null;
            var AuthService = $injector.get('AuthService');
            var $state = $injector.get('$state');

            function getUrl(state) {
              return parseUrl($state.href(state))
            }

            function parseUrl(url) {
              //return url.startsWith('#/') ? url.replace('#', '') : url;
              return stringStartsWith(url, '#/') ? url.replace('#', '') : url;
            }

            var ud = AuthService.getUserData();
            if (ud?.last_state?.href) {
              //console.log('$urlRouterProvider.otherwise', ud.last_state);
              ret = parseUrl(ud.last_state.href);

              // Invalidate state. Non-existent or Abstract
              if (!$state.get(ud.last_state.name)
                  || $state.get(ud.last_state.name).abstract === true) {
                ret = null;
                if (WG_debug) {
                  console.log('Invalid last-state: ', $state.get(ud.last_state.name));
                }
              }
            }
            if (!ret) {
              if (AuthService.isLogged) {
                ret = getUrl(AuthService.homeState);
              } else {
                ret = getUrl(AuthService.loginState);
              }
            }
            // console.log('config otherwise', ret);
            return ret;
          }
      );

      // default route
      //$urlRouterProvider.otherwise('/app/devices/dashboard');

      //
      // Application Routes
      // -----------------------------------
      $stateProvider
          .state('app', {
            url: '/app?user',
            abstract: true,
            templateUrl: helper.basepath('app.html'),
            controller: 'AppController',
            resolve: helper.resolveFor('slimscroll', 'icons', 'ui.gravatar'
                , 'toaster', 'jsonFormatter', 'angular-click-outside', 'textAngular'
                , 'filestyle' // Used for feedback
                , 'ngDialog', 'ngTable'//, 'highcharts-css', 'whirl'
            )
          })
          .state('page', {
            url: '/page',
            templateUrl: 'app/pages/page.html',
            resolve: helper.resolveFor('icons', 'ngDialog', 'ngTable', 'textAngular'),
            accessLevel: 'public'
          })
          .state('app.acqua', {
            url: '/acqua',
            title: 'Acqua',
            templateUrl: helper.basepath('acqua.html'),
            resolve: helper.resolveFor('timestring', 'localytics.directives', 'uiGmapgoogle-maps'),
            accessLevel: 'user'
          })
          // .state('app.home', {
          //   url: '/home',
          //   title: 'Home',
          //   // templateUrl: helper.basepath('home.html'),
          //   // resolve: helper.resolveFor('localytics.directives'),
          //   templateUrl: helper.basepath('partials/devices-units-overview.html'),
          //   // templateUrl: helper.basepath('partials/devices-overview.html'),
          //   resolve: helper.resolveFor('ng-nestable', 'highcharts-ng', 'highcharts-yaxis-panning', 'highcharts-scalable-yaxis', 'loader',  'ui.bootstrap.datetimepicker', 'ng-FitText'
          //       // , 'duScroll'
          //   ),
          //   redirectTo: 'app.devices.dashboard',
          // templateUrl: helper.basepath('partials/devices-units-card.html'),
          // resolve: helper.resolveFor( 'ng-nestable', 'highcharts-ng', 'highcharts-yaxis-panning', 'highcharts-scalable-yaxis', 'loader',  'ui.bootstrap.datetimepicker', 'ng-FitText'
          // ),
          //   accessLevel: 'user'
          // })
          // .state('app.wine-grid', {
          //   url: '/wine-grid',
          //   abstract: true,
          //   templateUrl: helper.basepath('wine-grid.html'),
          //   resolve: helper.resolveFor('colorpicker.module', 'chart', 'paho-mqtt', 'highcharts-ng', 'localytics.directives',  'chart.js', 'highstock-export'),
          //   accessLevel: 'public'
          // })
          // .state('app.wine-grid.level', {
          //   url: '/level',
          //   title: 'Level',
          //   templateUrl: helper.basepath('wine-grid-level.html'),
          //   resolve: helper.resolveFor('bootstrap-progressbar'),
          //   accessLevel: 'public'
          // })
          // .state('app.wine-grid.values', {
          //   url: '/values',
          //   title: 'Values',
          //   templateUrl: helper.basepath('wine-grid-values.html'),
          //   //resolve: helper.resolveFor(),
          //   accessLevel: 'public'
          // })
          // .state('app.wine-grid.turbidity', {
          //   url: '/turbidity',
          //   title: 'Turbidity',
          //   templateUrl: helper.basepath('wine-grid-turbidity.html'),
          //   //resolve: helper.resolveFor(),
          //   accessLevel: 'public'
          // })
          // .state('app.wine-grid.raw', {
          //   url: '/raw',
          //   title: 'Raw Values',
          //   templateUrl: helper.basepath('wine-grid-raw.html'),
          //   //resolve: helper.resolveFor(),
          //   accessLevel: 'public'
          // })
          .state('app.admin', {
            url: '/admin',
            abstract: true,
            templateUrl: helper.basepath('admin.html'),
            resolve: helper.resolveFor('localytics.directives'),
            accessLevel: 'admin'
          })
          .state('app.admin.users', {
            url: '/users',
            title: 'Admin Users',
            templateUrl: helper.basepath('partials/admin-users.html'),
            accessLevel: 'admin'
          })
          .state('app.admin.groups', {
            url: '/groups',
            title: 'Admin Groups',
            templateUrl: helper.basepath('partials/admin-groups.html'),
            accessLevel: 'admin'
          })
          .state('app.admin.devices', {
            url: '/devices',
            title: 'Admin Devices',
            templateUrl: helper.basepath('partials/admin-devices.html'),
            accessLevel: 'admin'
          })
          .state('app.notifications', {
            url: '/notifications',
            templateUrl: helper.basepath('notifications.html'),
            accessLevel: 'user'
          })
          .state('app.notes', {
            url: '/notes',
            title: 'Notes',
            params: {
              action: null,
              note: null,
              attachments: null
            },
            templateUrl: helper.basepath('notes.html'),
            // resolve: helper.resolveFor('angularFileUpload', 'filestyle'),
            accessLevel: 'user'
          })
          .state('app.groups', { // TODO: Delete
            url: '/groups',
            templateUrl: helper.basepath('groups.html'),
            accessLevel: 'user'
          })
          .state('app.profile', {
            url: '/profile',
            templateUrl: helper.basepath('profile.html'),
            resolve: helper.resolveFor('loader', 'ui.sortable'),
            accessLevel: 'loggedin'
          })
          .state('app.config', {
            url: '/config',
            templateUrl: helper.basepath('ai-configs.html'),
            resolve: helper.resolveFor('loader'),
            accessLevel: 'user'
          })
          .state('app.geosite', {
            url: '/geosite',
            title: 'Devices Map positioning',
            templateUrl: helper.basepath('geosite.html'),
            resolve: helper.resolveFor('uiGmapgoogle-maps'),
            accessLevel: 'user'
          })
          .state('app.faq', {
            url: '/faq',
            title: 'FAQ',
            templateUrl: helper.basepath('partials/faq.html'),
            resolve: helper.resolveFor(),
            accessLevel: 'user'
          })
          .state('app.devices', {
            url: '/devices?place&unit&device&device_uuid&xAxisMin&xAxisMax&device1&param1&device2&param2&device3&param3&device4&param4&device5&param5&device6&param6&device7&param7&device8&param8&device9&param9&device10&param10&device11&param11&device12&param12&device13&param13&device14&param14&device15&param15&device16&param16&device17&param17&device18&param18&device19&param19',
            abstract: true,
            templateUrl: helper.basepath('devices.html'),
            resolve: helper.resolveFor('timestring', 'localytics.directives'
                // , 'ui.bootstrap.datetimepicker'
                , 'ng-nestable', 'loader', 'ng-FitText'),
            accessLevel: 'user',
          })
          .state('app.devices.dashboard', { // app.devices IS THE PARENT!!
            url: '/dashboard?place_id&unit_id&device_sn&device_id&view&filter&searchText&sort',
            title: 'Overview',
            templateUrl: helper.basepath('partials/devices-units-card.html'),
            // resolve: helper.resolveFor(),
            accessLevel: 'user'
          })
          .state('app.devices.units', {
            url: '/units?sensors&parameter&nest_by',
            title: 'Technical View',
            templateUrl: helper.basepath('partials/devices-units.html'),
            resolve: helper.resolveFor('JSONedit'
            ),
            accessLevel: 'user'
          })
          .state('app.devices.compare', {
            url: '/compare?devices&parameter&parameters&nest_by', // And all hinerited from /devices
            title: 'Compare',
            templateUrl: helper.basepath('partials/devices-compare.html'),
            // resolve: helper.resolveFor(),
            accessLevel: 'user'
          })
          .state('app.devices.export', {
            url: '/export',
            title: 'Export',
            params: {
              start: null,
              end: null,
              devices: null,
              sensors: null,
              params: null,
              parameters: null,
            },
            templateUrl: helper.basepath('partials/export-data.html'),
            // resolve: helper.resolveFor(),
            accessLevel: 'user'
          })
          .state('app.devices.reports', {
            url: '/reports',
            title: 'Reports',
            templateUrl: helper.basepath('partials/reports.html'),
            // resolve: helper.resolveFor(),
            accessLevel: 'user'
          })
          .state('app.devices.pairing', {
            url: '/pairing',
            title: 'Pairing',
            templateUrl: helper.basepath('partials/devices-pairing.html'),
            // resolve: helper.resolveFor(),
            accessLevel: 'user'
          })
          .state('app.devices.edit-report', {
            url: '/edit',
            title: 'Edit Report',
            controller: 'ReportEditController',
            templateUrl: helper.basepath('partials/report-edit.html'),
            // resolve: helper.resolveFor(),
            accessLevel: 'user'
          })
          .state("app.manage.winemaking", {
            url: "/winemaking?protocol&share_code",
            title: "Winemaking",
            params: {
              protocol: null,
              share_code: null,
            },
            templateUrl: helper.basepath("partials/manage-protocols.html"),
            resolve: helper.resolveFor("loader", "ng-FitText"),
            accessLevel: "user",
          }).state('page.test', {
        url: '/test',
        title: 'Test',
        // templateUrl: helper.basepath('test-bed.html'),
        template: "<test-bed></test-bed>",
        resolve: helper.resolveFor("loader", "ng-FitText"),
        accessLevel: 'public'
      })
          .state('app.manage', {
            url: '/manage',
            abstract: true,
            templateUrl: helper.basepath('devices.html'),
            resolve: helper.resolveFor('loader', 'ng-FitText'),
            accessLevel: 'user'
          })
          .state('app.manage.licenses', {
            url: '/licenses',
            title: 'Licenses',
            templateUrl: helper.basepath('partials/manage-licenses.html'),
            resolve: helper.resolveFor('loader'),
            accessLevel: 'distributor'
          })
          .state('app.manage.pendinglicenses', {
            url: '/pendinglicenses',
            title: 'Pending Licenses',
            templateUrl: helper.basepath('partials/manage-unlicensed.html'),
            // Pass the "pending" parameter to the controller
            params: {
              pending: true
            },
            resolve: helper.resolveFor('loader'),
            accessLevel: 'distributor'
          })
          .state('app.manage.unlicensed', {
            url: '/unlicensed',
            title: 'Unlicensed',
            templateUrl: helper.basepath('partials/manage-unlicensed.html'),
            resolve: helper.resolveFor('loader'),
            accessLevel: 'distributor'
          })
          .state('app.manage.places', {
            url: '/places?place',
            title: 'Places',
            templateUrl: helper.basepath('partials/manage-places.html'),
            resolve: helper.resolveFor('loader', 'ng-FitText'),
            accessLevel: 'user'
          })
          .state('app.manage.batches', {
            url: '/batches?batch',
            title: 'Batches',
            templateUrl: helper.basepath('partials/manage-batches.html'),
            resolve: helper.resolveFor('loader', 'ng-FitText'),
            accessLevel: 'user'
          })
          .state('app.manage.processes', {
            url: '/processes?process',
            title: 'Processes',
            templateUrl: helper.basepath('partials/manage-processes.html'),
            resolve: helper.resolveFor('loader', 'ng-FitText'),
            accessLevel: 'user'
          })

          .state('app.manage.units', {
            url: '/units?unit',
            title: 'Monitored Units',
            templateUrl: helper.basepath('partials/manage-units.html'),
            resolve: helper.resolveFor('loader', 'ng-FitText'),
            accessLevel: 'user'
          })
          .state('app.manage.devices', {
            url: '/devices?device',
            title: 'Devices',
            templateUrl: helper.basepath('home.html'),
            // resolve: helper.resolveFor(  'loader', 'ng-FitText'),
            resolve: helper.resolveFor('localytics.directives'),
            accessLevel: 'user'
          })
          .state('app.manage.sensors', {
            url: '/sensors?device',
            title: 'Sensors',
            templateUrl: helper.basepath('sensors.html'),
            // resolve: helper.resolveFor(  'loader', 'ng-FitText'),
            resolve: helper.resolveFor('localytics.directives'),
            accessLevel: 'user'
          })
          .state('app.manage.smartbox', {
            url: '/smartbox?device',
            title: 'Smartboxes',
            templateUrl: helper.basepath('smartbox.html'),
            // resolve: helper.resolveFor(  'loader', 'ng-FitText'),
            resolve: helper.resolveFor('localytics.directives'),
            accessLevel: 'user'
          })
          .state('app.manage.alarm_templates', {
            url: '/alarm_templates?alarm',
            title: 'Alarm Presets',
            templateUrl: helper.basepath('partials/manage-alarm-templates.html'),
            resolve: helper.resolveFor('loader'),
            accessLevel: 'user'
          })
          .state('app.manage.alarms', {
            url: '/alarms?alarm',
            title: 'Alarms',
            templateUrl: helper.basepath('partials/manage-alarms.html'),
            resolve: helper.resolveFor('loader'),
            accessLevel: 'user'
          })
          .state('app.error', {
            url: '/error/:error',
            title: 'Error',
            templateUrl: helper.basepath('error.html'),
            accessLevel: 'public'
          })
          //
          // Single Page Routes
          // -----------------------------------
          .state('page.login', {
            url: '/login',
            title: "Login",
            templateUrl: 'app/pages/login.html',
            params: {
              detail: null,
              status: null
            },
            accessLevel: 'public'
          })
          // .state('page.must-login', {
          //   // url: '/login/{detail}{status:int}',
          //   // url: '/login/:detail/:status',
          //   // url: '/login/:detail',
          //   url: '/login',
          //   params: {
          //     detail: null,
          //     status: null
          //   },
          //   title: "Login",
          //   templateUrl: 'app/pages/login.html',
          //   accessLevel: 'public'
          // })
          .state('page.register', {
            url: '/register',
            title: "Register",
            templateUrl: 'app/pages/register.html',
            resolve: helper.resolveFor('text-mask'),
            // accessLevel: 'anon'
            accessLevel: 'public'
          })
          .state('page.recover', {
            url: '/recover',
            title: "Recover",
            templateUrl: 'app/pages/recover.html',
            // accessLevel: 'anon'
            accessLevel: 'public'
          })
          .state('page.recover-confirm', {
            url: '/recover-confirm/:uid/:token',
            // url: '/recover-confirm',
            title: "Confirm",
            templateUrl: 'app/pages/recover-confirm.html',
            // accessLevel: 'anon'
            accessLevel: 'public'
          })
          .state('page.account-confirm', {
            url: '/account-confirm/:key',
            title: "Confirm",
            templateUrl: 'app/pages/account-confirm.html',
            // accessLevel: 'anon'
            accessLevel: 'public'
          })
          //.state('page.lock', {
          //    url: '/lock',
          //    title: "Lock",
          //    templateUrl: 'app/pages/lock.html',
          //    accessLevel: 'public'
          //})
          .state('page.disabled', {
            url: '/disabled',
            title: "Disabled",
            templateUrl: 'app/pages/disabled.html',
            accessLevel: 'public'
          })
          .state('page.404', {
            url: '/404',
            title: "Not Found",
            templateUrl: 'app/pages/404.html',
            accessLevel: 'public'
          })
      //
      // CUSTOM RESOLVES
      //   Add your own resolves properties
      //   following this object extend
      //   method
      // -----------------------------------
      // .state('app.someroute', {
      //   url: '/some_url',
      //   templateUrl: 'path_to_template.html',
      //   controller: 'someController',
      //   resolve: ng.extend(
      //     helper.resolveFor(), {
      //     // YOUR RESOLVES GO HERE
      //     }
      //   )
      // })
      // .state('dev', {
      //   url: '/dev',
      //   abstract: true,
      //   templateUrl: helper.basepath('dev-app.html'),
      //   controller: 'AppController',
      //   resolve: helper.resolveFor('paho-mqtt', 'slimscroll', 'toaster', 'jsonFormatter'),
      //   accessLevel: 'admin'
      // })
      // .state('dev.dev', {
      //   url: '/dev',
      //   title: 'Dev',
      //   templateUrl: helper.basepath('partials/dev.html'),
      //   resolve: helper.resolveFor(  'parsley', 'timestring', 'lodash',
      //     'localytics.directives',
      //     'ng-nestable', 'loader',
      //     'highcharts-ng', 'highcharts-yaxis-panning', 'highcharts-scalable-yaxis',
      //     'ng-FitText')
      // })

      ;


    }])
      .config(['$ocLazyLoadProvider', 'APP_REQUIRES', function ($ocLazyLoadProvider, APP_REQUIRES) {
        'use strict';

        // Lazy Load modules configuration
        $ocLazyLoadProvider.config({
          debug: false,
          events: true,
          modules: APP_REQUIRES.modules
        });

      }]).config(['$controllerProvider', '$compileProvider', '$filterProvider', '$provide',
    function ($controllerProvider, $compileProvider, $filterProvider, $provide) {
      'use strict';
      // registering components after bootstrap
      App.controller = $controllerProvider.register;
      App.directive = $compileProvider.directive;
      App.filter = $filterProvider.register;
      App.factory = $provide.factory;
      App.service = $provide.service;
      App.constant = $provide.constant;
      App.value = $provide.value;
      App.decorator = $provide.decorator;
    }]).config(['tmhDynamicLocaleProvider', function (tmhDynamicLocaleProvider) {
    tmhDynamicLocaleProvider.localeLocationPattern('vendor/angular-i18n/angular-locale_{{locale}}.js' + '?v=' + window['get_WG_version']());
  }]).config(['$translateProvider', function ($translateProvider) {
    $translateProvider.useStaticFilesLoader({
      prefix: 'app/i18n/',
      suffix: '.json' + '?v=' + window['get_WG_version']()
    });
    $translateProvider.preferredLanguage('en-GB');
    //$translateProvider.preferredLanguage('pt-PT');
    // $translateProvider.preferredLanguage('en-US');
    //$translateProvider.preferredLanguage('fr-FR');
    //$translateProvider.rememberLanguage(true);
    $translateProvider.useLocalStorage();
    $translateProvider.usePostCompiling(true);
    $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
    //$translateProvider.use($translateProvider.use() || $translateProvider.preferredLanguage());
  }]).config(['$httpProvider', function ($httpProvider) {
    // https://stackoverflow.com/questions/10388033/csrf-verification-failed-request-aborted/35833923#35833923
    $httpProvider.defaults.headers.post['X-CSRFToken'] = $('meta[name=csrf_token]').attr('content');
    // register the interceptor via an anonymous factory
    $httpProvider.interceptors.push(["$q", "$location", "$injector",
      function ($q, $location: ng.ILocationService, $injector) {
        return {
          'responseError': function (response) {
            if (response.data) {
              let _detail = response.data.detail || response.data.non_field_errors?.[0] || response.data.non_field_errors?.[1] || null;
              if (WG_debug) console.error('http interceptor 1', response.status, response.data);
              if (response.status === 401
                  && (
                      response.data.code == "token_not_valid" ||
                      _detail == 'Authentication credentials were not provided.' ||
                      _detail == 'Signature has expired.' ||
                      _detail == 'Error decoding signature.' ||
                      _detail == 'Invalid signature.' ||
                      _detail == 'As credenciais de autenticação não foram fornecidas.' ||
                      _detail == 'Assinatura expirou.' ||
                      _detail == 'Assinatura inválida.' ||
                      _detail == 'Informations d\'authentification non fournies.' ||
                      _detail == 'Signature a expiré.' ||
                      _detail == 'Signature non valide.'
                  )) {

                let AuthService = $injector.get('AuthService');
                if (AuthService) {
                  if (WG_debug) console.warn('Logging Out.');
                  // AuthService.logout();
                  AuthService.deleteToken();
                  AuthService.deleteUser();
                  AuthService.user = null;
                  AuthService.userData = {};
                  AuthService.isLogged = false;
                  window.location.reload();
                } else {
                  if (WG_debug) console.warn('Reloading.');
                  window.location.reload();
                }
                // $injector.get('$state').go('page.must-login', {
                // }, {location: true, inherit: false});

                // $injector.get('$state').go('page.login', {
                //   detail: _detail,
                //   status: response.status
                // }, {location: true, inherit: false, notify: false, reload: true});
              } else if (response.status === 403 && _detail == 'You do not have permission to perform this action.') {
                if (WG_debug) console.error('You do not have permission to perform this action.', response);
              } else if (_detail == 'User account is disabled.') {
                $injector.get('$state').go('page.disabled', {
                  detail: _detail,
                  status: response.status
                }, {location: true, inherit: false});
              }
              return $q.reject(response);
            } else {
              if (WG_debug) console.error('http interceptor 2', response.status, response);
              // console.error('No Internet! Please verify connection and reload');
              return $q.reject(response);
            }
          }
        };
      }]);


    $httpProvider.interceptors.push(["$q", "$location", "$injector",
      function ($q, $location: ng.ILocationService, $injector) {
        return {
        //   TODO: intercept incoming static files, search for a Header: Version, and if changed, reload the page
        //   'response': function (response) {
        //     if (response.config.url.startsWith('app/') && response.headers('X-Version')) {
        //       if (WG_debug) console.log('X-Version', response.headers('X-Version'), response.config.url);
        //       if (window['get_WG_version']() != response.headers('X-Version')) {
        //         // S
        //
        //         if (WG_debug) console.log('Reloading page due to version change');
        //         window.location.reload();
        //       }
        //     }
        //     return response;
        //   },

          'request': function (config) {
            var AuthService = $injector.get('AuthService');
            // remove starting slash
            if (config.url.startsWith('/')) {
              config.url = config.url.slice(1);
            }
            if (config.url.startsWith('api/dashboard/') &&
                !config.url.startsWith('api/dashboard/sensors/') &&
                !config.url.startsWith('api/dashboard/feedback/') &&
                !config.url.startsWith('api/dashboard/notification/')) {

              if (AuthService.view_as_owner) {

                if (config.method !== "GET" && AuthService.view_as_owner.write === false) {
                  console.error("Trying to make a Write request in Read-Only account!");
                  if (WG_debug) console.info("request", config);
                  config.url = undefined;
                  delete config.data;
                  return $q.reject("Trying to make a Write request in Read-Only account!");
                }

                if (AuthService.view_as_owner.id > 0) { // Valid user selected

                  // When getting/updating alarms/rules, use AuthService.user, unless client_view is enabled
                  if (config.url.startsWith('api/dashboard/alarm') ||
                      config.url.startsWith('api/dashboard/rule')) {
                    if (AuthService.user_is_admin && AuthService.clients_view) {
                      //TODO implement viewing client alarms as admin with new notification targets feature
                      config.url = updateURLParameter(config.url, 'user_id', AuthService.view_as_owner.id);
                      config.url = updateURLParameter(config.url, 'owner__username', AuthService.view_as_owner.username);

                      // This prevents "stealing" of devices when "viewing as"
                      if (config.method !== "GET" && config.method !== "DELETE" && config.data) {
                        config.data = _.cloneDeep(config.data); // prevent changing original object
                        config.data.owner = {'username': AuthService.view_as_owner.username};
                      }
                    }
                  } else if (config.url.startsWith('api/dashboard/winemaking/protocol_template/')) {
                    // This prevents "stealing" of protocols when saving them
                    if (config.method !== "GET" && config.method !== "DELETE") {
                      config.url = updateURLParameter(config.url, 'owner__username', AuthService.view_as_owner.username);
                      if (config.data) {
                        config.data = _.cloneDeep(config.data); // prevent changing original object
                        config.data.owner = {'username': AuthService.view_as_owner.username};
                      }
                    }
                  } else {
                    if (config.url.startsWith('api/dashboard/distributors')
                        || config.url.startsWith('api/dashboard/devices/unlicensed')
                        || config.url.startsWith('api/dashboard/devices/inventory')) {
                      if (!(AuthService.user_is_admin
                          && (config.url.startsWith('api/dashboard/distributors/unlicensed')
                              || config.url.startsWith('api/dashboard/devices/unlicensed'))
                      )) {
                        // non-Admin users don't see unlicensed, only Pending (same endpoint, but with a valid distributor__username
                        config.url = updateURLParameter(config.url, 'distributor__username', AuthService.view_as_owner.username);
                      }
                    } else {
                      config.url = updateURLParameter(config.url, 'owner__username', AuthService.view_as_owner.username);
                    }
                    // This prevents "stealing" of devices when "viewing as"
                    if (config.method !== "GET" && config.method !== "DELETE"
                        && config.data) {
                      config.data = _.cloneDeep(config.data); // prevent changing original object
                      config.data.owner = {'username': AuthService.view_as_owner.username};
                    }
                  }

                } else if (AuthService.view_as_owner.id === -2 || AuthService.view_as_owner.id === -4) { // "All clients" selected
                  // TODO: Add other dev/non-client accounts to exclusion list
                  if (config.url.startsWith('api/dashboard/distributors')) {
                    // TODO: Implement "all distributores" view
                    config.url = updateURLParameter(config.url, 'distributor__username', 'Watgrid');
                  } else {
                    config.url = updateURLParameter(config.url, 'owner__username__ne', 'admin.script');
                  }
                } else if (AuthService.view_as_owner.id === -1) { // "All" selected
                  // "All - DANGEROUS"
                } else {
                  // Shouldn't happen
                  console.warn("Unknown user!", AuthService.view_as_owner);
                }

              }
            }

            if (config.url.startsWith('data/')) {
              if (AuthService.view_as_owner) {
                if (config.method !== "GET") {
                  if (AuthService.view_as_owner.write === false || AuthService.view_as_owner.id === -2) {
                    console.error("Trying to make a Write request in Read-Only account!");
                    if (WG_debug) console.info("request", config);
                    config.url = undefined;
                    delete config.data;
                    return $q.reject("Trying to make a Write request in Read-Only account!");
                  }

                  // This prevents "stealing" of devices when "view as client"
                  if (config.data) {
                    config.data = _.cloneDeep(config.data); // prevent changing original object
                    if (AuthService.clients_view && AuthService.user_is_admin && (config.url.includes('/SET_ALARM') || config.url.includes('/REMOVE_ALARM'))) {
                      config.data.user = {
                        'id': AuthService.view_as_owner.id,
                        'username': AuthService.view_as_owner.username,
                        'email': AuthService.view_as_owner.email,
                      };
                    } else {
                      // When we are acting as another user, inform the data API who I really am
                      config.data.user = {
                        'id': AuthService.user.id,
                        'username': AuthService.user.username,
                        'email': AuthService.user.email,
                      };
                    }
                  }
                }
              }
            }
            // if (config.url.startsWith("app/views/") && config.url.endsWith('.html')) {
            // if (config.url.match('^app/views/.*\.html$')) {
            if (config.url.match('^app/.*\.(html|json|js|css)$')) {
              config.url = updateURLParameter(config.url, 'v', window['get_WG_version']()); // Constant cache-burst when Debugging
            }
            return config;
          }
        };
      }]);
  }
  ]).config(['$provide', function ($provide) {
    // http://stackoverflow.com/questions/14718826/angularjs-disable-partial-caching-on-dev-machine
    // Set a suffix outside the decorator function
    // var cacheBust = Date.now().toString();
    // var cacheBust = WG_version;
    function templateFactoryDecorator($delegate) {
      let fromUrl = angular.bind($delegate, $delegate.fromUrl);
      $delegate.fromUrl = function (url, params) {
        if (url != null && angular.isString(url)) {
          url = updateURLParameter(url, 'v', window['get_WG_version']());
        }
        // console.log('templateFactoryDecorator', url, params);
        // @ts-ignore
        return fromUrl(url, params);
      };
      return $delegate;
    }

    $provide.decorator('$templateFactory', ['$delegate', templateFactoryDecorator]);
  }]).config(['$provide', 'RouteHelpersProvider', 'uibDatepickerPopupConfig', function ($provide, helper, uibDatepickerPopupConfig) {
    $provide.decorator('taOptions', ['taRegisterTool', '$delegate', '$uibModal',
      function (taRegisterTool, taOptions, $uibModal) {
        taRegisterTool('uploadFile', {
          buttontext: 'Upload File',
          iconclass: "icon-cloud-upload",
          action: function (deferred, restoreSelection) {
            $uibModal.open({
              controller: 'FileUploadModalInstance',
              templateUrl: helper.basepath('modals/file-upload.html')
            }).result.then(
                function (results) {
                  restoreSelection();
                  var location = window.location.origin;
                  angular.forEach(results, function (result) {
                    document.execCommand('insertImage', true, location + result.file);
                  });
                  deferred.resolve();
                },
                function () {
                  deferred.resolve();
                }
            );
            return false;
          }
        });
        taOptions.toolbar[3].push('uploadFile');
        return taOptions;
      }]);
    $provide.decorator('taTranslations', ['$delegate', '$translate',
      function ($delegate, $translate: ng.translate.ITranslateService) {
        var localeId = $translate.proposedLanguage() || $translate.use() || $translate.preferredLanguage();
        if (localeId === 'pt-PT') {
          uibDatepickerPopupConfig.closeText = 'Fechar';
          uibDatepickerPopupConfig.currentText = 'Hoje';
          uibDatepickerPopupConfig.clearText = 'Apagar';
        } else if (localeId === 'fr-FR') {
          uibDatepickerPopupConfig.closeText = 'Fermer';
          uibDatepickerPopupConfig.currentText = 'Courant';
          uibDatepickerPopupConfig.clearText = 'Effacer';
        }
        if (localeId === 'pt-PT') {
          $delegate.html.tooltip = "Alternar html / Rich Text";
          $delegate.heading.tooltip = "Cabeçalho ";
          $delegate.p.tooltip = "Parágrafo";
          $delegate.pre.tooltip = "Texto pré-formatado";
          $delegate.ul.tooltip = "Lista não ordenada";
          $delegate.ol.tooltip = "Lista Ordenada";
          $delegate.quote.tooltip = "Citar / não citar seleção ou parágrafo";
          $delegate.undo.tooltip = "Desfazer";
          $delegate.redo.tooltip = "Refazer";
          $delegate.bold.tooltip = "Negrito";
          $delegate.italic.tooltip = "Itálico";
          $delegate.underline.tooltip = "Sublinhar";
          $delegate.strikeThrough.tooltip = "Rasurado";
          $delegate.justifyLeft.tooltip = "Alinhar texto à esquerda";
          $delegate.justifyRight.tooltip = "Alinhar texto à direita";
          $delegate.justifyFull.tooltip = "Justificar texto";
          $delegate.justifyCenter.tooltip = "Centro";
          $delegate.indent.tooltip = "Aumenta o parágrafo";
          $delegate.outdent.tooltip = "Diminuir parágrafo";
          $delegate.clear.tooltip = "Limpar a formatação";
          $delegate.insertImage.dialogPrompt = "Por favor, indique o URL da imagem para inserir";
          $delegate.insertImage.tooltip = "Inserir imagem";
          $delegate.insertImage.hotkey = "a - possivelmente atalho dependente de idioma... por implementação futura";
          $delegate.insertVideo.tooltip = "Inserir vídeo";
          $delegate.insertVideo.dialogPrompt = "Por favor, indique o URL do youtube para incorporar";
          $delegate.insertLink.tooltip = "Inserir / link editar";
          $delegate.insertLink.dialogPrompt = "Por favor, indique o URL para inserir";
          $delegate.editLink.reLinkButton.tooltip = "Relink";
          $delegate.editLink.unLinkButton.tooltip = "Unlink";
          $delegate.editLink.targetToggle.buttontext = "Abrir numa nova janela";
          $delegate.wordcount.tooltip = "Exibir contagem de palavras";
          $delegate.charcount.tooltip = "Exibir contagem de caracteres";
        } else if (localeId === 'fr-FR') {
          $delegate.html.tooltip = "Alterner html/text";
          $delegate.heading.tooltip = "Titre ";
          $delegate.p.tooltip = "Paragraphe";
          $delegate.pre.tooltip = "Texte préformaté";
          $delegate.ul.tooltip = "Liste non ordonée";
          $delegate.ol.tooltip = "Liste ordonée";
          $delegate.quote.tooltip = "Citer/ne pas citer la sélection ou le paragraphe";
          $delegate.undo.tooltip = "Défaire";
          $delegate.redo.tooltip = "Refaire";
          $delegate.bold.tooltip = "Gras";
          $delegate.italic.tooltip = "Italique";
          $delegate.underline.tooltip = "Souligné";
          $delegate.strikeThrough.tooltip = "Barré";
          $delegate.justifyLeft.tooltip = "Aligner le texte à gauche";
          $delegate.justifyRight.tooltip = "Aligner le texte à droite";
          $delegate.justifyFull.tooltip = "Justifier le texte";
          $delegate.justifyCenter.tooltip = "Centrer";
          $delegate.indent.tooltip = "Augmenter le retrait";
          $delegate.outdent.tooltip = "Réduire le retrait";
          $delegate.clear.tooltip = "Formattage clair";
          $delegate.insertImage.dialogPrompt = "Veuillez entrer l'URL de l'image à insérer";
          $delegate.insertImage.tooltip = "Insérer une image";
          $delegate.insertImage.hotkey = "Raccourci clavier pour un possible changement de langue ... pour une future mise en oeuvre";
          $delegate.insertVideo.tooltip = "Insérer une video";
          $delegate.insertVideo.dialogPrompt = "Veuillez entrer l'URL youtube à charger";
          $delegate.insertLink.tooltip = "Insérer/Edit un lien";
          $delegate.insertLink.dialogPrompt = "Veuillez entrer l'URL à insérer";
          $delegate.editLink.reLinkButton.tooltip = "Relink";
          $delegate.editLink.unLinkButton.tooltip = "Unlink";
          $delegate.editLink.targetToggle.buttontext = "Ouvrir dans une nouvelle fenêtre";
          $delegate.wordcount.tooltip = "Afficher le contenu des mots";
          $delegate.charcount.tooltip = "Afficher le contenu des caractères";
        } else {
          // console.log('textAngular $translate.instant', 'localeId', localeId, $translate.instant('textAngular.html.tooltip'));
          $delegate.html.tooltip = $translate.instant('textAngular.html.tooltip');
          $delegate.heading.tooltip = $translate.instant('textAngular.heading.tooltip');
          $delegate.p.tooltip = $translate.instant('textAngular.p.tooltip');
          $delegate.pre.tooltip = $translate.instant('textAngular.pre.tooltip');
          $delegate.ul.tooltip = $translate.instant('textAngular.ul.tooltip');
          $delegate.ol.tooltip = $translate.instant('textAngular.ol.tooltip');
          $delegate.quote.tooltip = $translate.instant('textAngular.quote.tooltip');
          $delegate.undo.tooltip = $translate.instant('textAngular.undo.tooltip');
          $delegate.redo.tooltip = $translate.instant('textAngular.redo.tooltip');
          $delegate.bold.tooltip = $translate.instant('textAngular.bold.tooltip');
          $delegate.italic.tooltip = $translate.instant('textAngular.italic.tooltip');
          $delegate.underline.tooltip = $translate.instant('textAngular.underline.tooltip');
          $delegate.strikeThrough.tooltip = $translate.instant('textAngular.strikeThrough.tooltip');
          $delegate.justifyLeft.tooltip = $translate.instant('textAngular.justifyLeft.tooltip');
          $delegate.justifyRight.tooltip = $translate.instant('textAngular.justifyRight.tooltip');
          $delegate.justifyFull.tooltip = $translate.instant('textAngular.justifyFull.tooltip');
          $delegate.justifyCenter.tooltip = $translate.instant('textAngular.justifyCenter.tooltip');
          $delegate.indent.tooltip = $translate.instant('textAngular.indent.tooltip');
          $delegate.outdent.tooltip = $translate.instant('textAngular.outdent.tooltip');
          $delegate.clear.tooltip = $translate.instant('textAngular.clear.tooltip');
          $delegate.insertImage.dialogPrompt = $translate.instant('textAngular.insertImage.dialogPrompt');
          $delegate.insertImage.tooltip = $translate.instant('textAngular.insertImage.tooltip');
          $delegate.insertImage.hotkey = $translate.instant('textAngular.insertImage.hotkey');
          $delegate.insertVideo.tooltip = $translate.instant('textAngular.insertVideo.tooltip');
          $delegate.insertVideo.dialogPrompt = $translate.instant('textAngular.insertVideo.dialogPrompt');
          $delegate.insertLink.tooltip = $translate.instant('textAngular.insertLink.tooltip');
          $delegate.insertLink.dialogPrompt = $translate.instant('textAngular.insertLink.dialogPrompt');
          $delegate.editLink.reLinkButton.tooltip = $translate.instant('textAngular.editLink.reLinkButton.tooltip');
          $delegate.editLink.unLinkButton.tooltip = $translate.instant('textAngular.editLink.unLinkButton.tooltip');
          $delegate.editLink.targetToggle.buttontext = $translate.instant('textAngular.editLink.targetToggle.buttontext');
          $delegate.wordcount.tooltip = $translate.instant('textAngular.wordcount.tooltip');
          $delegate.charcount.tooltip = $translate.instant('textAngular.charcount.tooltip');
        }
        return $delegate;
      }]);
  }]).controller('NullController', [function () {
  }]);
}