namespace wg {
  export class wgInfoTipButton implements ng.IDirective {
    static factory(): ng.IDirectiveFactory {
      const directive = () => new wgInfoTipButton();
      return directive;
    }

    public restrict = 'E';
    public scope = {
      targetTag: '@tag'
    };
    public template = `
      <button type="button" class="i fa fa-info-circle"></button>
    `;
    public link(scope: any, element: ng.IAugmentedJQuery) {
      console.log('Target Tag:', scope.targetTag);
      const buttonElement = element.find('button');
      buttonElement.on('click', () => {
        const targetElement = document.getElementById(scope.targetTag);
        if (targetElement) {
          const isVisible = targetElement.style.display === 'block';
          targetElement.style.display = isVisible ? 'none' : 'block';
        }
      });
    }
  }

  export class wgInfoTipText implements ng.IDirective {
    static factory(): ng.IDirectiveFactory {
      const directive = () => new wgInfoTipText();
      return directive;
    }

    public restrict = 'E';
    public scope = {
      tag: '@'
    };
    public link(scope: any, element: ng.IAugmentedJQuery) {
      element.attr('id', scope.tag);
      element.css('display', 'none');
    }
  }

  App.directive('wgInfoTipButton', wgInfoTipButton.factory());
  App.directive('wgInfoTipText', wgInfoTipText.factory());
}



