/**
 * Created by pmec on 03/12/15.
 */

namespace wg {
  /**
   * Valid options:
   *  - standalone: Use their own div. Else, inject on parent's div (transclude)
   *  - no-overlay: use their own "Block" div, else overlay over existing one.
   *  - opaque: opaque background
   *  - s: small size
   *  - xs: extra small size
   *  - white: white color instead of black
   *  - inline: display: inline?
   * @type {WhirlDirective}
   */
  class WhirlDirective implements ng.IDirective {
    static factory(): ng.IDirectiveFactory {
      const directive = () => new WhirlDirective();
      directive.$inject = [];
      // @ts-ignore
      return directive;
    }

    constructor() {
    };

    restrict = 'EA';
    replace = true;
    transclude = true;
    scope = {
      loading: '=whirl',
      whirlOptions: '@',
    };


    // @ts-ignore
    template = (elem: JQuery, attr: ng.IAttributes): string => {
      if (attr?.whirlOptions?.indexOf("standalone") !== -1) {
        return '<div ng-show="loading" class="whirl {{whirlClasses}}"></div>';
      } else {
        return '<div ng-transclude ng-class="{\'whirl\': loading}" class="{{whirlClasses}}"></div>';
      }
    };

    link = (scope: ng.IScope, element: JQuery, attrs: ng.IAttributes) => {
      const optionsArray = (attrs.whirlOptions || '').replace("whirl-", "").split(' ');

      scope.whirlClasses = "";

      if (optionsArray.indexOf('no-overlay') !== -1) {
        scope.whirlClasses += ' whirl-no-overlay';
      } else {
        scope.whirlClasses += ' whirl-overlay';
      }
      if (optionsArray.indexOf('sm') !== -1 || optionsArray.indexOf('s') !== -1) {
        scope.whirlClasses += ' whirl-sm';
      }
      if (optionsArray.indexOf('xs') !== -1) {
        scope.whirlClasses += ' whirl-xs';
      }
      if (optionsArray.indexOf('white') !== -1) {
        scope.whirlClasses += ' whirl-white';
      }
      if (optionsArray.indexOf('inline') !== -1) {
        scope.whirlClasses += ' whirl-inline';
      }
      if (optionsArray.indexOf('opaque') !== -1) {
        scope.whirlClasses += ' whirl-opaque';

      }
      // if (optionsArray.indexOf('standalone') !== -1) {
      //   scope.whirlClasses += ' whirl-overlay';
      // }
    };
  }

  App.directive('whirl', WhirlDirective.factory());
}