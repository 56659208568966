// Base on https://robertyoung.github.io/angular-bootstrap-feedback/ but this needed angular 1.5.0 and was not flexible enough.
// and another http://experiments.hertzen.com/jsfeedback/
// Both use https://github.com/niklasvh/html2canvas but in our case was not working properly.
// The screen had some artifacts from the CSS. So now we ask the user to take a screenshot from the host OS.
// An example of html2canvas http://jsfiddle.net/4w9rdrte/
namespace wg {
  App.controller('feedbackCtrl', ['$rootScope', '$scope', 'ngDialog', '$window', 'APIUtils', 'deviceDetector',
    function ($rootScope: IRootScope, $scope, ngDialog: ng.dialog.IDialogService, $window, APIUtils: APIUtils, deviceDetector) {
      if (WG_debug) console.log('feedback directive controller', deviceDetector);
      $scope.feedback = {};
      // $rootScope.feedback = $scope.feedback;

      $scope.feedback.deviceDetector = deviceDetector.raw;
      $scope.feedback.keyboard_short = false;

      function init() {
        $scope.feedback.isModalOpened = false;
        $scope.feedback.isScreenshotMode = false;
        $scope.feedback.isScreenshotDone = false;
        $scope.modalScope = $scope.$new();
        $scope.modalScope.feedback = $scope.feedback;
        $scope.modalScope.data = {attachments: []};
        $scope.modalScope.preview_data = {};
      }

      init();

      $scope.feedback.open_file_uploader = function () {
        if (WG_debug) console.log('feedback directive open_file_uploader');
        ngDialog.openConfirm({
          // templateUrl: 'app/views/modals/file-upload.html',
          template: 'app/views/modals/file-upload.html',
          className: 'ngdialog-theme-default ngdialog-theme-default-lg',
          data: {
            upload_url: 'api/dashboard/feedback/files/',
            upload_broadcast_id: 'FileUploadModal:onSuccessItem:Feeback',
            upload_single: true,
          },
          controller: 'FileUploadModalInstance',
        }).then((results) => {
          console.log('Files Uploaded', results);
          var location = window.location.origin;
          angular.forEach(results, function (result) {
            if (result && result.id) {
              $scope.data.attachments.push({"id": result.id})
            }
          });
        }, (reason) => {
          console.log('Modal promise rejected. Reason: ', reason);
        });
      };
      $scope.feedback.takeScreenshotButtonPressed = function () {
        $scope.feedback.isScreenshotMode = true;
        if (WG_debug) console.log('feedback directive takeScreenshotButtonPressed');
      };
      $scope.feedback.cancelScreenshotPressed = function () {
        if (WG_debug) console.log('feedback directive cancelScreenshotPressed');
        init();
      };
      $scope.feedback.takeScreenshotPressed = function () {
        $scope.feedback.isScreenshotMode = false;
        $scope.feedback.isScreenshotDone = true;
        if (WG_debug) console.log('feedback directive takeScreenshotPressed');
        $scope.feedback.openModal();
      };
      $scope.feedback.openModal = function () {
        if (WG_debug) console.log('feedback directive openModal');
        $scope.feedback.isModalOpened = true;
        $scope.feedback.isScreenshotMode = false;
        ngDialog.openConfirm({
          template: 'feedbackModalDialog',
          className: 'ngdialog-theme-default ngdialog-theme-default-lg',
          data: {},
          scope: $scope,
          controller: ['$rootScope', '$scope', function ($rootScope: IRootScope, $scope) {
            // https://jsfiddle.net/u6Lkn48d/
            window.addEventListener('paste', function (e) {
              $scope.$apply(function () {
                // @ts-ignore
                console.log(e.clipboardData.files);
                $scope.data.screenshot = undefined;
                $scope.preview_data.screenshot = undefined;
                // @ts-ignore
                if (e.clipboardData.files.length === 1) {
                  // @ts-ignore
                  $scope.data.screenshot = e.clipboardData.files[0];
                  // @ts-ignore
                  $scope.preview_data.screenshot = e.clipboardData.files[0];
                }
              });
            }, false);
          }]
        }).then((data) => {
          $scope.feedback.isModalOpened = false;
          console.log('Feedback submitted', data);

          if (data) {
            APIUtils.save_feedback_with_attachments(data['title'], data['body'], data['screenshot'], data['attachments'])
          }
          init();
        }, (reason) => {
          if (reason !== 'takeScreenshotButtonPressed') {
            init();
            $scope.isModalOpened = false;
            console.log('Feedback rejected. Reason: ', reason);
          }
        });
      }
    }]);


  App.directive('feedback', [function () {
    return {
      restrict: 'E',
      controller: 'feedbackCtrl',
      scope: {},
      link: function ($scope, $element) {
        if (WG_debug) console.log('feedback directive link');
        var options = $element.data();
      },
      // transclude: true,
      templateUrl: "app/views/directives/feedback.html",
    };
  }]);
}
