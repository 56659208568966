namespace wg {

    export class DistributorAssignModalController {

        static $inject = ["$scope", "$http", "ngDialog"];

        submitted: boolean = false;
        requestError: string = undefined;
        success: boolean = undefined;
        loading: boolean = false;



        public selectedDistributor: IDistributor;
        public distributors: IDistributor[]; //one distributor for each device, this works like this because wg-dist-picker expects a list to handle actions for multiple devices
        public deviceData: IDevice[];


        constructor(private $scope: ng.IScope, private $http: ng.IHttpService, private ngDialog: any) {
        }

        $onInit() {

            this.distributors = Array(this.deviceData.length) as IDistributor[];

        }

        saveData() {
            this.submitted = true;
            this.loading = true;

            const newLicenseData = this.deviceData.map((device) => {
                return {
                    device_id: device.id,
                    distributor_id: this.selectedDistributor.id
                }
            });

            Promise.all(
                newLicenseData.map((data) => {
                    return this.$http.post('api/dashboard/distributors/license/', {
                        device_id: data.device_id,
                        distributor_id: data.distributor_id
                    })
                })
            )
            .then(() => {
                this.success = true;
                this.loading = false;
                setTimeout(() => {
                    this.ngDialog.closeAll();
                    location.reload();
                }, 2000);
            })
            .catch((error) => {
                this.requestError = error.data;
                this.loading = false;
            });


        }
    }
}


    App.component('distributorAssignModal', {

        controller: wg.DistributorAssignModalController,
        templateUrl: 'app/views/modals/distributor-assign-modal.html',
        bindings: {
            deviceData: '=',
        },
        controllerAs: 'ctrl'
    });





