namespace wg {

    export type wgDropdownOption = {
        label: string,
        value: any,
    }

    export class WgDropdownSelectController {


        static $inject = [];
        constructor() {}

        public items:wgDropdownOption[];
        public selectedItem:wgDropdownOption;
        public onChangeCallback: (changes: any) => void;
        dropdownOpen = false;

        onChange(item:wgDropdownOption) {

            this.selectedItem = item;
            this.toggleDropdown();
            if (this.onChangeCallback) {

                // value and label come from this -> items="[{label:'Winegrid':value:'wg'},{label:'Enartis':value:'en'}]"
                this.onChangeCallback({ itemLabel: this.selectedItem.label, itemValue: this.selectedItem.value });
            }
        };

        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        }

        $onInit = () => {

            this.selectedItem = this.items[0];
        }
    }

    App.component('wgDropdownSelect', {
        controller: WgDropdownSelectController as any,
        templateUrl: "app/views/directives/wg-dropdown-select.html",
        controllerAs: 'ctrl',
        bindings: {
            items: '<',
            onChangeCallback: '&?',
        },
    })
}