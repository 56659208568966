/**=========================================================
 * Module: access-register.js
 * Demo for register account api
 =========================================================*/
namespace wg {
  App.controller('RecoverFormController', ['$scope', '$rootScope', '$http', '$state', '$timeout', 'AuthService',
    function ($scope, $rootScope: IRootScope, $http: ng.IHttpService, $state: _IState, $timeout: ng.ITimeoutService, AuthService: IAuthService) {
      if (AuthService.isLogged) {
        $state.go(AuthService.homeState);
        return;
      }

      // bind here all data from the form
      $scope.account = {
        email: ''
      };

      $scope.retStatus = {
        loading: false,
        result: null,
        message: '',
      } as IReturnResult;

      var dataError = null;
      $scope.reset = function () {
        if ($scope.retStatus.loading)
          return;
        $scope.retStatus.message = '';
        $scope.retStatus.loading = true;
        $scope.retStatus.result = null;

        let timeoutRequest = $timeout(function () {
          $scope.retStatus.loading = false;
          $scope.retStatus.message = 'app.error.TIMEOUT';
          $scope.retStatus.result = "error";
        }, 10000);

        AuthService.recover($scope.account.email).then(
            function (data) {
              $scope.retStatus.message = data.detail;
              $rootScope.parsley_reset('resetInput', dataError);
              if (AuthService.response.status === 200) {
                $scope.retStatus.result = "success";
                $state.go(AuthService.loginState, {
                  detail: data.detail,
                  status: AuthService.response.status
                }, {location: false, inherit: false});
              } else {
                $scope.retStatus.result = "error";
              }
            }, function (data) {
              $rootScope.parsley_reset('resetInput', dataError);
              dataError = data;
              var used_errors = $rootScope.parsley_set('resetInput', dataError);
              if (data.non_field_errors) {
                $scope.retStatus.message = data.non_field_errors[0];
              } else {
                $scope.retStatus.message = 'pages.recover.SERVERREQUEST';
              }
              $scope.retStatus.result = "error";
            })
            .finally(function () {
              $scope.retStatus.loading = false;
              $timeout.cancel(timeoutRequest);
            });
      };
    }]);
}