/**
 * Created by pmec on 30/11/15.
 */
App.directive('ngConfirmModal', ['ngDialog', function (ngDialog) {
  return {
    restrict: 'A',
    scope: {
      ngDialogScope: '=',
      ngConfirmIf: '=',
      ngConfirmClick: '&',
      ngConfirmRejectClick: '&',
      ngConfirmTitle: '@',
      ngConfirmText: '@',
      ngConfirmOk: '@',
      ngConfirmCancel: '@'
    },
    link: function (scope, elem, attrs) {
      elem.on('click', function (e) {
        e.preventDefault();
        if (angular.isDefined(scope.ngConfirmIf) && !scope.ngConfirmIf) {
          if (angular.isDefined(scope.ngConfirmClick)) {
            scope.$apply(scope.ngConfirmClick);
          }
          return
        }
        var ngDialogScope = angular.isDefined(scope.ngDialogScope) ? scope.ngDialogScope : scope;
        angular.isDefined(attrs.ngDialogClosePrevious) && ngDialog.close(attrs.ngDialogClosePrevious);

        var defaults = ngDialog.getDefaults();

        scope.confirmData = {
          title: 'Confirm',
          text: '',
          ok: 'OK',
          cancel: 'Cancel'
        };
        if (scope.ngConfirmTitle) {
          scope.confirmData.title = scope.ngConfirmTitle;
        }
        if (scope.ngConfirmText) {
          scope.confirmData.text = scope.ngConfirmText;
        }
        if (scope.ngConfirmOk) {
          scope.confirmData.ok = scope.ngConfirmOk;
        }
        if (scope.ngConfirmCancel) {
          scope.confirmData.cancel = scope.ngConfirmCancel;
        }
        ngDialogScope.confirmData = scope.confirmData;

        var confirmFn = function () {
          if (angular.isDefined(scope.ngConfirmClick)) {
            //scope.$apply(scope.ngConfirmClick());
            scope.ngConfirmClick(scope, {$event:e});
          }
        };
        var rejectFn = function () {
          if (angular.isDefined(scope.ngConfirmRejectClick)) {
            //scope.$apply(scope.ngConfirmRejectClick());
            scope.ngConfirmRejectClick(scope, {$event:e});
          }
        };

        ngDialog.openConfirm({
          template: attrs.ngConfirmModal,
          className: attrs.ngDialogClass || defaults.className,
          controller: attrs.ngDialogController,
          scope: ngDialogScope,
          data: attrs.ngDialogData,
          showClose: attrs.ngDialogShowClose === 'false' ? false : (attrs.ngDialogShowClose === 'true' ? true : defaults.showClose),
          closeByDocument: attrs.ngDialogCloseByDocument === 'false' ? false : (attrs.ngDialogCloseByDocument === 'true' ? true : defaults.closeByDocument),
          closeByEscape: attrs.ngDialogCloseByEscape === 'false' ? false : (attrs.ngDialogCloseByEscape === 'true' ? true : defaults.closeByEscape),
          preCloseCallback: attrs.ngDialogPreCloseCallback || defaults.preCloseCallback
        }).then(function (confirm) {
          confirmFn();
          //console.log('Confirmed');
          //scope.$apply(callback);
        }, function (reject) {
          rejectFn();
          //console.log('Rejected');
        });
      });

    }
  }
}]);
