/**
 * Created by pmec on 19/02/16.
 */
// App.config(['uiGmapGoogleMapApiProvider', function (GoogleMapApi) {
//   GoogleMapApi.configure({
// //    key: 'your api key',
//     // v: '3.20',
//     // libraries: 'geometry,visualization'
//   });
// }]);

App.controller('AcquaController', ['$rootScope', '$scope', '$filter', '$http', '$location', 'uiGmapIsReady', 'uiGmapGoogleMapApi',
  function ($rootScope, $scope, $filter, $http, $location, uiGmapIsReady, uiGmapGoogleMapApi) {
    uiGmapGoogleMapApi.then(function (maps) {
      console.log(maps);
    });
    $scope.map = {
      center: {latitude: 40.6555516, longitude: -8.6733586},
      zoom: 16,
      bounds: {},
      originalBounds: {}
    };
    $scope.options = {scrollwheel: true};
    $scope.maploading = true;
    
    $scope.deviceMarkers = [];
    $scope.defDeviceMarkers = [
      {
        id: 'base',
        latitude: 40.65386231,
        longitude: -8.66813242,
        title: 'Base',
        href: 'app/devices/unit/13',
        onClick: function (gMarker, event, marker) {
          console.log('Base');
          $location.path("/404");
          $location.path(marker.href);
        }
        //icon: "http://chart.apis.google.com/chart?chst=d_map_spin&chld=1|0|ffffff|10|_|" + devices[i].name
      }, {
        id: 'SO',
        latitude: 40.65485126,
        longitude: -8.66867155,
        title: 'SO',
        // href: 'app/devices/unit/13',
        href: 'app/devices/units?unit=uqJCiBLs4PolFjNGJHVEI4',
        onClick: function (gMarker, event, marker) {
          console.log('SO');
          $location.path(marker.href);
        }
      }, {
        id: 'SE',
        latitude: 40.65580153,
        longitude: -8.66809756,
        title: 'SE',
        // href: 'app/devices/unit/12',
        href: 'app/devices/units?unit=Ax0mzq7irSko9m160WgET1',
        onClick: function (gMarker, event, marker) {
          console.log('SE');
          //$location.path(marker.href);
        }
      }];
    $scope.statewatch = false;
    
    $scope.$watch(function () {
      return $scope.map.bounds;
    }, function (nv, ov) {
      // Only need to regenerate once
      if (!ov.southwest && nv.southwest) {
        angular.extend($scope.map.originalBounds, $scope.map.bounds);
      }
    }, true);
    
    uiGmapIsReady.promise(1).then(function () {
      $scope.options = {scrollwheel: false, mapTypeId: google.maps.MapTypeId.SATELLITE};
      //$scope.options = {scrollwheel: false};
      $scope.loadMapMakers();
      $scope.maploading = false;
    });
    
    $scope.loadMapMakers = function () {
      if ($scope.statewatch === false) {
        $scope.statewatch = true;
        
        $rootScope.$watch('WGApiData.AllReady', function () {
          if (!$rootScope.WGApiData.AllReady) {
            return;
          }
          $scope.loadMapMakers();
        });
      }
      
      if (typeof (google) === 'undefined')
        return;
      if (typeof (google.maps) === 'undefined')
        return;
      
      var markers = [];
      if (!_.isEmpty($rootScope.WGDevices.devices)) {
        
        let devices = $rootScope.WGDevices.devices;
        
        for (let _device of devices) {
          if (_device.lat == null || _device.lon == null || _device.description !== 'Ilha dos Puxadoiros') {
            continue;
          }
          markers.push({
            id: _device.name,
            latitude: _device.lat,
            longitude: _device.lon,
            title: _device.name,
            uuid: _device.uuid,
            // href: 'app/devices/unit/'+ _device.uuid,
            // href: 'app/devices/units?unit=' + _device.uuid,
            href: 'app/devices/units',
            onClick: function (gMarker, event, marker) {
              console.log(marker);
              $location.path(marker.href).search({unit: marker.uuid});
            }
            //icon: "http://chart.apis.google.com/chart?chst=d_map_spin&chld=1|0|ffffff|10|_|" + _device.name
          });
        }
      }
      if (markers.length === 0)
        return;
      //markers = $scope.defDeviceMarkers;
      $scope.deviceMarkers = markers;
      
      var bounds = new google.maps.LatLngBounds();
      angular.forEach(markers, function (marker) {
        bounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude));
      });
      var northeast = bounds.getNorthEast();
      var southwest = bounds.getSouthWest();
      $scope.map.bounds = {
        northeast: {
          latitude: northeast.lat(),
          longitude: northeast.lng()
        },
        southwest: {
          latitude: southwest.lat(),
          longitude: southwest.lng()
        }
      };
      angular.extend($scope.map.originalBounds, $scope.map.bounds);
    }
  }]);