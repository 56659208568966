/**
 * Created by LC on 23/05/2020.
 */
namespace wg {
  App.controller("AIConfigsController", ['$rootScope', '$scope', 'AuthService',
    function ($rootScope:IRootScope, $scope, AuthService:IAuthService) {

      // Create required fields
      if (!AuthService.user.configs) {
        AuthService.user.configs = {};
      }
      if (!AuthService.user.configs.ai_notifications) {
        AuthService.user.configs.ai_notifications = {}
      }

      $scope.userConfigs = AuthService.user.configs;

      $scope.ai_configs = [];

      // TODO: When implementing a "disable-all" switch, we need either to:
      //  switch all to false OR
      //  complicate the logic when reading the value to also check the dependent OR
      //  save a second variable indicating what the original value was to come back to it when re-enabling.
      // Hardcoded available options and their user.configs reference
      // $scope.ai_configs['ALL'] = {
      //   name_sref: 'app.ai_configs.ALL',
      //   config: AuthService.user.configs.ai_notifications['ALL'], // read 'active','email','notification', etc
      // };

      $scope.ai_configs.push({
        type: 'status',
        name_sref: 'app.ai_configs.DEVICE_MISSING',
        config: $scope.userConfigs.ai_notifications['DEVICE_MISSING'],
      });
      $scope.ai_configs.push({
        type: 'status',
        name_sref: 'app.ai_configs.DENS_AFTER_FERMENTATION',
        config: $scope.userConfigs.ai_notifications['DENS_AFTER_FERMENTATION'],
      });


      $scope.ai_configs.push({
        type: 'ai',
        name_sref: 'app.ai_configs.FERMENTATION_STARTED',
        config: $scope.userConfigs.ai_notifications['FERMENTATION_STARTED'],
      });
      $scope.ai_configs.push({
        type: 'ai',
        name_sref: 'app.ai_configs.FERMENTATION_STAGNATED',
        config: $scope.userConfigs.ai_notifications['FERMENTATION_STAGNATED'],
      });
      $scope.ai_configs.push({
        type: 'ai',
        name_sref: 'app.ai_configs.FERMENTATION_END',
        config: $scope.userConfigs.ai_notifications['FERMENTATION_END'],
      });

      if (WG_debug) console.log("Loaded ai_configs:", $scope.ai_configs);


      $scope.updateStatus = {loading: false, result: ''};
      $scope.updatePreferences = function () {
        AuthService.updateUserConfigs($scope.userConfigs, $scope.updateStatus);
      };
    }
  ]);
}