import ITask = wg.ITask;
import IPlace = wg.IPlace;
import IWinemakingStage = wg.IWinemakingStage;
import IWinemakingProtocol = wg.IWinemakingProtocol;


class WGProtocolTaskView {

  //this is passed to the controller and we extract the values from it
  public selectedPlace: IPlace;
  //after selectedPlace is processed, we store the tasks here
  public tasks: ITask[] = [];
  public protocols: IWinemakingProtocol[] = [];

  public hiddenFields: {};
  public isCheckboxSelected: boolean[] = [];

  public taskTableParams: any;
  public loading: boolean = false;
  public TASK_STATUS_TYPES = wg.TASK_STATUS_TYPES;

  // public alphabeticSort = WGProtocolTimelineView.alphabeticSort;

  static $inject = ["NgTableParams", "$scope", "$rootScope", "$http", "ngDialog", "WGApiData"];

  constructor(private ngTableParams: NgTable.ITableParamsConstructor<ITask>, private $scope: ng.IScope, private $rootScope: ng.IRootScopeService, private $http: ng.IHttpService, private ngDialog: angular.dialog.IDialogService, private WGApiData: wg.WGApiData) {


    // Listen for the 'selectAllTasks' event
    $scope.$on('selectAllTasks', (event, isSelected) => {
      this.selectAll(isSelected);
    });

    // Listen for the 'taskFilterChanged' event
    $rootScope.$on('taskFilterChanged', (event, searchText, filters) => {
      // this.taskTableParams.filter({name: searchText});
    });

    $rootScope.$on('tasksChangedStatus', (event, data) => {
      this.taskTableParams = this.buildTableParams(this, this.tasks);
    });
  }


  public $onInit() {
    if (WG_debug) console.log("on init WGProtocolTaskView");
    this.hiddenFields = this.hiddenFields || {unit: true};
  }

  changeSelection(rowIndex: number, task: ITask) {
    console.log("changeSelection", rowIndex, task);
  }

  selectAll(onOrOff: boolean) {
    if (WG_debug) console.log("selectAll");

    for (let i = 0; i < this.tasks.length; i++) {
      this.isCheckboxSelected[i] = onOrOff;
    }


  }

  $onChanges(changes: any) {
    //if selectedPlace changes, we need to update the tasks and build ngTableParams
    if (changes.selectedPlace) {
      if (WG_debug) console.log("selectedPlace changed", this.selectedPlace);

      this.taskTableParams = this.buildTableParams(this, this.tasks);
    }

  }

  public buildTableParams(ctrl: WGProtocolTaskView, tasks: ITask[]): any {
    return new ctrl.ngTableParams({
      page: 1,
      count: 10,
      sorting: {status: "asc"}
    }, {
      // @ts-ignore
      getData: ($defer: any, params: any) => {
        ctrl.loading = true;
        //response is an array of units with fields `tasks` and `task_planned`
        ctrl.$http.get<{
          current_stage: IWinemakingStage,
          protocols: IWinemakingProtocol[],
          tasks: ITask[],
          planned_tasks: ITask[],
          id: number, // Unit ID
          place: number // Place ID
        }[]>("api/dashboard/units/winemaking/").then((response) => {
          ctrl.loading = true;
          const _tasksByUnit = response.data;

          const tasks = _tasksByUnit.reduce(
              (acc, unit) => {
                //if unit is not in the selected place, skip
                let _unit = _.find(ctrl.selectedPlace?.units, {id: unit.id});
                if (!_unit) {
                  if (WG_debug) console.log("Unit not found in selected place", unit);
                  return acc;
                }

                const normal_tasks = unit.tasks.map((task) => {
                  task.unit = _unit;
                  task.current_stage = unit.current_stage;
                  return task;
                })
                const planned_tasks = unit.planned_tasks.map((task) => {
                  task.unit = _unit;
                  // task.current_stage = unit.current_stage;
                  task.status = 4
                  return task;
                })
                return acc.concat(normal_tasks, planned_tasks);
              }, []
          )
          ctrl.tasks = tasks;


          const sortField = Object.keys(params.sorting())[0];
          let sortingAlgorithm = undefined;
          if (sortField === 'status') {
            const order = params.sorting()[sortField] === 'asc' ? 1 : -1;
            sortingAlgorithm = ctrl.getStatusSort(params, sortField);
          } else if (sortField === 'name') {
            sortingAlgorithm = ctrl.alphabeticSort(params, sortField);
          } else if (sortField === 'unit__name') {
            sortingAlgorithm = ctrl.alphabeticSort(params, sortField);
          } else if (sortField === 'stage_name') {
            sortingAlgorithm = ctrl.alphabeticSort(params, sortField);
          } else if (sortField === 'due_at') {
            sortingAlgorithm = ctrl.getTimeSort(params.sorting()[sortField], "due_at", "completed_at");
          }

          const orderedData = params.sorting() ? tasks.sort(sortingAlgorithm) : tasks;

          ctrl.loading = false;
          $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()))
        });
      }
    });
  }

  public getStatusByTaskId(taskId: number) {
    return _.find(this.TASK_STATUS_TYPES, {id: taskId});
  }

  public onTaskClick(task: ITask) {
    wg.WgTimelineItem.onTaskClick(task, this);
  }

  public alphabeticSort(params, sortField) {
    return (a: ITask, b: ITask) => {
      const direction: "desc" | "asc" = params.sorting()[sortField];
      const fields = sortField.split('__');

      const getField = (obj, fields) => {
        return fields.reduce((acc, field) => acc && acc[field], obj) || '';
      };


      const aField = getField(a, fields);
      const bField = getField(b, fields);
      return direction === 'asc' ? aField.localeCompare(bField) : bField.localeCompare(aField);
    }
  }

  public getStatusSort(params, sortField) {
    return (a: ITask, b: ITask) => {
      const direction: "desc" | "asc" = params.sorting()[sortField];
      const statusOrder = [2, 3, 4, 1];
      const aStatus = a.status;
      const bStatus = b.status;

      const aIndex = statusOrder.indexOf(aStatus);
      const bIndex = statusOrder.indexOf(bStatus);

      if (aIndex === bIndex) {
        const aDate = new Date(a.due_at).getTime() || Number.MAX_SAFE_INTEGER;
        const bDate = new Date(b.due_at).getTime() || Number.MAX_SAFE_INTEGER;

        return direction === 'asc' ? aDate - bDate : bDate - aDate;
      }

      return direction === 'asc' ? aIndex - bIndex : bIndex - aIndex;
    }
  }

  public getTimeSort(direction: "desc" | "asc", ...fields) { // you can pass multiple fields to sort by, in case the first one doesn't have a value
    return (a: ITask, b: ITask) => {
      let timeA = a[fields[0]] || a[fields[1]] || Number.MAX_SAFE_INTEGER; //two fields enough for now
      let timeB = b[fields[0]] || b[fields[1]] || Number.MAX_SAFE_INTEGER;
      timeA = new Date(timeA).getTime();
      timeB = new Date(timeB).getTime();

      return direction === 'asc' ? timeA - timeB : timeB - timeA;
    }

  }
}


App.component("wgProtocolTaskView", {
  templateUrl: "app/views/partials/wg-protocol-task-view.html",
  controller: WGProtocolTaskView,
  controllerAs: "ctrl",
  bindings: {
    selectedPlace: "<",
    hiddenFields: "<"
  }
})

