namespace wg {
  App.controller('TourController',
      ['$rootScope', '$scope', 'uiTourService', 'AuthService',
        function ($rootScope:IRootScope, $scope, uiTourService, AuthService: IAuthService) {
          if (WG_debug) console.log('TourController Init');

          // $scope.navigateToAndWaitFor = function (_tour, state, stepId) {
          //   // Used to build multi-page/view tours
          //   $state.go(state);
          //   return _tour.waitFor(stepId);
          // };

          // Current tour version. Increase when steps are updated
          $rootScope.TourVersion_Current = 2;

          if ($rootScope.TourVersion_Current > $rootScope.TourVersion_UserSeen) {
            if (WG_debug) console.log('Tour version seen by this user: ' + $rootScope.TourVersion_UserSeen +
                ', current version: ' + $rootScope.TourVersion_Current);
          }

          // Call with to set that the user has seen the tour with given version
          $scope.updateSeenTour = function (version) {
            version = version || $rootScope.TourVersion_Current || 0;
            var _configs = {hasSeenTour: {mainVersion: version}};
            AuthService.updateUserConfigs(_configs);
            // AuthService.updateUserConfigs(0);
            console.log("Tour seen. Updated to", version);
          };

          $rootScope.tourComplete = function (arg) {
            // if(WG_debug) console.log("Tour Complete OK", arg);
            try {
              var tour = uiTourService.getTour();
              tour.end();
            } catch (e) {
            }
            $scope.updateSeenTour($rootScope.TourVersion_Current);
          };

          // let tour = uiTourService.getTourByName('dashTour');
          // let tour = uiTourService.getTour();
          // tour.end().then(function () {
          //   if(WG_debug) console.log("Tour Ended OK"); // Called on webpage loads
          //   // $scope.updateSeenTour(1);
          //   // return $state.go('app.devices.dashboard');
          //   // }).then(function () {
          //   //   console.log("Tour Ended Fail");
          //   //   // return tour.startAt('placeStep');
          // });
          // App.config(['TourConfigProvider', function (TourConfigProvider) {
          //   TourConfigProvider.set("backdropPadding", "5px");
          //   TourConfigProvider.set("onBackdropClick", $rootScope.tourComplete);
          // }]);

          // Not working. Need to be defined in every step
          // try {
          //   // Set default configs (not sure if this works)
          //   var tour = uiTourService.getTour();
          //   tour.options.templateUrl = "tour-step-template.html"; // This is the default
          //   tour.options.appendToBody = true;
          //   tour.options.placement = "bottom-left";
          //   tour.options.backdrop = true;
          //   tour.options.backdropPadding = "5px";
          //   tour.options.backdropBorderRadius = "4px";
          //   tour.options.backdropZIndex = 10000;
          //   tour.options.onBackdropClick = $rootScope.tourComplete;
          // } catch (e) {
          // }

          if (WG_debug) console.log('TourController Done');
        }]
  );
}
