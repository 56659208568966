/**
 * Created by silva on 15-09-2016.
 */

App.controller('ReportEditController', ['$state', '$rootScope', '$scope', '$http', '$filter', 'ngDialog', 'AuthService', function ($state, $rootScope, $scope, $http, $filter, ngDialog, AuthService) {
  //TODO: make modal into page
  if (!angular.isDefined($rootScope.reportToEditID)) $state.go('app.devices.reports');

  //      ---     VARIABLES
  let halt = true;
  // $scope.new_report = $rootScope.reports[reportToEditID];
  $scope.new_report = [{
    "id": null,
    "title": "No Report Found"
  }];
  for (let i = 0; i <= $rootScope.reports.length; i++) {
    if ($rootScope.reports[i].id === $rootScope.reportToEditID) {
      $scope.new_report = $rootScope.reports[i];
      break;
    }
  }

  $scope.hours_in_a_day = [
    1, 2, 3, 4, 5,
    6, 7, 8, 9, 10,
    11, 12, 13, 14, 15,
    16, 17, 18, 19, 20,
    21, 22, 23, 24
  ];
  $scope.opcoes_para_medias = [
    {id: '1', name: '1h', duration: 1, key: 'hours'},
    {id: '2', name: '2h', duration: 2, key: 'hours'},
    {id: '3', name: '4h', duration: 4, key: 'hours'},
    {id: '4', name: '8h', duration: 8, key: 'hours'},
    {id: '5', name: '12h', duration: 12, key: 'hours'},
    {id: '6', name: '24h', duration: 24, key: 'hours'},
    {id: '7', name: '78h', duration: 78, key: 'hours'},
  ];
  $scope.displayCrontabInHuman = '';
  $scope.schedular = {
    "hour": 10,
    "hour2nd": {
      "isCheck": false,
      "value": ''
    },
    "weekly": {
      "isCheck": false,
      "weekdays": [
        {
          "name": "MON",
          "value": false
        },
        {
          "name": "TUE",
          "value": false
        },
        {
          "name": "WED",
          "value": false
        },
        {
          "name": "THU",
          "value": false
        },
        {
          "name": "FRI",
          "value": false
        },
        {
          "name": "SAT",
          "value": false
        },
        {
          "name": "SUN",
          "value": false
        }
      ]
    }
  };

  let max_n_param = 2;
  $scope.max_param = false;
  $scope.param_warn = false;

  $scope.devices = [
    {
      id: null,
      name: null,
      available: 0,
      selected: null
    }
  ];
  $scope.parameters = [
    {
      id: null,
      name: null,
      available: 0,
      selected: null
    }
  ];
  $scope.avaParLeng = 0;
  $scope.selParLeng = 0;
  $scope.selDevLeng = 0;
  $scope.searchDevTerm = {};
  $scope.searchDevTerm.name = '';

  function makeDeviceArray() {
    $scope.devices = [];
    // TODO: no fakeDevices please!!!
    angular.forEach($rootScope.fakeDevices, function (deviceOrig, key) {
      let beSelected = $scope.new_report.devices.findIndex(devi => devi.id === deviceOrig.id) > -1;
      if (WG_debug) console.log("Making Device Array for report", beSelected);

      // iterate through parameters of the device and apply the availability to each of them on the parameters array
      let devParams = deviceOrig.streams;
      calculateAvailableParameters(devParams, beSelected ? 1 : 0);

      $scope.devices.push({
        id: deviceOrig.id,
        name: deviceOrig.name,
        selected: beSelected,
        streams: devParams
      });
    });
  }

  function makeParamsArray() {
    $scope.parameters = [];
    angular.forEach(
        $rootScope.WGSensors.sensors,
        function (paramOrig, key) {
          let beSelected = $scope.new_report.parameters.findIndex(param => param.id === paramOrig.id) > -1;
          if (paramOrig.configs.accessLevel === AuthService.user.role && paramOrig.id) {
            $scope.parameters.push({
              id: paramOrig.id,
              name: paramOrig.stream,
              available: 0,
              selected: beSelected
            });
          }
        });
  }

  function iniciarController() {
    makeParamsArray();
    makeDeviceArray();
    $scope.avaParLeng = count('parameters', 'available');
    $scope.selParLeng = count('parameters', 'selected');
    $scope.selDevLeng = count('devices', 'selected');
    // halt = true;
    halt = false;
  }

  //                                                                   ---     ACTIONS
  $scope.saveChanges = function () {
    console.log("SaveChanges");
    $scope.schedular2crontab();
    selectedParametersAndDevices2newReport();
    sendReport();
  };

  $scope.deleteReport = function () {
    console.log("delete Report");
    ngDialog.openConfirm({
      template: 'app/views/modals/confirm-danger.html',
      className: 'ngdialog-theme-default',
      // controller: ['', function(){}],
      width: 400,
      scope: $scope
    }).then(function (data) {
      console.log('DELETE PROCESS CONFIRMED', data);

      $rootScope.WGProcesses.changed = true;
      $http.delete('api/dashboard/processes/' + $scope.new_report.id + '/')
          .then(function (response) {
            $rootScope.WGProcesses.changed = true;
            console.log("DELETE process response: )", response);
            if (response.status >= 200 && response.status < 300) {
              console.log("DELETE process SUCCESS");
              $scope.confirm(new_report, true);

            }
            //$scope.loading = false;
          }, errorFn);

    }, function (reason) {
      console.log('DELETE Modal promise rejected. Reason: ', reason);
    });
  };


  $scope.updateAvailableParameters = function (device) {
    if (!halt) {
      $scope.selDevLeng = count('devices', 'selected');

      let new_parameters = device.streams;
      // iterate through parameters of the device and apply the availabilty to each of them on the parameters array
      calculateAvailableParameters(new_parameters, device.selected ? 1 : -1);
      $scope.avaParLeng = count('params', 'available');
    }
  };

  // function spitNames(arr){
  //     let tt = '';
  //     angular.forEach(
  //         arr,
  //         function(bla, i){
  //             tt += i===0?bla:", "+bla;
  //     });
  //     return tt === '' ? 'empty' : tt;
  // }
  $scope.updateSelectedParameters = function (ndx) {
    if (!halt) {
      // console.log("UpdSelParams -1- ndx: "+ndx+". Parameters selected: "+spitNames($scope.parameters.filter(p=>p.selected).map(p=>p.name)));

      let change = !$scope.parameters[ndx].selected;
      // console.log("USP 0.1 change: "+change);

      let noChange = $scope.parameters[ndx].selected;
      // console.log("USP 0.2 noChange: "+noChange);

      $scope.param_warn = $scope.selParLeng >= max_n_param - 1 && change;
      // console.log("USP 0.3 $scope.param_warn: "+$scope.param_warn);

      $scope.parameters[ndx].selected = $scope.selParLeng < max_n_param + 1 ? change : noChange;
      // console.log("USP 0.4 $scope.parameters["+ndx+"].selected: "+$scope.parameters[ndx].selected+" CONDITION:$scope.selParLeng < max_n_param+1 :: "+$scope.selParLeng < max_n_param+1);

      $scope.selParLeng = count('params', 'selected');
      // console.log("USP 0.5 $scope.parameters["+ndx+"].selected: "+$scope.parameters[ndx].selected);

      // $scope.param_warn = $scope.selParLeng >= max_n_param && change;
      $scope.max_param = $scope.selParLeng >= max_n_param;
      // console.log("USP 0.6  $scope.max_param: "+ $scope.max_param);

      // console.log("UpdSelParams -2- ndx: "+ndx+". Parameters selected: "+spitNames($scope.parameters.filter(p=>p.selected).map(p=>p.name)));
    }
  };

  //                                                                   ---     FUNCTIONS

  // function doSelectedParameters () {
  //     // ---- In case modal is open to edit, check selected params and syncs them to scope's functional array of params
  //     let selPNArr = selected_params_names_array = $scope.new_report.parameters.map(a => a.name);
  //     $scope.parameters.forEach(function(existingParam, key){
  //         $scope.parameters[key].selected = selPNArr.indexOf(existingParam.name) > -1 ;
  //     });
  //     $scope.selParLeng = $scope.parameters.filter( a => a.selected>0  ).length;}


  function calculateAvailableParameters(new_parameters, dif) {
    // console.log("---***---CAP---***---");
    let objIndex;
    // let dif = isRemove ? -1 : 1;
    angular.forEach(new_parameters, function (paramName, key) {
      objIndex = $scope.parameters.findIndex(obj => obj.name === paramName);
      // $scope.parameters is filtered by accessLevel===AuthService.getUser().role
      if (objIndex > -1) {
        $scope.parameters[objIndex].available += dif;
        if ($scope.parameters[objIndex].available < 0) {
          // console.log("DEVDO -- WARNING -- ERROR: function calculateAvailableParameters => availabilty of parameter:" +paramName + " became negative.");
          //$scope.parameters[objIndex].available = 0;
          $scope.selParLeng = "ERROR: please contact wagrid about error: 0034VG010CAP567AN";
        }
      }
    });
  }

  //      ---     COM
  // function sendReport() {
  //     if ($scope.ngDialogData.isCreate) {
  //         $http.post('api/dashboard/notes/', note).then(function (response) {
  //             if (response.status === 201) {
  //                 note.edit = false;
  //                 note.preview = true;
  //                 note.edited = false;
  //                 if (!note.is_report) {
  //                     $scope.notes.push(response.data);
  //                 } else {
  //                     $scope.reports.push(response.data);
  //                 }
  //                 $scope.selectedNote = response.data;
  //             }
  //             $scope.loading = false;
  //         }, errorFn);
  //     } else {
  //         $http.patch('api/dashboard/notes/' + note.id + '/', note).then(function (response) {
  //             if (response.status === 200) {
  //                 note.edit = false;
  //                 note.preview = true;
  //                 note.edited = false;
  //             }
  //             $scope.loading = false;
  //         }, errorFn);
  //     }
  //     // $http.get('api/dashboard/reports/all/', {}).then(function (response) {
  //     //     let data = response.data;
  //     //     $scope.reports = data;
  //     //
  //     //     $scope.loading = false;
  //     // }, function (response) {
  //     //     console.error(response);});

  function selectedParametersAndDevices2newReport() {
    halt = true;
    // $rootScope.watgridSensorNames;
    $scope.new_report.devices = [];
    $scope.new_report.parameters = [];
    // let origParIds = $rootScope.WGSensors.sensors.map( a=> a.id );
    // let chave;
    // angular.forEach(
    //     $scope.parameters.filter(function(obj) {    return obj.selected;    }),
    //     function(value, key){
    //         chave = origParIds.indexOf($scope.new_report.id);
    //         // chave = $rootScope.WGSensors.sensors.findIndex( wgSensor => wgSensor.id === $scope.)
    //         $scope.new_report.parameters.push($rootScope.WGSensors.sensors[chave]);});

    angular.forEach(
        $scope.parameters.filter(p => p.selected),
        function (selPar, key) {
          let parNdx = $rootScope.WGSensors.sensors.findIndex(wgSens => wgSens.id === selPar.id);
          $scope.new_report.parameters.push($rootScope.WGSensors.sensors[parNdx]);
        });

    angular.forEach(
        $scope.devices.filter(d => d.selected),
        function (device, key) {
          $scope.new_report.devices.push(device);
        });
  }

  function sendReport() {
    //$scope.confirm($scope.new_report, false);
  }

  //                                                                   ---     UTILS

  // $('form').parsley().on('form:submit', function() {
  //     form.parsley().whenValidate().done(function(){
  //         console.log('successfully validated');
  //     });
  //     // return false; // Don't submit form for this demo
  // });
  // on form submit
  // window.Parsley.on('form:error', function(event) {
  //     console.log("error submiting form parsley");
  // });
  // window.Parsley.on('form:validate', function(event) {
  //     console.log("submiting form parsley");
  // });
  // window.Parsley.on('form:success', function(event) {
  //     // validate form with parsley.
  //     console.log("success submiting form parsley");
  //     // angular.element(this).parsley().validate();
  //     //
  //     // // if this form is valid
  //     // if (angular.element(this).parsley().isValid()) {
  //     //     // show alert message
  //     //     $scope.confirm($scope.new_report, false);
  //     // }
  //     //
  //     // // prevent default so the form doesn't submit. We can return true and
  //     // // the form will be submited or proceed with a ajax request.
  //     // event.preventDefault();
  // });
  // Em caso de querermos fazer zoom in&out no Doc
  // function map (num, in_min, in_max, out_min, out_max) {
  //     return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
  // }

  // UI utils
  $scope.toggle2hour = function ($event) {
    if (!$scope.schedular.hour2nd.isCheck) {
      //TODO: it'snot working. It'd be nice when the user opts for a second hour for the dropdown to open auto/
      console.log("dropdown should have openened1", angular.element($event.currentTarget).prev().children().children());
      // after '$scope.schedular.hour2nd.isCheck=!$scope.schedular.hour2nd.isCheck;' => open2ndDropdown($event)
    }

    // allthatmatters
    if ($scope.schedular.hour2nd.isCheck) {
      $scope.schedular.hour2nd.value = '';
    }

    $scope.schedular.hour2nd.isCheck = !$scope.schedular.hour2nd.isCheck;
    $scope.schedular2crontab();
  };
  $scope.disable_param = function (ndx) {
    return ($scope.max_param && !$scope.parameters[ndx].selected);
  };
  $scope.isWeekend = function (indx) {
    return indx > 4;
  };

  function hoursOrder() {
    if ($scope.schedular.hour2nd.value !== '') {
      if ($scope.schedular.hour2nd.value < $scope.schedular.hour) {
        let f = $scope.schedular.hour2nd.value;
        $scope.schedular.hour2nd.value = $scope.schedular.hour;
        $scope.schedular.hour = f;
      }
    }
  }

  $scope.choose2ndHour = function () {
    hoursOrder();
    $scope.schedular2crontab()
  };

  function open2ndDropdown(e) {
    // console.log("open2ndDropdown");
    // $timeout(function() {
    //     angular.element(e.currentTarget).prev().children().children().trigger('click');
    // });
  }

  function count(array, prop) {
    let counter = 0;
    prop = array === 'devices' ? false : prop === 'available';
    let arr = (array === 'devices' ? $scope.devices : $scope.parameters);
    angular.forEach(
        arr,
        function (obj, key) {
          if (prop) {
            counter += obj.available ? 1 : 0;
          } else {
            counter += obj.selected ? 1 : 0;
          }
        });
    // for(let i = 0; i < arr.length; ++i){
    //     if(countAvail){
    //         count += arr[i].available ? 1 : 0;
    //     }else{
    //         count += arr[i].selected ? 1 : 0;}}
    return counter;
  }

  $scope.schedular2crontab = function () {
    let s = '0 ';
    let m = '0 ';
    let h = $scope.schedular.hour;
    let h2 = $scope.schedular.hour2nd;
    let M = '* ';
    let w, w_h = '*';

    let daysString = $filter('translate')('app.reports.edit.crontab.EVERY_DAY') + ' ';
    let hoursString = $filter('translate')('app.reports.edit.crontab.AT') + ' ' + h + $filter('translate')('app.reports.edit.crontab.H');

    if (h2.value !== '') {
      hoursString += ' ' + $filter('translate')('app.reports.edit.crontab.AND') + ' ' + $filter('translate')('app.reports.edit.crontab.AT') + ' ' + h2.value + $filter('translate')('app.reports.edit.crontab.H');
    }

    let week = $scope.schedular.weekly;
    let chosenDays = week.weekdays.filter(function (obj) {
      return obj.value;
    });
    if (week.isCheck && chosenDays.length > 0) {
      daysString = $filter('translate')('app.reports.edit.crontab.ON') + ' ';
      w = '';
      w_h = '';
      let c = 0;

      angular.forEach(
          chosenDays,
          function (day, key) {
            let x = (key + 1 === chosenDays.length - 1) ? (' ' + $filter('translate')('app.reports.edit.crontab.AND') + ' ') : (key + 1 === chosenDays.length) ? ' ' : ', ';
            daysString += $filter('translate')('app.reports.edit.crontab.day.' + day.name + '') + x;
          }
      );
      week.weekdays.forEach(function (day, key) {
        if (day.value) {
          w += (c === 0 ? '' : ',') + key;
          w_h += (c === 0 ? '' : ',') + (key + 1);
          c++;

        }
      });
    }

    $scope.new_report.crontab.string = s + m + h + (h2.value !== '' ? ',' + h2.value : '') + M + w;
    // $scope.new_report.crontab.humanString = 'at '+h+'h'+( h2.isCheck ? ' and '+h2.value+'h' : '' );
    // $scope.new_report.crontab.humanString = 'at '+h+'h'+( h2.isCheck ? ' and '+h2.value+'h' : '' )+(w==='*' ? ' every day.' : 'on '+w+'.');
    $scope.new_report.crontab.humanString = '' + h + 'h' + (h2.value !== '' ? ', ' + h2.value + 'h' : '') + (w === '*' ? ' *' : ' | ' + w_h + '');
    $scope.displayCrontabInHuman = daysString + hoursString;
    // console.log("crontab=",crontab, "\ncrontab.humanString", $scope.new_report.crontab.humanString );
    // app.reports.edit.crontab.EVERY_DAY
    // app.reports.edit.crontab.ON
    // app.reports.edit.crontab.AT
    // app.reports.edit.crontab.AND
    // app.reports.edit.crontab.H
    // app.reports.edit.crontab.day.MON
    // app.reports.edit.crontab.day.TUE
    // app.reports.edit.crontab.day.WED
    // app.reports.edit.crontab.day.THU
    // app.reports.edit.crontab.day.FRI
    // app.reports.edit.crontab.day.SAT
    // app.reports.edit.crontab.day.SUN
  };
  $scope.date = new Date().getTime();
  angular.forEach($scope.opcoes_para_medias, function (option) {
    option.humanDuration = $filter('amDurationFormat')(option.duration, option.key);
    // option.secondsDuration = moment.duration(option.duration, option.key).asSeconds();
  });


  iniciarController();

  //      ---     DEV UTIL
  $scope.spitData = function () {
    console.log("Spit Data. rootscope", $rootScope, "scope", $scope);
  };
}]);
