namespace wg {


  // wgTable accepts an action array that can be used to display buttons|other elements in the table or for the batch actions element
  export type wgTableAction = { name: string, callback: (onWhat: Object | Object[]) => void };

  export class WgTableController {
    static $inject = ['NgTableParams', '$scope'];

    data: {}[]; // data to be displayed in the table
    tableParams: any; // table params for ng-table
    tableColumns: any; // table rows to be displayed

    loading: boolean = false;

    isAllSelected: boolean = false;
    isRowSelected: boolean[] = null;  // has length = data.length. isRowSelected[i] = true means that the row with index i is selected

    // export type Action = { name: string, callback: (index:number,row:{}) => {}} };
    actions: wgTableAction[];

    changeSelectionAll = () => {
      this.isRowSelected = this.data.map((row) => this.isAllSelected);
      console.log(this.isRowSelected);
    }

    changeSelection = (index: number) => {
      console.log(this.isRowSelected);
    }


    constructor(private ngTableParams: NgTable.ITableParamsConstructor<any>, private $scope: ng.IScope) {

    }

    $onInit = () => {
      //build table params
      this.$scope.tableParams = this.tableParams = this.buildTableParams(this);

      //if selectedRows is not passed from parent component, initialize it
      if (!this.isRowSelected) {
        this.isRowSelected = this.data.map((row) => false);
      }


    }


    buildTableParams(controller: WgTableController) {
      return new this.ngTableParams({
        page: 1,            // show first page
        count: 25,   // count per page
        filter: {}
      }, {
        total: this.data.length, // length of data
        paginationMaxBlocks: 7, // 7 = first, 3 centered, last
        paginationMinBlocks: 3,
        getData: (params) => {
          this.loading = true;
          console.log("Get-TD list_items.");
          let search = "this.search.all";
          let filter = '';
          let obj = params.filter();
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (angular.isDefined(obj[key]) && obj[key] !== '') {
                filter += key + '=' + obj[key] + '&';
              }
            }
          }
          let query = '?page_size=' + params.count();

          if (params.orderBy() + '' !== '') {
            query += '&ordering=' + params.orderBy();
          }
          if (angular.isDefined(search) && search !== '') {
            query += '&search=' + search;
          } else {
            query += '&page=' + params.page();
          }
          if (filter !== '') {
            filter = filter.substring(0, filter.length - 1);
            query += '&' + filter;
          }

          // Sorting logic
          let orderedData = controller.data;
          let orderBy = params.orderBy();
          if (orderBy) {
            orderedData = orderBy.reduce((sortedData, sortParam) => {
              let direction = sortParam[0] === '+' ? 1 : -1;
              let column = sortParam.substring(1);
              return sortedData.sort((a, b) => {
                if (a[column] < b[column]) return -1 * direction;
                if (a[column] > b[column]) return 1 * direction;
                return 0;
              });
            }, orderedData);
          }
          //return sorted and sliced data

          this.loading = false;
          console.log("Get-TD list_items done.");

          return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
        }
      });
    }
  }

  App.component('wgTable', {
    controller: WgTableController as any,
    templateUrl: "app/views/directives/wg-table.html",
    bindings: {
      data: '<',
      tableColumns: '<',
      selectedRows: '<',
      actions: '<'
    },
    transclude: {
      batchActions: '?wgBatchActions' // Named slot for wgBatchActions

    },
  });

}