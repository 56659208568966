namespace wg {
  export class wgPreventEdit implements ng.IDirective {
    static factory(): ng.IDirectiveFactory {
      const directive = () => new wgPreventEdit();
      return directive;
    }

    restrict = 'E'; // Keep it as an element directive

    scope = {
      callback: '&',
      condition: '=',
      disabled: '=?',
    };

    // TODO: Allow to programatically hide the button
    link(scope: ng.IScope, element: JQuery, attrs: any) {

      //attrs -> {condition:boolean,  callback: function , warning?: string}

      scope.disabled = (scope.disabled === true || scope.disabled == "disabled");

      scope.$watch(() => scope.$parent.$eval(attrs.condition), function (newValue) {

        if (!attrs.warning) attrs.warning = 'Multiple Values'; //keep this short so it fits in the button without line break

        if (newValue) {
          var button = angular.element(
              '<button class="btn btn-default dropdown-toggle full-width break-word"' +
              (scope.disabled ? ' disabled="disabled" ' : '') +
              ' style="background: #e6e6e6" ' +
              '>' + attrs.warning + '</button>'
          );
          button.on('click', function () {
            button.remove();
            element.show();

            //set the condition to false
            scope.condition = true;

            if (scope.callback) {
              scope.callback();
              if (!scope.$root.$$phase) {
                scope.$root.$apply();
              }
            }
          });
          element.after(button);
        } else {
          element.show();
        }
      });
    }
  }

  App.directive('wgPreventEditingMultiple', wg.wgPreventEdit.factory());
}