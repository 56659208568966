/**
 * Created by pmec on 26/09/15.
 // Angular File Upload module does not include this directive
 // Only for example


 /**
 * The ng-thumb directive
 * @author: nerv
 * @version: 0.1.2, 2014-01-09
 */

namespace wg {
  App.directive('ngThumb', ['$window', function ($window) {
    var helper = {
      support: !!($window.FileReader && $window.CanvasRenderingContext2D),
      isFile: function (item) {
        return angular.isObject(item) && item instanceof $window.File;
      },
      isImage: function (file) {
        var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
      }
    };

    return {
      restrict: 'A',
      template: '<canvas class="ngThumb"/>',
      link: function (scope, element, attributes) {
        // console.log('ngThumb directive');
        if (!helper.support) return;

        var params = scope.$eval(attributes.ngThumb);

        if (!helper.isFile(params.file)) return;
        if (!helper.isImage(params.file)) return;

        var canvas = element.find('canvas');
        var reader = new FileReader();

        reader.onload = onLoadFile;
        reader.readAsDataURL(params.file);

        function onLoadFile(event) {
          var img = new Image();
          img.onload = onLoadImage;
          img.src = event.target.result;
        }

        function onLoadImage() {
          var width = params.width || this.width / this.height * params.height;
          var height = params.height || this.height / this.width * params.width;
          canvas.attr({width: width, height: height});
          canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
        }
      }
    };
  }]);

  App.controller('FileUploadModalInstance', ['$rootScope', '$scope', '$http', 'FileUploader',
    function ($rootScope:IRootScope, $scope, $http: ng.IHttpService, FileUploader) {
      activate();

      function activate() {
        // console.log('FileUploadModalInstance', 'activate', $scope);
        var files = [];
        var upload_url = 'api/dashboard/notes/files/';
        var upload_broadcast_id = 'FileUploadModal:onSuccessItem';
        var upload_filter = '';
        $scope.upload_single = false;
        if (angular.isDefined($scope.ngDialogData)) {
          upload_url = $scope.ngDialogData.upload_url || upload_url;
          upload_broadcast_id = $scope.ngDialogData.upload_broadcast_id || upload_broadcast_id;
          upload_filter = $scope.ngDialogData.upload_filter || upload_filter;
          $scope.upload_single = $scope.ngDialogData.upload_single || $scope.upload_single;
        }
        var uploader = $scope.uploader = new FileUploader({
          url: upload_url,
          headers: $http.defaults.headers.common
        });

        // FILTERS
        uploader.filters.push({
          name: 'customFilter',
          fn: function (/*item, options*/) {
            return this.queue.length < 10;
          }
        });
        if (upload_filter === 'imageFilter') {
          uploader.filters.push({
            name: upload_filter,
            fn: function (item /*{File|FileLikeObject}*/, options) {
              var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
              var ret = true;
              return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
          });
        }

        // CALLBACKS

        uploader.onWhenAddingFileFailed = function (item /*{File|FileLikeObject}*/, filter, options) {
          console.info('onWhenAddingFileFailed', item, filter, options);
        };
        uploader.onAfterAddingFile = function (fileItem) {
          if (WG_debug) console.info('onAfterAddingFile', fileItem);
        };
        uploader.onAfterAddingAll = function (addedFileItems) {
          if (WG_debug) console.info('onAfterAddingAll', addedFileItems);
        };
        uploader.onBeforeUploadItem = function (item) {
          if (WG_debug) console.info('onBeforeUploadItem', item);
        };
        uploader.onProgressItem = function (fileItem, progress) {
          if (WG_debug) console.info('onProgressItem', fileItem, progress);
        };
        uploader.onProgressAll = function (progress) {
          if (WG_debug) console.info('onProgressAll', progress);
        };
        uploader.onSuccessItem = function (fileItem, response, status, headers) {
          if (WG_debug) console.info('onSuccessItem', fileItem, response, status, headers);
          files.push(response);
          $rootScope.$broadcast(upload_broadcast_id, fileItem, response)
        };
        uploader.onErrorItem = function (fileItem, response, status, headers) {
          console.info('onErrorItem', fileItem, response, status, headers);
        };
        uploader.onCancelItem = function (fileItem, response, status, headers) {
          console.info('onCancelItem', fileItem, response, status, headers);
        };
        uploader.onCompleteItem = function (fileItem, response, status, headers) {
          if (WG_debug) console.info('onCompleteItem', fileItem, response, status, headers);
        };
        uploader.onCompleteAll = function () {
          if (WG_debug) console.info('onCompleteAll');
          // $ubiModalInstance.close(files);
          var close = null;
          if (angular.isDefined($scope.$close)) {
            close = $scope.$close;
          } else if (angular.isDefined($scope.confirm)) {
            close = $scope.confirm;
          }
          if (close !== null) {
            close(files);
          }
        };

        // console.info('uploader', uploader);
      }

    }]);
}