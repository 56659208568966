namespace wg {
  export class ngPreventDblClick implements ng.IDirective {
    static factory(): ng.IDirectiveFactory {
      const directive = ($timeout: ng.ITimeoutService) => new ngPreventDblClick($timeout);
      directive.$inject = ['$timeout'];
      return directive;
    }

    constructor(private $timeout: ng.ITimeoutService) {
    }

    restrict = 'A';
    priority = -1;

    delay = 80;

    link(scope: ng.IScope, element: JQuery) {
      let disabled = false;
      let timeout: ng.IPromise<void>;

      let onClick = (evt: JQuery.TriggeredEvent) => {
        if (disabled) {
          console.warn("Double-click prevented");
          evt.preventDefault();
          evt.stopImmediatePropagation();
        // } else {
        //   if (WG_debug) console.log("Click detected");
        }

        disabled = true;
        if (timeout) {
          this.$timeout.cancel(timeout);
        }
        timeout = this.$timeout(() => {
          disabled = false;
          timeout = null;
        }, this.delay, false);
      }

      scope.$on('$destroy', function () {
        element.off('click', onClick);
      });
      element.on('click', onClick);
    }
  }

  App.directive('ngClick', wg.ngPreventDblClick.factory());

  App.directive('preventDoubleClick', wg.ngPreventDblClick.factory());
}
